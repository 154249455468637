import React from "react";
import { pdfjs } from "react-pdf";
import { PDFPageItem } from "react-pdf/dist/Page";
import { IconButton } from "../globalComponents";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Icon } from ".";
import { IFile } from "../../types";
import { Spinner } from "./Spinner";
import "./PdfHandler.scss";

interface IPdfHandlerProps {
    file: IFile;
}

interface IPdfHandlerState {
    pdf: pdfjs.PDFDocumentProxy | null;
    viewPort: pdfjs.PDFPageViewport | null;
    pageRendering: boolean;
    totalPages: number;
    pageNumPending: number | null;
    pdfLoaded: boolean;
    pageNum: number;
    scale: number;
    isSliding: boolean;
}

export class PdfHandler extends React.Component<IPdfHandlerProps, IPdfHandlerState> {

    private canvasRef: React.RefObject<HTMLCanvasElement>;
    private wrapperRef: React.RefObject<HTMLDivElement>;
    private sliderRef: React.RefObject<HTMLInputElement>;

    constructor(props: IPdfHandlerProps) {
        super(props);

        this.canvasRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.sliderRef = React.createRef();

        this.state = {
            viewPort: null,
            pdf: null,
            totalPages: 0,
            pageRendering: false,
            pageNumPending: null,
            pdfLoaded: false,
            pageNum: 1,
            scale: 5,
            isSliding: false,
        };
    }

    async componentDidMount() {
        const url = `${process.env.REACT_APP_LOCALHOST}${this.props.file.realFileUri}`;
        pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.js";

        var pdf: pdfjs.PDFDocumentProxy = await pdfjs.getDocument(url).promise;
        this.setState({ pdf: pdf, pdfLoaded: true, totalPages: pdf.numPages });
        this.renderPage(this.state.pageNum);


        let width = window.innerWidth;
        let height = window.innerHeight;
        window.addEventListener("orientationchange", (event) => {
            let pdfDiv = document.getElementsByClassName('react-transform-element')[0] as HTMLElement;
            
            let temp = width;
            width = height;
            height = temp;

			if(pdfDiv){
				pdfDiv.style.cssText = width > height ? "transform: translate(200px, 0px) scale(1);" : "transform: translate(0px, 0px) scale(1);";
			}
        });
    }

    render() {
        return (this.state.pdfLoaded ?
            <React.Fragment>
                <TransformWrapper options={{ minScale: 1, maxScale: 8, limitToBounds: true}} pan={{ lockAxisX: false }} >
                    <TransformComponent>
                        <div ref={this.wrapperRef}>
                            <canvas id="pdf-render-canvas" ref={this.canvasRef} />
                        </div>
                    </TransformComponent>
                </TransformWrapper>
              
				<div className="slider-scroll-container" >
                	<input className="slider-scroll" 
						type="range"  
						defaultValue="1"
						min="1"
						max={this.state.totalPages} 
						ref={this.sliderRef}
						onMouseOver={this.onSliderMouseOver}
						onMouseLeave={this.onSliderMouseLeave}
						onChange={this.onSliderChange}
						onMouseUp={this.onSliderMouseUp}
						onTouchEnd={this.onSliderTouchEnd}>
					</input>
                	<span style={{width:"1%"}}></span>
					<span className="pageCounter">{`${this.sliderRef.current && this.sliderRef.current.value}/${this.state.totalPages}`}</span>
				</div>
                {<button id="prev-btn" className="switch-page-button" hidden={this.state.isSliding || this.state.pageNum <= 1} onClick={this.onPrevPage}>
                    <Icon name="arrow_back" />
                </button>
                }
                {<button id="next-btn" className="switch-page-button" hidden={this.state.isSliding || this.state.pageNum >= this.state.totalPages} onClick={this.onNextPage}>
                    <Icon name="arrow_forward" />
                </button>
                }
            </React.Fragment >
            : <Spinner style={{ marginTop: "50%", marginLeft: "calc(50% - 40px)", width: "80px", height: "80px" }} />);
    }


	private onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({isSliding: true});
	private onSliderMouseOver = (e: React.MouseEvent<HTMLInputElement>) => this.setState({isSliding: true});
	private onSliderMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => this.setState({isSliding: false});
	private onSliderMouseUp = (e: React.MouseEvent<HTMLInputElement> ) => this.queueRenderPage(parseInt(e.currentTarget.value));
	private onSliderTouchEnd = (e: React.TouchEvent<HTMLInputElement>) => {this.queueRenderPage(parseInt(e.currentTarget.value)); this.setState({isSliding: false})};

    private onPrevPage = () => {
        if (this.state.pageNum <= 1)
            return;

		const targetPage = this.state.pageNum - 1;
		if(this.sliderRef.current){
			this.sliderRef.current.value = targetPage.toString();
		}
        this.queueRenderPage(targetPage);
    };

    private onNextPage = () => {
        if (this.state.pdf && this.state.pageNum >= this.state.pdf.numPages)
            return;

		const targetPage = this.state.pageNum + 1;
		if(this.sliderRef.current){
			this.sliderRef.current.value = targetPage.toString();
		}

        this.queueRenderPage(targetPage);
    };

    private queueRenderPage = (num: number) => {
        this.setState({ pageNum: num });

        if (this.state.pageRendering)
            this.setState({ pageNumPending: num });
        else
            this.renderPage(num);
    };

    private renderPage = async (num: number) => {
        if (!this.state.pdf || !this.canvasRef.current || !this.wrapperRef.current)
            return;

        this.setState({ pageRendering: true });
        var page = await this.state.pdf.getPage(num);
        var viewPort = page.getViewport({ scale: this.state.scale })

        this.canvasRef.current.width = viewPort.width;
        this.canvasRef.current.height = viewPort.height;

        // this.canvasRef.current.style.width = "100%"
        // this.canvasRef.current.style.height = "100%"

        this.wrapperRef.current.style.width = "fit-content";
        this.wrapperRef.current.style.height = "fit-content";

        const canvasContext = this.canvasRef.current.getContext('2d');
        if (canvasContext) {
            var renderContext = {
                canvasContext: canvasContext,
                viewport: viewPort,
            }

            var renderTask = page.render(renderContext);
            renderTask.promise.then(() => {
                this.setState({ pageRendering: false });
                if (this.state.pageNumPending) {
                    this.renderPage(this.state.pageNumPending);
                    this.setState({ pageNumPending: null });
                }
            });
        }

    };
}