import React from "react";
import { Lang } from "../../managers/I18n";
import { Portal } from "../../utilities";
import { MobileNavLink } from "./MobileNavLink";
import "./MobileNavMenu.scss";
import { DocumentationTab } from "../products/ProductsDocumentationTab";
import { authProvider } from "../../auth/AuthProvider";

export interface IMobileNavMenuProps {
	isOpen: boolean;
	toggle: (isOpen: boolean) => void;
}

export const MobileNavMenu: React.FunctionComponent<IMobileNavMenuProps> = ({
	isOpen,
	toggle
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<Portal>
			<div className="mobile-nav-menu-outer">
				<div
					className="mobile-nav-menu-underlay"
					onClick={() => toggle(false)}
				/>
				<div className="mobile-nav-menu-side">
					<div className="mobile-nav-title">
						<strong>holo</strong>
						<span>maintenance</span>
					</div>
					<Lang>
						{getLanguageString => (
							<div className="layout-navmenu-body">
								<MobileNavLink
									onClick={() => toggle(false)}
									exact
									text={getLanguageString(
										"NavLink.Dashboard"
									)}
									iconName="dashboard"
									to="/"
								/>
								<MobileNavLink
									onClick={() => toggle(false)}
									text={getLanguageString("Products")}
									iconName="library_books"
									to="/products"
								/>
								<MobileNavLink
									onClick={() => toggle(false)}
									text={getLanguageString("Documentation")}
									iconName="description"
									to="/documentation"
								/>
								<MobileNavLink
									onClick={() => toggle(false)}
									text={getLanguageString("NavLink.CallLogs")}
									iconName="label"
									to="/tickets"
								/>
								<MobileNavLink
									onClick={() => toggle(false)}
									text={getLanguageString("NavLink.Settings")}
									iconName="settings"
									to="/settings"
								/>
								<MobileNavLink
									onClick={() => toggle(false)}
									text={getLanguageString("NavLink.Help")}
									iconName="help"
									to="/help"
								/>
								<div onClick={deleteCookie}>
									<MobileNavLink
										text={getLanguageString("NavLink.SignOut")}
										iconName="launch"
										to=""
									/>
								</div>
							</div>
						)}
					</Lang>
				</div>
			</div>
		</Portal>
	);
};


//TODO: Make global!!
const deleteCookie = () => {
	document.cookie = "connectionId=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; HasCheckedLicense=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "isReloaded=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "TemporaryToken=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	authProvider.logout();
};