import React from "react";
import { IQueueUser } from "../../types";
import { classNames, OriginalHtmlProps } from "../../utilities";
import { Icon } from "../globalComponents";
import "./incoming-call.scss";
import { GetLangString } from "../../managers/I18n";

export interface IIncomingCallProps extends OriginalHtmlProps<HTMLDivElement> {
	queueUser: IQueueUser;
}

export const IncomingCall: React.FunctionComponent<IIncomingCallProps> = ({ className, queueUser, ...rest }) => (
	<div {...rest} className={classNames("incoming-call-container", className)} title={GetLangString('Ticket.AnswerCall')}>
		<Icon className="incoming-call-user-icon" name="person" />
		<div className="incoming-call-text-container">
			<p>{`${queueUser.userName} (${queueUser.userAgent})`}</p>
			<span>{queueUser.productName}</span>
		</div>
		<Icon className="incoming-call-phone-icon" name="phone" />
	</div>
);
