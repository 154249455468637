import { merge, omit } from "ramda";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { IFile } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities/dictionary";
import { Action, actions } from "./files.actions";

export interface IState {
	error: boolean;
	deleting: boolean;
	loading: boolean;
	data: IDictionary<IFile>;
	dataForProduct: IDictionary<IFile>;
	weight: number;
	maxFileUploadWeight: number;
	maxWeight: number;
}

export const initialState: IState = {
	error: false,
	data: {},
	dataForProduct: {},
	deleting: false,
	loading: false,
	weight: -1,
	maxFileUploadWeight: 50000000,
	maxWeight: 20,
};

export const reducer: Reducer<IState> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.updateFile):
			return {
				...state,
			};
		case getType(actions.uploadFileThumbnailSuccess):
			console.log(`action.payload: ${action.payload.thumbnailUrl}`);
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: merge(state.data[action.payload.id], action.payload),
				}
			};
		case getType(actions.updateFileSuccess):
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: merge(state.data[action.payload.id], action.payload),
				},
			};
		case getType(actions.updateFileError):
			return {
				...state,
				error: true,
			};
		case getType(actions.activeCallFileUploadSuccess):
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.getFilesForProductSuccess):
			return {
				...state,
				error: false,
				loading: false,
				dataForProduct: makeDictionary(action.payload, "id"),
			};
		case getType(actions.getFilesForProduct):
			return {
				...state,
				error: false,
				loading: true,
			};
		case getType(actions.getFilesByCompanyIdSuccess):
		case getType(actions.getAllFilesSuccess):
			return {
				...state,
				data: makeDictionary(action.payload, "id")
			}
		case getType(actions.getFilesForProductError):
			return {
				...state,
				error: true,
				loading: false,
			};
		case getType(actions.onFileUploaded):
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.deleteFile):
			return {
				...state,
				deleting: true,
			};
		case getType(actions.deleteFileError):
			return { ...state, error: true, deleting: false };
		case getType(actions.deleteFileSuccess):
			return { ...state, data: omit([...action.payload], state.data), deleting: false };//return { ...state, data: omit([action.payload.toString()], state.data), deleting: false };
		case getType(actions.setFileOfficial):
			return { ...state, data: {} };
		case getType(actions.setFileOfficialError):
			return { ...state, error: true };
		case getType(actions.setFileOfficialSuccess):
			return { ...state, data: omit([action.payload.toString()], state.data) };
		case getType(actions.getFiles):
			return { 
				...state, 
				data: {}, 
				loading: true,
				error: false, 
			};
		case getType(actions.getFilesError):
			return { ...state, data: {}, error: true };
		case getType(actions.getFilesSuccess):
			return { 
					...state,
					error: false, 
					data: makeDictionary(action.payload, "id"),
					loading: false,
				};
		case getType(actions.getFilesWeightSuccess):
			return { ...state, weight: action.payload };
		case getType(actions.getFilesMaxWeightSuccess):
			return { ...state, maxWeight: action.payload };
		case getType(actions.getFilesMaximumSizeLimitUploadSuccess):
			return { ...state, maxFileUploadWeight: action.payload};
		default:
			return state;
	}
};
