import React from "react";
import Button from "reactstrap/lib/Button";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Table from "reactstrap/lib/Table";
import { T } from "../../managers/I18n";
import { Id, IFile } from "../../types";
import { getFileType } from "../../utilities";
import { logger } from "../../utilities/logger";
import { Spinner, GetIconComponent } from "../globalComponents";
import "./LinkDocumentModal.scss";

interface ILinkDocumentModalProps {
	files: IFile[];
	loading: boolean;
	isOpen: boolean;
	linkedFileIds: Id[];
	referenceLoading: string[];
	onCheck: (fileId: string) => void;
	toggle: () => void;
}

export class LinkDocumentModal extends React.Component<ILinkDocumentModalProps> {
	public render() {
		const { isOpen, toggle, loading, files } = this.props;

		return (
			<Modal className="products-link-document-modal" isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>
					<T v="ProductsProperties.LinkDocument" />
				</ModalHeader>
				<ModalBody className={"products-link-document-modal-body"}>
					{loading ? (
						<Spinner />
					) : (
						<Table>
							<thead>
								<tr>
									<th>
										<T v="ProductsProperties.Linked" />
									</th>
									<th>
										<T v="ProductsProperties.FilePreview" />
									</th>
									<th>
										<T v="ProductsProperties.Filetype" />
									</th>
									<th>
										<T v="ProductsProperties.Filename" />
									</th>
								</tr>
							</thead>
							<tbody>
								{files.sort(this.sortFilesByName).map(file => (
									<tr key={file.id}>
										<th scope="row">
											{this.isLoading(file.id) ? (
												<Spinner />
											) : (
												<input
													type="checkbox"
													onChange={() => this.props.onCheck(file.id)}
													checked={this.isFileLinked(file.id)}
												/>
											)}
										</th>
										<td>{GetIconComponent(file, "50")}</td>
										<td>{getFileType(file)}</td>
										<td>{file.name}</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={this.handleSave} color="primary">
						<T v="Done" />
					</Button>
					<Button onClick={this.props.toggle} color="secondary">
						<T v="Cancel" />
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	private sortFilesByName(a: IFile, b: IFile) : number {
		if (a.name.toLowerCase() > b.name.toLowerCase())
			return 1;
		else if (a.name.toLowerCase() < b.name.toLowerCase())
			return -1;
		else
			return 0;
	}

	private isLoading = (fileId: string) => {
		const { referenceLoading } = this.props;
		if (!referenceLoading) {
			return false;
		}

		return this.props.referenceLoading.indexOf(fileId) !== -1;
	};

	private handleSave = () => {
		logger.debug("Save clicked.");
		this.props.toggle();
	};

	private isFileLinked = (fileId: Id) => {
		const linkIndex = this.props.linkedFileIds.findIndex(i => i === fileId);
		return linkIndex !== -1;
	};
}
