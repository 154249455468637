import { StreamManager } from "openvidu-browser";
import * as React from "react";
import { VideoElementWithMediaStream } from "../../utilities";

export interface IVideo {
	streamManager: StreamManager;
	className?: string;
	onRemoteVideoRef?: (el: React.RefObject<VideoElementWithMediaStream>) => void;
	onLocalVideoRef?: (el: React.RefObject<HTMLVideoElement>) => void;
}

export class Video extends React.Component<IVideo> {
	public videoRef = React.createRef<HTMLVideoElement>();

	public componentDidUpdate(props: IVideo) {
		if (props && this.videoRef.current) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
		}
		if (this.props.onRemoteVideoRef) {
			this.props.onRemoteVideoRef(this.videoRef as React.RefObject<VideoElementWithMediaStream>);
		}
		if (this.props.onLocalVideoRef) {
			this.props.onLocalVideoRef(this.videoRef);
		}
	}

	public componentDidMount() {
		if (this.props && this.videoRef.current) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
		}
	}

	public render() {
		return <video className={this.props.className} autoPlay playsInline ref={this.videoRef} />;
	}
}
