export const eventname = Symbol("eventname");
export interface IEvent {
	target: any;
}

export interface IEventBase<T extends IEvent> {
	(event: T): void;
	[eventname]: string;
	add(listener: (event: T) => any): void;
	remove(listener: (event: T) => any): void;
}

export function newEvent<T extends IEvent>(name: string): IEventBase<T> {
	const funcs: Array<(event: T) => any> = [];

	// tslint:disable-next-line:only-arrow-functions
	const caller = (function(event: T): void {
		for (const func of funcs) {
			func(event);
		}
	} as any) as IEventBase<T>;
	caller[eventname] = name;

	caller.add = (listener: (event: T) => any) => {
		funcs.push(listener);
	};

	caller.remove = (listener: (event: T) => any) => {
		const index = funcs.indexOf(listener);
		if (index >= 0) {
			funcs.slice(index, 1);
		}
	};
	return caller;
}
