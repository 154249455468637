import React from "react";
import { Container, Row } from "reactstrap";
import { T } from "../../managers/I18n";
import "./LoadingPage.scss";
import { Spinner } from "./Spinner";

export const LoadingPage: React.FunctionComponent<{}> = () => (
	<Container>
		<Row>
			<div className="loading-container">
				<Spinner />
				<h2>
					<T v="Loading" />
				</h2>
			</div>
		</Row>
	</Container>
);
