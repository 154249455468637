import React from "react";
import Input from "reactstrap/lib/Input";
import { ILicenseNumber } from "../../types/ILicense";
import moment from "moment";
import { GetLangString, T } from "../../managers/I18n";
import { Card, CardBody, CardDeck, CardGroup, Col, Label, Row } from "reactstrap";
import { IRootState } from "../../reducers";
import { connect } from "react-redux";
import "./LicenseHandler.scss";

interface ILicenseHandlerProps extends StateProps {
    licenses: ILicenseNumber[],
    editMode: boolean,
    handleChange: (fieldName: keyof ILicenseNumber) => (event: React.ChangeEvent<HTMLInputElement>) => void;

}

interface ILicenseHandlerState {
    licenses: ILicenseNumber[],
    showLicenses: boolean | number,
}

class LicenseHandlerBase extends React.Component<ILicenseHandlerProps, ILicenseHandlerState> {
    constructor(props: ILicenseHandlerProps) {
        super(props);

        this.state = {
            licenses: [],
            showLicenses: true,
        };

    }

    // private firstLicense: ILicenseNumber = { companyId: "0", totalLicenses: "0", renewalMonths: "0", startDate: "0", endDate: "0" };

    componentDidUpdate(prevProps: ILicenseHandlerProps) {
        if (prevProps.licenses.length !== this.props.licenses.length) {
            // this.extractLastElement(this.props.licenses);
            this.setState({ licenses: this.props.licenses });
        }
    }

    render() {
        console.log("[RENDER] props " + this.props.licenses.length);
        console.log("[RENDER] state " + this.state.licenses.length);
        return (
            <>
                {this.props.licenses.length !== 0 ?
                    <Col className="licenses-list">
                        {/* <i  style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showLicenses ? 1 : -1) + ')', visibility:"hidden"}} className="material-icons md-24">expand_less</i> */}
                        {/* <span onClick={this.handleToggleLicenses}><T v={this.state.showLicenses ? "Minimize" : "Expand"} /></span> */}
                        {
                            <div className="scrollable-content custom-scrollbar">{
                                this.state.showLicenses &&
                                this.state.licenses.map((license, index) => {
                                    return (
                                        index <= this.props.prevLicenses.length - 1 &&
                                        <div className="card-height">{
                                            this.showCardLicense(license, index)
                                        }
                                        </div>

                                    )
                                }
                                )
                            }</div>
                        }
                    </Col> : <div><T v={"Companies.NoLicenses"} /></div>
                }

            </>
        )
    }


    private showCardLicense = (license: ILicenseNumber, index: number) => {
        return (
            <Card>
                <div className="license-card">
                    <CardBody>
                        <Label><T v={"Companies.LicenseInformation"} /></Label><Input id={`${index.toString()}`} disabled={index <= this.props.prevLicenses.length - 1} type="text" placeholder="" onChange={this.handleChange("totalLicenses")} value={license.totalLicenses} />
                        <Label><T v={"Companies.RenewalMonths"} /></Label><Input id={`${index.toString()}`} disabled={this.props.editMode} type="text" placeholder="" onChange={this.handleChange("renewalMonths")} value={license.renewalMonths} />
                        <Label><T v={"Companies.CreationDate"} /></Label><Input id={`${index.toString()}`} disabled={index <= this.props.prevLicenses.length - 1} type="date" onChange={this.handleChange("startDate")} value={moment(license.startDate).format("YYYY-MM-DD")} />
                        <Label><T v={"Companies.ExpirationDate"} /></Label><Input id={`${index.toString()}`} disabled={index <= this.props.prevLicenses.length - 1} type="date" onChange={this.handleChange("endDate")} value={moment(license.endDate).format("YYYY-MM-DD")} />
                    </CardBody>
                </div>
            </Card >
        )
    }

    private handleChange = (fieldName: keyof ILicenseNumber) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { licenses } = this.state;
        const license = licenses[parseInt(event.target.id)];
        license[fieldName] = event.target.value;
        this.setState({ licenses: licenses });

        this.props.handleChange(fieldName);
    };
}

const mapStateToProps = (state: IRootState) => ({
    prevLicenses: state.companies.licenses
});

type StateProps = ReturnType<typeof mapStateToProps>;

const LicenseHandler = connect(mapStateToProps, {})(LicenseHandlerBase);

export default LicenseHandler;