import { compose } from "ramda";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, Router } from "react-router";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../managers/I18n";
import { UserRole } from "../../types/IRoles";
import { ITroubleshootingFlatNode, NodeType } from "../../types/ITroubleshootingNode";
import { defaultPreventer, ensureTrailingSlash, OriginalHtmlProps, propagationStopper } from "../../utilities";
import { Icon } from "../globalComponents/Icon";
import { IconButton } from "../globalComponents/IconButton";
import { getDepthStyles, getIconName, getLanguageText } from "./Troubleshooting.Utilities";

export interface ITroubleshootingNodeProps extends OriginalHtmlProps<HTMLDivElement>, RouteComponentProps<{ id: string }> {
	userRole: UserRole;
	depth: number;
	node: ITroubleshootingFlatNode;
	onDeleteClicked: (nodeId: number) => void;
	onDeselect: () => void;
	isSelected: boolean;
	languageKey: string;
	nodeList: any;
}

const TroubleshootingNodeBase: React.FunctionComponent<ITroubleshootingNodeProps> = ({
	match,
	node,
	isSelected,
	depth,
	location,
	history,
	languageKey,
	onClick,
	onDeselect,
	onDeleteClicked,
	staticContext,
	userRole,
	nodeList,
	...rest
}) => {
	// const lang = navigator.language.substring(0, 2);
	const lang = getCurrentLanguage();

	const onClickInternal = (e: React.MouseEvent<HTMLDivElement>) => {
		if (userRole === UserRole.Operator || userRole === UserRole.Expert) {
			var found = 0;
			for (var i = 1; i <= Object.keys(nodeList).length; i++) {
				if (node != null && nodeList[i] != undefined && nodeList[i].type === NodeType.ANSWER && nodeList[i].parentId === node.id) {
					found = nodeList[i].id;
				}
			}
			if (found != 0) {
				history.push(`${window.location.pathname}/${found}`);
			}
		}
		if (userRole === UserRole.CompanyAdmin || userRole === UserRole.SuperAdmin) {
			if (isSelected) {
				return onDeselect();
			}

			return onClick!(e);
		}
	};
	if (userRole === UserRole.Operator || userRole === UserRole.Expert) {
		if (node.type === NodeType.QUESTION) {
			return (
				<div
					{...rest}
					onClick={onClickInternal}
					className={`troubleshooting-node ${isSelected ? "active" : ""}`}
					style={getDepthStyles(depth)}
				>
					<Icon className={"node-icon"} name={getIconName(node.type)} />

					<span className={"node-text"}>{getLanguageText(node.text, lang)[0]}</span>
				</div>
			);
		}
	} else {	
	return (
		<div {...rest} onClick={onClickInternal} className={`troubleshooting-node ${isSelected ? "active" : ""}`} style={getDepthStyles(depth)}>
			<Icon className={"node-icon"} name={getIconName(node.type)} />
			<span className={"node-text"} >{getLanguageText(node.text, lang)[0]}</span>
			<div className="troubleshooting-node-actions">
				{userRole === UserRole.CompanyAdmin && (
					<IconButton
						onClick={compose(() => onDeleteClicked(node.id), propagationStopper, defaultPreventer)}
						className="troubleshooting-node-actions-button"
						iconName="delete"
					/>
				)}
				{node.type === NodeType.ANSWER && (
					<Link to={ensureTrailingSlash(match.url) + node.id}>
						<IconButton className="troubleshooting-node-actions-button" iconName={getIconType(userRole)} />
					</Link>
				)}
			</div>
		</div>
	);
}
return <div />;
};

const getIconType = (userRole: UserRole): string => {
	switch (userRole) {
		case UserRole.Expert:
			return "visibility";
		case UserRole.CompanyAdmin:
			return "edit";
		default:
			return "visibility";
	}
};

export const TroubleshootingNode = withRouter(TroubleshootingNodeBase);
