import React from "react";
import { OriginalHtmlProps } from "../../utilities";
import "./SaveOverlay.scss";
import { Spinner } from "./Spinner";

export interface ISaveOverlayProps extends OriginalHtmlProps<HTMLDivElement> {
	text?: string;
}

export const SaveOverlay: React.FunctionComponent<ISaveOverlayProps> = ({ text = "Loading...", ...rest }) => (
	<div {...rest} className="save-overlay-container">
		<Spinner className="save-overlay-spinner" />
		<div className="save-overlay-text">{text}</div>
	</div>
);
