import { userInfo } from "os";
import React from "react";
import { connect } from "react-redux";
import Badge from "reactstrap/lib/Badge";
import { Spinner } from ".";
import { IRootState } from "../../reducers";
import { IUser } from "../../types";
import { IQueueUser } from "../../types/IQueueUser";
import { toArray } from "../../utilities";

type IQueueBadgeProps = StateProps;

const filterQueue = (queue: any, userInfo: IUser, users: IUser[]): IQueueUser[] =>
{
	var queueFiltered = getFilteredQueue(queue, userInfo, users);
	//this.props.listUpdate(queueFiltered);
	return queueFiltered;//getFilteredQueue(this.props.queue, this.props.loggedUser, this.props.users);
}

export function getFilteredQueue(queue: IQueueUser[], loggedUser: IUser, users: IUser[] = []): IQueueUser[]
{
	const isUserVisibleFilter = (queueUser: IQueueUser): boolean =>
	{
		const user = users.find(x => x.id === queueUser.userId);
		const isSameTeam = (loggedUser.teamId === queueUser.teamId) || (user !== undefined && user.isTemporary);
		const isVisible = user !== undefined && (!user.isTemporary || (user.isTemporary && user.createdBy == loggedUser.id));
		//if (user) console.log(user.isTemporary + " - " + user.createdBy);

		return isVisible && isSameTeam;
	};
	console.log(`[foo] queue: ${queue.length}`);
	const filteredQueue = queue.filter((queueUser, index) => queue.indexOf(queueUser) === index && isUserVisibleFilter(queueUser)); // duplicates check
	return filteredQueue;
}

const QueueBadgeBase: React.FunctionComponent<IQueueBadgeProps> = ({ connecting, queue, userInfo, users }) =>
	connecting ? <Spinner /> : <Badge color="primary">{filterQueue(queue, userInfo, users).length.toString()}</Badge>;

const mapStateToProps = (state: IRootState) => ({
	users: toArray(state.users.data),
	userInfo: state.userInfo.data,
	connecting: state.callQueue.connecting,
	queue: state.callQueue.queue,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const QueueBadge = connect(mapStateToProps)(QueueBadgeBase);
