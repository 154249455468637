import * as React from "react";
import Button from "reactstrap/lib/Button";
import { BROWSER_WARNING } from "../../constants";
import { Message } from "../../managers/I18n";
import { isEdgeOrIe } from "../../utilities";
import { logger } from "../../utilities/logger";
import "./BrowserWarning.scss";
import { Icon } from "./Icon";
import { LanguageToggler } from "./LanguageToggler";

function shouldShowBrowserWarning() {
	try {
		const previouslyCancelled = window.localStorage.getItem(BROWSER_WARNING);
		if (previouslyCancelled) {
			return false;
		}

		return isEdgeOrIe();
	} catch (err) {
		logger.warn("Error while fetching browser warning from localStorage");
		return false;
	}
}

export const BrowserWarning: React.FunctionComponent = () => {
	const [isVisible, setVisible] = React.useState(shouldShowBrowserWarning());
	const hide = () => {
		try {
			window.localStorage.setItem(BROWSER_WARNING, "true");
		} catch (err) {
			logger.warn("Error while setting browserWarning key to true in localStorage.");
		}

		setVisible(false);
	};

	if (!isVisible) {
		return null;
	}

	return (
		<div className="browser-warning-container">
			<div className="browser-warning-language-toggler-container">
				<LanguageToggler />
			</div>
			<div className="browser-warning-icon">
				<Icon name="warning" />
			</div>
			<div className="browser-warning-title">
				<Message id="BrowserWarning.Title" />
			</div>
			<div className="browser-warning-body">
				<Message id="BrowserWarning.Body" />
			</div>
			<div className="browser-warning-compatible-browsers">
				<a href="https://www.google.com/chrome/" className="browser-warning-browser-link">
					<img src="https://www.google.com/chrome/static/images/chrome-logo.svg" />
					<span>Google Chrome</span>
				</a>
				<a href="https://www.mozilla.org/firefox/download/thanks/" className="browser-warning-browser-link">
					<img src="https://www.mozilla.org/media/img/logos/firefox/logo-quantum.9c5e96634f92.png" />
					<span>Mozilla Firefox</span>
				</a>
			</div>
			<div className="browser-warning-continue">
				<Message id="BrowserWarning.Continue" />
			</div>
			<div className="browser-warning-continue-button-container">
				<Button onClick={hide} color="primary">
					<Message id="BrowserWarning.ContinueButton" wrap={false} />
				</Button>
			</div>
		</div>
	);
};
