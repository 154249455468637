import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { serverManager } from "../../managers/ServerManager";
import { logger } from "../../utilities/logger";
import { actions } from "./troubleshooting-languages.actions";

export function* handleFetch(action: ReturnType<typeof actions.fetch>) {
	try {
		const data = yield call(serverManager.troubleshooting.languages.get);
		yield put(actions.fetchSuccess(data));
	} catch (err) {
		logger.error(err);
		yield put(actions.fetchError("Something went wrong."));
	}
}

export function* troubleshootingLanguagesSaga() {
	yield takeEvery(getType(actions.fetch), handleFetch);
}
