import { ITeam } from "../../types/ITeam";
import { OriginalHtmlProps } from "../../utilities/html-props";
import { classNames } from "../../utilities/class-names";
import { Icon } from "../globalComponents";
import "./TeamListItem.scss";
import React from "react";

type IProp = {
	team: ITeam;
} & OriginalHtmlProps<HTMLDivElement>;

export const TeamListItem: React.FunctionComponent<IProp> = ({ className, team, ...rest }) => {
    return (
        <div className={classNames(className, "team-item-row")} {...rest}>
            <Icon name={"business"} />
			<span className={"team-item-row-name"}>{team.name}</span>
		</div>
    );
};