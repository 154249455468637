export const languageData: { [lang: string]: any } = {
	it: {
		Id: "it-IT",
		Modal: {
			AuthCode: "Copia il codice seguente nell'header della richiesta",
			AuthEntryPoint: "Inserisci l'entry point del tuo client",
			Ok: "Ok",
			Cancel: "Annulla",
			DeleteUser: "Sei sicuro di voler eliminare questo utente: ",
			DeleteTeam: "Sei sicuro di voler eliminare questo team: ",
			BlockUser: "Sei sicuro di voler bloccare questo utente: ",
			UnblockUser: "Sei sicuro di voler sbloccare questo utente: ",
			UserLink: "Nuovo link utente generato - tutti i link precedenti sono invalidi.",
			Copy: "Copia negli appunti",
			LinkToProduct: "Collega a Prodotto",
			Redirect: "Indirizza a",
			EnterSignature: "Inserisci firma"
		},
		Sort: {
			Name: "Nome",
			Date: "Data",
			Type: "Tipo",
			Official: "Ufficiali",
			ShowOfficial: "Visualizzando solo file ufficiali",
			ShowAll: "Visualizzando tutti i file",
			FilesAmount: "Numero file: ",
			DiskSpace: "Spazio disco: ",
		},
		Toast: {
			ConnectWithAnotherAccount: "Oppure connettiti con un altro account ",
			UnlicensedUserLogoutMessage: "Non hai una licenza valida, contatta l'admin della tua company",
			ErrorHeader: "Errore:",
			ContactAdminError: "Contatta un admin dell'azienda per accedere",
			TicketFileLoading: "Un file è ancora in caricamento, attendere",
			DocumentUploadedSuccess: "Documento caricato nel ticket con successo",
			UserIsBlocked: "Questo account è bloccato",
			OpenNavMenu: "Apri/chiudi menu di navigazione",
			ClickToModify: "Clicca per modificare",
			ChangeRoleButton: "Cambia ruolo",
			TicketMergeSuccessful: "Ticket uniti correttamente",
			TicketMergeFailed: "Errore durante l'unione dei ticket",
			TicketDeleteSuccessful: "Ticket eliminato con successo",
			TicketDeleteFailed: "Cancellazione ticket fallita",
			TicketsReportRequestSuccessful: "Report prodotto richiesto con successo",
			TicketsReportRequestFailed: "Il report richiesto non è ancora completato",

			Error: "Errore",
			Info: "Info",
			Warning: "Attenzione",
			Success: "Completato",
			UserTempSuccess: "Utente temporaneo creato con successo.",
			UserTempError: "Impossibile creare utente temporaneo.",
			UserTokenSuccess: "Accesso utente generato con successo.",
			UserTokenError: "Impossibile generare accesso per l'utente.",
			UserDeletedSuccess: "Utente eliminato con successo.",
			UserDeletedError: "Impossibile eliminare utente.",
			UserBlockSuccess: "Utente bloccato con successo.",
			UserBlockError: "Impossibile bloccare utente.",
			UserUnblockSuccess: "Utente sbloccato con successo.",
			UserUnblockError: "Impossibile sbloccare utente.",
			AllowAccessToTemporaryUsers: "Consenti accesso agli utenti temporanei",
			TeamCreationSuccess: "Gruppo creato con successo.",
			TeamCreationError: "Creazione gruppo fallita.",
			TeamEditSuccess: "Gruppo aggiornato con successo.",
			TeamEditError: "Aggiornamento gruppo fallito.",
			TeamDeletedSuccess: "Gruppo eliminato con successo.",
			TeamDeletedError: "Eliminazione gruppo fallita.",
			UploadingLogoError: "Caricamento del logo fallito",
			LicenseExpiration: "Licenza in scadenza",
			LicenseExpirationInfo: "La tua licenza scadrà tra ",
			Expired: "La tua licenza è scaduta. Non potrai più accedere a questo account a breve.",
			Day: "giorno",
			Days: "giorni",
			DontShowAgain: "Non mostrare più",

			TicketUpdated: "Ticket aggiornato con successo.",
			TicketUpdatedFailed: "Aggiornamento ticket fallito.",
			CompanyCreeated: "Azienda creata con successo.",
			CompanyCreeatedFailed: "Creazione azienda fallita.",
			CompanyUpdated: "Azienda aggiornata con successo.",
			CompanyUpdatedFailed: "Aggiornamento azienda fallito.",
			CompanyDeleted: "Azienda eliminata con successo.",
			CompanyDeletedFailed: "Eliminazione azienda fallita.",

			UserEditSuccess: "Utente aggiornato con successo.",
			UserEditError: "Impossibile aggiornare utente.",
			UserResetSuccess: "Credenziali utente ripristinate con successo.",
			UserResetError: "Impossibile ripristinare credenziali utente.",
			HintTitle: "Suggerimento:",
			HintBodyPositionModel: "Seleziona il modello nella finestra dei file",
			HintBodyPositionModelPart1: "Posizionalo sullo screenshot",
			HintBodyPositionModelPart2: "Premi il pulsante in alto a destra per inviarlo all'operatore",
			HintBodyAR: "Punta la camera ad una superficie per <br> far comparire il cerchio <br> Tocca lo schermo per posizionare il modello",
			HintBody: "Usa due dita per muovere il gemello digitale",
			HintBodyPart1: "Un dito per ruotarlo",
			HintBodyPart2: "Pinch per lo zoom",
			SaveClipsDataSuccess: "Dati animazione salvati con successo.",
			SaveClipsDataFail: "Errore nel salvataggio dei dati animazione.",
			LogoutMessage: "Hai effettuato l'accesso su un altro computer. Stai per essere disconnesso.",
			MobileMessageHeader: "Avviso",
			MobileMessageBody: "Suoni abilitati",
			AppCreated: "Applicazione creata con successo.",
			AppCreateFailed: "Creazione applicazione fallita.",
			AppRemoved: "Applicazione eliminata con successo.",
			AppRemoveFailed: "Eliminazione applicazione fallita.",
			IosMessageError: "Utilizza Safari come browser se vuoi effettuare la chiamata",
		},
		BrowserWarning: {
			Alert: "Il browser che stai usando potrebbe non essere supportato. Per favore, usa Chrome su PC o Safari su MacOS/iOS.",
			Title: "Browser incompatibile.",
			Body: "Il browser in utilizzo non supporta le video chiamate. Per favore, installa uno dei browser sotto elencati.",
			Continue: "Se desideri lo stesso procedere, premi il pulsante in fondo alla pagina.",
			ContinueButton: "Continua",
		},
		PrivacyPolicy: {
			CookieTitle: "Accetta cookies",
			CookieText: "INFORMATIVA PER ACCETTARE COOKIE. "
				+ "INFORMATIVA PER ACCETTARE COOKIE. "
				+ "INFORMATIVA PER ACCETTARE COOKIE. ",
			ReadPrivacyPolicy: "Leggi l'informativa sulla privacy",
			PrivacyTitle: "Informativa sulla privacy",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "Accetta e procedi",
			Exit: "Esci",
			Download: "Scarica",
			Back: "Indietro",
		},
		Cancel: "Annulla",
		Done: "Completato",
		Select: "Seleziona",
		RemoveLogo: "Rimuovi Logo",
		LastModification: "Ultima modifica",
		Rotation: "Rotazione",
		ZMove: "Muovi in profondità",
		XMove: "Muovi in orizzontale",
		YMove: "Muovi in verticale",
		ObjectSettings: "Impostazioni modello",
		ActiveCall: {
			WaitForLoading: "Attendi che il video precedente venga caricato",
			UnlockExpert: "Sblocca esperto",
			SelectExpert: "Seleziona esperto",
			RotatePhone: "Ruota il telefono in modalità landscape",
			NoTimeRemaining: "Minuti esauriti. Non puoi rispondere alle chiamate",
			OpenCallHeader: "Richiedi supporto",
			OpenCallButtonCompany: "Supporto company",
			OpenCallButtonTeam: "Supporto team",
			OpenCallBody: "Desideri aprire la chiamata?",
			ImageCaptured: "Immagine schermo salvata.",
			VideoCaptureStart: "Registrazione schermo avviata",
			VideoCaptureAdded: "Aggiunto evento registrazione schermo.",
			VideoUploaded: "Video caricato.",
			FileSent: "File inviato all'Operatore",
			FileUploadedAndSent: "File caricato e inviato all'Operatore",
			AnnotationUploaded: "Annotazioni caricate sul registro.",
			FailedToAddCallLog: "Impossibile aggiungere evento al registro chiamate",
			EndCall: "Chiudi chiamata",
			ShowCamera: "Mostra video-camera",
			Mute: "Muta microfono",
			StartRecording: "Inizia registrazione",
			Unmute: "Smuta microfono",
			FloatingWindow: "Finestra flottante",
			ToggleFullscreen: "Attiva/Disattiva schermo intero",
			ToggleLocation: "Crea annotazioni",
			ToggleProductInfo: "Attiva/Disattiva informazioni prodotto",
			SendingFile: "Invio di file",
			Call: "Chiama",
			HideCamera: "Nascondi Camera",
			ScreenShot: "Effettua uno screenshot",
			SwitchCamera: "Cambia Camera",
			ChangeVisualization: "Cambia Visualizzazione",
			WebcamUnabled: "Non hai permesso l'utilizzo della webcam",
			AnnotationConfirm: "Conferma",
			AnnotationUndo: "Annulla",
			AnnotationDraw: "Disegna",
			AnnotationCircle: "Cerchio",
			AnnotationArrow: "Freccia",
			AlertHeader: "Upload dei file",
			AlertBody: "Uscirai dalla chiamata non appena i file saranno caricati sul server.",
			SnapshotReceived: "Snapshot ricevuto da Operatore",
			EnableGenericExpertUser: "Richiedi supporto",
			CompanyHelp: "Sblocca Company",
			TeamHelp: "Sblocca Team",
			SelectProduct: "Cambia prodotto",
			OpenToGeneric: "Supporto richiesto",
			OpenToTeam: "Supporto del team richiesto",
			ChangeResolution: "Cambia risoluzione",
			PositionModel: "Posiziona modello",
			ModelOpenToast: "Il modello è stato aperto",
			ModelPositioningToast: "Il modello è stato posizionato",
			ShareScreen: "Condividi schermo",
		},
		CompanyProperties: {
			CompanyName: "Nome azienda",
		},
		Error: "C'è stato un errore.",
		Home: "Schermata principale",
		TestString: "Testo di prova",
		TestHeader: "Titolo di prova",
		Test: {
			String: "Testo di prova",
			Header: "Titolo di prova",
		},
		Languages: {
			en: "Inglese",
			it: "Italiano",
		},
		Search: "Cerca",
		Save: "Salva",
		Delete: "Elimina",
		ResendCredentials: "Reinvia credenziali",
		Credentials: "Credenziali",
		Resend: "Reinvia",
		Saving: "Salvataggio in corso...",
		Loading: "Caricamento...",
		WebVideo: "Video Web",
		VideoChat: "Video Chat",
		Products: "Prodotti",
		ProductsList: "Lista Prodotti",
		Submit: "Sottoscrivi",
		AdminDetails: "Dettagli Admin",
		CompanyDetails: "Dettagli Azienda",
		SubscriberInfo: "Dettagli Sottoscrittore",
		BoostrapCSS: "Bootstrap CSS",
		ProductsProperties: {
			QRCode: "QR-Code",
			QRCodeDescription: "Applica questo QR-Code sul prodotto fisico e scansionalo da una app Holo-Maintenance dopo aver effettuato l'accesso come Operatore per accedere velocemente a questo prodotto",
			Code: "Codice",
			Created: "Creato",
			Documentation: "Documentazione",
			EditedBy: "Modificato da",
			CallLog: "Registro chiamate",
			New: "Nuovo prodotto",
			LinkDocument: "Allega documento",
			LinkTeam: "Allega al gruppo",
			LinkProduct: "Allega a prodotto",
			MakeOfficial: "Rendi ufficiale",
			Linked: "Allegato",
			TeamName: "Nome gruppo",
			Filetype: "Tipo file",
			Filename: "Nome file",
			FilePreview: "Anteprima",
			NotFound: "Prodotto non trovato",
			UploadDocument: "Carica documento",
			NoProducts: "Nessun prodotto",
			Troubleshooting: "Risoluzione problemi",
			ProductInformation: "Informazioni prodotto",
			ProductCode: "Codice prodotto",
			ProductDescription: "Descrizione",
			ProductImage: "Immagine prodotto",
			ProductName: "Nome prodotto",
			ProductShortName: "Nome (breve) prodotto",
			ProductTeams: "Nei gruppi: ",
			ProductTeam: "Nel gruppo: ",
			LastUpdated: "Ultimo aggiornamento",
			NoProductLinked: "Nessuno",
			GenericProductLinked: "Generico",
		},
		Documentation: "Documentazione",
		DocumentationModal: {
			Title: "Elimina file",
			Text: "Stai per eliminare questi file: ",
		},
		DocumentationDetails: {
			ReloadModel: "Ricarica informazioni",
			TakeData: "Dati animazione: ",
			ClipsAmount: "Numero animazioni: ",
			UploadError: "Qualcosa è andato storto durante il caricamento del file",
			UploadErrorSizeFile: "File troppo grande",
			SearchPlaceholder: "Cerca un file...",
			Rejected: "è stato rifiutato: dimensione del file troppo grande o tipo non supportato.",
			Details: "Dettagli",
			Name: "Nome file: ",
			Type: "Tipo file: ",
			Size: "Dimensione file: ",
			Added: "Aggiunto: ",
			LinkedProducts: "Allegato a: ",
			SelectedFiles: "File selezionati: ",
			Deleting: "Eliminazione in corso...",
			TakeNumber: "Animazione ",
			TakeText: "Testo visivo: ",
			TakeSound: "Testo parlato: ",
			PreviewTakeSound: "Anteprima audio",
			StopPreviewTakeSound: "Ferma anteprima",
			CopyPreviewTakeSound: "Genera",
			CopyPreviewTakeSounds: "Genera tutti gli audio",
			SaveTakeData: "Salva dati animazione",
		},
		Troubleshooting: {
			Answer: "Risposta",
			AnswerPlaceholder: "Scrivi qui la risposta...",
			EditQuestion: "Modifica domanda",
			NewQuestion: "Nuova domanda",
			NewAnswer: "Nuova risposta",
			AddAnswer: "Aggiungi risposta",
			SaveAnswer: "Salva risposta",
			AddDocument: "Aggiungi documento",
			Completed: "Soluzione problema raggiunta"
		},
		TroubleshootingLanguages: {
			SelectedTextSingular: "1 lingua selezionata",
			SelectedTextPlural: "lingue selezionate",
			SelectedTextNone: "Nessuna lingua selezionata",
			NoneAvailable: "Nessuna lingua selezionata",
		},
		WorkLog: {
			Title: "Registro Chiamate",
		},
		Ticket: {
			CreateNewTicket: "Crea un nuovo ticket",
			AddToSummary: "Aggiungi al riassunto",
			AddToSummaryPlaceholder: "INSERISCI IL TESTO CHE VUOI AGGIUNGERE AL RIASSUNTO...",
			MergeDeleteError: "Puoi eseguire questa operazione solo sui tuoi ticket",
			OperatorPosition: "Posizione operatore catturata",
			OperatorPositionDescription: "L'operatore è localizzato a (latitudine, longitudine): ",
			MergeHeader: "Unione ticket",
			MergeBody: "Gli eventi dei due ticket potrebbero sovrapporsi",
			DeleteHeader: "Cancellazione ticket",
			DeleteBody: "I ticket selezionati saranno rimossi",
			LastModificationDate: "Data di ultima modifica",
			CannotModifySummary: "Puoi solo aggiungere testo",
			OperatorNameChange: "Il nome dell'operatore può essere modificato solo dall'autore del ticket",
			ReceiveCallLogViaMail: "Spedizione report",
			ReceiveCallLogViaMailBody: "Il report sarà inviato via e-mail",
			SendMailReport: "Invia report",
			ExportPdf: "Esporta (PDF)",
			ExportPdfNoImage: "Esporta (PDF no immagini)",
			ExportCsv: "Esporta (CSV)",
			ProductReport: "Richiedi Report",
			Save: "Salva",
			Cancel: "Cancella",
			Submit: "Cerca",
			SearchProduct: "Cerca prodotti",
			ProductName: "Nome prodotto",
			ProductCode: "Codice prodotto",
			CompanyName: "Nome azienda",
			LastUpdated: "Ultimo aggiornamento",
			CompanyCode: "Codice azienda",
			DateCreated: "Data di creazione",
			OperatorName: "Nome operatore",
			Status: "Stato",
			ExpertName: "Nome esperto",
			Summary: "Riassunto",
			Timeline: "Cronologia degli eventi",
			Export: "Esporta",
			FilterByDate: "Filtra per data",
			TicketCreatedSuccess: "Ticket creato con successo",
			TicketCreatedFailed: "Ticket annullato",
			DocumentUploaded: "Documento caricato nel ticket",
			Login: "Sessione avviata",
			SessionEnded: "Fine sessione",
			Enqueue: "In coda",
			TroubleshootingTreeClose: "Azione",
			TroubleshootingTreeOpen: "Azione",
			TroubleshootingTreeReset: "Azione",
			AnswerCall: "Risposta",
			VideoCapturedStarted: "Registrazione video avviata",
			FileOpen: "Documento aperto",
			FileSent: "Documento inviato",
			SignatureCaptured: "Firma salvata",
			ImageCaptured: "Immagine catturata",
			VideoCapturedEnded: "Video registrato",
			AnnotationSent: "Azione",
			ModelOpen: "File 3D aperto",
			EndedCall: "Chiamata chiusa",
			ProductDownload: "Prodotto scaricato",
			AnnotationDeleted: "Azione",
			ParticipantJoin: "Un esperto entra in chiamata",
			ModelPosition: "Modello 3D riposizionato",
			ProductChanged: "Prodotto cambiato",
			CallPublished: "Richiesta ulteriore esperto",
			CallPublishedTeam: "Richiesta ulteriore esperto del proprio gruppo",
			LoginDescription: "L'Utente ha effettuato l'accesso.",
			SessionEndedDescription: " ",
			ParticipantJoinDescription: "L'esperto entra in chiamata: ",
			EnqueueDescription: "In coda, in attesa della connessione di un esperto remoto...",
			TroubleshootingTreeCloseDescription: "Raggiunta la fine dell'albero risoluzione problemi, apertura di un file.",
			TroubleshootingTreeOpenDescription: "Albero risoluzione problemi inizializzata.",
			TroubleshootingTreeResetDescription: "Albero risoluzione problemi ripristinato.",
			AnswerCallDescription: "Connessione con un esperto remoto avvenuta.",
			VideoCapturedStartedDescription: "L'esperto remoto ha avviato una registrazione video.",
			FileOpenDescription: "Navigazione: ",
			FileSentDescription: "Ricevendo il file: ",
			FileSentFromDescription: "Ricevendo il file da ",
			SignatureCapturedDescription: "Firma autorizzazione salvata",
			ImageCapturedDescription: "L'esperto remoto ha salvato un'immagine dello schermo",
			VideoCapturedEndedDescription: "Registrazione sessione terminata",
			AnnotationSentDescription: "L'esperto remoto ha inviato un'annotazione",
			ModelOpenDescription: "Scaricamento modello 3D: ",
			EndedCallDescription: " ",
			ProductDownloadDescription: "Dati prodotto scaricati",
			ProductChangedDescription: "L'esperto ha cambiato il prodotto a ",
			AnnotationDeletedDescription: "Annotazioni eliminate dall'esperto",
			VideoCaptureDescription: "L'esperto remoto ha iniziato una registrazione video.",
			DocumentSentDescription: "Navigazione: ",
			NoTickets: "Nessun ticket da visualizzare",
			TicketStatusChanged: "Modificato stato del ticket",
			TicketStatusChangedDescription: "Lo stato è cambiato in ",
		},
		TicketStatus: {
			SelectStatus: "Seleziona status",
			Open: "Aperto",
			Working: "Funziona",
			WaitingForFeedback: "In attesa di feedback",
			Solved: "Risolto",
			ClosedButNotSolved: "Chiuso ma non risolto",
			NeedFurtureActivity: "È richiesta attività futura",
			NoNeedToSolved: "Nessuna soluzione necessaria",
		},
		Upload: {
			UploadingConfirmation: "Il logo è stato caricato",
			Drag: "Trascina un file qui per caricarlo",
			Drop: "Rilascia il file per iniziare il caricamento",
		},
		Call: {
			Mute: "Mutati",
			Hangup: "Chiudi",
			SendClick: "Invia click",
		},
		CallLogList: {
			Product: "Prodotto",
			Selection: "Selezione",
			TicketID: "ID Ticket",
			Started: "Avviato",
			Operator: "Operatore",
			Expert: "Esperto",
			Status: "Stato",
			Summary: "Descrizione",
		},
		UserManagement: "Gestione utente",
		UserType: {
			Operator: "Operatore",
			CompanyAdmin: "	Amministratore azienda",
			Expert: "	Esperto remoto",
			SuperAdmin: "Super Administrator",
			Root: "Root",
		},
		Users: {
			IsLinked: "È collegato",
			Move: "Sposta",
			LoggedUserError: "Non hai i permessi per creare utenti",
			CompanyDomainError: "Non è stato possibile prelevare il dominio della company",
			EmailError: "Indirizzo mail non valido",
			FirstNameError: "Nome non valido",
			LastNameError: "Cognome non valido",
			PhoneError: "Numero di telefono non valido",
			RoleError: "Ruolo non valido",
			UserNameEmpty: "Nome utente vuoto",
			IllegalCharacterInUsername: "Caratteri non validi per il nome utente",
			UsernameAlreadyExists: "Nome utente esistente",

			AddAuthCode: "Genera chiave d'accesso API",
			Code: "Codici di autorizzazione",
			AddUser: "Crea utente",
			AddTempUser: "Crea utente temporaneo",
			UserName: "Username",
			FirstName: "Nome",
			LastName: "Cognome",
			Email: "Email",
			Phone: "Telefono",
			Role: "Ruolo",
			Delete: "Elimina",
			Block: "Blocca",
			Unblock: "Sblocca",
			Edit: "Modifica",
			CopyLink: "Genera Link",
			Users: "Utenti",
			ActiveUsers: "Utenti attivi",
			BlockedUsers: "Utenti bloccati",
			Blocked: "BLOCCATO",
			NotAllowed: "Vietato",
			SuperAdmins: "Super Admin",
			CompanyAdmins: "Amministratori di Azienda",
			Experts: "Esperti",
			Operators: "Operatori",
			Temporary: "Operatori temporanei",
			TemporaryUser: "Utente temporaneo",
			CreatedAt: " ore fa",
			CreatedNow: " - Ora"
		},
		Apps: {
			Id: "Id",
			Name: "Nome",
			Ip: "Ip",
			CreationDate: "Data creazione",
			AccessDate: "Data accesso",
			Authorized: "Autorizzata",
			Create: "Create App",
			ListTitle: "Applicazioni",
			Save: "Salva",
			Remove: "Elimina",
			SelectApp: "Per favore, seleziona un'applicazione",
			NoAppSelected: "Nessuna applicazione selezionata",
		},
		Companies: {
			Reset: "Resetta",
			LicensesResetError: "Errore durante la cancellazione delle licenze",
			LicensesResetSuccessfully: "Licenze cancellate con successo",
			LicensesDeletedSuccess: "Licenze cancellate con successo",
			LicensesDeletedError: "Errore durante la cancellazione delle licenze",
			NewLicense: "Nuova licenza",
			Disable: "Disabilita",
			LicenseNumber: "Numero licenze",
			Plan: "Piano",
			SubscriptionActivated: "Sottoscrizione attivata, puoi chiudere il browser adesso",
			SubscriptionError: "Errore durante l'attivazione della sottoscrizione",
			LicensesSuccess: "Licenze create con successo",
			LicensesError: "Errore durante la creazione delle licenze",
			MissingCompanyName: "Nome azienda vuoto",
			CompanyNameAlreadyExists: "Nome azienda esistente",
			MissingAddress: "Indirizzo mancante",
			MissingOfficialName: "Nome ufficiale mancante",
			MissingLicenseInformation: "Informazioni sulle licenze mancanti",
			LicenseMustBeANumber: "Licenza invalida, indicare un numero per le licenze",
			MissingCity: "Città non specificata",
			MissingPostCode: "CAP non specificato",
			MissingCountry: "Paese non specificato",
			NoMainCompaniesAvailable: "Nessuna azienda principale disponibile",
			SubordinateCompanies: "Aziende subordinate",
			Subordinate: "Subordinata",
			ToCompany: "all'azienda",
			MakeSubordinateError: "In quest'azienda sono presenti dei prodotti. Eliminare i prodotti prima di renderla subordinata.",
			RenewalMonths: "Mesi di rinnovo",
			CreationDate: "Data di creazione",
			Name: "Nome",
			OfficialName: "Nome ufficiale",
			Address: "Indirizzo",
			PostCode: "Codice postale",
			State: "Stato",
			Country: "Paese",
			LicenseInformation: "Numero licenze",
			NoLicenses: "Nessuna licenza",
			AllowTemporaryUsers: "Permetti utenti temporanei",
			UploadSizeLimit: "Massima dimensione file upload (MB)",
			StorageLimit: "Limite archivazione files (GB)",
			allowArFunctions: "Permetti funzionalità AR",
			allowTeams: "Permetti l'uso di Gruppi",
			allowApiUsage: "Permetti l'uso delle API",
			allowMinuteRate: "Permetti l'uso della tariffa al minuto",
			NoCompanySelected: "Nessuna azienda selezionata",
			SelectCompany: "Per favore, seleziona un'azienda",
			City: "Città",
			Create: "Crea azienda",
			listTitle: "Aziende",
			Delete: "Elimina",
			Save: "Salva",
			NotificationsEmail: "Email da notificare",
			ExpirationDate: "Data di scadenza",
			MaximumCallTime: "Tempo di chiamata massimo",
			TotalExtraMinutesReload: "Minuti extra totali ricaricati",
			TotalUsedMinutes: "Minuti consumati totali"
		},
		NavLink: {
			Tickets: "Ticket",
			UploadDocument: "Carica i file nel ticket",
			Ticket: "File ricevuti",
			NoFileReceived: "Non ci sono file ricevuti in questo ticket!",
			UploadLogo: "Carica logo",
			Dashboard: "Cruscotto",
			Company: "Azienda",
			MyCompany: "Azienda",
			Products: "Prodotti",
			Documentation: "Documentazione",
			UserManagement: "Gestione utente",
			SignOut: "Esci",
			CallLogs: "Registro Chiamate",
			Call: "Chiamata",
			Received: "Ricevuti",
			Files: "Documenti",
			Troubleshooting: "Risoluzione problemi",
			ProductsSelection: "Selezione Prodotti",
			ChangeProduct: "Cambia Prodotto",
			Settings: "Impostazioni",
			Help: "Aiuto",
			Teams: "Gruppi",
			TicketCall: "Chiamata da ticket",
			PrivacyPolicy: "Informativa sulla privacy",
			AccessWithHololens: "Accedi da Hololens",
			Qrcode: "QR-Code",
		},
		Dashboard: {
			cameraError: "Si prega di collegare la camera",
			cameraErrorDescription: "Verifica che il sito abbia l'autorizzazione all'utilizzo della camera.",
			activeCallers: "Operatori attivi",
			connecting: "Collegamento in corso...",
			activeCalls: "Chiamate attive",
			noOngoingCalls: "Nessun operatore in chiamata",
			inQueue: "in coda.",
			noCallersInQueue: "Nessun operatore in coda",
		},
		Help: "Aiuto",
		HelpProperties: {
			Message: "Descrivi il problema riscontrato (specifica le caratteristiche del device): ",
			Send: "Invia",
		},
		Teams: {
			TitleName: "Nome del team...",
			Name: "Nome",
			listTitle: "Gruppi",
			NoTeamSelected: "Nessun gruppo selezionato",
			SelectTeam: "Per favore, seleziona un gruppo",
			CreateButton: "Crea gruppo",
			NumberOfUsers: "Numero di utenti",
			None: "Nessuno",
		}
	},
	cn: {
		Id: "cn-CN",
		Modal: {
			AuthCode: "复制以下代码到要求的头部标签中",
			AuthEntryPoint: "插入站点的入口点",
			Ok: "好的",
			Cancel: "取消",
			DeleteUser: "是否确定要删除此用户：",
			DeleteTeam: "是否确定要删除此团队：",
			BlockUser: "是否确定要阻止用户访问：",
			UnblockUser: "是否确定要取消阻止用户： ",
			UserLink: "已生成新用户链接-任何以前的链接都被判无效。",
			Copy: "复制到剪贴板",
			LinkToProduct: "链接到产品",
			Redirect: "重定向到", 
			EnterSignature: "输入签名",
		},
		Sort: {
			Name: "名称",
			Date: "日期",
			Type: "类型",
			Official: "官员",
			ShowOfficial: "只查看官方文件",
			ShowAll: "查看所有文件",
			FilesAmount: "文件计数：",
			DiskSpace: "磁盘空间： ",
		},
		Toast: {
			ConnectWithAnotherAccount: "Or log in with another account ",
			UnlicensedUserLogoutMessage: "You don't have any licenses, contact your company admin.",
			ErrorHeader: "错误：",
			ContactAdminError: "请与公司管理员联系以获取访问权限",
			TicketFileLoading: "正在上传文件，请稍后！",
			DocumentUploadedSuccess: "文件已成功上传到单据中",
			UserIsBlocked: "此账号已被阻止",
			OpenNavMenu: "打开/关闭导航菜单",
			ClickToModify: "单击此处进行修改",
			ChangeRoleButton: "切换角色",
			TicketMergeSuccessful: "工单合并成功",
			TicketMergeFailed: "工单合并失败",
			TicketDeleteSuccessful: "工单删除成功",
			TicketDeleteFailed: "工单删除失败",
			TicketsReportRequestSuccessful: "产品报告请求成功",
			TicketsReportRequestFailed: "请求的产品报告尚未完成",
			Error: "错误",
			Info: "信息",
			Warning: "警告",
			Success: "成功",
			UserTempSuccess: "已成功创建临时用户。",
			UserTempError: "无法创建临时用户。",
			UserTokenSuccess: "已成功生成用户令牌。",
			UserTokenError: "无法生成用户令牌。",
			UserDeletedSuccess: "用户删除。",
			UserDeletedError: "未能删除用户。",
			UserBlockSuccess: "用户被阻止",
			UserBlockError: "无法阻止用户",
			UserUnblockSuccess: "用户解锁。",
			UserUnblockError: "无法取消阻止用户",
			AllowAccessToTemporaryUsers: "允许临时用户访问",
			TeamCreationSuccess: "团队创建成功。",
			TeamCreationError: "团队创建失败",
			TeamEditSuccess: "团队已成功更新。",
			TeamEditError: "未能更新团队。",
			TeamDeletedSuccess: "团队已成功删除。",
			TeamDeletedError: "未能删除团队。",
			UploadingLogoError: "未能上传logo",
			LicenseExpiration: "许可证到期",
			LicenseExpirationInfo: "您的许可证将于____年到期 ",
			Expired: "你的许可证过期了。您不久将无法再访问您的帐户",
			Day: "一天",
			Days: "几天",
			DontShowAgain: "不再显示",

			TicketUpdated: "呼叫日志已成功更新。",
			TicketUpdatedFailed: "呼叫日志更新失败。",
			CompanyCreeated: "公司创建成功。",
			CompanyCreeatedFailed: "公司创建失败。",
			CompanyUpdated: "公司更新成功。",
			CompanyUpdatedFailed: "公司更新失败。",
			CompanyDeleted: "公司已成功删除。",
			CompanyDeletedFailed: "公司删除失败。",

			MobileMessageHeader: "注意",
			MobileMessageBody: "声音启用",
			UserEditSuccess: "用户已成功更新。",
			UserEditError: "用户更新失败",
			UserResetSuccess: "用户凭据重置成功",
			UserResetError: "用户凭据重置失败。",
			HintTitle: "提示：",
			HintBodyAR: "将摄像头指向表面，换行，在换行处显示滑块，点击屏幕定位",
			HintBodyPositionModel: "Select model",
			HintBodyPositionModelPart1: "in the window",
			HintBodyPositionModelPart2: "on the right",
			HintBody: "用两个手指移动数字孪生",
			HintBodyPart1: "用一个手指旋转它",
			HintBodyPart2: "按比例缩放",
			SaveClipsDataSuccess: "已成功保存剪辑数据。",
			SaveClipsDataFail: "无法保存剪辑数据",
			LogoutMessage: "您已登录到另一台计算机。连接即将断开。",
			HelpRequested: "帮助请求已成功发送。",
			HelpRequestFailed: "无法发送帮助请求。",
			AppCreated: "已成功创建应用程序。",
			AppCreateFailed: "应用程序创建失败。",
			AppRemoved: "应用程序已成功删除。",
			AppRemoveFailed: "应用程序移动失败。",
			IosMessageError: "如果您想打电话，请使用Safari",
		},
		BrowserWarning: {
			Alert: "您正在使用的浏览器可能不完全支持。请在电脑端上使用Chrome或在MacOS/iOS上使用Safari。",
			Title: "不兼容的浏览器。",
			Body: "我们检测到您的浏览器不支持视频通话。请使用下列兼容浏览器之一。",
			Continue: "如果仍要继续，请按下面的按钮。",
			ContinueButton: "继续",
		},
		PrivacyPolicy: {
			CookieTitle: "接受Cookies",
			CookieText: "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. ",
			ReadPrivacyPolicy: "阅读隐私政策",
			PrivacyTitle: "隐私政策",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "接受并点击下一步",
			Exit: "退出",
			Download: "下载",
			Back: "后退",
		},
		Cancel: "取消",
		Done: "完成",
		Select: "选择",
		RemoveLogo: "移除Logo",
		LastModification: "最后修改",
		Rotation: "旋转",
		ZMove: "Move in depth深入",
		XMove: "横移",
		YMove: "垂直移动",
		ObjectSettings: "对象设置",
		ActiveCall: {
			WaitForLoading: "正在加载上一个视频，请稍候",
			UnlockExpert: "解锁专家",
			SelectExpert: "挑选专家",
			RotatePhone: "请将设备转到横向模式",
			NoTimeRemaining: "时间到，无法接电话。",
			OpenCallHeader: "需要支持",
			OpenCallButtonCompany: "公司支持",
			OpenCallButtonTeam: "团队支持",
			OpenCallBody: "你想要外放电话吗",
			ImageCaptured: "图片采集",
			VideoCaptureStart: "视频捕采集已开启",
			VideoCaptureAdded: "添加视频采集。",
			VideoUploaded: "视频上传",
			FileSent: "文件发送给操作员",
			FileUploadedAndSent: "上传文件并发送给操作员",
			AnnotationUploaded: "批注已上载到日志。",
			FailedToAddCallLog: "无法添加通话记录",
			EndCall: "结束通话",
			ShowCamera: "打开摄像机",
			Mute: "静音",
			StartRecording: "开始录音",
			Unmute: "取消静音",
			ToggleFullscreen: "全屏切换",
			FloatingWindow: "浮动窗",
			ToggleLocation: "记笔记",
			ToggleProductInfo: "切换产品信息",
			SendingFile: "发送文件",
			Call: "呼叫",
			HideCamera: "隐藏摄像头",
			ScreenShot: "截图",
			SwitchCamera: "开关摄像机头",
			ChangeVisualization: "变化可视化",
			WebcamUnabled: "你没有允许打开你的网络摄像头",
			AnnotationConfirm: "确认",
			AnnotationUndo: "打开/取消",
			AnnotationDraw: "画",
			AnnotationCircle: "画圈",
			AnnotationArrow: "箭头",
			SnapshotReceived: "从操作员处收到截图",
			AlertHeader: "上载注册",
			AlertBody: "正在将注册项目上载到服务器，请稍候",
			EnableGenericExpertUser: "请求支持",
			CompanyHelp: "解锁公司",
			TeamHelp: "解锁团队",
			SelectProduct: "更换产品",
			OpenToGeneric: "启用公司支持",
			OpenToTeam: "启用团队支持",
			ChangeResolution: "更改分辨率",
			PositionModel: "位置模型",
			ModelOpenToast: "模型已经打开",
			ModelPositioningToast: "模型已放置",
			ShareScreen: "Share screen",
		},
		CompanyProperties: {
			CompanyName: "公司名称",
		},
		Error: "发生错误.",
		Home: "主页",
		TestString: "这是一个测试字符串",
		TestHeader: "测试头部标签",
		Test: {
			String: "这是一个测试字符串",
			Header: "测试头部标签",
		},
		Languages: {
			en: "英语",
			it: "意大利语",
		},
		Search: "搜索",
		Save: "保存",
		Delete: "删除",
		ResendCredentials: "重新发送密码",
		Credentials: "密码",
		Resend: "重发",
		Saving: "保存中……",
		Loading: "加载中……",
		WebVideo: "网络视频",
		VideoChat: "视频聊天",
		Products: "产品",
		Submit: "Submit",
		SubscriberInfo: "Subscriber Info",
		CompanyDetails: "Company Details",
		AdminDetails: "Admin Details",
		BoostrapCSS: "Bootstrap CSS",
		ProductsProperties: {
			QRCode: "二维码",
			QRCodeDescription: "在产品上放置此二维码，以操作员身份登录时从Holo维护应用程序中扫描，以快速访问此产品",
			Created: "创建",
			Documentation: "文档",
			EditedBy: "编辑：",
			CallLog: "通话记录",
			New: "新产品",
			LinkDocument: "链接文件",
			LinkTeam: "链接到团队",
			LinkProduct: "链接产品",
			MakeOfficial: "正式",
			Linked: "联系",
			TeamName: "团队名称",
			Filetype: "文件类型",
			Filename: "文件名",
			FilePreview: "预览",
			NotFound: "找不到产品",
			UploadDocument: "上传文件",
			NoProducts: "无产品",
			Troubleshooting: "故障排除",
			ProductInformation: "产品信息",
			ProductCode: "产品代码",
			ProductDescription: "描述",
			ProductImage: "产品图片",
			ProductName: "产品名称",
			ProductShortName: "产品简称",
			ProductTeams: "团队合作：",
			ProductTeam: "团队中： ",
			LastUpdated: "最后更新",
			NoProductLinked: "无个",
			GenericProductLinked: "通用的",
		},
		Documentation: "文档",
		DocumentationModal: {
			Title: "删除文件",
			Text: "是否要删除这些文件：",
		},
		DocumentationDetails: {
			ReloadModel: "重新加载信息",
			TakeData: "动画数据：",
			ClipsAmount: "剪辑数量：",
			UploadError: "上载文件时出错",
			UploadErrorSizeFile: "File size too large",
			SearchPlaceholder: "搜索文件……",
			Rejected: "被拒绝，因为它太大或文件类型不允许",
			Details: "细节",
			Name: "文件名：",
			Type: "文件类型：",
			Size: "文件大小：",
			Added: "补充：",
			LinkedProducts: "链接到： ",
			SelectedFiles: "选定文件：",
			Deleting: "产出中……",
			TakeNumber: "修剪/夹住",
			TakeText: "显示文本：",
			TakeSound: "音声文本",
			PreviewTakeSound: "预览音频",
			StopPreviewTakeSound: "停止预览",
			CopyPreviewTakeSound: "生成",
			CopyPreviewTakeSounds: "生成所有音频",
			SaveTakeData: "保存剪辑数据",
		},
		Troubleshooting: {
			Answer: "A回答",
			AnswerPlaceholder: "在这里输入答案……",
			EditQuestion: "编辑问题",
			NewQuestion: "新问题",
			NewAnswer: "新答案",
			AddAnswer: "增加答案",
			SaveAnswer: "保存答案",
			AddDocument: "增加文档",
			Completed: "故障排除分支结束"
		},
		TroubleshootingLanguages: {
			SelectedTextSingular: "选择了语言1",
			SelectedTextPlural: "选定的语言",
			SelectedTextNone: "未选择任何语言",
			NoneAvailable: "无可用的语言",
		},
		WorkLog: {
			Title: "通话记录",
		},
		Ticket: {
			CreateNewTicket: "创建工单",
			AddToSummary: "添加至摘要",
			AddToSummaryPlaceholder: "插入要添加到摘要中的文本……",
			MergeDeleteError: "您只能在自己的工单上执行此操作",
			MergeHeader: "合并工单",
			MergeBody: "工单事项可能重叠",
			DeleteHeader: "删单",
			DeleteBody: "选定的工单将被删除",
			LastModificationDate: "最后修改日期",
			CannotModifySummary: "您只能添加文本",
			OperatorNameChange: "只能由工单作者修改操作人姓名",
			ReceiveCallLogViaMail: "报告交付",
			ReceiveCallLogViaMailBody: "报告将通过电子邮件发送",
			SendMailReport: "发送报告",
			ExportPdf: "导出（PDF）",
			ExportPdfNoImage: "导出（PDF无图像）",
			ExportCsv: "导出（CSV）",
			ProductReport: "请求报告",
			Save: "保存",
			Cancel: "取消",
			Submit: "搜索",
			SearchProduct: "搜索产品",
			ProductName: "产品名称",
			ProductCode: "产品代码",
			CompanyName: "公司名称",
			LastUpdated: "上次更新",
			CompanyCode: "公司代码",
			DateCreated: "创建日期",
			OperatorName: "操作员姓名",
			Status: "状态",
			ExpertName: "专家名",
			Summary: "总结",
			Timeline: "时间线",
			Export: "导出",
			FilterByDate: "按日期筛选",
			TicketCreatedSuccess: "已成功创建工单",
			TicketCreatedFailed: "Ticket canceled",
			DocumentUploaded: "在工单中上载的文档",
			Login: "会议开始",
			SessionEnded: "结束会议",
			OperatorPosition: "捕获操作员位置",
			OperatorPositionDescription: "操作员位于（纬度、经度）：",
			Enqueue: "排队",
			TroubleshootingTreeClose: "故障排除树已关闭",
			TroubleshootingTreeOpen: "疑难解答树已打开",
			TroubleshootingTreeReset: "故障排除树重置",
			AnswerCall: "回答",
			VideoCapturedStarted: "视频捕获已开始",
			FileOpen: "打开的文件",
			FileSent: "发送的文件",
			SignatureCaptured: "捕捉签名",
			ImageCaptured: "拍摄的图像",
			VideoCapturedEnded: "捕获的视频",
			AnnotationSent: "行动",
			ModelOpen: "三维文件已打开",
			EndedCall: "通话结束",
			ProductDownload: "下载的产品",
			AnnotationDeleted: "删除注释",
			ParticipantJoin: "专家加入了通话",
			ModelPosition: "三维模型重新定位",
			ProductChanged: "产品更改",
			CallPublished: "t请求另一位专家的帮助",
			CallPublishedTeam: "请求同一团队的另一位专家提供帮助",

			LoginDescription: "用户登录。",
			SessionEndedDescription: " ",
			EnqueueDescription: "正在进入队列，等待远程专家连接……",
			TroubleshootingTreeCloseDescription: "故障排除结束，正在打开文件",
			TroubleshootingTreeOpenDescription: "故障排除已初始化。",
			TroubleshootingTreeResetDescription: "故障排除已初始化并重新启动。",
			AnswerCallDescription: "已连接到远程专家。",
			VideoCapturedStartedDescription: "远程专家启动了视频捕获。",
			FileOpenDescription: "浏览：",
			ParticipantJoinDescription: "一位专家加入了这一通话：",
			FileSentDescription: "接收文件： ",
			FileSentFromDescription: "文件接收自 ",
			SignatureCapturedDescription: "授权签名已保存",
			ImageCapturedDescription: "远程专家捕获的图像。",
			VideoCapturedEndedDescription: "会话录制结束。",
			AnnotationSentDescription: "从远程专家发送的注释。",
			ModelOpenDescription: "下载3d模型：",
			EndedCallDescription: " ",
			ProductDownloadDescription: "下载的产品资料。",
			ProductChangedDescription: "专家把产品更换为",
			AnnotationDeletedDescription: "由操作员删除的批注。",
			VideoCaptureDescription: "远程专家启动了视频捕获。",
			DocumentSentDescription: "浏览中",
			NoTickets: "No tickets to show",
			TicketStatusChanged: "Changed ticket status",
			TicketStatusChangedDescription: "Status changed to ",
		},
		TicketStatus: {
			SelectStatus: "选择状态",
			Open: "打开",
			Working: "工作中",
			WaitingForFeedback: "等待反馈",
			Solved: "已解决",
			ClosedButNotSolved: "已关闭但未解决",
			NeedFurtureActivity: "需要未来的活动",
			NoNeedToSolved: "无需解决",
		},
		Upload: {
			UploadingConfirmation: "Logo已上载",
			Drag: "将文件拖放到此处以上载",
			Drop: "拖放以开始上载",
		},
		Call: {
			Mute: "静音",
			Hangup: "挂断",
			SendClick: "发送点击",
		},
		CallLogList: {
			Product: "产品",
			Selection: "选择",
			TicketID: "工单号",
			Started: "启动",
			Operator: "操作员",
			Expert: "专家",
			Status: "地位",
			Summary: "描述",
		},
		UserManagement: "用户管理",
		UserType: {
			Operator: "操作员",
			CompanyAdmin: "公司管理员",
			Expert: "远程专家",
			SuperAdmin: "超级管理员",
			Root: "根",
		},
		Users: {
			IsLinked: "链接",
			Move: "移动",
			LoggedUserError: "您无法创建用户",
			CompanyDomainError: "公司域名不存在",
			EmailError: "无效的电子邮件地址",
			FirstNameError: "无效的名字",
			LastNameError: "无效的姓氏",
			PhoneError: "电话号码无效",
			RoleError: "无效角色",
			UserNameEmpty: "空用户名",
			IllegalCharacterInUsername: "用户名中包含无效字符",
			UsernameAlreadyExists: "用户名已存在",

			AddAuthCode: "添加身份验证代码",
			Code: "授权码",
			AddUser: "添加用户",
			AddTempUser: "添加临时用户",
			UserName: "用户名",
			FirstName: "名字",
			LastName: "姓",
			Email: "电子邮件",
			Phone: "电话",
			Role: "职位",
			Delete: "删除",
			Block: "阻止",
			Unblock: "解除阻止",
			Edit: "编辑",
			CopyLink: "生成链接",
			Users: "用户",
			ActiveUsers: "活跃用户",
			BlockedUsers: "被阻止的用户",
			Blocked: "阻止",
			NotAllowed: "不允许",
			SuperAdmins: "公司超级管理员",
			CompanyAdmins: "公司管理人员",
			Experts: "专家",
			Operators: "操作员",
			Temporary: "临时操作员",
			TemporaryUser: "临时用户",
			CreatedAt: "几小时前",
			CreatedNow: "-现在-"
		},
		Apps: {
			Id: "身份证",
			Name: "姓名",
			Ip: "知识产权",
			CreationDate: "创建日期",
			AccessDate: "访问日期",
			Authorized: "经授权的",
			Create: "创建应用程序",
			ListTitle: "应用程序",
			Save: "保存",
			Remove: "删除",
			SelectApp: "请选择一个应用程序",
			NoAppSelected: "未选择任何应用程序",
		},
		Companies: {
			Reset: "重置",
			LicensesResetSuccessfully: "许可证重置成功",
			LicensesResetError: "许可证重置出错",
			LicensesDeletedSuccess: "许可证成功删除",
			LicensesDeletedError: "许可证删除出错",
			NewLicense: "新许可证",
			Disable: "禁用",
			LicenseNumber: "许可证号",
			Plan: "设计",
			SubscriptionActivated: "订阅已激活，现在您可以关闭浏览器",
			SubscriptionError: "订阅激活过程出错",
			LicensesSuccess: "许可证创建成功",
			LicensesError: "创建许可证时出错",
			MissingCompanyName: "公司名称为空",
			CompanyNameAlreadyExists: "公司名称已存在",
			MissingAddress: "缺少地址",
			MissingOfficialName: "缺少正式名称",
			MissingLicenseInformation: "缺少许可证信息",
			LicenseMustBeANumber: "许可证信息必须是数字",
			MissingCity: "缺少城市",
			MissingPostCode: "缺少邮编",
			MissingCountry: "缺少国家",
			NoMainCompaniesAvailable: "No main companies available",
			SubordinateCompanies: "下属公司",
			Subordinate: "下属的",
			ToCompany: "致公司",
			MakeSubordinateError: "该公司有一些产品。 切换从属状态前删除产品",
			RenewalMonths: "更新月份",
			CreationDate: "创建日期",
			Name: "名称",
			OfficialName: "官方名称字",
			Address: "地址",
			PostCode: "邮编",
			State: "州",
			Country: "国家",
			LicenseInformation: "许可证金额",
			NoLicenses: "No licenses",
			AllowTemporaryUsers: "允许临时用户",
			UploadSizeLimit: "最大上载文件（MB）",
			StorageLimit: "存储限制（GB）",
			allowArFunctions: "允许AR功能",
			allowTeams: "允许使用Teams",
			allowApiUsage: "允许使用APIs",
			allowMinuteRate: "允许分钟计费标准",
			NoCompanySelected: "未选择公司",
			SelectCompany: "请选择一家公司",
			City: "城市",
			Create: "创建公司",
			listTitle: "公司",
			Delete: "删除",
			Save: "保存",
			NotificationsEmail: "通知邮件",
			ExpirationDate: "到期日期",
			MaximumCallTime: "最长通话时间",
			TotalExtraMinutesReload: "重新加载的额外分钟总数",
			TotalUsedMinutes: "已用分钟总数"
		},
		NavLink: {
			Tickets: "工单",
			UploadDocument: "将您的文档上传到工单中",
			Ticket: "收到的文件",
			NoFileReceived: "此工单中没有收到任何文件！",
			UploadLogo: "上传图标",
			Dashboard: "仪表板",
			Company: "公司",
			MyCompany: "公司",
			Products: "产品",
			Documentation: "文档",
			UserManagement: "用户管理",
			SignOut: "退出",
			CallLogs: "通话记录",
			Call: "呼叫",
			Received: "收到",
			Files: "文件",
			Troubleshooting: "故障排除",
			ProductsSelection: "产品选择",
			ChangeProduct: "换货",
			Settings: "设置",
			Help: "帮助",
			Teams: "团队",
			TicketCall: "Call from ticket", 
			PrivacyPolicy: "Privacy policy",
			AccessWithHololens: "Log in with Hololens",
			Qrcode: "二维码",
		},
		Dashboard: {
			cameraError: "没有连接摄像头。",
			cameraErrorDescription: "请检查此网站是否允许使用摄像头。",
			activeCallers: "主动呼叫者",
			connecting: "等待接通……",
			activeCalls: "主动呼叫",
			noOngoingCalls: "没有持续的电话",
			inQueue: "排队。",
			noCallersInQueue: "无呼叫队列",
		},
		Help: "帮助",
		HelpProperties: {
			Message: "描述遇到的问题（添加设备规格）：",
			Send: "发送",
		},
		Teams: {
			TitleName: "名称……",
			Name: "姓名",
			listTitle: "Teams",
			NoTeamSelected: "未选择账号",
			SelectTeam: "请选择账号",
			CreateButton: "创建账号",
			NumberOfUsers: "用户数",
			None: "无",
		}
	},
	de: {
		Id: "de-DE",
		Modal: {
			Ok: "Ok",
			Cancel: "Abbrechen",
			DeleteUser: "Wirklich diesen Benutzer löschen: ",
			DeleteTeam: "Wirklich dieses Team löschen: ",
			BlockUser: "Wirklich diesen Benutzer blockieren: ",
			UnblockUser: "Wirklich diesen Benutzer entsperren: ",
			UserLink: "Neuer Benutzerlink generiert - Alle vorherigen Links sind ungültig.",
			Copy: "In den Notizen kopieren",
			LinkToProduct: "Link zum Produkt",
			Redirect: "Weiterleitung zu",
			EnterSignature: "Unterschrift eingeben"
		},
		Sort: {
			Name: "Name",
			Date: "Datum",
			Type: "Typ",
			Official: "Offiziell",
			ShowOfficial: "Nur offizielle Dateien anzeigen",
			ShowAll: "Alle Dateien anzeigen",
			FilesAmount: "Dateinummer: ",
			DiskSpace: "Speicherkapazität: ",
		},
		Toast: {
			TicketFileLoading: "Een bestand is aan het uploaden, wacht!",
			DocumentUploadedSuccess: "Document succesvol geupload in het ticket",
			UserIsBlocked: "Ihr Account ist gesperrt",
			OpenNavMenu: "Navigationsmenü öffnen / schließen",
			ClickToModify: "Hier klicken zum Bearbeiten",
			ChangeRoleButton: "Rolle wechseln",
			TicketMergeSuccessful: "Tickets wurden korrekt verbunden",
			TicketMergeFailed: "Fehler bei der Verbindung der Tickets",
			TicketDeleteSuccessful: "Ticket erfolgreich gelöscht",
			TicketDeleteFailed: "Ticketlöschung gescheitert",
			TicketsReportRequestSuccessful: "Produktreport erfolgreich angefordert",
			TicketsReportRequestFailed: "Der angeforderte Report ist noch nicht vollständig",
			Error: "Fehler",
			Info: "Info",
			Warning: "Vorsicht",
			Success: "Erledigt",
			UserTempSuccess: "Befristeter Benutzer erfolgreich erstellt.",
			UserTempError: "Befristeter Benutzer kann nicht erstellt werden.",
			UserTokenSuccess: "Benutzeranmeldung erfolgreich generiert.",
			UserTokenError: "Benutzeranmeldung kann nicht generiert werden.",
			UserDeletedSuccess: "Benutzer erfolgreich gelöscht.",
			UserDeletedError: "Benutzer kann nicht gelöscht werden.",
			UserBlockSuccess: "Benutzer erfolgreich blockiert.",
			UserBlockError: "Benutzer kann nicht blockiert werden.",
			UserUnblockSuccess: "Benutzer erfolgreich entsperrt",
			UserUnblockError: "Benutzer kann nicht entsperrt werden.",
			AllowAccessToTemporaryUsers: "Zugriff auf befristeten Benutzer erlauben",
			TeamCreationSuccess: "Gruppe erfolgreich erstellt.",
			TeamCreationError: "Gruppenerstellung gescheitert",
			TeamEditSuccess: "Gruppe erfolgreich aktualisiert.",
			TeamEditError: "Gruppenaktualisierung gescheitert ",
			TeamDeletedSuccess: "Gruppe erfolgreich gelöscht.",
			TeamDeletedError: "Löschung der Gruppe gescheitert.",
			UploadingLogoError: "Logo konnte nicht geladen werden",
			LicenseExpiration: "Lizenz läuft in Kürze ab",
			LicenseExpirationInfo: "Lizenz läuft in ... Tagen ab",
			Expired: "Ihre Lizenz ist abgelaufen. Sie können sich nicht mehr bei diesem Account anmelden.",
			Day: "Tag",
			Days: "Tage",
			DontShowAgain: "Nicht mehr anzeigen",

			TicketUpdated: "Ticket erfolgreich aktualisiert.",
			TicketUpdatedFailed: "Ticketaktualisierung gescheitert",
			CompanyCreeated: "Firma erfolgreich erstellt.",
			CompanyCreeatedFailed: "Firma kann nicht erstellt werden",
			CompanyUpdated: "Firma erfolgreich aktualisiert.",
			CompanyUpdatedFailed: "Firmaaktualisierung gescheitert.",
			CompanyDeleted: "Firma erfolgreich gelöscht.",
			CompanyDeletedFailed: "Löschung der Firma gescheitert.",

			UserEditSuccess: "Benutzer erfolgreich aktualisiert.",
			UserEditError: "Benutzer kann nicht aktualisiert werden.",
			UserResetSuccess: "Benutzeranmeldedaten erfolgreich wiederhergestellt.",
			UserResetError: "Benutzeranmeldedaten können nicht wiederhergestellt werden.",
			HintTitle: "Empfehlung:",
			HintBodyAR: "Richten Sie die Kamera auf eine Oberfläche,<br> damit der Kreis angezeigt wird. <br> Tippen Sie auf den Bildschirm, um das Modell zu platzieren",
			HintBody: "Bewegen Sie den digitalen Zwilling mit zwei Fingern",
			HintBodyPart1: "Ein Finger, um ihn zu drehen",
			HintBodyPart2: "Pinch zum Zoomen",
			SaveClipsDataSuccess: "Animationsdaten erfolgreich gespeichert.",
			SaveClipsDataFail: "Fehler beim Speichern der Animationsdaten.",
			LogoutMessage: "Sie sind auf einem anderen Computer angemeldet. Sie werden gleich getrennt.",
			MobileMessageHeader: "Benachrichtigung",
			MobileMessageBody: "Sound-Effetke aktiviert",
			AppCreated: "App erfolgreich erstellt.",
			AppCreateFailed: "Anwendungserstellung gescheitert",
			AppRemoved: "App erfolgreich gelöscht.",
			AppRemoveFailed: "Löschung der App gescheitert.",
			IosMessageError: "Verwenden Sie Safari als Browser, wenn Sie den Anruf tätigen möchten",
		},
		BrowserWarning: {
			Alert: "Der von Ihnen verwendete Browser wird möglicherweise nicht unterstützt. Bitte verwenden Sie Chrome auf PC oder Safari auf MacOS / iOS.",
			Title: "Inkompatibler Browser.",
			Body: "Der von Ihnen verwendete Browser unterstützt keine Videoanrufe. Bitte installieren Sie einen der unten aufgeführten Browser.",
			Continue: "Wenn Sie dennoch weitergehen möchten, drücken Sie die Taste am Ende der Seite",
			ContinueButton: "Weiter",
		},
		PrivacyPolicy: {
			CookieTitle: "Cookies akzeptieren",
			CookieText: "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. ",
			ReadPrivacyPolicy: "Datenschutzerklärung lesen",
			PrivacyTitle: "Datenschutzerklärung",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "Akzeptieren und fortfahren",
			Exit: "Beenden",
			Download: "Herunterladen",
			Back: "Zurück",
		},
		Cancel: "Abbrechen",
		Done: "Erledigt",
		Select: "Markieren",
		RemoveLogo: "Logo entfernen",
		LastModification: "Letzte Bearbeitung",
		Rotation: "Drehung",
		ZMove: "Vorwärts bewegen",
		XMove: "Horizontal bewegen",
		ObjectSettings: "Modelleinstellungen",
		ActiveCall: {
			UnlockExpert: "Experte zulassen",
			SelectExpert: "Experte auswählen",
			RotatePhone: "Drehen Sie Ihr Telefon in den Querformatmodus",
			NoTimeRemaining: "Minuten verbraucht. Sie können keine Anrufe entgegennehmen",
			OpenCallHeader: "Hilfe erhalten",
			OpenCallButtonCompany: "Support für die Firma",
			OpenCallButtonTeam: "Support für das Team",
			OpenCallBody: "Möchten Sie den Anruf führen??",
			ImageCaptured: "Screenshot gespeichert.",
			VideoCaptureStart: "Bildschirmaufnahme gestartet",
			VideoCaptureAdded: "Ereignis für Bildschirmaufnahme hinzugefügt.",
			VideoUploaded: "Video hochgeladen.",
			FileSent: "Datei an den Operator gesendet",
			FileUploadedAndSent: "Datei hochgeladen und an den Operator gesendet",
			AnnotationUploaded: "Anmerkungen auf die Registrierung hochgeladen.",
			FailedToAddCallLog: "Fehler beim Hinzufügen des Ereignisses zu der Anrufliste",
			EndCall: "Anruf beenden",
			ShowCamera: "Videokamera anzeigen",
			Mute: "Mikrofon stummschalten",
			StartRecording: "Registrierung starten",
			Unmute: "Mikrofon aktivieren",
			FloatingWindow: "Schwebendes Fenster",
			ToggleFullscreen: "Vollbild aktivieren / deaktivieren",
			ToggleLocation: "Anmerkung erstellen",
			ToggleProductInfo: "Produktinformationen aktivieren / deaktivieren",
			SendingFile: "Dateien senden",
			Call: "Anrufen",
			HideCamera: "Kamera verstecken",
			ScreenShot: "Screenshot aufnehmen",
			SwitchCamera: "Kamera wechseln",
			ChangeVisualization: "Ansicht wechseln",
			WebcamUnabled: "Sie haben die Nutzung der Webcam nicht zugelassen",
			AnnotationConfirm: "Bestätigen",
			AnnotationUndo: "Abbrechen",
			AnnotationDraw: "Zeichen",
			AnnotationCircle: "Kreis",
			AnnotationArrow: "Pfeil",
			AlertHeader: "Datei-Upload",
			AlertBody: "Der Anruf wird beendet, sobald die Dateien auf den Server hochgeladen wurden.",
			SnapshotReceived: "Snapshot vom Operator empfangen",
			EnableGenericExpertUser: "Hilfe erhalten",
			CompanyHelp: "Firma entsperren",
			TeamHelp: "Team entsperren",
			SelectProduct: "Produkt wechseln",
			OpenToGeneric: "Support benötigt",
			OpenToTeam: "Teamsupport benötigt",
			ChangeResolution: "Auflösung wechseln",
			PositionModel: "Modell platzieren",
			ModelOpenToast: "Modell geöffnet",
			ModelPositioningToast: "Modell platziert",
			ShareScreen: "Share screen",
		},
		CompanyProperties: {
			CompanyName: "Firmenname",
		},
		Error: "Leider ist ein Problem aufgetreten",
		Home: "Startseite",
		TestString: "Testtext",
		TestHeader: "Testtitel",
		Test: {
			String: "Testtext",
			Header: "Testtitel",
		},
		Languages: {
			en: "Englisch",
			it: "Italienisch",
		},
		Search: "Suchen",
		Save: "Speichern",
		Delete: "Löschen",
		ResendCredentials: "Anmeldeinformationen wieder senden",
		Credentials: "Anmeldeinformationen",
		Resend: "Wieder senden",
		Saving: "Speicherung...",
		Loading: "Wird geladen...",
		WebVideo: "Webvideo",
		VideoChat: "Video-Chat",
		Products: "Produkte",
		BoostrapCSS: "Bootstrap CSS",
		ProductsProperties: {
			QRCode: "QR-Code",
			QRCodeDescription: "Wenden Sie diesen QR-Code auf das physische Produkt an und scannen Sie ihn von der Holo-Maintenance-App, nachdem Sie sich als Operator angemeldet haben, um schnell auf dieses Produkt zuzugreifen",
			Created: "Geschaffen",
			Documentation: "Dokumentation",
			EditedBy: "Bearbeitet von",
			CallLog: "Anrufliste",
			New: "Neues Produkt",
			LinkDocument: "Dokument beifügen",
			LinkTeam: "Zur Gruppe beifügen",
			LinkProduct: "Zu das Produkt beifügen ",
			MakeOfficial: "Mach es offiziell",
			Linked: "Beigefügt",
			TeamName: "Gruppenname",
			Filetype: "Dateityp",
			Filename: "Dateiname",
			FilePreview: "Vorschau",
			NotFound: "Produkt nicht gefunden",
			UploadDocument: "Dokument hochladen",
			NoProducts: "Kein Produkt",
			Troubleshooting: "Problembehandlung",
			ProductInformation: "Produktinformation",
			ProductCode: "Produktcode",
			ProductDescription: "Beschreibung",
			ProductImage: "Produktbild",
			ProductName: "Produktname",
			ProductShortName: "Produktname - Kurzname",
			ProductTeams: "In Gruppen: ",
			ProductTeam: "In der Gruppe: ",
			LastUpdated: "Letztes Update",
			NoProductLinked: "Null",
			GenericProductLinked: "Allgemein",
		},
		Documentation: "Dokumentation",
		DocumentationModal: {
			Title: "Dateien löschen",
			Text: "Sie sind dabei, diese Dateien zu löschen: ",
		},
		DocumentationDetails: {
			ReloadModel: "Modellinformation wieder laden",
			TakeData: "Animationsdaten: ",
			ClipsAmount: "Anzahl der Animationen: ",
			UploadError: "Beim Hochladen der Datei ist ein Fehler aufgetreten",
			SearchPlaceholder: "Datei suchen...",
			Rejected: "wurde abgelehnt: Dateigröße zu groß oder kein unterstützter Typ.",
			Details: "Details",
			Name: "Dateiname: ",
			Type: "Dateityp: ",
			Size: "Dateigröße: ",
			Added: "Hinzugefügt: ",
			LinkedProducts: "Anhang zu: ",
			SelectedFiles: "Ausgewählte Dateien: ",
			Deleting: "Löschung läuft...",
			TakeNumber: "Animation",
			TakeText: "Visueller Text: ",
			TakeSound: "Gesprochener Text: ",
			PreviewTakeSound: "Audiovorschau",
			StopPreviewTakeSound: "Vorschau stoppen",
			CopyPreviewTakeSound: "Aufnehmen",
			CopyPreviewTakeSounds: "Alle Audios aufnehmen",
			SaveTakeData: "Animationsdaten speichern",
		},
		Troubleshooting: {
			Answer: "Antwort",
			AnswerPlaceholder: "Schreiben Sie hier die Antwort ...",
			EditQuestion: "Frage bearbeiten",
			NewQuestion: "Neue Frage",
			NewAnswer: "Neue Antwort",
			AddAnswer: "Antwort hinzufügen",
			SaveAnswer: "Antwort speichern",
			AddDocument: "Dokument hinzufügen",
			Completed: "Komplette Problemlösung"
		},
		TroubleshootingLanguages: {
			SelectedTextSingular: "1 Sprache ausgewählt",
			SelectedTextPlural: "ausgewählte Sprachen",
			SelectedTextNone: "Keine Sprache ausgewählt",
			NoneAvailable: "Keine Sprache ausgewählt",
		},
		WorkLog: {
			Title: "Anrufliste",
		},
		Ticket: {
			CreateNewTicket: "Neues Ticket erstellen",
			TicketCreatedSuccess: "Ticket erfolgreich erstellt",
			TicketCreatedFailed: "Ticket canceled",
			AddToSummary: "Zur Zusammenfassung hinzufügen",
			AddToSummaryPlaceholder: "FÜGEN SIE DEN TEXT EIN, DEN SIE ZUR ZUSAMMENFASSUNG HINZUFÜGEN MÖCHTEN ...",
			MergeDeleteError: "Sie können diese Aktion nur für Ihre Tickets ausführen",
			OperatorPosition: "Position des Operators verzeichnet ",
			OperatorPositionDescription: "Der Operator befindet sich in (Breite, Länge): ",
			MergeHeader: "Tickets verbinden",
			MergeBody: "Die Ereignisse der Tickets könnten sich überschneiden",
			DeleteHeader: "Ticket löschen",
			DeleteBody: "Die ausgewählte Tickets werden gelöscht",
			LastModificationDate: "Datum der letzten Bearbeitung",
			CannotModifySummary: "Sie können nur Text hinzufügen",
			OperatorNameChange: "Der Name des Operators kann nur vom Ticketautor bearbeiten werden",
			ReceiveCallLogViaMail: "Report senden",
			ReceiveCallLogViaMailBody: "Der Report wird per E-Mail gesendet",
			SendMailReport: "Report senden",
			ExportPdf: "Exportieren (PDF)",
			ExportPdfNoImage: "Exportieren(PDF ohne Bilder )",
			ExportCsv: "Exportieren (CSV)",
			ProductReport: "Report anfordern",
			Save: "Speichern",
			Cancel: "Abbrechen",
			Submit: "Suchen",
			SearchProduct: "Produkte suchen",
			ProductName: "Produktname",
			ProductCode: "Produktcode",
			CompanyName: "Firmenname",
			LastUpdated: "Zuletzt aktualisiert",
			CompanyCode: "Code der Firma",
			DateCreated: "Erstellungsdatum",
			OperatorName: "Name des Operators",
			Status: "Status",
			ExpertName: "Name des Experten",
			Summary: "Zusammenfassung",
			Timeline: "Chronologie der Ereignisse",
			Export: "Exportieren",
			FilterByDate: "Nach Datum filtern",
			Login: "Session gestartet",
			SessionEnded: "Session beendet",
			Enqueue: "In der Schlage",
			TroubleshootingTreeClose: "Aktion",
			TroubleshootingTreeOpen: "Aktion",
			TroubleshootingTreeReset: "Aktion",
			AnswerCall: "Antwort",
			VideoCapturedStarted: "Videoaufnahme gestartet",
			FileOpen: "Dokument öffnen",
			FileSent: "Dokument gesendet",
			SignatureCaptured: "Unterschrift erhalten",
			ImageCaptured: "Bild aufgenommen",
			VideoCapturedEnded: "Video aufgenommen",
			AnnotationSent: "Aktion",
			ModelOpen: "3D-Datei öffnen",
			EndedCall: "Anruf beendet",
			ProductDownload: "Produkt heruntergeladen",
			AnnotationDeleted: "Aktion",
			ParticipantJoin: "Ein Experte kommt an",
			ModelPosition: "3D-Modell neu positioniert",
			ProductChanged: "Produkt gewechselt",
			CallPublished: "Zusätzlichen Experten anfordern",
			CallPublishedTeam: "Sie zusätzlichen Experten von Ihrer Gruppe anfordern",

			LoginDescription: "Benutzer hat sich angemeldet.",
			SessionEndedDescription: " ",
			EnqueueDescription: "Sie befinden sich in einer Warteschlange und warten darauf, dass ein Remote-Experte eine Verbindung herstellt...",
			TroubleshootingTreeCloseDescription: "Ende des Problemlösungsbaums erreicht. Datei wird geöffnet.",
			TroubleshootingTreeOpenDescription: "Problemlösungsbaum initialisiert.",
			TroubleshootingTreeResetDescription: "Problemlösungsbaum wiederhergestellt.",
			AnswerCallDescription: "Verbindung zu einem Experte herstellen.",
			ParticipantJoinDescription: "Der Experte tritt dem Anruf bei: ",
			VideoCapturedStartedDescription: "Der Experte hat eine Videoaufnahme gestartet.",
			FileOpenDescription: "Navigation: ",
			FileSentDescription: "Eingehende Datei: ",
			FileSentFromDescription: "Datei wird empfangen von ",
			SignatureCapturedDescription: "Autorisierungssignatur gespeichert",
			ImageCapturedDescription: "Der Experte hat einen Screenshot gespeichert",
			VideoCapturedEndedDescription: "Session-Registrierung beendet",
			AnnotationSentDescription: "Der Remote-Experte hat eine Anmerkung gesendet",
			ModelOpenDescription: "3D-Modell Download: ",
			EndedCallDescription: " ",
			ProductDownloadDescription: "Produktdaten heruntergeladen.",
			ProductChangedDescription: "Der Experte hat das Produkt gewechselt: ",
			AnnotationDeletedDescription: "Anmerkungen vom Experte gelöscht",
			VideoCaptureDescription: "Der Experte hat eine Videoaufnahme gestartet.",
			DocumentSentDescription: "Navigation: ",
			NoTickets: "No tickets to show",
			TicketStatusChanged: "Changed ticket status",
			TicketStatusChangedDescription: "Status changed to ",
		},
		TicketStatus: {
			SelectStatus: "Status auswählen",
			Open: "Geöffnet",
			Working: "Klappt",
			WaitingForFeedback: "In Erwartung von Feedback",
			Solved: "Gelöst",
			ClosedButNotSolved: "Geschlossen, aber nicht gelöst",
			NeedFurtureActivity: "Anfrage für Zukünftige Aktivitäten",
			NoNeedToSolved: "Keine Lösung erforderlich",
		},
		Upload: {
			UploadingConfirmation: "Logo hochgeladen",
			Drag: "Hierher ziehen, um die Datei zu hochladen ",
			Drop: "Loslassen Sie die Datei, um den Upload zu starten",
		},
		Call: {
			Mute: "Mikrofon ausschalten",
			Hangup: "Besprechung beenden",
			SendClick: "Click senden",
		},
		CallLogList: {
			Selection: "Auswahl",
			Product: "Produkt",
			TicketID: "ID Ticket",
			Started: "Gestartet",
			Operator: "Operator",
			Expert: "Experte",
			Status: "Status",
			Summary: "Beschreibung",
		},
		UserManagement: "Benutzerverwaltung",
		UserType: {
			Operator: "Operator",
			CompanyAdmin: "	Unternehmen Admin",
			Expert: "	Experte",
			SuperAdmin: "Super Admin",
			Root: "Root",
		},
		Users: {
			IsLinked: "Is Linked",
			Move: "Move",
			LoggedUserError: "You are not able to create users",
			CompanyDomainError: "Company domain does not exist",
			EmailError: "Invalid email address",
			FirstNameError: "Invalid first name",
			LastNameError: "Invalid last name",
			PhoneError: "Phone number not valid",
			RoleError: "Invalid role",
			UserNameEmpty: "Empty username",
			IllegalCharacterInUsername: "Invalid characters in username",
			UsernameAlreadyExists: "Username already exists",
			
			AddAuthCode: "Generate API access key",
			Code: "Authorization codes",
			AddUser: "Benutzer erstellen",
			AddTempUser: "Befristeter Benutzer erstellen",
			UserName: "Username",
			FirstName: "Name",
			LastName: "Familienname",
			Email: "Email",
			Phone: "Telefon",
			Role: "Rolle",
			Delete: "Löschen",
			Block: "Blockieren",
			Unblock: "Entsperren",
			Edit: "Bearbeiten",
			CopyLink: "Link erstellen",
			Users: "Benutzer",
			ActiveUsers: "Aktive Benutzer",
			BlockedUsers: "Blockierte Benutzer",
			Blocked: "BLOCKIERT",
			NotAllowed: "Verboten",
			CompanyAdmins: "Admins der Firma",
			Experts: "Experten",
			Operators: "Operators",
			Temporary: "Befristeter Operators",
			TemporaryUser: "Befristeter Benutzer",
			CreatedAt: " Stunden",
			CreatedNow: " - Jetzt"
		},
		Apps: {
			Id: "Id",
			Name: "Name",
			Ip: "Ip",
			CreationDate: "Erstellungsdatum ",
			AccessDate: "Zugriffsdatum",
			Authorized: "Autorisiert",
			Create: "App erstellen",
			ListTitle: "Apps",
			Save: "Speichern",
			Remove: "Löschen",
			SelectApp: "Bitte wählen Sie eine App",
			NoAppSelected: "Keine App ausgewählt",
		},

		Companies: {
			Reset: "Reset",
			LicensesResetSuccessfully: "Lizenzen erfolgreich zurückgesetzt",
			LicensesResetError: "Fehler beim Zurücksetzen der Lizenzen",
			LicensesDeletedSuccess: "Lizenzen erfolgreich gelöscht",
			LicensesDeletedError: "Fehler beim Löschen der Lizenzen",
			NewLicense: "Neue Lizenz",
			Disable: "Deaktivieren",
			LicenseNumber: "Anzahl der Lizenzen",
			Plan: "Plan",
			SubscriptionActivated: "Abonnement aktiviert, Sie können den Browser jetzt schließen",
			SubscriptionError: "Fehler bei der Aktivierung des Abonnements",
			LicensesSuccess: "Lizenzen erfolgreich erstellt",
			LicensesError: "Fehler bei der Lizenzerstellung",
			MissingCompanyName: "Firmenname ist leer",
			CompanyNameAlreadyExists: "Firmenname existiert schon",
			MissingAddress: "Fehlende E-Mail-Adresse",
			MissingOfficialName: "Fehlender offizieller Name",
			MissingLicenseInformation: "Fehlende Lizenzinformationen",
			LicenseMustBeANumber: "Lizenzinformationen müssen eine Zahl sein",
			MissingCity: "Fehlende Stadt",
			MissingPostCode: "Fehlende Postleitzahl",
			MissingCountry: "Fehlender Staat",
			NoMainCompaniesAvailable: "Keine Hauptfirma verfügbar",
			SubordinateCompanies: "Untergeordnete Firmen",
			Subordinate: "Untergeordnete",
			ToCompany: "der Firma",
			MakeSubordinateError: "Es gibt einige Produkte in dieser Firma. Löschen Sie Produkte, bevor Sie sie in den untergeordneten Status wechseln",
			RenewalMonths: "Verlängerungsmonate",
			CreationDate: "Erstellungsdatum",
			Name: "Name",
			OfficialName: "Offizieller Name",
			Address: "Addresse",
			PostCode: "Postleitzahl",
			State: "Staat",
			Country: "Land",
			LicenseInformation: "Anzahl der Lizenzen",
			NoLicenses: "Keine Lizenz",
			AllowTemporaryUsers: "Befristeter Benutzer aktivieren",
			UploadSizeLimit: "Maximale Größe für das Hochladen von Dateien (MB)",
			StorageLimit: "Begrenzen Sie Archivierungsdateien (GB)",
			allowArFunctions: "AR-Funktionalität aktivieren",
			allowTeams: "Verwendung von Gruppen erlauben",
			allowApiUsage: "Verwendung von APIs erlauben",
			allowMinuteRate: "Verwendung der Rate pro Minute erlauben",
			NoCompanySelected: "Keine Firma ausgewählt",
			SelectCompany: "Bitte wählen Sie eine Firma aus",
			City: "Stadt",
			Create: "Firma erstellen",
			listTitle: "Firmen",
			Delete: "Löschen",
			Save: "Speichern",
			NotificationsEmail: "Kontakt E-mail",
			ExpirationDate: "Ablaufdatum",
			MaximumCallTime: "Maximale Anrufzeit",
			TotalExtraMinutesReload: "Insgesamt zusätzliche Minuten neu geladen",
			TotalUsedMinutes: "Gesamtverbrauch der Minuten"
		},
		NavLink: {
			Tickets: "Tickets",
			UploadDocument: "Upload uw documenten in het ticket",
			Ticket: "Erhaltene Dateien",
			NoFileReceived: "In diesem Ticket gibt es keine erhaltene Datei!",
			UploadLogo: "Logo hochladen",
			Dashboard: "Dashboard",
			Company: "Firma",
			MyCompany: "Firma",
			Products: "Produkte",
			Documentation: "Dokumentation",
			UserManagement: "Benutzerverwaltung",
			SignOut: "Abmelden",
			CallLogs: "Anrufliste",
			Call: "Anruf",
			Received: "Erhaltene",
			Files: "Dokumente",
			Troubleshooting: "Problemlösung",
			ProductsSelection: "Produktauswahl",
			ChangeProduct: "Produkt wechseln",
			Settings: "Einstellungen",
			Help: "Hilfe",
			Teams: "Gruppen",
			TicketCall: "Call from ticket", 
			PrivacyPolicy: "Privacy policy",
			AccessWithHololens: "Log in with Hololens",
			Qrcode: "QR-Code",
		},
		Dashboard: {
			cameraError: "Bitte schließen Sie die Kamera an",
			cameraErrorDescription: "Überprüfen Sie, ob die Site die Kamera erlaubt hat",
			activeCallers: "Aktive Operators",
			connecting: "Verbindung...",
			activeCalls: "Aktive Anrufe",
			noOngoingCalls: "Kein Operator im Anruf",
			inQueue: "in der Warteschlange.",
			noCallersInQueue: "Kein Operator in der Warteschlange",
		},
		Help: "Hilfe",
		HelpProperties: {
			Message: "Beschreiben Sie das aufgetretene Problem (geben Sie die Eigenschaften des Geräts an): ",
			Send: "Senden",
		},
		Teams: {
			TitleName: "Teamname...",
			Name: "Name",
			listTitle: "Gruppen",
			NoTeamSelected: "Keine Gruppe ausgewählt",
			SelectTeam: "Bitte wählen Sie eine Gruppe aus",
			CreateButton: "Gruppe erstellen",
			NumberOfUsers: "Anzahl der Benutzer",
		}
	},
	es: {
		Id: "es-ES",
		Modal: {
			Ok: "Ok",
			Cancel: "Borrar",
			DeleteUser: "¿Estás seguro de eliminar este usuario:",
			DeleteTeam: "¿Estás seguro de eliminar este equipo: ",
			BlockUser: "¿Estás seguro de bloquear este usuario: ",
			UnblockUser: "¿Estás seguro de desbloquear este usuario: ",
			UserLink: "Se ha generado un nuevo enlace para usuario - todos los enlaces anteriores son inválidos.",
			Copy: "Copiar en el portapapeles",
			LinkToProduct: "Conectar a Producto",
			Redirect: "Direccionar a"
		},
		Sort: {
			Name: "Nombre",
			Date: "Fecha",
			Type: "Tipo",
			Official: "Oficiales",
			ShowOfficial: "Visualizando sólo archivos oficiales",
			ShowAll: "Visualizando todos los archivos",
			FilesAmount: "Número archivo: ",
			DiskSpace: "Espacio del disco: ",
		},
		Toast: {
			TicketFileLoading: "Se está cargando un archivo, ¡espera!",
			DocumentUploadedSuccess: "Documento cargado con éxito en el ticket",
			UserIsBlocked: "Esta cuenta está bloqueada",
			OpenNavMenu: "Abrir/cerrar el menú de navegación",
			ClickToModify: "Hacer clic para editar",
			ChangeRoleButton: "Cambiar rol",
			TicketMergeSuccessful: "Tickets correctamente unidos",
			TicketMergeFailed: "Error al juntar los tichets",
			TicketDeleteSuccessful: "Ticket borrado con éxito",
			TicketDeleteFailed: "Cancelación del ticket fallida",
			TicketsReportRequestSuccessful: "Informe del producto solicitado con éxito",
			TicketsReportRequestFailed: "El informe solicitado aún no está completo",
			Error: "Error",
			Info: "Informaciones",
			Warning: "Cuidado",
			Success: "Completado",
			UserTempSuccess: "Usuario temporal creado con éxito.",
			UserTempError: "No se puede crear un usuario temporal.",
			UserTokenSuccess: "Acceso del usuario generado con éxito.",
			UserTokenError: "No se puede generar el acceso del usuario.",
			UserDeletedSuccess: "Usuario eliminado con éxito.",
			UserDeletedError: "No se puede eliminar el usuario.",
			UserBlockSuccess: "Usuario bloqueado con éxito.",
			UserBlockError: "No se puede bloquear el usuario.",
			UserUnblockSuccess: "Usuario desbloqueado con éxito.",
			UserUnblockError: "No se puede desbloquear el usuario.",
			AllowAccessToTemporaryUsers: "Permitir el acceso a los usuarios temporales",
			TeamCreationSuccess: "Grupo creado con éxito.",
			TeamCreationError: "Creación del grupo fallida.",
			TeamEditSuccess: "Grupo actualizado con éxito.",
			TeamEditError: "Actualización del grupo fallida.",
			TeamDeletedSuccess: "Grupo eliminado con éxito.",
			TeamDeletedError: "Eliminación del grupo fallida.",
			UploadingLogoError: "Carga del logo fallida",
			LicenseExpiration: "Licencia en expiración",
			LicenseExpirationInfo: "Tu licencia expirará entre ",
			Expired: "Su licencia ha expirado. Ya no podrás acceder a esta cuenta en breve.",
			Day: "día",
			Days: "días",
			DontShowAgain: "No mostrar más",

			TicketUpdated: "Entrada actualizada con éxito.",
			TicketUpdatedFailed: "Actualización de la entrada fallida.",
			CompanyCreeated: "Empresa creada con éxito.",
			CompanyCreeatedFailed: "Creación de la empresa fallida.",
			CompanyUpdated: "Empresa actualizada con éxito.",
			CompanyUpdatedFailed: "Actualización de la empresa fallida.",
			CompanyDeleted: "Empresa eliminada con éxito.",
			CompanyDeletedFailed: "Eliminación de la empresa fallida.",

			UserEditSuccess: "Usuario actualizado con éxito.",
			UserEditError: "No se puede actualizar el usuario.",
			UserResetSuccess: "Claves del usuario restauradas con éxito.",
			UserResetError: "No se pueden restaurar las claves del usuario.",
			HintTitle: "Consejo:",
			HintBodyAR: "Poner la cámara a una superficie para <br> hacer aparecer el círculo <br> Tocar la pantalla para posicionar el modelo",
			HintBody: "Usa dos dedos para mover el gemelo digital",
			HintBodyPart1: "Un dedo para girarlo",
			HintBodyPart2: "Pinchar para hacer zoom",
			SaveClipsDataSuccess: "Datos de animación guardados con éxito.",
			SaveClipsDataFail: "Error al guardar los datos de animación.",
			LogoutMessage: "Está conectado a otro ordenador. Tu cuenta se está desconectando.",
			MobileMessageHeader: "Aviso",
			MobileMessageBody: "Sonidos activados",
			AppCreated: "Aplicación creada con éxito.",
			AppCreateFailed: "Creación de la aplicación fallida.",
			AppRemoved: "Aplicación eliminada con éxito.",
			AppRemoveFailed: "Eliminación de la aplicación fallida.",
			IosMessageError: "Utilizar Safari como tu navegador si quieres hacer la llamada",
		},
		BrowserWarning: {
			Alert: "Es posible que el navegador que utiliza no sea compatible. Por favor, utilizar Chrome en PC o Safari en MacOS/iOS.",
			Title: "Incompatibilidad del navegador.",
			Body: "Tu navegador no admite videollamadas. Por favor, instalar uno de los navegadores listados a continuación.",
			Continue: "Si igual deseas ir adelante, pulsar el botón en la parte inferior de la página.",
			ContinueButton: "Continuar",
		},
		PrivacyPolicy: {
			CookieTitle: "Aceptar cookies",
			CookieText: "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. ",
			ReadPrivacyPolicy: "Leer la política de privacidad",
			PrivacyTitle: "Política de privacidad",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "Aceptar y proceder",
			Exit: "Salir",
			Download: "Descargar",
			Back: "Volver",
		},
		Cancel: "Borrar",
		Done: "Completado",
		Select: "Seleccionar",
		RemoveLogo: "Quitar Logo",
		LastModification: "Última modificación",
		Rotation: "Rotazione",
		ZMove: "Mover en profundidad",
		XMove: "Mover en horizontal",
		ObjectSettings: "Impostazioni modello",
		ActiveCall: {
			UnlockExpert: "Desbloquear experto",
			SelectExpert: "Seleccionar experto",
			RotatePhone: "Girar el móvil en horizontal (landscape)",
			NoTimeRemaining: "No hay minutos más. No se puede contestar a las llamadas",
			OpenCallHeader: "Solicitar apoyo",
			OpenCallButtonCompany: "Apoyo de la empresa",
			OpenCallButtonTeam: "Apoyo del equipo",
			OpenCallBody: "¿Quieres abrir la llamada?",
			ImageCaptured: "Imagen de pantalla guardada.",
			VideoCaptureStart: "Se ha comenzado la grabación de pantalla",
			VideoCaptureAdded: "Añadido evento de grabación de pantalla.",
			VideoUploaded: "Video subido.",
			FileSent: "Archivo enviado al Operador",
			FileUploadedAndSent: "Archivo cargado y enviado al Operador",
			AnnotationUploaded: "Anotaciones cargadas en el registro.",
			FailedToAddCallLog: "No se puede añadir un evento al registro de llamadas",
			EndCall: "Cerrar llamada",
			ShowCamera: "Mostrar video cámara",
			Mute: "Desactivar micrófono",
			StartRecording: "Comienza a grabar",
			Unmute: "Activar micrófono",
			FloatingWindow: "Ventana flotante",
			ToggleFullscreen: "Activar/Desactivar la pantalla completa",
			ToggleLocation: "Crear anotaciones",
			ToggleProductInfo: "Activar/Desactivar informaciones del producto",
			SendingFile: "Envío de archivos",
			Call: "Llamar",
			HideCamera: "Ocultar cámara",
			ScreenShot: "Hacer una captura de pantalla",
			SwitchCamera: "Cambiar cámara",
			ChangeVisualization: "Cambiar visualización",
			WebcamUnabled: "No se permitió el uso de la webcam",
			AnnotationConfirm: "Confirmar",
			AnnotationUndo: "Borrar",
			AnnotationDraw: "Dibujar",
			AnnotationCircle: "Circulo",
			AnnotationArrow: "Flecha",
			AlertHeader: "Subir archivos",
			AlertBody: "Salir de la llamada tan pronto como los archivos se suban al servidor.",
			SnapshotReceived: "Snapshot recibido del Operador",
			EnableGenericExpertUser: "Solicitar apoyo",
			CompanyHelp: "Desbloquear Empresa",
			TeamHelp: "Desbloquear Equipo",
			SelectProduct: "Cambiar producto",
			OpenToGeneric: "Apoyo requerido",
			OpenToTeam: "Se requiere apoyo del equipo",
			ChangeResolution: "Cambiar resolución",
			PositionModel: "Posicionar modelo",
			ModelOpenToast: "El modelo ha sido abierto",
			ModelPositioningToast: "El modelo ha sido posicionado",
			ShareScreen: "Share screen",
		},
		CompanyProperties: {
			CompanyName: "Nombre empresa",
		},
		Error: "Hay un error.",
		Home: "Pantalla principal",
		TestString: "Texto de prueba",
		TestHeader: "Título de prueba",
		Test: {
			String: "Texto de prueba",
			Header: "Título de prueba",
		},
		Languages: {
			en: "Inglés",
			it: "Italiano",
		},
		Search: "Buscar",
		Save: "Guardar",
		Delete: "Borrar",
		ResendCredentials: "Reenviar claves",
		Credentials: "Claves",
		Resend: "Reenviar",
		Saving: "Guardando...",
		Loading: "Cargando...",
		WebVideo: "Video Web",
		VideoChat: "Video Chat",
		Products: "Productos",
		BoostrapCSS: "Bootstrap CSS",
		ProductsProperties: {
			QRCode: "QR-Code",
			QRCodeDescription: "Aplicar este QR-Code al producto físico y escanearlo desde la aplicación Holo-Maintenance después de iniciar sesión como Operador para acceder rápidamente a este producto",
			Created: "Creado",
			Documentation: "Documentación",
			EditedBy: "Modificado de",
			CallLog: "Registro llamadas",
			New: "Nuevo producto",
			LinkDocument: "Adjuntar el documento",
			LinkTeam: "Adjuntar al grupo",
			LinkProduct: "Adjuntar el producto",
			MakeOfficial: "Hacer oficial",
			Linked: "Adjunto",
			TeamName: "Nombre grupo",
			Filetype: "Tipo archivo",
			Filename: "Nombre archivo",
			FilePreview: "Anteprima",
			NotFound: "Producto no encontrado",
			UploadDocument: "Cargar documento",
			NoProducts: "No hay productos",
			Troubleshooting: "Solución de problemas",
			ProductInformation: "Informaciones de producto",
			ProductCode: "Código producto",
			ProductDescription: "Descripción",
			ProductImage: "Imagen del producto",
			ProductName: "Nombre del producto",
			ProductShortName: "Nombre (corto) del producto",
			ProductTeams: "En los grupos: ",
			ProductTeam: "En el grupo: ",
			LastUpdated: "Última actualización",
			NoProductLinked: "Ningúna conexión",
			GenericProductLinked: "Genérico",
		},
		Documentation: "Documentación",
		DocumentationModal: {
			Title: "Borrar archivos",
			Text: "Estás borrando estos archivos: ",
		},
		DocumentationDetails: {
			ReloadModel: "Recargar informaciones",
			TakeData: "Datos de animación: ",
			ClipsAmount: "Número de animaciones: ",
			UploadError: "Algo salió mal al cargar el archivo",
			SearchPlaceholder: "Buscando un archivo...",
			Rejected: "fue rechazado: el tamaño del archivo es demasiado grande o el tipo no es compatible.",
			Details: "Detalles",
			Name: "Nombre del archivo: ",
			Type: "Tipo del archivo: ",
			Size: "Tamaño del archivo: ",
			Added: "Adjunto: ",
			LinkedProducts: "Adjunto a: ",
			SelectedFiles: "Archivos seleccionados: ",
			Deleting: "Borrando...",
			TakeNumber: "Animación ",
			TakeText: "Texto visual: ",
			TakeSound: "Texto hablado: ",
			PreviewTakeSound: "Anteprima audio",
			StopPreviewTakeSound: "Parar anteprima",
			CopyPreviewTakeSound: "Generar",
			CopyPreviewTakeSounds: "Generar todos los audios",
			SaveTakeData: "Guardar los datos de animación",
		},
		Troubleshooting: {
			Answer: "Respuesta",
			AnswerPlaceholder: "Escribir aquí la respuesta.",
			EditQuestion: "Modificar la pregunta",
			NewQuestion: "Nueva pregunta",
			NewAnswer: "Nueva respuesta",
			AddAnswer: "Añadir la respuesta",
			SaveAnswer: "Guardar la respuesta",
			AddDocument: "Añadir documento",
			Completed: "Solución del problema alcanzada"
		},
		TroubleshootingLanguages: {
			SelectedTextSingular: "1 idioma seleccionado",
			SelectedTextPlural: "idiomas seleccionados",
			SelectedTextNone: "No se ha seleccionado ningún idioma",
			NoneAvailable: "No se ha seleccionado ningún idioma",
		},
		WorkLog: {
			Title: "Registro Llamadas",
		},
		Ticket: {
			CreateNewTicket: "Crear un nuevo billete",
			TicketCreatedSuccess: "Billete creado con éxito",
			TicketCreatedFailed: "Ticket canceled",
			AddToSummary: "Añadir al resumen",
			AddToSummaryPlaceholder: "INSERTAR EL TEXTO QUE QUIERES AÑADIR AL RESUMES...",
			MergeDeleteError: "Sólo puede realizar esta operación en sus ticket",
			OperatorPosition: "Posición del operador capturada",
			OperatorPositionDescription: "El Operador se encuentra en (latitud, longitud): ",
			MergeHeader: "Unión de ticket",
			MergeBody: "Los eventos de los dos tickets pueden superponerse",
			DeleteHeader: "Cancelación del ticket",
			DeleteBody: "Los tichets seleccionados serán retirados",
			LastModificationDate: "Fecha de la última modificación",
			CannotModifySummary: "Sólo puedes añadir texto",
			OperatorNameChange: "El nombre del operador sólo puede ser modificado por el autor de la entrada",
			ReceiveCallLogViaMail: "Envío del informe",
			ReceiveCallLogViaMailBody: "El informe se enviará por correo electrónico",
			SendMailReport: "Enviar informe",
			ExportPdf: "Exportar (PDF)",
			ExportPdfNoImage: "Exportar (PDF sin imagenes)",
			ExportCsv: "Exportar (CSV)",
			ProductReport: "Solicitar informe",
			Save: "Guardar",
			Cancel: "Borrar",
			Submit: "Buscar",
			SearchProduct: "Buscar productos",
			ProductName: "Nombre del producto",
			ProductCode: "Código del producto",
			CompanyName: "Nombre de la empresa",
			LastUpdated: "Última actualización",
			CompanyCode: "Código de la empresa",
			DateCreated: "Fecha de creación",
			OperatorName: "Nombre del operador",
			Status: "Estado",
			ExpertName: "Nombre del experto",
			Summary: "Resumen",
			Timeline: "Cronología de eventos",
			Export: "Exportar",
			FilterByDate: "Filtrar por fecha",
			Login: "Sesión empezada",
			SessionEnded: "Fin de la sesión",
			Enqueue: "En cola",
			TroubleshootingTreeClose: "Acción",
			TroubleshootingTreeOpen: "Acción",
			TroubleshootingTreeReset: "Acción",
			ParticipantJoin: "Un experto está entrando en la llamada",
			AnswerCall: "Respuesta",
			VideoCapturedStarted: "Grabación de video empezada",
			FileOpen: "Documento abierto",
			FileSent: "Documento enviado",
			SignatureCaptured: "Firma capturada",
			ImageCaptured: "Imagen capturada",
			VideoCapturedEnded: "Video grabado",
			AnnotationSent: "Acción",
			ModelOpen: "Archivo 3D abierto",
			EndedCall: "Llamada cerrada",
			ProductDownload: "Producto descargado",
			AnnotationDeleted: "Acción",

			ModelPosition: "Modelo 3D reposicionado",
			ProductChanged: "Producto cambiado",
			CallPublished: "Solicitud adicional de un experto",
			CallPublishedTeam: "Solicitud de un experto adicional de su grupo",

			LoginDescription: "El usuario está conectado.",
			SessionEndedDescription: " ",
			ParticipantJoinDescription: "El experto está entrando en la llamada: ",
			EnqueueDescription: "Estás en cola, esperando que un experto remoto se conecte...",
			TroubleshootingTreeCloseDescription: "Cuando llegues al final del árbol de solución de problemas, abrir un archivo.",
			TroubleshootingTreeOpenDescription: "Árbol de solución de problemas inicializado.",
			TroubleshootingTreeResetDescription: "Árbol de solución de problemas restaurado.",
			AnswerCallDescription: "Conexión con un experto remoto.",
			VideoCapturedStartedDescription: "El experto remoto comenzó una grabación de video.",
			FileOpenDescription: "Navegación: ",
			FileSentDescription: "Recibiendo el archivo: ",
			FileSentFromDescription: "Recepción del archivo de ",
			SignatureCapturedDescription: "Firma de autorización guardada",
			ImageCapturedDescription: "El experto remoto guardó una captura de pantalla",
			VideoCapturedEndedDescription: "Grabación de la sesión terminada",
			AnnotationSentDescription: "El experto remoto envió una anotación",
			ModelOpenDescription: "Descargando el modelo 3D: ",
			EndedCallDescription: " ",
			ProductDownloadDescription: "Datos del producto descargados",
			ProductChangedDescription: "El experto cambió el producto a ",
			AnnotationDeletedDescription: "Anotaciones eliminadas por el experto",
			VideoCaptureDescription: "El experto remoto comenzó una grabación de video.",
			DocumentSentDescription: "Navegación: ",
			NoTickets: "No tickets to show",
			TicketStatusChanged: "Changed ticket status",
			TicketStatusChangedDescription: "Status changed to ",
		},
		TicketStatus: {
			SelectStatus: "Seleccionar el estado",
			Open: "Abierto",
			Working: "Está funcionando",
			WaitingForFeedback: "Esperando un feedback",
			Solved: "Resuelto",
			ClosedButNotSolved: "Cerrado pero no resuelto",
			NeedFurtureActivity: "Se requiere una actividad futura",
			NoNeedToSolved: "No se necesita ninguna solución",
		},
		Upload: {
			UploadingConfirmation: "El logo ha sido subido",
			Drag: "Arrastrar un archivo aquí para subirlo",
			Drop: "Soltar el archivo para empezar a subirlo",
		},
		Call: {
			Mute: "Silenciados",
			Hangup: "Cerrar",
			SendClick: "Enviar un clic",
		},
		CallLogList: {
			Selection: "Seleccionar",
			Product: "Producto",
			TicketID: "ID ticket",
			Started: "Comenzado",
			Operator: "Operador",
			Expert: "Experto",
			Status: "Estado",
			Summary: "Descripción",
		},
		UserManagement: "Gestión usuario",
		UserType: {
			Operator: "Operador",
			CompanyAdmin: "	Administrador de la empresa",
			Expert: "	Experto remoto",
			SuperAdmin: "Super Administrador",
			Root: "Root",
		},
		Users: {
			IsLinked: "Is Linked",
			Move: "Move",
			LoggedUserError: "You are not able to create users",
			CompanyDomainError: "Company domain does not exist",
			EmailError: "Invalid email address",
			FirstNameError: "Invalid first name",
			LastNameError: "Invalid last name",
			PhoneError: "Phone number not valid",
			RoleError: "Invalid role",
			UserNameEmpty: "Empty username",
			IllegalCharacterInUsername: "Invalid characters in username",
			UsernameAlreadyExists: "Username already exists",
			
			AddAuthCode: "Generate API access key",
			Code: "Authorization codes",
			AddUser: "Crear usuario",
			AddTempUser: "Crear usuario temporal",
			UserName: "Usuario",
			FirstName: "Nombre",
			LastName: "Appelidos",
			Email: "Correo electrónico",
			Phone: "Teléfono",
			Role: "Rol",
			Delete: "Borrar",
			Block: "Bloquear",
			Unblock: "Desbloquear",
			Edit: "Modificar",
			CopyLink: "Generar Enlace",
			Users: "Usuarios",
			ActiveUsers: "Usuarios activos",
			BlockedUsers: "Usuarios bloqueados",
			Blocked: "BLOQUEADO",
			NotAllowed: "Prhibido",
			CompanyAdmins: "Administradores de la Empresa",
			Experts: "Expertos",
			Operators: "Operadores",
			Temporary: "Operadores temporales",
			TemporaryUser: "Usuario temporal",
			CreatedAt: "Hacia horas",
			CreatedNow: " - Hora"
		},
		Apps: {
			Id: "Id",
			Name: "Nombre",
			Ip: "Ip",
			CreationDate: "Fecha de creación",
			AccessDate: "Fecha de acceso",
			Authorized: "Autorizada",
			Create: "Crear App",
			ListTitle: "Aplicaciones",
			Save: "Guardar",
			Remove: "Eliminar",
			SelectApp: "Por favor, seleccionar una aplicación",
			NoAppSelected: "No se ha seleccionado ninguna aplicación",
		},

		Companies: {
			Reset: "Reset",
			LicensesResetSuccessfully: "Licencias restablecidas con éxito",
			LicensesResetError: "Error al restablecer las licencias",
			LicensesDeletedSuccess: "Licencias eliminadas con éxito",
			LicensesDeletedError: "Error en la eliminación de licencias",
			NewLicense: "Nueva licencia",
			Disable: "Desactivar",
			LicenseNumber: "Número de licencia",
			Plan: "Plan",
			SubscriptionActivated: "Suscripción activada, ya puede cerrar el navegador",
			SubscriptionError: "Error durante la activación de la suscripción",
			LicensesSuccess: "Licencias creadas con éxito",
			LicensesError: "Error durante la creación de licencias",
			MissingCompanyName: "El nombre de la empresa está vacío",
			CompanyNameAlreadyExists: "El nombre de la empresa ya existe",
			MissingAddress: "Falta el correo electrónico",
			MissingOfficialName: "Falta el nombre oficial",
			MissingLicenseInformation: "Falta la información de la licencia",
			LicenseMustBeANumber: "La información de la licencia debe ser un número",
			MissingCity: "Falta la ciudad",
			MissingPostCode: "Falta el código postal",
			MissingCountry: "Falta el país",
			NoMainCompaniesAvailable: "No hay empresa principal disponible",
			SubordinateCompanies: "Empresas subjectas",
			Subordinate: "Subjecta",
			ToCompany: "a la empresa",
			MakeSubordinateError: "Eliminar los productos antes de cambiarlos a un estado subordinado",
			RenewalMonths: "Meses de renovación",
			CreationDate: "Fecha de creación",
			Name: "Nombre",
			OfficialName: "Nombre oficial",
			Address: "Dirección",
			PostCode: "Código postal",
			State: "Estado",
			Country: "País",
			LicenseInformation: "Licencias totales",
			NoLicenses: "Ningúna licencia",
			AllowTemporaryUsers: "Permitir a los usuarios temporales",
			UploadSizeLimit: "Tamaño máximo de carga de archivos (MB)",
			StorageLimit: "Limitar archivos de archivación (GB)",
			allowArFunctions: "Permitir funzionalidad AR",
			allowTeams: "Permitir el utilizo de los  Grupos",
			allowApiUsage: "Permitir el utilizo de las API",
			allowMinuteRate: "Permitir el utilizo de la tasa por minuto",
			NoCompanySelected: "No se ha seleccionado ninguna empresa",
			SelectCompany: "Por favor, seleccionar una empresa",
			ExpirationDate: "Fecha de caducidad",	
			City: "Ciudad",
			Create: "Crear empresa",
			listTitle: "Empresas",
			Delete: "Borrar",
			Save: "Guardar",
			NotificationsEmail: "Correo electrónico para ser notificado",
			MaximumCallTime: "Tiempo máximo de llamada",
			TotalExtraMinutesReload: "Total de minutos extra recargados",
			TotalUsedMinutes: "Total de minutos consumidos"
		},
		NavLink: {
			Tickets: "Solicitudes",
			UploadDocument: "Cargue sus documentos en el ticket",
			Ticket: "Archivos recibidos",
			NoFileReceived: "¡No se han recibido archivos en esta entrada!",
			UploadLogo: "Subir logo",
			Dashboard: "Tablero de mandos",
			Company: "Empresa",
			MyCompany: "Empresa",
			Products: "Productos",
			Documentation: "Documentación",
			UserManagement: "Gestión usuario",
			SignOut: "Salir",
			CallLogs: "Registro Llamadas",
			Call: "Llamada",
			Received: "Recibidos",
			Files: "Documentos",
			Troubleshooting: "Solución de problemas",
			ProductsSelection: "Seleccionar Productos",
			ChangeProduct: "Cambiar Producto",
			Settings: "Ajustes",
			Help: "Ayuda",
			Teams: "Grupos",
			TicketCall: "Call from ticket", 
			PrivacyPolicy: "Privacy policy",
			AccessWithHololens: "Log in with Hololens",
			Qrcode: "QR-Code",
		},
		Dashboard: {
			cameraError: "Por favor, conectar la cámara",
			cameraErrorDescription: "Comprobar que el sitio tiene permiso para usar la cámara.",
			activeCallers: "Operadores activados",
			connecting: "Conectando...",
			activeCalls: "Llamadas activadas",
			noOngoingCalls: "No hay operador en llamada",
			inQueue: "En cola",
			noCallersInQueue: "No hay ningún operador a continuación",
		},
		Help: "Ayuda",
		HelpProperties: {
			Message: "Describir el problema que está experimentando (especificar las características del dispositivo): ",
			Send: "Enviar",
		},
		Teams: {
			TitleName: "Nombre del equipo...",
			Name: "Nombre",
			listTitle: "Grupos",
			NoTeamSelected: "Ningún grupo seleccionado",
			SelectTeam: "Por favor, seleccionar un grupo",
			CreateButton: "Crear grupo",
			NumberOfUsers: "Número de usuarios",
		}
	},
	en: {
		Id: "en-US",
		Modal: {
			AuthCode: "Copy the following code in the request header",
			AuthEntryPoint: "Insert the entry point of your site",
			Ok: "Ok",
			Cancel: "Cancel",
			DeleteUser: "Are you sure you want to delete this user: ",
			DeleteTeam: "Are you sure you want to delete this team: ",
			BlockUser: "Are you sure you want to block user: ",
			UnblockUser: "Are you sure you want to unblock user: ",
			UserLink: "New user link generated - any previous links have been rendered invalid.",
			Copy: "Copy to clipboard",
			LinkToProduct: "Link to Product",
			Redirect: "Redirect to",
			EnterSignature: "Enter signature",
		},
		Sort: {
			Name: "Name",
			Date: "Date",
			Type: "Type",
			Official: "Officials",
			ShowOfficial: "Seeing official files only",
			ShowAll: "Seeing all files",
			FilesAmount: "Files count: ",
			DiskSpace: "Disk space: ",
		},
		Toast: {
			ConnectWithAnotherAccount: "Or log in with another account ",
			UnlicensedUserLogoutMessage: "You don't have any licenses, contact your company admin.",
			ErrorHeader: "Error:",
			ContactAdminError: "Contact your company admin to get access",
			TicketFileLoading: "A file is uploading, wait!",
			DocumentUploadedSuccess: "Document uploaded successfully in the ticket",
			UserIsBlocked: "This account is blocked",
			OpenNavMenu: "Open/close navigation menu",
			ClickToModify: "Click to modify",
			ChangeRoleButton: "Switch Role",
			TicketMergeSuccessful: "Tickets merged successfully",
			TicketMergeFailed: "Tickets merge failed",
			TicketDeleteSuccessful: "Ticket delete succeded",
			TicketDeleteFailed: "Ticket delete failed",
			TicketsReportRequestSuccessful: "Product report request successfully",
			TicketsReportRequestFailed: "The requested product report has not been completed yet",
			Error: "Error",
			Info: "Info",
			Warning: "Warning",
			Success: "Success",
			UserTempSuccess: "Temporary user created successfully.",
			UserTempError: "Failed to create temporary user.",
			UserTokenSuccess: "User token generated successfully.",
			UserTokenError: "Failed to generate user token.",
			UserDeletedSuccess: "User deleted.",
			UserDeletedError: "Failed to delete user.",
			UserBlockSuccess: "User blocked.",
			UserBlockError: "Failed to block user.",
			UserUnblockSuccess: "User unblocked.",
			UserUnblockError: "Failed to unblock user.",
			AllowAccessToTemporaryUsers: "Allow access to temporary users",
			TeamCreationSuccess: "Team created successfully.",
			TeamCreationError: "Failed to create team.",
			TeamEditSuccess: "Team updated successfully.",
			TeamEditError: "Failed to update team.",
			TeamDeletedSuccess: "Team deleted successfully.",
			TeamDeletedError: "Failed to delete team.",
			UploadingLogoError: "Failed to upload logo",
			LicenseExpiration: "License expiring",
			LicenseExpirationInfo: "Your license will expire in ",
			Expired: "Your license is expired. You will no longer be able to access your account shortly.",
			Day: "day",
			Days: "days",
			DontShowAgain: "Don't show again",

			TicketUpdated: "Call Log Updated successfully.",
			TicketUpdatedFailed: "Call Log Update failed.",
			CompanyCreeated: "Company created successfully.",
			CompanyCreeatedFailed: "Company creation failed.",
			CompanyUpdated: "Company updated successfully.",
			CompanyUpdatedFailed: "Company update failed.",
			CompanyDeleted: "Company deleted successfully.",
			CompanyDeletedFailed: "Company delete failed.",
			MobileMessageHeader: "Attention",
			MobileMessageBody: "Sounds enabled",
			UserEditSuccess: "User updated successfully.",
			UserEditError: "User update failed.",
			UserResetSuccess: "User credentials reset successful.",
			UserResetError: "User credentials reset failed.",
			HintTitle: "Hint:",
			HintBodyAR: "Point the camera to a surface to <br> make the circle appear<br>Tap on the screen to position the model ",
			HintBodyPositionModel: "Select model",
			HintBodyPositionModelPart1: "in the window",
			HintBodyPositionModelPart2: "on the right",
			HintBody: "Use two fingers to move the digital twin",
			HintBodyPart1: "One finger to rotate it",
			HintBodyPart2: "Pinch to zoom",
			SaveClipsDataSuccess: "Successfully saved clips data.",
			SaveClipsDataFail: "Failed to save clips data.",
			LogoutMessage: "You've logged on another computer. You're about to be disconnected.",

			HelpRequested: "Help request successfully sent.",
			HelpRequestFailed: "Help request failed to send.",

			AppCreated: "App created successfully.",
			AppCreateFailed: "App creation failed.",
			AppRemoved: "App removed successfully.",
			AppRemoveFailed: "App remotion failed.",
			IosMessageError: "Use Safari if you want to do a call",
		},
		BrowserWarning: {
			Alert: "The browser you are using may not be fully supported. Please use Chrome on PC or Safari on MacOS/iOS.",
			Title: "Incompatible browser.",
			Body: "We have detected that your browser does not support video calls. Please use one of the compatible browsers listed below.",
			Continue: "If you want to continue anyway, press the button below.",
			ContinueButton: "Continue",
		},
		PrivacyPolicy: {
			CookieTitle: "Accept Cookies",
			CookieText: "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. ",
			ReadPrivacyPolicy: "Read privacy policy",
			PrivacyTitle: "Privacy policy",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "Accept and proceed",
			Exit: "Exit",
			Download: "Download",
			Back: "Back",
		},
		Cancel: "Cancel",
		Done: "Done",
		Select: "Select",
		RemoveLogo: "Remove Logo",
		LastModification: "Last modification",
		Rotation: "Rotation",
		ZMove: "Move in depth",
		XMove: "Move horizontally",
		YMove: "Move vertically",
		ObjectSettings: "Object Settings",
		ActiveCall: {
			WaitForLoading: "Wait while the previous video has been loaded",
			UnlockExpert: "Unlock expert",
			SelectExpert: "Select expert",
			RotatePhone: "Please turn your device to landscape mode",
			NoTimeRemaining: "Not minutes remaining. You cannot answer the call.",
			OpenCallHeader: "Request support",
			OpenCallButtonCompany: "Company support",
			OpenCallButtonTeam: "Team support",
			OpenCallBody: "Do you want to open the call?",
			ImageCaptured: "Image captured.",
			VideoCaptureStart: "Video capture started",
			VideoCaptureAdded: "Video capture event added.",
			VideoUploaded: "Video uploaded.",
			FileSent: "File sent to the Operator",
			FileUploadedAndSent: "File uploaded and sent to the Operator",
			AnnotationUploaded: "Annotation uploaded to log.",
			FailedToAddCallLog: "Failed to add call log event",
			EndCall: "End call",
			ShowCamera: "Show camera",
			Mute: "Mute microphone",
			StartRecording: "Start recording",
			Unmute: "Unmute microphone",
			ToggleFullscreen: "Toggle fullscreen",
			FloatingWindow: "Floating window",
			ToggleLocation: "Make notes",
			ToggleProductInfo: "Toggle Product info",
			SendingFile: "Sending file",
			Call: "Call",
			HideCamera: "Hide Camera",
			ScreenShot: "Screenshot",
			SwitchCamera: "Switch Camera",
			ChangeVisualization: "Change Visualization",
			WebcamUnabled: "You have not allowed your webcam",
			AnnotationConfirm: "Confirm",
			AnnotationUndo: "Undo",
			AnnotationDraw: "Draw",
			AnnotationCircle: "Circle",
			AnnotationArrow: "Arrow",
			SnapshotReceived: "Snapshot received from Operator",
			AlertHeader: "Uploading registrations",
			AlertBody: "Wait while registrations are uploaded to server",
			EnableGenericExpertUser: "Ask for support",
			CompanyHelp: "Unlock Company",
			TeamHelp: "Unlock Team",
			SelectProduct: "Change product",
			OpenToGeneric: "Company support enabled",
			OpenToTeam: "Team support enabled",
			ChangeResolution: "Change resolution",
			PositionModel: "Position model",
			ModelOpenToast: "Model has been opened",
			ModelPositioningToast: "Model has been placed",
			ShareScreen: "Share screen",
		},
		CompanyProperties: {
			CompanyName: "Company name",
		},
		Error: "An error occured.",
		Home: "Home",
		TestString: "This is a test string",
		TestHeader: "Test header",
		Test: {
			String: "This is a test string",
			Header: "Test header",
		},
		Languages: {
			en: "English",
			it: "Italian",
		},
		Search: "Search",
		Save: "Save",
		Delete: "Delete",
		ResendCredentials: "Resend credentials",
		Credentials: "Credentials",
		Resend: "Resend",
		Saving: "Saving...",
		Loading: "Loading...",
		WebVideo: "Web Video",
		VideoChat: "Video Chat",
		Products: "Products",
		ProductsList: "Products List",
		Submit: "Submit",
		SubscriberInfo: "Subscriber Info",
		CompanyDetails: "Company Details",
		AdminDetails: "Admin Details",
		BoostrapCSS: "Bootstrap CSS",
		ProductsProperties: {
			QRCode: "QR-Code",
			QRCodeDescription: "Apply this QR-Code on the physical product and scan it from a Holo-Maintenance app when logged as an Operator to access this product quickly",
			Created: "Created",
			Code: "Code",
			Documentation: "Documentation",
			EditedBy: "Edited by",
			CallLog: "Call log",
			New: "New Product",
			LinkDocument: "Link document",
			LinkTeam: "Link to team",
			LinkProduct: "Link product",
			MakeOfficial: "Make official",
			Linked: "Linked",
			TeamName: "Team name",
			Filetype: "Filetype",
			Filename: "Filename",
			FilePreview: "Preview",
			NotFound: "Product not found",
			UploadDocument: "Upload document",
			NoProducts: "No products",
			Troubleshooting: "Troubleshooting",
			ProductInformation: "Productinformation",
			ProductCode: "Product Code",
			ProductDescription: "Description",
			ProductImage: "Product image",
			ProductName: "Product name",
			ProductShortName: "Product shortname",
			ProductTeams: "In teams: ",
			ProductTeam: "In team: ",
			LastUpdated: "Last updated",
			NoProductLinked: "None",
			GenericProductLinked: "Generic",
		},
		Documentation: "Documentation",
		DocumentationModal: {
			Title: "Delete file",
			Text: "Do you want to delete these files: ",
		},
		DocumentationDetails: {
			ReloadModel: "Reload information",
			TakeData: "Animation data: ",
			ClipsAmount: "Clips amount: ",
			UploadError: "Something went wrong while uploading file",
			UploadErrorSizeFile: "File size too large",
			SearchPlaceholder: "Search for files...",
			Rejected: "was rejected because it was either too large or the filetype is not allowed.",
			Details: "Details",
			Name: "File name: ",
			Type: "File type: ",
			Size: "File size: ",
			Added: "Added: ",
			LinkedProducts: "Linked to: ",
			SelectedFiles: "Selected files: ",
			Deleting: "Deleting...",
			TakeNumber: "Clip ",
			TakeText: "Displayed text: ",
			TakeSound: "Voiced text: ",
			PreviewTakeSound: "Preview audio",
			StopPreviewTakeSound: "Stop preview",
			CopyPreviewTakeSound: "Generate",
			CopyPreviewTakeSounds: "Generate all audio",
			SaveTakeData: "Save clip data",
		},
		Troubleshooting: {
			Answer: "Answer",
			AnswerPlaceholder: "Type answer here...",
			EditQuestion: "Edit Question",
			NewQuestion: "New question",
			NewAnswer: "New answer",
			AddAnswer: "Add answer",
			SaveAnswer: "Save answer",
			AddDocument: "Add document",
			Completed: "End of troubleshooting branch"
		},
		TroubleshootingLanguages: {
			SelectedTextSingular: "1 language selected",
			SelectedTextPlural: "languages selected",
			SelectedTextNone: "No languages selected",
			NoneAvailable: "No languages available",
		},
		WorkLog: {
			Title: "Call Logs",
		},
		Ticket: {
			CreateNewTicket: "Create new ticket",
			AddToSummary: "Add to summary",
			AddToSummaryPlaceholder: "INSERT THE TEXT YOU WANT TO ADD TO THE SUMMARY...",
			MergeDeleteError: "You can perform this operation only on your own tickets",
			MergeHeader: "Tickets merge",
			MergeBody: "The ticket's events may be overlapped",
			DeleteHeader: "Tickets removal",
			DeleteBody: "The selected tickets will be deleted",
			LastModificationDate: "Last modification date",
			CannotModifySummary: "You can only add text",
			OperatorNameChange: "Operator name can be modified only by the ticket's author",
			ReceiveCallLogViaMail: "Report delivery",
			ReceiveCallLogViaMailBody: "The report will be sent via e-mail",
			SendMailReport: "Send report",
			ExportPdf: "Export (PDF)",
			ExportPdfNoImage: "Export (PDF no images)",
			ExportCsv: "Export (CSV)",
			ProductReport: "Request Report",
			Save: "Save",
			Cancel: "Cancel",
			Submit: "Search",
			SearchProduct: "Search products",
			ProductName: "Product Name",
			ProductCode: "Product Code",
			CompanyName: "Company Name",
			LastUpdated: "Last updated",
			CompanyCode: "Company Code",
			DateCreated: "Date Created",
			OperatorName: "Operator Name",
			Status: "Status",
			ExpertName: "Expert Name",
			Summary: "Summary",
			Timeline: "Timeline",
			Export: "Export",
			FilterByDate: "Filter by date",
			TicketCreatedSuccess: "Ticket created successfully",
			TicketCreatedFailed: "Ticket canceled",
			DocumentUploaded: "Document uploaded in the ticket",
			Login: "Session started",
			SessionEnded: "End session",
			OperatorPosition: "Operator Position captured",
			OperatorPositionDescription: "Operator located at (latitude, longitude): ",
			Enqueue: "Enqueue",
			TroubleshootingTreeClose: "Troubleshooting tree closed",
			TroubleshootingTreeOpen: "Troubleshooting tree opened",
			TroubleshootingTreeReset: "Troubleshooting tree reset",
			AnswerCall: "Answer",
			VideoCapturedStarted: "Video capturing started",
			FileOpen: "Document opened",
			FileSent: "Document sent",
			SignatureCaptured: "Signature captured",
			ImageCaptured: "Image captured",
			VideoCapturedEnded: "Video captured",
			AnnotationSent: "Action",
			ModelOpen: "3D file opened",
			EndedCall: "Call Ended",
			ProductDownload: "Product Downloaded",
			AnnotationDeleted: "Annotation deleted",
			ParticipantJoin: "Expert joins the call",
			ModelPosition: "3D model repositioned",
			ProductChanged: "Product changed",
			CallPublished: "Requested help from another expert",
			CallPublishedTeam: "Requested help from another expert of the same team",

			LoginDescription: "User logged in.",
			SessionEndedDescription: " ",
			EnqueueDescription: "Entering queue, awaiting Remote expert connection...",
			TroubleshootingTreeCloseDescription: "End of troubleshooting reached, opening files.",
			TroubleshootingTreeOpenDescription: "Troubleshooting initialized.",
			TroubleshootingTreeResetDescription: "Troubleshooting initialized restarted.",
			AnswerCallDescription: "Connected to remote expert.",
			VideoCapturedStartedDescription: "Remote Expert started video capture.",
			FileOpenDescription: "Browsing: ",
			ParticipantJoinDescription: "An expert join the call: ",
			FileSentDescription: "Receiving file: ",
			FileSentFromDescription: "Receiving file from ",
			SignatureCapturedDescription: "Authorization signature saved.",
			ImageCapturedDescription: "Remote Expert captured image.",
			VideoCapturedEndedDescription: "Session recording ended.",
			AnnotationSentDescription: "Annotations sent by Remote Expert.",
			ModelOpenDescription: "Downloading 3d model: ",
			EndedCallDescription: " ",
			ProductDownloadDescription: "Product data downloaded.",
			ProductChangedDescription: "The expert changed product to ",
			AnnotationDeletedDescription: "Annotations removed by operator.",
			VideoCaptureDescription: "Remote Expert started video capture.",
			DocumentSentDescription: "Browsing: ",
			NoTickets: "No tickets to show",
			TicketStatusChanged: "Changed ticket status",
			TicketStatusChangedDescription: "Status changed to ",
		},
		TicketStatus: {
			SelectStatus: "Select status",
			Open: "Open",
			Working: "Working",
			WaitingForFeedback: "Waiting for feedback",
			Solved: "Solved",
			ClosedButNotSolved: "Closed but not solved",
			NeedFurtureActivity: "Need future activity",
			NoNeedToSolved: "No need to be solved",
		},
		Upload: {
			UploadingConfirmation: "Logo has been uploaded",
			Drag: "Drag and drop files here to upload",
			Drop: "Drop to start uploading",
		},
		Call: {
			Mute: "Mute",
			Hangup: "Hangup",
			SendClick: "Send Click",
		},
		CallLogList: {
			Product: "Product",
			Selection: "Selection",
			TicketID: "TicketID",
			Started: "Started",
			Operator: "Operator",
			Expert: "Expert",
			Status: "Status",
			Summary: "Description",
		},
		UserManagement: "User management",
		UserType: {
			Operator: "Operator",
			CompanyAdmin: "	Company Administrator",
			Expert: "	Remote Expert",
			SuperAdmin: "Super Administrator",
			Root: "Root",
		},
		Users: {
			IsLinked: "Is Linked",
			Move: "Move",
			LoggedUserError: "You are not able to create users",
			CompanyDomainError: "Company domain does not exist",
			EmailError: "Invalid email address",
			FirstNameError: "Invalid first name",
			LastNameError: "Invalid last name",
			PhoneError: "Phone number not valid",
			RoleError: "Invalid role",
			UserNameEmpty: "Empty username",
			IllegalCharacterInUsername: "Invalid characters in username",
			UsernameAlreadyExists: "Username already exists",

			AddAuthCode: "Generate API access key",
			Code: "Authorization codes",
			AddUser: "Add user",
			AddTempUser: "Add temporary user",
			UserName: "User name",
			FirstName: "First name",
			LastName: "Last name",
			Email: "Email",
			Phone: "Phone",
			Role: "Role",
			Delete: "Delete",
			Block: "Block",
			Unblock: "Unblock",
			Edit: "Edit",
			CopyLink: "Generate Link",
			Users: "Users",
			ActiveUsers: "Active Users",
			BlockedUsers: "Blocked Users",
			Blocked: "BLOCKED",
			NotAllowed: "Not allowed",
			SuperAdmins: "Company Super Admins",
			CompanyAdmins: "Company Admins",
			Experts: "Experts",
			Operators: "Operators",
			Temporary: "Temporary Operators",
			TemporaryUser: "Temporary user",
			CreatedAt: " hours ago",
			CreatedNow: " - Now"
		},
		Apps: {
			Id: "Id",
			Name: "Name",
			Ip: "Ip",
			CreationDate: "CreationDate",
			AccessDate: "AccessDate",
			Authorized: "Authorized",
			Create: "Create App",
			ListTitle: "Apps",
			Save: "Save",
			Remove: "Delete",
			SelectApp: "Please select an app",
			NoAppSelected: "No app selected",
		},
		Companies: {
			Reset: "Reset",
			LicensesResetSuccessfully: "Licenses successfully reset",
			LicensesResetError: "Error while resetting licenses",
			LicensesDeletedSuccess: "Licenses successfully deleted",
			LicensesDeletedError: "Error while deleting licenses",
			NewLicense: "New License",
			Disable: "Disable",
			LicenseNumber: "License Number",
			Plan: "Plan",
			SubscriptionActivated: "Subscription activated, you can close the browser now",
			SubscriptionError: "Error during subscription activation",
			LicensesSuccess: "Licenses creation success",
			LicensesError: "Error during licenses creation",
			MissingCompanyName: "Company name empty",
			CompanyNameAlreadyExists: "Company name already exists",
			MissingAddress: "Missing address",
			MissingOfficialName: "Missing official name",
			MissingLicenseInformation: "Missing license information",
			LicenseMustBeANumber: "License information must be a number",
			MissingCity: "Missing city",
			MissingPostCode: "Missing ZIP code",
			MissingCountry: "Missing country",
			NoMainCompaniesAvailable: "No main companies available",
			SubordinateCompanies: "Subordinate companies",
			Subordinate: "Subordinate",
			ToCompany: "to the company",
			MakeSubordinateError: "There are some products in this company. Delete products before switching it to Subordinate Status.",
			RenewalMonths: "Renewal Months",
			CreationDate: "Creation Date",
			Name: "Name",
			OfficialName: "Official name",
			Address: "Address",
			PostCode: "Post code",
			State: "State",
			Country: "Country",
			LicenseInformation: "Total licenses",
			NoLicenses: "No licenses",
			AllowTemporaryUsers: "Allow temporary users",
			UploadSizeLimit: "Maximum file upload size (MB)",
			StorageLimit: "Storage Limit (GB)",
			allowArFunctions: "Allow AR functionalities",
			allowTeams: "Allow usage of Teams",
			allowApiUsage: "Allow usage of APIs",
			allowMinuteRate: "Allow minute rate",
			NoCompanySelected: "No company selected",
			SelectCompany: "Please select a company",
			City: "City",
			Create: "Create company",
			listTitle: "Companies",
			Delete: "Delete",
			Save: "Save",
			NotificationsEmail: "Notifications Email",
			ExpirationDate: "Expiration Date",
			MaximumCallTime: "Maximum Call Time",
			TotalExtraMinutesReload: "Total of extra minutes reloaded",
			TotalUsedMinutes: "Total of used minutes"
		},
		NavLink: {
			Tickets: "Tickets",
			UploadDocument: "Upload your documents in the ticket",
			Ticket: "Received files",
			NoFileReceived: "There are no received files in this ticket!",
			UploadLogo: "Upload logo",
			Dashboard: "Dashboard",
			Company: "Company",
			MyCompany: "Company",
			Products: "Products",
			Documentation: "Documentation",
			UserManagement: "User management",
			SignOut: "Sign out",
			CallLogs: "Call Logs",
			Call: "Call",
			Received: "Received",
			Files: "Files",
			Troubleshooting: "Troubleshooting",
			ProductsSelection: "Product Selection",
			ChangeProduct: "Change Product",
			Settings: "Settings",
			Help: "Help",
			Teams: "Teams",
			TicketCall: "Call from ticket", 
			PrivacyPolicy: "Privacy policy",
			AccessWithHololens: "Log in with Hololens",
			Qrcode: "Qr Code"
		},
		Dashboard: {
			cameraError: "No Camera Connected.",
			cameraErrorDescription: "Please check that the camera is allowed for this website.",
			activeCallers: "Active callers",
			connecting: "Connecting to queue...",
			activeCalls: "Active calls",
			noOngoingCalls: "No ongoing calls",
			inQueue: "in queue.",
			noCallersInQueue: "No callers in queue",
		},
		Help: "Help",
		HelpProperties: {
			Message: "Describe the problem encountered (add device specifications): ",
			Send: "Send",
		},
		Teams: {
			TitleName: "Team name...",
			Name: "Name",
			listTitle: "Teams",
			NoTeamSelected: "No team selected",
			SelectTeam: "Please select a team",
			CreateButton: "Create team",
			NumberOfUsers: "Number of users",
			None: "None",
		}
	},
	no: {
		Toast: {
			Error: "Feil",
			Info: "Informasjon",
			Warning: "Advarsel",
			Success: "Suksess",
		},
		Upload: {
			Drag: "Dra og slipp filer her for å laste opp",
			Drop: "Slipp for å starte opplasting",
		},
		BrowserWarning: {
			Title: "Inkompatibel nettleser.",
			Body: "Vi har detektert at din nettleser ikke støtter videosamtaler. Vennligst bruk en av de følgende støttede nettleserne.",
			Continue: "Hvis du vil fortsette allikevel, trykk på knappen under.",
			ContinueButton: "Fortsett",
		},
		PrivacyPolicy: {
			CookieTitle: "Cookie acceptance",
			CookieText: "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. "
				+ "COOKIE INFORMATION POLICY. ",
			ReadPrivacyPolicy: "Read privacy policy",
			PrivacyTitle: "Privacy policy",
			PrivacyText: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. "
				+ "Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. "
				+ "Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
				+ "Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			AcceptAndProceed: "Accept and proceed",
			Exit: "Exit",
			Download: "Download",
			Back: "Back",
		},
		ActiveCall: {
			EndCall: "Avslutt samtale",
			ShowCamera: "Vis kamera",
			Mute: "Slå på mikrofon",
			StartRecording: "Start opptak",
			Unmute: "Demp mikrofon",
			ToggleLocation: "Slå av/på lokasjon",
			ToggleFullscreen: "Slå av/på fullskjerm",
			ToggleProductInfo: "Skjul/Vis produktinfo",
			SendingFile: "Sender fil",
			ShareScreen: "Share screen",
		},
		CompanyProperties: {
			CompanyName: "Firmanavn",
		},
		Error: "En feil oppsto.",
		TestString: "Dette er en test streng",
		TestHeader: "Test Overskrift",
		Test: {
			String: "Dette er en test streng",
			Header: "Test Overskrift",
		},
		Home: "Hjem",
		Search: "Søk",
		Save: "Lagre",
		Loading: "Laster...",
		Saving: "Lagrer...",
		WebVideo: "Web Video",
		VideoChat: "Video Chat",
		Products: "Produkter",
		BoostrapCSS: "Bootstrap CSS",
		Troubleshooting: {
			Answer: "Svar",
			AnswerPlaceholder: "Skriv svar her...",
			EditQuestion: "Rediger spørsmål",
			AddAnswer: "Legg til svar",
			NewQuestion: "Nytt spørsmål",
			NewAnswer: "Nytt svar",
			SaveAnswer: "Lagre svar",
			AddDocument: "Legg til dokument",
		},
		Languages: {
			en: "Engelsk",
			it: "Italiensk",
		},
		ProductsProperties: {
			Created: "Opprettet",
			Documentation: "Dokumentasjon",
			EditedBy: "Endret av",
			CallLog: "Arbeidslogg",
			New: "Nytt produkt",
			NoProducts: "Ingen produkter",
			Linked: "Linket",
			Filetype: "Filtype",
			Filename: "Filnavn",
			Troubleshooting: "Feilsøking",
			ProductInformation: "Produktinformasjon",
			ProductCode: "Produktkode",
			ProductDescription: "Beskrivelse",
			ProductName: "Produktnavn",
			ProductShortName: "Product kortnavn",
			LinkDocument: "Link dokument",
			UploadDocument: "Last opp dokument",
			LastUpdated: "Sist oppdatert",
			Qrcode: "QR-Kode",
		},
		TroubleshootingLanguages: {
			NoneAvailable: "Ingen språk tilgjengelig",
			SelectedTextSingular: "1 språk valgt",
			SelectedTextPlural: "språk valgt",
			SelectedTextNone: "Ingen språk valgt",
		},
		Documentation: "Dokumenter",
		DocumentationModal: {
			Title: "Slette fil",
			Text: "Vil du virkelig slette denne filen: ",
		},
		DocumentationDetails: {
			SearchPlaceholder: "Søk etter filer...",
			Details: "Detaljer",
			Name: "Filnavn: ",
			Type: "Filtype: ",
			Size: "Filstørrelse: ",
			Added: "Lagttil: ",
			Rejected: "ble avvist fordi den var enten for stor eller filtypen er ikke tillatt.",
		},
		Cancel: "Avbryt",
		Done: "Ferdig",
		Ticket: {
			ProductName: "Produkt Navn",
			ProductCode: "Produkt Kode",
			CompanyName: "Selskaps Navn",
			LastUpdated: "Sist Oppdatert",
			CompanyCode: "Selskaps Kode",
			DateCreated: "Dato Opprettet",
			OperatorName: "Oppratær Navn",
			Status: "Status",
			ExpertName: "Ekspert Navn",
			Summary: "Log Sammendrag",
			Timeline: "Tidslinje",

			Login: "Session started",
			SessionEnded: "End session",
			Enqueue: "Enqueue",
			TroubleshootingTreeClose: "Action",
			TroubleshootingTreeOpen: "Action",
			TroubleshootingTreeReset: "Action",
			AnswerCall: "Answer",
			VideoCapturedStarted: "Video capturing started",
			FileOpen: "Document opened",
			FileSent: "Document sent",
			SignatureCaptured: "Signature captured",
			ImageCaptured: "Image captured",
			VideoCapturedEnded: "Video captured",
			AnnotationSent: "Action",
			ModelOpen: "3D file opened",
			EndedCall: "Call Ended",
			ProductDownload: "Product downloaded",
			AnnotationDeleted: "Action",

			LoginDescription: "User logged in.",
			SessionEndedDescription: "",
			EnqueueDescription: "Entering queue, awaiting Remote expert connection...",
			TroubleshootingTreeCloseDescription: "End of troubleshooting reached, opening files.",
			TroubleshootingTreeOpenDescription: "Troubleshooting initialized.",
			TroubleshootingTreeResetDescription: "Troubleshooting initialization restarted.",
			AnswerCallDescription: "Connected to remote expert.",
			VideoCapturedStartedDescription: "Remote Expert started video capture.",
			FileOpenDescription: "",
			FileSentDescription: "",
			FileSentFromDescription: "",
			ImageCapturedDescription: "",
			VideoCapturedEndedDescription: "",
			AnnotationSentDescription: "",
			ModelOpenDescription: "",
			EndedCallDescription: "",
			ProductDownloadDescription: "",
			AnnotationDeletedDescription: "",
			NoTickets: "No tickets to show",
			TicketStatusChanged: "Changed ticket status",
			TicketStatusChangedDescription: "Status changed to ",
		},
		TicketStatus: {
			Open: "Åpen",
			Working: "Under Arrbeid",
			WaitingForFeedback: "Venter på tilbakemelding",
			Solved: "Løst",
			ClosedButNotSolved: "Lukket men ikke  løst",
			NeedFurtureActivity: "Trenger mer arbeid",
			NoNeedToSolved: "Må løses",
		},
		Call: {
			Mute: "Mute",
			Hangup: "Legg på",
			SendClick: "Send klikk",
		},
		Dashboard: {
			cameraError: "Koble til web kamera.",
			cameraErrorDescription: "Denne seksjonen av applicasjonen krever et web kamera.",
			activeCallers: "Active callers",
			connecting: "Connecting to queue...",
			activeCalls: "Active calls",
			noOngoingCalls: "No ongoing calls",
			inQueue: "in queue.",
			noCallersInQueue: "No callers in queue",
		},
	},
};

