import React from "react";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { UncontrolledDropdown } from "reactstrap/lib/Uncontrolled";
import { LanguageConsumer } from "../../managers/I18n";
import { languageData } from "../../managers/lang";

export const LanguageToggler: React.FunctionComponent<{}> = () => (
	<LanguageConsumer>
		{({ currentLanguage, changeLanguage }) => (
			<UncontrolledDropdown>
				<DropdownToggle style={{ width: "102px" }} nav caret>
					{currentLanguage}
				</DropdownToggle>
				<DropdownMenu>
					{Object.keys(languageData)
						.sort()
						.map(lang => (
							<DropdownItem onClick={() => changeLanguage(lang)} key={lang}>
								{lang}
							</DropdownItem>
						))}
				</DropdownMenu>
			</UncontrolledDropdown>
		)}
	</LanguageConsumer>
);
