import * as React from "react";
import { Id } from "../../types";
import { MobileCallWindow } from "./MobileCallWindow";

interface IProps {
	id: Id;
	token: string;
	ticketId?: string;
}
export class MobileCaller extends React.Component<IProps> {
	public render() {
		// if(this.props.token == undefined){
		// 	this.props.token = "";
		// }
		return <MobileCallWindow id={this.props.id} token={this.props.token} ticketId={this.props.ticketId}/>;
	}
}
