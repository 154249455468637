export interface IDeviceStatus {
	video: boolean;
	audio: boolean;
}

export const checkMediaDevices = (deviceInfos: MediaDeviceInfo[], callBack: (deviseConnected: IDeviceStatus) => void) => {
	const deviseConnected: IDeviceStatus = {
		video: false,
		audio: false,
	};
	deviceInfos.forEach(device => {
		if (device.kind === "videoinput") {
			deviseConnected.video = true;
		}

		if (device.kind === "audioinput") {
			deviseConnected.audio = true;
		}
	});

	callBack(deviseConnected);
};

export const handleMediaDeviceError = (error: any) => {
	console.log("navigator.MediaDevices.getUserMedia error: ", error.message, error.name);
};
