import { StreamManager } from "openvidu-browser";
import * as React from "react";

export interface IVideo {
	streamManager: StreamManager;
	videoClass: string;
}

export class Video extends React.Component<IVideo> {
	public videoRef = React.createRef<HTMLVideoElement>();

	public componentDidUpdate(props: IVideo) {
		if (props && this.videoRef.current) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
		}
	}

	public componentDidMount() {
		if (this.props && this.videoRef.current) {
			this.props.streamManager.addVideoElement(this.videoRef.current);
		}
	}

	// ADDED testing video unmount
	public componentWillUnMount() {
		this.props.streamManager.removeAllVideos();
	}

	public render() {
		return <video className={this.props.videoClass} autoPlay={true} ref={this.videoRef} />;
	}
}
