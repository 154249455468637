import React from "react";
import { classNames, OriginalHtmlProps } from "../../utilities";
import "./StretchedImage.scss";

export interface IStretchedImageProps extends OriginalHtmlProps<HTMLDivElement> {
	height: number;
	imgSrc: string;
}

export const StretchedImage: React.FunctionComponent<IStretchedImageProps> = ({ className, imgSrc, height, style, ...rest }) => (
	<div {...rest} style={{ ...style, minHeight: height, height }} className={classNames("app-stretched-image", className)}>
		<img src={imgSrc} />
	</div>
);
