export function ensureTrailingSlash(path: string) {
	const trailingSlash = "/";
	const lastChar = path[path.length - 1];

	if (lastChar === trailingSlash) {
		return path;
	}

	return path + trailingSlash;
}
