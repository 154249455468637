import { AxiosInstance } from "axios";
import { IMarketplaceLicenses } from "../../types/ILicense";
import { IPurchasedSubscription } from "../../types/ISubscription";


export class HomeManager {
    private agent: AxiosInstance;
	private url: string = "LandingPage";

    constructor(agent: AxiosInstance) {
        this.agent = agent;
    }

    public postSubscriptionToken = async (token: string) => this.agent.post<IPurchasedSubscription>(`${this.url}/PostSubscriptionToken?token=${token}`);
    public activateSubscription = async (subscriptionId: string, planId: string, quantity: number, marketLicenses: IMarketplaceLicenses) => this.agent.post(`${this.url}/ActivateSubscription?subscriptionId=${subscriptionId}&planId=${planId}&quantity=${quantity}`, marketLicenses);
}