import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Card, Col, Container, CustomInput, FormGroup, Label, Row } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Form from "reactstrap/lib/Form";
import Input from "reactstrap/lib/Input";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { actions, selectUser } from "../../reducers/users";
import { Id, IUser, IUserCreate } from "../../types";
import { UserRole, UserRoleText } from "../../types/IRoles";
import { enumToArray } from "../../utilities/enum";
import { ErrorPage, IconButton, IconTextButton, RequiredLabel } from "../globalComponents";
import { getOptions } from "../globalComponents/getOptions";
import { LoadingPage } from "../globalComponents/LoadingPage";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import "./User.scss";

interface IOwnProps
{
	id: Id;
}

interface IProps extends ConnectProps, IOwnProps, InjectedToastsProps, RouteProps
{
	forcedCompanyId?: string;
	forcedTeamId?: string;
	onClosePressed?: () => void;
}

interface IUserState
{
	notFound: boolean;
	loading: boolean;
	error: boolean;
	saving: boolean;
	localItem: IUserCreate;
	nameValid: boolean;
	nameInValid: boolean;
}

class UserComponent extends React.Component<IProps, IUserState> {
	public state: IUserState = {
		notFound: true,
		loading: false,
		error: false,
		saving: false,
		localItem: {
			userName: "",
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			userRole: 1,
			isLinked: false,
			isTemporary: true,
			companyId: "",
			teamId: "",
		},
		nameValid: false,
		nameInValid: false,
	};

	public render()
	{
		const { loading, error, saving, localItem, nameValid, nameInValid } = this.state;
		if (loading) {
			return <LoadingPage />;
		}

		if (error) {
			return (
				<ErrorPage>
					<T v={"Error"} />
				</ErrorPage>
			);
		}

		return (
			<Container fluid>
				<Form onSubmit={this.handleCreateUser}>
					<Row style={{ marginTop: 15, padding: 15 }}>
						<Col sm={12} lg={8} className="user-create-container" style={{ flex: '100%', maxWidth: '100%' }}>
							<div className="user-create-actionbar">
								<div>
									<h4 className="user-create-items">
										<T v="Users.AddUser" />
									</h4>
								</div>
								<div>
									<IconButton iconName="person_add" type="submit" />
								</div>
							</div>

							<Card className="user-create-form-container">
								<CardBody>
									<Row noGutters>
										<Col className="gutter-right" xs={12}>
											<FormGroup>
												<RequiredLabel>
													<T v={"Users.UserName"} />
												</RequiredLabel>
												<Input
													required
													invalid={nameInValid}
													valid={nameValid}
													disabled={saving}
													className={saving ? "disabled" : ""}
													onBlur={this.handleUserNameValidation}
													onChange={this.handleChange("userName")}
													value={localItem.userName}
												/>
											</FormGroup>
										</Col>
										<Col className="gutter-right" xs={12} md={6}>
											<FormGroup>
												<RequiredLabel>
													<T v={"Users.FirstName"} />
												</RequiredLabel>
												<Input
													required
													disabled={saving}
													className={saving ? "disabled" : ""}
													onChange={this.handleChange("firstName")}
													value={localItem.firstName}
												/>
											</FormGroup>
										</Col>
										<Col className="gutter-right" xs={12} md={6}>
											<FormGroup>
												<RequiredLabel>
													<T v={"Users.LastName"} />
												</RequiredLabel>
												<Input
													required
													disabled={saving}
													className={saving ? "disabled" : ""}
													onChange={this.handleChange("lastName")}
													value={localItem.lastName}
												/>
											</FormGroup>
										</Col>
										<Col className="gutter-right" xs={12} md={6}>
											<FormGroup>
												<RequiredLabel>
													<T v={"Users.Email"} />
												</RequiredLabel>
												<Input
													type="email"
													required
													disabled={saving}
													className={saving ? "disabled" : ""}
													onChange={this.handleChange("email")}
													value={localItem.email}
												/>
											</FormGroup>
										</Col>
										<Col className="gutter-right" xs={12} md={6}>
											<FormGroup required>
												<RequiredLabel>
													<T v={"Users.Phone"} />
												</RequiredLabel>
												<Input
													required
													disabled={saving}
													className={saving ? "disabled" : ""}
													onChange={this.handleChange("phone")}
													value={localItem.phone}
												/>
											</FormGroup>
										</Col>
										<Col md={12} lg={4}>
											<FormGroup>
												<RequiredLabel for="Status">
													<T v="Users.Role" />
												</RequiredLabel>
												<CustomInput
													type="select"
													id="Status"
													required
													name="TicketStatus"
													value={localItem.userRole}
													onChange={this.handleChange("userRole")}
												>
													{enumToArray(UserRoleText, this.props.loggedUser.userRole).map(getOptions)}
												</CustomInput>
											</FormGroup>
										</Col>
										{/* <div style={{width: "50%", height: 58, marginLeft: 15}}>
											<Col className="gutter-right" xs={12} md={6}>
												<Row>
													<FormGroup>
														<Label>
															<T v={"Users.IsLinked"}/>
														</Label>
														<Input
															style={{ width: 28, height: 28, marginLeft: -77, marginTop: 28 }}
															type="checkbox"
															checked={localItem.isLinked}
															onChange={this.handleToggleBoolUserChange("isLinked")}
														/>
													</FormGroup>
												</Row>
											</Col>
										</div> */}
									</Row>
									<Row style={{ marginTop: 15, padding: 15, justifyContent: 'space-between' }}>
										<IconTextButton iconName="person_add" text="Users.AddUser" color="primary" type="submit" />
										<IconTextButton iconName="cancel" text="Cancel" color="secondary" onClick={this.props.onClosePressed} />
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}

	private handleToggleBoolUserChange = (fieldName: keyof IUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			localItem: {
				...this.state.localItem,
				[fieldName]: event.target.checked
			}
		});
	};

	private handleCreateUser = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log('[foo] create user: ' + this.props.forcedTeamId);
		this.state.localItem.companyId = this.props.forcedCompanyId ? this.props.forcedCompanyId : "";
		this.state.localItem.teamId = this.props.forcedTeamId ? this.props.forcedTeamId : "";
		this.props.creatUser(this.state.localItem, this.validation);
	};

	private validation = (msg: string, valid: boolean) =>
	{
		if (valid) {
			this.props.addToast({ type: ToastType.Success, msg });
			if (this.props.onClosePressed) this.props.onClosePressed();
			//this.props.history.push(`/`);
			return;
		}

		this.props.addToast({ type: ToastType.Error, msg });
	};

	private handleUserNameValidation = async () =>
	{
		if (this.state.localItem.userName === "") {
			this.setState({ nameValid: false, nameInValid: false });
			return;
		}
		const response = await serverManager.users.IsValidUsername(this.state.localItem.userName);
		console.log(this.state.localItem.userName, "Is valid: ", response.data);
		if (response.data) {
			this.setState({ nameValid: true, nameInValid: false });
		} else {
			this.setState({ nameValid: false, nameInValid: true });
		}
	};

	private handleChange = (fieldName: keyof IUser) => (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		console.log(`[foo] selected role: ${e.target.value}`)
		this.setState({
			localItem: {
				...this.state.localItem,
				[fieldName]: e.target.value,
			},
		});
	};
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
	loading: state.users.loading,
	error: state.users.error,
	notFound: state.users.notFound,
	saving: state.users.saving,
	loggedUser: state.userInfo.data,
	user: selectUser(state, ownProps.id),
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getSingleUser: actions.getSingleUser,
			updateUser: actions.updateUser,
			creatUser: actions.createUser,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;
type RouteProps = RouteComponentProps<{ id: string }>;
export const User = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(withToasts(UserComponent)));
