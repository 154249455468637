import * as React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { T } from "../../managers/I18n";

export class Panel extends React.Component {
	public render() {
		return (
			<Col sm={6} className="panel">
				<Card>
					<CardHeader>
						<T v="Test.Header" />
					</CardHeader>
					<CardBody>
						<T v="Test.String" />
					</CardBody>
				</Card>
			</Col>
		);
	}
}
