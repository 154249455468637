import "@google/model-viewer";
import {
	Device,
	OpenVidu,
	Publisher,
	PublisherProperties,
	Session,
	SignalEvent,
	Stream,
	StreamEvent,
	StreamManager,
	Subscriber,
} from "openvidu-browser";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ticketEvent } from "../../managers/DummyData";
import { actions as companyActions } from "../../reducers/company";
import { actions as productActions } from "../../reducers/products";
import { serverManager } from "../../managers/ServerManager";
import { Id, IClips, ICompany, IProduct, TicketItemType } from "../../types";
import { classNames, logger, toArray } from "../../utilities";
import { getRandomName } from "../../WebRTC/helpers";
import {
	IConnectedSession,
	IOVError,
	IOVTokenData,
	ISessionConnectionData,
	IUserType,
	OPERATOR,
	parseConnectionData,
	REMOTE_EXPERT,
	REMOTE_PARTICIPANT,
} from "../../WebRTC/RTCHelpers";
import { OVManager } from "../../WebRTC/RTCManager";
import { ISignInInfo, SignalingHelper } from "../../WebRTC/Signaling";
import { IPeerInfo } from "../../WebRTC/StreamManager";
import { Deferred, log } from "../active-call/ActiveCallUtils";
import { MobileToolbarButton } from "../active-call/ToolbarButton";
import { Icon, Spinner } from "../globalComponents";
import {
	handle3dModel,
	handleImageFile,
	handleVideoFile,
} from "../globalComponents/MobileFileHelper";
import { FbxHandler } from "../globalComponents/FbxHandler";
import "./MobileCallWindow.scss";
import { MobileRemoteVideo } from "./MobileRemoteVideo";
import { VideoView } from "./video-view";
import Container from "reactstrap/lib/Container";
import { GetLangString, T } from "../../managers/I18n";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Card from "reactstrap/lib/Card";
import { IDeviceStatus, checkMediaDevices, handleMediaDeviceError } from "../../utilities/checkDevice";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/files";
import ReactDOM from "react-dom";
import { PdfHandler } from "../globalComponents/PdfHandler";
import { HD, HQ, LQ } from "../../constants";

interface IMobileCallerState {
	webcamConnected: boolean;
	calling: boolean;
	mainStreamManager?: StreamManager;
	isConnected: boolean;
	activeCall: boolean;
	publisher?: Publisher;
	remoteExpert?: Subscriber;
	participants: Subscriber[];
	myInfo: IPeerInfo | undefined;
	session: Session;
	publisherType: IUserType;
	error: IOVError | null;
	sessionId: string;
	localMicEnabled: boolean;
	localVideoEnabled: boolean;
	modalWindowEnabled: boolean;
	videoDeviceIndex: number;
	videoDeviceList: Device[];
	switchCamera: boolean;
	localStream: MediaStream | undefined;
	videoResolution: string;
	isCallGeneric: boolean;
}

interface CanvasElement extends HTMLCanvasElement {
	captureStream(frameRate?: number): MediaStream;
}

interface IProps extends RouteComponentProps, ConnectProps {
	id: Id;
	company: ICompany;
	token: string;
	ticketId: string;
}

type MobileCallWindowProps = IProps;

export class MobileCallWindowBase extends React.Component<
	MobileCallWindowProps,
	IMobileCallerState
> {
	public OV: OpenVidu = new OpenVidu();
	public initialState: IMobileCallerState = {
		webcamConnected: false,
		calling: false,
		myInfo: undefined,
		isConnected: false,
		activeCall: false,
		mainStreamManager: undefined,
		publisher: undefined,
		remoteExpert: undefined,
		participants: [],
		session: this.OV.initSession(),
		publisherType: OPERATOR,
		error: null,
		sessionId: "",
		localMicEnabled: true,
		localVideoEnabled: true,
		modalWindowEnabled: false,
		videoDeviceIndex: 1,
		videoDeviceList: [],
		switchCamera: true,
		localStream: undefined,
		videoResolution: HD,
		isCallGeneric: false,
	};

	private signalClient = new SignalingHelper(`${process.env.REACT_APP_LOCALHOST}api/MediaServer/`);
	private rndName = getRandomName();
	private productId = this.props.id;
	private ticketId = this.props.ticketId;

	constructor(props: any) {
		super(props);
		this.state = { ...this.initialState };
		console.log("Token id temporary user:" + this.props.token);
	}

	public async componentDidMount() {
		this.setupEvents();
		window.onbeforeunload = () => {
			this.disconnectSession();
			this.dequeueUserAndSession();
		};		
	}

	public componentWillMount() {

		this.handleDeviseCheck();
		navigator.mediaDevices.ondevicechange = event => {
			event.preventDefault();
			this.handleDeviseCheck();
		};
	}

	///////////////// END SESSION /////////////////////////////////////////
	public componentWillUnmount() {
		this.dequeueUserAndSession();
		this.disconnectSession();
		this.setState({ ...this.initialState });
	}

	private permissionCheck = async () => {
		//const { status } = await Permissions.askAsync(Permissions.CAMERA);
		//this.setState({ webcamConnected: status === 'granted' });
	};

	private handleDeviseCheck = () => {
		navigator.mediaDevices
			.enumerateDevices()
			.then(devises => checkMediaDevices(devises, this.handleDeviseStatus))
			.catch(handleMediaDeviceError);
	};

	private handleDeviseStatus = (deviseConnected: IDeviceStatus) => {
		this.setState({ webcamConnected: deviseConnected.video });
	};

	public closeConnection = async () => {
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.indexOf("android") > -1) {
			if (document.fullscreenElement !== null) {
				document.exitFullscreen();
			}
		} else {
			if (this.state.localStream !== undefined) {
				this.state.localStream
					.getTracks()
					.forEach((track) => track.stop());
			}
		}
		serverManager.queue.dequeueSession(this.state.session.sessionId);

		const isTemporary = this.props.userInfo.isTemporary;
		let path = ""; //path to products list if isCallGeneric is true

		if (isTemporary) {
			path = this.ticketId ? `/product/${this.productId}/ticketcall/${this.ticketId}/files` : `/product/${this.productId}/files`;
		}

		this.props.history.push(path);
	};

	public disconnectSession = () => {

		if (!this.state.session) {
			throw new Error("No session to end.");
		}

		if (this.state.isConnected) {
			logger.debug("[MobileCallWindow] Closing connection...");
			this.state.session.disconnect();
		}
	};

	public dequeueUserAndSession = () => {
		if (this.state.myInfo) {
			serverManager.queue.disconnectCall(this.state.myInfo.id, this.state.sessionId);
		}
		this.signalClient.stopListen();
	};

	/////////////// HANDLE PRODUCT /////////////////////////////
	private async getProductForId(id: Id) {
		const deferred = new Deferred<IProduct>();

		this.props.getGenericProduct(x => deferred.resolve(x));

		const genericProduct: IProduct = await deferred.promise;
		const productList = await serverManager.products.get();
		const product = productList.data.find((p) => p.id === id);
		const result = product ? product : genericProduct;

		this.setState({ isCallGeneric: result.id == genericProduct.id });

		return result;
	}

	public async enqueueWithProduct(result: ISignInInfo) {
		var userAgent = "";
		if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
			userAgent = "Android AR ready";
		} else if (navigator.userAgent.toLowerCase().indexOf("iphone") !== -1) {
			userAgent = "IPhone VR only";
		} else if (navigator.userAgent.toLowerCase().indexOf("mac") !== -1) {
			userAgent = "MacOS VR only";
		} else if (navigator.userAgent.toLowerCase().indexOf("windows") !== -1) {
			userAgent = "Windows VR only";
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				ticketEvent.push({
					id: 2,
					type: TicketItemType.OperatorPosition,
					date: new Date().toDateString(),
					url: "",
					data: `${position.coords.latitude}, ${position.coords.longitude}`,
					linkedObject: {},
				});
				this.getProductForId(this.productId)
					.then((product) => {
						if (this.state.myInfo && product) {
							serverManager.queue
								.enqueue(product.id, product, result.id, ticketEvent, userAgent, this.ticketId)
								.then((i) => {
									logger.debug(
										"[MobileCallWindow] Enqueued correctly."
									);
									logger.debug(
										"[MobileCallWindow] Enqueue data: ",
										i.data
									);
									const sessionId: string = i.data.roomId;
									this.setState({ sessionId });
								});
						} else {
							throw Error;
						}
					})
					.catch((e) => {
						logger.debug("[MobileCallWindow] Failed to enqueue: " + e);
					});
			}, error => {
				this.getProductForId(this.productId)
					.then((product) => {
						if (this.state.myInfo && product) {
							serverManager.queue
								.enqueue(product.id, product, result.id, ticketEvent, userAgent, this.ticketId)
								.then((i) => {
									logger.debug(
										"[MobileCallWindow] Enqueued correctly."
									);
									logger.debug(
										"[MobileCallWindow] Enqueue data: ",
										i.data
									);
									const sessionId: string = i.data.roomId;
									this.setState({ sessionId });
								});
						} else {
							throw Error;
						}
					})
					.catch((e) => {
						logger.debug("[MobileCallWindow] Failed to enqueue: " + e);
					});
			})
		} else {
			this.getProductForId(this.productId)
				.then((product) => {
					if (this.state.myInfo && product) {
						serverManager.queue
							.enqueue(product.id, product, result.id, ticketEvent, userAgent, this.ticketId)
							.then((i) => {
								logger.debug(
									"[MobileCallWindow] Enqueued correctly."
								);
								logger.debug(
									"[MobileCallWindow] Enqueue data: ",
									i.data
								);
								const sessionId: string = i.data.roomId;
								this.setState({ sessionId });
							});
					} else {
						throw Error;
					}
				})
				.catch((e) => {
					logger.debug("[MobileCallWindow] Failed to enqueue: " + e);
				});
		}

	}

	////////////////// SETUP SIGNALING ///////////////////////////////////
	public async setupSignaling() {
		const result = await this.signalClient.signIn(this.rndName);
		console.log(`[foo] result: ${result.id}`);
		await this.enqueueWithProduct(result);

		this.signalClient.listen(result.id);
		this.signalClient.onmessage.add((ev) => {
			logger.warn("[MobileCallWindow] Received msg", ev.message);
			if (ev.message.message === "ExpertReady") {
				logger.debug("[MobileCallWindow] Expert is ready");
				this.startSession(this.state.publisherType);
			}
		});

		this.setState({
			myInfo: {
				id: result.id,
				name: this.rndName,
				status: 1,
			},
		});
	}

	////////////////// SETUP SESSION /////////////////////////
	public async startSession(type: IUserType) {
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.indexOf("android") > -1) {
			if (!(document as any)["fullscreenElement"]) {
				//document.documentElement.requestFullscreen();
			}
		} else {

			await navigator.mediaDevices
				.getUserMedia({
					audio: true,
					video: true,
				})
				.then((stream) => {
					this.setState({ localStream: stream });
				});
		}

		if (type === OPERATOR && this.state.sessionId === "") {
			this.setState({ calling: true, publisherType: type });
			console.log(`[foo] prima di setupSignaling`);
			this.setupSignaling();
			console.log(`[foo] dopo di setupSignaling`);
			return;
		}



		await this.setupSession(type, this.state.sessionId);
	}

	public setupSession = async (type: IUserType, sessionId: string) => {
		const data: IOVTokenData = {
			sessionId,
			userType: type
		};
		try {
			const AuthData: ISessionConnectionData = await OVManager.tokenGetter(
				data
			);
			await OVManager.connectSession({
				session: this.state.session,
				...AuthData,
			});

			await this.connectToSession(type);
			logger.debug("[MobileCallWindow] connectSession Success");
		} catch (error) {
			this.setState({ calling: false });
			log("Session created with: ", error);
		}
	}

	public connectToSession = async (type: IUserType) => {
		try {
			const session: IConnectedSession | null = await this.connectUser();
			if (!session) {
				throw Error;
			}

			let mainStreamManager;
			if (session.publisherType === OPERATOR) {
				mainStreamManager = session.mainStreamManager;
			}
			this.setState({
				mainStreamManager,
				publisher: session.publisher,
				publisherType: session.publisherType,
				isConnected: session.isConnected,
			});

		} catch (error) {
			this.setState({ isConnected: false, calling: false, error: error as IOVError || null });
		}
	};

	public connectUser = async (): Promise<IConnectedSession | null> => {
		const { session, publisherType } = this.state;
		const resolution = this.state.videoResolution;
		const frameRate = 15;
		let videoSource: string = "";

		await this.OV.getDevices().then((devices) => {
			this.setState({
				videoDeviceList: devices.filter(
					(device) => device.kind === "videoinput"
				),
			});

			const index =
				this.state.videoDeviceList &&
					this.state.videoDeviceList.length > 1
					? 1
					: 0;

			videoSource = this.state.videoDeviceList[index].deviceId;
			this.setState({ videoDeviceIndex: index });
		});

		try {
			const publisherSettings: PublisherProperties = {
				videoSource,
				audioSource: true,
				publishAudio: true,
				publishVideo: true,
				frameRate,
				resolution,
				mirror: false,
			};

			const publisher: Publisher = this.OV.initPublisher(
				undefined as any,
				publisherSettings
			);

			console.log(`[foo] publisher NOT published`);
			session.publish(publisher).then(() => { console.log(`[foo] publisher published`); }).catch(err => console.log(`error ${err} in connectUser`));

			const data: IConnectedSession = {
				mainStreamManager: publisher as StreamManager,
				publisher: publisher,
				publisherType: publisherType,
				isConnected: true,
			};

			return data;
		}
		catch (err) {
			logger.error(err);
			return null;
		}
	};

	public addHololensStream = (subscriber: Subscriber) => {
		this.setState({
			mainStreamManager: subscriber,
			// participants: [...this.state.participants, subscriber],
		});
	};

	public addParticipantStream = (subscriber: Subscriber) => {
		this.setState({
			participants: [...this.state.participants, subscriber],
		});
	};

	public addRemoteExpertStream = (subscriber: Subscriber) => {
		this.setState({
			remoteExpert: subscriber,
			// participants: [...this.state.participants, subscriber],
		});
	};

	public removeHololensStream = (stream: Stream) => {
		// const participants = this.state.participants.filter(
		// 	participant => participant.stream !== stream,
		// );
		// this.setState({ mainStreamManager: undefined, participants });
		this.setState({ mainStreamManager: undefined });
	};

	public removeParticipantStream = (stream: Stream) => {
		const participants = this.state.participants.filter(
			(participant) => participant.stream !== stream
		);
		this.setState({ participants });
	};

	public removeRemoteExpertStream = (stream: Stream) => {
		// const participants = this.state.participants.filter(
		// 	participant => participant.stream !== stream,
		// );
		// this.setState({ remoteExpert: undefined, participants });
		this.setState({ remoteExpert: undefined });

		this.closeConnection();
	};

	private containerStyle: React.CSSProperties = {
		paddingTop: 12,
	};

	public render() {
		const { localMicEnabled, localVideoEnabled,
			calling, remoteExpert,
			participants, mainStreamManager,
			videoResolution, videoDeviceIndex } = this.state;

		if (!this.state.webcamConnected) {
			return (
				<Container fluid style={this.containerStyle}>
					<Row>
						<Col>
							<Card className="camera-warning-card">
								<Icon name="warning" className="md-48" />
								<h2>
									<T v="Dashboard.cameraError" />
								</h2>
								<T v="Dashboard.cameraErrorDescription" />
							</Card>
						</Col>
					</Row>
				</Container>
			);
		}
		return (
			<>
				<div
					className={this.callPageModalContainerClassNames()}
					id="mobile-view"
				>
					<div className="video_view">
						<div className="camera-selected-indicator">
							{videoDeviceIndex + 1}
						</div>
						<div>
							<MobileToolbarButton
								onClick={() => this.switchCamera()}
								iconName={"flip_camera_ios"}
								disabled={!this.state.localVideoEnabled}
								id="swap-camera-button"
								title={GetLangString('ActiveCall.SwitchCamera')}
							/>
							<div className="dropdown-resolution">
								<button
									style={{ fontWeight: "bold" }}
									id="change-resolution-button"
									title={GetLangString('ActiveCall.ChangeResolution')}
								>{OVManager.changeResolutionIcon(videoResolution)}</button>
								{this.state.publisher && <div className="dropdown-resolution-content">
									<a onClick={() => this.changeRes(HD)}>High Quality</a>
									<a onClick={() => this.changeRes(HQ)}>Medium Quality</a>
									<a onClick={() => this.changeRes(LQ)}>Low Quality</a>
								</div>}
							</div>
						</div>
						{calling && <Spinner className="spinner" />}
						{remoteExpert && (
							<div onClick={() => this.changeMainStreamManager(this.getRemoteExpertStreamManager() as Subscriber)}>
								<MobileRemoteVideo
									videoClass="remote_view_view"
									streamManager={
										this.getRemoteExpertStreamManager() as StreamManager
									}
								/>
							</div>
						)}
						{participants.map((participant, index) => {
							return (
								<MobileRemoteVideo
									key={index}
									videoClass="remote_view_hidden"
									streamManager={participant as StreamManager}
								/>
							);
						})}
					</div>
					{mainStreamManager && (
						<VideoView
							containerClass="mobile-caller-localvideo-container"
							videoClass="mobile-caller-localvideo-element"
							streamManager={this.getHololensViewStreamManager()}
						/>
					)}
					<div className="active-call-page-localvideo-menu">
						<div className="active-call-page-localvideo-menu-section">
							<MobileToolbarButton
								id="mute_mic_button"
								iconName={localMicEnabled ? "mic" : "mic_off"}
								onClick={this.toggleMicEnabled}
								title={localMicEnabled ? GetLangString('ActiveCall.Mute') : GetLangString('ActiveCall.Unmute')}
							/>
							{this.state.activeCall || this.state.calling ? (
								<MobileToolbarButton
									className={"lg icon-red"}
									onClick={() => this.closeConnection()}
									iconName={"call_end"}
									id="hangup_button"
									title={GetLangString('ActiveCall.EndCall')}
								/>
							) : (
								<MobileToolbarButton
									className={"lg icon-green"}
									onClick={() => this.startSession(OPERATOR)}
									iconName={"call"}
									id="hangup_button"
									title={GetLangString('ActiveCall.Call')}
								/>
							)}
							<MobileToolbarButton
								onClick={this.toggleVideoEnabled}
								iconName={
									localVideoEnabled
										? "videocam"
										: "videocam_off"
								}
								id="toggle_local_video"
								title={localVideoEnabled ? GetLangString('ActiveCall.HideCamera') : GetLangString('ActiveCall.ShowCamera')}
							/>
						</div>
					</div>
					<div
						className={this.getModalContainerClassNames()}
						id="modal-window"
					>
						<button
							style={{ height: "32px", width: "32px" }}
							onClick={this.closeModalWindow}
						>
							<Icon name="close" />
						</button>
					</div>
				</div>
			</>
		);
	}

	private changeRes = (res: string) => {
		OVManager.changeResolution(this.OV, this.state, res, () => this.setState((state) => { return { videoResolution: res } }));
	}

	private changeMainStreamManager = (subscriber: Subscriber) => {
		const { mainStreamManager } = this.state;

		var temp = mainStreamManager;
		this.setState({ mainStreamManager: subscriber as StreamManager, remoteExpert: temp as Subscriber })
	};

	private callPageModalContainerClassNames() {
		const base = "active-call-page-localvideo-container";
		const expandedClass = "active-call-page-localvideo-container-expanded";
		const { calling, activeCall } = this.state;
		const expanded = calling || activeCall ? expandedClass : base;
		return classNames(expanded);
	}

	private setupEvents() {
		this.state.session.on("streamCreated", (event) => {
			const e = (event as unknown) as StreamEvent;
			const subscriber = this.state.session.subscribe(
				e.stream,
				undefined as any
			);

			const connectionData = parseConnectionData(e.stream);

			if (connectionData == null) {
				return;
			}

			switch (connectionData.type) {
				case OPERATOR:
					return this.addHololensStream(subscriber);
				case REMOTE_PARTICIPANT:
					this.setState({ activeCall: true, calling: false });
					return this.addParticipantStream(subscriber);
				case REMOTE_EXPERT:
					this.setState({ activeCall: true, calling: false });
					return this.addRemoteExpertStream(subscriber);
			}
		});
		this.state.session.on("streamDestroyed", (event) => {
			const e = event as StreamEvent;
			e.preventDefault();
			const connectionData = parseConnectionData(e.stream);
			if (connectionData == null) {
				return;
			}

			switch (connectionData.type) {
				case OPERATOR:
				// return this.removeHololensStream(e.stream);
				case REMOTE_PARTICIPANT:
					return this.removeParticipantStream(e.stream);
				case REMOTE_EXPERT:
					return this.removeRemoteExpertStream(e.stream);
			}
		});

		this.state.session.on("signal:onFile", async (event) => {
			const e = (event as any) as SignalEvent;
			const { publisher, modalWindowEnabled } = this.state;
			// 	try {

			// 	//window.location.href = 'intent://shareyourscreen/#Intent;scheme=myapp;package=info.dvkr.screenstream;end';
			// 	const imageStream = document.createElement('img');
			// 	imageStream.crossOrigin = "Anonymous";
			// 	imageStream.src = 'http://192.168.1.80:8080/stream.mjpeg';

			// 	document.body.appendChild(imageStream);
			// 	const canvas_stream : any = document.createElement('canvas');
			// 	const canvas_direct : any = document.createElement('canvas');
			// 	//this.addElementToModal(canvas_stream);
			// 	canvas_stream.id = "stream";
			// 	canvas_direct.id = "direct";
			// 	const canvasContextStream = canvas_stream.getContext('2d');
			// 	const canvasContextDirect = canvas_direct.getContext('2d');
			// 	document.body.appendChild(canvas_stream);
			// 	document.body.appendChild(canvas_direct);
			// 	imageStream.onload = function() {
			// 		canvas_stream.width = canvas_direct.width = imageStream.width;
			// 		canvas_stream.height = canvas_direct.height = imageStream.height;
			// 		this.onload = null;
			// 		if (canvasContextStream !== undefined) {
			// 			setInterval(function () {canvasDrawMjpeg(canvas_stream, canvasContextStream, canvasContextDirect, imageStream);}, 500);
			// 		}
			// 	};
			// 	//const documentMediaStreamTrack = canvas_stream.captureStream(15).getVideoTracks()[0];
			// 	//console.log("[debug] documentMediaStream: " + documentMediaStreamTrack);
			// 	//const newPublisher = this.OV.initPublisher(undefined as any, { videoSource: documentMediaStreamTrack, audioSource: undefined });
			// 	const videoStream = document.createElement('video');
			// 	videoStream.srcObject = canvas_stream.captureStream(15);
			// 	videoStream.autoplay = true;
			// 	this.addElementToModal(videoStream);
			// } catch (e) {
			// 	console.log(e.message);
			// }
			// if (publisher) {
			// 	publisher.replaceTrack(documentMediaStreamTrack);
			// }
			if (modalWindowEnabled) {
				this.closeModalWindow();
			}

			const data = String(e.data).split("|");
			if (data.length !== 2) {
				publisher!.session.signal({
					data: `ERROR:SomeError:null`,
					type: "onFileReceived",
				});
				return;
			}
			publisher!.session.signal({
				data: `SUCCESS:Success:${data[1]}`,
				type: "onFileReceived",
			});

			serverManager.files.getById(data[1]).then((res) => {
				const type = res.data.name.split(".").pop();
				let model: any;

				if (type && (type.toLowerCase() === "glb" || type.toLowerCase() === "gltf")) {
					model = handle3dModel(`${process.env.REACT_APP_LOCALHOST}${res.data.realFileUri}`);
					this.addElementToModal(model);
				} else if (type === "fbx") {
					this.props.getMyCompany();
					this.props.getClipsData(res.data.id, (clipsData: IClips | null) => {
						if (clipsData == null) {
							clipsData = {
								id: res.data.id,
								clipsData: [],
							}
						}
						const divCurrentModel = document.createElement('div');
						divCurrentModel.id = "current-model-2";
						ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${res.data.realFileUri}`} clips={clipsData} allowArFunctions={false} activeCall={false} positioningModel={false} />, divCurrentModel);
						//model = handleFbxModel(res.data.realFileUri, clipsData, "false");
						this.addElementToModal(divCurrentModel);
					});
				} else if (type && type.toLowerCase() === "pdf") {
					const divCurrentModel = document.createElement('div');
					divCurrentModel.id = "current-model-2";
					divCurrentModel.style.cssText = "height: 100%;";
					ReactDOM.render(<PdfHandler file={res.data} />, divCurrentModel);
					this.addElementToModal(divCurrentModel);
				} else if (type && (type.toLowerCase() === "png" || type.toLowerCase() === "jpg")) {
					console.log(`[foo] onFileeeee: ${process.env.REACT_APP_LOCALHOST}${res.data.realFileUri}`);
					model = handleImageFile(`${process.env.REACT_APP_LOCALHOST}${res.data.realFileUri}`);
					const image = document.getElementById('image-to-delete');
					if (image) {
						image.style.cssText = "width: 100%;";
					}
					this.addElementToModal(model);
				} else if (type && type.toLowerCase() === "mp4") {
					handleVideoFile(res.data).then(divModel => { this.addElementToModal(divModel); });
				} else {
					window.open(res.data.realFileUri, "_blank");
				}
			});
		});
		this.state.session.on("signal:onMobileSnapshot", async (event) => {
			const e = (event as SignalEvent);
			if (this.state.modalWindowEnabled) {
				this.closeModalWindow();
			}

			const data = String(e.data).split("|");
			if (data.length !== 2) {
				this.state.publisher!.session.signal({
					data: `ERROR:SomeError:null`,
					type: "onMobileSnapshotReceived",
				});
				return;
			}

			this.state.publisher!.session.signal({
				data: `SUCCESS:Success:${data[1]}`,
				type: "onMobileSnapshotReceived",
			});

			serverManager.files.getById(data[1]).then((res) => {
				if (window.innerHeight > window.innerWidth) {
					const image = handleImageFile(`${process.env.REACT_APP_LOCALHOST}${res.data.thumbnailUrl}`, true);
					this.addElementToModal(image);
				} else {
					const image = handleImageFile(`${process.env.REACT_APP_LOCALHOST}${res.data.thumbnailUrl}`, false);
					this.addElementToModal(image);
				}
			});
		});
	}

	private toggleVideoEnabled = async () =>
	{
		if (!this.state.publisher) {
			return;
		}
		const isSendingVideo = !this.state.localVideoEnabled;
		this.state.publisher.publishVideo(isSendingVideo);
		this.setState({ localVideoEnabled: isSendingVideo });
	};


	private switchCamera = async () => {
		const {
			videoDeviceList,
			videoDeviceIndex,
			publisher,
			session,
		} = this.state;

		const resolution = this.state.videoResolution;
		const frameRate = 15;
		let videoSource: string = "";

		if (
			publisher &&
			videoDeviceList.length > 1 &&
			this.state.switchCamera === true
		) {
			this.setState({ switchCamera: false });
			const index = (videoDeviceIndex + 1) % videoDeviceList.length;
			videoSource = videoDeviceList[index].deviceId;
			this.setState({ videoDeviceIndex: index });

			const publisherSettings: PublisherProperties = {
				videoSource: videoSource,
				audioSource: this.state.localMicEnabled,
				publishAudio: true,
				publishVideo: true,
				frameRate,
				resolution,
				mirror: false,
			};
			// Creating a new publisher with specific videoSource
			// In mobile devices the default and first camera is the front one
			const newPublisher = this.OV.initPublisher(
				undefined as any,
				publisherSettings
			);

			session.unpublish(publisher);
			this.setState({
				publisher: newPublisher,
				mainStreamManager: newPublisher as StreamManager,
			});
			await session.publish(newPublisher);
			this.setState({ switchCamera: true });
		}
	};


	private addElementToModal = (elem: any) => {
		const modalWindow = document.getElementById("modal-window");
		if (modalWindow) {
			const oldElem = modalWindow.querySelector("#current-model-2");
			if (oldElem) {
				modalWindow.replaceChild(elem, oldElem);
			} else {
				modalWindow.appendChild(elem);
			}
		}
		this.setState({ modalWindowEnabled: true });
	};

	private getHololensViewStreamManager = () => {
		const {
			mainStreamManager,
			publisherType: userType,
			publisher,
		} = this.state;

		if (mainStreamManager) {
			return mainStreamManager;
		}

		if (publisher && userType === OPERATOR) {
			return publisher;
		}

		return null;
	};

	private getRemoteExpertStreamManager = () => {
		const { remoteExpert, publisherType: userType, publisher } = this.state;

		if (!remoteExpert && userType !== REMOTE_EXPERT) {
			return null;
		}

		if (remoteExpert) {
			return remoteExpert;
		}

		return publisher;
	};

	private toggleMicEnabled = () => {
		if (!this.state.publisher) {
			return;
		}
		const { localMicEnabled } = this.state;
		const isSendingAudio = !localMicEnabled;
		this.state.publisher.publishAudio(isSendingAudio);
		this.setState({ localMicEnabled: isSendingAudio });
	};

	private closeModalWindow = () => {
		this.setState({ modalWindowEnabled: false });
		const element = document.getElementById("current-model");
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
	};

	private getModalContainerClassNames = () => {
		const base = "active-call-page-modal-container";
		const { modalWindowEnabled } = this.state;
		const hidden = modalWindowEnabled ? "" : "hidden";
		return classNames(base, hidden);
	};
}

const canvasDrawMjpeg = async (canvas: any, canvasContext: any, canvasContextDirect: any, image: HTMLImageElement) => {
	// var ctx_off = canvas.cloneNode().getContext('2d');
	// ctx_off.drawImage(image, 0,0);
	// canvasContext.drawImage(ctx_off.canvas, 0, 0);

	var ctx_off = canvas.cloneNode().getContext('2d');
	ctx_off.drawImage(image, 0, 0);
	// and draw it back to our visible one
	canvasContext.drawImage(ctx_off.canvas, 0, 0);

	// draw the img directly on 'direct'
	canvasContextDirect.drawImage(image, 0, 0);
};

const mapStateToProps = (state: IRootState) => ({

	files: toArray(state.files.data),
	isMobile: state.layout.isMobile,
	userRole: state.userInfo.data.userRole,
	userInfo: state.userInfo.data,
	products: toArray(state.products.data),
	company: state.company.data.company,

});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMyCompany: companyActions.getMyCompany,
			getClipsData: actions.getFileClipsData,
			getGenericProduct: productActions.getGenericProduct,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const MobileCallWindow = connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileCallWindowBase));
