export enum ReceivableHubEvents {
	ReceiveMessage = "ReceiveMessage",
	SignInSuccess = "SignInSuccess",
	ListUpdate = "ListUpdate",
	SessionListUpdate = "SessionListUpdate",
	ReceiveTicketId = "TicketCreated",
	SendParticipantName = "SendParticipantName",
	SendUploadProgressValue = "SendUploadProgressValue"
}

export enum SendableHubEvents {
	AnswerCall = "AnswerCall",
	OperatorReady = "OperatorReady",
	ExpertReady = "ExpertReady",
	SignIn = "SignIn",
	EndCall = "EndCall",
	GetConnectionId= "GetConnectionId",
	SetIsNotificationExpired= "SetIsNotificationExpired",
}
