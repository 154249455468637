import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions, selectTicket } from "../../reducers/tickets";
import { ITicket } from "../../types";
import { toArray } from "../../utilities";
import { LoadingPage } from "../globalComponents";
import { TicketInfo } from "./TicketInfo";
import TicketTimeline from "./TicketTimeline";
import { UserRole } from "../../types/IRoles";
import "./TimeLineItem.scss";
import { RouteComponentProps, withRouter } from "react-router";

export interface IProps {
	ticketId: string;
}

interface IState {
	isEditing: boolean;
	localItem: ITicket;
}

interface ITicketProps extends ConnectProps, IProps {}

export class TicketComponent extends React.Component<ITicketProps, IState> {
	public componentDidMount() {
		this.props.getTicket(this.props.ticketId);
	}

	componentDidUpdate(prevProps: ConnectProps) {
		if (prevProps.ticket !== this.props.ticket) {
			console.log()
		}
	}

	public render() {
		const { loading, error, ticket } = this.props;

		if (loading) {
			return <LoadingPage />;
		}

		if (error || !ticket) {
			return (
				<Container fluid>
					<Row>
						<Col>
							<T v={"Error"} />
						</Col>
					</Row>
				</Container>
			);
		}
		if (this.props.userInfo.userRole === UserRole.Operator) {
			return (
				<TicketTimeline ticketEntries={ticket.ticketEvent} userInfo={this.props.userInfo}/>
			);
		} else {
			return (
				<Container fluid className="mt-3">
					<Row>
						<Col lg={10} md={12}>
							<Card>
								<TicketInfo ticket={ticket} saveTicket={this.props.saveTicket} exportTicket={this.exportTicket} exportTicketCsv={this.exportTicketCsv} getTicket={this.props.getTicket} />
								<CardBody />
								<CardFooter />
							</Card>
						</Col>
						<Col lg={10} md={12}>
							<TicketTimeline ticketEntries={ticket.ticketEvent} userInfo={this.props.userInfo}/>
						</Col>
					</Row>
				</Container>
			);
		}
		
	}

	private exportTicket = async (ticketId: string) => {
		console.log("Trying to export ticket " + ticketId);
		await this.props.exportTicket(ticketId);
	}

	private exportTicketCsv = async (ticketId: string) => {
		console.log("Trying to export ticket " + ticketId);
		await this.props.exportTicketCsv(ticketId);
	}
}

const mapStateToProps = (state: IRootState, props: IProps) => ({
	loading: state.tickets.loading,
	error: state.tickets.error,
	ticket: selectTicket(state, props.ticketId),
	tickets: toArray(state.tickets.data),
	userInfo: state.userInfo.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getTicket: actions.getTicket,
			exportTicket: actions.exportTicket,
			exportTicketCsv: actions.exportTicketCsv,
			saveTicket: actions.saveTicket,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ITiketState = StateProps & DispatchProps;
type RouteProps = RouteComponentProps<{ id: string,  }>;
type ConnectProps = ITiketState & RouteProps;

export const Ticket = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(TicketComponent));
