import React, { KeyboardEvent } from "react";
import { classNames, delay, VideoElementWithMediaStream, voidFn } from "../../utilities";
import { logger } from "../../utilities/logger";
import { AnnotationsColorPicker } from "./AnnotationsColorPicker";
import { ToolbarButton } from "./ToolbarButton";
import Input from "reactstrap/lib/Input";
import { Icon } from "../globalComponents/Icon";
import { GetLangString } from "../../managers/I18n";

type AnnotationMode = "DRAW" | "CROSSHAIR" | "ARROW" | "KEYBOARD";

export interface IDrawEvent {
	size: string;
	color: string;
	x: number;
	y: number;
	text?: string;
}

export interface IAnnotationsProps /* extends OriginalHtmlProps<HTMLDivElement> */ {
	canvasRef: (el: HTMLCanvasElement) => void;
	snapshotResolution: { snapshotWidth: number, snapshotHeight: number } | false;
	containerClassName?: string;
	canvasClassName?: string;
	isActive: boolean;
	//blackbarOffsets?: { offsetLeft: number, offsetTop: number }
	remoteVideoRef: React.RefObject<VideoElementWithMediaStream>;
	onAnnotationComplete: (data: any) => void;
	onAnnotationClose: () => void;
	onCrosshair: (drawEvent: IDrawEvent) => void;
	onArrow: (drawEvent: IDrawEvent) => void;
	onTextDrawing: (drawEvent: IDrawEvent) => void
	onStartDrawing: (drawEvent: IDrawEvent) => void;
	onContinueDrawing: (drawEvent: IDrawEvent) => void;
	onEndDrawing: (drawEvent: IDrawEvent) => void;
	onUndoDrawing: () => void;
}

export interface IAnnotationState {
	annotationColor: string;
	annotationSize: string;
	annotationMode: AnnotationMode;
	isAnimating: boolean;
	isDrawing: boolean;
	drawX: number;
	drawY: number;
	typedText: string;
	isPositioned: boolean;
	normalizedDrawX: number;
	normalizedDrawY: number;
}

class AnnotationsBase extends React.Component<IAnnotationsProps, IAnnotationState> {
	public state: IAnnotationState = {
		annotationColor: "#ff0000",
		annotationSize: "2",
		annotationMode: "DRAW",
		isAnimating: false,
		isDrawing: false,
		drawX: 0,
		drawY: 0,
		typedText: "",
		isPositioned: false,

		normalizedDrawX: 0,
		normalizedDrawY: 0,
	};

	private pointCount: number = 0;
	private canvasRef: HTMLCanvasElement | null = null;
	private ctx: CanvasRenderingContext2D | null = null;
	private isDrawing: boolean = false;
	private prevX: number = 0;
	private prevY: number = 0;
	private currX: number = 0;
	private currY: number = 0;
	private passedTime: number = 0;
	private drawDelay: number = 50;
	private canvasCopies: ImageData[] = [];

	public componentDidMount() {
		if (this.props.isActive) {
			this.runFlashAnimation();
		}
	}

	public componentDidUpdate(prevProps: IAnnotationsProps) {
		if (!prevProps.isActive && this.props.isActive) {
			this.runFlashAnimation();
		}
	}

	public render() {
		const { isAnimating, annotationColor, isDrawing } = this.state;
		const { onAnnotationComplete } = this.props;

		return (
			<div className={this.getContainerClassName()}>
				{isAnimating && <div className="flash-container" />}
				<canvas
					onTouchMove={this.handleCanvasTouchMove}
					onTouchStart={this.handleCanvasTouchStart}
					onTouchEnd={this.handleCanvasTouchEnd}
					onClick={this.handleCanvasClick}
					onMouseDown={this.handleCanvasMouseDown}
					onMouseUp={this.handleCanvasMouseUp}
					onMouseMove={this.handleCanvasMouseMove}
					ref={this.handleCanvasRef}
					className="active-call-page-annotation-canvas"
				/>
				{!isDrawing && (
					<div className="active-call-page-annotation-colors">
						<AnnotationsColorPicker color={annotationColor} onColorChanged={this.handleColorChange} />
					</div>
				)}
				{!isDrawing && (
					<div className="active-call-page-annotation-size">
						<input type="range" min="1" max="10" defaultValue={this.state.annotationSize} onChange={(x) => this.handleSizeChange(x.target.value)} className="slider" id="myRange" />
					</div>
				)}
				{!isDrawing && (
					<div className="active-call-page-annotation-sizePreview">
						{/*<i className="material-icons md-24" style={{color: `${annotationColor}`, fontSize:`${22 + parseInt(this.state.annotationSize)}px`, height: `${22 + parseInt(this.state.annotationSize)}px`, width: `${22 + parseInt(this.state.annotationSize)}px`}}>control_point</i>*/}
						<i className="material-icons md-24" style={{ color: `${annotationColor}`, fontSize: `${22 + parseInt(this.state.annotationSize)}px` }}>control_point</i>
					</div>
				)}
				{this.state.annotationMode === "KEYBOARD" && this.state.isPositioned &&
					<form onSubmit={this.drawText}>
						<button style={{ display: "flex", fontSize: `${(parseInt(this.state.annotationSize) * 10)}px`, position: 'absolute', zIndex: 999999999, left: `calc(${this.state.drawX - 4}px - 40px)`, borderRadius: `20px`, color: "green", top: (this.state.drawY - 4) + 'px' }} onClick={this.drawText}>
							<Icon name="done" />
						</button>
						<input onChange={event => this.setState({ typedText: event.target.value })} style={{ background: "none", fontSize: `${(parseInt(this.state.annotationSize) * 10)}px`, color: this.state.annotationColor, borderRadius: "45px", position: 'absolute', zIndex: 999999999, left: (this.state.drawX - 4) + 'px', top: (this.state.drawY - 4) + 'px' }} />
					</form>}
				{!isDrawing && (
					<div className={"active-call-page-annotation-menu"}>
						<div className="active-call-page-annotation-menu-section" />
						<div className="active-call-page-annotation-menu-section">
							<ToolbarButton
								className={this.getButtonClassName("KEYBOARD")}
								id="annotation_text"
								tooltip="keyboard"
								iconName="keyboard"
								onClick={() => this.setState({ annotationMode: "KEYBOARD" })}
								title={GetLangString("ActiveCall.AnnotationKeyboard")}
							/>
							<ToolbarButton
								className={this.getButtonClassName("CROSSHAIR")}
								id="annotation_stamp"
								tooltip="Crosshair"
								iconName="control_point"
								onClick={() => this.setState({ annotationMode: "CROSSHAIR", isPositioned: false })}
								title={GetLangString('ActiveCall.AnnotationCircle')}
							/>
							<ToolbarButton
								className={this.getButtonClassName("DRAW")}
								id="annotation_draw"
								tooltip="Draw"
								iconName="edit"
								onClick={() => this.setState({ annotationMode: "DRAW", isPositioned: false })}
								title={GetLangString('ActiveCall.AnnotationDraw')}
							/>
							<ToolbarButton
								className={this.getButtonClassName("ARROW")}
								id="annotation_arrow"
								tooltip="Arrow"
								iconName="arrow_downward"
								onClick={() => this.setState({ annotationMode: "ARROW", isPositioned: false })}
								title={GetLangString('ActiveCall.AnnotationArrow')}
							/>
							<ToolbarButton
								className={"lg icon-green"}
								id="annotation_done"
								tooltip="Annotation done"
								iconName="check"
								onClick={this.handleOnAnnotationComplete}
								title={GetLangString('ActiveCall.AnnotationConfirm')}
							/>
							<ToolbarButton
								className={"lg icon-red"}
								id="annotation_undo"
								tooltip="Undo annotation"
								iconName="undo"
								onClick={this.handleOnAnnotationUndo}
								title={GetLangString('ActiveCall.AnnotationUndo')}
							/>
							<ToolbarButton
								className={"lg icon-black"}
								id="annotation_close"
								tooltip="Close annotation"
								iconName="close"
								onClick={this.handleOnAnnotationClose}
								title={GetLangString('ActiveCall.AnnotationClose')}
							/>
						</div>
						<div className="active-call-page-annotation-menu-section" />
					</div>
				)}
			</div>
		);
	}

	private handleCanvasTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
		var touch = e.touches[0];
		switch (this.state.annotationMode) {
			case "DRAW":
				return this.draw(e.target, touch.clientX, touch.clientY);
			default:
				return voidFn();
		}
	}

	private handleOnAnnotationComplete = () => {
		if (this.state.isPositioned) {
			this.drawText();
		}
		this.setState({ isPositioned: false });
		console.log(`[foo] offsetLeft: ${this.canvasRef!.offsetLeft}`);
		this.cropBlackBars();

		// this.canvasRef!.toBlob((blob: Blob | null) => {
		// 	if (!blob) {
		// 		return;
		// 	}

		// 	this.props.onAnnotationComplete(blob);
		// });
	};

	private handleOnAnnotationUndo = () => {
		this.setState({ isPositioned: false });
		if (this.canvasCopies.length <= 0)
			return;

		const canvasCopy = this.canvasCopies.splice(this.canvasCopies.length - 1, 1)[0]

		if (canvasCopy && this.ctx) {
			this.ctx.putImageData(canvasCopy, 0, 0);
		}

		this.props.onUndoDrawing();
	};

	private handleOnAnnotationClose = () => this.props.onAnnotationClose();


	private getButtonClassName = (annotationMode: AnnotationMode) => {
		if (this.state.annotationMode === annotationMode) {
			return "active";
		}

		return "";
	};

	private handleColorChange = (annotationColor: string) => {
		this.setState({ annotationColor });
	};

	private handleSizeChange = (annotationSize: string) => {
		this.setState({ annotationSize });
	};

	private getContainerClassName = () => {
		const { isActive } = this.props;
		const activeClassName = isActive ? "annotation-mode" : "";
		const baseClassName = "active-call-page-annotation-container";
		return classNames(baseClassName, activeClassName);
	};

	private getNormalizedCoordinatesIfInside = (target: EventTarget, clientX: number, clientY: number) => {
		if (!this.props.remoteVideoRef.current) {
			return;
		}

		const { width, height } = this.canvasRef!.getBoundingClientRect();
		const containerAspectRatio = width / height;

		let videoAspectRatio: number = 1;

		if (this.props.snapshotResolution) {
			const snapshotRes = this.props.snapshotResolution;
			const { snapshotWidth, snapshotHeight } = snapshotRes;
			videoAspectRatio = snapshotWidth / snapshotHeight;
		}
		else {
			const { videoWidth, videoHeight } = this.props.remoteVideoRef.current;
			videoAspectRatio = videoWidth / videoHeight;
		}

		let blackbarSize = 0;
		let minX: number = 0;
		let maxX: number = width;
		let minY: number = 0;
		let maxY: number = height;
		let drawableContainerWidth: number = 0;
		let drawableContainerHeight: number = 0;
		let blackbarX = 0;
		let blackbarY = 0;

		if (containerAspectRatio >= videoAspectRatio) {
			drawableContainerHeight = height;
			drawableContainerWidth = height * videoAspectRatio;
			blackbarSize = (width - drawableContainerWidth) / 2;
			minX = blackbarSize;
			maxX = width - blackbarSize;
			blackbarX = blackbarSize;
		} else {
			drawableContainerWidth = width;
			drawableContainerHeight = width / videoAspectRatio;
			blackbarSize = (height - drawableContainerHeight) / 2;
			minY = blackbarSize;
			maxY = height - blackbarSize;
			blackbarY = blackbarSize;
		}

		let ele = target as HTMLElement;
		let offsetLeft = 0;
		let offsetTop = 0;
		while (ele) {
			offsetLeft += ele.offsetLeft;
			offsetTop += ele.offsetTop;
			ele = ele.offsetParent as HTMLElement;
		}

		const clickX = clientX - offsetLeft;
		const clickY = clientY - offsetTop;

		const isInsideVerticalBounds = clickY >= minY && clickY <= maxY;
		const isInsideHorizontalBounds = clickX >= minX && clickX <= maxX;
		const isInsideBounds = isInsideHorizontalBounds && isInsideVerticalBounds;

		if (isInsideBounds) {
			/* Normalizing the click events. */
			//const x = (clickX - minX) / (maxX - blackbarX);
			//const y = (clickY - minY) / (maxY - blackbarY);
			const extraWidth = width * (0.03);//clientX * 0.05 + width * 0.02;//55; //(width * 0.025)
			const extraHeight = height * (0.045);//height * 0.1;//55; //(height * 0.03)
			const x = (clickX - minX - extraWidth) / (maxX - blackbarX);
			const y = (clickY - minY - extraHeight) / (maxY - blackbarY);
			console.log("MIN: " + (minX) + " ; " + (minY))
			console.log("REMOVING: " + extraWidth + " ; " + extraHeight)
			console.log("FROM: " + (width) + " ; " + (height))
			console.log("FINAL: " + (x) + " ; " + (y))
			return { x, y };
		}

		logger.warn("Is outside");
		return false;
	};

	private drawPoint = (clientX: number, clientY: number) => {
		if (!this.ctx || !this.canvasRef || !this.props.remoteVideoRef.current) {
			return;
		}

		const c = this.ctx;

		const { left, top } = this.canvasRef.getBoundingClientRect();

		// Round the coordinates to avoid sub-pixel rendering.
		this.prevX = this.currX;
		this.prevY = this.currY;
		this.currX = clientX - left;
		this.currY = clientY - top;

		c.beginPath();
		c.moveTo(this.prevX, this.prevY);
		c.lineTo(this.currX, this.currY);
		c.strokeStyle = this.state.annotationColor;
		c.lineWidth = parseInt(this.state.annotationSize) + 1;
		c.stroke();
		c.closePath();
	};

	private draw = (target: EventTarget, clientX: number, clientY: number) => {
		if (this.isDrawing) {
			const coords = this.getNormalizedCoordinatesIfInside(target, clientX, clientY);

			if (coords) {
				this.handleDrawingCallback(coords);
				//console.warn("IS INSIDE, DRAWING");
				this.drawPoint(clientX, clientY);
			}
		}
	};

	private handleDrawingCallback = (coords: { x: number, y: number }) => {
		this.pointCount += 1;

		//if (this.pointCount % 70 === 0) {
		if (this.passedTime <= Date.now()) {
			logger.debug('Doing draw callback');
			this.props.onContinueDrawing({ ...coords, color: this.state.annotationColor, size: this.state.annotationSize });
			this.passedTime = Date.now() + this.drawDelay;
		}
	}

	private handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
		switch (this.state.annotationMode) {
			case "DRAW":
				return;
			case "CROSSHAIR":
				return this.stampCircle(e);
			case "ARROW":
				return this.drawArrow(e);
			case "KEYBOARD":
				return this.stampKeyboard(e);
		}
	};

	private stampKeyboard = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const coords = this.getNormalizedCoordinatesIfInside(e.target, e.clientX, e.clientY);

		var inputText = document.getElementById('temp-textfield');

		if (!coords || !this.props.remoteVideoRef.current || !this.ctx || !this.canvasRef || inputText) {
			return;
		}

		const { top, left } = this.canvasRef.getBoundingClientRect();

		const drawX = e.clientX - left;
		const drawY = e.clientY - top;
		this.setState({ normalizedDrawX: coords.x, normalizedDrawY: coords.y, drawX: drawX, drawY: drawY, isPositioned: true });
	};

	private drawText = () => {
		const context = this.ctx;
		var size = (parseInt(this.state.annotationSize) * 10);
		if (context) {
			context.textBaseline = 'top';
			context.textAlign = 'left';
			context.font = `${size}px sans-serif`;
			context.fillStyle = this.state.annotationColor;
			context.fillText(this.state.typedText, this.state.drawX - 4, this.state.drawY - 4);
			this.setState({ isPositioned: false });
			this.props.onTextDrawing({ ...{ x: this.state.normalizedDrawX, y: this.state.normalizedDrawY }, color: this.state.annotationColor, size: this.state.annotationSize, text: this.state.typedText });
		}
	};

	private drawArrow = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const coords = this.getNormalizedCoordinatesIfInside(e.target, e.clientX, e.clientY);

		if (!coords || !this.props.remoteVideoRef.current || !this.ctx || !this.canvasRef) {
			return;
		}

		const { top, left } = this.canvasRef.getBoundingClientRect();

		const drawX = e.clientX - left;
		const drawY = e.clientY - top;

		const c = this.ctx;
		const size = 64 + (parseInt(this.state.annotationSize) * 4) - 20 /*max size*/;
		c.moveTo(drawX, drawY);
		c.font = `${size}px "Material Icons"`;
		c.fillStyle = this.state.annotationColor;
		c.fillText("arrow_downward", drawX - size / 2, drawY + (size / 4));
		this.props.onArrow({ ...coords, color: this.state.annotationColor, size: this.state.annotationSize });
	};

	private stampCircle = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const coords = this.getNormalizedCoordinatesIfInside(e.target, e.clientX, e.clientY);

		if (!coords || !this.props.remoteVideoRef.current || !this.ctx || !this.canvasRef) {
			return;
		}

		const { top, left } = this.canvasRef.getBoundingClientRect();

		const drawX = e.clientX - left;
		const drawY = e.clientY - top;

		const c = this.ctx;
		c.moveTo(drawX, drawY);
		c.beginPath();
		c.arc(drawX, drawY, 20, 0, 2 * Math.PI);
		c.lineWidth = parseInt(this.state.annotationSize) + 1;
		c.strokeStyle = this.state.annotationColor;
		c.stroke();
		c.closePath();

		this.props.onCrosshair({ ...coords, color: this.state.annotationColor, size: this.state.annotationSize });
	};

	private setPreviousPositionTouch = (e: React.TouchEvent<HTMLCanvasElement>) => {
		if (!this.canvasRef) {
			return;
		}
		const { left, top } = this.canvasRef.getBoundingClientRect();
		var touch = e.touches[0];
		this.currX = touch.clientX - left;
		this.currY = touch.clientY - top;
		this.prevX = this.currX;
		this.prevY = this.currY;
	};

	private setPreviousPosition = (e: React.MouseEvent<HTMLCanvasElement>) => {
		if (!this.canvasRef) {
			return;
		}
		const { left, top } = this.canvasRef.getBoundingClientRect();

		this.currX = e.clientX - left;
		this.currY = e.clientY - top;
		this.prevX = this.currX;
		this.prevY = this.currY;
	};

	private handleCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
		switch (this.state.annotationMode) {
			case "DRAW":
				return this.draw(e.target, e.clientX, e.clientY);
			default:
				return voidFn();
		}
	};

	private handleCanvasTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
		this.handleSaveUndo();

		var touch = e.touches[0];

		if (this.state.annotationMode === "DRAW") {
			this.isDrawing = true;
			const coords = this.getNormalizedCoordinatesIfInside(e.target, touch.clientX, touch.clientY);

			if (coords) {
				this.setPreviousPositionTouch(e);
				this.handleDrawStart(coords.x, coords.y);
			}
		}
	};

	private handleCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
		this.handleSaveUndo();

		if (this.state.annotationMode === "DRAW") {
			this.isDrawing = true;
			const coords = this.getNormalizedCoordinatesIfInside(e.target, e.clientX, e.clientY);

			if (coords) {
				this.setPreviousPosition(e);
				this.handleDrawStart(coords.x, coords.y);
			}
		}
	};

	private handleSaveUndo = () => {
		if (this.ctx) {
			const canvasCopy = this.ctx.getImageData(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
			this.canvasCopies.push(canvasCopy);
		}
	}

	private handleDrawStart = (x: number, y: number) => {
		const drawEvent: IDrawEvent = {
			size: this.state.annotationSize,
			color: this.state.annotationColor,
			x: x,
			y: y,
		};

		this.pointCount = 0;
		this.props.onStartDrawing(drawEvent);
		this.setState({ isDrawing: true });
	}

	private handleCanvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
		this.setState({ isDrawing: false });
		this.isDrawing = false;
		const coords = this.getNormalizedCoordinatesIfInside(e.target, e.clientX, e.clientY);
		if (coords && this.state.annotationMode === "DRAW") {
			this.setPreviousPosition(e);
			this.props.onEndDrawing({ ...coords, color: this.state.annotationColor, size: this.state.annotationSize });
		}
	};

	private handleCanvasTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
		var touch = e.touches[0];
		this.setState({ isDrawing: false });
		this.isDrawing = false;
		if (touch !== undefined && touch.clientX !== undefined && touch.clientY !== undefined) {
			const coords = this.getNormalizedCoordinatesIfInside(e.target, touch.clientX, touch.clientY);
			if (coords && this.state.annotationMode === "DRAW") {
				this.setPreviousPositionTouch(e);
				this.props.onEndDrawing({ ...coords, color: this.state.annotationColor, size: this.state.annotationSize });
			}
		}
	}

	private handleCanvasRef = (el: HTMLCanvasElement) => {
		if (!el) {
			return;
		}
		this.canvasRef = el;
		this.ctx = el.getContext("2d", { alpha: false });
		this.props.canvasRef(el);
	};

	private runFlashAnimation = async () => {
		await this.setState({ isAnimating: true });
		await delay(1500);
		await this.setState({ isAnimating: false });
	};

	private cropBlackBars() {
		const context = this.ctx;
		if (context && this.props.remoteVideoRef.current) {
			var croppedCanvas = document.createElement('canvas');

			const canvasAr = this.canvasRef!.width / this.canvasRef!.height;
			const videoAr = this.props.remoteVideoRef.current.videoWidth / this.props.remoteVideoRef.current.videoHeight;

			const offsetLeft = videoAr > canvasAr ? 0 : (this.canvasRef!.width - this.canvasRef!.height * videoAr) / 2;
			//const offsetTop = videoAr < canvasAr ? 0 : (this.canvasRef!.height - this.canvasRef!.width / videoAr) / 2;
			croppedCanvas.width = this.canvasRef!.width - (offsetLeft * 2);
			croppedCanvas.height = this.canvasRef!.height;

			var croppedCanvasContext = croppedCanvas.getContext('2d');

			croppedCanvasContext!.drawImage(this.canvasRef!, offsetLeft, 0,
				this.canvasRef!.width - offsetLeft, this.canvasRef!.height,
				0, 0, this.canvasRef!.width - offsetLeft, this.canvasRef!.height);

			croppedCanvas.toBlob((blob: Blob | null) => {
				if (!blob) {
					return;
				}

				this.props.onAnnotationComplete(blob);
			});
		}
	}
}

export const Annotations = (props: IAnnotationsProps) => {
	return <AnnotationsBase {...props} />;
};
