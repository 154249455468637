import React, { useState } from "react";
import { Icon } from "../globalComponents";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Spinner } from "./Spinner";
import "./ModalImage.scss";
import ReactDOM from "react-dom";

interface IModalImageProps {
	uri: string;
}

export const ModalImage: React.FunctionComponent<IModalImageProps> = ({ uri }) => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	return (
		<div
			id="current-image"
			className="modal-window"
		>
			{!isImageLoaded &&
				<div>
					<Spinner />
				</div>
			}
			<TransformWrapper options={{ limitToBounds: true, limitToWrapper: true }}>
				{({ resetTransform }: any) => (
					<TransformComponent>
						<div
							style={{ position: "relative", width: "400px" }}
						>
							<img
								id={`image-to-delete-2`}
								src={uri}
								onLoad={() => {
									setIsImageLoaded(true);
								}}
								style={{
									position: "relative",
									margin: "auto",
									left: "0px",
									maxWidth: "100%"
								}}
							/>
						</div>
					</TransformComponent>

				)}
			</TransformWrapper>
			
		</div>
	);
};