import { AxiosInstance } from "axios";
import { product } from "ramda";
import { ICreateProductDto, IFile, IProduct, IProgress, IUpdateProductDto } from "../../types";
import { logger } from "../../utilities/logger";

export class ProductsManager {
	private agent: AxiosInstance;
	private url: string = "Products";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public get = async () => this.agent.get<IProduct[]>(this.url);
	public getById = async (productId: string) => this.agent.get<IProduct>(`${this.url}/${productId}`);
	public getGeneric = async () => this.agent.get<IProduct>(`${this.url}/GetGenericProduct`);
	public getReferences = async (productId: string) => this.agent.get<IFile[]>(`${this.url}/${productId}/reference`);
	public getByCompanyId = async (companyId: string) => this.agent.get<IProduct[]>(`${this.url}/GetProductsByCompanyId?companyId=${companyId}`);
	public post = async (dto: ICreateProductDto) => this.agent.post<IProduct>(`${this.url}/CreateProduct`, dto);
	public put = async (dto: IUpdateProductDto) => this.agent.put<IProduct>(`${this.url}/${dto.id}`, dto);
	public delete = async (productId: string) => this.agent.delete(`${this.url}/${productId}`);
	public addReference = async (productID: string, fileInfoId: string) => this.agent.post(`${this.url}/reference`, { productID, fileInfoId });
	// TODO: Maybe wrong productID field
	public removeReference = async (productId: string, fileInfoId: string) => this.agent.delete(`${this.url}/${productId}/reference/${fileInfoId}`);
	public getProductImageUrl = (productId: string) => `${this.agent.defaults.baseURL}${this.url}/${productId}/thumbnail`;
	public uploadProductImage = async (productId: string, file: File, progressLogger: (progress: IProgress) => void) => {
		const formData = new FormData();
		formData.append("file", file, file.name);
		const request = this.agent.post<unknown>(`${this.url}/${productId}/thumbnail`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent: ProgressEvent) => {
				progressLogger({ file, uploaded: progressEvent.loaded / progressEvent.total, id: URL.createObjectURL(file) });
				logger.debug("File upload progress", file.name, progressEvent.loaded / progressEvent.total);
			},
		});
		return request;
	};

	public deleteProductThumbnail = async (productId: string) => this.agent.delete(`${this.url}/DeleteThumbnailForProduct?productId=${productId}`);
	public addTeamReference = async (teamId: string, productId: string) => this.agent.post(`${this.url}/teamreference`, { teamId, productId });
	public removeTeamReference = async (teamId: string, productId: string) => this.agent.delete(`${this.url}/${teamId}/teamreference/${productId}`);
	public getTeamReferences = async (teamId: string) => this.agent.get<IProduct[]>(`${this.url}/${teamId}/teamreference`);
}
