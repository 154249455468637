import { LogLevel } from "@microsoft/signalr";
import * as loglevel from "loglevel";

const APPLICATION_NAME = "HoloMaintenance";
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const IS_PRODUCTION_DEBUG = window.localStorage.getItem("HOLOMAINTENANCE_PRODUCTION_DEBUG");

export let logger = loglevel.getLogger(`${APPLICATION_NAME} ${process.env.NODE_ENV!.toUpperCase()} Logger`);

if (IS_DEVELOPMENT || IS_PRODUCTION_DEBUG) {
	addPrefixForLogger(logger);
	logger.enableAll();
} else {
	addPrefixForLogger(logger);
	logger.disableAll();
}

logger.debug(`Enabling logger for ${process.env.NODE_ENV!.toUpperCase()}.`);

export const hubLogger = {
	log: (logLevel: LogLevel, ...rest: any[]) => {
		const logPrefix = "[SignalR]";
		switch (logLevel) {
			case LogLevel.Critical:
				return logger.error(logPrefix, ...rest);
			case LogLevel.Debug:
				return;
			case LogLevel.Error:
				return logger.error(logPrefix, ...rest);
			case LogLevel.Information:
				return logger.info(logPrefix, ...rest);
			case LogLevel.None:
				return logger.info(logPrefix, ...rest);
			case LogLevel.Trace:
				return;
			case LogLevel.Warning:
				return logger.warn(logPrefix, ...rest);
			default:
				return logger.warn(logPrefix, ...rest);
		}
	},
};

function addPrefixForLogger(customLogger: loglevel.Logger) {
	const originalFactory = customLogger.methodFactory;
	customLogger.methodFactory = (methodName: any, logLevel: any, loggerName: any) => {
		const rawMethod = originalFactory(methodName, logLevel, loggerName);
		return (...args: any[]) => {
			const messages = [getTimeStamp(), `[${methodName.toUpperCase()}]`, ...args];
			rawMethod.apply(undefined, messages);
		};
	};
	customLogger.setLevel(customLogger.getLevel());
}

function getTimeStamp() {
	const now = new Date();
	const hours = formatHour(now.getHours());
	const minutes = formatTime(now.getMinutes());
	const seconds = formatTime(now.getSeconds());
	const milliseconds = formatMilliseconds(now.getMilliseconds());
	return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function formatHour(amount: number) {
	const stringified = amount.toString();
	switch (stringified.length) {
		case 1:
			return `0${stringified}`;
		case 2:
			return `${stringified}`;
		default:
			return stringified;
	}
}

function formatTime(amount: number) {
	const stringified = amount.toString();
	switch (stringified.length) {
		case 1:
			return `${stringified}0`;
		case 2:
			return `${stringified}`;
		default:
			return stringified;
	}
}

function formatMilliseconds(ms: number) {
	const stringified = ms.toString();
	switch (stringified.length) {
		case 1:
			return `${stringified}00`;
		case 2:
			return `${stringified}0`;
		case 3:
			return `${stringified}`;
		default:
			return stringified;
	}
}
