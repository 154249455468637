import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { Action, actions } from "./layout.actions";

export interface IState {
	isNavOpen: boolean;
	isMobile: boolean;
}

export const initialState: IState = {
	isNavOpen: true,
	isMobile: false,
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.toggleNav):
			return {
				...state,
				isNavOpen: !state.isNavOpen,
			};
		case getType(actions.setMobile):
			return {
				...state,
				isMobile: action.payload,
			};
		default:
			return state;
	}
};
