import { AxiosInstance, AxiosResponse } from "axios";
import { Id, ITicket, ITicketCreate, ITicketItem } from "../../types";
import { delay, formatDate } from "../../utilities";
import { dummyTicket, dummyTickets } from "../DummyData";
import { getCurrentLanguage } from "../I18n";

export class TicketsManager {
	private agent: AxiosInstance;
	private url: string = "Tickets";
	private separator: string = "|";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public get = async (productId: Id, step: number = 0, date: string = formatDate(new Date()), description: string, teamId: Id, selectedStatus: string) => this.agent.get<ITicket[]>(`${this.url}/GetListOfTicketsForProducts?productId=${productId}&step=${step}&dateFilter=${date}&description=${description}&teamId=${teamId}&selectedStatus=${selectedStatus}`);
	public getById = async (ticketId: Id) => this.agent.get<ITicket>(`${this.url}/ticket/${ticketId}`);
	public getByProductId = async (productId: Id, start: number, length: number) => this.agent.get<ITicket>(`${this.url}/GetTicketForProduct?productId=${productId}&startIndex=${start}&length=${length}`);
	public getExportedById = async (ticketId: Id) => this.agent.get<ITicket>(`${this.url}/exportTicket/${ticketId}`);
	public getTicketsByOperatorId = async (operatorId: string) => this.agent.get<ITicket[]>(`${this.url}/GetTicketsByOperatorId?operatorId=${operatorId}`);
	public deleteOLD = async (ticketsIds: string[]) => {
		const separator = "|";
		const ticketsIdsString = ticketsIds.join(separator);
		this.agent.delete(`${this.url}/DeleteSelectedTickets?ticketsIdsString=${ticketsIdsString}&separator=${separator}`);
	};
	public exportAllTickets = async (productId: Id | null) => this.agent.get(`${this.url}/exportTickets/${productId}?lang=${getCurrentLanguage()}`);
	public delete = async (ticketsIds: string[]) => this.agent.delete(`${this.url}/DeleteSelectedTickets?ticketsIdsString=${ticketsIds.join(this.separator)}&separator=${this.separator}`);
	public merge = async (ticketsIds: string[]) => this.agent.put(`${this.url}/MergeTickets?ticketsIdsString=${ticketsIds.join(this.separator)}&separator=${this.separator}`);
	// public delete = async (ticketIds: string[]) => {
	// 	const createDeleteRequest = (ticketId: string) => {
	// 		const request = this.agent.delete(`${this.url}/DeleteTicket?ticketId=${ticketId}`);
	// 		return request;
	// 	};
	// 	const requests = ticketIds.map(createDeleteRequest);
	// 	const getData = (all: Array<AxiosResponse<ITicket>>) => all.map(res => res.data);
	// 	const response = await Promise.all(requests);
	// 	return getData(response);
	// };
	public postTicket = async (productId: Id, data: ITicketCreate) => this.agent.post<ITicket>(`${this.url}/startTicket/${productId}`, data);
	public postTicketEvent = async (ticketId: Id, event: ITicketItem) => this.agent.post<ITicket>(`${this.url}/ticketEvent/${ticketId}`, event);
	public put = async (dto: ITicket) =>
		this.agent.put<ITicket>(`${this.url}/endTicket/${dto.id}`, {
			id: dto.id,
			description: dto.description,
			operatorName: dto.operatorName,
			status: dto.status
		});
}
