import { AxiosResponse } from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { serverManager } from "../../managers/ServerManager";
import { IProduct } from "../../types";
import { logger } from "../../utilities/logger";
import { actions } from "./products.actions";

export function* handleGetProducts(action: ReturnType<typeof actions.getProducts>) {
	try {
		logger.debug("Fetching products");
		const { data }: AxiosResponse<IProduct[]> = yield call(serverManager.products.get);
		yield put(actions.getProductsSuccess(data));
		logger.debug(data.length, "products fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getProductsError("Something went wrong while fetching products."));
	}
}

export function* handleGetProductsByCompanyId(action: ReturnType<typeof actions.getProductsByCompanyId>) {
	try {
		logger.debug("Fetching products");
		const { data }: AxiosResponse<IProduct[]> = yield call(serverManager.products.getByCompanyId, action.payload);
		yield put(actions.getProductsByCompanyIdSuccess(data));
	} catch (err) {
		logger.error(err);
		yield put(actions.getProductsByCompanyIdError(err));
	}
}

export function* handleGetSingleProduct(action: ReturnType<typeof actions.getSingleProduct>) {
	try {
		logger.debug("Fetching product with id", action.payload);
		const { data }: AxiosResponse<IProduct> = yield call(serverManager.products.getById, action.payload);
		yield put(actions.getSingleProductSuccess(data));
		logger.debug("Product with id", action.payload, "fetched successfully.");
	} catch (err) {
		if (err.response && err.response.status === 404) {
			yield put(actions.getSingleProductMissing());
		} else {
			logger.error(err);
			yield put(actions.getSingleProductError("Something went wrong while fetching product with id: " + action.payload));
		}
	}
}

export function* handleGetGenericProduct(action: ReturnType<typeof actions.getGenericProduct>) {
	try {
		const { data }: AxiosResponse<IProduct> = yield call(serverManager.products.getGeneric);
		yield put(actions.getGenericProductSuccess(data));
		
		logger.debug("Generic Product fetched successfully.");

		if (typeof action.payload === "function") {
			yield call(action.payload, data);
		}

	} catch (err) {
		if (err.response && err.response.status === 404) {
			yield put(actions.getGenericProductMissing());
		} else {
			logger.error(err);
			yield put(actions.getGenericProductError("Something went wrong while fetching the generic product"));
		}
	}
}

export function* handleUpdateProduct(action: ReturnType<typeof actions.updateProduct>) {
	try {
		yield call(serverManager.products.put, action.payload);
		yield put(actions.updateProductSuccess(action.payload));
	} catch (err) {
		logger.error(err);
		yield put(actions.updateProductError("Something went wrong while saving product with id: " + action.payload.id));
	}
}

export function* handleCreateProduct(action: ReturnType<typeof actions.createProduct>) {
	try {
		const { dto, callback } = action.payload;
		const { data }: AxiosResponse<IProduct> = yield call(serverManager.products.post, dto);
		yield put(actions.createProductSuccess(data));

		if (typeof callback === "function") {
			yield call(callback);
		}
	} catch (err) {
		logger.error(err);
		yield put(actions.createProductError("Something went wrong while creating a new product"));
	}
}

export function* handleDeleteProduct(action: ReturnType<typeof actions.deleteProduct>) {
	try {
		yield call(serverManager.products.delete, action.payload);
		yield put(actions.deleteProductSuccess(action.payload));
	} catch (err) {
		logger.error(err);
		yield put(actions.deleteProductError(err));
	}
}

export function* handleAddReference(action: ReturnType<typeof actions.addReference>) {
	try {
		const { fileId, productId } = action.payload;
		yield call(serverManager.products.addReference, productId, fileId);
		yield put(actions.addReferenceSuccess(productId, fileId));
	} catch (err) {
		logger.error(err);
		yield put(actions.addReferenceError(err));
	}
}

export function* handleRemoveReference(action: ReturnType<typeof actions.removeReference>) {
	try {
		const { fileId, productId } = action.payload;
		yield call(serverManager.products.removeReference, productId, fileId);
		yield put(actions.removeReferenceSuccess(productId, fileId));
	} catch (err) {
		logger.error(err);
		yield put(actions.removeReferenceError(err));
	}
}

export function* handleThumbnailUpdated(action: ReturnType<typeof actions.onThumbnailUpdated>) {
	try {
		const productId = action.payload;
		const response: AxiosResponse<IProduct> = yield call(serverManager.products.getById, productId);
		yield put(actions.getSingleProductSuccess(response.data));
	} catch (err) {
		yield put(actions.getSingleProductError(err));
	}
}

export function* handleDeleteProductThumbnail(action: ReturnType<typeof actions.deleteProductThumbnail>) {
	try {
		const productId = action.payload;		
		const response: AxiosResponse<string> = yield call(serverManager.products.deleteProductThumbnail, productId);
		yield put(actions.deleteProductThumbnailSuccess(response.data));
	} catch (err) {
		yield put(actions.deleteProductThumbnailError(err));
	}
}

export function* productsSaga() {
	yield takeEvery(getType(actions.addReference), handleAddReference);
	yield takeEvery(getType(actions.removeReference), handleRemoveReference);
	yield takeLatest(getType(actions.onThumbnailUpdated), handleThumbnailUpdated);
	yield takeLatest(getType(actions.deleteProductThumbnail), handleDeleteProductThumbnail);
	yield takeLatest(getType(actions.deleteProduct), handleDeleteProduct);
	yield takeLatest(getType(actions.createProduct), handleCreateProduct);
	yield takeLatest(getType(actions.getProducts), handleGetProducts);
	yield takeLatest(getType(actions.getProductsByCompanyId), handleGetProductsByCompanyId);
	yield takeLatest(getType(actions.getSingleProduct), handleGetSingleProduct);
	yield takeLatest(getType(actions.getGenericProduct), handleGetGenericProduct);
	yield takeLatest(getType(actions.updateProduct), handleUpdateProduct);
}
