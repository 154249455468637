import React from "react";
import Button from "reactstrap/lib/Button";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Modal, { ModalProps } from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Lang } from "../../managers/I18n";
import { ICreateProductDto, Id } from "../../types";
import { useFormInput } from "../../utilities";
import { SaveOverlay } from "../globalComponents/SaveOverlay";

// tslint:disable-next-line:no-empty-interface
export interface INewProductModalProps extends ModalProps {
	isSaving: boolean;
	onSave: (dto: ICreateProductDto) => void;
}

export const NewProductModal: React.FunctionComponent<INewProductModalProps> = ({ isSaving, onSave, toggle, ...rest }) => {
	const shortName = useFormInput("");
	const description = useFormInput("");
	const name = useFormInput("");
	const productCode = useFormInput("");
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const form = {
			description: description.value,
			name: name.value,
			shortName: shortName.value,
			productCode: productCode.value,
		};
		onSave(form);
	};
	return (
		<Lang>
			{getLanguageString => (
				<Modal toggle={toggle} {...rest}>
					<Form onSubmit={handleSubmit}>
						{isSaving && <SaveOverlay text={getLanguageString("Saving")} />}
						<ModalHeader>{getLanguageString("ProductsProperties.New")}</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label>{getLanguageString("ProductsProperties.ProductName")}</Label>
								<Input minLength={3} required placeholder={getLanguageString("ProductsProperties.ProductName")} {...name} />
							</FormGroup>
							<FormGroup>
								<Label>{getLanguageString("ProductsProperties.ProductCode")}</Label>
								<Input minLength={1} required placeholder={getLanguageString("ProductsProperties.ProductCode")} {...productCode} />
							</FormGroup>
							<FormGroup>
								<Label>{getLanguageString("ProductsProperties.ProductShortName")}</Label>
								<Input minLength={3} required placeholder={getLanguageString("ProductsProperties.ProductShortName")} {...shortName} />
							</FormGroup>
							<FormGroup>
								<Label>{getLanguageString("ProductsProperties.ProductDescription")}</Label>
								<Input placeholder={getLanguageString("ProductsProperties.ProductDescription")} {...description} />
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button type="submit" color="primary">
								Save
							</Button>
							<Button onClick={toggle} color="secondary">
								Cancel
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
			)}
		</Lang>
	);
};
