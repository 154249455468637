import { AxiosInstance } from "axios";
import { ITroubleshootingFlatNode } from "../../types";
import { delay } from "../../utilities";
import { dummyTroubleshootingLanguages } from "../DummyData";

export class TroubleshootingManager {
	public languages = {
		get: async () => {
			await delay(2500);
			return dummyTroubleshootingLanguages;
		},
	};

	private agent: AxiosInstance;
	private url: string = "troubleshooting";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public getForProductId = async (productId: string) => {
		const response = await this.agent.get<ITroubleshootingFlatNode[]>(`${this.url}/${productId}`);

		if (!response.data) {
			return { data: [] };
		}

		return response;
	};
	public put = async (productId: string, tree: ITroubleshootingFlatNode[]) =>
		this.agent.put(`${this.url}/${productId}`, { tree: JSON.stringify(tree) });
	public getById = async (productId: string, nodeId: number) => {
		const { data } = await this.getForProductId(productId);
		const node = data.find(i => i.id === Number(nodeId));
		return node!;
	};
}
