import { AxiosInstance } from "axios";
import {
	IOVTokenData,
	ISessionConnectionData,
	IUserType
} from "../../WebRTC/RTCHelpers";

export class OvConnections {
	private agent: AxiosInstance;
	private baseUrl: string = "MediaServer/Join";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}
	public getToken = async (dto: IOVTokenData) =>
		this.agent.get<ISessionConnectionData>(
			`${this.baseUrl}/${dto.sessionId}?userType=${dto.userType}`
		);
}
