import Axios, { AxiosInstance } from "axios";
import { Server } from "http";
import { authProvider } from "../auth/AuthProvider";
import { marketplaceProvider } from "../auth/MarketplaceAuthProvider";
import { AuthorizationCodesManager } from "./api/authorizationCodes";
import { CompaniesManager } from "./api/companies";
import { FilesManager } from "./api/files";
import { HomeManager } from "./api/homeManager";
import { LicensesManager } from "./api/licensePortal";
import { OvConnections } from "./api/ovConnection";
import { ProductsManager } from "./api/products";
import { QueueManager } from "./api/queue";
import { TeamsManager } from "./api/teams";
import { TicketsManager } from "./api/tickets";
import { ToolsManager } from "./api/tools";
import { TroubleshootingManager } from "./api/troubleshooting";
import { UsersManager } from "./api/users";

export class ServerManager {
	public files!: FilesManager;
	public products!: ProductsManager;
	public tickets!: TicketsManager;
	public troubleshooting!: TroubleshootingManager;
	public queue!: QueueManager;
	public companies!: CompaniesManager;
	public users!: UsersManager;
	public ovConnections!: OvConnections;
	public tools!: ToolsManager;
	public teams!: TeamsManager;
	public authorizationCodes!: AuthorizationCodesManager;
	public licenses!: LicensesManager;
	public home!: HomeManager;

	constructor() {
		
	}

	public async init(){
		var response = await authProvider.getAccessToken();
		var agent = Axios.create({ baseURL: `${process.env.REACT_APP_LOCALHOST}api/`, headers: { Authorization: `Bearer ${response.accessToken}` } });
		this.files = new FilesManager(agent);
		this.products = new ProductsManager(agent);
		this.tickets = new TicketsManager(agent);
		this.troubleshooting = new TroubleshootingManager(agent);
		this.queue = new QueueManager(agent);
		this.companies = new CompaniesManager(agent);
		this.users = new UsersManager(agent);
		this.ovConnections = new OvConnections(agent);
		this.tools = new ToolsManager(agent);
		this.teams = new TeamsManager(agent);
		this.authorizationCodes = new AuthorizationCodesManager(agent);
		this.licenses = new LicensesManager(agent);
		this.home = new HomeManager(agent);
	}

	public async initForTemporaryUser(token:string){
		console.log("Init Server Manager for temporary User: " + token);
		var agent = Axios.create({ baseURL: `${process.env.REACT_APP_LOCALHOST}api/`, headers: {"X-Base-Token" : token } });
		
		this.files = new FilesManager(agent);
		this.products = new ProductsManager(agent);
		this.tickets = new TicketsManager(agent);
		this.troubleshooting = new TroubleshootingManager(agent);
		this.queue = new QueueManager(agent);
		this.companies = new CompaniesManager(agent);
		this.users = new UsersManager(agent);
		this.ovConnections = new OvConnections(agent);
		this.tools = new ToolsManager(agent);
		this.teams = new TeamsManager(agent);
		this.authorizationCodes = new AuthorizationCodesManager(agent);
		this.licenses = new LicensesManager(agent);
		this.home = new HomeManager(agent);
	}

	public async initMarketplaceUser(token: string) {
		var response = await authProvider.getAccessToken();
		var agent = Axios.create({ baseURL: `${process.env.REACT_APP_LOCALHOST}api/`, headers: { "MarketplaceToken" : token, Authorization: `Bearer ${response.accessToken}` } });

		this.companies = new CompaniesManager(agent);
		this.licenses = new LicensesManager(agent);
		this.home = new HomeManager(agent);
	}
}

export const serverManager : ServerManager = new ServerManager();
