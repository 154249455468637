import React from "react";
import { Icon } from ".";
import "./ErrorPage.scss";

export const ErrorPage: React.FunctionComponent<{}> = ({ children }) => (
	<div className="error-page-container">
		<Icon name="contact_support" />
		{children}
	</div>
);
