import * as signalR from "@microsoft/signalr";
import { eventChannel } from "redux-saga";
import { call, fork, put, take } from "redux-saga/effects";
import { hubLogger, logger } from "../../utilities/logger";
import { Action, actions } from "./call-queue.actions";
import { ReceivableHubEvents } from "./call-queue.events";
import "./CustomAlert.scss";
import { eventDispatcher } from "./call-queue.handlers";
import { getCookie, GetLangString } from "../../managers/I18n";
import { actions as userActions } from "../../reducers/users";
import { useDispatch } from "react-redux";
import { LatheBufferGeometry } from "three";
import { Toast } from "../../components/toast";
import { delay } from "../../utilities";
import { authProvider } from "../../auth/AuthProvider";
import { IHttpConnectionOptions } from "@microsoft/signalr";
import { AccessTokenResponse } from "react-aad-msal";
import { serverManager } from "../../managers/ServerManager";
import { SIGNALR_SERVER_KEEP_ALIVE_MILLISECONDS, SIGNALR_SERVER_TIMEOUT_MILLISECONDS } from "../../constants";

// tslint:disable-next-line:variable-name
let _connection: signalR.HubConnection;
var isAppeared: boolean = false;

//Temporary User
var tokenForAccessTemporaryUser: any = "";
var isTemporaryUser: boolean;
//
var messageHeader: string = GetLangString('Toast.LicenseExpiration');
var messageBody: string = GetLangString('Toast.LicenseExpirationInfo');
const firstCheckExpirationDay: number = 7;

const getAccessToken = async () => {
	let tokenObject: AccessTokenResponse = await authProvider.getAccessToken();
	return tokenObject.accessToken;
}

//get custom httpclient header with signalr
// This is a custom method which creates all the headers I need for my authentication
const getAuthHeaders = () => ({ "X-Base-Token": tokenForAccessTemporaryUser })

// As per @Brennan's answer I used his advice and extened the default one
class CustomHttpClient extends signalR.DefaultHttpClient {
	constructor() {
		super(console); // the base class wants an signalR.ILogger, I'm not sure if you're supposed to put *the console* into it, but I did and it seemed to work
	}

	// So far I have only overriden this method, and all seems to work well
	public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		var authHeaders = getAuthHeaders(); // These are the extra headers I needed to put in
		request.headers = { ...request.headers, ...authHeaders };
		// Now we have manipulated the request how we want we can just call the base class method
		return super.send(request);
	}
}
export async function getWebsocketConnection(): Promise<signalR.HubConnection> {
	if (_connection) {
		return Promise.resolve(_connection);
	}
	const hostUrl = `${process.env.REACT_APP_LOCALHOST}signalr`;

	var isTemporaryUser = window.location.href.includes("auth")

	if (isTemporaryUser) {
		tokenForAccessTemporaryUser = getCookie("X-Base-Token");
		console.log("log test getAccessToken 2:" + tokenForAccessTemporaryUser);
	}

	//for user logged in AD and non-AD
	const connectionOption: signalR.IHttpConnectionOptions = isTemporaryUser ? { httpClient: new CustomHttpClient() } : { accessTokenFactory: getAccessToken }

	const connection = new signalR.HubConnectionBuilder()
		.withUrl(hostUrl, connectionOption)
		.configureLogging(signalR.LogLevel.Debug)
		.build();
	connection.keepAliveIntervalInMilliseconds = SIGNALR_SERVER_KEEP_ALIVE_MILLISECONDS;
	connection.serverTimeoutInMilliseconds = SIGNALR_SERVER_TIMEOUT_MILLISECONDS;
	const handleLogout = () => {
		alert(GetLangString('Toast.LogoutMessage'));
		authProvider.logout();

		//window.location.href = "/azuread/account/signout";
		console.log(GetLangString('Toast.LogoutMessage'));
	}

	const handleUnlicensedUserSignout = () => {
		alert(GetLangString('Toast.UnlicensedUserLogoutMessage'));
		authProvider.logout();
		console.log(GetLangString('Toast.LogoutMessage'));
	}

	//Init Connection SignalR
	return new Promise((resolve, reject) => {
		connection
			.start()
			.then(() => {
				_connection = connection;
				//connection.invoke("SetConnectionId");
				//setConnectionId(connection);
				connection.invoke("GetConnectionId").then(function (connectionId) {
					serverManager.users.addLoggedUser(connectionId);
					document.cookie = `connectionId=${connectionId}`;
				}).catch(err => console.error(err));
				//connection.invoke("GetConnectionId").then(function () { connection.invoke("GetConnectionId").then(function (connectionId) { document.cookie = `connectionId=${connectionId}`; console.log(connectionId) }) }).catch(err => console.error(err));
				connection.on('DuplicatedUserSignout', handleLogout);
				connection.on('UnlicensedUserSignout', handleUnlicensedUserSignout);
				connection.on('LogOutQuick', () => {
					alert(GetLangString('Toast.LogoutMessage'));
					authProvider.logout();
					//window.location.href = "/azuread/account/signout";
				});
				connection.on('ExpirationDate', (difference: string) => {
					console.log("RECEIVED EXPIRATION DATE MSG: " + difference + " | CONNECTION ID: " + getCookieValue('connectionId'));
					if (!isAppeared) {
						isAppeared = true;
						var daysUntilExpiration = difference;
						var days = parseInt(daysUntilExpiration, 10);

						if (days > 0) {
							messageBody += `${days} ${GetLangString(days == 1 ? 'Toast.Day' : 'Toast.Days')}`
						}
						else {
							messageBody = `${GetLangString('Toast.Expired')}`;
						}

						if (days <= firstCheckExpirationDay) {
							customAlert(messageHeader, messageBody, true, connection, undefined, true);
						} else {
							customAlert(messageHeader, messageBody, false, connection, undefined, true);
						}
					}
				});
				resolve(_connection);
			})
			.catch(reject);

	});
}


export function customAlert(messageHeader: string, messageBody: string, removeCheckbox: boolean, connection?: signalR.HubConnection, divToAppend?: string, insertOk?: boolean) {
	const divElementToAppend = document.getElementsByClassName(divToAppend ? divToAppend : '')[0];

	const customAlertDivFather = document.createElement("div");
	customAlertDivFather.className = "custom-alert";

	const customAlertContent = document.createElement("div");
	customAlertContent.className = "message-style"

	const header = document.createElement("header");
	header.className = "custom-header";
	header.innerHTML = `<h2 style="text-transform: none;">${messageHeader}</h2>`;

	const bodyText = document.createTextNode(`${messageBody}`);
	const checkboxDiv = document.createElement("div");
	const newLine = document.createElement("br");
	const newLine2 = document.createElement("br");
	const newLine3 = document.createElement("br");

	const checkbox = document.createElement("input");
	checkbox.type = "checkbox";
	checkbox.id = "dontshowagain";
	checkbox.name = "dontshowagain";

	const label = document.createElement("label");
	label.className = "label-style";
	label.htmlFor = "dontshowagain";

	const labelText = document.createTextNode(GetLangString('Toast.DontShowAgain'));
	label.appendChild(labelText);

	const button = document.createElement("button");
	button.className = "button button2";
	button.innerText = "OK";
	button.onclick = () => {
		if (checkbox.checked && connection) {
			connection.invoke("SetIsNotificationExpired");
		}
		if (divElementToAppend) {
			divElementToAppend.removeChild(customAlertDivFather)
		} else {
			document.body.removeChild(customAlertDivFather);
		}
	}

	if (!removeCheckbox) {
		checkboxDiv.appendChild(checkbox);
		checkboxDiv.appendChild(label);
	}
	if (insertOk === true)
		checkboxDiv.appendChild(button);
	customAlertContent.appendChild(header);
	customAlertContent.appendChild(newLine);
	customAlertContent.appendChild(bodyText);
	customAlertContent.appendChild(newLine2);
	customAlertContent.appendChild(newLine3);
	customAlertContent.appendChild(checkboxDiv);
	customAlertDivFather.appendChild(customAlertContent);

	if (divElementToAppend) {
		divElementToAppend.appendChild(customAlertDivFather);
	} else {
		document.body.appendChild(customAlertDivFather);
	}
}

export function getCookieValue(a: string) {
	var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
	return b ? b.pop() : '';
}

export function parseWebSocketData(jsonData: any) {
	try {
		const serialized = JSON.parse(jsonData);
		// Any serialization logic in here.
		return serialized;
	} catch (err) {
		logger.error(`Error parsing websocketdata. ${jsonData}`, err);
		return "Could not parse data.";
	}
}

export type IWebSocketActionCreator = (payload: any) => Action;

function createSocketChannel(socket: signalR.HubConnection) {
	return eventChannel(emit => {
		const socketHandler = (action: IWebSocketActionCreator) => (data: any) => emit(action(data));
		const receiveMessageHandler = (msg: string) => {
			emit(actions.receiveMessage(msg));
		};

		socket.on(ReceivableHubEvents.ReceiveMessage, receiveMessageHandler);
		socket.on(ReceivableHubEvents.ListUpdate, socketHandler(actions.listUpdate));
		socket.on(ReceivableHubEvents.SessionListUpdate, socketHandler(actions.sessionListUpdate));
		socket.on(ReceivableHubEvents.SignInSuccess, socketHandler(actions.signInSuccess));
		socket.on(ReceivableHubEvents.SendParticipantName, socketHandler(actions.getParticipantUsername));
		socket.on(ReceivableHubEvents.SendUploadProgressValue, socketHandler(actions.getUploadProgressValue))

		const unsub = function unsubscribe() {
			socket.off(ReceivableHubEvents.ListUpdate, socketHandler(actions.listUpdate));
			socket.off(ReceivableHubEvents.SessionListUpdate, socketHandler(actions.sessionListUpdate));
			socket.off(ReceivableHubEvents.SendParticipantName, socketHandler(actions.getParticipantUsername));
			socket.stop();
		};

		return unsub;
	});
}

export function* listenToSocketEvents(socket: signalR.HubConnection) {
	yield call(logger.debug, "Listening to socket events.");
	yield put(actions.connected());
	const socketChannel = yield call(createSocketChannel, socket);
	yield fork(eventDispatcher, socket);

	while (true) {
		try {
			const action: Action = yield take(socketChannel);

			logger.debug("[SignalR]", "EVENT:", action.type, "DATA:", (action as any).payload);

			yield put(action);
		} catch (err) {
			logger.error("socket error:", err);
		}
	}
}

export const hubConnection = new signalR.HubConnectionBuilder().withUrl(`${process.env.REACT_APP_LOCALHOST}signalr`, { accessTokenFactory: getAccessToken }).build();
