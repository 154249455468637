import { ActionType, createAction } from "typesafe-actions";
import { resolve } from "url";
import { ToastType } from "../../components/toast";
import { IFile, IClip, IClips, Id } from "../../types";

export const actions = {
	activeCallFileUploadSuccess: createAction("data/files/activeCallFileUploadSuccess", resolve => (file: IFile) => resolve(file)),
	
	uploadFileThumbnail: createAction("data/files/uploadModelThumbnail", resolve => (modelId: Id, thumbnail: File) => resolve({ modelId, thumbnail })),
	uploadFileThumbnailSuccess : createAction("data/files/uploadModelThumbnailSuccess", resolve => (data: IFile) => resolve(data)),
	uploadFileThumbnailError: createAction("data/files/uploadModelThumbnailError", resolve => (err: string) => resolve(err)),

	updateFile: createAction("data/files/update", resolve => (fileInfo: IFile, callback?: (message: string, type: ToastType) => void) => resolve({fileInfo, callback})),
	updateFileError: createAction("data/files/updateError", resolve => (err: string) => resolve(err)),
	updateFileSuccess: createAction("data/files/updateSuccess", resolve => (data: IFile) => resolve(data)),

	deleteFile: createAction("data/files/delete", resolve => (fileId: string[], callback?: () => void) => resolve({ fileId, callback })),
	deleteFileError: createAction("data/files/deleteError", resolve => (err: string) => resolve(err)),
	deleteFileSuccess: createAction("data/files/deleteSuccess", resolve => (fileId: string[]) => resolve(fileId)),

	setFileOfficial: createAction("data/files/setFileOfficial", resolve => (fileId: string, callback?: () => void) =>
		resolve({ fileId, callback }),
	),
	setFileOfficialError: createAction("data/files/setFileOfficialError", resolve => (err: string) => resolve(err)),
	setFileOfficialSuccess: createAction("data/files/setFileOfficialSuccess", resolve => (fileId: string) => resolve(fileId)),
	
	setFileClipsData: createAction("data/files/setClipsData", resolve => (dto: IClips, callback?: (message: string, valid: boolean) => void) =>
		resolve({ dto, callback }),
	),
	setFileClipsDataError: createAction("data/files/setClipsDataError", resolve => (err: string) => resolve(err)),
	setFileClipsDataSuccess: createAction("data/files/setClipsDataSuccess", resolve => (data: IClips) => resolve(data)),

	getFileClipsData: createAction("data/files/getClipsData", resolve => (fileId: string, callback?: (clipsData: IClips | null, valid: boolean) => void) =>
		resolve({ fileId, callback }),
	),
	getFileClipsDataError: createAction("data/files/getClipsDataError", resolve => (err: string) => resolve(err)),
	getFileClipsDataSuccess: createAction("data/files/getClipsDataSuccess", resolve => (data: IClips) => resolve(data)),

	getAllFiles: createAction("data/files/getAllFiles"),
	getAllFilesSuccess: createAction("data/files/getAllFilesSuccess", resolve => (files: IFile[]) => resolve(files)),
	getAllFilesError: createAction("data/files/getAllFilesError", resolve => (error: string) => resolve(error)),

	getFiles: createAction("data/files/get", resolve => (page: number, searchString: string) => resolve({ page, searchString })),
	getFilesError: createAction("data/files/getError", resolve => (err: string) => resolve(err)),
	getFilesSuccess: createAction("data/files/getSuccess", resolve => (files: IFile[]) => resolve(files)),

	getFilesByCompanyId: createAction("data/files/getFilesByCompanyId", resolve => (companyId: string, page: number, searchString: string) => resolve({companyId, page, searchString})),
	getFilesByCompanyIdError: createAction("data/files/getFilesByCompanyIdError", resolve => (error: string) => resolve(error)),
	getFilesByCompanyIdSuccess: createAction("data/files/getFilesByCompanyIdSuccess", resolve => (files: IFile[]) => resolve(files)),

	getFilesWeight: createAction("data/files/getWeight"),
	getFilesWeightError: createAction("data/files/getWeightError", resolve => (err: string) => resolve(err)),
	getFilesWeightSuccess: createAction("data/files/getWeightSuccess", resolve => (weight: number) => resolve(weight)),

	getFilesMaxWeight: createAction("data/files/getMaxWeight"),
	getFilesMaxWeightError: createAction("data/files/getMaxWeightError", resolve => (err: string) => resolve(err)),
	getFilesMaxWeightSuccess: createAction("data/files/getMaxWeightSuccess", resolve => (weight: number) => resolve(weight)),
	
	getFilesMaximumSizeLimitUpload: createAction("data/files/getFilesMaximumSizeLimitUpload"),
	getFilesMaximumSizeLimitUploadError: createAction("data/files/getFilesMaximumSizeLimitUploadError", resolve => (err: string) => resolve(err)),
	getFilesMaximumSizeLimitUploadSuccess: createAction("data/files/getFilesMaximumSizeLimitUploadSuccess", resolve => (maxSizeLimit: number) => resolve(maxSizeLimit)),
	
	onFileUploaded: createAction("data/files/onFileUploaded", resolve => (file: IFile) => resolve(file)),

	getFilesForProduct: createAction("data/files/getForProduct", resolve => (productId: string) => resolve(productId)),
	getFilesForProductError: createAction("data/files/getForProductError", resolve => (err: string) => resolve(err)),
	getFilesForProductSuccess: createAction("data/files/getForProductSuccess", resolve => (data: IFile[]) => resolve(data)),
};

export type Action = ActionType<typeof actions>;
