import { Subscriber } from "openvidu-browser";
import { ActionType, createAction } from "typesafe-actions";
import { IConnectedSession, ILeaveSession, IOVError, IOVStreamRemove, IUserConnect } from "../../WebRTC/RTCHelpers";

export const actions = {
	connectUser: createAction("ov-connectUser", resolve => (userConnect: IUserConnect) => resolve(userConnect)),
	connectUserError: createAction("ov-connectUserError", resolve => (error: IOVError) => resolve(error)),
	connectUserSuccess: createAction("ov-connectUserSuccess", resolve => (connectedSession: IConnectedSession) => resolve(connectedSession)),

	leaveSession: createAction("ov-leaveSession", resolve => (leaveSession: ILeaveSession) => resolve(leaveSession)),
	leaveSessionError: createAction("ov-leaveSessionError", resolve => (error: IOVError) => resolve(error)),
	leaveSessionSuccess: createAction("ov-leaveSessionSuccess", resolve => (initialSession: IConnectedSession) => resolve(initialSession)),

	addHololensStream: createAction("ov-add/HololensStream", resolve => (subscriber: Subscriber) => resolve(subscriber)),
	addParticipantStream: createAction("ov-add/ParticipantStream", resolve => (subscriber: Subscriber) => resolve(subscriber)),
	addRemoteExpertStream: createAction("ov-add/RemoteExpertStream", resolve => (subscriber: Subscriber) => resolve(subscriber)),

	removeHololensStream: createAction("ov-remove/HololensStream", resolve => (dto: IOVStreamRemove) => resolve(dto)),
	removeHololensStreamError: createAction("ov-remove/HololensStreamError", resolve => (error: IOVError) => resolve(error)),
	removeHololensStreamSuccess: createAction("ov-remove/HololensStreamSuccess", resolve => (participants: Subscriber[]) => resolve(participants)),

	removeParticipantStream: createAction("ov-remove/ParticipantStream", resolve => (dto: IOVStreamRemove) => resolve(dto)),
	removeParticipantStreamError: createAction("ov-remove/ParticipantStreamError", resolve => (error: IOVError) => resolve(error)),
	removeParticipantStreamSuccess: createAction("ov-remove/ParticipantStreamSuccess", resolve => (participants: Subscriber[]) =>
		resolve(participants),
	),

	removeRemoteExpertStream: createAction("ov-remove/RemoteExpertStream", resolve => (dto: IOVStreamRemove) => resolve(dto)),
	removeRemoteExpertStreamError: createAction("ov-remove/RemoteExpertStreamError", resolve => (error: IOVError) => resolve(error)),
	removeRemoteExpertStreamSuccess: createAction("ov-remove/RemoteExpertStreamSuccess", resolve => (participants: Subscriber[]) =>
		resolve(participants),
	),
};

export type Action = ActionType<typeof actions>;
