import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Col, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/users";
import { actions as productActions } from "../../reducers/products";
import { IUser, IUserCreate, ICompany, IProduct, Id, IAuthorizationCode } from "../../types";
import { toArray } from "../../utilities";
import { ErrorPage, Icon } from "../globalComponents";
import { LoadingPage } from "../globalComponents/LoadingPage";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { UserDeleteModal, UserActiveModal, UserLinkModal, AccessTokenGenerator, MoveToTeamModal } from "./UserDeletModal";
import "./Users.scss";
import { UsersRow } from "./UsersRow";
import { serverManager } from "../../managers/ServerManager";
import { actions as companyActions } from "../../reducers/companies";
import { UserRole } from "../../types/IRoles";
import { delay } from "redux-saga/effects";
import moment from "moment";
import { LinkProductToOperatorModal } from "../products/LinkProductModal";
import { User } from "./User";
import { ITeam } from "../../types/ITeam";

interface IUsersState {
	usersByTeamId: IUser[];
	updateOneTime: boolean;
	showTemp: boolean;
	showOperators: boolean;
	showExperts: boolean;
	showAdmins: boolean;
	notFound: boolean;
	loading: boolean;
	error: boolean;
	saving: boolean;
	currentCompany: ICompany | null;
	isBlocking: boolean;
	isSavingEdits: boolean;
	isCreatingUser: boolean;
	isResetUserEnabled: boolean,
	isDeleteModalOpen: boolean;
	isActiveModalOpen: boolean;
	isTokenModalOpen: boolean;
	linkProductModal: boolean,
	usersResetList: Id[],
	linkedProduct: string | null,
	editingUser: IUser | null;
	selectedUser: IUser | null;
	generatedLink: string | null;
	editedUserData: IUserCreate;
	genericProductId: string | null;
	previousCompanyId: string | null;
	previousTeamId: string | null | undefined;
	isCreateMode: boolean;
	createdUser: IUser | null;
	generatedCode: string;
	selectedSection: string;
	isAccessTokenModalOpen: boolean;
	showAuthCodes: boolean;
	openLinkTeamModal: boolean;
	userToMove: IUser | null;
	toTeam: ITeam | null;
	authCode: string;
}

interface IUsersProps extends ConnectProps
{
	forcedCompanyId: string | null;
	forcedTeamId: string | null | undefined;
}

class UserList extends React.Component<IUsersProps, IUsersState> {
	public state: IUsersState = {
		usersByTeamId: [],
		updateOneTime: false,
		genericProductId: null,
		showTemp: true,
		showOperators: true,
		showExperts: true,
		showAdmins: true,
		notFound: true,
		loading: false,
		error: false,
		saving: false,
		usersResetList: [],
		currentCompany: null,
		isBlocking: false,
		isSavingEdits: false,
		isCreatingUser: false,
		isResetUserEnabled: false,
		isDeleteModalOpen: false,
		isActiveModalOpen: false,
		isTokenModalOpen: false,
		linkProductModal: false,
		linkedProduct: null,
		editingUser: null,
		selectedUser: null,
		generatedLink: null,
		isCreateMode: false,
		previousCompanyId: null,
		previousTeamId: null,
		generatedCode: "Loading...",
		authCode: "",
		editedUserData: {
			userName: "",
			firstName: "",
			lastName: "",
			phone: "",
			isLinked: false,
			email: "",
			userRole: UserRole.Operator,
			isTemporary: false,
			companyId: "",
			teamId: ""
		},
		createdUser: null,
		selectedSection: "",
		isAccessTokenModalOpen: false,
		showAuthCodes: true,
		userToMove: null,
		openLinkTeamModal: false,
		toTeam: null,
	};

	private LoadUsers() {
		if (this.props.forcedCompanyId) {
			this.props.getTeams(this.props.forcedCompanyId);
			this.props.getUsersByCompany(this.props.forcedCompanyId);
			console.log('[debug] number of users: ' + this.props.users.length);
		}
		else if (this.props.forcedTeamId != undefined) {
			this.props.getUsersByTeam(this.props.forcedTeamId);
		} else {
			this.props.getUsers();
			console.log("[debug] all users: " + this.props.users.length);
		}
	}

	public componentDidMount()
	{
		this.LoadUsers();

		console.log("Updating company id: " + this.props.forcedCompanyId);
		this.props.getProducts();
		this.props.getAuthCodes();
		
		this.props.getGenericProduct(this.handleGenericProduct);

		var tempUser = this.props.users.find(x => x.isTemporary && x.createdBy == this.props.loggedUser.id);
		if (tempUser)
			this.setState({ createdUser: tempUser })
	}

	public componentDidUpdate(prevProps: IUsersProps) {
		const { authCodes, forcedCompanyId, forcedTeamId } = this.props;
		let newState = {};
		
		if (forcedCompanyId != this.state.previousCompanyId) {
			this.props.cleanUsers();
			this.LoadUsers();

			newState = {...newState, previousCompanyId: forcedCompanyId };
		}
		if (forcedTeamId != this.state.previousTeamId) {
			this.props.cleanUsers();
			this.LoadUsers();

			newState = {...newState, previousTeamId: forcedTeamId };
		}

		if (prevProps.authCodes.length !== authCodes.length) {
			const newCode = (authCodes && authCodes.length > 0) ? authCodes[authCodes.length - 1].code : "Loading...";
			newState = {...newState, generatedCode: newCode };
		}

		if(Object.keys(newState).length > 0){
			this.setState(() => {return {...newState}});
		}
	}

	public render() {
		const { loading, error, isDeleteModalOpen, isActiveModalOpen, isTokenModalOpen, isCreateMode } = this.state;

		if (loading) {
			return <LoadingPage />;
		}
		
		if (error) {
			return (
				<ErrorPage>
					<T v={"Error"} />
				</ErrorPage>
			);
		}

		if (isCreateMode) {
			console.log('[foo] forcedTeamId: ' + this.props.forcedTeamId);
			return (
				<User
					forcedCompanyId={this.props.forcedCompanyId}
					forcedTeamId={this.props.forcedTeamId}
					onClosePressed={this.handleUserCreationClose}
				/>
			)
		}

		return (
			<React.Fragment>
				
				<LinkProductToOperatorModal
					products={this.props.products}
					// teams={this.props.teams}
					// userInfo={this.props.loggedUser}
					genericProductId={this.state.genericProductId}
					onClick={this.handleLinkProduct}
					onClickGeneric={this.handleLinkGenericProduct}
					loading={this.props.loading}
					isOpen={this.state.linkProductModal}
					toggle={this.handleLinkProductClose}
				/>

				<UserDeleteModal
					toggle={this.closeModal}
					isOpen={isDeleteModalOpen}
					handleOk={this.handleDeleteOk}
					name={this.state.selectedUser ? this.state.selectedUser.userName : ""}
				/>

				<UserActiveModal
					toggle={this.closeModal}
					isOpen={isActiveModalOpen}
					handleOk={this.handleToggleBlockOk}
					isBlocking={this.state.isBlocking}
					name={this.state.selectedUser ? this.state.selectedUser.userName : ""}
				/>

				<UserLinkModal
					toggle={this.closeModal}
					isOpen={isTokenModalOpen}
					handleCopy={() => this.handleCopyToClipboard(this.state.generatedLink)}
					handleRedirect={this.handleSelectedSection}
					handleProductLink={this.handleLinkProductOpen}
					link={this.state.generatedLink ? `${window.location}temporaryUserAccess/${this.state.authCode}/${this.state.linkedProduct}/${this.state.selectedSection !== "" ? this.state.selectedSection : "call"}` : "Loading..."}
				/>

				<AccessTokenGenerator 
					isOpen={this.state.isAccessTokenModalOpen}
					toggle={this.closeModal}
					handleCopy={() => this.handleCopyToClipboard(this.state.generatedCode)}
					saveEntryPoint={this.saveEntryPoint}
					code={this.state.generatedCode}
				/>

				<MoveToTeamModal 
					teams={this.props.teams}
					toggle={this.closeModal}
					isOpen={this.state.openLinkTeamModal}
					user={this.state.userToMove}
					onTeamSelected={this.handleOnCheck}
					moveToTeam={this.moveToTeam}
				/>
				<Row>
					<Col sm={12} className="users-list-container">
						<div className="users-list-actionbar">
							<div>
								<h4 className="users-list-items">
									<T v="Users.Users" />
								</h4>
							</div>
							<div>
								<UncontrolledButtonDropdown onClick={(e: React.MouseEvent<HTMLButtonElement>) => e}>
									<DropdownToggle disabled={this.state.isCreatingUser} caret={false} >
										<Icon name={"person_add"} />
									</DropdownToggle>
									<DropdownMenu right={true}>
										<DropdownItem disabled={this.props.loggedUser.userRole <= UserRole.Expert} onClick={this.handleAddUser}>
											<T v="Users.AddUser" />
										</DropdownItem>
										{this.props.loggedUser.userRole < UserRole.CompanyAdmin && <DropdownItem onClick={this.handleAddTempUser} disabled={this.props.currentCompany.allowTemporaryUsers.toString() === "false"}>
											<T v="Users.AddTempUser" />
										</DropdownItem>}
										{this.props.loggedUser.userRole >= UserRole.CompanyAdmin && <DropdownItem onClick={this.handleAddAuthCode}>
											<T v="Users.AddAuthCode" />
										</DropdownItem>}
									</DropdownMenu>
									
								</UncontrolledButtonDropdown>
							</div>
						</div>
					</Col>

					{this.props.loggedUser.userRole === UserRole.SuperAdmin && 
						<Col sm={12} className="users-list">
							<i onClick={this.handleToggleAdmins} style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showAdmins ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
							<span onClick={this.handleToggleAdmins}><T v={"Users.SuperAdmins"} /></span>
							{this.state.showAdmins && this.props.users.map(x => !this.state.usersResetList.includes(x.id) && x.userRole === UserRole.SuperAdmin && this.handleGetUsers(x))}
						</Col>
					}
					{this.props.loggedUser.userRole >= UserRole.CompanyAdmin &&
						<Col sm={12} className="users-list">
							<i onClick={this.handleToggleAdmins} style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showAdmins ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
							<span onClick={this.handleToggleAdmins}><T v={"Users.CompanyAdmins"} /></span>
							{this.state.showAdmins && this.props.users.map(x => !this.state.usersResetList.includes(x.id) && x.userRole === UserRole.CompanyAdmin && this.handleGetUsers(x))}
						</Col>
					}
					{this.props.loggedUser.userRole >= UserRole.CompanyAdmin &&
						<Col sm={12} className="users-list">
							<i onClick={this.handleToggleExperts} style={{ height: '16', paddingRight: '16', transform: 'scaleY(' + (this.state.showExperts ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
							<span onClick={this.handleToggleExperts}><T v={"Users.Experts"} /></span>
							{this.state.showExperts && this.props.users.map(x => !this.state.usersResetList.includes(x.id) && x.originalUserRole === UserRole.Expert && this.handleGetUsers(x))}
						</Col>
					}
					{this.props.loggedUser.userRole >= UserRole.CompanyAdmin &&
						<Col sm={12} className="users-list">
							<i onClick={this.handleToggleOperators} style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showOperators ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
							<span onClick={this.handleToggleOperators}><T v={"Users.Operators"} /></span>
							{this.state.showOperators && this.props.users.map(x => !this.state.usersResetList.includes(x.id) && x.userRole === UserRole.Operator && x.originalUserRole !== UserRole.Expert && !x.isTemporary && this.handleGetUsers(x))}
						</Col>
					}
					<Col sm={12} className="users-list">
						<i onClick={this.handleToggleTemps} style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showTemp ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
						<span onClick={this.handleToggleTemps}><T v={"Users.Temporary"} /></span>
						{this.state.showTemp && this.props.users.map(x => !this.state.usersResetList.includes(x.id) && x.userRole === UserRole.Operator && x.isTemporary && Math.abs(moment.utc(x.creationDate).diff(moment().utc(), "hours")) < 8 && (this.props.loggedUser.userRole >= UserRole.CompanyAdmin || x.createdBy == this.props.loggedUser.id) && this.handleGetUsers(x))}
					</Col>

					{this.props.loggedUser.userRole >= UserRole.CompanyAdmin && 
						<Col sm={12} className="users-list">
							<i onClick={() => this.setState({ showAuthCodes: !this.state.showAuthCodes })} style={{ paddingRight: '16', transform: 'scaleY(' + (this.state.showAuthCodes ? 1 : -1) + ')' }} className="material-icons md-24">arrow_drop_down</i>
							<span><T v={"Users.Code"} /></span>
							{this.state.showAuthCodes && this.props.authCodes.map(x => this.handleGetAuthCode(x))}
						</Col>
					}
				</Row>
			</React.Fragment>
		);
	}
	private openDeleteModal = () => this.setState({ isDeleteModalOpen: true });
	private openBlockModal = () => this.setState({ isActiveModalOpen: true });
	private openTokenModal = (link: string) => this.setState({ linkProductModal: false, isTokenModalOpen: true, generatedLink: link });

	private handleOnCheck = (team: ITeam | null) => {
		this.setState({ toTeam: team });
	};

	private moveToTeam = () => {
		if (this.state.userToMove) {
			if (this.state.toTeam)
				this.props.moveToTeam(this.state.toTeam.id, this.state.userToMove.id, this.props.forcedTeamId === null || this.props.loggedUser.userRole === UserRole.SuperAdmin);
			else
				this.props.moveToTeam(null, this.state.userToMove.id, this.props.forcedTeamId === null || this.props.loggedUser.userRole === UserRole.SuperAdmin);
		}
		this.setState({ openLinkTeamModal: false });
	};

	private closeModal = () =>
	{
		this.setState({ isDeleteModalOpen: false });
		this.setState({ isActiveModalOpen: false });
		this.setState({ isTokenModalOpen: false });
		this.setState({ linkProductModal: false });
		this.setState({ selectedUser: null });
		this.setState({ generatedLink: null });
		this.setState({ linkedProduct: this.state.genericProductId ? this.state.genericProductId : null });
		this.setState({ isAccessTokenModalOpen: false });
		this.setState({ openLinkTeamModal: false });
	};

	private saveEntryPoint = async (entryPoint: string) => {
		await serverManager.authorizationCodes.addEntryPoint(this.state.generatedCode, entryPoint);
		this.props.addEntryPoint(this.state.generatedCode, entryPoint);
		this.setState({ isAccessTokenModalOpen: false });
	};

	private handleUserCreationClose = () =>
	{
		this.setState({ isCreateMode: false });
		this.props.cleanUsers();
		this.LoadUsers();
	}

	private handleAddAuthCode = () => {
		this.props.addAuthCode();
		this.setState({ isAccessTokenModalOpen: true });
	};

	private handleSelectedSection = (section: string) => {
		const { createdUser } = this.state;
		this.setState({ selectedSection: section });
		if (createdUser)
		this.props.getBearerToken(createdUser.id.toString(), this.state.linkedProduct ? this.state.linkedProduct : "", "", this.tempUserLinkCallbackForRedirect);
		//this.props.getBearerToken(createdUser.id.toString(), this.state.linkedProduct ? this.state.linkedProduct : "", section, this.tempUserLinkCallback);
	};

	private tempUserLinkCallback = (link: string, msgId: string, type: ToastType) => {
		this.setState({ generatedLink: link });
	};

	private tempUserLinkCallbackForRedirect = (link: string, msgId: string, type: ToastType) => {
		this.setState({ authCode: link });
		this.token = link;
		console.log("GetTokenBy Id return: " + this.token);
		this.generateUserUrl();
	};
	
	

	private handleToggleAdmins = () =>
	{
		if (this.state.showAdmins) {
			this.setState({ showAdmins: false });
		}
		else {
			this.setState({ showAdmins: true });
		}
	}

	private handleToggleExperts = () =>
	{
		if (this.state.showExperts) {
			this.setState({ showExperts: false });
		}
		else {
			this.setState({ showExperts: true });
		}
	}

	private handleToggleOperators = () =>
	{
		if (this.state.showOperators) {
			this.setState({ showOperators: false });
		}
		else {
			this.setState({ showOperators: true });
		}
	}

	private handleToggleTemps = () =>
	{
		if (this.state.showTemp) {
			this.setState({ showTemp: false });
		}
		else {
			this.setState({ showTemp: true });
		}
	}

	private handleLinkProductClose = () =>
	{
		this.setState({ linkProductModal: false, isTokenModalOpen: true });
	};

	private handleLinkProductOpen = () =>
	{
		this.setState({ linkProductModal: true, isTokenModalOpen: false });
	};

	private handleLinkProduct = (product: IProduct | null) => {
		if (product) {
			console.log(`[selected product name]: ${product.name}`);
			console.log(`[selected product id]: ${product.id}`);
			this.setState({linkedProduct: product.id});
		}
			

		
		this.handleLinkProductId(product ? product.id : null);
	};

	private handleLinkProductId = (productId: string | null) => {
		const { createdUser, selectedSection } = this.state;
		console.log(`[handleLinkProduct]: ${productId}`)
		if (createdUser) {
			if (productId) {
				this.setState({ linkedProduct: productId });
				this.state.linkedProduct = productId;
				this.props.getBearerToken(createdUser.id.toString(), this.state.linkedProduct, selectedSection, this.tempUserLinkCallbackForRedirect);
			}
			else {
				this.setState({ linkedProduct: null });
				this.state.linkedProduct = null;
				this.props.getBearerToken(createdUser.id.toString(), "", selectedSection, this.tempUserLinkCallbackForRedirect);
			}
		}

		this.setState({ linkProductModal: false, isTokenModalOpen: true });
		
		//this.handleCopyUserLink(this.state.selectedUser);
	};

	private handleLinkGenericProduct = () =>
	{
		this.handleLinkProductId(this.state.genericProductId ? this.state.genericProductId : null);

		//this.setState({ linkedProduct: this.state.genericProductId ? this.state.genericProductId : null });
		//this.state.linkedProduct = this.state.genericProductId ? this.state.genericProductId : null;
		//this.handleCopyUserLink(this.state.selectedUser);
	};

	private handleDeleteOk = () =>
	{
		this.setState({ isDeleteModalOpen: false });
		if (this.state.selectedUser) {
			this.props.deleteUser(this.state.selectedUser.id.toString(), this.handleToastMessages);
		}
	};

	private handleToggleBlockOk = () =>
	{
		this.setState({ isActiveModalOpen: false });
		if (this.state.selectedUser) {
			if (this.state.isBlocking) {
				this.props.blockUser(this.state.selectedUser.id.toString(), this.handleToggleBlockToastMessages);
			}
			else {
				this.props.unblockUser(this.state.selectedUser.id.toString(), this.handleToggleBlockToastMessages);
			}
		}
	};

	private handleDeleteUser = (user: IUser) =>
	{
		this.setState({ selectedUser: user });
		this.openDeleteModal();
	};

	private handleEditUser = (user: IUser) =>
	{
		if (this.state.isSavingEdits)
			return;

		this.setState({ isResetUserEnabled: false });

		if (this.state.editingUser == user) {
			this.setState({ editingUser: null });
		}
		else {
			this.state.editedUserData.firstName = user.firstName;
			this.state.editedUserData.lastName = user.lastName;
			this.state.editedUserData.email = user.email;
			this.state.editedUserData.userRole = user.userRole;
			this.state.editedUserData.isTemporary = user.isTemporary;

			//this.setState({ editedUserData: user });
			this.setState({ editingUser: user });
			this.enableEditEffect();
		}
	};

	private async enableEditEffect()
	{
		this.setState({ isSavingEdits: true });

		await delay(200);

		this.setState({ isSavingEdits: false });
	};

	private handleSaveUser = (userData: IUserCreate) =>
	{
		if (this.state.editingUser && !this.state.isSavingEdits) {
			this.state.editingUser.firstName = userData.firstName;
			this.state.editingUser.lastName = userData.lastName;
			this.state.editingUser.email = userData.email;
			this.state.editingUser.userRole = userData.userRole;

			this.setState({ isSavingEdits: true });

			this.props.updateUser(this.state.editingUser, this.handleEditToastMessages);
		}
	};

	private handleResetUser = () =>
	{
		if (!this.state.isSavingEdits) {
			this.setState({ isResetUserEnabled: !this.state.isResetUserEnabled });
		}
	};

	private handleToggleBlockUser = (user: IUser, doBlock: boolean) =>
	{
		if (!this.state.selectedUser) {
			this.setState({ isBlocking: doBlock, selectedUser: user });
			this.openBlockModal();
		}
	};

	private handleToastMessages = async (msgId: string, type: ToastType) =>
	{
		this.props.addToast({ msgId, type });
	};

	private handleToggleBlockToastMessages = (msgId: string, type: ToastType) =>
	{
		if (this.state.selectedUser && type === ToastType.Success) {
			this.state.selectedUser.isBlocked = this.state.isBlocking;
		}
		this.setState({ selectedUser: null });

		this.props.addToast({ msgId, type });
	};

	private handleResetToastMessages = (msgId: string, type: ToastType) =>
	{
		if (this.state.editingUser && type === ToastType.Success) {
			this.props.cleanUsers();
			//this.state.usersResetList.push(this.state.editingUser.id);
			//console.log("USER HAS BEEN RESET???? " + this.state.usersResetList.includes(this.state.editingUser.id));
		}

		this.setState({ isSavingEdits: false });
		this.setState({ editingUser: null });

		this.props.addToast({ msgId, type });

		this.LoadUsers();
	};

	private handleEditToastMessages = (msgId: string, type: ToastType) =>
	{
		if (!this.state.isResetUserEnabled) {
			this.setState({ isSavingEdits: false });
		}
		else if (this.state.editingUser) {
			if (this.state.isResetUserEnabled) {
				console.log("Trying to reset: " + this.state.editingUser ? this.state.editingUser.userName : "ERROR");
				this.props.resetUser(this.state.editingUser.id.toString(), this.handleResetToastMessages);
				this.setState({ isResetUserEnabled: false });
			}
		}

		this.props.addToast({ msgId, type });
	};

	private handleGetAuthCode = (authCode: IAuthorizationCode) => {
		return (
			<div className={"users-item-row"} key={authCode.code}>
				<Icon name="person" />
				<div className="users-item-row-info">
					<span className="users-item-row-name">{`${authCode.code.substring(0, 4)}***** - ${authCode.crmEntryPoint}`}</span>
				</div>
					<div className="users-item-row-action">
						<UncontrolledButtonDropdown onClick={(e: React.MouseEvent<HTMLButtonElement>) => e}>
							<DropdownToggle>
								<Icon name="more_vert" />
							</DropdownToggle>
							<DropdownMenu right={true}>
								<DropdownItem onClick={() => this.props.deleteAuthCode(authCode.code)}>
									<T v="Users.Delete" />
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
			</div>
		);
	};


	private handleGetUsers(user: IUser): JSX.Element {
		var teamName = this.getUserTeamName(user);
		return <UsersRow
			key={user.id}
			user={user}
			loggedUser={this.props.loggedUser}
			isSameUser={this.props.loggedUser.id == user.id}
			userData={this.state.editedUserData}
			userTeamName={teamName}
			isEditMode={user == this.state.editingUser}
			isSavingEdits={this.state.isSavingEdits}
			isResetEnabled={this.state.isResetUserEnabled}
			isCreationRecent={this.getUserRecent(user)}
			getUserCreationDate={this.getSingleUserCreationDate}
			onDeleteUser={this.handleDeleteUser}
			onToggleResetUser={this.handleResetUser}
			onEditUser={this.handleEditUser}
			onCopyUserLink={this.handleCopyUserLink}
			onSaveUser={this.handleSaveUser}
			onMoveUser={this.onMoveUser}
			onBlockUser={(x) => this.handleToggleBlockUser(x, true)}
			onUnblockUser={(x) => this.handleToggleBlockUser(x, false)}
		/>;
	}

	private handleCopyUserLink = (user: IUser | null) =>
	{
		this.setState({ selectedUser: user });
		//navigator.clipboard.writeText(window.location.href + "/temporaryuserWIP");
		//this.props.getBearerToken(userCreated.id.toString(), this.state.linkedProduct ? this.state.linkedProduct : "", "", this.tempUserLinkCallbackForRedirect);
		this.props.getBearerToken(user ? user.id.toString() : "", this.state.linkedProduct ? this.state.linkedProduct : "", "", this.handleUserLinkRetrieved);
	}

	private handleUserLinkRetrieved = (link: string, msgId: string, type: ToastType) =>
	{
		if (type == ToastType.Success) {
			this.setState({ authCode: link });
			const url = `${window.location}temporaryUserAccess/${link}/${this.state.linkedProduct}/call`;
			this.openTokenModal(url);
		}
		else {
			this.props.addToast({ msgId, type });
		}
	};

	private onMoveUser = (user: IUser) => {
		console.log(user.id);
		this.setState({ userToMove: user, openLinkTeamModal: true });
	};

	private getUserTeamName(user: IUser): string | null
	{
		var teamName : string | null = null;
		var team = this.props.teams.find(x => x.id === user.teamId);

		if (user.teamId != null && team != undefined){
			teamName = team.name;
		}

		console.log(team);

		return teamName;
	}

	private getSingleUserCreationDate(user: IUser): string
	{
		var now = moment();
		var date = moment(user.creationDate).utc(true).local();

		var minutes = now.diff(date, 'minutes');
		var hours = Math.abs(Math.floor(minutes / 60));
		var minutesLeft = Math.abs(Math.floor(((minutes / 60) - hours) * 60));

		return hours + ":" + ((minutesLeft < 10) ? "0" + minutesLeft : minutesLeft);
	}

	private handleGenericProduct = (product: IProduct) =>
	{
		this.setState({ genericProductId: product.id });
		this.setState({ linkedProduct: product.id });
	}

	private getUserRecent(user: IUser): boolean
	{
		var now = moment();
		var date = moment(user.creationDate).utc(true).local();

		var minutes = now.diff(date, 'minutes');

		return minutes <= 4;
	}

	// private handleCopyUserLink = (user: IUser | null) =>
	// {
	// 	this.props.createTempUser(this.handleTempUserCallback);		
	// }

	private handleCopyToClipboard = async (link: string | null) =>
	{
		//navigator.clipboard.writeText(link);
		try {
			let anyNavigator: any
			anyNavigator = window.navigator
			if (link) await anyNavigator.clipboard.writeText(link);
		} catch (Exception) {

		}
	}

	private handleAddUser = () =>
	{
		this.setState({ isCreateMode: true });
		//this.props.history.push(`/user`);
	};

	private handleAddTempUser = () =>
	{
		this.setState({ linkedProduct: this.state.genericProductId ? this.state.genericProductId : null });

		this.setState({ isCreatingUser: true });

		this.props.createTempUser(this.handleTempUserCallback);
		//this.props.history.push(`/tempuser`);
	};


	userId: string = "";
	productId:string = "";
	section:string = "call";
	token:string = "";


	private handleTempUserCallback = async (userCreated: IUser | null, msgId: string, type: ToastType) =>
	{
		if (type == ToastType.Success && userCreated) {
			this.setState({ isCreatingUser: false, isTokenModalOpen: true, createdUser: userCreated });

			this.userId = userCreated.id.toString();
			this.productId =  this.state.linkedProduct ? this.state.linkedProduct : "";
			this.section = this.state.selectedSection? this.state.selectedSection : "";
			//this.props.getBearerToken(userCreated.id.toString(), this.state.linkedProduct ? this.state.linkedProduct : "", "", this.tempUserLinkCallbackForRedirect);
			this.props.getBearerToken(userCreated.id.toString(), this.state.linkedProduct ? this.state.linkedProduct : "", "", this.tempUserLinkCallbackForRedirect);		
			this.LoadUsers();
		}

		this.props.addToast({ msgId, type });
	};

	private generateUserUrl = () => { //restituisce l'url composto

	this.section = this.state.selectedSection? this.state.selectedSection : "";
	
	console.log("GenerateUserUrl: productId --> "+ this.productId + " redirectSection--> " + this.section + "token--> " + this.token);
	var link: string =  window.location.href;
	//add cookies window href

	switch(this.section) { 
		case "troubleshooting": { 
		  link += "temporaryUserAccess/" + this.token +"/" + this.state.linkedProduct + "/troubleshooting";		  
		  break; 
		} 
		case "received": { 
			link += "temporaryUserAccess/" + this.token +"/" + this.state.linkedProduct + "/received";
		   break; 
		}
		case "files": { 
			link += "temporaryUserAccess/" + this.token +"/" + this.state.linkedProduct + "/files";
			break; 
		 }
		case "call": { 
			link += "temporaryUserAccess/" + this.token +"/" + this.state.linkedProduct + "/call";
			break; 
		 } 
		default: { 
			link += "temporaryUserAccess/" + this.token +"/" + this.state.linkedProduct + "/call";
			break;  
		} 
	 } 

	 console.log("Redirect link: " + link)
	 this.setState({ generatedLink: link });  //usa questo per settare il link
			
	}
}

const mapStateToProps = (state: IRootState) => ({
	loggedUser: state.userInfo.data,
	loading: state.users.loading,
	error: state.users.error,
	notFound: state.users.notFound,
	saving: state.users.saving,
	currentCompany: state.company.data.company,
	users: toArray(state.users.data),
	authCodes: state.companies.authCodes,
	teams: toArray(state.users.teamsData),
	referenceTeamLoading: state.users.referenceTeamLoading,
	//isEmpty: state.users.isEmpty,
	products: toArray(state.products.data),
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			addEntryPoint: companyActions.addCrmEntryPoint,
			deleteAuthCode: companyActions.deleteAuthCode,
			addAuthCode: companyActions.addAuthCode,
			getAuthCodes: companyActions.getAuthCode,
			getSingleUser: actions.getSingleUser,
			updateUser: actions.updateUser,
			resetUser: actions.resetUserPsw,
			moveToTeam: actions.moveToTeam,
			getUsersByCompany: actions.getUsersByCompany,
			getUsersByTeam: actions.getUsersByTeam,
			getUsers: actions.getUsers,
			cleanUsers: actions.cleanUsers,
			deleteUser: actions.deleteUser,
			getBearerToken: actions.getBearerToken,
			blockUser: actions.blockUser,
			unblockUser: actions.unblockUser,
			createTempUser: actions.createTempUser,
			getProducts: productActions.getProducts,
			getGenericProduct: productActions.getGenericProduct,
			getTeams: actions.getTeamsByCompany,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps & RouteProps & InjectedToastsProps;
type RouteProps = RouteComponentProps<{ id: string }>;

export const Users = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(withToasts(UserList)));
