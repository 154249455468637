import { resolve } from "dns";
import { ActionType, createAction } from "typesafe-actions";
import { ICreateProductDto, Id, IProduct, IUpdateProductDto } from "../../types";

export const actions = {
	addReference: createAction("data/products/addReference", resolve => (productId: string, fileId: string) => resolve({ productId, fileId })),
	addReferenceSuccess: createAction("data/products/addReferenceSuccess", resolve => (productId: string, fileId: string) =>
		resolve({ productId, fileId }),
	),
	addReferenceError: createAction("data/products/addReferenceError", resolve => (err: string) => resolve(err)),
	removeReference: createAction("data/products/removeReference", resolve => (productId: string, fileId: string) => resolve({ productId, fileId })),
	removeReferenceSuccess: createAction("data/products/removeReferenceSuccess", resolve => (productId: string, fileId: string) =>
		resolve({ productId, fileId }),
	),
	removeReferenceError: createAction("data/products/removeReferenceError", resolve => (err: string) => resolve(err)),

	createProduct: createAction("data/products/create", resolve => (dto: ICreateProductDto, callback?: () => void) => resolve({ dto, callback })),
	createProductError: createAction("data/products/createError", resolve => (err: string) => resolve(err)),
	createProductSuccess: createAction("data/products/createSuccess", resolve => (data: IProduct) => resolve(data)),
	deleteProduct: createAction("data/products/delete", resolve => (productId: string) => resolve(productId)),
	deleteProductSuccess: createAction("data/products/deleteSuccess", resolve => (productId: string) => resolve(productId)),
	deleteProductError: createAction("data/products/deleteError", resolve => (err: string) => resolve(err)),
	deleteProductThumbnail: createAction("data/products/deleteThumbnailForProduct", resolve => (productId: string) => resolve(productId)),
	deleteProductThumbnailSuccess: createAction("data/products/deleteThumbnailForProductSuccess", resolve => (id: string) => resolve(id)),
	deleteProductThumbnailError: createAction("data/products/deleteThumbnailForProductError", resolve => (err: string) => resolve(err)),
	getProductsByCompanyId: createAction("data/products/getProductsByCompanyId", resolve => (companyId: string) => resolve(companyId)),
	getProductsByCompanyIdError: createAction("data/products/getProductsByCompanyIdError", resolve => (error: string) => resolve(error)),
	getProductsByCompanyIdSuccess: createAction("data/products/getProductsByCompanyIdSuccess", resolve => (products: IProduct[]) => resolve(products)),
	getSingleProduct: createAction("data/products/getSingle", resolve => (productId: string) => resolve(productId)),
	getSingleProductMissing: createAction("data/products/getSingleMissing"),
	getSingleProductError: createAction("data/products/getSingleError", resolve => (error: string) => resolve(error)),
	getSingleProductSuccess: createAction("data/products/getSingleSuccess", resolve => (product: IProduct) => resolve(product)),
	getGenericProduct: createAction("data/products/getGeneric", resolve => (callback: (product: IProduct) => void) => resolve(callback)),
	getGenericProductMissing: createAction("data/products/getGenericMissing"),
	getGenericProductError: createAction("data/products/getGenericError", resolve => (error: string) => resolve(error)),
	getGenericProductSuccess: createAction("data/products/getGenericSuccess", resolve => (product: IProduct) => resolve(product)),
	getProducts: createAction("data/products/get"),
	getProductsError: createAction("data/products/getError", resolve => (err: string) => resolve(err)),
	getProductsSuccess: createAction("data/products/getSuccess", resolve => (products: IProduct[]) => resolve(products)),
	updateProduct: createAction("data/products/updateProduct", resolve => (dto: IUpdateProductDto) => resolve(dto)),
	updateProductSuccess: createAction("data/products/updateProductSuccess", resolve => (data: IUpdateProductDto) => resolve(data)),
	updateProductError: createAction("data/products/updateProductError", resolve => (err: string) => resolve(err)),
	onThumbnailUpdated: createAction("data/products/onThumbnailUpdated", resolve => (productId: string) => resolve(productId)),
};

export type Action = ActionType<typeof actions>;
