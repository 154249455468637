import { ActionType, createAction } from "typesafe-actions";
import { IQueueUser, ISession } from "../../types";
import { IUserType } from "../../WebRTC/RTCHelpers";

export const actions = {
	connectToPeer: createAction(
		"call-queue/connectToPeer",
		resolve => (
			peerId: number,
			productId: string,
			hololensEvents: string,
			userId: string,
			roomId: string,
			userType: IUserType,
			operatorName: string,
			userAgent: string,
			ticketId?: string
		) => resolve({ peerId, productId, hololensEvents, userId, roomId, userType, operatorName, userAgent, ticketId }),
	),
	getParticipantUsername: createAction("call-queue/getParticipantUsername", resolve => (username: string) => resolve({ username })),
	getUploadProgressValue: createAction("call-queue/getUploadProgressValue", resolve => (value: number) => resolve({ value })),
	connectToPeerSuccess: createAction("call-queue/connectToPeerSuccess"),
	connectToSession: createAction("call-queue/connectToSession", resolve => (productId: string, roomId: string, userName: string, userType: IUserType) =>
		resolve({ productId, roomId, userName, userType }),
		),
	connectToSessionSuccess: createAction("call-queue/connectToSessionSuccess"),
	endCall: createAction("call-queue/endCall"),
	connect: createAction("call-queue/connect"),
	connected: createAction("call-queue/connected"),
	answerCall: createAction("call-queue/answerCall", resolve => (peerId: number) => resolve(peerId)),
	signIn: createAction("call-queue/signIn"),
	signInSuccess: createAction("call-queue/signInSuccess", resolve => (users: IQueueUser[]) => resolve(users)),
	receiveMessage: createAction("call-queue/receiveMessage", resolve => (msg: string) => resolve(msg)),
	toggleMinimizeCallWindow: createAction("call-queue/minimizeCallWindow"),
	receiveTiketId: createAction("call-queue/ReceiveTiketId", resolve => (ticketId: string) => resolve(ticketId)),
	listUpdate: createAction("call-queue/listUpdate", resolve => (users: IQueueUser[]) => resolve(users)),
	sessionListUpdate: createAction("call-queue/sessionListUpdate", resolve => (sessions: ISession[]) => resolve(sessions)),
	connectionId: createAction("all-queue/GetConnectionId", resolve => (connectionId: string) => resolve(connectionId)),
	setIsNotificationExpired: createAction("all-queue/GetConnectionId", resolve => (result: any) => resolve(result)),
};

export type Action = ActionType<typeof actions>;
