import { DateString, Id } from "./helpers";
import { ITicketItem } from "./ITicketItem";

export interface ITicket {
	id: Id;
	friendlyId: number;
	ticketId: number;
	operatorName: string;
	expertName: string;
	companyName: string;
	productName: string;
	productCode: string;
	description: string;
	productId: string;
	status: TicketStatus;
	start: DateString;
	end: DateString;
	ticketEvent: ITicketItem[];
}

export interface IUpdateTicket {
	id: Id;
	operatorName: string;
	description: string;
	status: TicketStatus
}

export enum TicketStatus {
	Open,
	Working,
	WaitingForFeedback,
	Solved,
	ClosedButNotSolved,
	NeedFurtureActivity,
	NoNeedToSolved,
}

export enum TicketStatusText2 {
	Open = "TicketStatus.Open",
	Working = "TicketStatus.Working",
	WaitingForFeedback = "TicketStatus.WaitingForFeedback",
	Solved = "TicketStatus.Solved",
	ClosedButNotSolved = "TicketStatus.ClosedButNotSolved",
	NeedFurtureActivity = "TicketStatus.NeedFurtureActivity",
	NoNeedToSolved = "TicketStatus.NoNeedToSolved",
}

// tslint:disable-next-line:one-variable-per-declaration
export const TicketStatusText: { [id: number]: string } = {
	[TicketStatus.Open]: "TicketStatus.Open",
	[TicketStatus.Working]: "TicketStatus.Working",
	[TicketStatus.WaitingForFeedback]: "TicketStatus.WaitingForFeedback",
	[TicketStatus.Solved]: "TicketStatus.Solved",
	[TicketStatus.ClosedButNotSolved]: "TicketStatus.ClosedButNotSolved",
	[TicketStatus.NeedFurtureActivity]: "TicketStatus.NeedFurtureActivity",
	[TicketStatus.NoNeedToSolved]: "TicketStatus.NoNeedToSolved",
};
