export enum ToastType {
	Success = "Toast.Success",
	Warning = "Toast.Warning",
	Error = "Toast.Error",
	Info = "Toast.Info",
}

export interface IToastMessage {
	id: number;
	msgId?: string;
	msg?: string;
	timeoutId: number;
	iconName?: string;
	type: ToastType;
}

export interface ICreateToast {
	msgId?: string;
	msg?: string;
	type: ToastType;
}
