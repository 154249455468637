import React, { createContext } from "react";
import { logger } from "../../utilities/logger";
import { ToastList } from "./toast";
import { ICreateToast, IToastMessage } from "./toast.interfaces";

interface IToastContextType {
	addToast: (toast: ICreateToast) => void;
	dismissToast: (id: number) => void;
	toasts: IToastMessage[];
}

const ToastContext = createContext<IToastContextType>({
	addToast: (toast: ICreateToast) => void 0,
	dismissToast: (id: number) => void 0,
	toasts: [],
});

interface IToastProviderState {
	addToast: (toast: ICreateToast) => void;
	dismissToast: (id: number) => void;
	toasts: IToastMessage[];
}

export const ToastConsumer = ToastContext.Consumer;

export class ToastProvider extends React.Component<{}, IToastProviderState> {
	private toastId: number = 0;

	private addToast = (toast: ICreateToast) => {
		this.toastId += 1;
		const id = this.toastId;
		const newToast: IToastMessage = {
			id,
			msg: toast.msg,
			msgId: toast.msgId,
			timeoutId: window.setTimeout(() => this.dismissToastInternal(id), 5000),
			type: toast.type,
		};
		const toasts = [...this.state.toasts, newToast];
		this.setState({ toasts });
	};

	private dismissToastInternal = (toastId: number) => {
		logger.debug("[dismissToastInteral]: toastId:", toastId);
		const toasts = this.state.toasts.filter(i => i.id !== toastId);
		this.setState({ toasts });
	};

	private dismissToast = (toastId: number) => {
		const toast = this.state.toasts.find(i => i.id === toastId);

		if (!toast) {
			return;
		}

		window.clearTimeout(toast.timeoutId);
		const toasts = this.state.toasts.filter(i => i.id !== toastId);
		this.setState({ toasts });
	};

	// tslint:disable-next-line:member-ordering
	public state: IToastProviderState = {
		addToast: this.addToast,
		dismissToast: this.dismissToast,
		toasts: [],
	};

	// tslint:disable-next-line:member-ordering
	public render() {
		const value: IToastContextType = {
			addToast: this.addToast,
			dismissToast: this.dismissToast,
			toasts: this.state.toasts,
		};
		logger.debug(this.state.toasts);
		return (
			<ToastContext.Provider value={value}>
				<ToastList onDismiss={this.dismissToast} toasts={this.state.toasts} />
				{this.props.children}
			</ToastContext.Provider>
		);
	}
}
