import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions as filesActions } from "../../reducers/files";
import { actions as productsActions } from "../../reducers/products";
import { actions as userActions } from "../../reducers/users";
import { IFile, IProduct } from "../../types";
import { UserRole } from "../../types/IRoles";
import { toArray } from "../../utilities";
import { logger } from "../../utilities/logger";
import { FileList, LoadingPage, SearchBar } from "../globalComponents";
import { LinkDocumentModal } from "./LinkDocumentModal";
import "./ProductsDocumentationTab.scss";
import { UploadDocumentModal } from "./UploadDocumentModal";
import { LinkTeamModal } from "./LinkTeamModal";
import { ITeam } from "../../types/ITeam";
import { RouteComponentProps, withRouter } from "react-router";
import { actions as userInfoActions } from "../../reducers/user-info";

type DocumentationTabProps = ConnectProps & RouteProps & {
	product: IProduct;
	isCompanyNotSubordinate: boolean;
};

interface IDocumentationTabState {
	uploadDocumentModal: boolean;
	linkDocumentModal: boolean;
	linkTeamModal: boolean;
}

export class DocumentationTabComponent extends React.Component<DocumentationTabProps> {
	public state: IDocumentationTabState = {
		uploadDocumentModal: false,
		linkDocumentModal: false,
		linkTeamModal: false,
	};

	public componentDidMount() {
		/*
		 ** NOTE:
		 ** Remember, this component does NOT unmount and refresh its data on tab-change
		 ** We should fetch all the files related to the given productId here.
		 */
		this.props.getAllFiles();
		this.props.getReferences(this.props.userInfo.teamId as string);
		this.props.getFilesForProduct(this.props.product.id);
		this.props.getTeams();
		//this.props.history.push(`/product/${this.props.product.id}/documentation`);
	}

	public render() {
		// TODO: Implement onFileClicked
		const onFileClicked = (file: IFile) => logger.debug("onFileClicked", file);

		const { linkDocumentModal, linkTeamModal, uploadDocumentModal } = this.state;
		const { files, filesToAttach, filesDictionary, error, loading, product, userInfo } = this.props;

		if (error) {
			return (
				<Row>
					<h1>error</h1>
				</Row>
			);
		}

		if (loading) {
			return <LoadingPage />;
		}

		console.log(`[foo] teamId: ${userInfo.teamId}`);

		return (
			<React.Fragment>
				<Col className="col-no-gutters" md={12} style={{ paddingTop: 12 }}>
					{userInfo.userRole === UserRole.CompanyAdmin &&
						<React.Fragment>
							<LinkDocumentModal
								files={filesToAttach.filter(file => file.official)}
								referenceLoading={this.props.referenceLoading[this.props.product.id]}
								onCheck={this.handleCheck}
								loading={loading}
								linkedFileIds={product.reference}
								isOpen={linkDocumentModal}
								toggle={this.closeLinkModal}
							/>
							{userInfo.userRole === UserRole.CompanyAdmin && <LinkTeamModal
								teams={this.props.teams}
								product={this.props.product}
								onCheck={this.handleTeamCheck}
								loading={loading}
								referenceLoading={this.props.referenceTeamLoading}
								isOpen={linkTeamModal}
								toggle={this.closeTeamLinkModal}
							/>}
							{this.props.isCompanyNotSubordinate && <div>
								{this.props.product.name.toLowerCase() !== "generic" && <Button className="documentation-tab-button" onClick={this.openLinkModal} color="primary">
									<T v="ProductsProperties.LinkDocument" />
								</Button>}
								{(userInfo.teamId === null || userInfo.teamId === "") && this.props.teams.length > 0 && this.props.product.name.toLowerCase() !== "generic" && <Button className="documentation-tab-button" onClick={this.openTeamLinkModal} color="primary">
									<T v="ProductsProperties.LinkTeam" />
								</Button>}
								<UploadDocumentModal productId={product.id} isOpen={uploadDocumentModal} toggle={this.closeUploadModal} />
								{this.props.product.name.toLowerCase() !== "generic" && <Button className="documentation-tab-button" onClick={this.openUploadModal} color="primary">
									<T v="ProductsProperties.UploadDocument" />
								</Button>}
							</div>}
						</React.Fragment>
					}
					<SearchBar hideOnMobile />
				</Col>
				<Col className="col-no-gutters" sm="12">
					<FileList isCompanyNotSubordinate={this.props.isCompanyNotSubordinate} onFileClicked={onFileClicked} files={filesDictionary} idsToRender={product.reference} handleEnableFileForTemporaryUser={userInfo.userRole === UserRole.CompanyAdmin ? this.handleEnableFileForTemporaryUser : undefined} />
				</Col>
			</React.Fragment>
		);
	}

	private handleEnableFileForTemporaryUser = (fileToUpdate: IFile) => {
		fileToUpdate.isVisibleToTemporaryUser = !fileToUpdate.isVisibleToTemporaryUser;
		this.props.updateFile(fileToUpdate, () => { });
	};

	private handleCheck = (fileId: string) => {
		const { referenceLoading, product } = this.props;
		const { reference: referencedFileIds, id } = product;
		const shouldAdd = referencedFileIds.indexOf(fileId) === -1;

		if (referenceLoading[id] && referenceLoading[id].indexOf(fileId) !== -1) {
			return;
		}

		if (shouldAdd) {
			this.props.addReference(id, fileId);
		} else {
			this.props.removeReference(id, fileId);
		}
	};

	private handleTeamCheck = (team: ITeam) => {
		if (this.props.product) {
			const productId = this.props.product.id;
			var { referencedProductIds, id } = team;
			const shouldAdd = !referencedProductIds || referencedProductIds.indexOf(productId) === -1;

			if (this.props.referenceTeamLoading[id] && this.props.referenceTeamLoading[id].indexOf(productId) !== -1) {
				return;
			}

			if (shouldAdd) {
				console.log("Adding team reference to product");
				this.props.addTeamReference(id.toString(), productId);
			} else {
				console.log("Removing team reference to product: " + id + " - " + productId);
				this.props.removeTeamReference(id.toString(), productId);
			}
		}
	};

	private closeUploadModal = () => {
		this.setState({ uploadDocumentModal: false });
	};

	private openUploadModal = () => {
		this.setState({ uploadDocumentModal: true });
	};

	private openTeamLinkModal = () => {
		this.setState({ linkTeamModal: true });
	};

	private closeTeamLinkModal = () => {
		this.setState({ linkTeamModal: false });
	};

	private openLinkModal = () => {
		this.setState({ linkDocumentModal: true });
	};

	private closeLinkModal = () => {
		this.setState({ linkDocumentModal: false });
	};
}

const mapStateToProps = (state: IRootState) => ({
	error: state.files.error,
	filesToAttach: toArray(state.files.data),
	filesDictionary: state.files.data,
	files: toArray(state.files.dataForProduct),
	referenceLoading: state.products.referenceLoading,
	referenceTeamLoading: state.users.referenceTeamLoading,
	loading: state.files.loading,
	userInfo: state.userInfo.data,
	teams: toArray(state.users.teamsData),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getAllFiles: filesActions.getAllFiles,
			getFilesForProduct: filesActions.getFilesForProduct,
			addReference: productsActions.addReference,
			removeReference: productsActions.removeReference,
			updateFile: filesActions.updateFile,
			getTeams: userActions.getTeams,
			getReferences: userActions.getProductsByTeam,
			addTeamReference: userActions.addTeamReference,
			removeTeamReference: userActions.removeTeamReference,
			getUserInfo: userInfoActions.getUserInfo,
		},
		dispatch,
	);



type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;
type RouteProps = RouteComponentProps<{ id: string }>;

export const DocumentationTab = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(DocumentationTabComponent));
