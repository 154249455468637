import React from "react";
import Row from "reactstrap/lib/Row";
import {
	acceptedExtension,
	bytesToKiloBytes,
	classNames,
	delay,
	fileAccepted,
	fileMatchSize,
	fileValidator,
	getDataTransferItems,
	getDataTransferItemsOnInput,
	IDictionary,
	OriginalHtmlProps,
	isFileWithinSizeLimit,
} from "../../utilities";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { Icon } from "./Icon";
import { T } from "../../managers/I18n";
import "./Dropzone.scss";
import { IProgress, IFile } from "../../types";
import Col from "reactstrap/lib/Col";
import { IRootState } from "../../reducers/root-reducer";
import { bindActionCreators, Dispatch } from "redux";
import { actions as filesActions } from "../../reducers/files";
import { connect } from "react-redux";

interface IDropzoneCompanyLogoProps extends OriginalHtmlProps<HTMLDivElement>, InjectedToastsProps, ConnectProps {
	uploader: (files: File[], progressCallback: (progress: IProgress) => void, hidden?: boolean) => Promise<IFile[]>;
    onFileUploaded: (logoUrl: string) => void;
    validFileTypes: string[];
}

interface IDropzoneCompanyLogoState {
    isDropping: boolean;
	isLogoUploaded: boolean;
	progressMap: IDictionary<number>;
	acceptedFiles: File[],
	rejectedFiles: File[]
}

export class DropzoneCompanyLogo extends React.Component<IDropzoneCompanyLogoProps, IDropzoneCompanyLogoState> {
    public input = React.createRef<HTMLInputElement>();
    constructor(props: any) {
        super(props);

        this.state = {
            isDropping: false,
			isLogoUploaded: false,
			progressMap: {},
			acceptedFiles: [],
			rejectedFiles: [],
        }
    }

    render() {
        const { className } = this.props;

        return (
            <React.Fragment>
                <div className={classNames("app-dropzone-outer", className)}>
					<div
						className={this.getContainerClassNames()}
						onDrop={this.handleDrop}
						onDragEnter={this.handleOnDragEnter}
						onDragLeave={this.handleOnDragLeave}
						onDragOver={this.handleOnDragOver}
					>
						{this.renderDropzoneContent()}
					</div>
				</div>
                <input type="file" style={{ display: "none" }} onChange={this.handleInputChange} ref={this.input} multiple={false} />
            </React.Fragment>
        );
	}

	componentDidMount() {
		this.props.getFilesMaximumSizeLimitUpload();
	}
	
	private progressCallback = (progress: IProgress) => {
		this.setState({ progressMap: { ...this.state.progressMap, [progress.file.name]: progress.uploaded } });
	};

    private fileValidator = (file: File) => {
        const { validFileTypes } = this.props;
		const isValidFileType = fileAccepted(file, validFileTypes);
		const isValidTypeOrExtension = isValidFileType;
		let isBelowMaxFileSize = file.size < this.props.maxWeight; // File weight control / file size control / fileweight / filesize
		const isAccepted = isValidTypeOrExtension && isBelowMaxFileSize;
		return isAccepted;
	};

    private handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

		var droppedFiles: File[];
		try{
			droppedFiles = getDataTransferItemsOnInput(e);
			const { acceptedFiles, rejectedFiles } = fileValidator(droppedFiles as File[], this.fileValidator);
		
			if (rejectedFiles.length > 0) {
				this.props.addToast({ type: ToastType.Error, msgId: "Toast.UploadingLogoError" });
				return;
			}
	
			const progressMap = acceptedFiles.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {});
			const totalFiles = [...this.state.acceptedFiles, ...acceptedFiles];
			await this.setState({ acceptedFiles: totalFiles, progressMap: progressMap, rejectedFiles: rejectedFiles });
	
			const response = await this.props.uploader(acceptedFiles, this.progressCallback, true);
			for (const file of response) {
				this.props.onFileUploaded(file.realFileUri);
			}
			const newAcceptedFiles = this.state.acceptedFiles.filter(this.isFileUploading);
			await this.setState({ acceptedFiles: newAcceptedFiles });
	
			this.setState({ isLogoUploaded: true });
		}
		
		catch(err){
			console.log("[debug] dt error " + err);
		}

    };

    private renderDropzoneContent = () => {
		const { isDropping, isLogoUploaded, acceptedFiles } = this.state;

		if (acceptedFiles.length > 0) {
			return <Row>{acceptedFiles.map(this.renderFilePreview)}</Row>;
		}

		if (isDropping) {
			return (
				<div className="app-dropzone-info">
					<Icon name="image" />
					<T v="Upload.Drop" />
				</div>
			);
        }
        
        if (isLogoUploaded) {
            return (
                <div className="app-dropzone-info">
                    <Icon name="check_circle" />
                    <T v="Upload.UploadingConfirmation" />
                </div>
            );
        }

		return (
			<div className="app-dropzone-info" onClick={this.handleUploadFileClick} style={{ cursor: "pointer" }}>
				<Icon name="cloud_upload" />
				<T v="Upload.Drag" />
			</div>
		);
	};
	
	private renderFilePreview = (file: File) => (
		<Col md={4} key={file.name}>
			<div className="app-dropzone-file-preview">
				<div className="app-dropzone-file-preview-thumbnail">
					{this.getThumbnail(file)}
					<div style={this.getProgressStyle(file)} className="app-dropzone-file-preview-progress" />
				</div>
				{/* <div className="app-dropzone-file-preview-body">
					<div className="app-dropzone-file-preview-filename">{file.name}</div>
					<div className="app-dropzone-file-preview-filesize">{bytesToKiloBytes(file.size)}kb</div>
				</div> */}
			</div>
		</Col>
	);

	private getThumbnail = (file: File) => {
		const url = URL.createObjectURL(file);

		switch (file.type) {
			case "image/png":
				return <img src={url} className="app-dropzone-file-preview-thumbnail-img" />;
			case "application/pdf":
				return this.renderThumbnailIcon();
			default:
				return this.renderThumbnailIcon();
		}
	};

	private renderThumbnailIcon = () => (
		<div className="app-dropzone-file-preview-thumbnail-icon">
			<Icon name="file_copy" />
		</div>
	);

	private getProgressStyle = (file: File) => {
		const width = this.state.progressMap[file.name] * 100 + "%";
		const style: React.CSSProperties = {
			width,
		};
		return style;
	};

    private handleUploadFileClick = () => {
		if (this.input.current) {
			this.input.current.click();
		}
	};


    private handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
	};

	private handleOnDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
	};

	private handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: false });
	};

    private handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
		this.setState({ isDropping: false });

		const droppedFiles = getDataTransferItems(e);
		const { acceptedFiles, rejectedFiles } = await fileValidator(droppedFiles as File[], this.fileValidator);

		if (rejectedFiles.length > 0) {
			this.props.addToast({ type: ToastType.Error, msgId: "Toast.UploadingLogoError" });
			return;
		}
		const progressMap = acceptedFiles.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {});
		const totalFiles = [...this.state.acceptedFiles, ...acceptedFiles];
		await this.setState({ acceptedFiles: totalFiles, progressMap: progressMap });
		const response = await this.props.uploader(acceptedFiles, this.progressCallback, true);
		
		for (const file of response) {
			this.props.onFileUploaded(file.realFileUri);
		}
		const newAcceptedFiles = this.state.acceptedFiles.filter(this.isFileUploading);
		await this.setState({ acceptedFiles: newAcceptedFiles });
        this.setState({ isLogoUploaded: true });
	};

	private isFileUploading = (file: File) => {
		const idx = this.state.acceptedFiles.findIndex(f => f.name === file.name);

		if (idx !== -1) {
			return false;
		}

		return true;
	};

    private getContainerClassNames = () => {
		const { isDropping } = this.state;
		const base = "app-dropzone-container";

		if (isDropping) {
			return classNames(base, "is-dropping");
		}

		return base;
	};
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
	company: state.company.data.company,
	maxWeight: state.files.maxFileUploadWeight,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getFilesMaximumSizeLimitUpload: filesActions.getFilesMaximumSizeLimitUpload,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const DropzoneLogo = connect(mapStateToProps, mapDispatchToProps)(withToasts(DropzoneCompanyLogo));