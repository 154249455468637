import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer, rootSaga } from "../reducers";
import { composeEnhancers } from "../utilities";

function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
	const createdStore = createStore(rootReducer, enhancer);
	sagaMiddleware.run(rootSaga);
	return createdStore;
}

export const store = configureStore();
