import React from "react";
import * as THREE from "three";
import { IClips } from "../../types";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Spinner } from "./Spinner";
import { getCurrentLanguage, GetLangString } from "../../managers/I18n";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./FbxHandler.scss";
import { delay } from "../../utilities/delay";
import { Renderer } from "@google/model-viewer/lib/three-components/Renderer";
import { PerspectiveCamera } from "@google/model-viewer/node_modules/three";
import { USDZExporter } from "../../USDZExporter";
//import { USDZExporter } from 'three/example/jsm/exporters/USDZExporter';
import { AnimationAction } from "@google/model-viewer/node_modules/three";
//import { PerspectiveCamera } from "three/src/cameras/PerspectiveCamera";
import { Icon } from "./Icon";
import fflate from "fflate";
//import { AnimationAction } from "three/src/animation/AnimationAction";
import { ARButton } from 'three/examples/jsm/webxr/ARButton.js';
import { classNames } from "../../utilities/class-names";
import { troubleshootingItemsSaga } from "../../reducers/troubleshooting-items";
import { Vector3 } from "three";
import { AR_INTRO_URL, MODEL_PLACEHOLDER_URL } from "../../constants";
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

interface IFbxHandlerProps {
    uri: string;
    clips: IClips;
    allowArFunctions: boolean;
    activeCall: boolean;
    positioningModel: boolean;
}

interface IFbxHandlerState {
    isModelLoaded: boolean;
    isToastAllowed: boolean;
    isVoiceEnabled: boolean;
    animationPlay: boolean;
    action: AnimationAction | null;
    mixer: any;
    model: any;
    synth: any;
    scene: any | null;
    camera: any | null;
    currentAnimation: number;
    totalAnimations: number;
    animationText: string;
    backgroundLight: boolean;
    backgroundColor: any;
    arMode: boolean;
    renderer: any;
    hitTestSource: any | null;
    hitTestSourceRequested: boolean;
    isModelInScene: boolean;
    depthValue: number;
    rotationValue: number;
    translationXValue: number;
    translationYValue: number;
    isModelMenuOpen: boolean;
    isUSDZReady: boolean;
}

var lastOrientationEvent: any = null;
var arButton: any = null;
export class FbxHandler extends React.Component<IFbxHandlerProps, IFbxHandlerState> {
    private canvasRef: React.RefObject<HTMLCanvasElement>;
    private divRef: React.RefObject<HTMLDivElement>;
    private overlayElementsAR: React.RefObject<HTMLDivElement>;
    private divButtonsRef: React.RefObject<HTMLDivElement>;
    private anchorRef: React.RefObject<HTMLAnchorElement>;

    constructor(props: any) {
        super(props);

        const lang = getCurrentLanguage();

        this.state = {
            isModelLoaded: false,
            isToastAllowed: true,
            isVoiceEnabled: true,
            animationPlay: true,
            action: null,
            mixer: null,
            model: null,
            synth: window.speechSynthesis,
            scene: null,
            camera: null,
            currentAnimation: 0,
            totalAnimations: 0,
            animationText: this.props.clips.clipsData[0] ? this.props.clips.clipsData[0].text[lang] : '',
            backgroundLight: true,
            backgroundColor: new THREE.Color(0xDBDCE0),
            arMode: false,
            renderer: undefined,
            hitTestSource: null,
            hitTestSourceRequested: false,
            isModelInScene: false,
            depthValue: 0,
            rotationValue: 0,
            translationXValue: 0,
            translationYValue: 0,
            isModelMenuOpen: false,
            isUSDZReady: false,
        };
        this.canvasRef = React.createRef<HTMLCanvasElement>();
        this.divRef = React.createRef<HTMLDivElement>();
        this.overlayElementsAR = React.createRef<HTMLDivElement>();
        this.divButtonsRef = React.createRef<HTMLDivElement>();
        this.anchorRef = React.createRef<HTMLAnchorElement>();
    }

    componentDidMount() {
        const { uri, activeCall, positioningModel } = this.props;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100000);

        let light = new THREE.HemisphereLight(0xffffff, 0x454545, 1);
        light.position.set(0.5, 1, 0.25);
        scene.add(light);
        if (this.divRef.current && this.canvasRef.current) {
            let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: this.canvasRef.current, preserveDrawingBuffer: true });
            this.setState({ scene: scene, camera: camera, renderer: renderer });
            renderer.setPixelRatio(window.devicePixelRatio);
            if (activeCall && !positioningModel) {
                renderer.setSize(400, 400);
            } else {
                renderer.setSize(window.innerWidth, window.innerHeight);
            }

            renderer.xr.enabled = true;

            if (!(navigator.userAgent.toLowerCase().includes("ios") || navigator.userAgent.toLowerCase().includes("ipad") || navigator.userAgent.toLowerCase().includes("mac") || navigator.userAgent.toLowerCase().includes("iphone")))
                renderer.context.makeXRCompatible();
            renderer.shadowMap.enabled = true;
            renderer.gammaOutput = true;
            const clock = new THREE.Clock();
            const controls = new OrbitControls(camera, renderer.domElement);
            //let ambientLight = new THREE.AmbientLight(0xffffff, 1);
            //scene.add(ambientLight);
            let directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            scene.add(directionalLight);
            scene.background = new THREE.Color(0xDBDCE0);
            const manager = new THREE.LoadingManager();
            //manager.onStart = this.startSpinnerAndToast;
            manager.onProgress = this.stopSpinner;
            manager.onLoad = this.allowStopToast;
            const loader = new FBXLoader(manager);
            var exportedObj: any;
            var radius = 0;
            let reticle = new THREE.Mesh(
                new THREE.RingBufferGeometry(0.15, 0.2, 32).rotateX(- Math.PI / 2),
                new THREE.MeshBasicMaterial()
            );
            var objScaleX: number = 0;
            var objScaleY: number = 0;
            var objScaleZ: number = 0;

            if (activeCall && positioningModel) {
                scene.background = null;
            } else {
                scene.background = new THREE.Color(0xDBDCE0);
            }
            loader.load(uri, (obj: any) => {
                var box = this.getRadius(obj);
                radius = box.geometry.boundingSphere.radius * 2;
                console.log("Radius for this OBJ is: " + radius);
                const mixer = new THREE.AnimationMixer(obj);
                this.setState({ mixer: mixer, model: obj, totalAnimations: obj.animations.length });
                exportedObj = obj;
                objScaleX = exportedObj.scale.x;
                objScaleY = exportedObj.scale.y;
                objScaleZ = exportedObj.scale.z;
                scene.add(obj);
                camera.up.set(obj.up.x, obj.up.y, obj.up.z);
                camera.position.set(0, radius, radius);
                controls.target = new THREE.Vector3(
                    box.geometry.boundingSphere.center.x,
                    box.geometry.boundingSphere.center.y,
                    box.geometry.boundingSphere.center.z
                );
                controls.update();
                controls.screenSpacePanning = true;
                controls.update();
                const animate = () => {
                    const delta = clock.getDelta();
                    if (mixer) {
                        mixer.update(delta);
                    }
                    controls.update();
                    renderer.render(scene, camera);
                    requestAnimationFrame(animate);
                };

                animate();
                directionalLight.position.set(camera.position.x, camera.position.y + 200, camera.position.z);
                directionalLight.target = obj;

                if (obj.animations.length > 0) {
                    this.playAnim(0);
                }

                if (lastOrientationEvent != null) {
                    window.removeEventListener('orientationchange', lastOrientationEvent);
                    window.removeEventListener('resize', lastOrientationEvent);
                }
                this.handleOrientationChange(exportedObj, renderer, camera, radius);

                lastOrientationEvent = () => this.handleOrientationChange(exportedObj, renderer, camera, radius);//, startSpinner, stopSpinner);
                window.addEventListener('orientationchange', lastOrientationEvent);
                window.addEventListener('resize', lastOrientationEvent);

                if (this.divRef.current) {
                    this.divRef.current.addEventListener('orientationchange', lastOrientationEvent);
                    this.divRef.current.addEventListener('resize', lastOrientationEvent);
                }

                // const gltfExporter = new GLTFExporter();

                // const options = {
                // 	trs: true,
                // 	onlyVisible: true,
                // 	truncateDrawRange: true,
                // 	binary: true,
                // 	maxTextureSize: 4096
                // };

                // gltfExporter.parse(scene, function (result: any) {
                //         const blob = new Blob([result], { type: 'application/octet-stream' })
                //         const link = document.getElementById('link') as HTMLAnchorElement;
                //         link.download = "scena.glb";
                //         link.href = URL.createObjectURL( blob );
                // }, options);

                // const exporter = new USDZExporter();

                // //const arraybuffer = await exporter.parse( scene );
                // //const blob = new Blob( [ arraybuffer ], { type: 'application/octet-stream' } );
                // exporter.parse(scene).then((arrayBuffer: any) => {
                //     const blob = new Blob( [ arrayBuffer ], { type: 'application/octet-stream' } );
                //     //var file = new File([blob], "scene.usdz", { type: 'application/octet-stream' });
                //     const link = document.getElementById('link') as HTMLAnchorElement;
                //     link.download = "scena.usdz";
                //     link.href = URL.createObjectURL( blob );
                // });
            }, (p: ProgressEvent) => {
                console.log("FBXLoader " + (p.loaded / p.total) * 100 + "%");
            }, (e: string) => {
                console.log(e);
            });
            let controller = renderer.xr.getController(0);
            controller.addEventListener('select', () => {
                if (reticle.visible) {
                    exportedObj.position.setFromMatrixPosition(reticle.matrix);
                    exportedObj.scale.x = objScaleX / 100;
                    exportedObj.scale.y = objScaleY / 100;
                    exportedObj.scale.z = objScaleZ / 100;
                    var dir = new THREE.Vector3();
                    var worldQuat = new THREE.Quaternion();
                    reticle.getWorldQuaternion(worldQuat);
                    dir.copy(reticle.up).applyQuaternion(worldQuat);
                    var dirMul = 1500;
                    var pos = new THREE.Vector3(reticle.position.x + (dir.x * dirMul), reticle.position.y + (dir.y * dirMul), reticle.position.z + (dir.z * dirMul));
                    exportedObj.lookAt(pos.x, pos.y, pos.z);
                    var quaternionIdentity = new THREE.Quaternion().identity();
                    var reticleEuler = new THREE.Euler().setFromQuaternion(worldQuat, 'XYZ');
                    var identityEuler = new THREE.Euler().setFromQuaternion(quaternionIdentity, 'XYZ');
                    // var cameraQuaternion = new THREE.Quaternion();
                    // camera.getWorldQuaternion(cameraQuaternion);
                    // var angle = THREE.Math.radToDeg(worldQuat.angleTo(cameraQuaternion));
                    console.log("IDENTITY: " + identityEuler.x + " " + identityEuler.y + " " + identityEuler.z);
                    console.log("WORLD QUAT: " + reticleEuler.x + " " + reticleEuler.y + " " + reticleEuler.z)
                    var angle = THREE.Math.radToDeg(worldQuat.angleTo(quaternionIdentity));
                    console.log("ANGOLO: " + angle);
                    console.log("VALORI TRONCATI: " + (Math.trunc(reticleEuler.x * 1000) / 1000) + " " + (Math.trunc(reticleEuler.z * 1000) / 1000));
                    if ((Math.trunc(reticleEuler.x * 1000) / 1000) === (Math.trunc(reticleEuler.z * 1000) / 1000)) {//if (angle < 45 || angle > 135) {
                        var cameraPosition = new THREE.Vector3().setFromMatrixPosition(camera.matrixWorld);
                        exportedObj.lookAt(cameraPosition.x, exportedObj.position.y, cameraPosition.z);
                        // var material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
                        // var points = [];
                        // points.push( new Vector3(exportedObj.position.x, exportedObj.position.y, exportedObj.position.z) );
                        // points.push( new Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z) )
                        // var geometry = new THREE.BufferGeometry().setFromPoints( points );
                        // var line = new THREE.Line( geometry, material );
                        // scene.add( line );
                        // exportedObj.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.Math.degToRad(0));
                    }
                    scene.add(exportedObj);
                    reticle.visible = false;
                    this.setState({ isModelInScene: true });
                }
            });

            scene.add(controller);
            reticle.matrixAutoUpdate = false;
            reticle.visible = false;
            scene.add(reticle);

            this.divRef.current.appendChild(renderer.domElement);
            renderer.setAnimationLoop((timestamp: any, frame: any) => {
                const { hitTestSourceRequested } = this.state;
                if (frame) {
                    var referenceSpace = renderer.xr.getReferenceSpace();
                    var session = renderer.xr.getSession();
                    if (!hitTestSourceRequested) {
                        session.requestReferenceSpace('viewer').then((referenceSpace: any) => {
                            session.requestHitTestSource({ space: referenceSpace }).then((source: any) => {
                                this.setState({ hitTestSource: source });
                            });
                        });

                        arButton.style.cssText = "font-size: 15px; top: calc(100% - 831px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 71px); width: 45px; height: 45px;";
                        arButton.innerText = "VR";

                        if (this.divButtonsRef.current && this.divButtonsRef.current.contains(arButton) && this.overlayElementsAR.current) {
                            this.divButtonsRef.current.removeChild(arButton);
                            this.overlayElementsAR.current.appendChild(arButton);
                        }

                        session.addEventListener('end', () => {
                            arButton.style.cssText = "font-size: 15px; top: calc(100% - -21px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 169px); width: 45px; height: 45px;";
                            arButton.innerText = "AR";

                            const arIntro = document.getElementById("ar-intro");

                            if (arIntro && this.overlayElementsAR.current && this.overlayElementsAR.current.contains(arIntro)) {
                                this.overlayElementsAR.current.removeChild(arIntro);
                            }

                            if (this.divButtonsRef.current && this.overlayElementsAR.current && this.overlayElementsAR.current.contains(arButton)) {
                                this.overlayElementsAR.current.removeChild(arButton);
                                this.divButtonsRef.current.appendChild(arButton);
                            }

                            if (activeCall && positioningModel) {
                                scene.background = null;
                            } else {
                                scene.background = new THREE.Color(0xDBDCE0);
                            }

                            reticle.visible = false;
                            var box = this.getRadius(exportedObj);
                            radius = box.geometry.boundingSphere.radius * 2;
                            console.log("Radius for this OBJ is: " + radius);
                            scene.add(exportedObj);
                            camera.up.set(exportedObj.up.x, exportedObj.up.y, exportedObj.up.z);
                            camera.position.set(0, radius, radius);

                            controls.target = new THREE.Vector3(
                                box.geometry.boundingSphere.center.x,
                                box.geometry.boundingSphere.center.y,
                                box.geometry.boundingSphere.center.z
                            );
                            controls.update();

                            controls.screenSpacePanning = true;
                            controls.update();
                            this.setState({ arMode: false, hitTestSourceRequested: false, hitTestSource: null });
                        });
                        this.setState({ hitTestSourceRequested: true });
                    }

                    if (this.state.hitTestSource && !this.state.isModelInScene) {
                        var hitTestResults = frame.getHitTestResults(this.state.hitTestSource);

                        if (hitTestResults.length) {
                            var hit = hitTestResults[0];
                            reticle.visible = true;
                            reticle.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);
                            console.log('[debug] reticle rotation x, y, z: ' + reticle.up.x + ' ' + reticle.up.y + ' ' + reticle.up.z);
                            const arIntro = document.getElementById("ar-intro");

                            if (arIntro && this.overlayElementsAR.current && this.overlayElementsAR.current.contains(arIntro)) {
                                this.overlayElementsAR.current.removeChild(arIntro);
                            }
                        } else {
                            reticle.visible = false;
                        }
                    }
                } else {
                    directionalLight.position.set(camera.position.x, camera.position.y + 200, camera.position.z);
                }
                renderer.render(scene, camera);
            });
        }
    }

    render() {
        const { isModelLoaded, isToastAllowed, isVoiceEnabled, animationPlay, backgroundLight, isModelInScene, isModelMenuOpen } = this.state;

        return (
            <div className={this.getCurrentModelClassName()} ref={this.divRef}>
                <canvas id="canvas-stream-video" ref={this.canvasRef} />
                {isToastAllowed &&
                    <div className="toast-message" onClick={this.stopToast}>
                        <h3>{GetLangString('Toast.HintTitle')}</h3>
                        {GetLangString('Toast.HintBody')}<br />
                        {GetLangString('Toast.HintBodyPart1')}<br />
                        {GetLangString('Toast.HintBodyPart2')}
                    </div>
                }
                {!isModelLoaded &&
                    <div className="div-spinner" >
                        <Spinner />
                    </div>
                }
                {isModelLoaded && this.state.totalAnimations > 0 && <div className="box-title" style={{ fontSize: "14px", width: "101px", height: "100px", position: "absolute", margin: "16px", top: "24px", background: "#eb0028", borderRadius: "22px", paddingTop: "70px", paddingLeft: "20px", alignSelf: "flex-end", color: "white", fontWeight: "bold" }}>Step Info</div>}
                {isModelLoaded && this.state.totalAnimations > 0 &&
                    <div className="box" style={{ position: "absolute", margin: "10px", top: "0px", display: "flex" }}>
                        <div className="box-inner" style={{ overflowY: "auto", flex: "1", padding: "20px", background: "white", maxHeight: "100px", borderRadius: "22px", fontSize: "14px" }}>{this.state.animationText}</div>
                        <div style={{ width: "78px", height: "100px" }}></div>
                    </div>
                }
                {isModelLoaded &&
                    <div className="buttons-container" ref={this.divButtonsRef}>
                        {this.state.totalAnimations > 0 && <button style={{ left: "-115px", height: "48px", width: "48px" }} onClick={this.playPauseAnim} >
                            <Icon style={{ fontSize: "24px" }} name={animationPlay ? "pause" : "play_arrow"} />
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "-60px", height: "48px", width: "48px" }} onClick={this.previousAnimation} >
                            <Icon style={{ fontSize: "24px" }} name="skip_previous" />
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "-5px", height: "48px", width: "48px" }}>
                            {`${this.state.currentAnimation + 1}/${this.state.totalAnimations}`}
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "50px", height: "48px", width: "48px" }} onClick={this.nextAnimation} >
                            <Icon style={{ fontSize: "24px" }} name="skip_next" />
                        </button>}
                        {this.isUSDZReady() &&
                            <a rel="ar" ref={this.anchorRef}>
                                <img style={{ width: "80px", marginLeft: "calc(100% - 210px)", marginTop: "calc(100% + 15px)", position: "absolute" }} src="https://threejs.org/examples/files/arkit.png" />
                            </a>}
                    </div>}
                {isModelLoaded &&
                    <div className={this.getArElementsClassName()} ref={this.overlayElementsAR}>
                        {this.state.totalAnimations > 0 && <div className="box-title" style={{ fontSize: "14px", width: "101px", height: "100px", position: "absolute", margin: "16px", top: "24px", background: "#eb0028", borderRadius: "22px", paddingTop: "70px", paddingLeft: "20px", alignSelf: "flex-end", color: "white", fontWeight: "bold" }}>Step Info</div>}
                        {this.state.totalAnimations > 0 && <div className="box" style={{ position: "absolute", margin: "10px", top: "0px", display: "flex" }}>
                            <div className="box-inner" style={{ overflowY: "auto", flex: "1", padding: "20px", background: "white", maxHeight: "100px", borderRadius: "22px", fontSize: "14px" }}>{this.state.animationText}</div>
                            <div style={{ width: "78px", height: "100px" }}></div>
                        </div>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "calc(100% - 376px)", top: "calc(100% - 100px)", height: "48px", width: "48px" }} onClick={this.playPauseAnim} >
                            <Icon style={{ fontSize: "24px" }} name={animationPlay ? "pause" : "play_arrow"} />
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "calc(100% - 303px)", top: "calc(100% - 100px)", height: "48px", width: "48px" }} onClick={this.previousAnimation} >
                            <Icon style={{ fontSize: "24px" }} name="skip_previous" />
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "calc(100% - 230px)", top: "calc(100% - 100px)", height: "48px", width: "48px" }}>
                            {`${this.state.currentAnimation + 1}/${this.state.totalAnimations}`}
                        </button>}
                        {this.state.totalAnimations > 0 && <button style={{ left: "calc(100% - 155px)", top: "calc(100% - 100px)", height: "48px", width: "48px" }} onClick={this.nextAnimation} >
                            <Icon style={{ fontSize: "24px" }} name="skip_next" />
                        </button>}
                        {isModelInScene && <button style={{ top: "calc(100% - 166px)", left: "calc(100% - 81px)", width: "48px", height: "48px" }} onClick={this.removeModel}>
                            <Icon style={{ fontSize: "24px" }} name="delete" />
                        </button>}
                        {isModelMenuOpen &&
                            <div style={{ marginTop: "calc(100% + 80px)", background: "#efefef", position: "absolute", zIndex: 9999999, height: "100%" }}>
                                <h5 style={{ padding: "10px" }}>{GetLangString("ObjectSettings")}</h5>
                                <button style={{ top: "-16px", width: "35px", height: "35px" }} onClick={() => this.setState({ isModelMenuOpen: false })}>
                                    <Icon name="close" />
                                </button>
                                <div style={{ padding: "10px" }}>
                                    <p style={{ fontSize: "15px", padding: "1px" }}>{GetLangString('Rotation')}</p>
                                    {<input className="slider" type="range" min="-45" max="45" value={this.state.rotationValue} style={{ width: "95vw" }} onChange={(event) => { this.changeModelRotation(event) }} />}
                                </div>
                                <div style={{ padding: "10px" }}>
                                    <p style={{ fontSize: "15px", padding: "1px" }}>{GetLangString('ZMove')}</p>
                                    {<input className="slider" type="range" min="-50" max="50" value={this.state.depthValue} style={{ width: "95vw" }} onChange={(event) => { this.changeModelDepth(event) }} />}
                                </div>
                                <div style={{ padding: "10px" }}>
                                    <p style={{ fontSize: "15px", padding: "1px" }}>{GetLangString('YMove')}</p>
                                    <input className="slider" type="range" min="-50" max="50" value={this.state.translationYValue} style={{ width: "95vw" }} onChange={(event) => { this.moveModelY(event) }} />
                                </div>
                                <div style={{ padding: "10px" }}>
                                    <p style={{ fontSize: "15px", padding: "1px" }}>{GetLangString('XMove')}</p>
                                    {<input className="slider" type="range" min="-50" max="50" value={this.state.translationXValue} style={{ width: "95vw" }} onChange={(event) => { this.moveModelX(event) }} />}
                                </div>
                            </div>}
                        {isModelInScene &&
                            <button style={{ top: "calc(100% - 250px)", left: "calc(100% - 81px)", width: "48px", height: "48px" }} onClick={() => this.setState({ isModelMenuOpen: true })}>
                                <Icon style={{ fontSize: "24px" }} name="settings" />
                            </button>}
                        {/* {isModelInScene && <div className="dropup" style={{ top: "calc(100% - 81px)", left: "calc(100% + 1px)" }}>
                            <button style={{ top: "calc(100% - 170px)", left: "calc(100% - 81px)", bottom: "96px", height: "48px", width: "48px" }}>
                                <Icon style={{ fontSize: "24px" }} name="settings"/>
                            </button>
						 	<div className="dropup-content">
                                 <div>
                                    <p style={{ fontSize: "12px", padding: "1px" }}>{GetLangString('Rotation')}</p>
						 		    {<input type="range" min="-45" max="45" value={this.state.rotationValue} style={{ width: "250px" }} onChange={(event) => {this.changeModelRotation(event)}}/>}
                                 </div>
                                 <div>
                                    <p style={{ fontSize: "12px", padding: "1px" }}>{GetLangString('Depth')}</p>
                                    {<input type="range" min="1" max="100" value={this.state.depthValue} style={{ width: "250px" }} onChange={(event) => {this.changeModelDepth(event)}}/>}
                                </div>
                             </div> 
						</div>} */}
                        {/* {isModelInScene && 
                        <input type="range" min="-45" max="45" value={this.state.rotationValue} style={{ width: "250px", marginTop: "calc(100% + 200px)", marginLeft: "calc(100% - 275px)" }} onChange={(event) => {this.changeModelRotation(event)}}/>}
                        {isModelInScene && 
                        <input type="range" min="1" max="100" value={this.state.depthValue} style={{ width: "250px", marginTop: "calc(100% - 375px)", marginLeft: "calc(100% - 275px)" }} onChange={(event) => {this.changeModelDepth(event)}}/>
                        } */}
                        {this.state.totalAnimations > 0 && <button style={{ top: "calc(100% - 100px)", left: "calc(100% - 81px)", bottom: "96px", height: "48px", width: "48px" }} onClick={this.setVoiceEnabled}>
                            <Icon style={{ fontSize: "24px" }} name={isVoiceEnabled ? "volume_up" : "volume_off"} />
                        </button>}
                    </div>
                }
                {isModelLoaded && this.state.totalAnimations > 0 &&
                    <button style={{
                        marginLeft: "auto",
                        marginRight: "0px",
                        marginTop: "auto",
                        bottom: "96px",
                        height: "48px",
                        width: "48px"
                    }}
                        onClick={this.setVoiceEnabled}>
                        <Icon style={{ fontSize: "24px" }} name={isVoiceEnabled ? "volume_up" : "volume_off"} />
                    </button>}
                {isModelLoaded && <button style={{ marginLeft: "auto", marginRight: "0px", marginTop: "auto", bottom: "32px", height: "48px", width: "48px" }} onClick={this.changeBackgroundColor}>
                    <Icon style={{ fontSize: "24px" }} name={backgroundLight ? "brightness_6" : "brightness_5"} />
                </button>}
            </div>

        );
    }

    private isUSDZReady = () => {
        return (navigator.userAgent.toLowerCase().includes("iphone") || navigator.userAgent.toLowerCase().includes("ipad") || navigator.userAgent.toLocaleLowerCase().includes("mac") || navigator.userAgent.toLocaleLowerCase().includes("ios")) && this.state.isUSDZReady;
    }

    private getCurrentModelClassName = () => {
        const { activeCall } = this.props;

        if (activeCall) {
            return 'active-call-current-model';
        }

        return "div-current-model";
    };

    private exportGLTF = () => {
        const { scene } = this.state;

        var gltfExporter = new GLTFExporter();

        const options = {
            trs: false,
            onlyVisible: true,
            truncateDrawRange: true,
            binary: true,
            embedImages: true,
            maxTextureSize: Infinity
        };

        gltfExporter.parse(scene, (result: any) => {
            const blob = new Blob([result], { type: 'application/octet-stream' })
            const gltfUrl = URL.createObjectURL(blob);
            const gltfLoader = new GLTFLoader();
            gltfLoader.load(gltfUrl, async (gltf: any) => {
                this.setState({ isUSDZReady: true })
                const exporter = new USDZExporter();
                const arrayBuffer = await exporter.parse(gltf.scene);
                const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                if (this.anchorRef.current) {
                    this.anchorRef.current.download = "scena.usdz";
                    this.anchorRef.current.href = URL.createObjectURL(blob);
                }
                // exporter.parse(gltf.scene).then((arrayBuffer: any) => {
                //     const blob = new Blob( [arrayBuffer], { type: 'application/octet-stream' } );
                //     //var file = new File([blob], "scene.usdz", { type: 'application/octet-stream' });
                //     //const link = document.getElementById('link') as HTMLAnchorElement;
                //     if (this.anchorRef.current) {
                //         this.anchorRef.current.download = "scena.usdz";
                //         this.anchorRef.current.href = URL.createObjectURL( blob );
                //     }
                // });
            })
        }, options);
    }

    private changeModelRotation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { model } = this.state;

        model.rotateY(THREE.Math.degToRad(event.target.valueAsNumber - this.state.rotationValue));

        this.setState({ rotationValue: event.target.valueAsNumber });
    };

    private changeModelDepth = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { model } = this.state;

        model.translateZ(((event.target.valueAsNumber - this.state.depthValue) * model.scale.z));
        // if (event.target.valueAsNumber >= this.state.depthValue)
        //     model.translateZ(-((event.target.valueAsNumber - this.state.depthValue) * model.scale.z));
        // else
        //     model.translateZ(((this.state.depthValue - event.target.valueAsNumber) * model.scale.z));
        this.setState({ depthValue: event.target.valueAsNumber });
    };

    private moveModelX = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { model } = this.state;
        model.translateX(((event.target.valueAsNumber - this.state.translationXValue) * model.scale.x));
        this.setState({ translationXValue: event.target.valueAsNumber });
    }

    private moveModelY = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { model } = this.state;
        model.translateY(((event.target.valueAsNumber - this.state.translationYValue) * model.scale.y));
        this.setState({ translationYValue: event.target.valueAsNumber });
    }

    private removeModel = () => {
        const { scene, model } = this.state;
        scene.remove(model);
        this.setState({ isModelInScene: false });
    };

    private getArElementsClassName = () => {
        const { arMode } = this.state;
        const baseClass = "buttons-container";
        var mode = 'hidden';
        if (arMode)
            mode = '';

        return classNames(baseClass, mode);
    };

    private setVoiceEnabled = () => {
        const { synth, isVoiceEnabled } = this.state;
        var isVoiceEn = !isVoiceEnabled;
        if (!isVoiceEn) {
            synth.cancel();
        }
        this.setState({ isVoiceEnabled: isVoiceEn });
    };

    private changeBackgroundColor = () => {
        const { backgroundLight, scene } = this.state;
        const { activeCall, positioningModel } = this.props;
        if (!scene) {
            return;
        }
        if (activeCall && positioningModel) {
            scene.background = null;
        } else {
            if (backgroundLight) {
                scene.background = new THREE.Color(0x232323);
                this.setState({ backgroundLight: false });
            } else {
                scene.background = new THREE.Color(0xDBDCE0);
                this.setState({ backgroundLight: true });
            }
        }

    };

    private previousAnimation = () => {
        const { currentAnimation, model } = this.state;
        if (!model)
            return;

        if (currentAnimation > 0) {
            var currentAnim = currentAnimation;
            currentAnim--;

            this.playAnim(currentAnim);
            this.setState({ currentAnimation: currentAnim });
        }

    };

    private nextAnimation = () => {
        const { currentAnimation, model } = this.state;
        if (!model) {
            return;
        }

        if (model.animations.length === 1) {
            this.playAnim(0);
        } else if (currentAnimation < model.animations.length - 1) {
            var currentAnim = currentAnimation;
            currentAnim++;
            this.playAnim(currentAnim);
            this.setState({ currentAnimation: currentAnim });
        }
    };

    private playAnim = (anim: number) => {
        const { mixer, model, isVoiceEnabled, synth } = this.state;
        const { clips } = this.props;
        if (!mixer || !model) {
            return;
        }

        const lang = getCurrentLanguage();

        mixer.stopAllAction();
        let action = mixer.clipAction(model.animations[anim]);
        if (action) {
            action.clampWhenFinished = true;
			action.loop = THREE.LoopOnce;
			action.play();

			const clipsData = clips.clipsData[anim];

			synth.cancel();

			if (!clipsData) {
				this.setState(() => {
					return { animationText: "", action: action }
				});

				return;
			}

			const text = clipsData.text[lang];
			const speechText = clipsData.speechText[lang];

			this.setState(() => {
				return { animationText: (text && text !== "") ? text : "", action: action }
			});

			if (speechText && speechText !== "" && isVoiceEnabled) {

				const utterThis = new SpeechSynthesisUtterance(speechText);
				const voice = synth.getVoices().find((x: any) => x.lang.includes(navigator.language));

				utterThis.voice = voice ? voice : synth.getVoices()[0];
				synth.speak(utterThis);
			}
		}
    };

    private startSpinnerAndToast = () => {
        const { renderer, camera } = this.state;
        const { activeCall } = this.props;
        if (activeCall && this.divRef.current && renderer && camera) {
            var desiredHeight: number = 400;
            //let desiredWidth: number = (this.divRef.current.clientHeight / this.divRef.current.clientWidth) / desiredHeight;
            this.divRef.current.style.cssText = `width: ${400}%; height: ${desiredHeight}px;`;
            renderer.setSize(400, desiredHeight);
            renderer.setPixelRatio(400 / desiredHeight);
            //camera.up.set(obj.up.x, obj.up.y, obj.up.z);
            //camera.position.set(0, radius, radius);
            (camera as PerspectiveCamera).aspect = 400 / desiredHeight;
            (camera as PerspectiveCamera).clearViewOffset();
            //spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: 50%; margin-top: 35vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>'
        }
    };

    private playPauseAnim = () => {
        const { animationPlay } = this.state;
        if (animationPlay) {
            this.pauseAnim();
            this.setState({ animationPlay: false });
        } else {
            this.pauseAnim();
            this.setState({ animationPlay: true });
        }
    };

    private pauseAnim = () => {
        const { action } = this.state;
        if (!action) {
            return;
        }

        if (action.timeScale == 0) {
            action.timeScale = 1;
        } else {
            action.timeScale = 0;
        }
    }

    private stopSpinner = () => {
        this.setState({ isModelLoaded: true });
    };

    private allowStopToast = async () => {
        const { allowArFunctions } = this.props;
        const { renderer, scene, model } = this.state;
        if ((navigator.userAgent.toLowerCase().includes("ios") || navigator.userAgent.toLowerCase().includes("ipad") || navigator.userAgent.toLowerCase().includes("mac") || navigator.userAgent.toLowerCase().includes("iphone"))){

            this.exportGLTF();
        }

        if (allowArFunctions && renderer && scene && model) {
            arButton = ARButton.createButton(renderer, { requiredFeatures: ['hit-test'], optionalFeatures: ['dom-overlay'], domOverlay: { root: this.overlayElementsAR.current } });
            arButton.addEventListener('click', async () => {
                scene.remove(model);
                scene.background = null;
                this.setState({ arMode: true });
                const image = document.createElement('div');
                image.id = "ar-intro";
                image.style.background = `url('${AR_INTRO_URL}')`;
                image.style.cssText = "margin-top: 50%; margin-left: calc(50% - 130px);";
                if (this.overlayElementsAR.current)
                    this.overlayElementsAR.current.appendChild(image);
                // await delay(5000);
                // document.body.removeChild(image);
            });

            await delay(1000);

            if (arButton.innerText === "START AR") {
                console.log('[debug] AR supportato');
                arButton.innerText = "AR";
                arButton.style.cssText = "font-size: 15px; top: calc(100% - -21px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 169px); width: 45px; height: 45px;";

                if (this.divButtonsRef.current && arButton) {
                    console.log("[debug] bottone AR inserito");
                    this.divButtonsRef.current.appendChild(arButton);
                }
            }

            console.log("[debug] AR button text: " + arButton.innerText);
        }

        await delay(3000);
        this.setState({ isToastAllowed: false });
    };

    private stopToast = () => {
        this.setState({ isToastAllowed: false });
    }

    private getRadius = (obj: any): any => {
        var box = new THREE.BoxHelper(obj, 0xff0000);
        box.geometry.computeBoundingSphere();

        if (Number.isNaN(box.geometry.boundingSphere.radius) || box.geometry.boundingSphere.radius == 0) {
            for (var i = 0; i < obj.children.length; i++) {
                if (!Number.isNaN(box.geometry.boundingSphere.radius) && box.geometry.boundingSphere.radius != 0) {
                    return box;
                }
                else {
                    console.log("OBJ radius is NaN! Re-iterating on " + obj.children[i].name);
                    box = this.getRadius(obj.children[i]);
                }
            }
        }

        return box;
    }

    private handleOrientationChange = async (obj: any, renderer: Renderer, camera: PerspectiveCamera, radius: number, onStart?: () => void, onStop?: () => void) => {
        try {

            //if (div && div.parentNode){
            //const canvas = div.getElementsByTagName("canvas")[0] as HTMLCanvasElement;
            //canvas.width = window.innerWidth * 3;
            //canvas.height = window.innerHeight * 3;
            //canvas.style.cssText = `width: ${window.innerWidth}px; height: ${window.innerHeight}px;`;
            //}

            if (renderer && this.divRef.current) {
                if (onStart) onStart();

                await delay(200);

                console.log("Renderer found");
                let aspectRatio = 0;

                var divParentWidth = this.divRef.current.clientWidth;
                var divParentHeight = this.divRef.current.clientHeight;
                aspectRatio = divParentWidth / divParentHeight;

                renderer.setSize(divParentWidth, divParentHeight);

                if (camera) {
                    console.log("Camera found");

                    camera.up.set(obj.up.x, obj.up.y, obj.up.z);
                    camera.position.set(0, radius, radius);
                    (camera as PerspectiveCamera).aspect = aspectRatio;
                    (camera as PerspectiveCamera).clearViewOffset();
                }

                if (onStop) onStop();
            }
        } catch {
            console.error("Error while applying changes on rotation");
        }
    }
}


