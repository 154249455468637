import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { serverManager } from "../../managers/ServerManager";
import { IFile, IClip, IClips } from "../../types";
import { logger } from "../../utilities/logger";
import { actions } from "./files.actions";

export function* handleUpdateFile(action: ReturnType<typeof actions.updateFile>) {
	const { fileInfo, callback } = action.payload;
	try {
		yield call(serverManager.files.updateFile, fileInfo);
		yield put(actions.updateFileSuccess(fileInfo));

		// if (typeof callback === "function") {
		// 	const contentString = `Toast.CompanyUpdated`;
		// 	yield call(callback, contentString, true);
		// }
	} catch (error) {
		logger.error(error);
		// if (typeof callback === "function") {
		// 	const contentString = `Toast.CompanyUpdatedFailed`;
		// 	yield call(callback, contentString, false);
		// }

		yield put(actions.updateFileError("Something went wrong while saving compnay with id: " + fileInfo.id));
	}
}

export function* handleGetAllFiles(action: ReturnType<typeof actions.getAllFiles>) {
	try {
		const { data } : AxiosResponse<IFile[]> = yield call(serverManager.files.getAllFiles);
		yield put(actions.getAllFilesSuccess(data));
	} catch (error) {
		yield put(actions.getAllFilesError(error));
	}
}

export function* handleUploadModelThumbnail(action: ReturnType<typeof actions.uploadFileThumbnail>) {
	try {
		const { modelId, thumbnail } = action.payload;
		const { data }: AxiosResponse<IFile> = yield call(serverManager.files.uploadFileThumbnail, modelId, thumbnail);
		console.log("[foo] data thumbnail: " + data.thumbnailUrl);
		yield put(actions.uploadFileThumbnailSuccess(data));
		console.log("MODEL THUMBNAIL UPLOADED SUCCESSFULLY");
	} catch (err) {
		console.log("SOMETHING WENT WRONG WHILE UPLOADING MODEL THUMBNAIL");
		yield put(actions.uploadFileThumbnailError(err));
	}
}

export function* handleGetFiles(action: ReturnType<typeof actions.getFiles>) {
	try {
		const { page, searchString } = action.payload;
		logger.debug("Fetching files.");
		const { data }: AxiosResponse<IFile[]> = yield call(serverManager.files.get, page, searchString);
		yield put(actions.getFilesSuccess(data));
		logger.debug(data.length, "files fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getFilesError("Something went wrong."));
	}
}

export function* handleGetFilesByCompanyId(action: ReturnType<typeof actions.getFilesByCompanyId>) {
	try {
		const { companyId, page, searchString } = action.payload;
		logger.debug("Fetching files.");
		const { data }: AxiosResponse<IFile[]> = yield call(serverManager.files.getByCompanyId, companyId, page, searchString);
		yield put(actions.getFilesByCompanyIdSuccess(data));
		logger.debug(data.length, "files fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getFilesByCompanyIdError("Something went wrong."));
	}
}


export function* handleGetFilesWeight(action: ReturnType<typeof actions.getFilesWeight>) {
	try {
		logger.debug("Fetching files weight.");
		const { data }: AxiosResponse<number> = yield call(serverManager.files.getWeight);
		yield put(actions.getFilesWeightSuccess(data));
		logger.debug(data, "files weight fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getFilesWeightError("Something went wrong."));
	}
}

export function* handleGetFilesMaximumSizeLimitUpload(action: ReturnType<typeof actions.getFilesMaximumSizeLimitUpload>) {
	try {
		const { data }: AxiosResponse<number> = yield call(serverManager.files.getMaximumSizeLimit);
		yield put(actions.getFilesMaximumSizeLimitUploadSuccess(data));
		logger.debug(data, "files weight fetched successfully.");
	} catch (err) {
		yield put(actions.getFilesMaximumSizeLimitUploadError("Something went wrong."));
	}
}

export function* handleGetFilesMaxWeight(action: ReturnType<typeof actions.getFilesMaxWeight>) {
	try {
		logger.debug("Fetching files weight.");
		const { data }: AxiosResponse<number> = yield call(serverManager.files.getMaxWeight);
		yield put(actions.getFilesMaxWeightSuccess(data));
		logger.debug(data, "files weight fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getFilesMaxWeightError("Something went wrong."));
	}
}

export function* handleDeleteFile(action: ReturnType<typeof actions.deleteFile>) {
	try {
		const { fileId, callback } = action.payload;
		logger.debug("Deleting file with id", fileId);
		
		const response : AxiosResponse = yield call(serverManager.files.delete, fileId)

		yield put(actions.deleteFileSuccess(fileId));
		
		if (typeof callback === "function")
			yield call(callback);

		logger.debug("Successfully deleted file with id", action.payload);
	} catch (err) {
		logger.debug("Failed deleting file", action.payload);
		logger.error(err);
		yield put(actions.deleteFileError("Something went wrong."));
	}
}

export function* handleSetFileOfficial(action: ReturnType<typeof actions.setFileOfficial>) {
	const { callback, fileId } = action.payload;
	try {
		logger.debug("Setting official status to file with id", fileId);
		yield call(serverManager.files.setOfficial, fileId);
		yield put(actions.setFileOfficialSuccess(fileId));
		logger.debug("Successfully set official status to file with id", fileId);
	} catch (err) {
		logger.error(err);
		yield put(actions.setFileOfficialError("Something went wrong."));
	}
	finally {
		if (callback){
			yield call(callback);
		}
	}
}

export function* handleSetClips(action: ReturnType<typeof actions.setFileClipsData>) {
	const { dto, callback } = action.payload;

	try {
		const { data }: AxiosResponse<IClips> = yield call(serverManager.files.setClipsData, dto);
		yield put(actions.setFileClipsDataSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Clips data added`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Failed to created user`;
			yield call(callback, contentString, false);
		}
		yield put(actions.setFileClipsDataError("Something went wrong while setting model clips data"));
	}
}

export function* handleGetClips(action: ReturnType<typeof actions.getFileClipsData>) {
	const { fileId, callback } = action.payload;

	try {
		const { data }: AxiosResponse<IClips> = yield call(serverManager.files.getClipsData, fileId);
		yield put(actions.getFileClipsDataSuccess(data));

		if (typeof callback === "function") {
			yield call(callback, data, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			yield call(callback, null, false);
		}
		yield put(actions.getFileClipsDataError("Something went wrong while getting model clips data"));
	}
}

export function* handleGetFilesForProduct(action: ReturnType<typeof actions.getFilesForProduct>) {
	try {
		const { data }: AxiosResponse<IFile[]> = yield call(serverManager.products.getReferences, action.payload);
		console.log('[debug] filesExtracted: ' + data.length);
		yield put(actions.getFilesForProductSuccess(data));
	} catch (err) {
		logger.error(err);
		yield put(actions.getFilesForProductError(err));
	}
}

export function* filesSaga() {
	yield takeLatest(getType(actions.getAllFiles), handleGetAllFiles);
	yield takeLatest(getType(actions.uploadFileThumbnail), handleUploadModelThumbnail);
	yield takeLatest(getType(actions.updateFile), handleUpdateFile);
	yield takeLatest(getType(actions.getFilesForProduct), handleGetFilesForProduct);
	yield takeLatest(getType(actions.getFilesByCompanyId), handleGetFilesByCompanyId);
	yield takeLatest(getType(actions.setFileOfficial), handleSetFileOfficial);
	yield takeLatest(getType(actions.setFileClipsData), handleSetClips);
	yield takeLatest(getType(actions.getFileClipsData), handleGetClips);
	yield takeLatest(getType(actions.getFiles), handleGetFiles);
	yield takeLatest(getType(actions.getFilesWeight), handleGetFilesWeight);
	yield takeLatest(getType(actions.deleteFile), handleDeleteFile);
	yield takeLatest(getType(actions.getFilesMaximumSizeLimitUpload), handleGetFilesMaximumSizeLimitUpload);
}
