import { AxiosInstance } from "axios";
import { IProduct, IQueueSession, IQueueUser, ISession, ITicketItem } from "../../types";

export class QueueManager {
	private agent: AxiosInstance;
	private url: string = "CallQueues";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public listAll = () => this.agent.get<IQueueUser[]>(this.url + "/listall");
	public clearQueue = async () => {
		const { data } = await this.listAll();
		const dequeueRequests = data.map(peer => peer.peerId).map(this.dequeue);
		await Promise.all(dequeueRequests);
	};
	public enqueue = (productId: string, product: IProduct, peerId: number, ticketEvents: ITicketItem[], userAgent: string, ticketId?: string) => {
		const model = { productId, ticketId, events: JSON.stringify(ticketEvents) };
		console.log("Enqueue model: ", model, "peerId: ", peerId);
		return this.agent.post(this.url + `/enqueue?userAgent=${userAgent}`, model, { params: { peerId } });
	};
	public dequeue = (peerId: number) => this.agent.delete(this.url + "/dequeue", { params: { peerId } });

	public enqueueSession = (session: IQueueSession) => this.agent.post(this.url + "/enqueue/session", session);
	public dequeueSession = (sessionId: string) => this.agent.delete(this.url + "/dequeue/session", { params: { sessionId } });
	public listSessionAll = () => this.agent.get<ISession[]>(this.url + "/listall/sessions");
	public openToGeneric = (sessionId: string, isVisible: boolean) => this.agent.put(this.url + `/openToGeneric/session?sessionId=${sessionId}&isVisible=${isVisible}`);
	public openToTeam = (sessionId: string, isVisible: boolean) => this.agent.put(this.url + `/openToTeam/session?sessionId=${sessionId}&isVisible=${isVisible}`);
	public openToUser = (sessionId: string, userName: string) => this.agent.put(this.url + `/openToUser/session?sessionId=${sessionId}&userName=${userName}`);

	public disconnectCall = (peerId: number, sessionId: string) => {
		const headers = this.agent.defaults.headers;
		const uri = `${process.env.REACT_APP_LOCALHOST}api/${this.url}/disconnectcall?peerId=${peerId}&sessionId=${sessionId}`;		
		let hd: RequestInit = {
			keepalive: true,
			method: 'POST',
			headers: {}
		};
		
		if(headers.hasOwnProperty("X-Base-Token")){
			hd.headers = { "X-Base-Token": headers["X-Base-Token"] };
		}
		else if (headers.hasOwnProperty("Authorization")){
			hd.headers = { "Authorization": headers["Authorization"] };
		}	

		fetch(uri, hd);
	}
}
