import { all, fork } from "redux-saga/effects";
import { callQueueSaga } from "./call-queue";
import { companiesSaga } from "./companies";
import { companySaga } from "./company/company.saga";
import { filesSaga } from "./files/files.saga";
import { ovSaga } from "./ov/ov.saga";
import { productsSaga } from "./products";
import { ticketsSaga } from "./tickets";
import { tools } from "./tools/tools.saga";
import { troubleshootingItemsSaga } from "./troubleshooting-items";
import { troubleshootingLanguagesSaga } from "./troubleshooting-languages";
import { userInfoSaga } from "./user-info";
import { usersSaga } from "./users/users.saga";

export function* rootSaga() {
	yield all([
		fork(callQueueSaga),
		fork(filesSaga),
		fork(productsSaga),
		fork(troubleshootingItemsSaga),
		fork(ticketsSaga),
		fork(troubleshootingLanguagesSaga),
		fork(companiesSaga),
		fork(companySaga),
		fork(usersSaga),
		fork(userInfoSaga),
		fork(tools),
		fork(ovSaga),
	]);
}
