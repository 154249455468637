import React from "react";
import { OriginalHtmlProps } from "../../utilities";
import { Icon } from "./Icon";
import "./PlaceholderPage.scss";

export interface IPlaceholderPageProps extends OriginalHtmlProps<HTMLDivElement> {
	iconName: string;
}

export const PlaceholderPage: React.FunctionComponent<IPlaceholderPageProps> = ({ children, iconName }) => (
	<div className="app-placeholder-page">
		<Icon name={iconName} />
		{children}
	</div>
);
