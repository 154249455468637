import { ActionType, createAction } from "typesafe-actions";
import { UserRole } from "../../types/IRoles";
import { IUser } from "../../types/IUser";

export const actions = {
	getUserInfo: createAction("userinfo/get"),
	getUserInfoError: createAction("userinfo/getError", resolve => (err: string) => resolve(err)),
	getUserInfoSuccess: createAction("userinfo/getSuccess", resolve => (userInfo: IUser) => resolve(userInfo)),
	changeUserRole: createAction("changeUserRole/set", resolve => (role: UserRole) => resolve(role)),
};

export type Action = ActionType<typeof actions>;
