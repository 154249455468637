import React from "react";
import { IFile } from "../../types";
import { IDictionary } from "../../utilities";
import { FileItemRow } from "./FileItemRow";
import "./FileList.scss";

interface IFileListProps {
	files: IDictionary<IFile>;
	isCompanyNotSubordinate?: boolean;
	idsToRender: string[];
	onFileClicked: (file: IFile) => void;
	handleEnableFileForTemporaryUser?: (fileToUpdate: IFile) => void;
}

export const FileList: React.FunctionComponent<IFileListProps> = ({ files, idsToRender, onFileClicked, isCompanyNotSubordinate, handleEnableFileForTemporaryUser }) => (
	<div className="file-list-container">
		{idsToRender.map(fileId => (
			<FileItemRow key={fileId} onClick={() => onFileClicked(files[fileId])} file={files[fileId]} isCompanyNotSubordinate={isCompanyNotSubordinate} handleEnableFileForTemporaryUser={handleEnableFileForTemporaryUser} />
		))}
	</div>
);
