interface IDiffable {
	[key: string]: any;
}

export function objectDiff<T1, T2>(a: T1 & IDiffable, b: T2 & IDiffable) {
	const diffObj = Object.keys(b).reduce((diff: string[], key) => {
		if (a[key] === b[key]) {
			return diff;
		}

		return [...diff, key];
	}, []);

	return diffObj;
}
