import { defaultTo, merge, omit } from "ramda";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { ICompany } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./company.actions";
import moment from "moment";

export interface IState {
	data: IDictionary<ICompany>;
	isDeleting: IDictionary<boolean>;
	referenceLoading: IDictionary<string[]>;
	error: boolean;
	notFound: boolean;
	loading: boolean;
	saving: boolean;
}

export const initialState: IState = {
	data: {},
	isDeleting: {},
	referenceLoading: {},
	error: false,
	notFound: false,
	loading: false,
	saving: false,
};

export const reducer: Reducer<IState> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.getMyCompany):
			return {
				...state,
				notFound: false,
				loading: true,
			};
		case getType(actions.getMyCompanySuccess):
			//action.payload.expirationDate = moment(action.payload.expirationDate).add(-10, 'days').utc(true).local().format("YYYY-MM-DDTHH:mm:ss");
			return {
				...state,
				notFound: false,
				loading: false,
				data: {
					...state.data,
					company: action.payload,
				},
			};
		case getType(actions.getMyCompanyError):
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};
