import React from "react";
import { classNames, OriginalHtmlProps } from "../../utilities";
import "./Spinner.scss";

interface ISpinnerProps extends OriginalHtmlProps<HTMLDivElement> {
	[key: string]: any;
}

export const Spinner: React.FunctionComponent<ISpinnerProps> = ({ className, ...props }) => (
	<div className={classNames(className, "spinner")} {...props} />
);
