import moment from "moment";
import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { toArray } from "../../utilities";
import { connect, useDispatch } from "react-redux";
import { MODEL_PLACEHOLDER_URL, PDF_PLACEHOLDER_URL } from "../../constants";
import { IRootState } from "../../reducers";
import { IFile } from "../../types";
import { actions as filesActions } from "../../reducers/files";
import { FileTypes, getFileType } from "../../utilities";
import { classNames } from "../../utilities/class-names";
import { OriginalHtmlProps } from "../../utilities/html-props";
import "./FileItemRow.scss";
import { Icon } from "./Icon";
import { actions } from "../../reducers/tickets";
import { ToastType } from "../toast";
import { GetLangString } from "../../managers/I18n";

export type FileItemRowProps = {
	file: IFile;
	isCompanyNotSubordinate?: boolean;
	handleEnableFileForTemporaryUser?: (fileToUpdate: IFile) => void;
} & OriginalHtmlProps<HTMLDivElement>;

export const GetIconComponent = (file: IFile, customSize: string = "auto") => {
	
	switch (getFileType(file)) {
		case FileTypes.IMAGE:
			return file.thumbnailUrl ? <img height={customSize} className="file-item-row-image-thumb" src={process.env.REACT_APP_LOCALHOST + file.thumbnailUrl} /> : <Icon name="image" />;
		case FileTypes.PDF:
			return PDF_PLACEHOLDER_URL ? <img height={customSize} className="file-item-row-image-thumb" src={PDF_PLACEHOLDER_URL} /> : <Icon name="insert_drive_file" />;
		case FileTypes.GLB || FileTypes.FBX:
			return MODEL_PLACEHOLDER_URL ? <img height={customSize} className="file-item-row-image-thumb" src={MODEL_PLACEHOLDER_URL} /> : <Icon name="3d_rotation" />;
		case FileTypes.VIDEO:
			return <Icon name="movie" />;
		default:
			return <Icon name={"insert_drive_file"} />;
	}
};

export const FileItemRow: React.FunctionComponent<FileItemRowProps> = ({ className, file, handleEnableFileForTemporaryUser, isCompanyNotSubordinate, ...rest }) => {
	if (!file) {
		return null;
	}
	
	return (
		<div className={classNames(className, "file-item-row")} {...rest}>
			{GetIconComponent(file)}
			{/* <Icon name={getIconName(file)} /> */}
			<span className="file-item-row-filename">{file.name}</span>
			<span className={"file-item-row-date_created"}>
				{moment
					.utc(file.createdDate)
					.local()
					.format("LL")}
			</span>
			{handleEnableFileForTemporaryUser !== undefined && <div title={GetLangString('Toast.AllowAccessToTemporaryUsers')}><input hidden={!isCompanyNotSubordinate} checked={file.isVisibleToTemporaryUser} style={{ marginRight: "10px" }} type="checkbox" onChange={() => handleEnableFileForTemporaryUser(file)} /></div>}
		</div>
	);
};
