import { logger } from "./logger";

export interface IFileReceivedMessageData {
	type: MessageType;
	message: string | undefined;
	fileId: string;
}

export const parseOnFileReceivedMessage = (message: string | undefined): IFileReceivedMessageData => {
	const messageArray: string[] = String(message).split(":");
	if (messageArray.length !== 3) {
		throw new Error("Message From Hololens is not valid");
	}
	logger.debug("messageArray: ", messageArray);
	return {
		type: getMessageType(messageArray[0]),
		message: messageArray[1],
		fileId: messageArray[2],
	};
};

const getMessageType = (type: string): MessageType => {
	switch (type) {
		case "ERROR":
			return MessageType.ERROR;
		case "SUCCESS":
			return MessageType.SUCCESS;
		default:
			return MessageType.WARNING;
	}
};

export enum MessageType {
	ERROR = "ERROR",
	SUCCESS = "SUCCESS",
	WARNING = "WARNING",
}
