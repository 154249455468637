import React from "react";
import { connect } from "react-redux";
import { Lang } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { IUser, ICompany, Id, stringToBoolean, IQueueUser } from "../../types";
import { actions } from "../../reducers/company";
import { actions as actionCompanies } from "../../reducers/companies";
import { UserRole } from "../../types/IRoles";
import { getActiveClassName, toArray } from "../../utilities";
import { Icon } from "../globalComponents";
import { NavLink, NormalLink } from "./NavLink";
import "./NavMenu.scss";
import { Dispatch, bindActionCreators } from "redux";
import { InjectedToastsProps } from "../toast";
import { UploadDocumentModal } from "../products/UploadDocumentModal";
import ReactDOM from "react-dom";
import { UploadCompanyLogoModal } from "../products/UploadCompanyLogo";
import { actions as userActions } from "../../reducers/users";
import { ITeam } from "../../types/ITeam";
import { TeamInformationBase } from "../company/TeamInformation";
import { userInfo } from "os";
import { authProvider } from "../../auth/AuthProvider";

interface IOwnProps extends InjectedToastsProps
{
	id: Id;
}

interface IOwnState {
	company: ICompany | undefined;
	uploadLogoModal: boolean;
	teams: ITeam[];
	userTeam: ITeam | undefined;
}

interface IProps extends ConnectProps, IOwnProps { }


class NavMenuBase extends React.Component<IProps, IOwnState> {
	private editLogoButtonRef = React.createRef<HTMLButtonElement>();
	private companyLogoRef = React.createRef<HTMLImageElement>();
	
	
	constructor(props: IProps) {
		super(props);

		this.state = {
			company: undefined,
			uploadLogoModal: false,
			teams: [],
			userTeam: undefined,
		}
	}

	componentDidMount() {
		this.props.getMyCompany();
		this.props.getCompanies();
		this.props.getTeams();
	}

	componentDidUpdate(prevProps: IProps) {
		const prevAllowTeams = prevProps.company ? prevProps.company.allowTeams : null;
		const currentAllowTeams = this.props.company ? this.props.company.allowTeams : null;

		if (prevAllowTeams !== currentAllowTeams) {
			this.setState({ company: this.props.company });
		}

		if (prevProps.company !== this.props.company) {
			this.setState({ company: this.props.company });
		}

		if (prevProps.teams !== this.props.teams) {
			const userTeam = this.props.teams.find(x => x.id === this.props.userInfo.teamId);
			if (userTeam) {
				this.setState({ teams: this.props.teams, userTeam: userTeam });
			} else {
				this.setState({ teams: this.props.teams });
			}
		}

		if (prevProps.userInfo !== this.props.userInfo) {
			console.log(`[foo] userInfo: ${this.props.userInfo.id}`);
		}

		if (prevProps.company !== this.props.company) {
			console.log(`[foo] company: ${this.props.company.logoUrl}`);
		}
	}

	public render()
	{
		const { isNavOpen, userInfo } = this.props;
		const { company, uploadLogoModal } = this.state;

		const queue = this.filterQueue();
		
		return (<div className={getActiveClassName(isNavOpen, "layout-navmenu", "is-open")}>
			{isNavOpen && (
				<div className="layout-navmenu-title">
					<strong>holo</strong>
					<span>maintenance</span>
				</div>
			)}
			{!isNavOpen && <div className="layout-navmenu-small-title">H</div>}
			{isNavOpen && (
				<div className="text-center">
					<div>
						{userInfo.userRole === UserRole.SuperAdmin ? <Icon name="person" className="user-icon" /> : (company !== undefined && company.logoUrl !== null && company.logoUrl !== "") ? <img style={{ width: "95%", height: "auto" }} ref={this.companyLogoRef} src={`${process.env.REACT_APP_LOCALHOST}${company.logoUrl}`} /> : <Icon name="person" className="user-icon" />}
						{userInfo.userRole === UserRole.CompanyAdmin && <button style={this.companyLogoRef.current ? {right: `calc(${this.companyLogoRef.current.width}px - 184px)`, top: `calc(${this.companyLogoRef.current.height}px + 60px)`} : {top: "125px", right: "80px"}} className="edit-company-logo" onClick={this.openDropzone}>
							<Icon name="camera_alt" />
						</button>}
						<br />
					</div>
					
					<span>Welcome</span>
					<h5 className="user-name">
						{userInfo.firstName + " " + userInfo.lastName}
						<h6 className="company-name">
							{(userInfo.userRole == UserRole.Expert || userInfo.userRole == UserRole.CompanyAdmin) && company != undefined ? `${company.officialName}` : ""}
						</h6>
						{userInfo.teamId !== null && <h6 className="team-name">({this.state.userTeam ? this.state.userTeam.name : ""})</h6>}
					</h5>

				</div>
			)}
			<Lang>
				{getLanguageString => (
					<div className="layout-navmenu-body">
						<UploadCompanyLogoModal isOpen={uploadLogoModal} toggle={this.closeDropzone} getlogoUrl={this.getCompanyLogoUrl} removeLogo={this.removeLogo}/>
						{userInfo.userRole === UserRole.Expert && (
							<NavLink
								exact
								text={
									queue.length > 0
										? `${getLanguageString(
											"NavLink.Dashboard"
										)} (${queue.length})`
										: getLanguageString("NavLink.Dashboard")
								}
								iconName="dashboard"
								to="/"
							/>
						)}
						{userInfo.userRole === UserRole.SuperAdmin && (
							<NavLink
								exact
								text={getLanguageString("NavLink.Company")}
								iconName="store"
								to="/"
							/>
						)}
						{userInfo.userRole === UserRole.CompanyAdmin && (
							<NavLink
								exact
								text={getLanguageString("NavLink.MyCompany")}
								iconName="dashboard"
								to="/"
							/>
						)}
						{userInfo.userRole === UserRole.CompanyAdmin && company !== undefined && company.allowTeams ? (
							<NavLink
								text={getLanguageString("NavLink.Teams")}
								iconName="people"
								to="/teams"
							/>
						) : undefined}
						{userInfo.userRole === UserRole.Expert ||
							userInfo.userRole === UserRole.CompanyAdmin ? (
								<React.Fragment>
									<NavLink
										text={getLanguageString("NavLink.Products")}
										iconName="library_books"
										to="/products"
									/>
									<NavLink
										text={getLanguageString(
											"NavLink.Documentation"
										)}
										iconName="description"
										to="/documentation"
									/>
								</React.Fragment>
							) : null}
						{userInfo.userRole < UserRole.SuperAdmin && <NavLink 
								text={getLanguageString("NavLink.Tickets")}
								iconName="featured_play_list"
								to="/tickets"
						/>}
						{userInfo.userRole === UserRole.Operator && (
							<React.Fragment>
								<NavLink
									text={getLanguageString("NavLink.Products")}
									iconName="library_books"
									to="/"
								/>
								{/* <NavLink text={getLanguageString("Settings.NavLink")} iconName="settings" to="/settings" /> */}
							</React.Fragment>
						)}
						{userInfo.userRole !== UserRole.SuperAdmin && (
							<NavLink
								text={getLanguageString("NavLink.Help")}
								iconName="help"
								to="/help"
						/>)}
						<div onClick={deleteCookie}>
						<a className="layout-navbar-navlink">
							<Icon style={{ color: "lightGrey" }} name="launch" />
							<span style={{color: "lightGrey"}}>{getLanguageString("NavLink.SignOut")}</span>
						</a>
						</div>
					</div>
				)}
			</Lang>
		</div>);
	}

	public filterQueue(): IQueueUser[]
	{
		var queueFiltered = getFilteredQueue(this.props.queue, this.props.userInfo, this.props.users);
		//this.props.listUpdate(queueFiltered);
		return queueFiltered;//getFilteredQueue(this.props.queue, this.props.loggedUser, this.props.users);
	}

	private removeLogo = () => {
		const { company } = this.state;

		if (!company) {
			return;
		}
		company.logoUrl = "";

		this.props.updateCompany(company, () => {});
	};

	private getCompanyLogoUrl = (logoUrl: string) => {
		const { company } = this.state;

		if (!company) {
			return;
		}
		company.logoUrl = logoUrl;
		
		this.props.updateCompany(company, () => {});
	};

	private openDropzone = () => {
		this.setState({ uploadLogoModal: true });
	};

	private closeDropzone = () => {
		 this.setState({ uploadLogoModal: false });
	};
}

export function getFilteredQueue(queue: IQueueUser[], loggedUser: IUser, users: IUser[] = []): IQueueUser[]
{
	const isUserVisibleFilter = (queueUser: IQueueUser): boolean =>
	{
		const user = users.find(x => x.id === queueUser.userId);
		const isSameTeam = (loggedUser.teamId === queueUser.teamId) || (user !== undefined && user.isTemporary);
		const isVisible = user !== undefined && (!user.isTemporary || (user.isTemporary && user.createdBy == loggedUser.id));
		//if (user) console.log(user.isTemporary + " - " + user.createdBy);

		return isVisible && isSameTeam;
	};
	console.log(`[foo] queue: ${queue.length}`);
	const filteredQueue = queue.filter((queueUser, index) => queue.indexOf(queueUser) === index && isUserVisibleFilter(queueUser)); // duplicates check
	return filteredQueue;
}

//TODO: Make global!!
const deleteCookie = () => {
	document.cookie = "connectionId=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; HasCheckedLicense=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "isReloaded=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "TemporaryToken=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	authProvider.logout();
};

const mapStateToProps = (state: IRootState) => ({
	queue: state.callQueue.queue,
	users: toArray(state.users.data),
	isNavOpen: state.layout.isNavOpen,
	userInfo: state.userInfo.data,
	company: state.company.data.company,
	teams: toArray(state.users.teamsData),
	loading: state.companies.loading,
	error: state.companies.error,
	notFound: state.companies.notFound,
	saving: state.companies.saving,
	companies: toArray(state.companies.data) as ICompany[],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMyCompany: actions.getMyCompany,
			updateCompany: actionCompanies.updateCompany,
			getCompanies: actionCompanies.getCompanies,
			getTeams: userActions.getTeams
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const NavMenu = connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
	NavMenuBase
);
