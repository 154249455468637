import { SendableHubEvents } from "../../reducers/call-queue/call-queue.events";

export class Messaging {
	public static Massage = {
		Snapshot: "onSnapshot",
		Arrow: "onArrow",
		Crosshair: "onStamp",
		StartDraw: "onStartDraw",
		ContinueDraw: "onContinueDraw",
		EndDraw: "onEndDraw",
		File: "onFile",
		Undo: "onUndo", //TODO
		Clear: "onClear", //TODO
		MobileSnapshot: "onMobileSnapshot",
		Text: "onText"
	};

	public static TypesId = {
		Snapshot: 0,
		Arrow: 1,
		Crosshair: 2,
		StartDraw: 3,
		ContinueDraw: 4,
		EndDraw: 5,
		File: 6,
		Undo: 7,
		Clear: 8,
		MobileSnapshot: 9,
		Text: 10
	};

	public static createFileMessage(id: string) {
		return `${this.TypesId.File}|${id}`;
	}

	public static createUndoMessage() {
		return `${this.TypesId.Undo}`;
	}

	public static createClearMessage(id: string) {
		return `${this.TypesId.Clear}|${id}`;
	}

	public static createMobileSnapshotMessage(id: string) {
		return `${this.TypesId.MobileSnapshot}|${id}`;
	}

	public static createSnapshotMessage() {
		return `${this.TypesId.Snapshot}`;
	}

	public static createArrowMessage(x: number, y: number, color: string, size: string) {
		return `${this.TypesId.Arrow}|${x}|${y}|#${color}|${size}`;
	}

	public static createCrosshairMessage(x: number, y: number, color: string, size: string) {
		return `${this.TypesId.Crosshair}|${x}|${y}|#${color}|${size}`;
	}

	public static createStartDrawMessage(x: number, y: number, color: string, size: string) {
		return `${this.TypesId.StartDraw}|${x}|${y}|#${color}|${size}`;
	}

	public static createContinueDrawMessage(x: number, y: number) {
		return `${this.TypesId.ContinueDraw}|${x}|${y}`;
	}

	public static createEndDrawMessage(x: number, y: number) {
		return `${this.TypesId.EndDraw}|${x}|${y}`;
	}

	public static createTextDrawMessage(x: number, y: number, color: string, size: string, text: string) {
		return `${this.TypesId.Text}|${x}|${y}|#${color}|${size}|${text}`;
	}
}
