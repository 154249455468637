import { logger } from "../../utilities/logger";

export const convertBlobToFile = (blob: Blob, name: string) => {
	const file = new File([blob], `holomaintenance-${Date.now()}-${name}.mp4`, { type: "video/mp4" });
	return file;
};

export const convertImageToFile = (data: Blob, name: string) => {
	const file = new File([data], `annotation-${Date.now()}-${name}.png`, { type: "image/png" });
	return file;
};

export const parsingJSON = (str: string) => {
	try {
		return JSON.parse(str);
	} catch (error) {
		logger.error("parsing of JSON failed.");
		return;
	}
};

export const log = (...messages: any[]) => {
	logger.debug("[CallWindow]", ...messages);
};

/**
 * @see https://github.com/domenic/promises-unwrapping/blob/master/docs/states-and-fates.md
 */
export class Deferred<T> {
	public promise: Promise<T>;

	private fate: "resolved" | "unresolved";
	private state: "pending" | "fulfilled" | "rejected";

	private _resolve: Function | undefined;
	private _reject: Function | undefined;

	constructor() {
		this.state = "pending";
		this.fate = "unresolved";
		this.promise = new Promise((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});
		this.promise.then(
			() => this.state = "fulfilled",
			() => this.state = "rejected"
		);
	}

	resolve(value?: any) {
		if (this.fate === "resolved") {
			throw "Deferred cannot be resolved twice";
		}
		this.fate = "resolved";
		if (this._resolve) this._resolve(value);
	}

	reject(reason?: any) {
		if (this.fate === "resolved") {
			throw "Deferred cannot be resolved twice";
		}
		this.fate = "resolved";
		if (this._reject) this._reject(reason);
	}

	isResolved() {
		return this.fate === "resolved";
	}

	isPending() {
		return this.state === "pending";
	}

	isFulfilled() {
		return this.state === "fulfilled";
	}

	isRejected() {
		return this.state === "rejected";
	}
}
