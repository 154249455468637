import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers/root-reducer";
import { IProduct, IProgress } from "../../types";
import { fileAccepted, isFileWithinSizeLimit, fileMatchSize, fileValidator, getDataTransferItemsOnInput, voidFn } from "../../utilities";
import { Icon, IconButton, ImageDropzone, Spinner } from "../globalComponents";
import "./ItemImageDisplay.scss";
import { actions as filesActions } from "../../reducers/files";
import { PRODUCT_PLACEHOLDER_URL } from "../../constants";
import { UserRole } from "../../types/IRoles";

export interface IItemProps extends ConnectProps {
	item: IProduct;
	onFileUploaded?: (file: File) => void;
	isCompanyNotSubordinate: boolean;
	handleDeleteImage: (productId: string) => void;
}

export interface IItemImageDisplayState {
	progress: number;
	uploadingFile: File | null;
	imageFetchedStamp: number;
}

class ItemImageDisplayBase extends Component<IItemProps, IItemImageDisplayState> {
	public input = React.createRef<HTMLInputElement>();

	public state: IItemImageDisplayState = {
		progress: 0,
		uploadingFile: null,
		imageFetchedStamp: Date.now(),
	};
	private readonly validFileTypes = ["image/png", "image/jpeg"];


	public render() {
		const { item, onFileUploaded } = this.props;
		return (
			<div className="item-image-display-container">
				<Card>
					<CardHeader>
						<CardTitle>
							<T v="ProductsProperties.ProductImage" />
						</CardTitle>
						{(this.props.isCompanyNotSubordinate && this.props.userInfo.userRole === UserRole.CompanyAdmin) && <div className="item-image-display-container-edit-icon" >
							<div className="button-container">
								<IconButton iconName={"edit"} onClick={this.handleInputClick} />
								<IconButton iconName={"delete"} onClick={() => {
									this.props.handleDeleteImage(item.id);
									if (this.input.current) {
										this.input.current.value = "";
									}
								}} />
							</div>
						</div>}
					</CardHeader>
					<CardBody>
						<ImageDropzone
							isDisabled={(this.props.userInfo.userRole !== UserRole.CompanyAdmin || !this.props.isCompanyNotSubordinate)}
							uploader={this.uploader}
							onFileUploaded={onFileUploaded || voidFn}
							imageUrl={this.setThumbnailUrl()}
							style={{ cursor: "pointer" }}
							onClick={this.handleInputClick}
						/>
						{(this.props.userInfo.userRole === UserRole.CompanyAdmin && this.props.isCompanyNotSubordinate) && <input ref={this.input} type="file" style={{ display: "none" }} onChange={this.handleOnChange}/>}
					</CardBody>
				</Card>
			</div>
		);
	}

	private setThumbnailUrl = () => {
		return (this.props.item.thumbnailUrl !== "") ? `${process.env.REACT_APP_LOCALHOST}${this.props.item.thumbnailUrl}` : "";
	}

	private uploader = (file: File, progress: (prog: IProgress) => void) =>
		serverManager.products.uploadProductImage(this.props.item.id, file, progress);

	private handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log("HANDLEONCHANGE")
		// e.preventDefault();

		var droppedFiles: File[];
		try{
			droppedFiles = getDataTransferItemsOnInput(e);

			const firstFile = [droppedFiles[0]];
			const { acceptedFiles, rejectedFiles } = fileValidator(firstFile as File[], this.fileValidator);
	
			if (rejectedFiles.length > 0) {
				return;
			}
	
			if (acceptedFiles.length > 0) {
				return this.handleUpload(acceptedFiles[0]);
			}
		}
		catch(err){
			console.log("[debug] dt error " + err);
		}
	
	};

	private fileValidator = (file: File) => {
		const isValidFileType = fileAccepted(file, this.validFileTypes);
		const isBelowMaxFileSize = file.size < this.props.maxWeight;
		const isAccepted = isValidFileType && isBelowMaxFileSize;
		return isAccepted;
	};

	private handleUpload = async (newFile: File) => {
		console.log("HANDLEUPLOAD");
		const progress = 0;
		const uploadingFile = newFile;
		await this.setState({ uploadingFile, progress });
		await this.uploader(newFile, this.progressCallback);
		if (this.props.onFileUploaded) {
			this.props.onFileUploaded(newFile);
		}
		this.setThumbnailUrl();
		await this.setState({ imageFetchedStamp: Date.now() });
	};

	private progressCallback = (progress: IProgress) => {
		this.setState({ progress: progress.uploaded });
	};

	private handleInputClick = () => {
		if (this.input.current) {
			this.input.current.click();
		}
	};
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
	company: state.company.data.company,
	maxWeight: state.files.maxFileUploadWeight,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMaximumFileWeight: filesActions.getFilesMaximumSizeLimitUpload,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;


export const ItemImageDisplay = connect(mapStateToProps, mapDispatchToProps)(ItemImageDisplayBase);