import { Publisher, Subscriber } from "openvidu-browser";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { ICallState, IConnectedSession, IOVError, ISessionConnectionData, REMOTE_EXPERT } from "../../WebRTC/RTCHelpers";
import { Action, actions } from "./ov.actions";

export interface IState {
	error: boolean;
	loading: boolean;
	callState: ICallState;
	ovError: IOVError | undefined;
}

const initialCallState: ICallState = {
	mainStreamManager: undefined,
	isConnected: false,
	publisher: undefined,
	remoteExpert: undefined,
	participants: [] as Subscriber[],
	publisherType: REMOTE_EXPERT,
};

export const initialState: IState = {
	error: false,
	loading: false,
	ovError: undefined,
	callState: initialCallState,
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.addHololensStream):
			return {
				...state,
				callState: {
					...state.callState,
					mainStreamManager: action.payload,
					participants: [...state.callState.participants!, action.payload],
				},
			};
		case getType(actions.addRemoteExpertStream):
			return {
				...state,
				callState: {
					...state.callState,
					remoteExpert: action.payload,
					participants: [...state.callState.participants!, action.payload],
				},
			};
		case getType(actions.addParticipantStream):
			return {
				...state,
				callState: {
					...state.callState,
					participants: [...state.callState.participants!, action.payload],
				},
			};
		case getType(actions.removeHololensStreamSuccess):
			return {
				...state,
				error: false,
				callState: {
					...state.callState,
					mainStreamManager: undefined,
					participants: action.payload,
				},
			};
		case getType(actions.removeRemoteExpertStreamSuccess):
			return {
				...state,
				error: false,
				callState: {
					...state.callState,
					remoteExpert: undefined,
					participants: action.payload,
				},
			};
		case getType(actions.removeParticipantStreamSuccess):
			return {
				...state,
				error: false,
				callState: {
					...state.callState,
					participants: action.payload,
				},
			};
		case getType(actions.connectUser):
			return {
				...state,
				loading: true,
			};
		case getType(actions.connectUserError):
		case getType(actions.removeHololensStreamError):
		case getType(actions.removeParticipantStreamError):
		case getType(actions.removeRemoteExpertStreamError):
			return {
				...state,
				ovError: action.payload,
				loading: false,
				error: true,
			};
		case getType(actions.connectUserSuccess):
			return {
				...state,
				callState: {
					...state.callState,
					mainStreamManager: action.payload.mainStreamManager,
					participants: [...state.callState.participants!],
					isConnected: action.payload.isConnected,
					publisherType: action.payload.publisherType,
					publisher: action.payload.publisher,
				},
				loading: false,
				ovError: undefined,
				error: false,
			};

		default:
			return state;
	}
};
