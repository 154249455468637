import React from "react";
import ReactDOM from "react-dom";

export interface IPortalState {
	childElMounted: boolean;
}

export class Portal extends React.Component<{}, IPortalState> {
	public state: IPortalState = {
		childElMounted: false,
	};

	private uniqueId: string = `portal-${Date.now()}`;

	public componentDidMount() {
		const el = document.createElement("div");
		el.setAttribute("id", this.uniqueId);
		document.body.appendChild(el);
		this.setState({ childElMounted: true });
	}

	public componentWillUnmount() {
		const el = document.getElementById(this.uniqueId);
		if (!el) {
			throw new Error("Tried unmounting an element which was not present [Portal]");
		}

		document.body.removeChild(el);
	}

	public render() {
		const elem = document.getElementById(this.uniqueId);

		if (!elem) {
			return null;
		}

		return ReactDOM.createPortal(this.props.children, elem);
	}
}
