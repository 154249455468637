import "@google/model-viewer";
import { BinaryReader, Encoding } from "csharp-binary-stream";
import React from "react";
import ReactDOM from "react-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { logger } from "../../utilities";
import { Icon } from "../globalComponents";
import { IFile, IClips, IProgress } from "../../types";
import { AnimationAction } from "three/src/animation/AnimationAction";
import { PerspectiveCamera } from "three/src/cameras/PerspectiveCamera";
import { Renderer } from "@google/model-viewer/lib/three-components/Renderer";
import { delay } from "../../utilities";
import { GetLangString } from "../../managers/I18n";
import { ARButton } from 'three/examples/jsm/webxr/ARButton.js';
//@ts-ignore
import { ARUtils, ARPerspectiveCamera, ARView } from "three.ar.js";
import { WebXRManager } from "three/src/renderers/webxr/WebXRManager";
import { url, Session } from "inspector";
import { none } from "ramda";
import { count } from "console";
import "./MobileFileHelper.scss";
import { serverManager } from "../../managers/ServerManager";
import { pdfjs } from "react-pdf";

var lastOrientationEvent: any = null;

export const handleFbxModel = (uri: string, clips: IClips, allowArFunctions: string, activeCall?: boolean, positioningModel?: boolean) => {
	//defining div on which the model will load
	const divCurrentModel: HTMLDivElement = document.createElement("div");
	divCurrentModel.id = "current-model";
	if (activeCall === true) {
		divCurrentModel.style.cssText = "position: fixed: margin: 0;width: 100%; height: 100%;z-index:9999";
	} else {
		divCurrentModel.style.cssText = "position: fixed: margin: 0;width: 100vw; height: 100%;z-index:9999";
	}


	//defining the hit sources
	var hitTestSource: any = null;
	var hitTestSourceRequested = false;
	var currentAnimation = 0;
	var isInScene: boolean = false;
	var isARMode: boolean = false;

	//defining the scene, camera and light
	let scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100000);
	//let camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 20 );
	let light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
	light.position.set(0.5, 1, 0.25);
	scene.add(light);
	//defining the renderer
	const canvasStreamVideo: HTMLCanvasElement = document.createElement("canvas");
	canvasStreamVideo.id = "canvas-stream-video";
	let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvasStreamVideo, preserveDrawingBuffer: true });

	// divCurrentModel.addEventListener("change", () => {
	// 	renderer.setPixelRatio(divCurrentModel.clientWidth / divCurrentModel.clientHeight);
	// 	renderer.setSize(divCurrentModel.clientWidth, divCurrentModel.clientHeight);
	// 		if (camera) {
	// 			console.log("Camera found");

	// 			//camera.up.set(obj.up.x, obj.up.y, obj.up.z);
	// 			camera.position.set(0, radius, radius);
	// 			(camera as PerspectiveCamera).aspect = divCurrentModel.clientWidth / divCurrentModel.clientHeight;
	// 			(camera as PerspectiveCamera).clearViewOffset();
	// 		}
	// });

	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(divCurrentModel.clientWidth, divCurrentModel.clientHeight);


	renderer.xr.enabled = true;
	renderer.shadowMap.enabled = true;

	const divElements = document.createElement('div');
	divElements.style.cssText = "position: absolute; margin: 16px; top: calc(100% - 115px); left: 50%;";
	const animVisualizer = document.createElement("button");
	const nextButton = document.createElement("button");
	const button = document.createElement("button");
	const playButton = document.createElement("button");
	const voiceButton = document.createElement("button");
	const animationNameFather = document.createElement("div");
	const animationNameTitle = document.createElement("div");
	const detachButton = document.createElement("button");
	detachButton.style.cssText = "top: calc(100% - 166px); left: calc(100% - 81px); width: 48px; height: 48px;";
	const detachButtonIcon = document.createElement("i");
	detachButtonIcon.className = "material-icons app_icon";
	detachButtonIcon.style.cssText = "font-size: 24px;";
	detachButtonIcon.textContent = "delete";
	detachButton.appendChild(detachButtonIcon);
	var arButton = ARButton.createButton(renderer, { requiredFeatures: ['hit-test'], optionalFeatures: ['dom-overlay'], domOverlay: { root: divElements } });

	arButton.addEventListener('click', async () => {
		isARMode = true;

		const toastTitleAR = GetLangString(`Toast.HintTitle`);
		const toastBodyAR = GetLangString(`Toast.HintBodyAR`);
		toast.innerHTML = `<h3>${toastTitleAR}</h3>${toastBodyAR}`;
		divElements.appendChild(toast);

		toast.addEventListener("click", () => {
			divElements.removeChild(toast);
		});


		if (divCurrentModel.contains(voiceButton)) {
			divCurrentModel.removeChild(voiceButton);
			divElements.appendChild(voiceButton);
		}
		if (divCurrentModel.contains(animationNameFather) && divCurrentModel.contains(animationNameTitle)) {
			divCurrentModel.removeChild(animationNameFather);
			divCurrentModel.removeChild(animationNameTitle);
			divElements.appendChild(animationNameTitle);
			divElements.appendChild(animationNameFather);
		}

		animVisualizer.style.cssText = "left: calc(100% - 230px); top: calc(100% - 100px); height: 48px; width: 48px;";
		nextButton.style.cssText = "left: calc(100% - 155px); top: calc(100% - 100px); height: 48px; width: 48px;";
		button.style.cssText = "left: calc(100% - 303px); top: calc(100% - 100px); height: 48px; width: 48px;";
		playButton.style.cssText = "left: calc(100% - 376px); top: calc(100% - 100px); height: 48px; width: 48px;";
		voiceButton.style.cssText = "top: calc(100% - 100px); left: calc(100% - 81px); bottom: 96px; height: 48px; width: 48px;";
		scene.background = null;
		scene.remove(exportedObj);
		await delay(10000);
		divElements.removeChild(toast);
	});

	var lightBackground = true;
	var voiceEnabled = true;
	var currentAnimation = 0;
	var action: AnimationAction;
	const clock = new THREE.Clock();
	const controls = new OrbitControls(camera, renderer.domElement);
	const ambientLight = new THREE.AmbientLight(0xffffff, 1);
	scene.add(ambientLight);
	let directionalLight = new THREE.DirectionalLight(0xffffff, 1);
	scene.add(directionalLight);
	// //directionalLight.position.set(5000, 10000, 7500);
	if (activeCall && positioningModel) {
		scene.background = null;
	} else {
		scene.background = new THREE.Color(0xDBDCE0);
	}


	//spinner declaration (creare un css per gestirlo come si deve)
	const spinner = document.createElement("div");
	spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: 50%; margin-top: 50vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>';
	var objScaleX: number;
	var objScaleY: number;
	var objScaleZ: number;
	//manager definition
	const manager = new THREE.LoadingManager();

	const startSpinnerAndToast = function () {
		if (activeCall === true) {
			var desiredHeight: number = 400;
			let desiredWidth: number = (divCurrentModel.clientHeight / divCurrentModel.clientWidth) / desiredHeight;
			divCurrentModel.style.cssText = `width: ${desiredWidth}%; height: ${desiredHeight}px;`
			renderer.setSize(desiredWidth, desiredHeight);
			renderer.setPixelRatio(400 / desiredHeight);
			//camera.up.set(obj.up.x, obj.up.y, obj.up.z);
			//camera.position.set(0, radius, radius);
			(camera as PerspectiveCamera).aspect = 400 / desiredHeight;
			(camera as PerspectiveCamera).clearViewOffset();
			spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: 50%; margin-top: 35vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>'
		}

		divCurrentModel.appendChild(spinner);
		divCurrentModel.appendChild(toast);
		console.log("Loading started");
	};

	const stopToast = function () {
		if (divCurrentModel.contains(toast)) {
			divCurrentModel.removeChild(toast);
		}
	}

	const allowStopToast = async function () {
		toast.onclick = stopToast;

		await delay(3000);

		stopToast();
	}

	const stopSpinner = function () {
		if (divCurrentModel.contains(spinner)) {
			divCurrentModel.removeChild(spinner);
		}
		console.log("Loading stopped");
	};

	manager.onStart = startSpinnerAndToast;

	manager.onProgress = stopSpinner;

	manager.onLoad = allowStopToast;

	//hint toast declaration (creare anche qui un css)
	const toast = document.createElement("div");
	toast.id = "toast";
	toast.style.cssText = "min-width: 250px; top: 25%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%); background-color: #00000088; color: #fff; text-align: center; border-radius: 22px; padding: 16px; position: absolute; z-index: 1; font-size: 17px;";
	const toastTitle = GetLangString('Toast.HintTitle');
	const toastBody = GetLangString('Toast.HintBody');
	toast.innerHTML = `<h3>${toastTitle}</h3>${toastBody}`;


	//FBX loader definition
	const loader = new FBXLoader(manager);
	var exportedObj: any;
	var synth = window.speechSynthesis;
	var radius = 0;

	detachButton.addEventListener("click", () => {
		scene.remove(exportedObj);
		isInScene = false;
		divElements.removeChild(detachButton);
	});

	//function for getting the radius of an object
	const getRadius = (obj: any): any => {
		var box = new THREE.BoxHelper(obj, 0xff0000);
		box.geometry.computeBoundingSphere();

		if (Number.isNaN(box.geometry.boundingSphere.radius) || box.geometry.boundingSphere.radius == 0) {
			for (var i = 0; i < obj.children.length; i++) {
				if (!Number.isNaN(box.geometry.boundingSphere.radius) && box.geometry.boundingSphere.radius != 0) {
					console.log("Radius for this OBJ is: " + radius);
					return box;
				}
				else {
					console.log("OBJ radius is NaN! Re-iterating on " + obj.children[i].name);
					box = getRadius(obj.children[i]);
				}
			}
		}

		return box;
	}

	let reticle = new THREE.Mesh(
		new THREE.RingBufferGeometry(0.15, 0.2, 32).rotateX(- Math.PI / 2),
		new THREE.MeshBasicMaterial()
	);
	/*
		loader.load(uri, (obj: any) => {
			var box = getRadius(obj);
			radius = box.geometry.boundingSphere.radius * 2;
			console.log("Radius for this OBJ is: " + radius);
			const mixer = new THREE.AnimationMixer(obj);
			exportedObj = obj;
			objScaleX = exportedObj.scale.x;
			objScaleY = exportedObj.scale.y;
			objScaleZ = exportedObj.scale.z;
			// if (obj.animations[0] !== undefined) {
			// 	const action = mixer.clipAction(obj.animations[0]);
			// 	action.play();
			// }
			console.log('IL MESSAGGIO SUL BOTTONE E: ' + arButton.innerText);
	
			if (arButton.innerText === "START AR" && allowArFunctions === "true") {
				console.log("SONO QUI IPAD");
				arButton.innerText = "AR";
				arButton.style.cssText = "font-size: 15px; top: calc(100% - -21px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 169px); width: 45px; height: 45px;";
				divElements.appendChild(arButton);
			}
	
			if (!renderer.xr.isPresented) {
				console.log("Animations: " + obj.animations.length);
				scene.add(obj);
				camera.up.set(obj.up.x, obj.up.y, obj.up.z);
				
				camera.position.set(0, radius, radius);
				
				controls.target = new THREE.Vector3(
					box.geometry.boundingSphere.center.x,
					box.geometry.boundingSphere.center.y,
					box.geometry.boundingSphere.center.z
				);
				controls.update();
				
				controls.screenSpacePanning = true;
				controls.update();
				
				const animate = () => {
					const delta = clock.getDelta();
					if (mixer) {
						mixer.update(delta);
					}
					controls.update();
					renderer.render(scene, camera);
					requestAnimationFrame(animate);
				};
	
				animate();
				directionalLight.position.set(camera.position.x, camera.position.y, camera.position.z);
					directionalLight.target = obj;
				
				if (obj.animations.length > 0) {
					const animCount = document.createElement("P");
					animCount.id = "anim-text";
					animCount.style.cssText = "margin-left: auto; margin-right: auto; margin-top: auto; bottom: 32px; left: 0px; height: 48px; width: 48px;";
					animCount.innerText = `${currentAnimation + 1} / ${obj.animations.length}`;
					
					animationNameTitle.className = "box-title";
					animationNameTitle.style.cssText = "font-size: 14px;width: 101px;height: 100px;position: absolute;margin: 16px;top: 24px;background: #eb0028;border-radius: 22px;padding-top: 70px;padding-left: 20px;align-self: flex-end;color: white;font-weight: bold;";
					animationNameTitle.appendChild(document.createTextNode("Step Info"));
					
					animationNameFather.className = "box";
					animationNameFather.id = "anim-vis";
					animationNameFather.style.cssText = "position: absolute; margin: 10px;top: 0px;display: flex;";
					
					const animationNameSon1 = document.createElement("div");
					animationNameSon1.className = "box-inner"
					animationNameSon1.style.cssText = "overflow-y: auto;flex: 1;padding: 20px; background: white;max-height: 100px;border-radius: 22px; font-size: 14px";
					
					const animationNameSon2 = document.createElement("div");
					animationNameSon2.style.cssText = "width: 78px; height: 100px;";
					
					const animationNameText: Text = document.createTextNode(clips.clipsData[0] ? clips.clipsData[0].text : "");
					
					animationNameSon1.appendChild(animationNameText);
					animationNameFather.appendChild(animationNameSon1);
					animationNameFather.appendChild(animationNameSon2);
					divCurrentModel.appendChild(animationNameTitle);
					divCurrentModel.appendChild(animationNameFather);
					
									
					const showText = (value: boolean) => {
						animationNameSon1.hidden = !value;
						animationNameSon2.hidden = !value;
						animationNameFather.hidden = !value;
						animationNameTitle.hidden = !value;
					}
									
					const playAnim = (anim: number) => {
						mixer.stopAllAction();
						action = mixer.clipAction(obj.animations[anim]);
						action.clampWhenFinished = true;
						action.loop = THREE.LoopOnce;
						action.play();
					
						if (clips.clipsData[anim] && clips.clipsData[anim].text != null && clips.clipsData[anim].text != "") {
							showText(true);
					
							animationNameText.textContent = clips.clipsData[anim].text;
					
							if (voiceEnabled) {
								synth.cancel();
								var utterThis = new SpeechSynthesisUtterance(clips.clipsData[anim].speechText);
								var voice = synth.getVoices().find(x => x.lang.includes(navigator.language));
								utterThis.voice = voice ? voice : synth.getVoices()[0];
								synth.speak(utterThis);
							}
						} else {
							showText(false);
						}
					}
				
					const pauseAnim = () => {
						if (action.timeScale == 0)
							action.timeScale = 1;
						else
							action.timeScale = 0;
					}
	
					playAnim(0);
	
					//const animVisualizer = document.createElement("button");
					animVisualizer.style.cssText = "left: -5px; height: 48px; width: 48px;";
					var string = "1";
					var txt = document.createTextNode(string + "/" + obj.animations.length);
					
					playButton.id = "play_pause_button";
					var playPauseIcon = document.createElement("i");
					playPauseIcon.style.cssText = "font-size: 24px";
					playPauseIcon.className = "material-icons app_icon";
					playPauseIcon.textContent = "pause";
					playButton.appendChild(playPauseIcon);
					playButton.style.cssText = "left: -115px; height: 48px; width: 48px;"
					// if (obj.animations.length > 1) {
							
					// } else {
					// 	playButton.style.cssText = "margin-left: auto; margin-right: auto; margin-top: auto; bottom: 32px; left: -21%; height: 48px; width: 48px;"
					// }
					playButton.onclick = () => {
						if (playPauseIcon.textContent == "play_arrow") {
							pauseAnim();
							playPauseIcon.textContent = "pause";
						} else {
							pauseAnim();
							playPauseIcon.textContent = "play_arrow";
						}
					}
					divElements.appendChild(playButton);
					//divCurrentModel.appendChild(playButton);
				
					if (obj.animations.length > 1) {
						console.log("Adding prev-anim button");
	
						button.style.cssText = "left: -60px; height: 48px; width: 48px;";
						button.id = "prev-button";
						button.onclick = () => {
							if (isARMode) {
								if (isInScene) {
									if (currentAnimation > 0) {
										currentAnimation--;
										animVisualizer.removeChild(txt);
			
										string = "" + (currentAnimation + 1);
										txt = document.createTextNode(string + "/" + obj.animations.length);
										animVisualizer.appendChild(txt);
										divElements.appendChild(animVisualizer);
										playAnim(currentAnimation);
										string = "";
									}		
								}
							} else {
								if (currentAnimation > 0) {
									currentAnimation--;
									animVisualizer.removeChild(txt);
		
									string = "" + (currentAnimation + 1);
									txt = document.createTextNode(string + "/" + obj.animations.length);
									animVisualizer.appendChild(txt);
									divElements.appendChild(animVisualizer);
									playAnim(currentAnimation);
									string = "";
								}
							}
							
						};
						const icon = document.createElement("i");
						icon.style.cssText = "font-size: 24px; transform: scaleX(-1);";
						icon.textContent = "skip_next";
						icon.className = "material-icons app_icon";
						button.appendChild(icon);
						divElements.appendChild(button);
					}
	
					{ //currentAnimation < obj.animations.length - 1 || obj.animations.length === 1
						console.log("Adding next-anim button");
	
						nextButton.style.cssText = "left: 50px; height: 48px; width: 48px;";
						nextButton.id = "next-button";
						nextButton.onclick = () => {
							if (isARMode) {
								if (isInScene) {
									if (obj.animations.length === 1) {
										playAnim(0);
									}
									else if (currentAnimation < obj.animations.length - 1) {
										currentAnimation++;
										animVisualizer.removeChild(txt);
			
										string = "" + (currentAnimation + 1);
										txt = document.createTextNode(string + "/" + obj.animations.length);
										animVisualizer.appendChild(txt);
										divElements.appendChild(animVisualizer);
										playAnim(currentAnimation);
										animCount.innerText = `${currentAnimation + 1} / ${obj.animations.length}`;
									}
								}
							} else {
								if (obj.animations.length === 1) {
									playAnim(0);
								}
								else if (currentAnimation < obj.animations.length - 1) {
									currentAnimation++;
									animVisualizer.removeChild(txt);
		
									string = "" + (currentAnimation + 1);
									txt = document.createTextNode(string + "/" + obj.animations.length);
									animVisualizer.appendChild(txt);
									divElements.appendChild(animVisualizer);
									playAnim(currentAnimation);
									animCount.innerText = `${currentAnimation + 1} / ${obj.animations.length}`;
								}
							}
							
						};
						const icon = document.createElement("i");
						icon.style.cssText = "font-size: 24px";
						icon.textContent = "skip_next";
						icon.className = "material-icons app_icon";
						nextButton.appendChild(icon);
						divElements.appendChild(nextButton);
					}
					
					animationNameSon1.appendChild(animationNameText);
					animationNameFather.appendChild(animationNameSon1);
					animationNameFather.appendChild(animationNameSon2);
					divCurrentModel.appendChild(animationNameTitle);
					divCurrentModel.appendChild(animationNameFather);
					animVisualizer.appendChild(txt);
					divElements.appendChild(animVisualizer);
					divCurrentModel.appendChild(divElements);
				} else {
					divCurrentModel.appendChild(divElements);
				}
				
				if (clips.clipsData.length > 0) {
					console.log("Adding mute button");	
					//button.style.cssText = "position: absolute;margin-left: auto; margin-right: auto; margin-top: auto; bottom: 10px; left: 64px;";
					//voiceButton.style.cssText = "top: calc(100% - 37px); left: calc(100% - -123px); bottom: 96px; height: 48px; width: 48px;";
					voiceButton.style.cssText = "margin-left: auto; margin-right: 0px; margin-top: auto; bottom: 96px; height: 48px; width: 48px;";
					voiceButton.id = "light-button";
					const voiceIcon = document.createElement("i");
					voiceIcon.style.cssText = "font-size: 24px";
					voiceButton.onclick = () => setVoiceEnabled(!voiceEnabled);
					voiceIcon.className = "material-icons app_icon";
					voiceButton.appendChild(voiceIcon);
					//divElements.appendChild(voiceButton);
					divCurrentModel.appendChild(voiceButton);	
					const setVoiceEnabled = (value: boolean) => {
						if (!value) {
							synth.cancel();
						}	
						voiceEnabled = value;
						voiceIcon.textContent = voiceEnabled ? "volume_up" : "volume_off";
					};	
					setVoiceEnabled(voiceEnabled);
				}
				{
					console.log("Adding light button");
	
					const lightButton = document.createElement("button");
					//button.style.cssText = "position: absolute;margin-left: auto; margin-right: auto; margin-top: auto; bottom: 10px; left: 64px;";
					lightButton.style.cssText = "margin-left: auto; margin-right: 0px; margin-top: auto; bottom: 32px; height: 48px; width: 48px;";
					lightButton.id = "light-button";
					const lightIcon = document.createElement("i");
					lightIcon.style.cssText = "font-size: 24px";
					lightButton.onclick = () => setBackgroundLight(!lightBackground);
					lightIcon.className = "material-icons app_icon";
					lightButton.appendChild(lightIcon);
					divCurrentModel.appendChild(lightButton);
	
					const setBackgroundLight = (value: boolean) => {
						lightBackground = value;
						if (activeCall && positioningModel) {
							scene.background = null;
						} else {
							scene.background = lightBackground ? new THREE.Color(0xDBDCE0) : new THREE.Color(0x232323);
						}
						
						lightIcon.textContent = lightBackground ? "brightness_6" : "brightness_5";
					};
	
					setBackgroundLight(lightBackground);
				}
					
				if (lastOrientationEvent != null) {
					window.removeEventListener('orientationchange', lastOrientationEvent);
					window.removeEventListener('resize', lastOrientationEvent);
				}
	
				handleOrientationChange(exportedObj, renderer, camera, radius);
				
				lastOrientationEvent = () => handleOrientationChange(exportedObj, renderer, camera, radius);//, startSpinner, stopSpinner);
				window.addEventListener('orientationchange', lastOrientationEvent);
				window.addEventListener('resize', lastOrientationEvent);
	
				divCurrentModel.addEventListener('orientationchange', lastOrientationEvent);
				divCurrentModel.addEventListener('resize', lastOrientationEvent);
			} 
		}, (p: ProgressEvent) =>
			{
				logger.debug("FBXLoader " + (p.loaded / p.total) * 100 + "%");
			},
			(e) =>
			{
				logger.error(e);
		});
	*/
	// var geometry = new THREE.CylinderBufferGeometry( 0.1, 0.1, 0.2, 32 ).translate(0, 0.1, 0 );

	let controller = renderer.xr.getController(0);
	controller.addEventListener('select', () => {
		if (reticle.visible) {
			// var material = new THREE.MeshPhongMaterial( { color: 0xffffff * Math.random() } );
			// var mesh = new THREE.Mesh( geometry, material );
			exportedObj.position.setFromMatrixPosition(reticle.matrix);
			exportedObj.scale.x = objScaleX / 100;
			exportedObj.scale.y = objScaleY / 100;
			exportedObj.scale.z = objScaleZ / 100;
			// exportedObj.lookAt(camera.position);
			//if ((reticle.rotation.x > 235 && reticle.rotation.x < 310) || (reticle.rotation.x > -125 && reticle.rotation.x < -45)) {
			var dir = new THREE.Vector3();
			var worldQuat = new THREE.Quaternion();
			reticle.getWorldQuaternion(worldQuat);
			dir.copy(reticle.up).applyQuaternion(worldQuat);
			var dirMul = 1500;
			var pos = new THREE.Vector3(reticle.position.x + (dir.x * dirMul), reticle.position.y + (dir.y * dirMul), reticle.position.z + (dir.z * dirMul));
			// 				var geometry = new THREE.SphereGeometry( 5, 32, 32 );
			// var material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
			// var sphere = new THREE.Mesh( geometry, material );

			// sphere.position.set(pos.x, pos.y, pos.z);
			//scene.add( sphere );
			exportedObj.lookAt(pos.x, pos.y, pos.z);
			//exportedObj.rotation.set(new THREE.Vector3(0, exportedObj.rotation.y, 0));
			// } else {
			// 	exportedObj.rotation.set(reticle.rotation);
			// }
			// exportedObj.rotation.set(0, exportedObj.rotation.y, 0);
			// mesh.position.setFromMatrixPosition( reticle.matrix );
			// mesh.scale.y = Math.random() * 2 + 1;
			scene.add(exportedObj);
			isInScene = true;
			reticle.visible = false;
			divElements.appendChild(detachButton);
		}
	});

	scene.add(controller);

	reticle.matrixAutoUpdate = false;
	reticle.visible = false;
	scene.add(reticle);


	divCurrentModel.appendChild(renderer.domElement);

	renderer.setAnimationLoop((timestamp: any, frame: any) => {
		if (frame) {
			var referenceSpace = renderer.xr.getReferenceSpace();
			var session = renderer.xr.getSession();

			if (!hitTestSourceRequested) {
				session.requestReferenceSpace('viewer').then((referenceSpace: any) => {
					session.requestHitTestSource({ space: referenceSpace }).then((source: any) => {
						hitTestSource = source;
					});
				});

				arButton.style.cssText = "font-size: 15px; top: calc(100% - 831px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 71px); width: 45px; height: 45px;";
				arButton.innerText = "VR";

				session.addEventListener('end', function () {
					if (divElements.contains(toast)) {
						divElements.removeChild(toast);
					}
					//divElements.style.cssText = "position: absolute; margin: 16px; top: calc(100% - 115px); left: 50%;";
					isARMode = false;
					arButton.style.cssText = "font-size: 15px; top: calc(100% - -21px); background-color: white; position: absolute; bottom: 20px; border: 1px solid rgb(255, 255, 255); border-radius: 43px; color: rgb(0, 0, 0); font-weight: bold; font: 13px sans-serif bold; text-align: center; outline: none; z-index: 999; cursor: auto; left: calc(100% - 169px); width: 45px; height: 45px;";
					arButton.innerText = "AR";
					//arButton.style.cssText = "top: calc(100% - 118px); position: absolute; bottom: 20px; padding: 12px 6px; border: 1px solid rgb(255, 255, 255); border-radius: 4px; background: rgba(0, 0, 0, 0.1); color: rgb(255, 255, 255); font: 13px sans-serif; text-align: center; opacity: 1; outline: none; z-index: 999; cursor: pointer; left: calc(50% - 50px); width: 100px;";
					reticle.visible = false;
					//divElements.removeChild(arButton);
					if (divElements.contains(detachButton)) {
						divElements.removeChild(detachButton);
					}
					if (divElements.contains(voiceButton)) {
						divElements.removeChild(voiceButton);
						divCurrentModel.appendChild(voiceButton);
					}
					if (divElements.contains(animationNameFather) && divElements.contains(animationNameTitle)) {
						divElements.removeChild(animationNameFather);
						divElements.removeChild(animationNameTitle);
						divCurrentModel.appendChild(animationNameTitle);
						divCurrentModel.appendChild(animationNameFather);
					}
					//divCurrentModel.appendChild(arButton);
					animVisualizer.style.cssText = "left: -5px; height: 48px; width: 48px;";
					nextButton.style.cssText = "left: 50px; height: 48px; width: 48px;";
					button.style.cssText = "left: -60px; height: 48px; width: 48px;";
					playButton.style.cssText = "left: -115px; height: 48px; width: 48px;";
					voiceButton.style.cssText = "margin-left: auto; margin-right: 0px; margin-top: auto; bottom: 96px; height: 48px; width: 48px;";
					if (activeCall && positioningModel) {
						scene.background = null;
					} else {
						scene.background = new THREE.Color(0xDBDCE0);
					}
					var box = getRadius(exportedObj);
					radius = box.geometry.boundingSphere.radius * 2;
					console.log("Radius for this OBJ is: " + radius);
					const mixer = new THREE.AnimationMixer(exportedObj);
					scene.add(exportedObj);
					camera.up.set(exportedObj.up.x, exportedObj.up.y, exportedObj.up.z);
					camera.position.set(0, radius, radius);

					controls.target = new THREE.Vector3(
						box.geometry.boundingSphere.center.x,
						box.geometry.boundingSphere.center.y,
						box.geometry.boundingSphere.center.z
					);
					controls.update();

					controls.screenSpacePanning = true;
					controls.update();
					hitTestSourceRequested = false;
					hitTestSource = null;
				});
				hitTestSourceRequested = true;
			}

			if (hitTestSource && !isInScene) {
				var hitTestResults = frame.getHitTestResults(hitTestSource);

				if (hitTestResults.length) {
					var hit = hitTestResults[0];
					reticle.visible = true;
					reticle.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);
				} else {
					reticle.visible = false;
				}
			}
		}
		renderer.render(scene, camera);
	});

	return divCurrentModel;
	// const renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
	// renderer.setPixelRatio(window.devicePixelRatio);
	// renderer.setSize(window.innerWidth, window.innerHeight);
	// renderer.shadowMap.enabled = true;
	// var lightBackground = true;
	// var voiceEnabled = true;
	// var currentAnimation = 0;
	// var action: AnimationAction;
	// const clock = new THREE.Clock();
	// const camera = new THREE.PerspectiveCamera(
	// 	75,
	// 	window.innerWidth / window.innerHeight,
	// 	0.1,
	// 	100000
	// );

	// const controls = new OrbitControls(camera, renderer.domElement);

	// const scene = new THREE.Scene();
	// scene.add(new THREE.AmbientLight(0xffffff));
	// const ambientLight = new THREE.AmbientLight(0xffffff, 1);
	// scene.add(ambientLight);
	// const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
	// scene.add(directionalLight);
	// //directionalLight.position.set(5000, 10000, 7500);
	// scene.background = new THREE.Color(0xDBDCE0);

	// const spinner = document.createElement("div");
	// spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: 50%; margin-top: 50vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>';

	// const toast = document.createElement("div");
	// toast.id = "toast";
	// toast.style.cssText = "min-width: 250px; top: 25%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%); background-color: #00000088; color: #fff; text-align: center; border-radius: 22px; padding: 16px; position: absolute; z-index: 1; font-size: 17px;";
	// const toastTitle = GetLangString('Toast.HintTitle');
	// const toastBody = GetLangString('Toast.HintBody');
	// toast.innerHTML = `<h3>${toastTitle}</h3>${toastBody}`;

	// const manager = new THREE.LoadingManager();

	// const startSpinnerAndToast = function ()
	// {
	// 	divCurrentModel.appendChild(spinner);
	// 	divCurrentModel.appendChild(toast);
	// 	console.log("Loading started");
	// };

	// const stopToast = function ()
	// {
	// 	if (divCurrentModel.contains(toast)) {
	// 		divCurrentModel.removeChild(toast);
	// 	}
	// }

	// const allowStopToast = async function ()
	// {
	// 	toast.onclick = stopToast;

	// 	await delay(3000);

	// 	stopToast();
	// }

	// const stopSpinner = function ()
	// {
	// 	if (divCurrentModel.contains(spinner)) {
	// 		divCurrentModel.removeChild(spinner);
	// 	}
	// 	console.log("Loading stopped");
	// };

	// manager.onStart = startSpinnerAndToast;

	// manager.onProgress = stopSpinner;

	// manager.onLoad = allowStopToast;

	// var radius = 0;
	// const loader = new FBXLoader(manager);
	// var exportedObj: any;
	// var synth = window.speechSynthesis;

	// const getRadius = (obj: any): any =>
	// {
	// 	var box = new THREE.BoxHelper(obj, 0xff0000);
	// 	box.geometry.computeBoundingSphere();

	// 	if (Number.isNaN(box.geometry.boundingSphere.radius) || box.geometry.boundingSphere.radius == 0) {
	// 		for (var i = 0; i < obj.children.length; i++) {
	// 			if (!Number.isNaN(box.geometry.boundingSphere.radius) && box.geometry.boundingSphere.radius != 0) {
	// 				console.log("Radius for this OBJ is: " + radius);
	// 				return box;
	// 			}
	// 			else {
	// 				console.log("OBJ radius is NaN! Re-iterating on " + obj.children[i].name);
	// 				box = getRadius(obj.children[i]);
	// 			}
	// 		}
	// 	}

	// 	return box;
	// }

	// loader.load(
	// 	uri,
	// 	(obj: any) =>
	// 	{
	// 		var box = getRadius(obj);
	// 		radius = box.geometry.boundingSphere.radius * 2;
	// 		console.log("Radius for this OBJ is: " + radius);
	// 		const mixer = new THREE.AnimationMixer(obj);
	// 		exportedObj = obj;
	// 		// if (obj.animations[0] !== undefined) {
	// 		// 	const action = mixer.clipAction(obj.animations[0]);
	// 		// 	action.play();
	// 		// }

	// 		// renderer.xr.enabled = true;
	// 		// var arButton = ARButton.createButton(renderer);
	// 		// divCurrentModel.appendChild(arButton);
	// 		// arButton.addEventListener("click", onSessionStart);

	// 		// var geometry = new THREE.CylinderBufferGeometry( 0, 0.05, 0.2, 32 ).rotateX( Math.PI / 2 );

	// 		// function onSelect() {
	// 		// 	var material = new THREE.MeshPhongMaterial( { color: 0xffffff * Math.random() } );
	// 		// 	var mesh = new THREE.Mesh(geometry, material);
	// 		// 	mesh.position.set(0, 0, - 0.3).applyMatrix4( controller.matrixWorld );
	// 		// 	mesh.quaternion.setFromRotationMatrix( controller.matrixWorld );
	// 		// 	scene.add( mesh );
	// 		// }

	// 		// function onSessionStart() {
	// 		// 	scene.background = null;
	// 		// }
	// 		// var session = renderer.xr.getSession();
	// 		// session.addEventListener('squeeze', onSessionStart);
	// 		// scene.add(session);

	// 		// renderer.xr.addEventListener('start', onSessionStart);

	// 		// var controller = renderer.xr.getController(0);
	// 		// controller.addEventListener('select', onSelect);
	// 		// scene.add(controller);

	// 		// renderer.setAnimationLoop(function () {
	// 		// 	renderer.render(scene, camera);
	// 		// });

	// 		if (!renderer.xr.isPresented) {
	// 			console.log("Animations: " + obj.animations.length);

	// 			scene.add(obj);
	// 			camera.up.set(obj.up.x, obj.up.y, obj.up.z);

	// 			camera.position.set(0, radius, radius);

	// 			controls.target = new THREE.Vector3(
	// 				box.geometry.boundingSphere.center.x,
	// 				box.geometry.boundingSphere.center.y,
	// 				box.geometry.boundingSphere.center.z
	// 			);
	// 			controls.update();

	// 			controls.screenSpacePanning = true;
	// 			controls.update();

	// 			const animate = () =>
	// 			{
	// 				const delta = clock.getDelta();
	// 				if (mixer) {
	// 					mixer.update(delta);
	// 				}
	// 				controls.update();
	// 				renderer.render(scene, camera);
	// 				requestAnimationFrame(animate);
	// 			};
	// 			animate();

	// 			//var width = -

	// 			if (obj.animations.length > 0) {
	// 				const animCount = document.createElement("P");
	// 				animCount.id = "anim-text";
	// 				animCount.style.cssText = "margin-left: auto; margin-right: auto; margin-top: auto; bottom: 32px; left: 0px; height: 48px; width: 48px;";
	// 				animCount.innerText = `${currentAnimation + 1} / ${obj.animations.length}`;

	// 				const animationNameTitle = document.createElement("div");
	// 				animationNameTitle.className = "box-title";
	// 				animationNameTitle.style.cssText = "font-size: 14px;width: 101px;height: 100px;position: absolute;margin: 16px;top: 24px;background: #eb0028;border-radius: 22px;padding-top: 70px;padding-left: 20px;align-self: flex-end;color: white;font-weight: bold;";
	// 				animationNameTitle.appendChild(document.createTextNode("Step Info"));

	// 				const animationNameFather = document.createElement("div");
	// 				animationNameFather.className = "box";
	// 				animationNameFather.id = "anim-vis";
	// 				animationNameFather.style.cssText = "position: absolute; margin: 10px;top: 0px;display: flex;";

	// 				const animationNameSon1 = document.createElement("div");
	// 				animationNameSon1.className = "box-inner"
	// 				animationNameSon1.style.cssText = "overflow-y: auto;flex: 1;padding: 20px; background: white;max-height: 100px;border-radius: 22px; font-size: 14px";

	// 				const animationNameSon2 = document.createElement("div");
	// 				animationNameSon2.style.cssText = "width: 78px; height: 100px;";

	// 				const animationNameText: Text = document.createTextNode(clips.clipsData[0] ? clips.clipsData[0].text : "");

	// 				animationNameSon1.appendChild(animationNameText);
	// 				animationNameFather.appendChild(animationNameSon1);
	// 				animationNameFather.appendChild(animationNameSon2);
	// 				divCurrentModel.appendChild(animationNameTitle);
	// 				divCurrentModel.appendChild(animationNameFather);

	// 				const divElements = document.createElement('div');
	// 				divElements.style.cssText = "position: absolute; margin: 16px; top: calc(100% - 115px); left: 50%;";

	// 				const showText = (value: boolean) =>
	// 				{
	// 					animationNameSon1.hidden = !value;
	// 					animationNameSon2.hidden = !value;
	// 					animationNameFather.hidden = !value;
	// 					animationNameTitle.hidden = !value;
	// 				}

	// 				const playAnim = (anim: number) =>
	// 				{
	// 					mixer.stopAllAction();
	// 					action = mixer.clipAction(obj.animations[anim]);
	// 					action.clampWhenFinished = true;
	// 					action.loop = THREE.LoopOnce;
	// 					action.play();

	// 					if (clips.clipsData[anim] && clips.clipsData[anim].text != null && clips.clipsData[anim].text != "") {
	// 						showText(true);

	// 						animationNameText.textContent = clips.clipsData[anim].text;

	// 						if (voiceEnabled) {
	// 							synth.cancel();
	// 							var utterThis = new SpeechSynthesisUtterance(clips.clipsData[anim].soundUri);
	// 							var voice = synth.getVoices().find(x => x.lang.includes(navigator.language));
	// 							utterThis.voice = voice ? voice : synth.getVoices()[0];
	// 							synth.speak(utterThis);
	// 						}
	// 					}
	// 					else {
	// 						showText(false);
	// 					}
	// 				}

	// 				const pauseAnim = () =>
	// 				{
	// 					if (action.timeScale == 0)
	// 						action.timeScale = 1;
	// 					else
	// 						action.timeScale = 0;
	// 				}

	// 				playAnim(0);

	// 				const animVisualizer = document.createElement("button");
	// 				animVisualizer.style.cssText = "left: -5px; height: 48px; width: 48px;";
	// 				var string = "1";
	// 				var txt = document.createTextNode(string + "/" + obj.animations.length);

	// 				const playButton = document.createElement("button");
	// 				playButton.id = "play_pause_button";
	// 				var playPauseIcon = document.createElement("i");
	// 				playPauseIcon.style.cssText = "font-size: 24px";
	// 				playPauseIcon.className = "material-icons app_icon";
	// 				playPauseIcon.textContent = "pause";
	// 				playButton.appendChild(playPauseIcon);
	// 				playButton.style.cssText = "left: -115px; height: 48px; width: 48px;"
	// 				// if (obj.animations.length > 1) {

	// 				// } else {
	// 				// 	playButton.style.cssText = "margin-left: auto; margin-right: auto; margin-top: auto; bottom: 32px; left: -21%; height: 48px; width: 48px;"
	// 				// }
	// 				playButton.onclick = () => {
	// 					if (playPauseIcon.textContent == "play_arrow") {
	// 						pauseAnim();
	// 						playPauseIcon.textContent = "pause";
	// 					} else {
	// 						pauseAnim();
	// 						playPauseIcon.textContent = "play_arrow";
	// 					}
	// 				}
	// 				divElements.appendChild(playButton);
	// 				//divCurrentModel.appendChild(playButton);

	// 				if (obj.animations.length > 1) {
	// 					console.log("Adding prev-anim button");

	// 					const button = document.createElement("button");
	// 					button.style.cssText = "left: -60px; height: 48px; width: 48px;";
	// 					button.id = "prev-button";
	// 					button.onclick = () =>
	// 					{
	// 						if (currentAnimation > 0) {
	// 							currentAnimation--;
	// 							animVisualizer.removeChild(txt);

	// 							string = "" + (currentAnimation + 1);
	// 							txt = document.createTextNode(string + "/" + obj.animations.length);
	// 							animVisualizer.appendChild(txt);
	// 							divElements.appendChild(animVisualizer);
	// 							playAnim(currentAnimation);
	// 							string = "";
	// 						}
	// 					};
	// 					const icon = document.createElement("i");
	// 					icon.style.cssText = "font-size: 24px; transform: scaleX(-1);";
	// 					icon.textContent = "skip_next";
	// 					icon.className = "material-icons app_icon";
	// 					button.appendChild(icon);
	// 					divElements.appendChild(button);
	// 				}

	// 				{ //currentAnimation < obj.animations.length - 1 || obj.animations.length === 1
	// 					console.log("Adding next-anim button");

	// 					const button = document.createElement("button");
	// 					button.style.cssText = "left: 50px; height: 48px; width: 48px;";
	// 					button.id = "next-button";
	// 					button.onclick = () =>
	// 					{
	// 						if (obj.animations.length === 1) {
	// 							playAnim(0);
	// 						}
	// 						else if (currentAnimation < obj.animations.length - 1) {
	// 							currentAnimation++;
	// 							animVisualizer.removeChild(txt);

	// 							string = "" + (currentAnimation + 1);
	// 							txt = document.createTextNode(string + "/" + obj.animations.length);
	// 							animVisualizer.appendChild(txt);
	// 							divElements.appendChild(animVisualizer);
	// 							playAnim(currentAnimation);
	// 							animCount.innerText = `${currentAnimation + 1} / ${obj.animations.length}`;
	// 						}
	// 					};
	// 					const icon = document.createElement("i");
	// 					icon.style.cssText = "font-size: 24px";
	// 					icon.textContent = "skip_next";
	// 					icon.className = "material-icons app_icon";
	// 					button.appendChild(icon);
	// 					divElements.appendChild(button);
	// 				}

	// 				animationNameSon1.appendChild(animationNameText);
	// 				animationNameFather.appendChild(animationNameSon1);
	// 				animationNameFather.appendChild(animationNameSon2);
	// 				divCurrentModel.appendChild(animationNameTitle);
	// 				divCurrentModel.appendChild(animationNameFather);
	// 				animVisualizer.appendChild(txt);
	// 				divElements.appendChild(animVisualizer);
	// 				divCurrentModel.appendChild(divElements);
	// 			}

	// 			if (clips.clipsData.length > 0) {
	// 				console.log("Adding mute button");

	// 				const voiceButton = document.createElement("button");
	// 				//button.style.cssText = "position: absolute;margin-left: auto; margin-right: auto; margin-top: auto; bottom: 10px; left: 64px;";
	// 				voiceButton.style.cssText = "margin-left: auto; margin-right: 0px; margin-top: auto; bottom: 96px; height: 48px; width: 48px;";
	// 				voiceButton.id = "light-button";
	// 				const voiceIcon = document.createElement("i");
	// 				voiceIcon.style.cssText = "font-size: 24px";
	// 				voiceButton.onclick = () => setVoiceEnabled(!voiceEnabled);
	// 				voiceIcon.className = "material-icons app_icon";
	// 				voiceButton.appendChild(voiceIcon);
	// 				divCurrentModel.appendChild(voiceButton);

	// 				const setVoiceEnabled = (value: boolean) =>
	// 				{
	// 					if (!value) {
	// 						synth.cancel();
	// 					}

	// 					voiceEnabled = value;
	// 					voiceIcon.textContent = voiceEnabled ? "volume_up" : "volume_off";
	// 				};

	// 				setVoiceEnabled(voiceEnabled);
	// 			}

	// 			{
	// 				console.log("Adding light button");

	// 				const lightButton = document.createElement("button");
	// 				//button.style.cssText = "position: absolute;margin-left: auto; margin-right: auto; margin-top: auto; bottom: 10px; left: 64px;";
	// 				lightButton.style.cssText = "margin-left: auto; margin-right: 0px; margin-top: auto; bottom: 32px; height: 48px; width: 48px;";
	// 				lightButton.id = "light-button";
	// 				const lightIcon = document.createElement("i");
	// 				lightIcon.style.cssText = "font-size: 24px";
	// 				lightButton.onclick = () => setBackgroundLight(!lightBackground);
	// 				lightIcon.className = "material-icons app_icon";
	// 				lightButton.appendChild(lightIcon);
	// 				divCurrentModel.appendChild(lightButton);

	// 				const setBackgroundLight = (value: boolean) =>
	// 				{
	// 					lightBackground = value;
	// 					scene.background = lightBackground ? new THREE.Color(0xDBDCE0) : new THREE.Color(0x232323);
	// 					lightIcon.textContent = lightBackground ? "brightness_6" : "brightness_5";
	// 				};

	// 				setBackgroundLight(lightBackground);
	// 			}

	// 			if (lastOrientationEvent != null) {
	// 				window.removeEventListener('orientationchange', lastOrientationEvent);
	// 				window.removeEventListener('resize', lastOrientationEvent);
	// 			}

	// 			handleOrientationChange(exportedObj, renderer, camera, radius);

	// 			lastOrientationEvent = () => handleOrientationChange(exportedObj, renderer, camera, radius);//, startSpinner, stopSpinner);
	// 			window.addEventListener('orientationchange', lastOrientationEvent);
	// 			window.addEventListener('resize', lastOrientationEvent);
	// 		} else {
	// 			scene.background = new THREE.Color(0xffffff);
	// 		}

	// 	},
	// 	(p: ProgressEvent) =>
	// 	{
	// 		logger.debug("FBXLoader " + (p.loaded / p.total) * 100 + "%");
	// 	},
	// 	(e) =>
	// 	{
	// 		logger.error(e);
	// 	}
	// );

	// //window.onorientationchange = ;

	// //const button : any = <Button onClick={nextAnimation}> cazzi </Button>
	// // const button = document.createElement("BUTTON");
	// // button.onclick = nextAnimation;

	// divCurrentModel.appendChild(renderer.domElement);
	// return divCurrentModel;
};

function isMobileDevice() {
	const toMatch = [
		"android",
		"webos",
		"ios",
		"iphone",
		"ipad",
		"ipod",
		"blackberry",
		"windows phone",
	];

	for (let i = 0; i < toMatch.length; i++) {
		if (navigator.userAgent.toLowerCase().indexOf(toMatch[i]) !== -1) {
			return true;
		}
	}

	return false;
}

async function handleOrientationChange(obj: any, renderer: Renderer, camera: PerspectiveCamera, radius: number, onStart?: () => void, onStop?: () => void) {
	try {

		//if (div && div.parentNode){
		//const canvas = div.getElementsByTagName("canvas")[0] as HTMLCanvasElement;
		//canvas.width = window.innerWidth * 3;
		//canvas.height = window.innerHeight * 3;
		//canvas.style.cssText = `width: ${window.innerWidth}px; height: ${window.innerHeight}px;`;
		//}

		if (renderer) {
			if (onStart) onStart();

			await delay(200);

			console.log("Renderer found");
			let aspectRatio = 0;

			var divParentWidth = document.getElementById("current-model")!.clientWidth;
			var divParentHeight = document.getElementById("current-model")!.clientHeight;
			aspectRatio = divParentWidth / divParentHeight;

			renderer.setSize(divParentWidth, divParentHeight);

			if (camera) {
				console.log("Camera found");

				camera.up.set(obj.up.x, obj.up.y, obj.up.z);
				camera.position.set(0, radius, radius);
				(camera as PerspectiveCamera).aspect = aspectRatio;
				(camera as PerspectiveCamera).clearViewOffset();
			}

			if (onStop) onStop();
		}
	}
	catch {
		console.error("Error while applying changes on rotation");
	}
	//console.log("the orientation of the device is now " + screen.orientation.angle);
}

const nextAnimation = () => {
};

export const handle3dModel = (uri: string) => {
	const model = document.createElement("model-viewer");
	model.src = uri;
	model.cameraControls = true;
	model.ar = true;
	model.arScale = "auto";
	model.id = "current-model";
	model.style.cssText =
		"position: fixed: margin: 0;width: 100vw; height: 100%;z-index:9999";
	model.backgroundColor = "#232323";

	return model;
};

export const handleVideoFileOld = (file: IFile) => {
	const isIOS =
		/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
	const div = document.createElement("div");
	div.id = "current-model";
	div.style.cssText =
		"position: fixed: margin: 0;width: 100vw; height: 100%;z-index:9999; display: flex; align-items: center;justify-content: center; overflow: none";
	if (isIOS) {
		const filesize = (file.bytes / 1048576).toFixed(1);

		const videoContainer = document.createElement("div");
		if (parseFloat(filesize) > 0) {
			const videoText = document.createElement("p");
			videoText.textContent = `Video (${filesize} Mb)`;
			videoContainer.appendChild(videoText);
		}
		const videoBtn = document.createElement("button");
		videoBtn.textContent = "Download";
		videoBtn.style.cssText =
			"background-color: transparent; \
			position: initial; \
			border: 2px solid #fff; \
			color: #fff; \
			padding: 15px 32px; \
			width: 120px; \
			height: 60px; \
			margin: 0 auto; \
			text-decoration: none; \
			border-radius: 4px";
		videoBtn.onclick = () => {
			window.open(file.realFileUri, "_blank");
		};
		videoContainer.appendChild(videoBtn);
		div.appendChild(videoContainer);
	} else {
		const video = (
			<video
				loop
				autoPlay
				controls
				muted
				playsInline
				src={file.realFileUri}
				width="100%"
				style={{ maxHeight: "calc(100vh - 120px)", zIndex: 9999 }}
			>
				<source src={file.realFileUri} type="video/mp4"></source>
			</video>
		);
		ReactDOM.render(video, div);
	}
	return div;
};



export const handleVideoFile = async (file: IFile, activeCall?: boolean) => {
	const response = await fetch(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}.mp4`);
	const uri = await response.text();
	console.log(response);
	const divVideoElement = document.createElement("div");
	divVideoElement.id = "current-model";
	if (activeCall === true) {
		divVideoElement.style.cssText =
			"margin: 0; z-index:9999; display: flex; align-items: center; justify-content: center; width: 100%;";
	} else {
		divVideoElement.style.cssText =
			"position: fixed; margin: 0; width: 100vw; height: 100%; z-index:9999; display: flex; align-items: center; justify-content: center";
	}
	const video = document.createElement("video");
	video.style.cssText =
		"width: 100%; max-height: calc(100vh - 120px); z-index: 9999";
	video.src = uri;
	video.toggleAttribute("autoplay", true);
	video.toggleAttribute("controls", true);
	video.setAttribute("controlsList", "nodownload");
	video.toggleAttribute("loop", true);
	video.toggleAttribute("muted", true);
	video.toggleAttribute("playsinline", true);

	const source = document.createElement("source");
	source.type = "video/mp4";
	source.src = file.realFileUri;
	var myDiv = <div className="modal-window-drag-anchor">
		<Icon style={{ transform: "rotate(45deg)" }} name="zoom_out_map" />
	</div>;
	video.appendChild(source);
	divVideoElement.appendChild(video);
	return divVideoElement;
};

export const handleImageFile = (uri: string, isPortrait?: boolean, activeCall?: boolean) => {
	var count: number = 0
	const tempdiv = document.createElement("div");
	tempdiv.id = "current-model";
	if (activeCall === true) {
		tempdiv.style.cssText = 'background: #232323; position: fixed; margin: 0px; width: 100%; z-index: 9999; display: flex; justify-content: center;';
	} else {
		tempdiv.style.cssText = "position: fixed; margin: 0px;width: 100vw; height: 100%; z-index:9999; display: flex; justify-content: center";
	}


	const spinner = document.createElement("div");
	if (activeCall === true) {
		spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: -20%; margin-top: 35vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>';
	} else {
		spinner.innerHTML = '<div style="width: 100%;height: 100%;margin-left: -64px;margin-top: -128px;position: absolute;"><div class="spinner spinner" style=" margin-left: -50%; margin-top: 50vh; margin-bottom: auto; height: 128px; width: 128px;"></div></div>';
	}


	console.log(spinner);

	let navBarHeight: number = 0;
	var navBar = document.getElementsByClassName("layout-navbar")[0] as HTMLDivElement;
	if (navBar != null) {
		navBarHeight = + navBar.clientHeight;
	}

	const spinnerEnd = () => {
		const inputImage: HTMLImageElement = document.getElementById("image-to-delete") as HTMLImageElement;
		// if (inputImage && isPortrait !== undefined && count <= 1) {
		// 	const inputWidth = inputImage.naturalWidth;
		// 	const inputHeight = inputImage.naturalHeight;
		//     let outputWidth = inputWidth;
		//     let outputHeight = inputHeight;

		// 	if (isPortrait === true) {
		// 		outputWidth = 500;
		// 		count++;
		// 		//outputHeight = 1280;
		// 	} else {
		// 		outputWidth = 900;
		// 		count++;
		// 		//outputHeight = 500;
		// 	}

		// 	const outputX = (outputWidth - inputWidth) * .5;
		//     const outputY = (outputHeight - inputHeight) * .5;

		// 	const outputImage = document.createElement('canvas');
		// 	outputImage.width = outputWidth;
		// 	outputImage.height = outputHeight;
		// 	const ctx = outputImage.getContext('2d');
		// 	if (ctx !== null) {
		// 		ctx.drawImage(inputImage, outputX, outputY);
		// 	}
		// 	inputImage.src = outputImage.toDataURL();
		// }
		if (tempdiv.contains(spinner))
			tempdiv.removeChild(spinner);
	};

	const zoomableThing = (
		<TransformWrapper options={{ limitToBounds: true, limitToWrapper: true }}>
			{({ resetTransform }: any) => (
				<>
					<TransformComponent>
						<div
							style={activeCall !== true ?
								{
									position: "relative",
									width: "100vw",
									height: `calc(100vh - ${isPortrait === undefined ? navBarHeight * 2 : -17}px)`,
								} : {
									position: "relative",
									width: "40vw"
								}}
						>
							<img
								id={`image-to-delete`}
								src={uri}
								onLoad={() => spinnerEnd()}
								style={activeCall !== true ? {
									position: "absolute",
									margin: "auto",
									left: 0,
									top: 0,
									bottom: 0,
									right: 0,
									maxWidth: "100%",
									maxHeight: "calc(100vh - 120px)"
								} : {
									position: "relative",
									margin: "auto",
									left: "0px",
									maxWidth: "100%"
								}}
							/>
						</div>
					</TransformComponent>
				</>
			)}
		</TransformWrapper>
	);
	ReactDOM.render(zoomableThing, tempdiv);

	tempdiv.appendChild(spinner);

	return tempdiv;
};

export const handlePdfImage = async (file: IFile, activeCall?: boolean) => {
	const url = `${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`;
	pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js";

	var pdf = await pdfjs.getDocument(url).promise;
	var pageNumber = 1;
	var page = await pdf.getPage(pageNumber);
	var scale = 1.5;
	var viewPort = page.getViewport({ scale: scale });
	var canvas = document.createElement('canvas');
	var context = canvas.getContext('2d');
	canvas.height = viewPort.height;
	canvas.width = viewPort.width;

	if (context !== null) {
		var renderContext = {
			canvasContext: context,
			viewport: viewPort
		};
		var renderTask = page.render(renderContext);
		renderTask.promise.then(() => {
			console.log('Page rendered');
		});
	}

	const div = document.createElement('div');
	div.id = "current-model";
	div.appendChild(canvas);

	return div;
};

export const handlePdfImageOLD = async (fileId: string, activeCall?: boolean) => {
	return fetch(`${process.env.REACT_APP_LOCALHOST}api/Files/${fileId}/GetPdfFileForHololens`)
		.then((res) => {
			return res.arrayBuffer();
		})
		.then((buffer) => {
			const reader = new BinaryReader(buffer);
			reader.readString(Encoding.Utf8);
			const numPages = reader.readInt();
			reader.readInt();
			reader.readInt();

			const imageArray: number[][] = [];

			try {
				for (let i = 0; i < numPages; i++) {
					const length = reader.readInt();
					const image: number[] = reader.readBytes(length);
					imageArray.push(image);
				}
			} catch (err) {
				console.log("Error reading data: " + err);
			}
			return imageArray;
		})
		.then((array) => {
			const tempdiv = document.createElement("div");
			tempdiv.id = "current-model";
			tempdiv.className = "div-pdf";

			if (activeCall === true) {
				tempdiv.style.cssText = "position: absolute; margin: 0px; overflow-y: auto; height: 400px; background: #232323; z-index: 9999;"
			} else {
				tempdiv.style.cssText = "position: absolute; margin: 0;width: 100vw; height: 100%; z-index:9999";
			}


			const ZoomableContent = (
				<div
					style={{
						width: "100%",
						//display: "flex",
						flexDirection: "column"
					}}
				>
					{array.map((page, index) => {
						const base64 = btoa(
							new Uint8Array(page).reduce(
								(data, byte) =>
									data + String.fromCharCode(byte),
								""
							)
						);
						const src = `data:image/png;base64,${base64}`;
						return (
							<img
								key={index}
								src={src}
								style={{ width: "100%", margin: "2px" }}
							/>
						);
					})}
				</div>
			);
			const zoomableThing = (
				<TransformWrapper
					options={{ limitToBounds: false }}
					pan={{ lockAxisX: false }}
				>
					{({ resetTransform, scale }: any) => (
						<>
							<TransformComponent>
								{ZoomableContent}
							</TransformComponent>
						</>
					)}
				</TransformWrapper>
			);
			ReactDOM.render(zoomableThing, tempdiv);

			var editZoomableThing = tempdiv.getElementsByClassName("react-transform-component")[0] as HTMLDivElement;
			if (editZoomableThing != null) {
				editZoomableThing.style.overflow = "visible";
			}

			return tempdiv;
		})
		.catch((err) => {
			console.log("Error: " + err);
		});
};
