import "@google/model-viewer";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../reducers";
import { setCookie } from "../../managers/I18n";
import moment from "moment";
import { serverManager } from "../../managers/ServerManager";
import { IUser, TicketStatus } from "../../types";
import { authProvider } from "../../auth/AuthProvider";



interface IProps extends RouteComponentProps, ConnectProps
{
    token: string;
	id: string;
    ticketId?: string;
	pageRedirect: string;
}

interface IUserRedirectProps extends ConnectProps, IProps {}


export class UserRedirectComponent extends React.Component<IUserRedirectProps>{
    pageRedirect: string;
    token: string ;
	id: string;
    ticketId: string;

	constructor(props: any)
	{		
		super(props);
		console.log("Token id temporary user: " + this.props.token);
        console.log("Product id id temporary user: " + this.props.id);
        console.log("Redirect Section temporary user: " + this.props.pageRedirect);

        this.pageRedirect = this.props.pageRedirect;
        this.token = this.props.token;
        this.id = this.props.id;
        this.ticketId = this.props.ticketId ? this.props.ticketId : "";
	}

	public async componentDidMount()
	{
        console.log("Token id temporary user: " + this.props.token);
        console.log("Product id id temporary user: " + this.props.id);
        console.log("Redirect Section temporary user: " + this.props.pageRedirect);
	}

	public async componentWillMount()
	{
        let baseUrl: string = window.location.href;
        //set redirect path for selected section in UI
        let troubleshootingUrl: string = "/product/"+ this.id +"/troubleshooting";
        let receivedUrl : string = "/product/"+ this.id +"/received";
        let callUrl : string = this.ticketId ? `/product/${this.id}/ticketcall/${this.ticketId}/call` : "/product/"+ this.id +"/call";
        let filesUrl : string = "/product/"+ this.id +"/files";
        const { data } = await serverManager.users.getUserByAccessToken(this.props.token);
        const response = await serverManager.tickets.getTicketsByOperatorId(data.id as string);
        const isActive: boolean = Math.abs(moment.utc(data.creationDate).diff(moment().utc(), "hours")) < 8;
        const anyOpenTicket: boolean = response.data.find(ticket => ticket.status !== TicketStatus.Solved && ticket.status !== TicketStatus.NoNeedToSolved && TicketStatus.ClosedButNotSolved) !== undefined;

        console.log(`[debug-china] token: ${this.token}, isActive: ${isActive}, diff in minutes: ${Math.abs(moment.utc(data.creationDate).diff(moment().utc(), "minutes"))}, creationDate string: ${data.creationDate}, creationDate date: ${moment.utc(data.creationDate)}, now utc: ${moment().utc()}`);

        if (isActive) {
            switch(this.pageRedirect) { 
                case "troubleshooting": { 
                    console.log("add cookie: " + this.token);
                    setCookie('XBasicToken',this.token);
                    console.log("Redirect to : " + troubleshootingUrl);
                    this.props.history.push(troubleshootingUrl);
                    break;
                }
                case "received": { 
                    console.log("add cookie: " + this.token);
                    setCookie('XBasicToken',this.token);
                    console.log("Redirect to : " + receivedUrl);
                    this.props.history.push(receivedUrl);
                    break;
                }
                case "files": { 
                    console.log("add cookie: " + this.token);
                    setCookie('XBasicToken',this.token);
                    console.log("Redirect to : " + filesUrl);
                    this.props.history.push(filesUrl);
                    break;
                }
                case "call": { 
                    console.log("add cookie: " + this.token);
                    setCookie('XBasicToken',this.token);
                    console.log("Redirect to : " + callUrl);
                    this.props.history.push(callUrl);
                    break;
                }
                default: { 
                    console.log("add cookie: " + this.token);
                    setCookie('XBasicToken',this.token);
                    console.log("Redirect to : " + callUrl);
                    this.props.history.push(callUrl);
                    break; 
                }
            }
        } else if (anyOpenTicket) {
            this.props.history.push(receivedUrl);
        } else {
            window.location.href = window.origin;
        }
	}

    render() {
        return null;
    }
}

const mapStateToProps = (state: IRootState) => ({
    userInfo: state.userInfo.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const UserRedirect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(UserRedirectComponent));
