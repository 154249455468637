import { IRootState } from "../";

export function selectLanguageKey(state: IRootState) {
	const { selectedLanguageIds } = state.troubleshootingLanguages;
	const availableLanguages = Object.keys(state.troubleshootingLanguages.data);

	if (selectedLanguageIds.length >= 1) {
		return selectedLanguageIds[0];
	}

	if (availableLanguages.length === 0) {
		return "";
	}

	return availableLanguages[0];
}
