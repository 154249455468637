import React, { useState } from "react";
import { Button, Col, Input, InputGroup, InputGroupAddon, Row, Label } from "reactstrap";
import { Lang, T } from "../../managers/I18n";
import { UserRole } from "../../types/IRoles";
import "./DocumentationMenu.scss";

interface IProps {
	isCompanyNotSubordinate: boolean;
	role: UserRole;
	isFileSelected: boolean;
	isOfficialToggled: boolean;
	isSortInverted: boolean;
	sortType: number;
	filesCount: number;
	filesWeight: number;
	maxWeight: number;
	onSearchTextChanged: (searchText: string) => void;
	onDelete: () => void;
	onOfficialToggle: () => void;
	onSortToggle: (type: number) => void;
	searchString: string;
}


export const DocumentationMenu = (props: IProps) => {
	const [text, setText] = useState<string>("");
	return (
		<div className={"documentation-menu"}>
		<Lang>
			{getLanguageString => (<div>
				<Row  style={{textAlign: `center`, rowGap: `10px`}}>
				<Col>
					<h2>
						<T v={"Sort.FilesAmount"}/>
						<span>{props.filesCount}</span>
					</h2>
				</Col>
				<Col>
					<h2 style={{textAlign: `center`, color: props.filesWeight > props.maxWeight ? `red` : ``}}>
						<T v={"Sort.DiskSpace"}/>
						<span>{props.filesWeight >= 0 ? props.filesWeight.toFixed(2) : `loading...`} / {props.maxWeight.toFixed(2)} GB</span>
					</h2>
				</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<Button onClick={() => props.onSearchTextChanged(text)} color="input">
									<i className="material-icons">search</i>
								</Button>
							</InputGroupAddon>
							<Input
								value={text}
								onChange={e => setText(e.target.value.toLowerCase())}
								placeholder={getLanguageString("DocumentationDetails.SearchPlaceholder")}
							/>
						</InputGroup>
						<h2 style={{textAlign: `center`, fontSize: `140%`}}>
							<T v={props.isOfficialToggled ? "Sort.ShowOfficial" : "Sort.ShowAll"}/>
						</h2>
					</Col>
					{props.role === UserRole.CompanyAdmin && (
						<Col className="doc-tool-sort-column">
							<Button onClick={() => props.onOfficialToggle()} color={props.isOfficialToggled ? "white" : "item"} className="doc-tool-sort-button">
								<i className="material-icons md-24">description</i>
							</Button>
							<Label className={"doc-tool-sort-text"}>
								<T v="Sort.Official"/>
							</Label>

							<Button onClick={() => props.onSortToggle(0)} color={props.sortType == 0 ? "white" : "item"} className={props.sortType == 0 && props.isSortInverted ? "doc-tool-sort-button-flip" : "doc-tool-sort-button"}>
								<i className="material-icons md-24">arrow_drop_down</i>
							</Button>
							<Label className={"doc-tool-sort-text"}>
								<T v="Sort.Name"/>
							</Label>

							<Button onClick={() => props.onSortToggle(1)} color={props.sortType == 1 ? "white" : "item"} className={props.sortType == 1 && props.isSortInverted ? "doc-tool-sort-button-flip" : "doc-tool-sort-button"}>
								<i className="material-icons md-24">arrow_drop_down</i>
							</Button>
							<Label className={"doc-tool-sort-text"}>
								<T v="Sort.Date"/>
							</Label>

							<Button onClick={() => props.onSortToggle(2)} color={props.sortType == 2 ? "white" : "item"} className={props.sortType == 2 && props.isSortInverted ? "doc-tool-sort-button-flip" : "doc-tool-sort-button"}>
								<i className="material-icons md-24">arrow_drop_down</i>
							</Button>
							<Label className={"doc-tool-sort-text"}>
								<T v="Sort.Type"/>
							</Label>

							{props.isCompanyNotSubordinate && <Button disabled={!props.isFileSelected} onClick={() => props.onDelete()} className="float-right" color="item">
								<i className="material-icons md-24">delete</i>
							</Button>}
							{/*<Label className={"doc-tool-delete-text"}>
								<T v="Delete"/>
							</Label>*/}
							{/* <Button className="float-right mr-1" color="item">
							<i className="material-icons md-24">view_module</i>
						</Button>
						<Button className="float-right mr-1" color="item">
							<i className="material-icons md-24">view_list</i>
						</Button> */}
						</Col>
					)}
				</Row>
			</div>)}
		</Lang>
		</div>
	);
};
