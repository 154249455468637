import { compose } from "redux";
import { IS_DEVELOPMENT, IS_PRODUCTION_DEBUG } from "./logger";

interface IWindowWithDevtools extends Window {
	// tslint:disable-next-line:no-any
	__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

/* tslint:disable */
export const composeEnhancers =
	((IS_DEVELOPMENT || IS_PRODUCTION_DEBUG) &&
		// tslint:disable-next-line:max-line-length
		window &&
		(window as IWindowWithDevtools).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		(window as IWindowWithDevtools).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			name: "HoloMaintenance",
		})) ||
	compose;
/* tslint:enable */
