import "./TeamInformation.scss";
import React from "react";
import { ITeam } from "../../types/ITeam";
import { Spinner } from "../globalComponents/Spinner";
import { IconButton } from "../globalComponents/IconButton";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import { T, GetLangString, Lang } from "../../managers/I18n";
import Input from "reactstrap/lib/Input";
import { objectDiff } from "../../utilities/object-diff";
import { IconTextButton } from "../globalComponents/IconTextButton";
import { LoadingPage } from "../globalComponents/LoadingPage";

interface ITeamInformationCreationProps {
    team: ITeam;
    isSaving: boolean;
    isCreateNew: boolean;
	isEditing: boolean;
	onCreateClicked: (newTeam: ITeam) => void;
}

interface ITeamInformationCreationState {
    team: ITeam;
    loading: boolean;
    isEditing: boolean;
    isCreateNew: boolean;
    cancelTeam: ITeam;
    teamNameValid: boolean;
    teamNameInvalid: boolean;
}

export class TeamInformationCreation extends React.Component<ITeamInformationCreationProps, ITeamInformationCreationState> {
    constructor(props: any) {
        super(props);

        this.state = {
            team: {
                id: '',
                name: '',
                companyId: '',
				referencedProductIds: [],
            },
            loading: false,
            isEditing: false,
            isCreateNew: false,
            cancelTeam: ({} as any) as ITeam,
            teamNameInvalid: false,
            teamNameValid: false,
        }
    }

    public componentDidMount()
	{
		if (this.props.isEditing || this.props.isCreateNew) {
			this.setState({ isEditing: this.props.isEditing, isCreateNew: this.props.isCreateNew });
		}

		this.setState({ team: this.props.team });
	}

    public componentDidUpdate(prevProps: ITeamInformationCreationProps)
	{
		if (prevProps !== this.props && !this.props.isSaving) {
			this.setState({ team: this.replaceNullWithEmptyString(this.props.team) });
		}

		if (prevProps !== this.props && this.props.team.id === "" && !this.props.isSaving) {
			this.setState({ team: this.replaceNullWithEmptyString(this.props.team) });
		}
	}

    render() {
        const { team, teamNameValid, teamNameInvalid, isCreateNew, isEditing, loading } = this.state;
        const { isSaving } = this.props;
        const editMode = this.returnEditMode(isEditing, isSaving, isCreateNew);
        
        if (loading) {
			return <LoadingPage />;
		}

		if (!team) {
			return null;
		}

        return (
			<Lang>{getLanguageString => (
            <div className="team-detail-container">
				<div className="team-detail-actionbar">
					<div>
						<h4 className="team-list-items">{team.name ? team.name : getLanguageString("Teams.TitleName")}</h4>
					</div>
					{this.renderActionButton()}
				</div>
				<Card className="team-details">
                    <CardBody>
                        <Form>
                            <Row noGutters>
                                <Col className="gutter-right" xs={12} md={6}>
                                    <FormGroup>
									    <Label>
										    <T v={"Teams.Name"} /> {/*nome della company*/}
									    </Label>
									    <Input
										    valid={teamNameValid}
								    		invalid={teamNameInvalid}
								    		onBlur={this.handleTeamNameValidation}
								    		disabled={editMode}
								    		className={editMode ? "disabled" : ""}
								    		onChange={this.handleTeamChange("name")}
								    		value={team.name}
									    />
							        </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        {this.renderAddButton()}
                    </CardBody>
                </Card>
            </div>)
			}</Lang>
        );
    }

    private handleTeamNameValidation = async () =>
	{
		if (this.state.team.name === "" || this.state.team.name === this.state.cancelTeam.name) {
			this.setState({ teamNameInvalid: false, teamNameValid: false });
			return;
		}
		// const response = await serverManager.companies.isValidCompanyName(this.state.team.name);
		// if (response.data) {
		// 	this.setState({ teamNameValid: true, teamNameInvalid: false });
		// } else {
		// 	this.setState({ teamNameValid: false, teamNameInvalid: true });
		// }
	};

    private handleTeamChange = (fieldName: keyof ITeam) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			team: {
				...this.state.team,
				[fieldName]: e.target.value,
			},
		});
	};

    private returnEditMode(isEditing: boolean, isSaving: boolean, isCreateNew: boolean) {
		if (isEditing) {
			return !isEditing || isSaving;
		}
		if (isCreateNew) {
			return !isCreateNew || isSaving;
		}
		return true;
	}

    private renderAddButton = () =>
	{
		const { isCreateNew } = this.state;

		if (isCreateNew) {
			return (
				<div>
					<IconTextButton color="primary" iconName="add" text={GetLangString("Teams.CreateButton")} onClick={this.handleCreateButtonClick} />
				</div>
			);
		}
		return null;
	};

    private renderActionButton = () =>
	{
		const { isEditing, isCreateNew } = this.state;
		const { isSaving } = this.props;

		if (isSaving) {
			return (
				<div>
					<Spinner />
				</div>
			);
		}

		if (isCreateNew) {
			return (
				<div>
					<IconButton onClick={this.handleCreateButtonClick} iconName={"save"} />
				</div>
			);
		}

		if (isEditing) {
			return (
				<div>
					<IconButton onClick={this.cancelEditMode} iconName={"close"} />
					<IconButton onClick={this.handleSaveButtonClick} iconName={"save"} />
				</div>
			);
		}

		return (
			<div>
				<IconButton onClick={this.handleEditButtonClick} iconName={"edit"} />
			</div>
		);
	};

    private handleEditButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
		event.preventDefault();
		this.setState({ isEditing: !this.state.isEditing });
	};

    private handleSaveButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) =>
	{
		event.preventDefault();
		const diff = objectDiff(this.state.team, this.props.team);

		if (diff.length) {
			return this.saveItem();
		}

		return this.cancelEditMode();
	};

    private cancelEditMode = () =>
	{
		this.setState({ isEditing: false });
	};

    private saveItem = async () =>
	{
		const { team } = this.state;
		if (this.props.onCreateClicked) {
			this.props.onCreateClicked(team);
		}
		this.setState({ isEditing: false });
	};

    private handleCreateButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) =>
	{
		event.preventDefault();
		//const diff = objectDiff(this.state.company, this.props.company);

		//console.log("trying to create new company");
		//if (diff.length) {
		return this.saveItem();
		//}

		//return this.cancelEditMode();
	};

    private replaceNullWithEmptyString = (team: ITeam) =>
	{
		const keys = Object.keys(team);
		const replacedCompany = { ...team };

		for (const key of keys) {
			const typedKey = key as keyof typeof team;
			if (replacedCompany[typedKey] === null) {
				replacedCompany[typedKey] = "";
			}
		}

		return replacedCompany;
	};
}