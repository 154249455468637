import moment, { lang } from "moment";
import React from "react";
import { CardHeader, CardTitle, Col, CustomInput, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import CardBody from "reactstrap/lib/CardBody";
import { bindActionCreators, Dispatch } from "redux";
import { flatTroubleshootingMockData, ticket } from "../../managers/DummyData";
import { getCurrentLanguage, GetLangString, Message, Msg, T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { IFile, ITicket, ITicketItem, IUpdateTicket, IUser, TicketItemType, TicketStatus, TicketStatusText, TicketStatusText2 } from "../../types";
import { delay, logger, objectDiff, toArray } from "../../utilities";
import { enumToArray, Ivalue } from "../../utilities/enum";
import { IconButton, Spinner } from "../globalComponents";
import { SaveOverlay } from "../globalComponents/SaveOverlay";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { IProps } from "./Ticket";
import "./TicketInfo.scss";
import { connect } from "react-redux";
import { serverManager } from "../../managers/ServerManager";
import { AxiosResponse } from "axios";
import { AccessTokenResponse } from "react-aad-msal";
import { authProvider } from "../../auth/AuthProvider";
import { throws } from "assert";
import { UserLinkModal } from "../user/UserDeletModal";
import { actions } from "../../reducers/users";
import { userInfo } from "os";

export interface ITicketInfoProps extends InjectedToastsProps, ConnectProps {
	ticket: ITicket;
	saveTicket: (ticket: IUpdateTicket, callback?: (msg: string, valid: boolean) => void) => void;
	exportTicket: (ticketId: string) => void;
	// exportTicketVideoPdf: (ticketId: string) => void;
	exportTicketCsv: (ticketId: string) => void;
	getTicket: (ticketId: string) => void;
}

interface IState {
	isSaving: boolean;
	localItem: ITicket;
	currentLang: string;
	videoUrl: string | null;
	isOperatorNameInputDisabled: boolean;
	isModifying: boolean;
	fixedValue: string;
	temporaryString: string;
	isLinkModalOpen: boolean;
	callLink: string;
}

//var isFixed = false;
class TicketInfoBase extends React.Component<ITicketInfoProps> {
	public state: Readonly<IState> = {
		localItem: {
			id: 0,
			ticketId: 0,
			friendlyId: 0,
			companyName: "",
			expertName: "",
			operatorName: "",
			start: new Date().toString(),
			end: new Date().toString(),
			status: 0,
			productId: "",
			productCode: "",
			productName: "",
			description: "",
			ticketEvent: [
				{
					id: 0,
					type: TicketItemType.Login,
					date: new Date().toString(),
					url: "",
					data: "",
				},
			],
		},
		isSaving: false,
		currentLang: "",
		videoUrl: null,
		isOperatorNameInputDisabled: true,
		isModifying: false,
		fixedValue: this.props.ticket.description,
		temporaryString: "",
		isLinkModalOpen: false,
		callLink: "",
	};

	public componentDidMount() {
		if (this.props.ticket) {
			this.setState({ localItem: this.props.ticket });
			//console.log("sono carico"+ this.state.localItem.description);
			//this.setState({ fixedValue: this.state.localItem.description });
		}
		this.props.getUsersByCompany(this.props.userInfo.companyId);
		this.getExportTicketVideoPdfUrl();
	}

	public componentDidUpdate(prevProps: ITicketInfoProps) {
		if (prevProps.ticket !== this.props.ticket) {
			this.setState({ localItem: this.props.ticket });
		}



		// if (prevState.localItem.description !== this.state.localItem.description && !this.state.isFixed) {
		// 	this.setState({ fixedValue: this.state.localItem.description, isFixed: true });
		// }
	}

	public render() {
		const { callLink, isLinkModalOpen, isSaving } = this.state;
		const { productName, productCode, companyName, start: startTime, operatorName, description, status } = this.state.localItem;
		const getStatusOptions = (item: Ivalue): JSX.Element => {
			const name: string = item.name;
			return (
				<Msg key={item.value} id={name}>
					{val => <option value={item.value}>{val}</option>}
				</Msg>
			);
		};

		return (
			<React.Fragment>
				{isSaving && <SaveOverlay text="Saving..." />}
				<CardHeader>
					<CardTitle>{productName}</CardTitle>
					{this.renderActionButton()}
				</CardHeader>
				<CardBody>
					<UserLinkModal
						hiddenLink={true}
						hiddenRedirect={true}
						handleProductLink={() => {}}
						handleRedirect={() => {}}
						handleCopy={() => this.handleCopyToClipboard(callLink)}
						isOpen={isLinkModalOpen}
						toggle={() => this.setState({ isLinkModalOpen: !isLinkModalOpen })}
						link={callLink}
					/>
					<Form>
						<Row>
							<Col md={6}>
								<Label>
									<T v="Ticket.ProductName" />
								</Label>
								<p>{productName}</p>
							</Col>
							<Col md={6}>
								<Label>
									<T v="Ticket.ProductCode" />
								</Label>
								<p>{productCode}</p>
							</Col>
							<Col md={6}>
								<Label>
									<T v="Ticket.CompanyName" />
								</Label>
								<p>{companyName}</p>
							</Col>
							<Col>
								<Label>
									<T v="Ticket.DateCreated" />
								</Label>
								<p>
									{moment
										.utc(new Date(startTime))
										.local()
										.format("LLLL")}
								</p>
							</Col>
							<Col md={6}>
								<Label>
									<T v="Ticket.OperatorName" />
								</Label>
								<div onClick={(e) => this.setOperatorNameInputChange(e)} title={GetLangString('Toast.ClickToModify')}>
									<input
										type="text"
										disabled={this.state.isOperatorNameInputDisabled}
										onChange={this.handleChange("operatorName")}
										value={operatorName}
									/>
								</div>
								{/* <p> {operatorName}</p> */}
							</Col>
							<Col md={12}>
								<FormGroup>
									<Label for="Summary">
										<T v="Ticket.Summary" />
									</Label>
									<textarea
										// onBlur={(event) => this.handleKeyUp(event)}
										// onKeyUp={(event) => this.handleKeyUp(event)}
										disabled={true}
										className="form-control "
										id="Summary"
										value={this.state.fixedValue}
									/>
								</FormGroup>
							</Col>
							<Col md={12}>
								<FormGroup>
									<Label for="AddToSummary">
										<T v="Ticket.AddToSummary" />
									</Label>
									<textarea
										id="AddToSummary"
										onChange={this.handleTextChange("description")}
										className="form-control "
										value={this.state.temporaryString}
										placeholder={GetLangString("Ticket.AddToSummaryPlaceholder")}
									/>
								</FormGroup>
							</Col>
							<Col md={12} lg={4}>
								<FormGroup>
									<Label for="Status">
										<T v="Ticket.Status" />
									</Label>
									<CustomInput type="select" id="Status" name="TicketStatus" value={status} onChange={this.handleChange("status")}>
										{enumToArray(TicketStatusText).map(getStatusOptions)}
									</CustomInput>
								</FormGroup>
							</Col>
							{<Col md={6} className="generate-link-col-button">
									<Button disabled={this.props.ticket.status === TicketStatus.Solved} color="primary" onClick={() => this.onGenerateLink()}>
										<T v="Users.CopyLink" />
									</Button>
							</Col>}
						</Row>
						<Row>
							<Col xs={12}>
								<Button disabled={this.shouldSaveButtonBeDisabled()} color="primary" onClick={async () => await this.handleSave()}>
									<Message wrap={false} id="Save" />
								</Button>
								<Button style={{ marginLeft: 8 }} disabled={this.shouldSaveButtonBeDisabled()} onClick={this.handleCancelChanges}>
									<Message wrap={false} id="Cancel" />
								</Button>
								<Button onClick={async () => await this.getExportTicketUrl(true)} style={{ marginLeft: 8 }} color="primary" /*onClick={this.handleExport}*/>
									<T v="Ticket.ExportPdf" />
									{/* <Message wrap={false} id={`${GetLangString('Ticket.ExportPdf')}`} /> */}
								</Button>
								<Button onClick={async () => await this.getExportTicketUrl(false)} style={{ marginLeft: 8 }} color="primary">
									<T v="Ticket.ExportPdfNoImage" />
									{/* <Message wrap={false} id={`${GetLangString('Ticket.ExportPdfNoImage')}`}/> */}
								</Button>
								{/* <Button href={this.state.videoUrl} style={{ marginLeft: 8 }} color="primary" >
									<Message wrap={false} id="Export (VIDEO PDF)" />
								</Button> */}
								<Button onClick={async () => await this.getExportTicketCsvUrl()} style={{ marginLeft: 8 }} color="primary" /*onClick={this.handleExport}*/>
									<T v="Ticket.ExportCsv" />
									{/* <Message wrap={false} id={`${GetLangString('Ticket.ExportCsv')}`} /> */}
								</Button>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</React.Fragment>
		);
	}

	private handleKeyUp(event: any): void {
		var currentTarget = event.currentTarget;
		console.log("fixedValue: " + this.state.fixedValue);
		console.log("currentTargetValue: " + currentTarget.value);
		if (currentTarget.value.indexOf(this.state.fixedValue) !== 0) {
			event.preventDefault();
			currentTarget.value = this.state.fixedValue;
		}
	}

	private _isValidTempUser(date: string){
		return Math.abs(moment.utc(date).diff(moment().utc(), "hours")) < 8;
	}



	private _getTempUser = (): IUser | undefined => {
		
		const users = this.existTempUser();

		if(users.length === 0){
			this.props.createTempUser(this.handleTempUserCallback);
			return;
		}

		return users[0];
	}

	private handleTempUserCallback = async (userCreated: IUser | null, msgId: string, type: ToastType) =>
		{
			if (type == ToastType.Success && userCreated) {
				this.handleGenerateLink(userCreated);
			}
	
			this.props.addToast({ msgId, type });
		};

	private handleGenerateLink = (tempUser: IUser) => {
		this.setState(() => { return { isLinkModalOpen: !this.state.isLinkModalOpen }});
		this.props.getBearerToken(tempUser.id.toString(), this.props.ticket.productId, "", this.tempUserLinkCallbackForRedirect);
	}

	private tempUserLinkCallbackForRedirect = (authToken: string, msgId: string, type: ToastType) => {
		const ticket = this.props.ticket;
		const url = `${window.location.origin}/temporaryUserAccess/${authToken}/${ticket.productId}/ticketcall/${ticket.id}/call`;
		this.setState({ callLink: url });
	};

	private existTempUser() {
		return this.props.users.filter(x => x.isTemporary && x.createdBy == this.props.userInfo.id && !x.isBlocked && this._isValidTempUser(x.creationDate)).sort((userA, userB) => {
			const dateA = new Date(userA.creationDate).getTime();
			const dateB = new Date(userB.creationDate).getTime();

			return dateB - dateA;
		});
	}

	private onGenerateLink(){
		const tempUser = this._getTempUser();

		if (!tempUser) {
			return;
		}

		this.handleGenerateLink(tempUser);
	}


	private handleCopyToClipboard = async (link: string | null) =>
	{
		try {
			const anyNavigator = window.navigator;
			if (link) await anyNavigator.clipboard.writeText(link);
		} catch (Exception) {

		}
	}


	private setOperatorNameInputChange = (e: any) => {
		if (!this.state.isModifying && this.state.localItem.expertName === this.props.userInfo.userName)
			this.setState({ isOperatorNameInputDisabled: false });
		else
			this.props.addToast({ type: ToastType.Error, msg: GetLangString("Ticket.OperatorNameChange") });
	}

	private async getExportTicketVideoPdfUrl(): Promise<string> {
		const { ticket } = this.props;
		for (let i = 0; i < ticket.ticketEvent.length; i++) {
			if (ticket.ticketEvent[i].type === TicketItemType.VideoCapturedEnded && ticket.ticketEvent[i].linkedObject !== undefined) {
				var file: IFile = ticket.ticketEvent[i].linkedObject;
				const response = await fetch(file.realFileUri + ".mp4");
				const uri = await response.text();
				this.setState({ videoUrl: uri });

			}
		}
		return `api/Tickets/exportVideoPdf/${this.props.ticket.id}?isImageRequired=${true}&currentLocalTimeString=${moment().format("DD/MM/YYYY HH:mm")}`;
	}

	private getExportTicketUrl = async (isImageRequired: boolean) => {
		try {
			const lang: string = getCurrentLanguage();
			const tokenResponse: AccessTokenResponse = await authProvider.getAccessToken();
			const token: string = tokenResponse.accessToken;
			const response = await fetch(`${process.env.REACT_APP_LOCALHOST}api/Tickets/exportTicket/${this.props.ticket.id}?isImageRequired=${isImageRequired}&lang=${lang}&currentLocalTimeString=${moment().format("DD/MM/YYYY HH:mm")}`, { method: 'get', headers: new Headers({ "Authorization": `Bearer ${token}` }) });
			const blobFile: Blob = await response.blob();
			const fileUrl = URL.createObjectURL(blobFile);
			
			setTimeout(() => window.open(fileUrl));
		} catch (error) {
			console.log(error);
		}
	}

	private getExportTicketCsvUrl = async () => {
		try {
			const lang: string = getCurrentLanguage();
			const tokenResponse: AccessTokenResponse = await authProvider.getAccessToken();
			const token: string = tokenResponse.accessToken;
			const response = await fetch(`${process.env.REACT_APP_LOCALHOST}api/Tickets/exportTicketCsv/${this.props.ticket.id}?lang=${lang}`, { method: 'get', headers: new Headers({ "Authorization": `Bearer ${token}` }) });
			const blobFile: Blob = await response.blob();
			const fileUrl = URL.createObjectURL(blobFile);
			
			setTimeout(() => window.open(fileUrl));
		} catch (error) {
			console.log(error);
		}
	}

	private renderActionButton = () => {
		const { isSaving } = this.state;

		if (isSaving) {
			return (
				<div className="button-container">
					<Spinner />
				</div>
			);
		}

		return (
			<div className="button-container">
				{!this.shouldSaveButtonBeDisabled() && <IconButton onClick={this.handleSave} iconName={"save"} disabled={this.shouldSaveButtonBeDisabled()} />}
				{!this.shouldSaveButtonBeDisabled() && <IconButton onClick={this.handleCancelChanges} iconName={"close"} disabled={this.shouldSaveButtonBeDisabled()} />}
				{/*<IconButton href={this.getExportTicketUrl()} onClick={this.handleExport} iconName={"vertical_align_bottom"} disabled={false}/>*/}
			</div>
		);
	};

	private shouldSaveButtonBeDisabled = (): boolean => {
		if (this.state.isSaving) {
			return true;
		}

		const diff = objectDiff(this.state.localItem, this.props.ticket);

		if (diff.length !== 0) {
			return false;
		}

		return true;
	};



	private handleChange = (fieldName: keyof ITicket) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			localItem: {
				...this.state.localItem,
				[fieldName]: e.target.value,
			},
		});


	};

	private handleTextChange = (fieldName: keyof ITicket) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			localItem: {
				...this.state.localItem,
				[fieldName]: e.target.value,
			},
			temporaryString: e.target.value
		});
	};

	private changeStatusTicketEvent = async () => {

		const currentStatus = this.state.localItem.status;
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.TicketStatusChanged,
				data: currentStatus,

			};
			const response = await serverManager.tickets.postTicketEvent(this.props.ticket.id, newEvent);
			logger.info(response);
		} catch (error) {
			// this.errorMessage(error);
		}
	};

	private handleSave = async () => {
		if (this.state.temporaryString !== "" && this.state.fixedValue !== this.state.localItem.description) {
			var descriptionAndDate = `${this.state.fixedValue}${this.state.localItem.description} \n[${GetLangString("LastModification")}: ${new Date()}]\n`;
			this.setState({ localItem: { ...this.state.localItem, "description": descriptionAndDate }, fixedValue: descriptionAndDate, temporaryString: "" })
		}
		await this.setState({ isSaving: true });
		const updateTicket: IUpdateTicket = {
			id: this.state.localItem.id,
			description: this.state.localItem.description,
			status: this.state.localItem.status,
			operatorName: this.state.localItem.operatorName
		}

		await this.changeStatusTicketEvent();

		await this.props.saveTicket(updateTicket, this.validation);
		await this.setState({ isSaving: false, isModifying: false, isOperatorNameInputDisabled: true });
	};

	private handleExport = async () => {
		await this.props.exportTicket(this.state.localItem.id.toString());
	};

	private validation = (msgId: string, valid: boolean) => {
		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId });
			return;
		}

		this.props.addToast({ type: ToastType.Error, msgId });
	};

	private handleCancelChanges = () => {
		this.setState({ localItem: this.props.ticket });
	};
}

const mapStateToProps = (state: IRootState) => ({
	userInfo: state.userInfo.data,
	tickets: toArray(state.tickets.data),
	users: toArray(state.users.data),
	authCodes: state.companies.authCodes,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getUsersByCompany: actions.getUsersByCompany,
			getBearerToken: actions.getBearerToken,
			createTempUser: actions.createTempUser,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const TicketInfo = connect(mapStateToProps, mapDispatchToProps)(withToasts(TicketInfoBase));
