import accepts from "attr-accept";
import { serverManager } from "../managers/ServerManager";
import { IFile } from "../types";

export function acceptedExtension(file: File, extensions: string[]) {
	const splitOnDot = file.name.split(".");
	const fileExtension = splitOnDot[splitOnDot.length - 1];

	if (extensions.indexOf(fileExtension.toLowerCase()) !== -1) {
		return true;
	}

	return false;
}

export function getDataTransferItems(event: React.DragEvent<HTMLDivElement>) {
	if (event.dataTransfer) {
		const dt = event.dataTransfer;

		if (dt.files && dt.files.length) {
			return Array.from(dt.files);
		} else if (dt.items && dt.items.length) {
			return Array.from(dt.items);
		}

		throw new Error("No dataTransfer items or files found.");
	}
	return [];
}

export function getDataTransferItemsOnInput(
	event: React.ChangeEvent<HTMLInputElement>
) {
	if (event.currentTarget.files) {
		const dt = event.currentTarget;
	
		console.log("[debug] dt length " + dt.files!.length);

		if (dt.files && dt.files !== null && dt.files.length) {
			return Array.from(dt.files);
		}

		throw new Error("No dataTransfer items or files found.");
	}
	return [];
}

export function fileAccepted(file: File, accept: string[]) {
	return accepts(file, accept);
}

export function allFilesAccepted(files: File[], accept: string[]) {
	return files.every((file: File) => fileAccepted(file, accept));
}

export function fileMatchSize(file: File, maxSize: number, minSize: number) {
	return file.size <= maxSize && file.size >= minSize;
}

export function isFileWithinSizeLimit(file: File) {
	
	//return file.size <= maximumSizeLimit;
	//TODO make this an azure setting - was 25000000
}

export function fileValidator(
	fileList: File[],
	validator: (file: File) => boolean
): {
	acceptedFiles: File[];
	rejectedFiles: File[];
} {
	const isFileAccsepted = (file: File) => validator(file);
	const isFileRejected = (file: File) => !validator(file);
	
	const acceptedFiles = fileList.filter(isFileAccsepted);
	const rejectedFiles = fileList.filter(isFileRejected);

	console.log('[babana] acceptedFiles.length: ' + acceptedFiles.length);
	console.log('[babana] rejected: ' + rejectedFiles.length);

	return { acceptedFiles, rejectedFiles };
}

export function bytesToKiloBytes(bytes: number, decimals: number = 2) {
	const kb = bytes / 1024;
	const asString = kb.toFixed(decimals);
	return asString;
}

export function formatKilobytes(bytes: number) {
	var result = `${bytesToKiloBytes(bytes)} KB`;
	if (bytes >= 1024 * 1024) {
		result = `${bytesToKiloBytes(bytes / 1024)} MB`;
	}
	return result;
}

export enum FileTypes {
	IMAGE = "image",
	VIDEO = "video",
	PDF = "pdf",
	UNKNOWN = "unknown",
	GLB = "model",
	FBX = "model"
}

export enum FileExtension {
	PNG = ".png",
	JPG = ".jpg",
	JPEG = ".jpeg",
	PDF = ".pdf",
	UNKNOWN = ".unknown",
	GLB = ".glb",
	FBX = ".fbx",
	MP4 = ".mp4",
}

export function getFileType(file: IFile): FileTypes {
	const [filetypeString] = file.fileUri.split(":");

	switch (filetypeString) {
		case FileTypes.IMAGE:
			return FileTypes.IMAGE;
		case FileTypes.VIDEO:
			return FileTypes.VIDEO;
		case FileTypes.PDF:
			return FileTypes.PDF;
		case FileTypes.GLB:
			return FileTypes.GLB;
		case FileTypes.FBX:
			return FileTypes.FBX;
		default:
			return FileTypes.UNKNOWN;
	}
}

export function getFileExtension(file: IFile): FileExtension {
	const values = Object.values(FileExtension)
	var result = FileExtension.UNKNOWN;

	values.forEach(x => {
		if (file.name.toLowerCase().endsWith(x)){
			result = x;
		}
	})

	return result;
}
