import { AxiosInstance, AxiosResponse } from "axios";
import { IFile, IProgress, IClip, IClips, Id } from "../../types";
import { logger } from "../../utilities/logger";

export class FilesManager {
	private agent: AxiosInstance;
	private url: string = "Files";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}
	public getAllFiles = () => this.agent.get<IFile[]>(`${this.url}/GetAllFiles`);
	public getBlobUrl = (fileId: string) => `${this.agent.defaults.baseURL}blob/${fileId}`;
	public getById = async (fileId: string) => this.agent.get<IFile>(`${this.url}/${fileId}`);
	public getPdfFile = async (fileId: string) => this.agent.get<IFile>(`${this.url}/${fileId}/GetPdfFileForHololens`);
	public get = async (page: number, searchString: string) => this.agent.get<IFile[]>(`${this.url}?page=${page}&search=${searchString}`);
	public getByCompanyId = async (companyId: string, page: number, searchString: string) => this.agent.get<IFile[]>(`${this.url}/GetFilesByCompanyId?companyId=${companyId}&page=${page}&search=${searchString}`);
	public getWeight = async () => this.agent.get<number>(`${this.url}/GetFilesWeight`);
	public getMaxWeight = async () => this.agent.get<number>(`${this.url}/GetMaximumSizeLimit`);
	public block = async (UserId: string) => this.agent.put<string>(`${this.url}/BlockUser?userId=${UserId}`);
	public updateFile = async (file: IFile) => this.agent.put<IFile>(`${this.url}/${file.id}`, file);
	public delete = async (fileIds: string[]) => {
		const separator = "|";
		const fileIdsString = fileIds.join(separator);
		this.agent.delete(`${this.url}/${fileIdsString}/separator/${separator}`);
	};
	//public delete = async (fileIds: string[]) => {
	//	const promises = fileIds.map(fileId => this.agent.delete(`${this.url}/${fileId}`));
	//	return Promise.all(promises);
	//};
	public uploadFileThumbnail = async (modelId: Id, thumbnail: File) => {
		const createRequest = (item: File) => {
			const formData = new FormData();
			formData.append("thumbnail", item, item.name);
			const response = this.agent.post<IFile>(`${this.url}/UploadThumbnail?fileId=${modelId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			});
			return response;
		}
		const response = await createRequest(thumbnail);
		//const response = await request;

		return response.data;
	}
	
	
	
	public setOfficial = async (fileId: string) => this.agent.put(`${this.url}/SetFileOfficial?fileInfoId=${fileId}`);
	public setClipsData = async (dto: IClips) => this.agent.post<IClips>(`${this.url}/SetModelClipsData`, dto);
	public getClipsData = async (fileId: string) => this.agent.get<IClips>(`${this.url}/GetModelClipsData?fileId=${fileId}`);
	public getMaximumSizeLimit = async () => this.agent.get<number>(`${this.url}/GetMaximumSizeLimit`);
	public upload = async (filesForUploading: File[], progressLogger: (progress: IProgress) => void, hidden?: boolean) => {
		const createPostRequest = (item: File) => {
			const formData = new FormData();
			formData.append("file", item, item.name);
			const request = this.agent.post<IFile>(`${this.url}/uploadFile?hidden=${hidden || false}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (progressEvent: ProgressEvent) => {
					console.log("File upload progress", item.name, progressEvent.loaded / progressEvent.total);
					progressLogger({ file: item, uploaded: progressEvent.loaded / progressEvent.total, id: URL.createObjectURL(item) });
					console.log("File upload progress", item.name, progressEvent.loaded / progressEvent.total);
					logger.debug("File upload progress", item.name, progressEvent.loaded / progressEvent.total);
				},
			});
			return request;
		};
		const requests = filesForUploading.map(createPostRequest);

		const getData = (all: Array<AxiosResponse<IFile>>) => all.map(res => res.data);
		const response = await Promise.all(requests);
		return getData(response);
	};
}
