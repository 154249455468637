import React from "react";
import { classNames } from "../../utilities/class-names";
import { OriginalHtmlProps } from "../../utilities/html-props";
import { Icon } from "../globalComponents";
import "./CompanyListItem.scss";
import { ICompany } from "../../types";
import moment from "moment";
import { ILicenseNumber } from "../../types/ILicense";

type IProp = {
	company: ICompany;
	licenses: ILicenseNumber[];
} & OriginalHtmlProps<HTMLDivElement>;

export const CompanyListItem: React.FunctionComponent<IProp> = ({ className, company, licenses, ...rest }) =>
{
	//const isActive = moment() <= moment(company.expirationDate) && moment() >= moment(company.creationDate);

	const isActive = licenses.find(license => moment() <= moment(license.endDate) && moment() >= moment(license.startDate));

	return (
		<div className={classNames(className, "company-item-row")} {...rest}>
			<Icon name={isActive ? "business" : "domain_disabled"} />
			<span className={isActive ? "company-item-row-name" : "company-item-row-name-inactive"}>{company.officialName}</span>
		</div>
	);
};
