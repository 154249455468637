import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { ITroubleshootingLanguage } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./troubleshooting-languages.actions";

export interface IState {
	loading: boolean;
	error: boolean;
	data: IDictionary<ITroubleshootingLanguage>;
	selectedLanguageIds: string[];
}

export const initialState: IState = {
	loading: false,
	error: false,
	data: {},
	selectedLanguageIds: [],
};

function handleToggleSelectLanguage(selectedLanguageIds: string[], languageId: string) {
	if (selectedLanguageIds.indexOf(languageId) !== -1) {
		return selectedLanguageIds.filter(i => i !== languageId);
	}

	return [...selectedLanguageIds, languageId];
}

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.toggleSelectLanguage):
			return {
				...state,
				selectedLanguageIds: handleToggleSelectLanguage(state.selectedLanguageIds, action.payload),
			};
		case getType(actions.fetch):
			return {
				...state,
				loading: true,
			};
		case getType(actions.fetchError):
			return {
				...state,
				loading: false,
				error: true,
			};
		case getType(actions.fetchSuccess):
			return {
				...state,
				loading: false,
				error: false,
				data: makeDictionary(action.payload, "id"),
				// Select first language if any in result.
				selectedLanguageIds: action.payload.length ? [action.payload[0].id] : [],
			};
		default:
			return state;
	}
};
