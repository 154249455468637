import React from "react";
import Alert from "reactstrap/lib/Alert";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { bindActionCreators, Dispatch } from "redux";
import { Icon } from ".";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers/root-reducer";
import { IFile, IProgress } from "../../types";
import {
	acceptedExtension,
	bytesToKiloBytes,
	classNames,
	delay,
	fileAccepted,
	fileMatchSize,
	fileValidator,
	getDataTransferItems,
	getDataTransferItemsOnInput,
	IDictionary,
	OriginalHtmlProps,
	isFileWithinSizeLimit,
} from "../../utilities";
import { logger } from "../../utilities/logger";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { actions as filesActions } from "../../reducers/files";
import "./Dropzone.scss";
import { connect } from "react-redux";
import { convertImageToFile } from "../active-call/ActiveCallUtils";
import { serverManager } from "../../managers/ServerManager";

export interface IDropzoneProps extends OriginalHtmlProps<HTMLDivElement>, InjectedToastsProps, ConnectProps {
	isCompanyNotSubordinate?: boolean;
	isFileUploaded?: (isUploaded: boolean) => void;
	onFileUploaded: (file: IFile) => void;
	onFileDropInCall?: () => void;
	onFileRejected?: () => void;
	uploader: (files: File[], progressCallback: (progress: IProgress) => void) => Promise<IFile[]>;
	validFileTypes: string[];
	validFileExtensions: string[];
}

export interface IDropzoneState {
	progressMap: IDictionary<number>;
	isDropping: boolean;
	acceptedFiles: File[];
	rejectedFiles: File[];
}

class DropzoneBase extends React.Component<IDropzoneProps, IDropzoneState> {
	public input = React.createRef<HTMLInputElement>();
	public state: IDropzoneState = {
		isDropping: false,
		progressMap: {},
		acceptedFiles: [],
		rejectedFiles: [],
	};

	public render() {
		const { addToast, className, onFileUploaded, validFileTypes, uploader, validFileExtensions, isCompanyNotSubordinate, ...restProps } = this.props;
		return (
			<React.Fragment>
				{console.log(isCompanyNotSubordinate)}
				{(isCompanyNotSubordinate === undefined || isCompanyNotSubordinate) ? (
					<div {...restProps} className={classNames("app-dropzone-outer", className)}>
						<div
							className={this.getContainerClassNames()}
							onDrop={this.handleDrop}
							onDragEnter={this.handleOnDragEnter}
							onDragLeave={this.handleOnDragLeave}
							onDragOver={this.handleOnDragOver}
							style={{ height: "80px" }}
						>
							{this.renderDropzoneContent()}
						</div>
					</div>
				) : (
					<React.Fragment></React.Fragment>)}
				<input type="file" style={{ display: "none" }} onChange={this.handleInputChange} ref={this.input} multiple={true} />
				{this.renderRejectedFiles()}
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.props.getFilesMaximumSizeLimitUpload();
	}

	private handleUploadFileClick = () => {
		if (this.props.isFileUploaded)
			this.props.isFileUploaded(false);
		if (this.input.current) {
			this.input.current.click();
		}
	};

	private handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();

		var droppedFiles : File[];

		try{
			droppedFiles = getDataTransferItemsOnInput(e);
			const { acceptedFiles, rejectedFiles } = await fileValidator(droppedFiles as File[], this.fileValidator);
	
			await this.handleRejectedFiles(rejectedFiles);
			await this.handleAcceptedFiles(acceptedFiles);

		}
		catch(err){
			console.log("[debug] dt error " + err);
		}
	};

	private renderRejectedFiles = () => {
		const { rejectedFiles, acceptedFiles } = this.state;
		if (rejectedFiles.length === 0) {
			return null;
		}
		return (
			<div className="app-dropzone-rejected-files">
				{rejectedFiles.map(rejected => (
					<Alert color="danger" key={rejected.name}>
						{rejected.name} <T v="DocumentationDetails.Rejected" />
					</Alert>
				))}
			</div>
		);
	};

	private renderDropzoneContent = () => {
		const { isDropping, acceptedFiles } = this.state;

		if (acceptedFiles.length > 0) {
			return <Row style={{ overflowY: "auto", height: "100%" }}>{acceptedFiles.map(this.renderFilePreview)}</Row>;
		}

		if (isDropping) {
			return (
				<div className="app-dropzone-info">
					<Icon name="image" />
					<T v="Upload.Drop" />
				</div>
			);
		}

		return (
			<div className="app-dropzone-info" onClick={this.handleUploadFileClick} style={{ cursor: "pointer" }}>
				<Icon name="cloud_upload" />
				<T v="Upload.Drag" />
			</div>
		);
	};

	private getContainerClassNames = () => {
		const { isDropping } = this.state;
		const base = "app-dropzone-container";

		if (isDropping) {
			return classNames(base, "is-dropping");
		}

		return base;
	};

	private handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		if (this.props.onFileDropInCall) {
			this.props.onFileDropInCall();
		}
		e.preventDefault();
		this.setState({ isDropping: false });

		const droppedFiles = getDataTransferItems(e);
		const { acceptedFiles, rejectedFiles } = fileValidator(droppedFiles as File[], this.fileValidator);
		await this.handleRejectedFiles(rejectedFiles);
		await this.handleAcceptedFiles(acceptedFiles);
	};

	private handleRejectedFiles = async (rejectedFiles: File[]) => {
		if (this.state.acceptedFiles.length === 0) {
			if (this.props.onFileRejected) {
				this.props.onFileRejected();
			}
		}

		return this.setState({ rejectedFiles });
	};

	private handleAcceptedFiles = async (newFiles: File[]) => {
		try {
			const progressMap = newFiles.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {});
			const acceptedFiles = [...this.state.acceptedFiles, ...newFiles];
			await this.setState({ acceptedFiles, progressMap });
			const response = await this.props.uploader(newFiles, this.progressCallback);
			if (this.props.isFileUploaded) {
				this.props.isFileUploaded(true);
			}

			for (const file of response) {
				this.props.onFileUploaded(file);

				if (file.name.includes(".mp4")) {
					await this.getVideoThumbnail(file);
				}
			}

			const newAcceptedFiles = this.state.acceptedFiles.filter(this.isFileUploading);
			await this.setState({ acceptedFiles: newAcceptedFiles });
		} catch (err) {
			logger.error("Some kind of error");
			this.props.addToast({ type: ToastType.Error, msgId: "DocumentationDetails.UploadErrorSizeFile" });
			const fileNames = newFiles.map(i => i.name);
			const acceptedFiles = this.state.acceptedFiles.filter(i => fileNames.indexOf(i.name) === -1);
			this.setState({ acceptedFiles });
		}
	};

	private getVideoThumbnail = async (file: IFile) => {
		const video = document.createElement('video');
		video.width = 720;
		video.height = 480;
		video.setAttribute("crossOrigin", "anonymous");
		video.onloadeddata = () => {
			const thumbCanvas = document.createElement("canvas");
			thumbCanvas.width = video.width;
			thumbCanvas.height = video.height;

			const thumbCanvasContext = thumbCanvas.getContext('2d');

			if (thumbCanvasContext) {
				thumbCanvasContext.drawImage(video, 0, 0, thumbCanvas.width, thumbCanvas.height);

				thumbCanvas.toBlob(async (blob: Blob | null) => {
					if (!blob)
						return;

					const videoFile = convertImageToFile(blob, "video_thumbnail");
					const updatedFile = await serverManager.files.uploadFileThumbnail(file.id, videoFile);
					this.props.uploadFileThumbnailSuccess(updatedFile);
				});
			}
		};
		video.src = `${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`;
	};

	private isFileUploading = (file: File) => {
		const idx = this.state.acceptedFiles.findIndex(f => f.name === file.name);

		if (idx !== -1) {
			return false;
		}

		return true;
	};

	private progressCallback = (progress: IProgress) => {
		this.setState({ progressMap: { ...this.state.progressMap, [progress.file.name]: progress.uploaded } });
	};

	private fileValidator = (file: File) => {
		const { validFileTypes, validFileExtensions } = this.props;
		logger.debug(file);
		const isValidFileType = fileAccepted(file, validFileTypes);
		const isValidFileExtension = acceptedExtension(file, validFileExtensions);
		const isValidTypeOrExtension = isValidFileExtension || isValidFileType;
		//let isBelowMaxFileSize = file.size < this.props.maxUploadWeight; // File weight control / file size control / fileweight / filesize
		const isAccepted = isValidTypeOrExtension //&& isBelowMaxFileSize;
		return isAccepted;
	};

	private handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
	};

	private handleOnDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
		if (this.props.isFileUploaded) {
			this.props.isFileUploaded(false);
		}
	};

	private handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: false });
	};

	private getThumbnail = (file: File) => {
		const url = URL.createObjectURL(file);

		switch (file.type) {
			case "image/png":
				return <img src={url} className="app-dropzone-file-preview-thumbnail-img" />;
			case "application/pdf":
				return this.renderThumbnailIcon();
			default:
				return this.renderThumbnailIcon();
		}
	};

	private renderThumbnailIcon = () => (
		<div className="app-dropzone-file-preview-thumbnail-icon">
			<Icon name="file_copy" />
		</div>
	);

	private renderFilePreview = (file: File) => (
		<Col md={4} key={file.name}>
			<div className="app-dropzone-file-preview">
				<div className="app-dropzone-file-preview-thumbnail">
					{this.getThumbnail(file)}
					<div style={this.getProgressStyle(file)} className="app-dropzone-file-preview-progress" />
				</div>
				{/* <div className="app-dropzone-file-preview-body">
					<div className="app-dropzone-file-preview-filename">{file.name}</div>
					<div className="app-dropzone-file-preview-filesize">{bytesToKiloBytes(file.size)}kb</div>
				</div> */}
			</div>
		</Col>
	);

	private getProgressStyle = (file: File) => {
		const width = this.state.progressMap[file.name] * 100 + "%";
		const style: React.CSSProperties = {
			width,
		};
		return style;
	};
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
	company: state.company.data.company,
	maxUploadWeight: state.files.maxFileUploadWeight,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getFilesMaximumSizeLimitUpload: filesActions.getFilesMaximumSizeLimitUpload,
			uploadFileThumbnailSuccess: filesActions.uploadFileThumbnailSuccess,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const Dropzone = connect(mapStateToProps, mapDispatchToProps)(withToasts(DropzoneBase));
