import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { DEV_USER_ROLE } from "../../constants";
import { serverManager } from "../../managers/ServerManager";
import { IUser } from "../../types";
import { IS_DEVELOPMENT, IS_PRODUCTION_DEBUG, logger } from "../../utilities/logger";
import { getCookieValue } from "../call-queue/call-queue";
import { actions } from "./user-info.actions";

export function* handleGetUserInfo(action: ReturnType<typeof actions.getUserInfo>) {
	try {
		const response: AxiosResponse<IUser> = yield call(serverManager.users.getMe);
		yield put(actions.getUserInfoSuccess(response.data));

		if (IS_DEVELOPMENT || IS_PRODUCTION_DEBUG) {
			const devUserRole = window.localStorage.getItem(DEV_USER_ROLE);

			if (devUserRole) {
				yield call(logger.debug, "[localStorage] Loaded UserRole from localStorage.");
				yield put(actions.changeUserRole(+devUserRole));
			}
		}
	} catch (err) {
		const errString = "something went wrong while fetching userinfo.";
		logger.error("[handleGetUserInfo]", err);
		yield put(actions.getUserInfoError(errString));
	}
}

export function* handleChangeUserRole(action: ReturnType<typeof actions.changeUserRole>) {
	try {
		window.localStorage.setItem(DEV_USER_ROLE, action.payload.toString());
		yield call(logger.debug, "[localStorage] UserRole saved in localStorage.");
	} catch (err) {
		logger.error("Error while persisting selected userRole to localStorage.");
	}
}

export function* userInfoSaga() {
	yield takeLatest(getType(actions.getUserInfo), handleGetUserInfo);
	yield takeLatest(getType(actions.changeUserRole), handleChangeUserRole);
}
