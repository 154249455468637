import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { serverManager } from "../../managers/ServerManager";
import { ICompany } from "../../types";
import { logger } from "../../utilities/logger";
import { actions } from "./company.actions";

export function* handleGetMyCompany() {
	try {
		logger.debug("Fetching your own company");
		const { data }: AxiosResponse<ICompany> = yield call(serverManager.companies.getMyCompany);
		console.log(`[foo] company logo: ${data}`);
		yield put(actions.getMyCompanySuccess(data));
		logger.debug(data.name, "company fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getMyCompanyError("Something went wrong while fetching companies."));
	}
}

export function* companySaga() {
	yield takeLatest(getType(actions.getMyCompany), handleGetMyCompany);
}
