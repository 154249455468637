import React from "react";
import { classNames, OriginalHtmlProps } from "../../utilities";
import "./MobileImage.scss";

export interface IMobileImageProps extends OriginalHtmlProps<HTMLDivElement> {
	height: number;
	imgSrc: string;
}

export const MobileImage: React.FunctionComponent<IMobileImageProps> = ({ className, imgSrc, height, width, style, ...rest }) => (
	<div {...rest} style={{ ...style, minHeight: height, width, height }} className={classNames("app-stretched-image", className)}>
		<img src={imgSrc} />
	</div>
);
