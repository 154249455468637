import * as React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions, selectProduct } from "../../reducers/products";
import { actions as userActions } from "../../reducers/users";
import { Id } from "../../types";
import { logger } from "../../utilities/logger";
import { ErrorPage } from "../globalComponents";
import { LoadingPage } from "../globalComponents/LoadingPage";
import { ItemDetailDisplay } from "./ItemDetailDisplay";
import { ItemImageDisplay } from "./ItemImageDisplay";
import "./Product.scss";
import { toArray } from "../../utilities";
import { ITeam } from "../../types/ITeam";

interface IOwnProps {
	id: Id;
	activeTab: string;
}

interface IProps extends ConnectProps, IOwnProps { }

interface IProductState {
	activeTab: string;
}


export class ProductComponent extends React.Component<IProps, IProductState> {

	public state: IProductState = {
		activeTab: this.props.activeTab,
	};

	componentDidMount() {
		logger.debug("ProductComponent mounted.");
		this.props.getSingleProduct(this.props.id.toString());
		this.props.getTeams();
		
	}

	public handleTabToggle = (tab: string) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	public render() {
		const isCompanyNotSubordinate = this.props.company && this.props.company.mainCompanyId === null;
		const { activeTab } = this.state;
		const { loading, error, product, saving, notFound, teams } = this.props;

		if (loading) {
			return <LoadingPage />;
		}

		if (notFound) {
			return (
				<ErrorPage>
					<T v={"ProductsProperties.NotFound"} />
				</ErrorPage>
			);
		}

		if (error || !product) {
			return (
				<Container fluid>
					<Row>
						<T v={"Error"} />
					</Row>
				</Container>
			);
		}

		return (
			<div className="product-container">
				<ItemDetailDisplay
					isCompanyNotSubordinate={isCompanyNotSubordinate}
					isSaving={saving}
					onSaveClicked={this.props.updateProduct}
					key={1}
					tabToggle={this.handleTabToggle}
					activeTab={activeTab}
					item={product}
					saveError={error}
					role={this.props.role}
					teams={!this.props.isInTeam ? this.props.teams : []}
				/>
				<div style={{ width: "30%" }} className="hide-mobile">
					<ItemImageDisplay isCompanyNotSubordinate={isCompanyNotSubordinate} onFileUploaded={() => this.props.onThumbnailUpdated(this.props.product.id)} handleDeleteImage={(productId: string) => this.props.deleteThumbnail(productId)} key={2} item={product} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
	company: state.company.data.company,
	loading: state.products.loading,
	error: state.products.error,
	notFound: state.products.notFound,
	product: selectProduct(state, ownProps.id),
	saving: state.products.saving,
	role: state.userInfo.data.userRole,
	isInTeam: state.userInfo.data.teamId != null && state.userInfo.data.teamId != "",
	teams: toArray(state.users.teamsData),
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getSingleProduct: actions.getSingleProduct,
			updateProduct: actions.updateProduct,
			onThumbnailUpdated: actions.onThumbnailUpdated,
			deleteThumbnail: actions.deleteProductThumbnail,
			getTeams: userActions.getTeams,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const Product = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductComponent);
