import { defaultTo, merge, omit } from "ramda";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { IProduct } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./products.actions";

export interface IState {
	data: IDictionary<IProduct>;
	isDeleting: IDictionary<boolean>;
	referenceLoading: IDictionary<string[]>;
	error: boolean;
	notFound: boolean;
	loading: boolean;
	saving: boolean;
}

export const initialState: IState = {
	data: {},
	isDeleting: {},
	referenceLoading: {},
	error: false,
	notFound: false,
	loading: false,
	saving: false,
};

export const reducer: Reducer<IState> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.removeReference):
			return {
				...state,
				referenceLoading: {
					...state.referenceLoading,
					[action.payload.productId]: [...defaultTo([], state.referenceLoading[action.payload.productId]), action.payload.fileId],
				},
			};
		case getType(actions.removeReferenceSuccess):
			return {
				...state,
				referenceLoading: {
					...state.referenceLoading,
					[action.payload.productId]: state.referenceLoading[action.payload.productId].filter(i => i !== action.payload.fileId),
				},
				data: {
					...state.data,
					[action.payload.productId]: {
						...state.data[action.payload.productId],
						reference: state.data[action.payload.productId].reference.filter(i => i !== action.payload.fileId),
					},
				},
			};
		case getType(actions.removeReferenceError):
			return {
				...state,
				error: true,
			};
		case getType(actions.addReference):
			return {
				...state,
				referenceLoading: {
					...state.referenceLoading,
					[action.payload.productId]: [...defaultTo([], state.referenceLoading[action.payload.productId]), action.payload.fileId],
				},
			};
		case getType(actions.addReferenceError):
			return {
				...state,
				error: true,
			};
		case getType(actions.addReferenceSuccess):
			return {
				...state,
				referenceLoading: {
					...state.referenceLoading,
					[action.payload.productId]: state.referenceLoading[action.payload.productId].filter(i => i !== action.payload.fileId),
				},
				data: {
					...state.data,
					[action.payload.productId]: {
						...state.data[action.payload.productId],
						reference: [...state.data[action.payload.productId].reference, action.payload.fileId],
					},
				},
			};
		case getType(actions.deleteProduct):
			return {
				...state,
				isDeleting: {
					...state.isDeleting,
					[action.payload]: true,
				},
			};
		case getType(actions.deleteProductSuccess):
			return {
				...state,
				isDeleting: omit([action.payload], state.isDeleting),
				data: omit([action.payload], state.data),
			};
		case getType(actions.createProduct):
			return {
				...state,
				saving: true,
			};
		case getType(actions.createProductError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.createProductSuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.updateProductError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.updateProduct):
			return {
				...state,
				saving: true,
			};
		case getType(actions.updateProductSuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: merge(state.data[action.payload.id], action.payload),
				},
			};
		case getType(actions.getProducts):
		case getType(actions.getProductsByCompanyId):
		case getType(actions.getSingleProduct):
			return {
				...state,
				notFound: false,
				loading: true,
			};
		case getType(actions.deleteProductThumbnailSuccess):
			state.data[action.payload].thumbnailUrl = "";
			return {
				...state,
				data: {
					...state.data,
					[action.payload]: state.data[action.payload],
				}
			};
		case getType(actions.getProductsByCompanyIdSuccess):
		case getType(actions.getProductsSuccess):
			return { ...state, data: { ...state.data, ...makeDictionary(action.payload, "id") }, loading: false, notFound: false };
		case getType(actions.getSingleProductSuccess):
			return {
				...state,
				notFound: false,
				loading: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.getProductsError):
		case getType(actions.getProductsByCompanyIdError):
		case getType(actions.getSingleProductError):
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};
