import { DateString, Id, ReferanceURL } from "./helpers";

export interface ITicketCreate {
	operatorId: Id;
	ticketEvents: ITicketItem[];
}

export interface ITicketItem {
	id?: Id;
	type: TicketItemType;
	date?: DateString;
	url?: ReferanceURL;
	data?: ReferanceURL | number;
	linkedObject?: any;
}

export enum TicketItemType {
	TicketCreatedSuccess = "TicketCreatedSuccess",
	DocumentUploaded = "DocumentUploaded",
	Login = "Login",
	OperatorPosition = "OperatorPosition",
	ProductListDownload = "ProductListDownload",
	ProductDownload = "ProductDownload",
	FileOpen = "FileOpen",
	ModelOpen = "ModelOpen",
	TroubleshootingTreeOpen = "TroubleshootingTreeOpen",
	TroubleshootingTreeReset = "TroubleshootingTreeReset",
	TroubleshootingTreeClose = "TroubleshootingTreeClose",
	Enqueue = "Enqueue",
	AnswerCall = "AnswerCall",
	EndedCall = "EndedCall",
	FileSent = "FileSent",
	AnnotationSent = "AnnotationSent",
	AnnotationDeleted = "AnnotationDeleted",
	ImageCaptured = "ImageCaptured",
	VideoCapturedStarted = "VideoCapturedStarted",
	VideoCapturedEnded = "VideoCapturedEnded",
	SessionEnded = "SessionEnded",
	ParticipantJoin = "ParticipantJoin",
	ModelPosition = "3D model repositioned",
	ProductChanged = "Product changed",
	TicketStatusChanged = "TicketStatusChanged",
	CallPublished = "Requested help from another expert",
	CallPublishedTeam = "Requested help from another expert of the same team",
}
