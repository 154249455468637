import jsQR from "jsqr";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { Button } from "reactstrap";
import { delay } from "redux-saga/effects";
import { IQrModalProps } from "./QrModal";

export interface IQrVideoProps extends IQrModalProps {
    width: number;
}

interface ICoordinates { x: number, y: number }

export const QrVideo: React.FunctionComponent<IQrVideoProps> = ({ products, width, toggle }) => {

    let canvas: CanvasRenderingContext2D;
    const [route, setRoute] = useState<string>("")
    const [text, setText] = useState<string>("")
    const [redirect, setRedirect] = useState<boolean>(false)

    const video = document.createElement("video") as HTMLVideoElement;
    const canvasElement = document.getElementById("canvas") as HTMLCanvasElement;

    useEffect(() => {
        if (canvasElement !== null && video !== null && redirect === false) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
                video.id = "video-view"
                video.srcObject = stream;
                video.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
                video.play();
                requestAnimationFrame(tick);
            })
            canvas = canvasElement.getContext("2d") as CanvasRenderingContext2D;
        }
        return () => {
            if (video) {
                const streamElem = video.srcObject as MediaStream;
                if (streamElem) {
                    const tracks = streamElem.getTracks() as MediaStreamTrack[];
                    tracks.forEach(track => track.stop());
                }
                video.srcObject = null;
            }
        }
    }, [canvasElement]);

    function drawLine(begin: ICoordinates, end: ICoordinates, color: string) {
        canvas!.beginPath();
        canvas!.moveTo(begin.x, begin.y);
        canvas!.lineTo(end.x, end.y);
        canvas!.lineWidth = 4;
        canvas!.strokeStyle = color;
        canvas!.stroke();
    }

    function tick() {
        let loop = true;
        if (video.readyState === video.HAVE_ENOUGH_DATA && canvasElement !== null) {
            canvasElement!.hidden = false;
            canvasElement!.height = video.videoHeight / (video.videoWidth / width);
            canvasElement!.width = video.videoWidth / (video.videoWidth / width);
            canvas!.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            const imageData = canvas!.getImageData(0, 0, canvasElement.width, canvasElement.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
            });

            if (code) {

                drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

                let i = 0;
                for (; i < products.length; i++) {
                    if (products[i].id === code.data) {
                        setRoute(`/product/${code.data}/troubleshooting`);
                        loop = false;
                        setRedirect(true);
                    } else {
                        setText(" QR code does not match any products");
                    }
                }
            }
        }
        if (loop) {
            requestAnimationFrame(tick);
        }
    }

    return (
        <div id="qr-container">
            {redirect ? <Redirect to={route} /> : <>
                <div id="qr-video" className="qr-video">
                    <canvas id="canvas" hidden></canvas>
                    {text}
                </div>
                <Button onClick={toggle} style={{ width: "100%" }} type="submit" color="primary">
                    Exit
			</Button></>
            }
        </div>
    )
}

export default QrVideo;

