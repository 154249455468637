import React from "react";
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { Id, IFile, IProduct } from "../../types";
import { Dropzone } from "../globalComponents/Dropzone";
import { ICreateToast, ToastType } from "../toast";

interface IDropzoneCreateTicketModalProps {
	addToast: (data: ICreateToast) => void;
	isOpen: boolean;
	toggle: (isCreatingTicket: boolean) => void;
	onFileUploaded: (file: IFile) => void;
	products?: IProduct[];
	getSelectedProduct?: (productName: string) => void;
}

interface IDropzoneCreateTicketModalState {
	isFileUploaded: boolean;
	selectedProduct: IProduct | undefined;
}

export class DropzoneCreateTicketModal extends React.Component<IDropzoneCreateTicketModalProps, IDropzoneCreateTicketModalState> {

	constructor(props: IDropzoneCreateTicketModalProps) {
		super(props);

		this.state = {
			isFileUploaded: false,
			selectedProduct: undefined
		}
	}

	componentDidMount() {
		//Reset dello stato per non usare file caricati precedentemente
		this.setState({ isFileUploaded: false });
		// this.props.toggle(false);
		if (this.props.getSelectedProduct && this.props.products && this.props.products[0])
			this.props.getSelectedProduct(this.props.products[0].name);
	}

	render() {
		return (
			<Modal isOpen={this.props.isOpen} toggle={() => { this.state.isFileUploaded ? this.ticketCreatedSuccessfully() : this.props.toggle(false); }} className="products-upload-document-modal">
				<ModalHeader toggle={() => { this.state.isFileUploaded ? this.ticketCreatedFailed() : this.props.toggle(false); }}>
					<T v="NavLink.UploadDocument" />
				</ModalHeader>
				{this.props.products !== undefined && <ModalBody>
					<select onChange={(event) => this.props.getSelectedProduct !== undefined ? this.props.getSelectedProduct(event.target.value) : undefined}>
						{this.props.products.map(product => {
							return <option key={product.id} value={product.name}>{product.name}</option>
						})}
					</select>
				</ModalBody>}
				<ModalBody>
					<Dropzone
						isFileUploaded={this.setIsFileUploaded}
						onFileUploaded={this.props.onFileUploaded}
						validFileTypes={["image/jpeg", "image/png"]}
						validFileExtensions={["jpg", "mp4", "pdf", "png", "fbx"]}
						uploader={serverManager.files.upload}
					/>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => { this.ticketCreatedSuccessfully() }}>
						<T v="OK" />
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	private setIsFileUploaded = (isUploaded: boolean) => {
		this.setState({ isFileUploaded: isUploaded });
	};

	private ticketCreatedSuccessfully = () => {
		this.props.toggle(true);
		this.props.addToast({ msgId: "Ticket.TicketCreatedSuccess", type: ToastType.Success });
	}
	private ticketCreatedFailed = () => {
		this.props.toggle(false);
		this.props.addToast({ msgId: "Ticket.TicketCreatedFailed", type: ToastType.Error });
	}

}