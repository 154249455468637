import { compose } from "ramda";
import * as React from "react";
import { Link } from "react-router-dom";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { UncontrolledButtonDropdown } from "reactstrap/lib/Uncontrolled";
import { PRODUCT_PLACEHOLDER_URL } from "../../constants";
import { IProduct, IUser } from "../../types";
import { UserRole } from "../../types/IRoles";
import { defaultPreventer, propagationStopper, shortenText } from "../../utilities";
import { logger } from "../../utilities/logger";
import { Icon, StretchedImage, MobileImage } from "../globalComponents";
import { SaveOverlay } from "../globalComponents/SaveOverlay";
import "./ItemDisplay.scss";
import { ITeam } from "../../types/ITeam";
import { T } from "../../managers/I18n";

export interface IItemProps {
	isCompanyNotSubordinate?: boolean;
	onClick?: (product: IProduct) => void;
	role: UserRole;
	isDeleting: boolean;
	userInfo: IUser;
	item: IProduct;
	teams: ITeam[];
	genericProductId: string | null;
	onDelete: (productId: string) => void;
}

export const ItemDisplay: React.FunctionComponent<IItemProps> = ({ isDeleting, item, teams, genericProductId, onDelete, userInfo, isCompanyNotSubordinate, onClick }) => {

	console.log("file url ItemDisplay method: " + item.thumbnailUrl);

	var thumbnailUrl = null;

	if (item.thumbnailUrl != "") {
		thumbnailUrl = `${process.env.REACT_APP_LOCALHOST}${item.thumbnailUrl}`;
	} else {
		thumbnailUrl = PRODUCT_PLACEHOLDER_URL;
	}

	let route = "";

	if (userInfo.userRole === UserRole.Operator) {
		route = `/product/${item.id}/files`; ///product/${item.id}/troubleshooting
	} else {
		route = `/product/${item.id}`;
	}

	let linkedTeams: string | undefined;

	teams.forEach(team => {
		if (team.referencedProductIds && team.referencedProductIds.includes(item.id)) {
			linkedTeams = linkedTeams ? `${linkedTeams}, ${team.name}` : `${team.name}`;
		}
	});

	return (
		<div onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick ? onClick(item) : () => { }} className={userInfo.userRole === UserRole.Operator ? "item-display-container-mobile" : "item-display-container"}>
				{(!item.isEmpty || item.id === genericProductId || userInfo.userRole >= UserRole.CompanyAdmin) ?
				<Link to={route}>
					<div className={userInfo.userRole === UserRole.Operator ? "product-card-container-mobile" : "product-card-container"}>
						{isDeleting && <SaveOverlay style={{ background: "rgba(255, 255, 255, .85)" }} text="Deleting..." />}
						{userInfo.userRole === UserRole.Operator ? (
							<MobileImage height={150} width={150} imgSrc={thumbnailUrl} />
						) : <StretchedImage height={150} imgSrc={thumbnailUrl} />}
						<div className={userInfo.userRole === UserRole.Operator ? "product-card-header-product-mobile" : "product-card-header"}>
							<div className={userInfo.userRole === UserRole.Operator ? "product-card-header-text-mobile" : "product-card-header-text"}>
								<p title={item.name}>{item.name}</p>
								<span title={item.productCode}>{item.productCode}</span>
							</div>
							<div className="product-card-header-button-container" onClick={compose(propagationStopper, defaultPreventer)}>
								{item.id !== genericProductId && userInfo.userRole >= UserRole.CompanyAdmin && (
									isCompanyNotSubordinate && (<UncontrolledButtonDropdown onClick={(e: React.MouseEvent<HTMLButtonElement>) => logger.debug(e)}>
										<DropdownToggle caret={false}>
											<Icon name="more_vert" />
										</DropdownToggle>

										<DropdownMenu>
											<DropdownItem onClick={(e: React.MouseEvent<HTMLButtonElement>) => onDelete(item.id as string)}>
												Delete
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledButtonDropdown>)
								)}
							</div>
						</div>
						{userInfo.userRole != UserRole.Operator && (<div className="product-card-description" title={item.description}>
							{shortenText(item.description, 75)}
						</div>)}
						{userInfo.userRole >= UserRole.CompanyAdmin && linkedTeams && (<div className="product-card-description">
							<T v={"Teams.listTitle"} /><span>: {linkedTeams}</span>
						</div>)}
					</div>
				</Link>

				:

				<div className={userInfo.userRole === UserRole.Operator ? "product-card-container-mobile" : "product-card-container"}>
					{isDeleting && <SaveOverlay style={{ background: "rgba(255, 255, 255, .85)" }} text="Deleting..." />}
					{userInfo.userRole === UserRole.Operator ? (
						<MobileImage height={150} width={150} imgSrc={thumbnailUrl} />
					) : <StretchedImage height={150} imgSrc={thumbnailUrl} />}
					<div className={userInfo.userRole === UserRole.Operator ? "product-card-header-product-mobile" : "product-card-header"}>
						<div className={userInfo.userRole === UserRole.Operator ? "product-card-header-text-mobile" : "product-card-header-text"}>
							<p style={{ color: "rgba(220, 220, 220, .85)" }} >{item.name}</p>
							<span>{item.productCode}</span>
						</div>
						<div className="product-card-header-button-container" />
					</div>
					<div className="product-card-description">
						{userInfo.userRole != UserRole.Operator ?
							shortenText(item.description, 75)
							: null}
					</div>
				</div>
			}
		</div>
	);
};
