import * as React from "react";
import { Col, Nav, NavItem, NavLink, Row, TabContent } from "reactstrap";
import { T } from "../../managers/I18n";
import { IProduct, ITroubleshootingFlatNode } from "../../types";
import { classNames } from "../../utilities";
import "./ItemDetailTabDisplay.scss";
import { DocumentationTab } from "./ProductsDocumentationTab";
import { ProductsTroubleshootingTab } from "./ProductsTroubleshootingTab";
import { WorkLogTab } from "./ProductsWorkLogTab";
import { QRCodeTab } from "./QRCodeTab";
import { UserRole } from "../../types/IRoles";
import { RouteComponentProps, withRouter } from "react-router";

interface ItemProps extends RouteProps {
	isCompanyNotSubordinate: boolean;
	troubleshootingList: ITroubleshootingFlatNode[];
	product: IProduct;
	tabToggle: any;
	activeTab: string;
	role: UserRole;
}

class ItemDetailTabDisplayBase extends React.Component<ItemProps> {
	public isActive(page: string): string {
		if (this.props.activeTab === page) {
			return "active";
		}
		return "";
	}

	public render() {
		return (
			<div className={"item_detail_tab_display_container"}>
				<Nav className={"custom-nav"} tabs>
					<NavItem>
						<NavLink
							className={classNames(this.isActive("1"), "custom-nav-link")}
							onClick={() => {
								this.props.history.push(`/product/${this.props.product.id}/documentation`);
								this.props.tabToggle("1");
							}}
						>
							<T v="ProductsProperties.Documentation" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classNames(this.isActive("2"), "custom-nav-link")}
							onClick={() => {
								this.props.history.push(`/product/${this.props.product.id}/worklogs`);
								this.props.tabToggle("2");
							}}
						>
							<T v="ProductsProperties.CallLog" />
						</NavLink>
					</NavItem>
					{(this.props.troubleshootingList.length > 0 || this.props.role >= UserRole.CompanyAdmin) && this.props.isCompanyNotSubordinate && <NavItem>
						<NavLink
							className={classNames(this.isActive("3"), "custom-nav-link")}
							onClick={() => {
								this.props.history.push(`/product/${this.props.product.id}/troubleshooting`);
								this.props.tabToggle("3");
							}}
						>
							<T v="ProductsProperties.Troubleshooting" />
						</NavLink>
					</NavItem>}
					{this.props.role >= UserRole.CompanyAdmin && (<NavItem>
						<NavLink
							className={classNames(this.isActive("4"), "custom-nav-link")}
							onClick={() => {
								this.props.history.push(`/product/${this.props.product.id}/qrcode`);
								this.props.tabToggle("4");
							}}
						>
							<T v="ProductsProperties.QRCode" />
						</NavLink>
					</NavItem>)}
				</Nav>
				<TabContent>{this.renderTabs()}</TabContent>
			</div>
		);
	}

	private renderTabs = () => {
		switch (this.props.activeTab) {
			case "1":
				return <DocumentationTab isCompanyNotSubordinate={this.props.isCompanyNotSubordinate} product={this.props.product} />;
			case "2":
				return <WorkLogTab productId={this.props.product.id} />;
			case "3":
				return <ProductsTroubleshootingTab product={this.props.product} />;
			case "4":
				return <QRCodeTab product={this.props.product} />;
			default:
				return <DocumentationTab product={this.props.product} />;
		}
	};
}

type RouteProps = RouteComponentProps<{ id: string }>;

export const ItemDetailTabDisplay = withRouter(ItemDetailTabDisplayBase);
