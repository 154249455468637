import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { T, GetLangString, Lang } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import "./Help.scss";
import { userInfo } from "os";
import PageHeader from "../globalComponents/PageHeader";
import Container from "reactstrap/lib/Container";
import { IconTextButton } from "../globalComponents";
import { actions } from "../../reducers/companies";
import Input from "reactstrap/lib/Input";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";

interface IHelpProps extends InjectedToastsProps
{
	[key: string]: any;
}

interface IHelpState
{
	sendingHelp: boolean;
	helpMessage: string;
}

class HelpComponent extends React.Component<IHelpProps, IHelpState> {
	constructor(props: any) {
		super(props);

		this.state = {
			sendingHelp: false,
			helpMessage: "",
		}
	}

	public render()
	{
		return (
			<Lang>{getLanguageString => (
			<React.Fragment>
				<PageHeader />
				<Container fluid>
					<div className="form-group">
						<h4> {getLanguageString("Help.Message")} </h4>
						<textarea className="form-control-help" id="exampleTextarea" rows={3} onChange={this.handleTextChange} />
					</div>
					<IconTextButton color={this.state.sendingHelp ? "secondary" : "primary"} iconName="send" text="Help.Send" disabled={this.state.sendingHelp} onClick={this.handleAskHelpButtonClick} />
				</Container>
			</React.Fragment>
			)}</Lang>
		);
	}

	private handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
	{
		this.setState({ helpMessage: e.target.value })
	}

	private handleAskHelpButtonClick = () =>
	{
		this.setState({ sendingHelp: true })

		const userId = this.props.userInfo.id.toString();

		this.props.sendHelp(userId, btoa(this.state.helpMessage), this.handleCallback);

		console.log(this.state.helpMessage);
	}

	private handleCallback = (message: string, valid: boolean) =>
	{
		this.setState({ sendingHelp: false })

		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId: message });
			return;
		}

		this.props.addToast({ type: ToastType.Error, msgId: message });
	}
}

const mapStateToProps = (state: IRootState) => ({
	userInfo: state.userInfo.data,
	role: state.userInfo.data.userRole
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			sendHelp: actions.sendHelp,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const Help = connect(
	mapStateToProps,
	mapDispatchToProps
)(withToasts(HelpComponent));
