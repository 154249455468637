import { ITextNode, NodeType } from "../../types/ITroubleshootingNode";

export function getLanguageText(textNode: ITextNode, langKey?: string) {
	const keys = Object.keys(textNode).sort();
	
	const defaultLangKey = keys[0]; //english

	if (!langKey) {
		if (!keys.length) {
			throw new Error("No languages added in node. Can't render text.");
		}

		const key = keys[0];

		return textNode[key];
	}

	var retrievedLangKey = langKey;

	keys.forEach(storedLangKey => {
		if (langKey.toLowerCase().includes(storedLangKey.toLowerCase())){
			retrievedLangKey = storedLangKey;
		}
	});

	if (!textNode[retrievedLangKey]) {
		retrievedLangKey = defaultLangKey;
		// throw new Error("No textNode for given language key.");
	}

	return textNode[retrievedLangKey];
}

export function getIconName(type: NodeType) {
	switch (type) {
		case NodeType.ANSWER:
			return "report";
		case NodeType.QUESTION:
			return "contact_support";
		default:
			return "close";
	}
}

export function getDepthStyles(depth: number): React.CSSProperties {
	if (window.innerWidth < 600) {
		return {
			marginLeft: depth * 24,
		};
	}
	return {
		marginLeft: depth * 48,
	};
}
