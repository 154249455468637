import React from "react";
import { UncontrolledTooltip } from "reactstrap/lib/Uncontrolled";
import { OriginalHtmlProps } from "../../utilities";
import { Icon } from "../globalComponents/Icon";

export interface IToolbarButtonBaseProps
	extends OriginalHtmlProps<HTMLButtonElement> {
	id: string;
	iconName: string;
}

export interface IToolbarButtonProps extends IToolbarButtonBaseProps {
	tooltip: string;
}

export const ToolbarButton: React.FunctionComponent<IToolbarButtonProps> = ({
	children,
	id,
	iconName,
	tooltip,
	...rest
}) => (
	<button id={id} {...rest}>
		{children}
		<Icon name={iconName} />
		<UncontrolledTooltip placement="top" target={id}>
			{tooltip}
		</UncontrolledTooltip>
	</button>
);

export const MobileToolbarButton: React.FunctionComponent<IToolbarButtonBaseProps> = ({
	children,
	id,
	iconName,
	...rest
}) => (
	<button id={id} {...rest}>
		{children}
		<Icon name={iconName} />
	</button>
);
