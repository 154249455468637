import React from "react";
import { Lang } from "../../managers/I18n";
import { classNames } from "../../utilities";
import { Icon } from "../globalComponents";
import { IToastMessage, ToastType } from "./toast.interfaces";

export interface IToastProps {
	onDismiss: (id: number) => void;
	toast: IToastMessage;
}

export const Toast: React.FunctionComponent<IToastProps> = ({ onDismiss, toast }) => {
	const getBoxClassName = (type: ToastType) => {
		const base = "app-toast-box";
		switch (type) {
			case ToastType.Error:
				return classNames(base, "error");
			case ToastType.Warning:
				return classNames(base, "warning");
			case ToastType.Info:
				return classNames(base, "info");
			case ToastType.Success:
				return classNames(base, "success");
		}
	};

	const getToastBody = (langFn: (msgId: string) => string) => {
		if (!toast.msgId && !toast.msg) {
			throw new Error("A toast requires either a msg or msgId to be set.");
		}

		if (toast.msgId) {
			return langFn(toast.msgId);
		}

		return toast.msg;
	};

	return (
		<Lang>
			{getLanguageString => (
				<div className={getBoxClassName(toast.type)}>
					<div className="app-toast-top-layer">
						<span className="app-toast-top-layer-text">{getLanguageString(toast.type)}</span>
						<button className="app-toast-top-layer-dismiss" onClick={() => onDismiss(toast.id)}>
							<Icon name="close" />
						</button>
					</div>
					<div className="app-toast-body">{getToastBody(getLanguageString)}</div>
				</div>
			)}
		</Lang>
	);
};
