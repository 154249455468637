import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { UncontrolledButtonDropdown } from "reactstrap/lib/Uncontrolled";
import { bindActionCreators, Dispatch } from "redux";
import { GetLangString, T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions as layoutActions } from "../../reducers/layout";
import { actions as userActions } from "../../reducers/user-info";
import { UserRole } from "../../types/IRoles";
import { IS_DEVELOPMENT, IS_PRODUCTION_DEBUG } from "../../utilities/logger";
import {
	Icon,
	IconButton,
	LanguageToggler,
	QueueBadge
} from "../globalComponents";
import { getPageHeader } from "../globalComponents/PageHeader";
import "./TopMenu.scss";
import { actions as userAction } from "../../reducers/users";
import { authProvider } from "../../auth/AuthProvider";
import { REGEX_GUID_ID } from "../../constants";
import { getFileIdFromPathName, getTicketIdFromPath, isCallPage } from "../../utilities/pathUtils";

interface ITopMenuProps extends ConnectProps {
	userRole: number;
	isMobile: boolean;
	toggleMobileNav: (isOpen: boolean) => void;
}

const getHeader = (pathname: string): string => {
	const path = pathname.substr(1);	
	const pathList = path.split("/");
	const regEx = new RegExp(REGEX_GUID_ID, 'i');
	let segment = "";

	if (!path) {
		return "ProductsSelection";
	}

	let idx = pathList.length -1;

	while(!segment){
		const segm = pathList[idx];

		if(!regEx.test(segm) && isNaN(parseInt(segm))){
			segment = segm;
		}

		if(idx === 0){
			return segment;
		}

		idx -= 1;
	}

	return segment;
};

const getTitle = (): string => {
	const path = window.location.pathname.substr(0);
	const page = getHeader(path);
	let title = page.charAt(0).toUpperCase() + page.substring(1);
	const ticketId = getTicketIdFromPath(path);


	if(ticketId && isCallPage(path)){
		title = 'TicketCall';
	}

	return "NavLink." + title;
}

const TopMenuBase: React.FunctionComponent<ITopMenuProps> = ({
	isMobile,
	userRole,
	userInfo,
	toggleMobileNav,
	toggleNav,
	setUserRole,
	switchRole,
	version
}) => {
	const [fileId, setFileId] = React.useState<string | undefined>('');

	useEffect(() => {
		setFileId(getFileIdFromPathName(location.pathname));
	}, [location]);
	
	return (
		<div
			style={{ touchAction: "manipulation"}}
			className="fixed-top"
		>
			{fileId === undefined && <div
				className={
					userRole === UserRole.Operator
						? "layout-navbar"
						: "layout-navbar-expert"
				}
			>
				{userRole !== UserRole.Operator && (
					<IconButton
						onClick={
							isMobile ? () => toggleMobileNav(true) : toggleNav
						}
						iconName="menu"
						style={{width:'auto'}}
						title={GetLangString("Toast.OpenNavMenu")}
					/>
				)}
				{userRole === UserRole.Expert && <IconButton
					onClick={
						() => {
							switchRole(()=>{
								location.reload();
							});
						}
					}
					iconName="360"
					style={{width:'auto'}}
					title={GetLangString('Toast.ChangeRoleButton')}
				/>}
				{isMobile && userRole !== UserRole.Operator && (
					<span className="layout-navbar-mobile-title">
						<T v={getPageHeader()} />
					</span>
				)}
				{userRole === UserRole.Operator && (
					<span className="layout-navbar-mobile-title">
						<T v={getTitle()} />
					</span>
				)}
				<div className="right-container">
					{userRole !== UserRole.Operator && <QueueBadge />}
					{/* <QueueBadge /> */}
					{userRole !== UserRole.Operator && <LanguageToggler />}
					{(IS_DEVELOPMENT || userRole === UserRole.Operator) && (
						<UncontrolledButtonDropdown
							onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
								e
							}
							direction="left"
						>
							<DropdownToggle
								className="select-role"
								caret={false}
							>
								<Icon name="more_vert" />
							</DropdownToggle>

							<DropdownMenu>
								{!userInfo.isTemporary && <Link to="/">
									<DropdownItem>
										<T v={"NavLink.ChangeProduct"}/>
									</DropdownItem>
								</Link>}
								{userRole === UserRole.Operator && !userInfo.isTemporary && <Link to="/user/qrcode">
									<DropdownItem>
										<T v={"NavLink.AccessWithHololens"}/>
									</DropdownItem>
								</Link>}
								{/* {userRole !== UserRole.Operator && <QueueBadge />} */}
								{IS_DEVELOPMENT || IS_PRODUCTION_DEBUG ? (
									<Link to="/settings">
										<DropdownItem>
											<T v={"NavLink.Settings"}/>
										</DropdownItem>
									</Link>
								) : null}
								<div style={{ cursor: "pointer" }} onClick={deleteCookie}>
									<DropdownItem>
										<T v={"NavLink.SignOut"}/>
									</DropdownItem>
								</div>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					)}
				</div>
			</div>}
		</div>
	);
};

//TODO: Make global!!
const deleteCookie = () => {
	document.cookie = "connectionId=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; HasCheckedLicense=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "isReloaded=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	document.cookie = "TemporaryToken=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	authProvider.logout();
};

const mapStateToProps = (state: IRootState) => ({
	isNavOpen: state.layout.isNavOpen,
	version: state.tools.data.version,
	isMobile: state.layout.isMobile,
	userRole: state.userInfo.data.userRole,
	userInfo: state.userInfo.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleNav: layoutActions.toggleNav,
			setUserRole: userActions.changeUserRole,
			switchRole: userAction.switchUserRole,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const TopMenu = connect(mapStateToProps, mapDispatchToProps, undefined, {
	pure: false
})(TopMenuBase);
