import { stat } from "fs";
import { userInfo } from "os";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { UserRole } from "../../types/IRoles";
import { IUser } from "../../types/IUser";
import { Action, actions } from "./user-info.actions";

export const initialUser: IUser = {
	id: "",
	teamId: null,
	email: "",
	firstName: "",
	lastName: "",
	phone: "",
	companyId: "",
	originalUserRole: UserRole.Expert,
	userRole: UserRole.Expert,
	accessToken: "",
	userName: "",
	isBlocked: false,
	isTemporary: false,
	creationDate: "",
	createdBy: "1",
	isFromAccessToken: false,
	usedCallTime: undefined,
	isLinked: false,
};

export interface IState
{
	error: string;
	isLoading: boolean;
	data: IUser;
}

export const initialState: IState = {
	isLoading: true,
	error: "",
	data: { ...initialUser },
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) =>
{
	if (state.data.teamId === "") state.data.teamId = null;

	switch (action.type) {
		case getType(actions.getUserInfo):
			return {
				...initialState,
			};
		case getType(actions.getUserInfoSuccess):
			return {
				...state,
				isLoading: false,
				data: action.payload,
			};
		case getType(actions.getUserInfoError):
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case getType(actions.changeUserRole):
			return {
				...state,
				data: {
					...state.data,
					userRole: action.payload,
				},
			};
		default:
			return state;
	}
};
