import { StreamManager } from "openvidu-browser";
import React from "react";
import { Video } from "./video";

interface IMobileRemoteVideoProps {
    streamManager?: StreamManager | null;
    videoClass: string;
}

export class MobileRemoteVideo extends React.Component<IMobileRemoteVideoProps> {
    public videoRef = React.createRef<HTMLVideoElement>();

    public render() {
        if (!this.props.streamManager) {
            console.log("ohh nooo!!");
            return <h1>No Video</h1>;
        }
        // console.log("woohoo!");
        return (
            <Video videoClass={this.props.videoClass} streamManager={this.props.streamManager} />
        );
    }
}
