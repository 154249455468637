import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ActiveCall } from "./components/active-call/ActiveCall";
import { CompanyAdminDashboard } from "./components/company/CompanyAdminDashboard";
import { CompanyDashboard } from "./components/company/CompanyDashboard";
import { Dashboard } from "./components/dashboard";
import Documentation from "./components/documentation/Documentation";
import DocumentationList from "./components/documentation/DocumentationList";
import { LoadingPage } from "./components/globalComponents";
import { BrowserWarning } from "./components/globalComponents/BrowserWarning";
import { DequeueAllPage } from "./components/globalComponents/DequeueAllPage";
import { Layout } from "./components/layout";
import { Product } from "./components/products/Product";
import { Products } from "./components/products/Products";
import { Help } from "./components/dashboard/Help";
import { Teams } from "./components/dashboard/Teams";
import { IUser } from "../src/types";
import { Settings } from "./components/settings/Settings";
import { Ticket } from "./components/ticket/Ticket";
import { Troubleshooting } from "./components/troubleshooting";
import { TroubleshootingAnswerEdit } from "./components/troubleshooting/TroubleshootingAnswerEdit";
import { User } from "./components/user/User";
import { MobileCaller } from "./components/video/MobileCaller";
import { serverManager, ServerManager } from "./managers/ServerManager";
import { IRootState } from "./reducers";
import { actions } from "./reducers/call-queue";
import { actions as layoutActions } from "./reducers/layout";
import { actions as tools } from "./reducers/tools";
import { actions as userInfoActions } from "./reducers/user-info";
import { UserRole } from "./types/IRoles";
import { WorkLogTab } from "./components/products/ProductsWorkLogTab";
import { GetLangString, LanguageConsumer } from "./managers/I18n";
import { CustomAlert } from "./utilities/custom-alert";
import { UserRedirect } from "./components/tempUserRedirect/UserRedirect";
import { TicketsTab } from "./components/ticket/TicketsTab";
import { LandingPage } from "./components/landing-page/LandingPage";
import { RefreshPage } from "./RefreshPage";
import { IncomingCallSound } from "./components/active-call/IncomingCallSound";
import { UserQrCodePage } from "./components/user/UserQrCodePage";

interface ILayoutProps extends ConnectProps {
	baseUrl: string;
	manager: ServerManager;
	temporaryAccessToken: string;
	marketplaceUserToken: string;
}

interface IRouteCompProps {
	token: string;
	id: string;
	ticketId?: string;
	workLogId: string;
	pageRedirect: string;
}

interface ILayoutBaseState {
	showCustomAlert: boolean;
	enableSound: boolean;
	users: IUser[];
}

const getProductsComponent = () => <Products />;
const getHelpPage = () => <Help />;
const getTeamsPage = () => <Teams />;
const getTicketsPage = () => <TicketsTab />
const getSettingsComponent = () => <Settings />;
const getDocumentationComponent = () => <Documentation />;
const getDocumentationListComponent = () => <DocumentationList />;
const getProductComponent = (
	routeProps: RouteComponentProps<IRouteCompProps>, activeTab: string
) => <Product id={routeProps.match.params.id} activeTab={activeTab} />;
const getTicketComponent = (
	routeProps: RouteComponentProps<IRouteCompProps>
) => <Ticket ticketId={routeProps.match.params.workLogId} />;

const getCallerComponent = (
	routeProps: RouteComponentProps<IRouteCompProps>
) => <MobileCaller id={routeProps.match.params.id} token={routeProps.match.params.token} ticketId={routeProps.match.params.ticketId}/>;


const createAccessToRoute = (
	routeProps: RouteComponentProps<IRouteCompProps>
) => <UserRedirect token={routeProps.match.params.token} id={routeProps.match.params.id} ticketId={routeProps.match.params.ticketId} pageRedirect={routeProps.match.params.pageRedirect} />;


const getCallLogComponent = (
	routeProps: RouteComponentProps<IRouteCompProps>
) => <WorkLogTab productId={routeProps.match.params.id} />;

export class LayoutBase extends React.Component<ILayoutProps, ILayoutBaseState> {
	constructor(props: any) {
		super(props);

		this.state = {
			showCustomAlert: true,
			enableSound: true,
			users: [],
		}
	}

	public async componentDidMount() {
		if (this.props.temporaryAccessToken == undefined && this.props.marketplaceUserToken == undefined) {
			await serverManager.init();
		} else if (this.props.temporaryAccessToken != undefined) {
			await serverManager.initForTemporaryUser(this.props.temporaryAccessToken);
		} else {
			await serverManager.initMarketplaceUser(this.props.marketplaceUserToken);
		}

		this.props.connect();
		this.props.getUserInfo();
		const response = await serverManager.users.get();
		this.setState({ users: response.data });

		//this.props.getVersion();
		this.browserCheck();

		if (this.props.userInfo.isBlocked) {
			alert(GetLangString('Toast.UserIsBlocked'));
		}
	}

	public static isMobileDevice = (): boolean => {
		const toMatch = [
			"android",
			"webos",
			"ios",
			"iphone",
			"ipad",
			"ipod",
			"blackberry",
			"windows phone",
		];

		// touchPoints check
		if (navigator.userAgent.toLowerCase().indexOf("mac") !== -1 && navigator.maxTouchPoints > 0) {
			return true;
		}

		// muted check
		if (navigator.userAgent.toLowerCase().indexOf("mac") !== -1) {
			try {
				const audio = new Audio();
				if (audio.muted) {
					audio.muted = false;
					if (audio.muted)
						return true;
				} else {
					audio.muted = true;
					if (!audio.muted)
						return true;
				}
			} catch (error) {
				return true;
			}
		}

		if (navigator.userAgent.toLowerCase().indexOf("mac") !== -1) {
			try {
				new Audio().play();
				new Audio().pause();
			} catch (e) {
				return true;
			}
		}

		for (let i = 0; i < toMatch.length; i++) {
			if (navigator.userAgent.toLowerCase().indexOf(toMatch[i]) !== -1) {
				return true;
			}
		}
		return false;
	}

	private browserCheck() {
		try {
			const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") != -1;
			const isSafari = navigator.userAgent.toLowerCase().indexOf("safari") != -1;

			if (!isChrome && !isSafari) {
				alert(GetLangString("BrowserWarning.Alert"));
			}
		}
		catch {

		}
	}

	public render() {
		const { showCustomAlert, enableSound } = this.state;
		const props = this.props;
		const { activeCall, isNavOpen, userInfoLoading, userInfo } = props;
		let keepSoundActive = false;
		console.log(`[foo] userInfo app: ${userInfo.userRole}`);
		if (userInfoLoading) {
			return <LoadingPage />;
		}



		if (userInfo.id === "") {

			return (
				<RefreshPage />
				// <div>
				// 	REFRESH PAGE
				// </div>
			);
		}

		return (
			<BrowserRouter basename={props.baseUrl}>
				<LanguageConsumer>
					{() =>
						<React.Fragment>
							{userInfo.userRole === UserRole.Expert &&
								<CustomAlert
									messageHeader={GetLangString(`Toast.MobileMessageHeader`)}
									messageBody={GetLangString(`Toast.MobileMessageBody`)}
									messageButton1={`OK`}
									keepSoundActive={keepSoundActive}
									sound={this.props.sound}

								/>}
							{activeCall && <ActiveCall />}
							<IncomingCallSound />
							<Layout isNavOpen={isNavOpen} userInfo={userInfo}>
								<div style={{ height: "60px" }} />
								{userInfo.userRole === UserRole.Expert && (
									<Route exact path="/" component={Dashboard} />
								)}
								{userInfo.userRole === UserRole.SuperAdmin && (
									<Route
										exact
										path="/"
										component={CompanyAdminDashboard}
									/>
								)}
								{(userInfo.userRole === UserRole.CompanyAdmin ||
									userInfo.userRole === UserRole.Expert) && (
										<React.Fragment>
											<Route
												exact
												path="/"
												component={CompanyDashboard}
											/>
											<Route exact path="/user" component={User} />
											<Route
												exact
												path="/companies/user"
												component={User}
											/>
										</React.Fragment>
									)}

								{userInfo.userRole !== UserRole.SuperAdmin && (
									<Route
										path="/help"
										component={() => getHelpPage()}
									/>
								)}

								{userInfo.userRole === UserRole.CompanyAdmin ||
									userInfo.userRole === UserRole.Expert ||
									userInfo.userRole === UserRole.Operator ? (
									<Route
										path="/products"
										component={() => getProductsComponent()}
									/>
								) : null}

								{userInfo.userRole === UserRole.CompanyAdmin ||
									userInfo.userRole === UserRole.Expert ? (
									<Switch>
										<Route
											exact
											path="/product/:id/ticket/:workLogId"
											render={routeProps =>
												getTicketComponent(routeProps)
											}
										/>
										<Route
											path="/product/:id/qrcode"
											component={() => <Product id={location.href.split("/")[4]} activeTab="4" />}
										/>
										<Route
											path="/product/:id/documentation"
											component={() => <Product id={location.href.split("/")[4]} activeTab="1" />}
										// render={routeProps =>
										// 	getProductComponent(routeProps, "1")
										// }
										/>
										<Route
											path={`/product/:id/worklogs`}
											component={() => <Product id={location.href.split("/")[4]} activeTab="2" />}
										// render={routeProps =>
										// 	getProductComponent(routeProps, "2")
										// }
										/>
										<Route
											path="/product/:id/troubleshooting/:editNodeId"
											component={TroubleshootingAnswerEdit}
										/>
										<Route
											path="/product/:id/troubleshooting"
											component={Troubleshooting}
										/>
										<Route
											path="/product/:id"
											render={routeProps =>
												getProductComponent(routeProps, "1")
											}
										/>
									</Switch>
								) : null}
								{userInfo.userRole === UserRole.CompanyAdmin ?
									<React.Fragment>
										<Route
											path="/teams"
											component={() => getTeamsPage()}
										/>
									</React.Fragment> : null
								}
								<React.Fragment>
									<Route
										path="/tickets"
										component={() => getTicketsPage()}
									/>
								</React.Fragment>
								{userInfo.userRole === UserRole.CompanyAdmin ||
									userInfo.userRole === UserRole.Expert ? (
									<React.Fragment>
										<Route
											path="/documentation"
											component={() =>
												getDocumentationComponent()
											}
										/>
										<Route
											path="/call-log/:id"
											render={routeProps =>
												getTicketComponent(routeProps)
											}
										/>
										<Route
											path="/ticket/:workLogId"
											render={routeProps =>
												getTicketComponent(routeProps)
											}
										/>
									</React.Fragment>
								) : null}
								{userInfo.userRole === UserRole.Operator ||
									userInfo.accessToken != null ? (
									<Switch>
										<Route
											exact
											path="/"
											render={() => <Products />}
										/>
										<Route
											exact
											path="/help"
											render={() => <Help />}
										/>
										
										<Route
											path="/product/:id/ticketcall/:ticketId/troubleshooting/:editNodeId"
											render={() => <TroubleshootingAnswerEdit />}
										/>
										<Route
											path="/product/:id/troubleshooting/:editNodeId"
											render={() => <TroubleshootingAnswerEdit />}
										/>
										<Route //Risoluzione problemi
											path="/product/:id/ticketcall/:ticketId/troubleshooting"
											render={() => <Troubleshooting />}
										/>
										<Route //Risoluzione problemi
											path="/product/:id/troubleshooting"
											render={() => <Troubleshooting />}
										/>
										<Route
											exact
											path="/product/:id/files/recent"
											component={DocumentationList}
										/>
										<Route //Ricevuti
											exact
											path="/product/:id/ticketcall/:ticketId/received"
											render={routeProps =>
												getCallLogComponent(routeProps)
											}
										/>		
										<Route //Ricevuti
											exact
											path="/product/:id/received"
											render={routeProps =>
												getCallLogComponent(routeProps)
											}
										/>										
										<Route //Documenti
											path="/product/:id/ticketcall/:ticketId/files"
											component={DocumentationList}
										/>
										<Route //Documenti
											path="/product/:id/files"
											component={DocumentationList}
										/>
										<Route
											path="/product/:id/files/:fileId"
											component={DocumentationList}
										/>
										<Route //*Chiamata
											path="/product/:id/call"
											render={routeProps =>
												getCallerComponent(routeProps)
											}
										/>
										<Route //*Chiamata
											path="/product/:id/ticketcall/:ticketId/call"
											render={routeProps => {
												return getCallerComponent(routeProps);
											}

											}
										/>
										<Route
											path="/product/:id/ticket/:workLogId"
											render={routeProps =>
												getTicketComponent(routeProps)
											}
										/>
										<Route
											path="/temporaryUserAccess/:token/:id/ticketcall/:ticketId/:pageRedirect"
											render={routeProps =>
												createAccessToRoute(routeProps)
											}
										/>
										<Route
											path="/temporaryUserAccess/:token/:id/:pageRedirect"
											render={routeProps =>
												createAccessToRoute(routeProps)
											}
										/>
										<Route
											path="/user/qrcode"
											render={() => <UserQrCodePage accessToken={userInfo.accessToken}/>}
										>
										</Route>
									</Switch>
								) : null}
								<Route path="/clear-queue" component={DequeueAllPage} />
								<Route
									exact
									path="/landing-page"
									component={LandingPage}
								/>
								<Route
									path="/settings"
									component={() => getSettingsComponent()}
								/>
								<BrowserWarning />
								<div style={{ height: "60px" }} />
							</Layout>

						</React.Fragment>}
				</LanguageConsumer>
			</BrowserRouter>
		);
	}

	private async playCallSound(play: boolean) {
		this.props.sound.loop = true;
		this.props.sound.currentTime = 0;
		this.props.sound.load();
		if (play) {
			this.props.sound.play().then(() => {
				console.log("AUDIO IS PLAYING");
			});
		} else {
			this.props.sound.pause();
		}
	}
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			connect: actions.connect,
			getUserInfo: userInfoActions.getUserInfo,
			toggleNav: layoutActions.toggleNav,
			getVersion: tools.getVersion
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(LayoutBase);



