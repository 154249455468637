export type ITroubleshootingLanguages = "it" | "en";

export enum NodeType {
	QUESTION = "question",
	ANSWER = "answer",
}

export interface ITextNode {
	[key: string]: string[];
}

export interface ITroubleshootingNodeText {
	text: ITextNode;
}

export interface ITroubleshootingNode extends ITroubleshootingNodeText {
	id: number;
	type: NodeType;
	children: ITroubleshootingNode[];
	reference?: string;
}

export interface ITroubleshootingFlatNode extends ITroubleshootingNodeText {
	id: number;
	type: NodeType;
	parentId: number | string | null;
	reference?: string;
}

export interface ITroubleshootingFlatNodeWithTargetIds extends ITroubleshootingFlatNode {
	targetIds: number[];
}
