import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { serverManager } from "../../managers/ServerManager";
import { logger } from "../../utilities/logger";
import { actions } from "./tools.actions";

export function* handleGetVersion(action: ReturnType<typeof actions.getVersion>) {
	try {
		const response: AxiosResponse<string> = yield call(serverManager.tools.getVersion);
		yield put(actions.getVersionSuccess(response.data));
	} catch (err) {
		const errString = "something went wrong while fetching userinfo.";
		logger.error(errString);
		yield put(actions.getVersionError(errString));
	}
}

export function* tools() {
	yield takeLatest(getType(actions.getVersion), handleGetVersion);
}
