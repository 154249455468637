import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { GetLangString } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { ITicket } from "../../types";
import { delay } from "../../utilities/delay";
import { logger } from "../../utilities/logger";
import { actions } from "./tickets.actions";

export function* handleGetTickets(action: ReturnType<typeof actions.getTickets>)
{
	const { productId, step, date, description, teamId, selectedStatus } = action.payload;
	try {
		const response : AxiosResponse = yield call(serverManager.tickets.get, productId, step, date, description, teamId, selectedStatus);
		yield put(actions.getTicketsSuccess(response.data));
	} catch (err) {
		logger.error(err);
		yield put(actions.getTicketsError("Something went wrong while fetching Tikets for this Product. Step: " + step));
	}
}

export function* handleGetTicket(action: ReturnType<typeof actions.getTicket>)
{
	try {
		logger.debug("Fetching ticket with id", action.payload);
		const response : AxiosResponse = yield call(serverManager.tickets.getById, action.payload);
		yield put(actions.getTicketSuccess(response.data));
		logger.debug("Ticket with id", action.payload, "fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getTicketError("Something went wrong while fetching Ticket."));
	}
}

export function* handleGetTicketView(action: ReturnType<typeof actions.getTicketView>)
{
	try {
		//logger.debug("Fetching ticket with id", action.payload);
		const { productId, start, length, callback } = action.payload;
		logger.debug("FETCHING SINGLE TICKET");
		const response : AxiosResponse = yield call(serverManager.tickets.getByProductId, productId, start, length);
		yield call(callback, response.data);
		logger.debug("CALLING CALLBACK");
		//yield put(actions.getTicketViewSuccess(response.data));
		//callback(response.data);
		logger.debug("Ticket with id", action.payload, "fetched successfully.");
	} catch (err) {
		logger.error(err);
	}
}

export function* handleMergeTickets(action: ReturnType<typeof actions.mergeTickets>) {
	const { ticketsIds, callback } = action.payload;
	try {
		yield call(serverManager.tickets.merge, ticketsIds);
		yield put(actions.mergeTicketsSuccess(ticketsIds));

		if (typeof callback === "function") {
			const contentString = `Toast.TicketMergeSuccessful`;
			yield call(callback, contentString, true);
		}
	} catch(error) {
		if (typeof callback === "function") {
			const contentString = `Ticket.MergeDeleteError`;
			yield call(callback, contentString, false);
		}
		yield put(actions.mergeTicketsError(GetLangString("Ticket.MergeDeleteError")));
	}
}

export function* handleDeleteSelectedTickets(action: ReturnType<typeof actions.deleteTickets>)
{
	const { ticketIds, callback } = action.payload;
	try {
		yield call(serverManager.tickets.delete, ticketIds);
		yield put(actions.deleteTicketsSuccess(ticketIds));

		if (typeof callback === "function") {
			const contentString = `Toast.TicketDeleteSuccessful`;
			yield call(callback, contentString, true);
		}
	} catch (error) {
		if (typeof callback === "function") {
			const contentString = `Ticket.MergeDeleteError`;
			yield call(callback, contentString, false);
		}
		yield put(actions.deleteTicketsError(GetLangString("Ticket.MergeDeleteError")));
	}
}

export function* handleRequestTicketsExport(action: ReturnType<typeof actions.requestTicketsReport>)
{
	const { productId, callback } = action.payload;
	try {
		//yield delay(500);
		if (productId !== null) {
			yield put(actions.requestTicketsReportSuccess(productId));

			if (typeof callback === "function") {
				const contentString = `Toast.TicketsReportRequestSuccessful`;
				yield call(callback, contentString, true);
			}
		}

		yield call(serverManager.tickets.exportAllTickets, productId);
		
	} catch (error) {
		if (typeof callback === "function") {
			const contentString = `Toast.TicketsReportRequestFailed`;
			yield call(callback, contentString, false);
		}
		yield put(actions.requestTicketsReportError("Something went wrong while deleting tickets: " + error));
	}
}

export function* handleExportTicket(action: ReturnType<typeof actions.exportTicket>)
{
	try {
		logger.debug("Fetching ticket with id", action.payload);
		const response : AxiosResponse = yield call(serverManager.tickets.getExportedById, action.payload);
		yield put(actions.exportTicketSuccess(response.data));
		logger.debug("Export ticket with id", action.payload, "fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.exportTicketError("Something went wrong while fetching ticket to export."));
	}
}

export function* handleExportTicketCsv(action: ReturnType<typeof actions.exportTicketCsv>)
{
	try {
		logger.debug("Fetching ticket with id", action.payload);
		const response : AxiosResponse = yield call(serverManager.tickets.getExportedById, action.payload);
		yield put(actions.exportTicketCsvSuccess(response.data));
		logger.debug("Export ticket with id", action.payload, "fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.exportTicketCsvError("Something went wrong while fetching ticket to export."));
	}
}


export function* handleSaveTicket(action: ReturnType<typeof actions.saveTicket>)
{
	const { editetTicket, callback } = action.payload;
	try {
		const response : AxiosResponse<ITicket> = yield call(serverManager.tickets.put, editetTicket);
		yield put(actions.saveTicketSuccess(response.data));

		if (typeof callback === "function") {
			const contentString = `Toast.TicketUpdated`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.TicketUpdatedFailed`;
			yield call(callback, contentString, false);
		}

		yield put(actions.saveTicketError("Something went wrong while saving Tikets for this Product."));
	}
}

export function* ticketsSaga()
{
	yield takeLatest(getType(actions.getTickets), handleGetTickets);
	yield takeLatest(getType(actions.getTicket), handleGetTicket);
	yield takeLatest(getType(actions.getTicketView), handleGetTicketView);
	yield takeLatest(getType(actions.deleteTickets), handleDeleteSelectedTickets);
	yield takeLatest(getType(actions.mergeTickets), handleMergeTickets);
	yield takeLatest(getType(actions.requestTicketsReport), handleRequestTicketsExport);
	yield takeLatest(getType(actions.exportTicket), handleExportTicket);
	yield takeLatest(getType(actions.exportTicketCsv), handleExportTicketCsv);
	yield takeLatest(getType(actions.saveTicket), handleSaveTicket);
}
