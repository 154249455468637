import Axios, { AxiosInstance } from "axios";
import { ILicense, ILicenseNumber, IMarketplaceLicenses } from "../../types/ILicense";

export class LicensesManager {
    private agent: AxiosInstance;
    private url: string = "Licenses"
    
    constructor(agent: AxiosInstance) {
        this.agent = agent;
    }

    public getLicensesByCompany = async (companyId: string) => this.agent.get<ILicenseNumber[]>(`${this.url}/GetLicensesByCompanyId?companyId=${companyId}`);
    public createLicensesForCompany = async (companyId: string, licenses: ILicenseNumber[]) => this.agent.post<ILicenseNumber[]>(`${this.url}/CreateLicensesForCompany?companyId=${companyId}`, licenses);
    public putUser = async (companyId: string, userId: string) => this.agent.get<boolean>(`${this.url}/PutUser?companyId=${companyId}&userId=${userId}`);
    public getAllLicenses = async () => this.agent.get<ILicenseNumber[]>(`${this.url}/GetAllLicenses`);
    public updateRenewalMonths = async (licenses: ILicenseNumber[]) => this.agent.put<ILicenseNumber[]>(`${this.url}/UpdateRenewalMonths`, licenses);
    public addMarketplaceLicenses = async (marketLicenses: IMarketplaceLicenses) => this.agent.post(`${this.url}/AddMarketplaceLicenses`, marketLicenses);
    public deleteLicenses = async (companyId: string) => this.agent.delete(`${this.url}/DeleteLicenses?companyId=${companyId}`);
    public resetLicenses = async (companyId: string) => this.agent.put(`${this.url}/ResetCompanyLicenses?companyId=${companyId}`);
}