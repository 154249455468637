import React from "react";
import { classNames } from "../../utilities/class-names";
import "./MenuIcon.scss";

export type MenuIconProps = {
	name: string;
} & React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>;

export const MenuIcon: React.FunctionComponent<MenuIconProps> = ({ className, name, color, ...rest }) => (
	<i style={{color:color}}className={classNames("material-icons", className)} {...rest}>
		{name}
	</i>
);
