import { AxiosInstance } from "axios";
import { IRecording, IUser, IUserCreate } from "../../types";
import { ITeam } from "../../types/ITeam";
import { getCurrentLanguage } from "../I18n";

export class UsersManager {
	private agent: AxiosInstance;
	private url: string = "Users";
	private urlTeams: string = "Teams";
	private urlAuth: string = "auth";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}
	public get = async () => this.agent.get<IUser[]>(`${this.url}/Users`);
	public getByCompany = async (companyId: string) => this.agent.get<IUser[]>(`${this.url}/Users?forceCompanyId=${companyId}`);
	public getBlocked = async () => this.agent.get<IUser[]>(`${this.url}/Blocked`);
	public getMe = async () => this.agent.get<IUser>(`${this.url}/Me`);
	public getById = async (UserId: string) => this.agent.get<IUser>(`${this.url}/${UserId}`);
	public post = async (dto: IUserCreate) => this.agent.post<IUser>(`${this.url}/Create?lang=${getCurrentLanguage()}`, dto);
	public createTemp = async () => this.agent.post<IUser>(`${this.url}/CreateTemp`);
	public getBearerToken = async (UserId: string, productId: string, redirectedSection: string) => this.agent.get<string>(`${this.url}/GetTokenById?userId=${UserId}&productId=${productId}&redirectedSection=${redirectedSection}`);
	public getUserDuration = async (UserId: string) => this.agent.get<string>(`${this.url}/GetTempDurationById?userId=${UserId}`);
	public getUserCreationDate = async (UserId: string) => this.agent.get<string>(`${this.url}/getUserCreationDate`);
	public block = async (UserId: string) => this.agent.put<string>(`${this.url}/BlockUser?userId=${UserId}`);
	public unblock = async (UserId: string) => this.agent.put<string>(`${this.url}/UnblockUser?userId=${UserId}`);
	public put = async (dto: IUser) => this.agent.put<IUser>(`${this.url}/${dto.id}`, dto);
	public reset = async (UserId: string) => this.agent.post(`${this.url}/ResetPassword?userId=${UserId}&lang=${getCurrentLanguage()}`);
	public delete = async (UserId: string) => this.agent.delete(`${this.url}/${UserId}`);
	public IsValidUsername = async (dto: string) => this.agent.get(`${this.url}/IsValidUsername?userName=${dto}`);
	public IsUserBlocked = async (UserId: string) => this.agent.get(`${this.url}/IsUserBlocked?userId=${UserId}`);
	
	public getTeams = async () => this.agent.get<ITeam[]>(`${this.urlTeams}/Teams`);
	public getTeamsByCompany = async (companyId: string) => this.agent.get<ITeam[]>(`${this.urlTeams}/Teams?forceCompanyId=${companyId}`); //Useful when you're a super admin and cannot access company context
	
	public addLoggedUser = async (connectionId: string) => this.agent.get(`${this.url}/AddLoggedUser?connectionId=${connectionId}`);
	public updateTeam = async (dto: ITeam) => this.agent.put<ITeam>(`${this.urlTeams}/UpdateTeam?teamId=${dto.id}`, dto);
	public createTeam = async (dto: ITeam) => this.agent.post<ITeam>(`${this.urlTeams}/CreateTeam`, dto); //Gives created Team with ID
	public deleteTeam = async (TeamId: string) => this.agent.delete(`${this.urlTeams}/DeleteTeam?teamId=${TeamId}`);

	public sendParticipantUsername = async (name: string) => this.agent.get(`${this.url}/SendParticipantName?name=${name}`);
	public switchUserRole = async () => this.agent.put(`${this.url}/SwitchUserRole`);
	public SetUserNotificationExpired = async () => this.agent.put(`${this.url}/SetIsNotificationExpired`);

	public getUserByAccessToken = async (accessToken: string) => this.agent.get<IUser>(`${this.url}/GetUserByAccessToken?accessToken=${accessToken}`);
}
