import moment from "moment";
import * as React from "react";
import Button from "reactstrap/lib/Button";
import Label from "reactstrap/lib/Label";
import { T, GetLangString, getCurrentLanguage } from "../../managers/I18n";
import { UserRole } from "../../types/IRoles";
import { FileTypes, formatKilobytes, getFileType, getFileExtension, FileExtension, toArray } from "../../utilities";
import { IFile, IClip, IClips } from "../../types";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/files";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Icon, Spinner } from "../globalComponents";
import { Object3D } from "three/src/core/Object3D";
import { AnimationClip } from "three/src/animation/AnimationClip";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { delay } from "../../utilities";
import { UncontrolledButtonDropdown } from "reactstrap/lib/Uncontrolled";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import * as THREE from "three";
import { convertImageToFile } from "../active-call/ActiveCallUtils";
import { serverManager } from "../../managers/ServerManager";
import { ANIMATION_LANGUAGES } from "../../constants";

interface IDetailInfromation {
	isCompanyNotSubordinate: boolean;
	fileName: string;
	fileSize: number;
	fileType: FileTypes;
	dateAdded: Date;
	isOfficial: boolean;
	linkedProducts?: string;
	onSetOfficial: () => void;
	onLinkProduct: (() => void) | null;
	userRole: UserRole;
}

export const DetailInformation = (props: IDetailInfromation) => {
	const {
		fileName,
		fileSize,
		dateAdded,
		fileType,
		isOfficial,
		linkedProducts,
		onSetOfficial,
		onLinkProduct,
		userRole,
	} = props;
	if (props.fileName === undefined) {
		return <span />;
	}
	return (
		<div className="mt-3">
			<h4>
				<T v="DocumentationDetails.Details" />
			</h4>
			<div>
				<p>
					<T v="DocumentationDetails.Name" />{" "}
					<Label>{fileName}</Label>
				</p>
				<p>
					<T v="DocumentationDetails.Type" />{" "}
					<Label>{fileType}</Label>
				</p>
				<p>
					<T v="DocumentationDetails.Size" />{" "}
					<Label>{formatKilobytes(fileSize)}</Label>
				</p>
				{userRole !== UserRole.Operator && (
					<p>
						<T v="DocumentationDetails.Added" />
						<Label>
							{moment
								.utc(dateAdded)
								.local()
								.format("LL")}
						</Label>
					</p>
				)}
				{userRole !== UserRole.Operator && (
					<p>
						<T v="DocumentationDetails.LinkedProducts" />
						<Label>
							{linkedProducts || (
								<T v="ProductsProperties.NoProductLinked" />
							)}
						</Label>
					</p>
				)}

				{props.isCompanyNotSubordinate &&
					userRole >= UserRole.CompanyAdmin && isOfficial && onLinkProduct != null && (
						<Button
							className="documentation-tab-button"
							onClick={onLinkProduct}
							color="primary"
						>
							<T v="ProductsProperties.LinkProduct" />
						</Button>
					)}
				{props.isCompanyNotSubordinate &&
					userRole >= UserRole.CompanyAdmin && !isOfficial && (
						<Button
							className="documentation-tab-button"
							onClick={onSetOfficial}
							color="primary"
						>
							<T v="ProductsProperties.MakeOfficial" />
						</Button>
					)}
			</div>
		</div>
	);
};

interface ITakeDataProps extends ConnectProps, InjectedToastsProps {
	file: IFile;
	isCompanyNotSubordinate: boolean;
}

interface ITakeDataState {
	fileToLoad: IFile | null;
	loadedModel: any;
	loading: boolean;
	modelData: IClips | null;
	voiceSynth: SpeechSynthesis;
	selectedLanguage: string;
}

class TakeDataBase extends React.Component<ITakeDataProps, ITakeDataState> {

	public state: ITakeDataState = {
		fileToLoad: null,
		loadedModel: null,
		loading: false,
		modelData: {
			id: "",
			clipsData: [],
		},
		voiceSynth: window.speechSynthesis,
		selectedLanguage: "",
	};

	componentDidMount() {
		var lang = getCurrentLanguage();
		this.setState({ selectedLanguage: lang });
	}

	public render() {
		if (this.state.fileToLoad != this.props.file) {
			this.setState({ fileToLoad: this.props.file, loadedModel: null });
			this.state.modelData = null;

			if (getFileExtension(this.props.file) === FileExtension.FBX) {
				this.handleClipsDataLoading(this.props.file.id);
			}
		}

		if (this.state.fileToLoad == null || getFileExtension(this.state.fileToLoad) != FileExtension.FBX) {
			return null;
		}

		if (this.state.loading) {
			return (<Spinner style={{ height: 64, width: 64, margin: "auto" }} />)
		}

		if (this.state.modelData == null)
			return null;

		var index = 0;

		return (
			<div className="mt-3">
				<h4>
					<T v="DocumentationDetails.TakeData" />
					<button title={GetLangString("DocumentationDetails.ReloadModel")} onClick={() => this.handleResetClipsDataLoading(this.props.file.id)} style={{ background: "lightgrey", border: "none", marginLeft: "calc(100% - 240px)" }}>
						<Icon name="settings_backup_restore" />
					</button>
				</h4>
				<UncontrolledButtonDropdown onClick={(e: React.MouseEvent<HTMLButtonElement>) => e} direction="left">
					<DropdownToggle>{this.state.selectedLanguage}</DropdownToggle>
					<DropdownMenu>
						<DropdownItem onClick={() => this.setState({ selectedLanguage: "it" })}>
							it
						</DropdownItem>
						<DropdownItem onClick={() => this.setState({ selectedLanguage: "en" })}>
							en
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledButtonDropdown>

				<div>
					<p>
						<T v="DocumentationDetails.ClipsAmount" />{" "}
						<Label>{this.state.modelData.clipsData.length}</Label>
						{this.state.modelData.clipsData.length > 0 && (
							this.props.isCompanyNotSubordinate && <div>
								<Button
									className="documentation-tab-button"
									color="secondary"
									onClick={this.handleClipsTextToSound}>
									<T v={"DocumentationDetails.CopyPreviewTakeSounds"} />
								</Button>
								<Col sm={12}>
									{this.handleAnimationsEditing(this.state.modelData.clipsData.length)}
								</Col>
								<Button
									className="documentation-tab-button"
									color="primary"
									onClick={() => this.handleClipsDataSaving(this.state.modelData, true)}
								>
									<T v="DocumentationDetails.SaveTakeData" />
								</Button>
							</div>
						)}
					</p>
				</div>
			</div>
		);
	}

	private handleClipsTextToSound = async () => {
		this.setState({ loading: true });

		const lang = this.state.selectedLanguage; //TODO: Get custom language from dropdown button

		if (this.state.modelData) {
			this.state.modelData.clipsData.forEach(x => {
				x.speechText[lang] = x.text[lang].toLowerCase();
			})
		}

		await delay(100);

		this.setState({ loading: false });
	}

	private handleSingleClipTextToSound = async (index: number) => {
		this.setState({ loading: true });

		const lang = this.state.selectedLanguage; //TODO: Get custom language from dropdown button

		if (this.state.modelData && this.state.modelData.clipsData[index]) {
			this.state.modelData.clipsData[index].speechText[lang] = this.state.modelData.clipsData[index].text[lang].toLowerCase();
		}

		await delay(100);

		this.setState({ loading: false });
	}

	private handleClipsDataLoading = (fileId: string) => {
		this.setState({ loading: true });
		console.log(`[foo] fileId: ${fileId}`);
		this.props.getClipsData(fileId, this.loadCallback);
	};

	private handleResetClipsDataLoading = (fileId: string) => {		
		const modelData = this.state.modelData;

		this.setState((state, props) => {
			return { loading: true };
		});

		if (!modelData) {
			this.setState((state, props) => {
				return { loading: false };
			});
			return;
		}
		
		const clipsData = [...modelData.clipsData];

		clipsData.forEach(x => {
			ANIMATION_LANGUAGES.forEach((l, idx) => {

				if (x.speechText.hasOwnProperty(l)) {
					x.speechText[l] = "";
				}
				if (x.text.hasOwnProperty(l)) {
					x.text[l] = "";
				}
			})
		});

		this.props.setClipsData({
			...modelData,
			clipsData: clipsData
		},
		(msg: string, valid: boolean) => {
			this.setState((state, props) => {
				return { loading: false };
			});

			if (getFileExtension(this.props.file) === FileExtension.FBX) {
				this.handleClipsDataLoading(this.props.file.id);
			}
		});
	};

	private loadCallback = (msg: IClips | null, valid: boolean) => {

		if (msg && valid && this.state.fileToLoad != null && msg.id != this.state.fileToLoad.id) {
			console.error(this.state.fileToLoad.id + " / " + msg.id);
			return;
		}
		console.log(`[foo] modelData: ${msg} && ${valid}`);

		if (valid && msg !== null && msg.clipsData !== null) {
			this.setState({
				modelData: msg,
				loading: false
			});
		} else {
			if (this.state.fileToLoad !== null) {
				const loader: FBXLoader = new FBXLoader();
				const fileInLoad = this.state.fileToLoad;

				console.log("test " + this.state.fileToLoad.id);
				console.log("test " + this.state.fileToLoad.realFileUri);

				loader.load(`${process.env.REACT_APP_LOCALHOST}${this.state.fileToLoad.realFileUri}`, (obj: any) => {
					console.log("start load ");

					if (this.state.fileToLoad == fileInLoad) {
						const clips = {
							id: this.state.fileToLoad.id,
							clipsData: new Array<IClip>(obj.animations.length)
						};

						this.setState({
							loadedModel: obj,
							modelData: clips,
							loading: false,
						});
						console.log("test " + clips);
						this.handleClipsDataSaving(clips, false);
					}
				});
			}
		}

	};

	private handleSaveModelThumbnail = (fileToLoad: IFile, model: any) => {
		console.log(`[foo] sono qui dentro!!!`);
		const canvas = document.createElement('canvas');
		const backgroundCanvas = document.createElement('canvas');
		const clock = new THREE.Clock();
		const mixer = new THREE.AnimationMixer(model);

		const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvas, preserveDrawingBuffer: true })
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100000);
		camera.position.set(1.166, 63.586, 154.963);
		const light = new THREE.HemisphereLight(0xffffff, 0x454545, 1);
		const directionalLight = new THREE.DirectionalLight(0xffffff, 1);

		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.shadowMap.enabled = true;
		renderer.gammaOutput = true;

		light.position.set(0.5, 1, 0.25);

		scene.add(light);
		//scene.add(directionalLight);
		scene.background = new THREE.Color(0xDBDCE0);

		scene.add(model);
		directionalLight.target = model;
		const animate = () => {
			const delta = clock.getDelta();
			if (mixer) {
				mixer.update(delta);
			}

			renderer.render(scene, camera);
			requestAnimationFrame(animate);
		};

		animate();

		renderer.setAnimationLoop(() => {
			renderer.render(scene, camera);
		});

		backgroundCanvas.width = 500;
		backgroundCanvas.height = 500;
		console.log(`[foo] canvasWidth, canvasHeight: ${canvas.width}, ${canvas.height}`);
		const context = backgroundCanvas.getContext('2d');

		if (context) {
			if (canvas.width > canvas.height) {
				const newHeight = (canvas.height / canvas.width) * backgroundCanvas.width;
				context.drawImage(canvas, 0, (backgroundCanvas.height / 2) - (newHeight / 2), backgroundCanvas.width, newHeight);
			} else {
				const newWidth = backgroundCanvas.height / (canvas.height / canvas.width);
				context.drawImage(canvas, (backgroundCanvas.width / 2) - (newWidth / 2), 0, newWidth, backgroundCanvas.height);
			}

			backgroundCanvas.toBlob(async (blob: Blob | null) => {
				if (!blob)
					return;
				const file = convertImageToFile(blob, "model_thumbnail");
				const response = await serverManager.files.uploadFileThumbnail(fileToLoad.id, file);
				this.props.uploadModelThumbnailSuccess(response);
				//this.props.uploadModelThumbnail(fileToLoad.id, file);
			});
		}
	};

	private handleClipsDataSaving = (clips: IClips | null, isSaving: boolean) => {
		if (clips != null) {
			this.props.setClipsData(clips, isSaving ? this.saveCallback : () => { });
		}
	};

	private saveCallback = (msg: string, valid: boolean) => {
		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId: "Toast.SaveClipsDataSuccess" });
			return;
		}

		this.props.addToast({ type: ToastType.Error, msgId: "Toast.SaveClipsDataFail" });
	};

	private handleAnimationsEditing(clips: number) {
		let table = []

		for (let i = 0; i < clips; i++) {
			table.push(<div>
				{
					this.handleAnimationEditing(i)
				}
			</div>)
		}

		return table
	}

	private handleAnimationEditing(index: number): JSX.Element {
		if (this.state.modelData == null)
			return <div></div>;

		const lang = this.state.selectedLanguage; //TODO: Get custom language from dropdown button
		if (this.state.modelData.clipsData[index] == null || this.state.modelData.clipsData[index].text == null || this.state.modelData.clipsData[index].speechText == null) {
			this.state.modelData.clipsData[index] = {
				text: {},
				speechText: {}
			};

			this.state.modelData.clipsData[index].text[lang] = "";
			this.state.modelData.clipsData[index].speechText[lang] = "";
		}

		if (this.state.modelData.clipsData[index].text[lang] == null) {
			this.state.modelData.clipsData[index].text[lang] = "";
		}

		if (this.state.modelData.clipsData[index].speechText[lang] == null) {
			this.state.modelData.clipsData[index].speechText[lang] = "";
		}

		return (<div>
			<T v="DocumentationDetails.TakeNumber" />{" " + (index + 1)}
			<FormGroup>
				<Label>
					<T v="DocumentationDetails.TakeText" />{" "}
				</Label>
				<textarea
					value={this.state.modelData.clipsData[index].text[lang]}
					className="form-control"
					style={{ minHeight: '50px' }}
					disabled={false}
					onChange={(x) => this.handleTextChange(x, index)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>
					<T v="DocumentationDetails.TakeSound" />{" "}
				</Label>
				<textarea
					value={this.state.modelData.clipsData[index].speechText[lang]}
					className="form-control"
					style={{ minHeight: '50px' }}
					disabled={false}
					onChange={(x) => this.handleSpeechTextChange(x, index)}
				/>
				<Button
					className="documentation-tab-button"
					color="primary"
					onClick={() => this.previewAnimationAudio(this.state.modelData ? this.state.modelData.clipsData[index].speechText[lang] : "")}
				>
					<T v={this.state.voiceSynth.speaking ? "DocumentationDetails.StopPreviewTakeSound" : "DocumentationDetails.PreviewTakeSound"} />
				</Button>
				<Button
					className="documentation-tab-button"
					color="secondary"
					onClick={() => this.handleSingleClipTextToSound(index)}
				>
					<T v="DocumentationDetails.CopyPreviewTakeSound" />
				</Button>
			</FormGroup>
		</div>);
	}

	private handleSpeechTextChange(x: React.ChangeEvent<HTMLTextAreaElement>, index: number): void {
		if (this.state.modelData) {
			var modelData = this.state.modelData;
			modelData.clipsData[index].speechText[this.state.selectedLanguage] = x.target.value;
			this.setState({ modelData: modelData });
		}
	}

	private handleTextChange(x: React.ChangeEvent<HTMLTextAreaElement>, index: number): void {
		if (this.state.modelData) {
			var modelData = this.state.modelData;
			modelData.clipsData[index].text[this.state.selectedLanguage] = x.target.value;
			this.setState({ modelData: modelData });
		}

	}

	private getRadius = (obj: any): any => {
		var box = new THREE.BoxHelper(obj, 0xff0000);
		box.geometry.computeBoundingSphere();

		if (Number.isNaN(box.geometry.boundingSphere.radius) || box.geometry.boundingSphere.radius == 0) {
			for (var i = 0; i < obj.children.length; i++) {
				if (!Number.isNaN(box.geometry.boundingSphere.radius) && box.geometry.boundingSphere.radius != 0) {
					return box;
				}
				else {
					console.log("OBJ radius is NaN! Re-iterating on " + obj.children[i].name);
					box = this.getRadius(obj.children[i]);
				}
			}
		}

		return box;
	}

	private previewAnimationAudio(text: string) {
		if (this.state.voiceSynth.speaking) {
			this.state.voiceSynth.cancel();

			this.setState({ loading: false });
		}
		else {
			this.state.voiceSynth.cancel();
			var utterThis = new SpeechSynthesisUtterance(text);
			var voice = this.state.voiceSynth.getVoices().find(x => x.lang.includes(navigator.language));

			if (voice) {
				console.log(voice.lang);
				utterThis.voice = voice;
				utterThis.onend = () => this.setState({ loading: false });
				this.state.voiceSynth.speak(utterThis);
			}

			this.setState({ loading: false });
		}
	}
}

const mapStateToProps = (state: IRootState) => ({
	files: toArray(state.files.data)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getClipsData: actions.getFileClipsData,
			setClipsData: actions.setFileClipsData,
			uploadModelThumbnailSuccess: actions.uploadFileThumbnailSuccess,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const TakeData = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withToasts(TakeDataBase));