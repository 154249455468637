import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { IQueueUser, ISession } from "../../types";
import { IDictionary } from "../../utilities";
import { IUserType, REMOTE_EXPERT } from "../../WebRTC/RTCHelpers";
import { handleRemoveParticipantStream } from "../ov";
import { Action, actions } from "./call-queue.actions";

export interface IState {
	activeCall: boolean;
	activeCallId: number | null;
	activeCallProductId: string | null;
	activeCallTicketId: string | null;
	activeCallConnected: boolean;
	holoLensEvents: string | null;
	userId: string | null;
	roomId: string | null;
	isCallWindowMinimized: boolean;
	connecting: boolean;
	connected: boolean;
	error: boolean;
	queue: IQueueUser[];
	sessions: ISession[];
	userType: IUserType;
	operatorName: string | null;
	operatorUserAgent: string | null;
	sound: HTMLAudioElement;
	participantsUsername: string | null;
	uploadValue: number,
}

export const initialState: IState = {
	activeCall: false,
	activeCallId: null,
	activeCallConnected: false,
	activeCallProductId: null,
	activeCallTicketId: null,
	holoLensEvents: null,
	userId: null,
	connecting: false,
	connected: false,
	isCallWindowMinimized: false,
	error: false,
	queue: [],
	sessions: [],
	roomId: null,
	userType: REMOTE_EXPERT,
	operatorName: null,
	operatorUserAgent: null,
	sound: new Audio("/images/queue.mp3"),
	participantsUsername: null,
	uploadValue: 0,
	// sound: new Audio("api/Files/GetCallSound"),
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.toggleMinimizeCallWindow):
			return {
				...state,
				isCallWindowMinimized: !state.isCallWindowMinimized,
			};
		case getType(actions.getParticipantUsername):
			return {
				...state,
				participantsUsername: action.payload.username
			};
		case getType(actions.getUploadProgressValue):
			return {
				...state,
				uploadValue: action.payload.value
			};
		case getType(actions.connectToSession):
			return {
				...state,
				activeCall: true,
				activeCallProductId: action.payload.productId,
				roomId: action.payload.roomId,
				userType: action.payload.userType,
			};
		case getType(actions.connectToPeer):
			return {
				...state,
				activeCall: true,
				activeCallId: action.payload.peerId,
				activeCallProductId: action.payload.productId,
				activeCallTicketId: action.payload.ticketId,
				holoLensEvents: action.payload.hololensEvents,
				userId: action.payload.userId,
				roomId: action.payload.roomId,
				userType: action.payload.userType,
				operatorName: action.payload.operatorName,
				operatorUserAgent: action.payload.userAgent,
			};
		case getType(actions.endCall):
			return {
				...state,
				activeCall: false,
				activeCallConnected: false,
				activeCallId: null,
				activeCallProductId: null,
				operatorName: null,
				operatorUserAgent: null,
			};
		case getType(actions.connect):
			return {
				...initialState,
				connecting: true,
			};
		case getType(actions.connected):
			return {
				...state,
				connecting: false,
				connected: true,
			};
		case getType(actions.signInSuccess):
		case getType(actions.listUpdate):
			return {
				...state,
				queue: action.payload,
			};
		case getType(actions.sessionListUpdate):
			return {
				...state,
				sessions: action.payload,
			};
		default:
			return state;
	}
};
