import React, { useEffect, useState } from "react";

// Component Import
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/files";
import { actions as companyActions } from "../../reducers/company";
import { actions as productActions } from "../../reducers/products";
import { IFile, IProduct, IClips, ICompany } from "../../types";
import { classNames, toArray } from "../../utilities";
import { Icon } from "../globalComponents";
import {
	handle3dModel,
	handleImageFile,
	handlePdfImage,
	handleFbxModel,
	handleVideoFile,
} from "../globalComponents/MobileFileHelper";
import DocumentationDetails from "./DocumentationDetails";
import "./DocumentationList.scss";
import { FbxHandler } from "../globalComponents/FbxHandler";
import ReactDOM from "react-dom";
import { PdfHandler } from "../globalComponents/PdfHandler";

const getProductIdFromPathName = (path: string) => {
	const pathArray = path.split("/");
	return pathArray[2];
};

const getFileIdFromPathName = (path: string) => {
	const pathArray = path.split("/");
	return pathArray.length >= 4 ? pathArray[4] : undefined;
};

const getFileList = (
	products: IProduct[],
	files: IFile[],
	productId: string
) => {
	const fileList: IFile[] = [];
	products.forEach((element) => {
		if (element.id === productId) {
			if (element.reference.length > 0) {
				element.reference.forEach((x) => {
					files.forEach((y) => {
						if (x === y.id && y.isVisibleToTemporaryUser) {
							fileList.push(y);
						}
					});
				});
			}
		}
	});
	return fileList;
};

const isRecentPage = (path: string) => {
	const pathArray = path.split("/");
	return pathArray[4] === "recent";
};

interface ITopMenuProps extends ConnectProps, RouteComponentProps<{ id: string }> {
	userRole: number;
	isMobile: boolean;
}

const DocumentationList: React.FunctionComponent<ITopMenuProps> = ({
	isMobile,
	userRole,
	location,
	history,
	products,
	files,
	company,
	getAllFiles
}) => {
	const [productId, setProductId] = useState<string>("");
	const [fileId, setFileId] = useState<string | undefined>("");
	const [recentPage, setRecentPage] = useState(false);
	const [modalEnabled, setModalEnabled] = useState<boolean>(false);
	const dispatch = useDispatch();
	const handleSetFileOfficial = () => null;
	const openLinkModal = () => null;
	let fileList: IFile[] = [];

	useEffect(() => {
		setProductId(getProductIdFromPathName(location.pathname));
		setFileId(getFileIdFromPathName(location.pathname));
		setRecentPage(isRecentPage(location.pathname));
		dispatch(companyActions.getMyCompany());
		getAllFiles();
		dispatch(productActions.getProducts());
		return () => {
			setModalEnabled(false);
		};
	}, [location]);

	const handleModal = async (file: IFile) => {
		const type = file.name.split(".").pop();
		let model: any;
		console.log(`[debug] realfileUri: ${file.realFileUri}`)
		switch (type) {
			case "glb":
			case "gltf":
				model = handle3dModel(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`);
				addElementToModal(model);
				break;
			case "fbx":
				dispatch(actions.getFileClipsData(file.id, (clipsData: IClips | null) => {
					if (clipsData == null) {
						clipsData = {
							id: file.id,
							clipsData: [],
						}
					}
					const divCurrentModel = document.createElement('div');
					divCurrentModel.id = "current-model-2";
					ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} clips={clipsData} allowArFunctions={company.allowArFunctions} activeCall={false} positioningModel={false} />, divCurrentModel);
					//model = handleFbxModel(file.realFileUri, clipsData, company.allowArFunctions);
					addElementToModal(divCurrentModel);
				}));
				break;
			case "png":
			case "jpg":
				model = handleImageFile(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`);
				addElementToModal(model);
				break;
			case "pdf":
				const divCurrentModel = document.createElement('div');
				divCurrentModel.id = "current-model-2";
				ReactDOM.render(<PdfHandler file={file} />, divCurrentModel);
				//model = await handlePdfImage(file)
				addElementToModal(divCurrentModel);
				break;
			case "mp4":
				model = await handleVideoFile(file);
				addElementToModal(model);
				break;
			default:
				break;
		}
	};

	const parseBoolean = (value: string) => {
		return value === "true";
	};

	const addElementToModal = (elem: any) => {
		const modalWindow = document.getElementById("modal-window");
		if (modalWindow) {
			const oldElem = modalWindow.querySelector("#current-model");
			if (oldElem) {
				modalWindow.replaceChild(elem, oldElem);
			} else {
				modalWindow.appendChild(elem);
			}
		}
		setModalEnabled(true);
	};

	const closeModalWindow = () => {
		setModalEnabled(false);
		const element = document.getElementById("current-model-2");
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
	};

	const getModalContainerClassNames = () => {
		const base = "modal-doc-container";
		const hidden = modalEnabled ? "" : "hidden";
		return classNames(base, hidden);
	};

	console.log(`fileId: ${fileId}`);
	
	if (fileId == undefined) {
		fileList = getFileList(products, files, productId);

		if (fileList.length === 0) {
			return null;
		}

		return (
			<React.Fragment>
				{fileList.map((file) => {
					const fileToSend: IFile[] = [];
					fileToSend.push(file);
					return (
						<div key={file.id}>
							<DocumentationDetails
								isCompanyNotSubordinate={company && company.mainCompanyId === null}
								files={fileToSend}
								onSetOfficial={handleSetFileOfficial}
								onLinkProduct={openLinkModal}
								handleModal={handleModal}
							/>
						</div>
					);
				})}
				<div className={getModalContainerClassNames()} id="modal-window">
					<button
						style={{ height: "48px", width: "48px" }}
						onClick={closeModalWindow}
					>
						<Icon name="arrow_back" style={{ fontSize: "24px" }} />
					</button>
				</div>
				{isMobile && <div style={{ height: "60px", color: "$primary" }} />}
			</React.Fragment>
		);
	}
	else {

		let file = files.find(x => x.id == fileId);

		if (file) handleModal(file);

		return (
			<React.Fragment>
				<div className={getModalContainerClassNames()} id="modal-window">
				</div>
			</React.Fragment>);
	}
};

const mapStateToProps = (state: IRootState) => ({
	files: toArray(state.files.data),
	isMobile: state.layout.isMobile,
	userRole: state.userInfo.data.userRole,
	products: toArray(state.products.data),
	company: state.company.data.company,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMyCompany: companyActions.getMyCompany,
			getAllFiles: actions.getAllFiles,
			getProducts: productActions.getProducts,
			getClipsData: actions.getFileClipsData,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentationList);
