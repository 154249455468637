import React from "react";
import { connect } from "react-redux";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { bindActionCreators, Dispatch } from "redux";
import { T, GetLangString } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { actions as callQueueActions } from "../../reducers/call-queue";
import { classNames, delay, toArray } from "../../utilities";
import { actions as userActions } from "../../reducers/users";
import { IDeviceStatus } from "../../utilities/checkDevice";
import { REMOTE_EXPERT, REMOTE_PARTICIPANT } from "../../WebRTC/RTCHelpers";
import { Icon, Spinner } from "../globalComponents";
import "./dashboard.scss";
import { IncomingCall } from "./incoming-call";
import { OngoingCall } from "./ongoing-call";
import { IQueueUser } from "../../types/IQueueUser";
import { Device, OpenVidu } from "openvidu-browser";
import { getCookieValue } from "../../reducers/call-queue/call-queue";
import { ISession, IUser } from "../../types";
import { UserRole } from "../../types/IRoles";
import { actions as companiesActions } from "../../reducers/companies";
import { actions as companyActions } from "../../reducers/company";

interface IDashboardBaseState {
	webcamConnected: boolean;
	audioConnected: boolean;
	videoDeviceList: Device[],
	videoDeviceIndex: number,
	isQueueFiltered: boolean,
	queue: IQueueUser[],
	sessions: ISession[],
}

class DashboardBase extends React.Component<ConnectProps, IDashboardBaseState> {
	webcamEnabled: boolean = false;
	public OV: OpenVidu = new OpenVidu();
	private SetWebcamEnabled(state: boolean) {
		this.webcamEnabled = state;
	}
	private errorCallback(error: any) {
		this.SetWebcamEnabled(false);
	}

	private successCallback(error: any) {
		this.SetWebcamEnabled(true);
	}

	public state: IDashboardBaseState = {
		webcamConnected: true,
		audioConnected: false,
		videoDeviceList: [],
		videoDeviceIndex: 1,
		isQueueFiltered: false,
		queue: [],
		sessions: [],
	};

	private containerStyle: React.CSSProperties = {
		paddingTop: 12,
	};

	public async componentWillMount() {
		var isReloaded = getCookieValue('isReloaded');

		const queue = await serverManager.queue.listAll();
		this.props.listUpdate(queue.data);
		
		if (isReloaded === "" && navigator.userAgent.toLowerCase().indexOf("iphone") !== -1 && this.props.loggedUser.userRole == UserRole.Expert) {
			document.cookie = "isReloaded=true";
			location.reload();
		}
		//this.handleDeviseCheck();
		navigator.mediaDevices.ondevicechange = event => {
			event.preventDefault();
		};
	}

	public filterQueue(): IQueueUser[] {
		var queueFiltered = getFilteredQueue(this.props.queue, this.props.loggedUser, this.props.users);
		return queueFiltered;//getFilteredQueue(this.props.queue, this.props.loggedUser, this.props.users);
	}


	public render() {
		const { connecting, loggedUser } = this.props;
		const { sessions } = this.state;

		const queue = this.filterQueue();

		
		console.log(`[foo] webcam connected: ${this.props.queue.length}`);
		if (!this.state.webcamConnected) {
			return (
				<Container fluid style={this.containerStyle}>
					<Row>
						<Col>
							<Card className="camera-warning-card">
								<Icon name="warning" className="md-48" />
								<h2>
									<T v="Dashboard.cameraError" />
								</h2>
								<T v="Dashboard.cameraErrorDescription" />
							</Card>
						</Col>
					</Row>
				</Container>
			);
		}

		if (connecting) {
			return (
				<Container fluid style={this.containerStyle}>
					<Row>
						<Col xs={12} md={6}>
							<Card style={{ display: "flex", padding: 24, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
								<Spinner style={{ height: 64, width: 64 }} />
							</Card>
						</Col>
						<Col xs={12} md={6}>
							<Card className="queue-card">
								<CardHeader>
									<T v="Dashboard.activeCallers" />
								</CardHeader>
								<CardBody>
									<T v="Dashboard.connecting" />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			);
		}

		return (
			<Container fluid style={this.containerStyle}>
				<Row>
					<Col xs={12} md={6}>
						<div style={{ textAlign: "center" }} className="queue-card p-5">
							{this.renderQueueStatus(queue)}
							<T v="Dashboard.inQueue" />
						</div>
						<Card className="queue-card">
							<CardHeader>
								<T v="Dashboard.activeCallers" />
							</CardHeader>
							<CardBody>
								{!this.isQueueEmpty(queue) &&
									queue.map(queueUser =>
									(<IncomingCall
										key={queueUser.peerId}
										onClick={() => {
											if (this.webcamEnabled) {
											
												this.props.connectToPeer(
													queueUser.peerId,
													queueUser.productId,
													queueUser.events,
													queueUser.userId,
													queueUser.roomId,
													REMOTE_EXPERT,
													queueUser.userName,
													queueUser.userAgent,
													queueUser.ticketId
												);
												//}
											}
											else {
												alert(GetLangString("ActiveCall.WebcamUnabled"));
											}
										}
										}
										queueUser={queueUser}
									/>)
									)
								}
								{this.isQueueEmpty(queue) && (
									<div className="dashboard-queue-placeholder">
										<Icon name="face" />
										<p>
											<T v="Dashboard.noCallersInQueue" />
										</p>
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
					<Col xs={12} md={6}>
						<Card className="queue-card">
							<CardHeader>
								<T v="Dashboard.activeCalls" />
							</CardHeader>
							<CardBody>
								{!this.isSessionsEmpty(sessions) ? (
									sessions.map(session => (
										<OngoingCall
											key={session.sessionId}
											onAccept={() => this.participantJoin(session)}
											session={session}
											onDecline={() => serverManager.queue.openToUser(session.sessionId, "")}
											hideDecline={session.participantToAdd !== this.props.loggedUser.userName}
										/>
									)))
									:
									(
										<div className="dashboard-queue-placeholder">
											<Icon name="phone" />
											<T v="Dashboard.noOngoingCalls" />
										</div>
									)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}

	private participantJoin = (session: ISession) => {
		serverManager.users.sendParticipantUsername(this.props.loggedUser.userName);
		this.props.connectToSession(session.productId, session.sessionId, this.props.loggedUser.userName, REMOTE_PARTICIPANT);
	}

	private async playCallSound(play: boolean) {
		this.props.sound.loop = true;
		this.props.sound.currentTime = 0;
		this.props.sound.load();
		if (play) {
			this.props.sound.play().then(() => {
				console.log("AUDIO IS PLAYING");
			});
		} else {
			this.props.sound.pause();
		}
	}

	private selectCamera(index: number): ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined {
		return () => { document.cookie = `selectedCamera=${index}` };
	}

	componentDidUpdate(prevProps: ConnectProps) {
		if (prevProps.sessions !== this.props.sessions) {
			const filteredSessions = this.props.sessions.filter(x => (this.props.loggedUser.teamId == x.teamId && x.isVisibleToTeam) || ((this.props.loggedUser.teamId == null || this.props.loggedUser.teamId == "") && x.isVisibleToGeneric) || (this.props.loggedUser.userName == x.participantToAdd));
			this.setState({ sessions: filteredSessions });
		}

		// if (prevProps.company !== this.props.company) {
		// 	this.props.updateUsedCallTime(this.props.company.id as string, 0);
		// }
	}

	private isQueueEmpty(queue: IQueueUser[]): boolean {
		return queue.length === 0;
	}

	private isSessionsEmpty(sessions: ISession[]): boolean {
		return sessions.length === 0;
	}

	private isQueueUserReady(queueUser: IQueueUser, secondsBeforeReady: number): boolean {
		return Math.ceil(Math.abs(new Date(queueUser.enqueuedAt).getTime() - new Date().getTime()) / 1000) <= secondsBeforeReady;
	}

	private isCallerReady(queueUser: IQueueUser, secondsBeforeReady: number): boolean {
		const isReady = Math.ceil(Math.abs(new Date(queueUser.enqueuedAt).getTime() - new Date().getTime()) / 1000) > secondsBeforeReady;
		queueUser.readyStatus = queueUser.readyStatus || 0;
		if (!isReady && queueUser.readyStatus == 0) {
			this.waitCallerReady(queueUser, secondsBeforeReady);
		}
		return isReady;
	}

	private async waitCallerReady(queueUser: IQueueUser, secondsBeforeReady: number) {
		queueUser.readyStatus = 1;
		while (this.isQueueUserReady(queueUser, secondsBeforeReady)) {
			await delay(100);
		}
		await delay(100);

		queueUser.readyStatus = 2;

		this.forceUpdate();
	}

	public async componentDidMount() {
		const sessions = await serverManager.queue.listSessionAll();

		this.props.getMyCompany();
		await this.OV.getDevices().then((devices) => {
			this.setState({
				videoDeviceList: devices.filter(
					(device) => device.kind === "videoinput"
				),
			});
		});
		if (sessions.status === 200) {
			this.props.sessionListUpdate(sessions.data);
		}
		this.props.getUsers();
		this.handleDeviseCheck();
	}

	private handleDeviseCheck = () => {
		navigator.mediaDevices.getUserMedia({ video: true }).then(this.successCheck(), this.errorCheck());
	};

	private handleDeviseStatus = (deviseConnected: IDeviceStatus) => {
		this.setState({ webcamConnected: deviseConnected.video, audioConnected: deviseConnected.audio });

		console.log("Device connected: ", deviseConnected);
	};

	private getQueueStatusColor = (queue: IQueueUser[]) => {
		if (queue.length === 0) {
			return "green";
		}

		if (queue.length < 3) {
			return "yellow";
		}

		return "red";
	};

	private renderQueueStatus = (queue: IQueueUser[]) => {
		const color = this.getQueueStatusColor(queue);
		const className = classNames("dashboard-queue-status-indicator", color);

		return <div className={className}>{queue.length.toString()}</div>;
	};

	private successCheck(): ((value: MediaStream) => void | PromiseLike<void>) | null | undefined {
		return (value) => {
			this.webcamEnabled = true;
			this.setState({ webcamConnected: true });
			value.getTracks().forEach(function (track) { track.stop(); })
		};
	}

	private errorCheck(): ((reason: any) => void | PromiseLike<void>) | null | undefined {
		return () => {
			this.webcamEnabled = false;
			this.setState({ webcamConnected: false });
		};
	}

}

export function getFilteredQueue(queue: IQueueUser[], loggedUser: IUser, users: IUser[] = []): IQueueUser[] {
	const isUserVisibleFilter = (queueUser: IQueueUser): boolean => {
		const user = users.find(x => x.id === queueUser.userId);
		//const isSameTeam = (loggedUser.teamId === queueUser.teamId) || (user !== undefined && user.isTemporary);
		const isVisible = user !== undefined && (!user.isTemporary || (user.isTemporary && user.createdBy == loggedUser.id));

		return isVisible;// && isSameTeam;
	};
	console.log(`[foo] queue: ${queue.length}`);
	const filteredQueue = queue.filter((queueUser, index) => queue.indexOf(queueUser) === index && isUserVisibleFilter(queueUser)); // duplicates check
	return filteredQueue;
}



const mapStateToProps = (state: IRootState) => ({
	loggedUser: state.userInfo.data,
	sound: state.callQueue.sound,
	activeCall: state.callQueue.activeCall,
	connected: state.callQueue.connected,
	connecting: state.callQueue.connecting,
	error: state.callQueue.error,
	queue: state.callQueue.queue,
	sessions: state.callQueue.sessions,
	users: toArray(state.users.data),
	remainingTime: state.companies.remainingTime,
	company: state.company.data.company,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			connectToPeer: callQueueActions.connectToPeer,
			connectToSession: callQueueActions.connectToSession,
			sessionListUpdate: callQueueActions.sessionListUpdate,
			getUsers: userActions.getUsers,
			listUpdate: callQueueActions.listUpdate,
			updateUsedCallTime: companiesActions.updateUsedCallTime,
			getMyCompany: companyActions.getMyCompany,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const Dashboard = connect(
	mapStateToProps,
	mapDispatchToProps,
)(DashboardBase);
