import { ActionType, createAction } from "typesafe-actions";
import { ICompany } from "../../types";

export const actions = {
	getMyCompany: createAction("data/company/getMyCompany"),
	getMyCompanyError: createAction("data/company/getMyCompanyError", resolve => (error: string) => resolve(error)),
	getMyCompanySuccess: createAction("data/company/getMyCompanySuccess", resolve => (company: ICompany) => resolve(company)),
};

export type Action = ActionType<typeof actions>;
