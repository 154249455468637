import { create } from "domain";
import { ActionType, createAction } from "typesafe-actions";
import { ToastType } from "../../components/toast";
import { ICompany, ICompanyCreate, ICompanyCreateDto, IApp, IUser, IAuthorizationCode } from "../../types";
import { ILicense, ILicenseNumber } from "../../types/ILicense";

export const actions = {
	getCompanies: createAction("data/company/getAll"),
	getCompaniesSuccess: createAction("data/company/getAllSuccess", resolve => (companies: ICompany[]) => resolve(companies)),
	getCompaniesError: createAction("data/company/getAllError", resolve => (err: string) => resolve(err)),

	getNonSubordinateCompanies: createAction("data/company/getNonSubordinateCompanies"),
	getNonSubordinateCompaniesSuccess: createAction("data/company/getNonSubordinateCompaniesSuccess", resolve => (nonSubordinateCompanies: ICompany[]) => resolve(nonSubordinateCompanies)),
	getNonSubordinateCompaniesError: createAction("data/company/getNonSubordinateCompaniesError", resolve => (err: string) => resolve(err)),
	
	getSubordinateCompaniesById: createAction("data/company/getSubordinateCompaniesById", resolve => (mainCompanyId: string) => resolve (mainCompanyId)),
	getSubordinateCompaniesByIdSuccess: createAction("data/company/getSubordinateCompaniesByIdSuccess", resolve => (subordinateCompanies: ICompany[]) => resolve(subordinateCompanies)),
	getSubordinateCompaniesByIdError: createAction("data/company/getSubordinateCompaniesByIdError", resolve => (err: string) => resolve(err)),

	createCompany: createAction("data/company/create", resolve => (dto: ICompanyCreateDto, callback?: (message: string, valid: boolean) => void, callbackLandingPage?: (message: string, company: ICompany | undefined) => void) =>
		resolve({ dto, callback, callbackLandingPage}),
	),
	createCompanyError: createAction("data/company/createError", resolve => (err: string) => resolve(err)),
	createCompanySuccess: createAction("data/company/createSuccess", resolve => (data: ICompany) => resolve(data)),
	
	deleteCompany: createAction("data/company/delete", resolve => (companyId: string, callback?: (message: string, valid: boolean) => void) =>
		resolve({ companyId, callback }),
	),
	deleteCompanySuccess: createAction("data/company/deleteSuccess", resolve => (companieId: string) => resolve(companieId)),
	deleteCompanyError: createAction("data/company/deleteError", resolve => (err: string) => resolve(err)),

	getSingleCompany: createAction("data/company/getSingle", resolve => (companyId: string) => resolve(companyId)),
	getSingleCompanyMissing: createAction("data/company/getSingleMissing"),
	getSingleCompanyError: createAction("data/company/getSingleError", resolve => (error: string) => resolve(error)),
	getSingleCompanySuccess: createAction("data/company/getSingleSuccess", resolve => (company: ICompany) => resolve(company)),

	addAuthCode: createAction("data/company/addAuthCode"),
	addAuthCodeSuccess: createAction("data/company/addAuthCodeSuccess", resolve => (authCode: IAuthorizationCode) => resolve(authCode)),
	addAuthCodeError: createAction("data/company/addAuthCodeError", resolve => (error: string) => resolve(error)),
	
	addCrmEntryPoint: createAction("data/company/addCrmEntryPoint", resolve => (authCode: string, entryPoint: string) => resolve({ authCode, entryPoint })),
	addCrmEntryPointSuccess: createAction("data/company/addCrmEntryPointSuccess", resolve => (entryPoint: string) => resolve(entryPoint)),
	addCrmEntryPointError: createAction("data/company/addCrmEntryPointError", resolve => (error: string) => resolve(error)),

	deleteAuthCode: createAction("data/company/deleteAuthCode", resolve => (code: string) => resolve(code)),
	deleteAuthCodeSuccess: createAction("data/company/deleteAuthCodeSuccess", resolve => (code: string) => resolve(code)),
	deleteAuthCodeError: createAction("data/company/deleteAuthCodeError", resolve => (error: string) => resolve(error)),

	getAuthCode: createAction("data/company/getAuthCode"),
	getAuthCodeSuccess: createAction("data/company/getAuthCodeSuccess", resolve => (authCodes: IAuthorizationCode[]) => resolve(authCodes)),
	getAuthCodeError: createAction("data/company/getAuthCodeError", resolve => (error: string) => resolve(error)),

	updateCompany: createAction("data/company/update", resolve => (dto: ICompany, callback?: (message: string, valid: boolean) => void) =>
		resolve({ dto, callback }),
	),
	updateCompanySuccess: createAction("data/company/updateSuccess", resolve => (data: ICompany) => resolve(data)),
	updateCompanyError: createAction("data/company/updaterror", resolve => (err: string) => resolve(err)),

	updateUsedCallTime: createAction("data/company/updateUsedCallTime", resolve => (companyId: string, time: number, callback?: (message: string, type: ToastType) => void) => resolve({ companyId, time, callback }) ),
	updateUsedCallTimeError: createAction("data/company/updateUsedCallTimeError", resolve => (err: string) => resolve(err)),
	updateUsedCallTimeSuccess: createAction("data/company/updateUsedCallTimeSuccess", resolve => (remainingTime: number) => resolve(remainingTime)),

	getCompanyQRCode: createAction("data/company/getCompanyQRCode"),
	getCompanyQRCodeError: createAction("data/company/getCompanyQRCodeError", resolve => (err: string) => resolve(err)),
	getCompanyQRCodeSuccess: createAction("data/company/getCompanyQRCodeSuccess", resolve => (qrCode: Blob) => resolve(qrCode)),

	sendHelp: createAction("data/company/sendHelp", resolve => (userId: string, message: string, callback?: (message: string, valid: boolean) => void) =>
		resolve({ userId, message, callback }),
	),
	sendHelpError: createAction("data/company/sendHelpError", resolve => (err: string) => resolve(err)),
	sendHelpSuccess: createAction("data/company/sendHelpSuccess", resolve => () => resolve()),

	getApps: createAction("data/auth/getAll"),
	getAppsError: createAction("data/auth/getAllError", resolve => (err: string) => resolve(err)),
	getAppsSuccess: createAction("data/auth/getAllSuccess", resolve => (apps: IApp[]) => resolve(apps)),

	setApp: createAction("data/auth/set", resolve => (dto: IApp, callback?: (message: string, valid: boolean) => void) =>
		resolve({ dto, callback }),
	),
	setAppError: createAction("data/auth/setError", resolve => (err: string) => resolve(err)),
	setAppSuccess: createAction("data/auth/setSuccess", resolve => (apps: IApp) => resolve(apps)),

	removeApp: createAction("data/auth/remove", resolve => (dto: string, callback?: (message: string, valid: boolean) => void) =>
		resolve({ dto, callback }),
	),
	removeAppError: createAction("data/auth/removeError", resolve => (err: string) => resolve(err)),
	removeAppSuccess: createAction("data/auth/removeSuccess", resolve => (app: string) => resolve(app)),

	getAllLicenses: createAction("data/license/getAll"),
	getAllLicensesSuccess: createAction("data/license/getAllSuccess", resolve => (licenses: ILicenseNumber[]) => resolve(licenses)),
	getAllLicensesError: createAction("data/license/getAllError", resolve => (error: string) => resolve(error)),

	getLicensesByCompanyId: createAction("data/license/getLicensesByCompanyId", resolve => (companyId: string) => resolve(companyId)),
	getLicensesByCompanyIdSuccess: createAction("data/license/getLicensesByCompanyIdSuccess", resolve => (licenses: ILicenseNumber[]) => resolve(licenses)),
	getLicensesByCompanyIdError: createAction("data/license/getLicensesByCompanyIdError", resolve => (error: string) => resolve(error)),

	createLicensesForCompany: createAction("data/license/createLicenseForCompany", resolve => (companyId: string, licenses: ILicenseNumber[], callback: (msgId: string, toastType: ToastType) => void) => resolve({ companyId, licenses, callback })),
	createLicensesForCompanySuccess: createAction("data/license/createLicenseForCompanySuccess", resolve => (licenses: ILicenseNumber[]) => resolve(licenses)),
	createLicensesForCompanyError: createAction("data/license/createLicenseForCompanyError", resolve => (error: string) => resolve(error)),

	updateLicensesRenewalMonths: createAction("data/license/updateLicensesRenewalMonths", resolve => (licenses: ILicenseNumber[]) => resolve(licenses)),
	updateLicensesRenewalMonthsSuccess: createAction("data/license/updateLicensesRenewalMonthsSuccess", resolve => (licenses: ILicenseNumber[]) => resolve(licenses)),
	updateLicensesRenewalMonthsError: createAction("data/license/updateLicensesRenewalMonthsError", resolve => (error: string) => resolve(error)),

	deleteLicenses: createAction("data/license/deleteLicenses", resolve => (companyId: string, callback?: (msgId: string, type: ToastType) => void) => resolve({ companyId, callback })),
	deleteLicensesSuccess: createAction("data/license/deleteLicensesSuccess"),
	deleteLicensesError: createAction("data/license/deleteLicensesError", resolve => (error: string) => resolve(error)),

	putUserCheckLicense: createAction("data/license/putUserCheckLicense", resolve => (companyId: string, userId: string) => resolve({ companyId, userId })),
	putUserCheckLicenseSuccess: createAction("data/license/putUserCheckLicenseSuccess", resolve => (result: boolean) => resolve(result)),
	putUserCheckLicenseError: createAction("data/license/putUserCheckLicenseError", resolve => (error: string) => resolve(error)),
};

export type Action = ActionType<typeof actions>;
