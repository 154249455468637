import moment from "moment";
import React from "react";
import { MODEL_PLACEHOLDER_URL, PDF_PLACEHOLDER_URL, PRODUCT_PLACEHOLDER_URL, VIDEO_PLACEHOLDER_URL } from "../../constants";
import { T } from "../../managers/I18n";
import { IFile, ITicketItem } from "../../types";
import { isModalView } from "../documentation/DocumentationDetails";
import { MobileImage } from "../globalComponents";
import { getMedia, getTicketAddon } from "./GetMedia";
import { getTicketDescription, getTicketIcon, getTicketTitle } from "./TicketItemText";
import "./TimeLineItem.scss";

export interface ITicketItemStartProps {
	item: ITicketItem;
	handleModal?: (file: IFile) => Promise<void | undefined>;
}

const TicketItemMediaMobile: React.FunctionComponent<ITicketItemStartProps> = (props: ITicketItemStartProps) => {
	const file = props.item.linkedObject as IFile;
	const modalView = isModalView(file);
	let thumbnailUrl = "";
	if (file.thumbnailUrl == 'generic://model') {
		thumbnailUrl = MODEL_PLACEHOLDER_URL;
	}
	else if (file.thumbnailUrl == 'generic://video') {
		thumbnailUrl = VIDEO_PLACEHOLDER_URL;
	} else if (file.thumbnailUrl == 'generic://pdf') {
		thumbnailUrl = PDF_PLACEHOLDER_URL;
	} else if (file.thumbnailUrl == 'generic://image/png') {
		thumbnailUrl = PRODUCT_PLACEHOLDER_URL;
	} else {
		thumbnailUrl = `${process.env.REACT_APP_LOCALHOST}${file.thumbnailUrl}`;
	}
	if (modalView) {
		console.log("file url: " + file.thumbnailUrl);
		return (
			<div>
				<div className="product-card-container-mobile" onClick={async () => await props.handleModal!(file)}>
					<MobileImage imgSrc={thumbnailUrl} width={80} height={80} />
					<div className="product-card-header-mobile">
						<div className="product-card-header-text-mobile">
							<p>
								{file.name}
							</p>
							<span>
								{moment
									.utc(props.item.date)
									.local()
									.format("LL")}
							</span>
						</div>
					</div>
				</div>
			</div>
		);

	} else {
		return (

			<div>
				<a href={file.realFileUri} target="_blank">
					<div className="product-card-container-mobile" >
						<MobileImage imgSrc={thumbnailUrl} width={80} height={80} />
						<div className="product-card-header-mobile">
							<div className="product-card-header-text-mobile">
								<p>
									{file.name}
								</p>
								<span>
									{moment
										.utc(props.item.date)
										.local()
										.format("LT")}
								</span>
							</div>
						</div>
					</div>
				</a>
			</div>
		);

	}
};

export default TicketItemMediaMobile;
