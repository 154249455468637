import React from "react";
import { classNames } from "../../utilities/class-names";
import { OriginalHtmlProps } from "../../utilities/html-props";
import { Icon } from "./Icon";
import "./IconButton.scss";
import { Spinner } from "./Spinner";

export type IconButtonProps = OriginalHtmlProps<HTMLButtonElement> & {
	iconName: string;
	showSpinner?: boolean;
};

export const IconButton: React.FunctionComponent<IconButtonProps> = ({ iconName, className, showSpinner, ...rest }) => (
	<button className={classNames("app_icon_button", className)} {...rest}>
		{showSpinner ? <Spinner /> : <Icon name={iconName} />}
	</button>
);
