import React from "react";
import { Table } from "reactstrap";
import { GetLangString, T } from "../../managers/I18n";
import { Id, ITicket, IUser, TicketStatus } from "../../types";
import { UserRole } from "../../types/IRoles";
import { isMobile } from "../../utilities";
import { sortByKey } from "../../utilities/sortByKey";
import { WorkLogItemRow } from "./WorkLogItemRow";
import "./WorkLogList.scss";

interface IWorkLogListProps {
	userInfo: IUser;
	workLogs: ITicket[];
	productId: Id;
	goToWorkLog: (workLog: Id) => void;
	getTicketId: (ticketId: string) => void;
	getDeselectedTicketId: (ticketId: string) => void;
	sortOrder: string;
	setSortOrder: (sortOrder: string) => void;
	sortOrderDesending: boolean;
}

export const WorkLogsList: React.FunctionComponent<IWorkLogListProps> = ({ userInfo, workLogs, getTicketId, getDeselectedTicketId, goToWorkLog, setSortOrder, sortOrder, sortOrderDesending }) => {
	const sortedList = processTicketsList(userInfo, workLogs, sortOrder, sortOrderDesending);

	return (
		<React.Fragment>
			{sortedList.length > 0 ? <Table striped hover responsive>
				<thead>
					<tr className="hide-mobile">
						{userInfo.userRole >= UserRole.Expert && <th><T v="CallLogList.Selection" /></th>}
						<th onClick={() => setSortOrder("ticketId")}><T v="CallLogList.TicketID" /></th>
						<th onClick={() => setSortOrder("start")}><T v="CallLogList.Started" /></th>
						<th onClick={() => setSortOrder("operatorName")}><T v="CallLogList.Operator" /></th>
						<th onClick={() => setSortOrder("expertName")}><T v="CallLogList.Expert" /></th>
						<th onClick={() => setSortOrder("status")}><T v="CallLogList.Status" /></th>
						<th onClick={() => setSortOrder("summary")}><T v="CallLogList.Summary" /></th>
					</tr>
				</thead>
				<tbody>
					{sortedList.map((workLog: any, index: number) => (
						<tr key={workLog.id} className="worklog-row">
							<WorkLogItemRow userInfo={userInfo} getDeselectedTicketId={getDeselectedTicketId} getTicketId={getTicketId} goToWorkLog={goToWorkLog} worklogItem={workLog} index={index} />
						</tr>

					))}
				</tbody>
			</Table> :
				userInfo.userRole === UserRole.Operator && <p style={{ position: "fixed", top: "50%", width: "100%", textAlign: "center" }}>{GetLangString("Ticket.NoTickets")}</p>
			}
			{<div style={{ height: "60px", color: "$primary" }} />}
		</React.Fragment>
	);
}


const processTicketsList = (userInfo: IUser, worklogs: ITicket[], sortOrder: string, sortOrderDescending: boolean) => {
	if (worklogs === undefined)
		return [];

	if (userInfo.isTemporary) {
		// var filteredWorkLogs = worklogs.filter(ticket => ticket.status === TicketStatus.Open);

		var orderedWorklogs = sortByKey(worklogs, sortOrder, sortOrderDescending);

		return orderedWorklogs;
	} else {
		return sortByKey(worklogs, sortOrder, sortOrderDescending);
	}
};