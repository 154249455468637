import React from "react";
import Button from "reactstrap/lib/Button";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import { Message, T } from "../../managers/I18n";
import { ITroubleshootingLanguage } from "../../types";
import { Spinner } from "../globalComponents";
import "./TroubleshootingLanguageSelector.scss";

interface ITroubleshootingLanguageSelectorState {
	isOpen: boolean;
}

interface ITroubleshootingLanguageSelectorProps {
	languages: ITroubleshootingLanguage[];
	loading: boolean;
	onLanguageSelected: (langId: string) => void;
	selectedLanguageIds: string[];
}

export class TroubleshootingLanguageSelector extends React.Component<ITroubleshootingLanguageSelectorProps, ITroubleshootingLanguageSelectorState> {
	public spinnerStyle: React.CSSProperties = {
		display: "block",
		marginTop: 4,
		marginLeft: "auto",
		marginRight: "auto",
	};

	public state: ITroubleshootingLanguageSelectorState = {
		isOpen: false,
	};

	public render() {
		const { isOpen } = this.state;
		const { loading } = this.props;
		return (
			<Dropdown isOpen={isOpen} toggle={this.onToggle}>
				<DropdownToggle caret>
					{this.getLanguageTextPrefix()}
					<Message id={this.getSelectedLanguagesId()} />
				</DropdownToggle>
				<DropdownMenu>
					<div className={"languages-dropdown-container"}>
						{loading ? <Spinner style={this.spinnerStyle} /> : this.renderItems()}
						<Button onClick={this.onToggle}>Ok</Button>
					</div>
				</DropdownMenu>
			</Dropdown>
		);
	}

	private getLanguageTextPrefix = () => {
		const { length } = this.props.selectedLanguageIds;
		if (length > 1) {
			return length.toString() + " ";
		}

		return "";
	};

	private getSelectedLanguagesId = () => {
		const { length } = this.props.selectedLanguageIds;
		switch (length) {
			case 0:
				return "TroubleshootingLanguages.SelectedTextNone";
			case 1:
				return "TroubleshootingLanguages.SelectedTextSingular";
			default:
				return "TroubleshootingLanguages.SelectedTextPlural";
		}
	};

	private renderItems = () => {
		const { languages, loading } = this.props;
		if (!languages.length && !loading) {
			return <T v="TroubleshootingLanguages.NoneAvailable" />;
		}

		return languages.map(lang => (
			// <DropdownItem key={lang.id}>
			<FormGroup key={lang.id} check={true}>
				<Label check={true}>
					<Input onChange={() => this.props.onLanguageSelected(lang.id)} checked={this.isSelected(lang.id)} type="checkbox" />
					{lang.name}
				</Label>
			</FormGroup>
			// </DropdownItem>
		));
	};

	private isSelected = (languageId: string) => {
		const { selectedLanguageIds } = this.props;
		return selectedLanguageIds.indexOf(languageId) !== -1;
	};

	private onToggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};
}
