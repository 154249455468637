import any from "ramda/es/any";
import * as React from "react";
import { Button } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardFooter from "reactstrap/lib/CardFooter";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import LicenseHandler from "./LicenseHandler";
import { GetLangString, T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IUser, IUserCreate } from "../../types";
import { ICompanyAdmin, ICompany, ICompanyCreate } from "../../types/ICompany";
import { objectDiff, toArray } from "../../utilities";
import { logger } from "../../utilities/logger";
import { Icon, IconButton, IconTextButton, LoadingPage } from "../globalComponents";
import { Spinner } from "../globalComponents/Spinner";
import { DateString } from "../../types/helpers";
import "./CompanyInformation.scss";
import moment from "moment";
import { actions } from "../../reducers/companies";
import { actions as productActions } from "../../reducers/products";
import { userInfo } from "os";
import { UserRole } from "../../types/IRoles";
import { ICreatedLicense, ILicenseNumber } from "../../types/ILicense";
import { connect } from "react-redux";
import { IRootState } from "../../reducers/root-reducer";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import "./LicenseHandler.scss";
import { LinkSubordinateToMain } from "./LinkSubordinateToMain";
import { withScroll } from "react-fns";
import { CompanySubordinateList } from "./CompanySubordinateList";


interface ItemProps extends ConnectProps, InjectedToastsProps {
	company: ICompany | any;
	isSuperAdmin: boolean;
	isSaving: boolean;
	isEditing: boolean;
	canEdit: boolean;
	nonSubordinateCompanies: ICompany[];
	onSaveClicked: (newCompany: ICompany) => void;
	onDeleteClicked: (company: ICompany) => void;
}

interface ItemState {
	loading: boolean;
	isEditing: boolean;
	company: ICompany;
	cancelCompany: ICompany;
	companyNameValid: boolean;
	companyNameInValid: boolean;
	officialNameValid: boolean;
	officialNameInValid: boolean;
	isDateValid: boolean;
	licenses: ILicenseNumber[];
	numberOfLicenses: string;
	startDate: string;
	endDate: string;
	showLicenses: boolean;
	unmutableLicenseIndex: number;
}

class CompanyInfromationBase extends React.Component<ItemProps, ItemState> {
	public state: Readonly<ItemState> = {
		company: {
			id: "",
			name: "",
			officialName: "",
			logoUrl: "",
			address: "",
			postCode: "",
			state: "",
			city: "",
			country: "",
			licenseInformation: "",
			mainCompanyId: null,
			allowTemporaryUsers: false,
			allowArFunctions: false,
			allowTeams: false,
			allowApiUsage: false,
			allowMinuteRate: false,
			notificationsEmail: "",
			expirationDate: moment().format("YYYY-MM-DD"),
			maximumCallTime: undefined,
			totalUsedMinutes: 0,
			totalExtraMinutes: 0,
			creationDate: moment().format("YYYY-MM-DD"),
			renewalMonths: 0,
			uploadLimit: 0,
			storageLimit: 0,
		},
		cancelCompany: ({} as any) as ICompany,
		isDateValid: true,
		isEditing: false,
		loading: false,
		// Validation
		companyNameValid: false,
		companyNameInValid: false,
		officialNameValid: false,
		officialNameInValid: false,
		licenses: [],
		numberOfLicenses: "",
		startDate: "",
		endDate: "",
		showLicenses: true,
		unmutableLicenseIndex: 0,
	};

	public componentDidMount() {
		this.props.getNonSubordinateCompanies();

		this.props.getLicensesByCompanyId(this.props.company.id);
		this.setState({ company: this.replaceNullWithEmptyString(this.props.company) });
		this.props.getSubordinateCompaniesById(this.props.company.id);
		if (this.props.company.mainCompanyId !== null) {
			this.props.getProductsByCompanyId(this.props.company.mainCompanyId);
		}
		else {
			this.props.getProductsByCompanyId(this.props.company.id);
		}
	}

	public componentDidUpdate(prevProps: ItemProps) {

		if (prevProps.company.id !== this.props.company.id) {
			this.setState({ isEditing: false });
			if (this.props.company.mainCompanyId !== null) {
				this.props.getProductsByCompanyId(this.props.company.mainCompanyId);
			}
			else {
				this.props.getProductsByCompanyId(this.props.company.id);
			}
		}

		if (prevProps !== this.props && !this.props.isSaving) {
			this.setState({ company: this.replaceNullWithEmptyString(this.props.company) });
		}

		if (prevProps !== this.props && this.props.company.id === "" && !this.props.isSaving) {
			this.setState({ company: this.replaceNullWithEmptyString(this.props.company) });
		}

		if (prevProps.licenses !== this.props.licenses) {
			this.setState({ unmutableLicenseIndex: this.props.licenses.length - 1, licenses: this.props.licenses })
		}
	}

	private createCheckboxForTemporaryUsers(langString: string, companyFieldName: keyof ICompany, companyFieldValue: string, editMode: boolean): JSX.Element {
		return (
			<div style={{ width: "50%", height: 58, whiteSpace: "nowrap" }}>
				<Col className="gutter-right" xs={12} md={6}>
					<Row>
						<Label>
							<T v={langString} />
						</Label>
						<Input
							id={companyFieldName}
							style={{ width: 28, height: 28, left: 'true', marginLeft: 0, marginTop: 28 }}
							type={"checkbox"}
							checked={companyFieldValue === "true"}
							disabled={editMode}
							className={editMode ? "disabled" : ""}
							onChange={(event) => this.handleToggleBoolCompanyChange(companyFieldName, event)}
						/>
					</Row>
				</Col>
			</div>
		)
	}

	private createCheckboxElement(langString: string, companyFieldName: keyof ICompany, companyFieldValue: string | boolean, editMode: boolean): JSX.Element {
		return (
			<div style={{ width: "50%", height: 58, whiteSpace: "nowrap" }}>
				<Col className="gutter-right" xs={12} md={6}>
					<Row>
						<Label>
							<T v={langString} />
						</Label>
						<Input
							id={companyFieldName}
							style={{ width: 28, height: 28, left: 'true', marginLeft: 0, marginTop: 28 }}
							type={"checkbox"}
							checked={companyFieldValue === "true"}
							disabled={editMode}
							className={editMode ? "disabled" : ""}
							onChange={(event) => this.handleToggleBoolCompanyChange(companyFieldName, event)}
						/>
					</Row>
				</Col>
			</div>
		)
	}

	private setMainCompany = (id: string | null) => this.setState({ company: { ...this.state.company, mainCompanyId: id } });

	public render() {
		const { isEditing, company, loading, companyNameValid, companyNameInValid, officialNameValid, officialNameInValid } = this.state;
		const { isSaving } = this.props;
		const editMode = this.returnEditMode(isEditing, isSaving);
		var uploadLimitFlag: boolean;

		// const imageUrl = serverManager.products.getProductImageUrl(item.id);

		if (loading) {
			return <LoadingPage />;
		}

		if (!company) {
			return null;
		}

		if (company.uploadLimit != 0) {
			uploadLimitFlag = true;
		}

		return (
			<div className="company-detail-container">
				<div className="company-detail-actionbar">
					<div>
						{!this.props.isSuperAdmin && <h4 className="company-list-items">{company.officialName ? company.officialName : "Company name..."}</h4>}
						{this.props.isSuperAdmin && <h4 className="company-list-items">{company.officialName ? company.officialName + `` : "Company name..."}</h4>}
					</div>
					{this.renderActionButton()}
				</div>
				<Card className="company-details">
					<CardBody>
						{/* <div className="hide-desktop">
							<img className="product-details-image-mobile" src={imageUrl} />
						</div> */}
						<Form>
							<Row noGutters>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.Name"} />
										</Label>
										<Input
											valid={companyNameValid}
											invalid={companyNameInValid}
											onBlur={this.handleCompanyNameValidation}
											disabled={true}
											className={"disabled"}
											//onChange={this.handleCompanyChange("name")}
											value={company.name}
										/>
									</FormGroup>
								</Col>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.OfficialName"} />
										</Label>
										<Input
											onBlur={this.handleOfficialNameValidation}
											valid={officialNameValid}
											invalid={officialNameInValid}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("officialName")}
											value={company.officialName}
										/>
									</FormGroup>
								</Col>

								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.LicenseInformation"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("licenseInformation")}
											value={company.licenseInformation}
										/>
									</FormGroup>
								</Col> */}

								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.RenewalMonths"} />
										</Label>
										<Input 
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("renewalMonths")}
											value={company.renewalMonths}
										/>
									</FormGroup>
								</Col> */}

								{this.props.isSuperAdmin && (<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.NotificationsEmail"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("notificationsEmail")}
											value={company.notificationsEmail}
										/>
									</FormGroup>
									{/* {this.props.isSuperAdmin && <FormGroup>
										<Label>
											<T v="Companies.TotalUsedMinutes" />
										</Label>
										<Input 
											disabled={true}
											className={"disabled"}
											value={company.totalUsedMinutes}
										/>
									</FormGroup>}
									{this.props.isSuperAdmin && <FormGroup>
										<Label>
											<T v="Companies.TotalExtraMinutesReload"/>
										</Label>
										<Input 
											disabled={true}
											className={"disabled"}
											value={company.totalExtraMinutes}
										/>
									</FormGroup>} */}
								</Col>
								)}

								{/* <Col className="gutter-right" xs={12} md={6}> */}
								{/* <FormGroup>
										<Label>
											<T v={"Companies.ExpirationDate"} />
										</Label>
										<Input
											min={(moment().format("YYYY-MM-DD"))}

											valid={this.state.isDateValid}
											invalid={!this.state.isDateValid}
											onBlur={this.handleCompanyDateValidation}

											type={"date"}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("expirationDate")}
											value={moment(company.expirationDate).format("YYYY-MM-DD")}
										/>

									</FormGroup> */}

								{/* <FormGroup>
										<Label>
											<T v={"Companies.MaximumCallTime"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disable" : ""}
											onChange={this.handleCompanyChange("maximumCallTime")}
											value={company.maximumCallTime}
										/>
									</FormGroup> */}
								{/* </Col> */}
								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.CreationDate"} />
										</Label>
										<Input
											min={(moment().add(-4, 'months').format("YYYY-MM-DD"))}
											type={"date"}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("creationDate")}
											value={moment(company.creationDate).format("YYYY-MM-DD")}
										/>
									</FormGroup>
								</Col> */}
								{/* </Col> */}
								{console.log("[debug]numero di subordinate alla main company " + company.id + ": " + this.props.subordinateCompaniesById.length)}
								{this.props.isSuperAdmin && <Col className="gutter-right" xs={12} md={6}>
									{(this.props.subordinateCompaniesById && this.props.subordinateCompaniesById.length > 0) ? (
										<CompanySubordinateList companiesToShow={this.props.subordinateCompaniesById} />
									) : (
										<LinkSubordinateToMain
											nonSubordinateCompanies={this.props.nonSubordinateCompanies.filter(x => x.id !== company.id)}
											isEditing={!editMode}
											company={company}
											selectItem={this.setMainCompany}
											hasProducts={this.checkHasOwnProducts(company)}
										/>)
									}
								</Col>}
							</Row>

							<Row noGutters className="company-details-permits">
								{this.props.isSuperAdmin && this.createCheckboxElement("Companies.AllowTemporaryUsers", "allowTemporaryUsers", company.allowTemporaryUsers, editMode)}
								{this.props.isSuperAdmin && this.createCheckboxElement("Companies.allowArFunctions", "allowArFunctions", company.allowArFunctions.toString(), editMode)}
								{this.props.isSuperAdmin && this.createCheckboxElement("Companies.allowTeams", "allowTeams", company.allowTeams.toString(), editMode)}
								{this.props.isSuperAdmin && this.createCheckboxElement("Companies.allowApiUsage", "allowApiUsage", company.allowApiUsage.toString(), editMode)}
								{/* {this.props.isSuperAdmin && this.createCheckboxElement("Companies.allowMinuteRate", "allowMinuteRate", company.allowMinuteRate, editMode)} */}
							</Row>



							<Row noGutters className="company-details-permits">
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.UploadSizeLimit"} />
										</Label>
										<Input
											pattern="[0-9]" 
											type="number"
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("uploadLimit")}
											value={company.uploadLimit}
										/>
									</FormGroup>
								</Col>
								<FormGroup>
									<Label>
										<T v={"Companies.StorageLimit"} />
									</Label>
									<Input
										pattern="[0-9]" 
										type="number"
										disabled={editMode}
										className={editMode ? "disabled" : ""}
										onChange={this.handleCompanyChange("storageLimit")}
										value={company.storageLimit}
									/>
								</FormGroup>
							</Row>

							<Row noGutters className="company-details-adress" >
								<Col className="gutter-right" xs={12} md={12}>
									<FormGroup>
										<Label>
											<T v={"Companies.Address"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("address")}
											value={company.address}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.City"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("city")}
											value={company.city}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.PostCode"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("postCode")}
											value={company.postCode}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.State"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("state")}
											value={company.state}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.Country"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("country")}
											value={company.country}
										/>
									</FormGroup>
								</Col>

								<Col xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v="Licenze" />
										</Label>

										<LicenseHandler licenses={this.state.licenses} editMode={editMode} handleChange={this.handleChange} />
										{console.log("[TEST]" + this.state.licenses.length)}
										{
											this.state.licenses.length <= this.props.licenses.length && isEditing && <Button disabled={editMode} onClick={this.handleAddLicense}>
												<Icon name="add" />
											</Button>}
										{this.state.licenses.length > this.props.licenses.length && isEditing && <Button disabled={editMode} onClick={this.handleOnClick}>
											<Icon name="remove" />
										</Button>}
									</FormGroup>
								</Col>

								{isEditing && this.state.licenses.length > this.props.licenses.length && <Col xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v="Licenses.NewLicense" />
										</Label>
										{this.state.licenses.length > 0 &&
											<div className="card-height">
												<Card>
													<div id="newLicense" className="license-card">
														<CardBody>
															<Label>{GetLangString("Companies.LicenseInformation")}</Label><Input id={`${(this.state.licenses.length - 1).toString()}`} type="text" placeholder="Inserisci numero licenze" onChange={this.handleChange("totalLicenses")} value={this.state.licenses[this.state.licenses.length - 1].totalLicenses} />
															<Label>{GetLangString("Companies.RenewalMonths")}</Label><Input id={`${(this.state.licenses.length - 1).toString()}`} type="text" placeholder="Inserisci mesi di rinnovo" onChange={this.handleChange("renewalMonths")} value={this.state.licenses[this.state.licenses.length - 1].renewalMonths} />
															<Label>{GetLangString("Companies.CreationDate")}</Label><Input id={`${(this.state.licenses.length - 1).toString()}`} type="date" onChange={this.handleChange("startDate")} value={moment(this.state.licenses[this.state.licenses.length - 1].startDate).format("YYYY-MM-DD")} />
															<Label>{GetLangString("Companies.ExpirationDate")}</Label><Input id={`${(this.state.licenses.length - 1).toString()}`} type="date" onChange={this.handleChange("endDate")} value={moment(this.state.licenses[this.state.licenses.length - 1].endDate).format("YYYY-MM-DD")} />
														</CardBody>
													</div>
												</Card>
											</div>
										}
										{/* 
										 <div>
										 {this.state.licenses.map((license, index) => {
											console.log(`end date: ${license.endDate}`);
											return (
												<React.Fragment>
													<Input id={`${index.toString()}`} disabled={index <= this.props.licenses.length - 1} type="text" placeholder="Numero di licenze" onChange={this.handleChange("totalLicenses")} value={license.totalLicenses}/>
													<Input id={`${index.toString()}`} disabled={editMode} type="text" placeholder="Mesi di rinnovo" onChange={this.handleChange("renewalMonths")} value={license.renewalMonths}/>
													<Input id={`${index.toString()}`} disabled={index <= this.props.licenses.length - 1} type="date" onChange={this.handleChange("startDate")} value={moment(license.startDate).format("YYYY-MM-DD")} />
													<Input id={`${index.toString()}`} disabled={index <= this.props.licenses.length - 1} type="date" onChange={this.handleChange("endDate")} value={moment(license.endDate).format("YYYY-MM-DD")} />
												</React.Fragment>
											);
										})} </div> */}
									</FormGroup>
								</Col>
								}
							</Row>

						</Form>

						{isEditing && (
							<Row style={{ justifyContent: "flex-end", display: "flex", padding: "1%" }}>
								{this.props.licenses.length > 0 && <IconTextButton color="primary" iconName="disabled_by_default" text="Companies.Disable" onClick={this.handleDisableButtonClick} />}
								{this.props.licenses.length > 0 && <IconTextButton color="primary" iconName="disabled_by_default" text="Companies.Reset" onClick={this.resetCompanyLicenses} />}
								<IconTextButton color="primary" iconName="delete_forever" text="Companies.Delete" onClick={this.handleDeleteButtonClick} />
								<IconTextButton color="primary" iconName="save" text="Companies.Save" onClick={this.handleSaveButtonClick} />
							</Row>
						)}
					</CardBody>
					<CardFooter />

				</Card>
			</div >
		);
	}

	private checkHasOwnProducts = (company: ICompany) => {
		var ownProducts = this.props.products.filter(x => x.companyId === company.id);

		//MIGLIORA CONTROLLO SUI PRODOTTI 
		console.log("[DEBUG] ownProducts " + ownProducts.length)
		
		return ownProducts.length > 1;
	}

	private resetCompanyLicenses = async () => {
		try {
			await serverManager.licenses.resetLicenses(this.props.company.id);
			this.props.addToast({ msgId: "Companies.LicensesResetSuccessfully", type: ToastType.Success });
		} catch (error) {
			this.props.addToast({ msgId: "Companies.LicensesResetError", type: ToastType.Error });
		}

	};


	private handleDisableButtonClick = async () => {
		this.props.deleteLicenses(this.props.company.id, this.callbackDisableButton);
	};

	private callbackDisableButton = (msgId: string, type: ToastType) => {
		this.props.addToast({ msgId: msgId, type: type });
	};

	private handleAddLicense = () => {
		this.setState({ licenses: [...this.state.licenses, { companyId: this.props.company.id, totalLicenses: "", startDate: "", endDate: "", renewalMonths: "" }] })
	}

	private handleOnClick = () => {
		const { licenses } = this.state;

		var licensesClone = [...licenses];

		licensesClone.length = licensesClone.length - 1;

		this.setState({ licenses: licensesClone });

	};

	private handleChange = (fieldName: keyof ILicenseNumber) => (event: React.ChangeEvent<HTMLInputElement>) => {

		const { licenses } = this.state;
		const license = licenses[parseInt(event.target.id)];
		license[fieldName] = event.target.value;

		this.setState({ licenses: licenses });
	};

	// private handleChangeRenewalMonths = (fieldName: keyof ILicenseNumber) => (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { licenses } = this.state;
	// 	licenses[parseInt(event.target.id)].renewalMonths = event.target.value;

	// 	this.setState({ licenses: licenses });
	// };

	// private handleChangeNumberOfLicenses = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { licenses } = this.state;

	// 	licenses[parseInt(event.target.id)].totalLicenses = event.target.value;

	// 	this.setState({ licenses: licenses });
	// };

	// private handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { licenses } = this.state;

	// 	licenses[parseInt(event.target.id)].startDate = event.target.value;

	// 	this.setState({ licenses: licenses });
	// };

	// private handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { licenses } = this.state;

	// 	licenses[parseInt(event.target.id)].endDate = event.target.value;

	// 	this.setState({ licenses: licenses });
	// };

	private replaceNullWithEmptyString = (company: ICompany) => {
		const keys = Object.keys(company);
		const replacedCompany = { ...company };

		for (const key of keys) {
			const typedKey = key as keyof typeof company;
			if (replacedCompany[typedKey] === null) {
				//replacedCompany[typedKey] = "";
			}
		}
		return replacedCompany;
	};

	private validation = (message: string, valid: boolean) => {
		if (valid) {
			(this.props as any).toastManager.add(message, { appearance: "success", autoDismiss: true });
			return;
		}

		(this.props as any).toastManager.add(message, { appearance: "error", autoDismiss: true });
	};

	private handleCompanyNameValidation = async () => {
		if (this.state.company.name === "" || this.state.company.name === this.state.cancelCompany.name) {
			this.setState({ companyNameInValid: false, companyNameValid: false });
			return;
		}
		const response = await serverManager.companies.isValidCompanyName(this.state.company.name);
		if (response.data) {
			this.setState({ companyNameValid: true, companyNameInValid: false });
		} else {
			this.setState({ companyNameValid: false, companyNameInValid: true });
		}
	};

	private handleCompanyDateValidation = async () => {
		const date = new Date(this.state.company.expirationDate).getTime();
		const now = new Date().getTime();

		this.setState({ isDateValid: (date - now > 0) });
	};

	private handleOfficialNameValidation = async () => {
		if (this.state.company.officialName === "" || this.state.company.officialName === this.state.cancelCompany.officialName) {
			this.setState({ officialNameValid: false, officialNameInValid: false });
			return;
		}
		const response = await serverManager.companies.isValidOfficialName(this.state.company.officialName);
		if (response.data) {
			this.setState({ officialNameValid: true, officialNameInValid: false });
		} else {
			this.setState({ officialNameValid: false, officialNameInValid: true });
		}
	};

	private renderActionButton = () => {
		const { isEditing } = this.state;
		const { isSaving } = this.props;

		if (isSaving) {
			return (
				<div>
					<Spinner />
				</div>
			);
		}

		if (isEditing) {
			return (
				<div>
					<IconButton onClick={this.cancelEditMode} iconName={"close"} />
					<IconButton onClick={this.handleSaveButtonClick} iconName={"save"} />
				</div>
			);
		}

		return (
			<div>
				{this.props.canEdit && <IconButton onClick={this.handleEditButtonClick} iconName={"edit"} />}
			</div>
		);
	};

	private handleCompanyChange = (fieldName: keyof ICompany) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			company: {
				...this.state.company,
				[fieldName]: e.target.value,
			},
		});
	};

	private handleToggleBoolCompanyChange = (fieldName: keyof ICompany, e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(`[debug] field name: ${fieldName}`);
		this.setState({
			company: {
				...this.state.company,
				[fieldName]: e.target.checked.toString(),
			},
		});
	}

	private handleSaveButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const diff = objectDiff(this.state.company, this.props.company);

		if (this.state.licenses.length > this.props.licenses.length) {
			this.props.createLicensesForCompany(this.props.company.id, this.state.licenses.slice(this.props.licenses.length, this.state.licenses.length), this.callback);
		}

		this.state.licenses.forEach(element => {
			element.companyId = this.props.company.id;
		});
		this.props.updateLicensesRenewalMonths(this.state.licenses);

		if (diff.length) {
			return this.saveItem();
		}

		return this.cancelEditMode();
	};

	private callback = (msgId: string, toastType: ToastType) => {
		this.props.addToast({ msgId: msgId, type: toastType })
	}

	private handleDeleteButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		return this.deleteItem();
	};

	private cancelEditMode = () => {
		const company = this.state.cancelCompany;
		this.setState({ isEditing: false, company });
	};

	private saveItem = () => {
		const { company } = this.state;
		this.setState({ isEditing: false });
		this.props.onSaveClicked(company);
	};

	private deleteItem = async () => {
		const { company } = this.state;
		this.setState({ isEditing: false });
		this.props.onDeleteClicked(company);
	};

	private handleEditButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const cansleCompany = this.state.company;
		this.setState({ isEditing: !this.state.isEditing, cancelCompany: cansleCompany });
	};

	private returnEditMode(isEditing: boolean, isSaving: boolean) {
		if (isEditing) {
			return !isEditing || isSaving;
		}
		return true;
	}
}

const mapStateToProps = (state: IRootState) => ({
	products: toArray(state.products.data),
	licenses: state.companies.licenses,
	nonSubordinateCompanies: state.companies.nonSubordinateCompanies,
	subordinateCompaniesById: state.companies.subordinateCompaniesById,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getProductsByCompanyId: productActions.getProductsByCompanyId,
			getSubordinateCompaniesById: actions.getSubordinateCompaniesById,
			getNonSubordinateCompanies: actions.getNonSubordinateCompanies,
			deleteLicenses: actions.deleteLicenses,
			getLicensesByCompanyId: actions.getLicensesByCompanyId,
			createLicensesForCompany: actions.createLicensesForCompany,
			updateLicensesRenewalMonths: actions.updateLicensesRenewalMonths,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

const CompanyInformation = connect(mapStateToProps, mapDispatchToProps)(withToasts(CompanyInfromationBase));

export default CompanyInformation;