import { StreamManager } from "openvidu-browser";
import * as React from "react";
import { T } from "../../managers/I18n";
import { VideoElementWithMediaStream } from "../../utilities";
import { Spinner } from "../globalComponents";
import { Video } from "./Video";
import "./VideoContainer.scss";

export interface IHoloLensViewProps {
	className?: string;
	onRemoteVideoRef?: (el: React.RefObject<VideoElementWithMediaStream>) => void;
	onLocalVideoRef?: (el: React.RefObject<HTMLVideoElement>) => void;
	streamManager?: StreamManager | null;
	style?: React.CSSProperties;
}

export interface IHoloLensViewState {
	isLoading: boolean;
}

export class VideoContainer extends React.Component<IHoloLensViewProps, IHoloLensViewState> {
	public videoRef = React.createRef<HTMLVideoElement>();
	public state: IHoloLensViewState = {
		isLoading: true,
	};

	public render() {
		if (!this.props.streamManager) {
			return (
				<div className="connecting-container">
					<Spinner />
					<h2>
						<T v="Connecting" />
					</h2>
				</div>
			);
		}

		return (
			<Video
				onRemoteVideoRef={this.props.onRemoteVideoRef && this.props.onRemoteVideoRef}
				onLocalVideoRef={this.props.onLocalVideoRef && this.props.onLocalVideoRef}
				className={this.props.className}
				streamManager={this.props.streamManager}
			/>
		);
	}
}
