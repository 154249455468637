import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { ToastProvider } from "./components/toast";
import { LanguageProvider, setCookie } from "./managers/I18n";
import { serverManager } from "./managers/ServerManager";
import "./scss/custom.scss";
import { store } from "./store/configure-store";
import { logger } from "./utilities/logger";
import { LayoutBase } from "./App";
import AzureAD from "react-aad-msal";
import { authProvider } from "./auth/AuthProvider";
import { LandingPage } from "./components/landing-page/LandingPage";
import { marketplaceProvider } from "./auth/MarketplaceAuthProvider";
import 'material-icons/iconfont/material-icons.css';


function main() {
	// Create browser history to use in the Redux store
	console.log("REACT_APP_CLIENT_ID:" + process.env.REACT_APP_CLIENT_ID);
	console.log("REACT_APP_TENANT_ID:" + process.env.REACT_APP_TENANT_ID);
	console.log("REACT_APP_SCOPE:" + process.env.REACT_APP_SCOPE);
	const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
	if (!baseUrl) {
		logger.debug("Did not find baseUrl");
		return;
	}

	const rootElement = document.getElementById("root");
	const man = serverManager;
	if (LayoutBase.isMobileDevice()) {
		document.addEventListener("visibilitychange", function () {
			if (document.visibilityState === 'visible') {
				console.log("Visibility state changed in: " + document.visibilityState);
				//window.location.reload();
			}
		});
	}

	//
	logger.debug("Mounting application into DOM...");
	const isTemporaryUser: boolean = window.location.href.includes("auth");
	console.log("log test 1: " + isTemporaryUser );
	let token:string = "";

	if (isTemporaryUser){
		token = window.location.href.substr(window.location.href.length - 36, 36);
		console.log("log test 2:" + token);
	}

	//Redirect from pageRedirect
	const isTemporaryUserForRedirect: boolean = window.location.href.includes("temporaryUserAccess");
	const isMarketplaceUser: boolean = window.location.href.includes("landing-page");
	let tokenForMarketplace = "";
	if (isMarketplaceUser) {
		tokenForMarketplace = location.href.split("token=")[1];
		console.log(`[foo] token: ${tokenForMarketplace}`);
	}

	console.log("log test ForRedirect 1: " + isTemporaryUserForRedirect );
	let tokenForRedirect: string = "";

	if (isTemporaryUserForRedirect){
		tokenForRedirect = window.location.href.split("/")[4];
		console.log("log test ForRedirect 2:" + tokenForRedirect);
	}	

		console.log("add cookie: " + tokenForRedirect);
		setCookie('XBasicToken', tokenForRedirect);

		
	logger.debug("Mounting application into DOM...");

	ReactDOM.render(
		<LanguageProvider>
			<ToastProvider>

			{!isTemporaryUser && !isMarketplaceUser  &&
			<AzureAD provider={authProvider} forceLogin={true}>
				<Provider store={store}>
					<App baseUrl={baseUrl} manager={man} />
				</Provider>
			</AzureAD>
			}
			
			{isTemporaryUser && !isMarketplaceUser &&
				<Provider store={store}>
					<App
						baseUrl={baseUrl}
						manager={man} 
						temporaryAccessToken={isTemporaryUserForRedirect == true ? tokenForRedirect : token}
					/>
				</Provider>
			}

			{isMarketplaceUser &&
				<Provider store={store}>
					<AzureAD provider={authProvider} forceLogin={true}>
						<LandingPage />
					</AzureAD>
				</Provider>
			}

			</ToastProvider>
		</LanguageProvider>,
		rootElement,
	);
}

main();
