export interface IDictionary<T> {
	[key: string]: T;
}

export interface IGenericObject {
	[key: string]: any;
}

export function makeDictionary<T extends IGenericObject>(list: T[], keyProp: keyof T) {
	const initialValue: IDictionary<T> = {};
	const dict = list.reduce((acc: IDictionary<T>, item) => {
		const currentKey: string = item[keyProp];
		acc[currentKey] = item;
		return acc;
	}, initialValue);
	return dict;
}

export function toArray<T extends IGenericObject>(dict: IDictionary<T>) {
	const keys = Object.keys(dict);
	const initialList = [] as T[];
	const list = keys.reduce((arr, key) => {
		arr.push(dict[key]);
		return arr;
	}, initialList);

	return list;
}
