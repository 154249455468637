import { take } from "ramda";

export function shortenText(text: string, length: number) {
	if (!text) {
		return "";
	}

	const ellipses = "...";
	if (text.length > length) {
		return take(length, text) + ellipses;
	}

	return text;
}
