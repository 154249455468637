import * as React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardFooter from "reactstrap/lib/CardFooter";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { actions as companiesActions } from "../../reducers/companies";
import { actions as companyActions } from "../../reducers/company";
import { ICompany, Id } from "../../types";
import { ErrorPage, IconButton } from "../globalComponents";
import { LoadingPage } from "../globalComponents/LoadingPage";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { Users } from "../user/Users";
import CompanyInfromation from "./CompanyInfromation";
import "../products/QRCodeTab.scss";
import { getActiveClassName } from "../../utilities";
import Label from "reactstrap/lib/Label";
import { UserRole } from "../../types/IRoles";
import { User } from "../user/AddUser";
import { getCookieValue } from "../../reducers/call-queue/call-queue";

interface IOwnProps extends InjectedToastsProps
{
	id: Id;
}

interface IProps extends ConnectProps, IOwnProps { }

interface ICompanyDashboardState
{
	notFound: boolean;
	loading: boolean;
	error: boolean;
	saving: boolean;
	isEditing: boolean;
	printOverlay: boolean;
	companyId: Id;
	company: ICompany | undefined;
	companyQRCode: Blob;
}

class Company extends React.Component<IProps, ICompanyDashboardState> {
	public state: ICompanyDashboardState = {
		notFound: true,
		printOverlay: false,
		loading: false,
		error: false,
		saving: false,
		isEditing: false,
		companyId: "",
		company: undefined,
		companyQRCode: new Blob(),
	};

	componentDidMount()
	{
		// const connectionId = getCookieValue("connectionId");
		// serverManager.users.addLoggedUser(connectionId as string);
		this.props.getMyCompany();
	}

	public render()
	{
		const { loading, error } = this.state;

		if (loading) {
			return <LoadingPage />;
		}

		if (error) {
			return (
				<ErrorPage>
					<T v={"Error"} />
				</ErrorPage>
			);
		}

		const className = getActiveClassName(this.state.printOverlay, "product-tab-qrcode-outer", "overlay");
		return (
			<Container fluid>
				<Col style={{ marginTop: 15, padding: 15 }}>
					{this.props.loggedUser.userRole >= UserRole.CompanyAdmin &&
						<Col lg={8} className="animated" fluid="md" style={{ maxWidth: '100%' }}>
							<Row style={{ alignItems: 'stretch' }}>
								<Col style={{ flex: 1.5, minWidth: '400px' }}>
									{this.props.company !== undefined ? (
										<CompanyInfromation
											isSuperAdmin={this.props.loggedUser.userRole === UserRole.SuperAdmin}
											canEdit={this.props.loggedUser.userRole > UserRole.CompanyAdmin}
											isEditing={this.state.isEditing}
											company={this.props.company}
											isSaving={this.state.saving}
											onSaveClicked={this.handleUpdateCompany}
											onDeleteClicked={() => { }}
										/>
									) : null}
								</Col>
								{/* <Col style={{ marginTop: 15, padding: 15, flex: 0, minWidth: '448px' }}>
									<Card>
										<div className={className}>
											{this.state.printOverlay &&
												<Label className={"product-tab-qrcode-name"}>
													{this.props.company.officialName}
												</Label>}
											<CardBody>
												<img src="/api/Companies/QRCode" style={{ width: "100%" }} />
											</CardBody>
											<IconButton className="no-print" onClick={this.callPrint} iconName="print" />
											<IconButton className="done-print" onClick={this.togglePrint} iconName="close" />
											<CardFooter />
										</div>
									</Card>
								</Col> */}
							</Row>
						</Col>
					}
					{this.props.company !== undefined && (this.props.loggedUser.userRole >= UserRole.CompanyAdmin || this.props.company.allowTemporaryUsers.toString() !== "false") &&
						<Col >
							<Users forcedTeamId={this.props.loggedUser.teamId}/>
						</Col>
					}
				</Col>
			</Container>
		);
	}

	private callPrint = async () =>
	{
		await this.togglePrint();
		window.print();
		await this.togglePrint();
	};

	private togglePrint = () =>
	{
		const printOverlay = !this.state.printOverlay;
		return this.setState({ printOverlay });
	};

	private handleUpdateCompany = (newCompany: ICompany) =>
	{
		this.props.updateCompany(newCompany, this.validation);
	};

	private validation = (msgId: string, valid: boolean) =>
	{
		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId });
			return;
		}

		this.props.addToast({ type: ToastType.Error, msgId });
	};
}

const mapStateToProps = (state: IRootState, ownState: ICompanyDashboardState) => ({
	loggedUser: state.userInfo.data,
	loading: state.company.loading,
	error: state.company.error,
	company: state.company.data.company,
	companyQRCode: state.companies.companyQECode,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMyCompany: companyActions.getMyCompany,
			updateCompany: companiesActions.updateCompany,
			getCompanyQRCode: companiesActions.getCompanyQRCode,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const CompanyDashboard = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withToasts(Company));
