import React from "react";
import { Input, InputGroup, InputProps } from "reactstrap";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import { getActiveClassName } from "../../utilities";
import { Icon } from "./Icon";
import "./SearchBar.scss";

export type SearchBarProps = {
	isSearching?: boolean;
	hideOnMobile?: boolean;
} & InputProps;

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({ isSearching, hideOnMobile, ...rest }) => (
	<InputGroup className={getActiveClassName(!!hideOnMobile, "searchbar-input-group", "hide-mobile")}>
		<InputGroupAddon addonType="prepend">
			<InputGroupText>
				<Icon name="search" />
			</InputGroupText>
		</InputGroupAddon>
		<Input {...rest} />
	</InputGroup>
);
