import { call, put, select, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { IRootState } from "..";
import { serverManager } from "../../managers/ServerManager";
import { ITroubleshootingFlatNode } from "../../types/ITroubleshootingNode";
import { IDictionary } from "../../utilities";
import { logger } from "../../utilities/logger";
import { actions } from "./troubleshooting-items.actions";

export function* handleGetTroubleshootingItemsForProduct(action: ReturnType<typeof actions.getTroubleshootingItemsForProduct>) {
	try {
		logger.debug("Fetching troubleshootingItems for product with id", action.payload);
		const { data } = yield call(serverManager.troubleshooting.getForProductId, action.payload);
		yield put(actions.getTroubleshootingItemsForProductSuccess(data));
		logger.debug(data.length, "troubleshootingItems fetched for product with id", action.payload);
	} catch (err) {
		logger.error(err);
		yield put(actions.getTroubleshootingItemsForProductError("Something went wrong."));
	}
}

export function* handleSaveTroubleshootingTree(action: ReturnType<typeof actions.saveTroubleshootingTree>) {
	try {
		yield call(serverManager.troubleshooting.put, action.payload.productId, action.payload.tree);
		yield put(actions.saveTroubleshootingTreeSuccess(action.payload.tree));
	} catch (err) {
		logger.error(err);
		yield put(actions.saveTroubleshootingTreeError("Something went wrong."));
	}
}

export function* handleGetSingleNode(action: ReturnType<typeof actions.getSingleNode>) {
	try {
		const { nodeId, productId } = action.payload;
		const { data } = yield call(serverManager.troubleshooting.getById, productId, nodeId);
		yield put(actions.getSingleNodeSuccess(data));
	} catch (err) {
		logger.error(err);
		yield put(actions.getSingleNodeError("Something went wrong."));
	}
}

export function* handleSaveSingleNode(action: ReturnType<typeof actions.saveSingleNode>) {
	try {
		const { node, callback, productId } = action.payload;
		const state: IRootState = yield select();
		const dict = state.troubleshootingItems.data;
		const withNewNode: IDictionary<ITroubleshootingFlatNode> = {
			...dict,
			[node.id]: node,
		};

		const dataToSave = state.troubleshootingItems.sortOrder.map(i => withNewNode[i]);

		yield call(serverManager.troubleshooting.put, productId, dataToSave);
		yield put(actions.saveSingleNodeSuccess(node));

		if (callback) {
			yield call(callback);
		}
	} catch (err) {
		logger.error(err);
		yield put(actions.saveSingleNodeError("Something went wrong"));
	}
}

export function* troubleshootingItemsSaga() {
	yield takeLatest(getType(actions.getTroubleshootingItemsForProduct), handleGetTroubleshootingItemsForProduct);
	yield takeLatest(getType(actions.saveTroubleshootingTree), handleSaveTroubleshootingTree);
	yield takeLatest(getType(actions.getSingleNode), handleGetSingleNode);
	/*
	 ** TODO: Fix this.
	 ** This does not have unit-tests because it is 100% guaranteed
	 ** this is not how it will work with a proper backend.
	 */
	yield takeLatest(getType(actions.saveSingleNode), handleSaveSingleNode);
}
