import none from "ramda/es/none";
import React from "react";
import { Label } from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { bindActionCreators } from "redux";
import { Dispatch } from "redux";
import { Icon } from ".";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers/root-reducer";
import { IFile, IProgress } from "../../types";
import {
	acceptedExtension,
	bytesToKiloBytes,
	classNames,
	delay,
	fileAccepted,
	isFileWithinSizeLimit,
	fileMatchSize,
	fileValidator,
	getDataTransferItems,
	IDictionary,
	OriginalHtmlProps,
} from "../../utilities";
import { logger } from "../../utilities/logger";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { actions as filesActions } from "../../reducers/files";
import "./UploadButton.scss";
import { connect } from "react-redux";

export interface IUploadButtonProps extends OriginalHtmlProps<HTMLDivElement>, InjectedToastsProps, ConnectProps {
	onFileUploaded: (file: IFile) => void;
	uploader: (files: File[], progressCallback: (progress: IProgress) => void) => Promise<IFile[]>;
	validFileTypes: string[];
	validFileExtensions: string[];
}

export interface IUploadButtonState {
	progressMap: IDictionary<number>;
	acceptedFiles: File[];
	rejectedFiles: File[];
}

class UploadButtonBase extends React.Component<IUploadButtonProps, IUploadButtonState> {
	public fileInput = React.createRef();
	public state: IUploadButtonState = {
		progressMap: {},
		acceptedFiles: [],
		rejectedFiles: [],
	};
	public input = React.createRef<HTMLInputElement>();

	public render() {
		const { addToast, className, onFileUploaded, validFileTypes, uploader, validFileExtensions, ...restProps } = this.props;

		return (
			<React.Fragment>
				<div {...restProps} className={classNames("app-upload-button-outer", className)}>
					<div className="upload-btn-wrapper">
						<button className="btn btn-primary" onClick={this.hadnleUploadFileClick}>
							Upload file
						</button>
						<input
							style={{ display: "none" }}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFiles(e)}
							multiple={false}
							type="file"
							ref={this.input}
						/>
					</div>
					<div>{this.renderFileContent()}</div>
				</div>
				{this.renderRejectedFiles()}
			</React.Fragment>
		);
	}

	private hadnleUploadFileClick = () => {
		if (this.input.current) {
			this.input.current.click();
		}
	};

	private renderRejectedFiles = () => {
		const { rejectedFiles } = this.state;
		if (rejectedFiles.length === 0) {
			return null;
		}

		return (
			<div className="app-upload-button-rejected-files">
				{rejectedFiles.map(rejected => (
					<Alert color="danger" key={rejected.name}>
						{rejected.name} <T v="DocumentationDetails.Rejected" />
					</Alert>
				))}
			</div>
		);
	};

	private renderFileContent = () => {
		const { acceptedFiles } = this.state;

		if (acceptedFiles.length > 0) {
			return <Row>{acceptedFiles.map(this.renderFilePreview)}</Row>;
		}
		return;
	};

	private handleFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log("hey");
		if (!e.currentTarget.files) {
			return;
		}
		const file: File | null = e.currentTarget.files.item(0);

		if (file) {
			const { acceptedFiles, rejectedFiles } = fileValidator([file], this.fileValidator);

			await this.handleRejectedFiles(rejectedFiles);
			await this.handleAcceptedFiles(acceptedFiles);
		}
	};

	private handleRejectedFiles = async (rejectedFiles: File[]) => {
		return this.setState({ rejectedFiles });
	};

	private handleAcceptedFiles = async (newFiles: File[]) => {
		try {
			const progressMap = newFiles.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {});
			const acceptedFiles = [...this.state.acceptedFiles, ...newFiles];
			await this.setState({ acceptedFiles, progressMap });
			const response = await this.props.uploader(newFiles, this.progressCallback);

			for (const file of response) {
				this.props.onFileUploaded(file);
			}

			const newAcceptedFiles = this.state.acceptedFiles.filter(this.isFileUploading);
			await this.setState({ acceptedFiles: newAcceptedFiles });
		} catch (err) {
			logger.error("Some kind of error");
			this.props.addToast({ type: ToastType.Error, msgId: "DocumentationDetails.UploadError" });
			const fileNames = newFiles.map(i => i.name);
			const acceptedFiles = this.state.acceptedFiles.filter(i => fileNames.indexOf(i.name) === -1);
			this.setState({ acceptedFiles });
		}
	};

	private isFileUploading = (file: File) => {
		const idx = this.state.acceptedFiles.findIndex(f => f.name === file.name);

		if (idx !== -1) {
			return false;
		}

		return true;
	};

	private progressCallback = (progress: IProgress) => {
		this.setState({ progressMap: { ...this.state.progressMap, [progress.file.name]: progress.uploaded } });
	};

	private fileValidator = (file: File) => {
		const { validFileTypes, validFileExtensions } = this.props;
		logger.debug(file);
		const isValidFileType = fileAccepted(file, validFileTypes);
		const isValidFileExtension = acceptedExtension(file, validFileExtensions);
		const isValidTypeOrExtension = isValidFileExtension || isValidFileType;
		let isBelowMaxFileSize = file.size < this.props.maxWeight;
		const isAccepted = isValidTypeOrExtension && isBelowMaxFileSize;
		return isAccepted;
	};

	private getThumbnail = (file: File) => {
		const url = URL.createObjectURL(file);

		switch (file.type) {
			case "image/png":
				return <img src={url} className="app-upload-button-preview-thumbnail-img" />;
			case "application/pdf":
				return this.renderThumbnailIcon();
			default:
				return this.renderThumbnailIcon();
		}
	};

	private renderThumbnailIcon = () => (
		<div className="app-upload-button-preview-thumbnail-icon">
			<Icon name="file_copy" />
		</div>
	);

	private renderFilePreview = (file: File) => (
		<Col md={4} key={file.name}>
			<div className="app-upload-button-file-preview">
				<div className="appupload-button-preview-thumbnail">
					{this.getThumbnail(file)}
					<div style={this.getProgressStyle(file)} className="app-upload-button-preview-progress" />
				</div>
				<div className="app-upload-button-file-preview-body">
					<div className="app-upload-button-file-preview-filename">{file.name}</div>
					<div className="app-upload-button-file-preview-filesize">{bytesToKiloBytes(file.size)}kb</div>
				</div>
			</div>
		</Col>
	);

	private getProgressStyle = (file: File) => {
		const width = this.state.progressMap[file.name] * 100 + "%";
		const style: React.CSSProperties = {
			width,
		};
		return style;
	};
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
	company: state.company.data.company,
	maxWeight: state.files.maxFileUploadWeight,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMaximumFileWeight: filesActions.getFilesMaximumSizeLimitUpload,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const UploadButton = connect(mapStateToProps, mapDispatchToProps)(withToasts(UploadButtonBase));
