import moment from "moment";
import React from "react";
import Button from "reactstrap/lib/Button";
import { T } from "../../managers/I18n";
import { ITicketItem, TicketItemType } from "../../types";
import { Icon } from "../globalComponents";
import { getTicketDescription, getTicketIcon, getTicketTitle } from "./TicketItemText";
import "./TimeLineItem.scss";

export interface ITiketItemStartProps {
	item: ITicketItem;
}

const TicketItemAction: React.FunctionComponent<ITiketItemStartProps> = (props: ITiketItemStartProps) => {
	return (
		<div className="timeline-block">
			<div className="timeline-icon">
				<Icon name={getTicketIcon(props.item.type)} />
			</div>
			<div className="timeline-content">
				<h2>
					<T v={getTicketTitle(props.item.type)} />
				</h2>
				<p>
					<T v={getTicketDescription(props.item.type, props.item.data)} />
				</p>
				
				<span className="timeline-date">
					<p>
						{moment
							.utc(props.item.date)
							.local()
							.format("LL")}
						<br />
						<small>
							{moment
								.utc(props.item.date)
								.local()
								.format("LT")}
						</small>
					</p>
				</span>
			</div>
		</div>
	);
};

export default TicketItemAction;
