import React from "react";
import { ColorResult, HuePicker } from "react-color";

interface IAnnotationsColorPickerProps {
	color: string;
	onColorChanged: (newColor: string) => void;
}

export const AnnotationsColorPicker: React.FunctionComponent<IAnnotationsColorPickerProps> = ({ color, onColorChanged }) => {
	const onChange = (c: ColorResult) => onColorChanged(c.hex);
	return (
		<div>
			<HuePicker onChange={onChange} color={color} />
		</div>
	);
};
