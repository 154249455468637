import { DateString } from ".";
import { IDictionary } from "../utilities";

export interface IFile {
	id: string;
	name: string;
	createdDate: DateString;
	thumbnailUrl: string | null;
	fileUri: string;
	realFileUri: string;
	official: boolean;
	bytes: number;
	hidden: boolean;
	isVisibleToTemporaryUser: boolean;
}

export interface IRecording {
	id: string; 
    name: string;
    outputMode: string;
    resolution: string;
    recordingLayout: string;
    sessionId: string;
    createdAt: number;
    size: number;
    duration: number;
    url: string;
    hasAudio: boolean;
    hasVideo: boolean;
    status: RecordingStatus;
	isComplete: boolean;
}

export enum RecordingStatus {
	Started = 0,
    Stopped = 1,
    Available = 2,
	Failed = 3,
	Ready = 4
}

export interface IClips
{
	id: string;
	clipsData: IClip[];
}

export interface IClip {
	text: IDictionary<string>;
	speechText: IDictionary<string>;
}
