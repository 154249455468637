import * as React from "react";
import { Button, Col, Container, Row } from "reactstrap";

// Component Import
import { array, number } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/files";
import { actions as productActions } from "../../reducers/products";
import { actions as userActions } from "../../reducers/users";
import { IClip, IClips, IFile, IProduct } from "../../types";
import { UserRole } from "../../types/IRoles";
import { delay, toArray } from "../../utilities";
import { logger } from "../../utilities/logger";
import { Dropzone } from "../globalComponents/Dropzone";
import FileItem from "../globalComponents/FileItem";
import * as THREE from "three";
import PageHeader from "../globalComponents/PageHeader";
import { LinkProductModal } from "../products/LinkProductModal";
import { DeleteFileModel } from "./DeleteFileModal";
import DocumentationDetails from "./DocumentationDetails";
import { DocumentationMenu } from "./DocumentationMenu";
import { userInfo } from "os";
import { isInterfaceDeclaration } from "typescript";
import { Icon, LoadingPage } from "../globalComponents";
import { ITeam } from "../../types/ITeam";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { convertImageToFile } from "../active-call/ActiveCallUtils";
import { getCurrentLanguage } from "../../managers/I18n";
import { ANIMATION_LANGUAGES } from "../../constants";

// tslint:disable-next-line:no-empty-interface
interface IDocumentationProps extends ConnectProps { }

interface IDocumentationState {
	selectedFiles: IFile[];
	searchString: string;
	officialToggle: boolean;
	isMultiSelectEnabled: boolean;
	fileSort: number;
	invertfileSort: number;
	availableFiles?: IFile[];
	deleteModalOpen: boolean;
	linkProductModal: boolean;
	genericProductId: string | null;
	page: number;
}

class Documentation extends React.Component<
	IDocumentationProps,
	IDocumentationState
> {
	public state: IDocumentationState = {
		selectedFiles: new Array<IFile>(),
		searchString: "",
		officialToggle: true,
		isMultiSelectEnabled: false,
		fileSort: 0,
		invertfileSort: 1,
		availableFiles: undefined,
		deleteModalOpen: false,
		linkProductModal: false,
		genericProductId: null,
		page: 0,
	};

	public componentDidMount() {
		this.props.getTeams();
		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getFilesByCompanyId(this.props.company.mainCompanyId, 0, this.state.searchString);
			console.log("[DEBUG] mainCompanyId " + this.props.company.mainCompanyId);
			this.props.getProductsByCompanyId(this.props.company.mainCompanyId);
		}
		else {
			this.props.getFiles(0, this.state.searchString);
			this.props.getFilesWeight();
			this.props.getProducts();
			this.props.getGenericProduct((x: IProduct) => this.setState({ genericProductId: x.id }));
		}

		this.setSortMethod(0, true);

		document.addEventListener("keydown", this.enableMultiSelect);
		document.addEventListener("keyup", this.disableMultiSelect);
	}
	private isCompanyNotSubordinate = this.props.company && this.props.company.mainCompanyId === null;

	public render() {
		const { selectedFiles, searchString, deleteModalOpen } = this.state;
		const { onFileUploaded, role, isInTeam, loadingTeams } = this.props;

		if (loadingTeams) {
			return <LoadingPage />;
		}

		const dropzone = (
			<Dropzone
				isCompanyNotSubordinate={this.isCompanyNotSubordinate}
				uploader={serverManager.files.upload}
				onFileUploaded={this.handleOnFileUploaded}
				validFileTypes={["image/jpeg", "image/png", "application/pdf"]}
				validFileExtensions={["glb", "mp4", "fbx"]}
			/>
		);

		const documentaitonMenu = (
			<DocumentationMenu
				role={role}
				isCompanyNotSubordinate={this.isCompanyNotSubordinate}
				isFileSelected={selectedFiles.length > 0}
				isOfficialToggled={this.state.officialToggle}
				isSortInverted={this.state.invertfileSort === -1}
				sortType={this.state.fileSort}
				filesCount={this.getFilteredFiles().length}
				filesWeight={this.props.filesWeight}
				maxWeight={this.props.filesMaxWeight}
				onSearchTextChanged={this.onSearchStringChange}
				searchString={searchString}
				onOfficialToggle={() =>
					this.setOfficialFilter(!this.state.officialToggle)
				}
				onSortToggle={this.setSortMethod}
				onDelete={() => this.toggleDeleteModal()}
			/>
		);

		const documentationDetails = (
			<DocumentationDetails
				isCompanyNotSubordinate={this.isCompanyNotSubordinate}
				files={selectedFiles}
				linkedProducts={this.getLinkedProducts()}
				onSetOfficial={this.handleSetFileOfficial}
				onLinkProduct={!isInTeam ? this.openLinkModal : null}
			/>
		);

		return (
			<React.Fragment>
				{selectedFiles.length === 1 && (
					<LinkProductModal
						genericProductId={this.state.genericProductId}
						products={this.props.products}
						file={selectedFiles[0]}
						referenceLoading={this.props.referenceLoading}
						onCheck={this.handleCheck}
						loading={this.props.loading}
						isOpen={this.state.linkProductModal}
						toggle={this.closeLinkModal}
					/>
				)}
				{selectedFiles.length > 0 && (
					<DeleteFileModel
						filesList={this.getSelectedFilesNames()}
						onCheck={this.handleDeleteFile}
						isOpen={deleteModalOpen}
						toggle={this.toggleDeleteModal}
					/>
				)}
				<PageHeader />
				<Container fluid>
					<Row>
						<Col lg={8}>
							<Row>
								{role === UserRole.CompanyAdmin && !isInTeam ? (
									<Col lg={12} className="padding-top-mobile">
										{dropzone}
									</Col>
								) : null}
								<Col
									lg={12}
									className="hide-mobile padding-top-desktop"
								>
									{documentaitonMenu}
								</Col>
								<Col
									lg={12}
									className={"list padding-top"}
									onClickCapture={this.handleDeselect}
								>
									{this.filterFiles().map(this.renderFile)}

								</Col>
							</Row>
						</Col>

						<Col lg={4} className="mb-3">
							{documentationDetails}
						</Col>
					</Row>

				</Container>
				<div style={{ textAlign: "center", width: "calc(100% - 660px)" }}>
					{this.props.files.length > 0 && this.state.page > 0 &&
						<Button onClick={this.handlePreviousPage}>
							<Icon name="keyboard_arrow_left" />
						</Button>}
					{this.props.files.length > 10 &&
						<Button onClick={this.handleNextPage}>
							<Icon name="keyboard_arrow_right" />
						</Button>}
				</div>
			</React.Fragment>
		);
	}


	private handleOnFileUploaded = (file: IFile) => {
		const loader: FBXLoader = new FBXLoader();

		loader.load(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`, (obj: any) => {
			const currentLang = getCurrentLanguage();
			const lang = ANIMATION_LANGUAGES.includes(currentLang) ? currentLang : 'en';
			
			const clips = {
				id: file.id,
				clipsData: obj.animations.map(() => {
					return {
						text: { [lang]: '' },
						speechText: {[lang]: ''}
					};
				})
			};

			this.handleClipsDataSaving(clips);
			this.handleSaveModelThumbnail(file, obj);

		});
		
		this.props.onFileUploaded(file);
	};

	private handleClipsDataSaving = (clips: IClips | null) => {
		if (clips != null) {
			this.props.setClipsData(clips, undefined);
		}
	};

	private handleSaveModelThumbnail = (fileToLoad: IFile, model: any) => {
		const canvas = document.createElement('canvas');
		const backgroundCanvas = document.createElement('canvas');
		const clock = new THREE.Clock();
		const mixer = new THREE.AnimationMixer(model);

		const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: canvas, preserveDrawingBuffer: true })
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100000);
		camera.position.set(1.166, 63.586, 154.963);
		const light = new THREE.HemisphereLight(0xffffff, 0x454545, 1);
		const directionalLight = new THREE.DirectionalLight(0xffffff, 1);

		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.shadowMap.enabled = true;
		renderer.gammaOutput = true;

		light.position.set(0.5, 1, 0.25);

		scene.add(light);
		//scene.add(directionalLight);
		scene.background = new THREE.Color(0xDBDCE0);

		scene.add(model);
		directionalLight.target = model;
		const animate = () => {
			const delta = clock.getDelta();
			if (mixer) {
				mixer.update(delta);
			}

			renderer.render(scene, camera);
			requestAnimationFrame(animate);
		};

		animate();

		renderer.setAnimationLoop(() => {
			renderer.render(scene, camera);
		});

		backgroundCanvas.width = 500;
		backgroundCanvas.height = 500;
		console.log(`[foo] canvasWidth, canvasHeight: ${canvas.width}, ${canvas.height}`);
		const context = backgroundCanvas.getContext('2d');

		if (context) {
			if (canvas.width > canvas.height) {
				const newHeight = (canvas.height / canvas.width) * backgroundCanvas.width;
				context.drawImage(canvas, 0, (backgroundCanvas.height / 2) - (newHeight / 2), backgroundCanvas.width, newHeight);
			} else {
				const newWidth = backgroundCanvas.height / (canvas.height / canvas.width);
				context.drawImage(canvas, (backgroundCanvas.width / 2) - (newWidth / 2), 0, newWidth, backgroundCanvas.height);
			}

			backgroundCanvas.toBlob(async (blob: Blob | null) => {
				if (!blob)
					return;
				console.log(`[foo] sto salvandoooo!`);
				const file = convertImageToFile(blob, "model_thumbnail");
				const response = await serverManager.files.uploadFileThumbnail(fileToLoad.id, file);
				this.props.uploadModelThumbnailSuccess(response);
				//this.props.uploadModelThumbnail(fileToLoad.id, file);
			});
		}
	};

	private filterFiles = () => {

		var files = [...this.props.files];

		files = files.splice(0, 10);

		return files;
	}

	private handleNextPage = () => {
		const { page, searchString } = this.state;

		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getFilesByCompanyId(this.props.company.mainCompanyId, page + 1, searchString);
		}
		else {
			this.props.getFiles(page + 1, searchString);
		}

		this.setState({ page: page + 1 });
	};

	private handlePreviousPage = () => {
		const { page, searchString } = this.state;

		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getFilesByCompanyId(this.props.company.mainCompanyId, page - 1, searchString);
		}
		else {
			this.props.getFiles(page - 1, searchString);
		}

		this.setState({ page: page - 1 });
	};

	// Sets file to official
	private handleSetFileOfficial = () => {
		const { selectedFiles } = this.state;
		if (selectedFiles.length !== 1) {
			return;
		}
		this.props.setFileOfficial(
			selectedFiles[0].id,
			this.handleCompletedOfficialStatusChange,
		);
	};

	private enableMultiSelect = (event: KeyboardEvent) => {
		if (event.ctrlKey && !this.state.isMultiSelectEnabled) {
			this.setState({ isMultiSelectEnabled: true });
		}
	};

	private disableMultiSelect = (event: KeyboardEvent) => {
		if (this.state.isMultiSelectEnabled) {
			this.setState({ isMultiSelectEnabled: false });
		}
	};

	private getSelectedFilesNames(): string[] {
		const namesList: string[] = new Array<string>();
		// this.state.selectedFiles.forEach(element => {
		// namesList = namesList == null ? `\n${element.name}` : `${namesList}, \n${element.name}`;
		// });
		this.state.selectedFiles.forEach(element => {
			namesList.push(element.name);
		});
		return namesList;
	}

	private handleCheck = (product: IProduct) => {
		if (this.state.selectedFiles.length === 1) {
			const fileId = this.state.selectedFiles[0].id;
			const { reference: referencedFileIds, id } = product;
			const shouldAdd = referencedFileIds.indexOf(fileId) === -1;

			if (
				this.props.referenceLoading[id] &&
				this.props.referenceLoading[id].indexOf(fileId) !== -1
			) {
				return;
			}

			if (shouldAdd) {
				this.props.addReference(id, fileId);
			} else {
				this.props.removeReference(id, fileId);
			}
		}
	};

	private getLinkedProducts(): string | undefined {
		let linkedProducts: string | undefined;

		if (this.state.selectedFiles.length === 1) {
			this.props.products.forEach(product => {
				if (
					product.reference.includes(
						this.state.selectedFiles[0].id,
					)
				) {
					linkedProducts = linkedProducts
						? `${linkedProducts}, ${product.name}`
						: `${product.name}`;
				}
			});
		}

		return linkedProducts;
	}

	private onSearchStringChange = (searchString: string) => {
		const { page } = this.state;

		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getFilesByCompanyId(this.props.company.mainCompanyId, 0, searchString);
		}
		else {
			this.props.getFiles(0, searchString);
		}

		this.setState({ searchString, page: 0 });
	};

	private renderFile = (file: IFile) => (
		<FileItem
			selected={this.state.selectedFiles.map(file => file.id)}
			onSelect={(id: string) => this.handleSelectedFile(id)}
			item={file}
			key={file.id}
		/>
	);

	private sortByName(a: IFile, b: IFile, sortDirection: number = 1): number {
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1 * sortDirection;
		} else if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1 * sortDirection;
		} else { return 0; }
	}

	private sortByDate(a: IFile, b: IFile, sortDirection: number = 1): number {
		if (a.createdDate < b.createdDate) {
			return 1 * sortDirection;
		} else if (a.createdDate > b.createdDate) {
			return -1 * sortDirection;
		} else {
			return 0;
		}
	}

	private sortByType(a: IFile, b: IFile, sortDirection: number = 1): number {
		const aType = a.name
			.substring(a.name.lastIndexOf(".") + 1, a.name.length)
			.toLowerCase();
		const bType = b.name
			.substring(b.name.lastIndexOf(".") + 1, b.name.length)
			.toLowerCase();

		if (aType > bType) { return 1 * sortDirection; } else if (aType < bType) { return -1 * sortDirection; } else { return 0; }
	}

	private isFileInProducts(file: IFile, products: IProduct[]): boolean {
		return products.filter(product => product.reference.includes(file.id)).length > 0;
	};

	private getFilteredFiles = () => {
		const { isInTeam, teams, teamId, products } = this.props;
		const team = isInTeam ? teams.find(x => x.id === teamId) : undefined;

		let retrievedFiles = this.props.files;

		if (team) {
			const teamProducts = products.filter(x => team.referencedProductIds.includes(x.id));
			retrievedFiles = retrievedFiles.filter(file => this.isFileInProducts(file, teamProducts));
		}

		let sortFunction = this.sortByName;
		switch (this.state.fileSort) {
			case 2: {
				sortFunction = this.sortByType;
				break;
			}
			case 1: {
				sortFunction = this.sortByDate;
				break;
			}
			default: {
				sortFunction = this.sortByName;
				break;
			}
		}

		const directionalSortFunction = (a: IFile, b: IFile) =>
			sortFunction(a, b, this.state.invertfileSort);
		retrievedFiles = retrievedFiles.sort(directionalSortFunction);

		if (this.state.searchString) {
			retrievedFiles = retrievedFiles.filter(i =>
				i.name
					.toLowerCase()
					.includes(this.state.searchString.toLowerCase()),
			);
		}

		if (this.state.officialToggle) {
			retrievedFiles = retrievedFiles.filter(
				i => i.official === this.state.officialToggle,
			);
		}

		return retrievedFiles;
	};

	private setSortMethod = async (
		newFileSort: number,
		ignoreInversion: boolean = false,
	) => {
		if (this.state.fileSort !== newFileSort) {
			if (!ignoreInversion) { this.setState({ invertfileSort: 1 }); }
			this.setState({ fileSort: newFileSort });
		} else {
			if (!ignoreInversion) {
				this.setState({
					invertfileSort: this.state.invertfileSort * -1,
				});
			}
		}
	};

	private setOfficialFilter = (status: boolean) => {
		this.setState({ officialToggle: status });
		this.setSortMethod(this.state.fileSort, true);
	};
	private toggleDeleteModal = () => {
		logger.debug("DeleteModal");
		const { selectedFiles } = this.state;
		if (selectedFiles.length === 0) {
			return;
		}
		this.setState(prevState => {
			return {
				deleteModalOpen: !prevState.deleteModalOpen,
			};
		});
	};

	private handleDeleteFile = async () => {
		logger.debug("DeleteFile");
		const { selectedFiles, page, searchString } = this.state;
		if (selectedFiles.length === 0) {
			return;
		}
		// selectedFiles.forEach(file => {
		// 	console.log("Deleting: " + file.name);
		// 	this.props.deleteFile([file.id]);
		// });
		this.props.deleteFile(selectedFiles.map(file => file.id));
		this.setState(prevState =>
		{
			return {
				deleteModalOpen: !prevState.deleteModalOpen,
			};
		});
		this.handleDeselect();
	};

	private handleCompletedOfficialStatusChange = () => {
		const { searchString } = this.state;

		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getFilesByCompanyId(this.props.company.mainCompanyId, 0, searchString)
		}
		else {
			this.props.getFiles(0, searchString);
		}
	};

	private handleSelectedFile(id: string) {
		const selectedFile = this.props.filesDict[id];
		const selectedFiles = this.state.selectedFiles;
		const singleSelectedFiles = new Array<IFile>(selectedFile);
		const index = selectedFiles.indexOf(selectedFile);

		if (!this.state.isMultiSelectEnabled) {
			this.setState({ selectedFiles: singleSelectedFiles });
		} else if (index >= 0) {
			selectedFiles.splice(index, 1);
			this.setState({ selectedFiles });
		} else {
			selectedFiles.push(selectedFile);
			this.setState({ selectedFiles });
		}
	}

	private handleDeselect = () => {
		const deselecteFiles = new Array<IFile>();
		this.setState({ selectedFiles: deselecteFiles });
	};

	private openLinkModal = () => {
		this.setState({ linkProductModal: true });
	};

	private closeLinkModal = () => {
		this.setState({ linkProductModal: false });
	};
}

const mapStateToProps = (state: IRootState) => ({
	company: state.company.data.company,
	deleting: state.files.deleting,
	loading: state.files.loading,
	filesDict: state.files.data,
	files: toArray(state.files.data),
	filesWeight: state.files.weight,
	filesMaxWeight: state.files.maxWeight,
	products: toArray(state.products.data),
	referenceLoading: state.products.referenceLoading,
	role: state.userInfo.data.userRole,
	teamId: state.userInfo.data.teamId,
	isInTeam: state.userInfo.data.teamId != null && state.userInfo.data.teamId != "",
	teams: toArray(state.users.teamsData),
	loadingTeams: state.users.loadingTeams,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			uploadModelThumbnailSuccess: actions.uploadFileThumbnailSuccess,
			setClipsData: actions.setFileClipsData,
			deleteFile: actions.deleteFile,
			setFileOfficial: actions.setFileOfficial,
			getFiles: actions.getFiles,
			getFilesByCompanyId: actions.getFilesByCompanyId,
			getFilesWeight: actions.getFilesWeight,
			getFilesMaxWeight: actions.getFilesMaxWeight,
			getProducts: productActions.getProducts,
			getProductsByCompanyId: productActions.getProductsByCompanyId,
			getGenericProduct: productActions.getGenericProduct,
			addReference: productActions.addReference,
			removeReference: productActions.removeReference,
			getTeams: userActions.getTeams,
			onFileUploaded: actions.onFileUploaded,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
