import { AxiosResponse } from "axios";
import moment from "moment";
import React from "react";
import { AccessTokenResponse } from "react-aad-msal";
import Button from "reactstrap/lib/Button";
import { authProvider } from "../../auth/AuthProvider";
import { T } from "../../managers/I18n";
import { IFile, ITicketItem, TicketItemType } from "../../types";
import { Icon } from "../globalComponents";
import { getMedia, getTicketAddon } from "./GetMedia";
import { getTicketDescription, getTicketIcon, getTicketTitle } from "./TicketItemText";
import "./TimeLineItem.scss";

export interface ITicketItemStartProps {
	item: ITicketItem;
	handleModal?: (file: IFile) => void | undefined;
}

const TicketItemMedia: React.FunctionComponent<ITicketItemStartProps> = (props: ITicketItemStartProps) => {
	console.log(`[foo] item: ${props.item.url}`);
	return (
		<div className="timeline-block">
			<div className="timeline-icon">
				<Icon name={getTicketIcon(props.item.type)} />
			</div>
			<div className="timeline-content">
				<div>
					<h2>
						<T v={getTicketTitle(props.item.type)} />
					</h2>
					{props.item.type === TicketItemType.VideoCapturedEnded && <Button style={{position: "absolute", right: "14px", top: "15px"}} href={`${process.env.REACT_APP_LOCALHOST}${props.item.linkedObject.realFileUri}`} >
						<Icon name="arrow_downward"/>
					</Button>}
				</div>
				<p>
					<T v={getTicketDescription(props.item.type, props.item.data)} />
					{getTicketAddon(props.item)}
				</p>

				{getMedia(props.item, props.handleModal)}

				<span className="timeline-date">
					<p>
						{moment
							.utc(props.item.date)
							.local()
							.format("LL")}
						<br />
						<small>
							{moment
								.utc(props.item.date)
								.local()
								.format("LT")}
						</small>
					</p>
				</span>
			</div>
		</div>
	);
};

export default TicketItemMedia;
