import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration, Logger, LogLevel } from "msal";

const config : Configuration = {
    auth: {
        authority: `https://login.microsoftonline.com/common`,//8b76f0a5-19db-4da7-8ad1-77f4e36e7bf3',
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: true
        },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
};

export const authenticationParameters = {
    //scopes: ['api://9cc4bd0b-244d-4980-bb73-394453a34273/user_impersonation']
    scopes: [`${process.env.REACT_APP_SCOPE}`] //api://b653f943-bda7-4038-b9e7-d1a76a840433/user_impersonation
}

export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)