import React from "react";
import { NavLink as RouterNavLink, NavLinkProps, RouteComponentProps, withRouter } from "react-router-dom";
import { Icon } from "../globalComponents";
import "./NavLink.scss";

interface IOwnProps {
	iconName: string;
	text: string;
}

type INavLinkProps = NavLinkProps & RouteComponentProps<{}> & IOwnProps;

const NavLinkBase: React.FunctionComponent<INavLinkProps> = ({ iconName, staticContext, to, text, ...rest }) => (
	<RouterNavLink {...rest} to={to} className="layout-navbar-navlink">
		<Icon name={iconName} />
		<span className="layout-navbar-navlink-text">{text}</span>
	</RouterNavLink>
);

export const NavLink = withRouter(NavLinkBase);

export const NormalLink: React.FunctionComponent<IOwnProps & { to: string | undefined }> = ({ iconName, to, text, ...rest }) => (
	<a {...rest} href={to} className="layout-navbar-navlink">
		<Icon name={iconName} />
		<span className="layout-navbar-navlink-text">{text}</span>
	</a>
);
