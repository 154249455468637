import * as signalR from "@microsoft/signalr";
import { call, delay, fork, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { logger } from "../../utilities/logger";
import { getWebsocketConnection, listenToSocketEvents } from "./call-queue";
import { actions } from "./call-queue.actions";

export function* handleConnect(action: ReturnType<typeof actions.connect>) {
	try {
		yield call(logger.debug, "Forking socketEventListener");
		console.log(`[foo] sono prima`);
		const socket : signalR.HubConnection = yield call(createWebSocket);
		console.log(`[foo] sono dopo`);
		yield fork(listenToSocketEvents, socket);
		yield put(actions.signIn());
		logger.debug("Connected!");
	} catch (err) {
		logger.error("Error connecting to websocket.");
	}
}

export function* callQueueSaga() {
	yield takeLatest(getType(actions.connect), handleConnect);
}

export function* createWebSocket() {
	for (let i = 0; i < 5; i++) {
		try {
			const socket: signalR.HubConnection = yield call(getWebsocketConnection);
			return socket;
		} catch (error) {
			if (i < 4) {
				yield delay(1000);
			}
		}
	}
	throw new Error("CreateWebsocket failed");
}
