import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { Id } from "../../types";
import { ITroubleshootingFlatNode, NodeType } from "../../types/ITroubleshootingNode";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./troubleshooting-items.actions";

export const createDefaultQuestionText = () => ({
	en: ["Type question text here..."],
	it: ["Scrivi qui il testo"],
});

export const createDefaultAnswerText = () => ({
	en: ["First answer step", "Second answer step"],
	it: ["Primo passo di risposta", "Secondo passaggio di risposta"],
});

export interface IState {
	error: boolean;
	loading: boolean;
	sortOrder: Id[];
	data: IDictionary<ITroubleshootingFlatNode>;
	saving: boolean;
	selectedNodeId: Id | null;
}

export const initialState: IState = {
	error: false,
	loading: true,
	sortOrder: [],
	data: {},
	saving: false,
	selectedNodeId: null,
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.getTroubleshootingItemsForProduct):
			return {
				...state,
				loading: true,
			};
		case getType(actions.getTroubleshootingItemsForProductError):
			return {
				...state,
				loading: false,
				error: true,
			};
		case getType(actions.getTroubleshootingItemsForProductSuccess):
			return {
				...state,
				loading: false,
				error: false,
				sortOrder: action.payload.map(i => i.id),
				data: makeDictionary(action.payload, "id"),
			};
		case getType(actions.setSelectedNodeId):
			return {
				...state,
				selectedNodeId: action.payload,
			};
		case getType(actions.clearSelectedNode):
			return {
				...state,
				selectedNodeId: null,
			};
		case getType(actions.saveTroubleshootingTree):
			return {
				...state,
				saving: true,
			};
		case getType(actions.saveTroubleshootingTreeError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.saveTroubleshootingTreeSuccess):
			return {
				...state,
				saving: false,
				error: false,
				sortOrder: action.payload.map(i => i.id),
				data: makeDictionary(action.payload, "id"),
			};
		case getType(actions.getSingleNode):
			return {
				...state,
				loading: true,
			};
		case getType(actions.getSingleNodeSuccess):
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.getSingleNodeError):
			return {
				...state,
				loading: false,
				error: true,
			};
		case getType(actions.saveSingleNode):
			return {
				...state,
				saving: true,
			};
		case getType(actions.saveSingleNodeError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.saveSingleNodeSuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.createNewAnswer):
			return handleNewAnswer(state);
		case getType(actions.createNewQuestion):
			return handleNewQuestion(state);
		default:
			return state;
	}
};

function getNextId(state: IState): number {
	if (state.sortOrder.length) {
		return Math.max(...state.sortOrder.map(Number)) + 1;
	}

	return 1;
}

function handleNewAnswer(state: IState): IState {
	const id = getNextId(state);
	const newAnswer: ITroubleshootingFlatNode = {
		id,
		parentId: state.selectedNodeId,
		type: NodeType.ANSWER,
		text: createDefaultAnswerText(),
	};
	const sortOrder = [...state.sortOrder];
	const parentIndex = sortOrder.findIndex(i => i === state.selectedNodeId);
	sortOrder.splice(parentIndex + 1, 0, id);
	return {
		...state,
		data: {
			...state.data,
			[newAnswer.id]: newAnswer,
		},
		sortOrder,
		selectedNodeId: id,
	};
}

function handleNewQuestion(state: IState): IState {
	const id = getNextId(state);

	const newQuestion: ITroubleshootingFlatNode = {
		id,
		parentId: state.selectedNodeId,
		type: NodeType.QUESTION,
		text: createDefaultQuestionText(),
	};

	const sortOrder = [...state.sortOrder];

	if (state.selectedNodeId) {
		const parentIdIndex = sortOrder.findIndex(item => item === state.selectedNodeId);
		sortOrder.splice(parentIdIndex + 1, 0, id);
	} else {
		sortOrder.unshift(id);
	}

	return {
		...state,
		data: {
			...state.data,
			[newQuestion.id]: newQuestion,
		},
		sortOrder,
		selectedNodeId: id,
	};
}
