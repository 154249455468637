import { ActionType, createAction } from "typesafe-actions";
import { ITroubleshootingLanguage } from "../../types";

export const actions = {
	fetch: createAction("data/troubleshootingLanguages/fetch"),
	fetchError: createAction("data/troubleshootingLanguages/fetchError", resolve => (errorMsg: string) => resolve(errorMsg)),
	fetchSuccess: createAction("data/troubleshootingLanguages/fetchSuccess", resolve => (data: ITroubleshootingLanguage[]) => resolve(data)),
	toggleSelectLanguage: createAction("data/troubleshootingLanguages/toggleSelectLanguage", resolve => (languageId: string) => resolve(languageId)),
};

export type Action = ActionType<typeof actions>;
