import { defaultTo, path, split } from "ramda";
import { logger } from "./logger";

export function getValueAtStringPath(p: string, o: any) {
	const splitPath = split(".", p);
	const text = path(splitPath, o);
	return defaultTo(p, text);
}

export function warnIfPathIsMissing(stringPath: string, obj: { [key: string]: any }, lang: string) {
	const splitPath = split(".", stringPath);
	const text = path(splitPath, obj);

	if (!text) {
		logger.warn(`Language token at ${stringPath} for language ${lang} is missing.`);
		return stringPath;
	}

	return text;
}
