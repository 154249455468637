import { ActionType, createAction } from "typesafe-actions";
import { Id, ITicket, IUpdateTicket } from "../../types";
import { formatDate } from "../../utilities/date";

export const actions = {
	saveTicket: createAction("data/ticket/saveTicket", resolve => (editetTicket: ITicket, callback?: (message: string, valid: boolean) => void) =>
		resolve({ editetTicket, callback }),
	),
	saveTicketError: createAction("data/ticket/saveTicketError", resolve => (error: string) => resolve(error)),
	saveTicketSuccess: createAction("data/ticket/saveTicketSuccess", resolve => (ticket: ITicket) => resolve(ticket)),

	getTickets: createAction("data/tickets/getTickets", resolve => (productId: Id, step: number = 0, date: string = formatDate(new Date()), description: string, teamId: Id, selectedStatus: string) => resolve({productId, step, date, description, teamId, selectedStatus})),
	getTicketsError: createAction("data/tickets/getTicketsError", resolve => (error: string) => resolve(error)),
	getTicketsSuccess: createAction("data/tickets/getTicketsSuccess", resolve => (items: ITicket[]) => resolve(items)),

	mergeTickets: createAction("data/tickets/mergeTickets", resolve => (ticketsIds: string[], callback?: (message: string, valid: boolean) => void) => resolve({ ticketsIds, callback })),
	mergeTicketsSuccess: createAction("data/ticket/mergeTicketsSuccess", resolve => (ticketIds: string[]) => resolve(ticketIds)),
	mergeTicketsError: createAction("data/ticket/mergeTicketsError", resolve => (error: string) => resolve(error)),

	getTicket: createAction("data/ticket/getTicket", resolve => (ticketId: string) => resolve(ticketId)),
	getTicketError: createAction("data/ticket/getTicketError", resolve => (error: string) => resolve(error)),
	getTicketSuccess: createAction("data/ticket/getTicketSuccess", resolve => (item: ITicket) => resolve(item)),

	deleteTickets: createAction("data/ticket/deleteTickets", resolve => (ticketIds: string[], callback?: (message: string, valid: boolean) => void) => resolve({ ticketIds, callback })),
	deleteTicketsSuccess: createAction("data/ticket/deleteTicketsSuccess", resolve => (ticketIds: string[]) => resolve(ticketIds)),
	deleteTicketsError: createAction("data/ticket/deleteTicketsError", resolve => (error: string) => resolve(error)),

	requestTicketsReport: createAction("data/ticket/requestTicketsReport", resolve => (productId: string | null, callback?: (message: string, valid: boolean) => void) => resolve({ productId, callback })),
	requestTicketsReportSuccess: createAction("data/ticket/requestTicketsReportSuccess", resolve => (productId: string) => resolve(productId)),
	requestTicketsReportError: createAction("data/ticket/requestTicketsReportError", resolve => (error: string) => resolve(error)),

	getTicketView: createAction("data/tickets/getTicketView", resolve => (productId: Id, start: number, length: number, callback: (tickets: ITicket[]) => void) => resolve({productId, start, length, callback})),
	getTicketViewError: createAction("data/tickets/getTicketViewError", resolve => (error: string) => resolve(error)),
	getTicketViewSuccess: createAction("data/tickets/getTicketViewSuccess", resolve => (item: ITicket) => resolve(item)),

	exportTicket: createAction("data/ticket/exportTicket", resolve => (ticketId: string) => resolve(ticketId)),
	exportTicketError: createAction("data/ticket/exportTicketError", resolve => (error: string) => resolve(error)),
	exportTicketSuccess: createAction("data/ticket/exportTicketSuccess", resolve => (item: object) => resolve(item)),

	exportTicketCsv: createAction("data/ticket/exportTicketCsv", resolve => (ticketId: string) => resolve(ticketId)),
	exportTicketCsvError: createAction("data/ticket/exportTicketCsvError", resolve => (error: string) => resolve(error)),
	exportTicketCsvSuccess: createAction("data/ticket/exportTicketCsvSuccess", resolve => (item: object) => resolve(item)),
};

export type Action = ActionType<typeof actions>;
