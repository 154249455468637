import { ActionType, createAction } from "typesafe-actions";
import { Id } from "../../types/";
import { ITroubleshootingFlatNode } from "../../types/ITroubleshootingNode";

export const actions = {
	createNewAnswer: createAction("data/troubleshootingItems/createNewAnswer"),
	createNewQuestion: createAction("data/troubleshootingItems/createNewQuestion"),
	clearSelectedNode: createAction("data/troubleshootingItems/clearSelectedNode"),
	getSingleNode: createAction("data/troubleshootingItems/getSingleNode", resolve => (productId: string, nodeId: number) =>
		resolve({ productId, nodeId }),
	),
	getSingleNodeError: createAction("data/troubleshootingItems/getSingleNodeError", resolve => (errMsg: string) => resolve(errMsg)),
	getSingleNodeSuccess: createAction("data/troubleshootingItems/getSingleNodeSuccess", resolve => (node: ITroubleshootingFlatNode) =>
		resolve(node),
	),
	getTroubleshootingItemsForProduct: createAction("data/troubleshootingItems/getItemsForProduct", resolve => (productId: string) =>
		resolve(productId),
	),
	getTroubleshootingItemsForProductError: createAction("data/troubleshootingItems/getItemsForProductError", resolve => (error: string) =>
		resolve(error),
	),
	getTroubleshootingItemsForProductSuccess: createAction(
		"data/troubleshootingItems/getItemsForProductSuccess",
		resolve => (items: ITroubleshootingFlatNode[]) => resolve(items),
	),
	saveSingleNode: createAction(
		"data/troubleshootingItems/saveSingleNode",
		resolve => (productId: string, node: ITroubleshootingFlatNode, callback?: () => void) => resolve({ node, callback, productId }),
	),
	saveSingleNodeError: createAction("data/troubleshootingItems/saveSingleNodeError", resolve => (errMsg: string) => resolve(errMsg)),
	saveSingleNodeSuccess: createAction("data/troubleshootingItems/saveSingleNodeSuccess", resolve => (node: ITroubleshootingFlatNode) =>
		resolve(node),
	),
	saveTroubleshootingTree: createAction(
		"data/troubleshootingItems/saveTroubleshootingTree",
		resolve => (productId: string, tree: ITroubleshootingFlatNode[]) => resolve({ productId, tree }),
	),
	saveTroubleshootingTreeError: createAction("data/troubleshootingItems/saveTroubleshootingTreeError", resolve => (errMsg: string) =>
		resolve(errMsg),
	),
	saveTroubleshootingTreeSuccess: createAction(
		"data/troubleshootingItems/saveTroubleshootingTreeSuccess",
		resolve => (tree: ITroubleshootingFlatNode[]) => resolve(tree),
	),
	setSelectedNodeId: createAction("data/troubleshootingItems/setSelectedNodeId", resolve => (nodeId: Id) => resolve(nodeId)),
};

export type Action = ActionType<typeof actions>;
