import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { DropzoneLogo } from "../globalComponents/DropzoneCompanyLogo";
import { serverManager } from "../../managers/ServerManager";
import { FileList } from "../globalComponents/FileList";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import { T } from "../../managers/I18n";
import { makeDictionary } from "../../utilities/dictionary";
import { ICompany, IFile } from "../../types";
import { actions } from "../../reducers/companies";
import "./UploadDocumentModal.scss";
import { IRootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";

interface IUploadCompanyLogoModalProps {
    isOpen: boolean;
	toggle: () => void;
	getlogoUrl: (logoUrl: string) => void;
	removeLogo: () => void;
}

export class UploadCompanyLogoModal extends React.Component<IUploadCompanyLogoModalProps> {

    constructor(props: any) {
		super(props);
    }

    render() {
        const { isOpen, toggle, removeLogo } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} className="products-upload-document-modal">
				<ModalHeader toggle={toggle}>
					<T v="NavLink.UploadLogo" />
				</ModalHeader>
				<ModalBody>
					<DropzoneLogo onFileUploaded={this.handleLogoUploaded} validFileTypes={["image/jpeg", "image/png"]} uploader={serverManager.files.upload} />
					{/* <div className="files-uploaded">
						<FileList
							files={makeDictionary(this.state.uploadedFiles, "id")}
							idsToRender={uploadedFiles.map(i => i.id)}
							onFileClicked={voidFn}
						/>
					</div> */}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={removeLogo}>
						<T v="RemoveLogo"/>
					</Button>
					<Button color="primary" onClick={this.onSaveClicked}>
						<T v="Done" />
					</Button>
					<Button color="secondary" onClick={this.onCancelClicked}>
						<T v="Cancel" />
					</Button>
				</ModalFooter>
			</Modal>
        );
    }

    private handleLogoUploaded = (logoUrl: string) => {
		//inserire qui il metodo per la scrittura nel db passando logo
		this.props.getlogoUrl(logoUrl);
	};
	
	private onSaveClicked = () => {
		this.props.toggle();
	};

	private onCancelClicked = () => {
		this.props.toggle();
	};
}