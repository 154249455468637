import { useEffect, useState } from "react";

interface IWindowSize {
	innerHeight: number;
	innerWidth: number;
	outerHeight: number;
	outerWidth: number;
}

export function getSize() {
	return {
		innerHeight: window.innerHeight,
		innerWidth: window.innerWidth,
		outerHeight: window.outerHeight,
		outerWidth: window.outerWidth,
	};
}

export function isMobile(size: IWindowSize): boolean {
	if (size.innerWidth > 500) {
		return false;
	}

	return true;
}

export function useMobile() {
	const [windowSize, setWindowSize] = useState(getSize());

	function handleResize() {
		setWindowSize(getSize());
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isMobile(windowSize);
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState(getSize());

	function handleResize() {
		setWindowSize(getSize());
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return windowSize;
}
