import { AxiosPromise } from "axios";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch } from "redux";
import { PRODUCT_PLACEHOLDER_URL } from "../../constants";
import { IRootState } from "../../reducers/root-reducer";
import { IProgress } from "../../types";
import { classNames, fileAccepted, isFileWithinSizeLimit, fileMatchSize, fileValidator, getDataTransferItems } from "../../utilities";
import "./ImageDropzone.scss";
import { actions as filesActions } from "../../reducers/files";

export interface IImageDropzoneProps extends React.HTMLProps<HTMLDivElement>, ConnectProps {
	imageUrl: string | null | undefined;
	onFileUploaded: (file: File) => void;
	uploader: (file: File, progressCallback: (progress: IProgress) => void) => AxiosPromise<unknown>;
	isDisabled: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface IImageDropzoneState {
	isDropping: boolean;
	progress: number;
	uploadingFile: File | null;
	imageFetchedStamp: number;
}

class ImageDropzoneBase extends React.Component<IImageDropzoneProps, IImageDropzoneState> {
	public state: IImageDropzoneState = {
		isDropping: false,
		progress: 0,
		uploadingFile: null,
		imageFetchedStamp: Date.now(),
	};
	private readonly validFileTypes = ["image/png", "image/jpeg"];

	public render() {
		const { className, imageUrl, uploader, onFileUploaded, onDrop, onDragOver, onDragEnd, onDragEnter, onDragLeave, ...rest } = this.props;
		const realImageUrl = imageUrl || PRODUCT_PLACEHOLDER_URL;
		return (
			this.props.isDisabled ? (
				<div
					className={this.getContainerClassNames(className)}
				>
					<img src={realImageUrl + `?${this.state.imageFetchedStamp}`} />
				</div>
			) : (
				<div
					{...rest}
					className={this.getContainerClassNames(className)}
					onDragOver={this.handleOnDragOver}
					onDragLeave={this.handleOnDragLeave}
					onDragEnter={this.handleOnDragEnter}
					onDrop={this.handleDrop}
				>
					<img src={realImageUrl + `?${this.state.imageFetchedStamp}`} />
				</div>)
		);
	}

	private getContainerClassNames = (className?: string) => {
		const { isDropping } = this.state;
		const base = classNames("app-image-dropzone-container", className);

		if (isDropping) {
			return classNames(base, "is-dropping");
		}
		return base;
	};

	private fileValidator = (file: File) => {
		const isValidFileType = fileAccepted(file, this.validFileTypes);
		let isBelowMaxFileSize = file.size < this.props.maxWeight;
		const isAccepted = isValidFileType && isBelowMaxFileSize;
		return isAccepted;
	};

	private handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: false });

		const droppedFiles = getDataTransferItems(e);
		const firstFile = [droppedFiles[0]];
		const { acceptedFiles, rejectedFiles } = fileValidator(firstFile as File[], this.fileValidator);

		if (rejectedFiles.length > 0) {
			return;
		}

		if (acceptedFiles.length > 0) {
			return this.handleUpload(acceptedFiles[0]);
		}
	};

	private handleUpload = async (newFile: File) => {
		const progress = 0;
		const uploadingFile = newFile;
		await this.setState({ uploadingFile, progress });
		await this.props.uploader(newFile, this.progressCallback);

		this.props.onFileUploaded(newFile);
		await this.setState({ imageFetchedStamp: Date.now() });
	};

	private progressCallback = (progress: IProgress) => {
		this.setState({ progress: progress.uploaded });
	};

	private handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
	};

	private handleOnDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: true });
	};

	private handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.setState({ isDropping: false });
	};
}

const mapStateToProps = (state: IRootState) => ({
	sound: state.callQueue.sound,
	queue: state.callQueue.queue,
	activeCall: state.callQueue.activeCall,
	isNavOpen: state.layout.isNavOpen,
	userInfoLoading: state.userInfo.isLoading,
	userInfo: state.userInfo.data,
	userType: state.callQueue.userType,
	company: state.company.data.company,
	maxWeight: state.files.maxFileUploadWeight,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMaximumFileWeight: filesActions.getFilesMaximumSizeLimitUpload,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const ImageDropzone = connect(mapStateToProps, mapDispatchToProps)(ImageDropzoneBase);