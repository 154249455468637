import { UserRole } from "../types/IRoles";

export interface Ivalue {
	value: number;
	name: string;
}

export function enumToArray(item: any, userRole?: UserRole): Ivalue[] {
	var keys = Object.keys(item).map((key, index) => ({ value: index, name: item[key] }));
	if (userRole !== undefined && userRole !== UserRole.SuperAdmin)
		keys = keys.filter(key => key.value !== 3);
	return keys;
}
