import moment from "moment";
import "moment/min/locales";
import React, { createContext } from "react";
import { warnIfPathIsMissing } from "../utilities";
import { logger } from "../utilities/logger";
import { languageData } from "./lang";

export const T: React.FunctionComponent<{ v: string }> = ({ v }) => <Message id={v} />;

interface ILanguageContextType
{
	changeLanguage: (lang: string) => void;
	currentLanguage: string;
	language: {
		[key: string]: any;
	};
}

const LanguageContext = createContext<ILanguageContextType>({
	currentLanguage: navigator.language.substring(0, 2),
	changeLanguage: (some: string) => void 0,
	language: {},
});

interface ILanguageProviderState
{
	language: string;
	changeLanguage: (lang: string) => void;
}

export const LanguageConsumer = LanguageContext.Consumer;

export class LanguageProvider extends React.Component<{}, ILanguageProviderState> {
	private changeLanguage = (language: string) =>
	{
		this.setState({ language });
		setCookie("Language", language);
	};


	// tslint:disable-next-line:member-ordering
	public state: ILanguageProviderState = {
		language: getCurrentLanguage(),
		changeLanguage: this.changeLanguage,
	};

	// tslint:disable-next-line:member-ordering
	public render()
	{
		const value = {
			currentLanguage: this.state.language,
			changeLanguage: this.changeLanguage,
			language: languageData[this.state.language],
		};
		setDatesLanguage(value.currentLanguage);

		logger.debug("Current language set to", this.state.language);
		return <LanguageContext.Provider value={value}>{this.props.children}</LanguageContext.Provider>;
	}
}

export function setCookie(name: string, value: string) //TODO: change class
{
	const newCookie = `${name}=${value}`;

	document.cookie = newCookie;

	console.log("SETTING COOKIE DONE: " + document.cookie);
}

export function getCookie(name: string): string | null //TODO: change class
{
	var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
	if (match) {
		return match[2];
	}
	else {
		return null;
	}
}

export function getCurrentLanguage(): string
{
	var language = getCookie("Language");

	if (language == null) {
		language = navigator.language.substring(0, 2);
		setCookie("Language", language);
	}

	return language;
}

export interface IMessageType
{
	id: string;
	wrap?: boolean;
}

export const Message: React.FunctionComponent<IMessageType> = ({ id, wrap = true }) =>
{
	return (
		<LanguageConsumer>
			{({ language, currentLanguage }): any =>
			{
				const val = warnIfPathIsMissing(id, language, currentLanguage);
				if (!wrap) {
					return val;
				}
				return <span>{warnIfPathIsMissing(id, language, currentLanguage) as string}</span>;
			}}
		</LanguageConsumer>
	);
};

interface IFnChildrenFn
{
	children: (fn: (val: string) => string) => React.ReactNode;
}

interface IStringChildrenFn
{
	children: (value: string) => React.ReactNode;
}

export const Lang: React.FunctionComponent<IFnChildrenFn> = ({ children }) =>
{
	const getLanguageString = (language: any, currentLanguage: any) => (pathString: string) => warnIfPathIsMissing(pathString, language, currentLanguage) as string;
	return <LanguageConsumer>{({ language, currentLanguage }) => children(getLanguageString(language, currentLanguage))}</LanguageConsumer>;
};

export const GetLangString = (path: string): string =>
{
	const lang = getCurrentLanguage();//navigator.language.substring(0, 2);

	var isKey: boolean = false;
	try {
		isKey = languageData[lang];
	}
	catch{

	}
	return warnIfPathIsMissing(path, languageData[isKey ? lang : "en"], lang) as string;
}

export const setDatesLanguage = (currentLang: string) => {

	console.log("[debug] currentLang " + currentLang);
	if (currentLang === 'cn') {
		currentLang = 'zh-cn';
	}
	if (currentLang === 'no') {
		currentLang = 'nb';
	}

	moment.locale(currentLang);
}


export const Msg: React.FunctionComponent<IMessageType & IStringChildrenFn> = ({ id, children }) => (
	<LanguageConsumer>{({ language, currentLanguage }) => children(warnIfPathIsMissing(id, language, currentLanguage) as string)}</LanguageConsumer>
);
