import React from "react";
import { PRODUCT_PLACEHOLDER_URL } from "../../constants";
import { Message } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IProduct } from "../../types";
import { classNames } from "../../utilities";
import { Icon, LoadingPage } from "../globalComponents";
import "./ProductInfo.scss";

export interface IProductInfoProps {
	fullscreen: boolean;
	onCloseClick?: () => void;
	error: boolean;
	loading: boolean;
	product: IProduct;
	userAgent: string;
}

export class ProductInfo extends React.Component<IProductInfoProps> {
	public render() {
		const { loading, error, product, fullscreen, userAgent, onCloseClick } = this.props;

		if (error) {
			return <div>Something wrong while fetching product.</div>;
		}

		if (loading || !product) {
			return <LoadingPage />;
		}
		var imageUrl = "";
		//const imageUrl = product.thumbnailUrl || PRODUCT_PLACEHOLDER_URL;

		if (product.thumbnailUrl === "" || product.thumbnailUrl === null)
			imageUrl = PRODUCT_PLACEHOLDER_URL;
		else
			imageUrl = `${process.env.REACT_APP_LOCALHOST}${product.thumbnailUrl}`;

		return (
			<React.Fragment>
			<div className={classNames("product-info-container", fullscreen ? "fullscreen" : "")}>
				<p style={{ padding: "5px", fontSize: "10px" }}>{userAgent}</p>
				<div className="product-info-header">
					<span className="product-info-header-title">
						<Message wrap={false} id="ProductsProperties.ProductInformation" />
					</span>
					{fullscreen && <Icon className="close-click" name="close" onClick={onCloseClick} />}
				</div>
				<div className="product-info-image-container">
					<img src={imageUrl} />
				</div>
				<div className="product-info-body">
					<div className="product-info-body-header">
						<div className="product-info-body-header-name">
							<p>{product.name}</p>
							<span>{product.productCode}</span>
						</div>
						<div className="product-info-body-header-company">
							<p>
								<Message wrap={false} id="CompanyProperties.CompanyName" />
							</p>
							<span>{product.companyName}</span>
						</div>
					</div>
					<div className="product-info-body-content">
						<p>
							<Message wrap={false} id="ProductsProperties.ProductDescription" />
						</p>
						<span>{product.description}</span>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}
}
