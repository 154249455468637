import React from "react";
import Webcam from "react-webcam";
import Button from "reactstrap/lib/Button";
import Modal, { ModalProps } from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Lang } from "../../managers/I18n";
import { IProduct } from "../../types";
import { useFormInput } from "../../utilities";
import QrVideo from "../products/QrVideo";
import "./QrModal.scss";

export interface IQrModalProps extends ModalProps {
	products: IProduct[]
}

export const QrModal: React.FunctionComponent<IQrModalProps> = ({ toggle, products, ...rest }) => {

	return (
		<Lang>
			{getLanguageString => (
				<Modal className="qr-modal-overlay" toggle={toggle} {...rest}>
					<div className="qr-video">
						<QrVideo products={products} width={window.outerWidth * 0.95} toggle={toggle} />
					</div>
				</Modal>
			)}
		</Lang>
	);
};
