import React from "react";
import Col from "reactstrap/lib/Col";
import { T } from "../../managers/I18n";
import { IApp } from "../../types";
import { IconButton, Icon, RequiredLabel, IconTextButton } from "../globalComponents";
import "./AppsList.scss";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import { OriginalHtmlProps, classNames } from "../../utilities";
import { Label, Row, FormGroup, Form, CardBody, Container } from "reactstrap";
import applySpec from "ramda/es/applySpec";
import moment from "moment";

interface IAppInfoProps
{
	app: IApp;
	handleSaveApp: (app: IApp) => void;
	handleRemoveApp: (appId: string) => void;
}

interface IAppInfoState { }

export class AppInfoEdit extends React.Component<IAppInfoProps, IAppInfoState> {
	public state: IAppInfoState = {};

	public render()
	{
		const { app, handleSaveApp, handleRemoveApp } = this.props;

		return (
			<CardBody>
				<Form>
					<Row noGutters>
						<Col className="gutter-right" xs={12} md={6}>
							<FormGroup>
								<RequiredLabel>
									<T v={"Apps.Id"} />
								</RequiredLabel>
								<Input
									onChange={(e) => app.id = e.target.value}
									defaultValue={app.id}
								/>
							</FormGroup>
						</Col>
						<Col className="gutter-right" xs={12} md={6}>
							<FormGroup>
								<RequiredLabel>
									<T v={"Apps.Name"} />
								</RequiredLabel>
								<Input
									onChange={(e) => app.name = e.target.value}
									defaultValue={app.name}
								/>
							</FormGroup>
						</Col>

						<Col className="gutter-right" xs={12} md={6}>
							<FormGroup>
								<Label>
									<T v={"Apps.Ip"} />
								</Label>
								<Input
									disabled={true}
									defaultValue={app.lastAccessIp}
								/>
							</FormGroup>
						</Col>

						<Col className="gutter-right" xs={12} md={6}>
							<Row>
								<Label style={{ marginLeft: 14 }}>
									<T v={"Apps.Authorized"} />
								</Label>
								<Input
									style={{ width: 28, height: 28, left: 'true', marginLeft: 14, marginTop: 28 }}
									type={"checkbox"}
									defaultChecked={app.isAuthorized}
									onChange={(e) => app.isAuthorized = e.target.checked}
								/>
							</Row>
						</Col>

						<Col className="gutter-right" xs={12} md={6}>
							<FormGroup>
								<Label>
									<T v={"Apps.CreationDate"} />
								</Label>
								<Input
									disabled={true}
									defaultValue={moment(app.creationDate).utc(true).local().toString()}
								/>
							</FormGroup>
						</Col>

						<Col className="gutter-right" xs={12} md={6}>
							<FormGroup>
								<Label>
									<T v={"Apps.AccessDate"} />
								</Label>
								<Input
									disabled={true}
									defaultValue={moment(app.lastAccessDate).utc(true).local().toString()}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
				<Row style={{ justifyContent: "space-between", width: "300px" }}>
					<IconTextButton color="primary" iconName="save" text="Apps.Save" onClick={() => handleSaveApp(app)} />
					<IconTextButton color="primary" iconName="delete_forever" text="Apps.Remove" onClick={() => handleRemoveApp(app.id)} />
				</Row>
			</CardBody>
		);
	}
}

interface IAppsListProps
{
	notFound: boolean;
	apps: IApp[];
	onCreateApp: () => void;
	onAppSelected: (id: string) => void;
	onAppDeSelected: () => void;
}

interface IAppsListState
{
	appFilter: string;
}

export class AppsList extends React.Component<IAppsListProps, IAppsListState> {
	public state: IAppsListState = {
		appFilter: "",
	};


	public render()
	{
		const { notFound, apps, onAppSelected, onAppDeSelected, onCreateApp } = this.props;

		return (
			<Col sm={12} lg={notFound ? 12 : 4} className="companies-list-container" onClickCapture={() => onAppDeSelected()}>
				<div className="companies-list-actionbar">
					<div>
						<h4 className="companies-list-items">
							<T v="Apps.ListTitle" />
						</h4>
					</div>
					<div>
						<IconButton iconName="add" onClick={onCreateApp} />
					</div>
				</div>

				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<Button disabled color="input">
							<i className="material-icons">search</i>
						</Button>
					</InputGroupAddon>
					<Input
						value={this.state.appFilter}
						onChange={e => this.setState({ appFilter: e.target.value.toLowerCase() })}
					/>
				</InputGroup>

				<div className="companies-list">{apps.map(app => app.name.toLowerCase().includes(this.state.appFilter) && renderAppListItem(app, onAppSelected))}</div>
			</Col>
		);
	}
}

const renderAppListItem = (app: IApp, onAppSelected: any) => (
	<AppListItem key={app.id} id={app.id} name={app.name} onClick={() => onAppSelected(app.id)} />
);


type IProp = {
	id: string;
	name: string;
} & OriginalHtmlProps<HTMLDivElement>;

const AppListItem: React.FunctionComponent<IProp> = ({ className, id, name, ...rest }) =>
{
	return (
		<div className={classNames(className, "company-item-row")} {...rest}>
			<Icon name="account_box" />
			<Container fluid>
				<Row sm={12} style={{ display: "flex" }}>
					<span className="company-item-row-name">{name}</span>
				</Row>
				<Row sm={12} style={{ display: "flex" }}>
					<span className="company-item-row-subname">{id}</span>
				</Row>
			</Container>
		</div>
	);
};
