import * as React from "react";
import { connect } from "react-redux";
import { Card, Col, Container, Row } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions } from "../../reducers/companies";
import { ICompanyAdmin, ICompany, ICompanyCreate, ICompanyItemView, Id, IUser, IUserCreate, IApp } from "../../types";
import { toArray, useMobile, delay } from "../../utilities";
import { ErrorPage, Icon } from "../globalComponents";
import { LoadingPage } from "../globalComponents/LoadingPage";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import "./CompanyAdminDashboard.scss";
import CompanyInfromation from "./CompanyInfromation";
import CompanyInfromationCreate from "./CompanyInfromationCreate";
import { CompanyList } from "./CompanyList";
import { AppsList, AppInfoEdit } from "./AppsList";
import { Users } from "../user/Users";
import moment from "moment";
import { companySaga } from "../../reducers/company";

interface ICompanyDashboardState {
	notFound: boolean;
	loading: boolean;
	error: boolean;
	saving: boolean;
	isCreateNew: boolean;
	isEditing: boolean;
	isDeleting: boolean;
	company: ICompany | undefined;
	app: IApp | undefined;
	companyToCreate: ICompany | undefined;
	userToCreate: ICompanyAdmin | undefined;
}

interface IOwnProps extends InjectedToastsProps {
	isMobile: boolean;
}

type ICompanyDashboardProps = ConnectProps & IOwnProps;

class Company extends React.Component<ICompanyDashboardProps, ICompanyDashboardState> {
	public state: ICompanyDashboardState = {
		notFound: true,
		loading: false,
		error: false,
		saving: false,
		isCreateNew: false,
		isEditing: false,
		isDeleting: false,
		company: undefined,
		app: undefined,
		companyToCreate: undefined,
		userToCreate: undefined,
	};

	public componentDidMount() {
		// const connectionId = getCookieValue("connectionId");
		// serverManager.users.addLoggedUser(connectionId as string);
		this.props.getCompanies();
		//this.props.getApps();
	}

	public render() {
		const { loading, error, notFound, app, company, userToCreate, isCreateNew: isCreateNew } = this.state;
		const { companies, apps } = this.props;
		if (loading) {
			return <LoadingPage />;
		}

		if (error) {
			return (
				<ErrorPage>
					<T v={"Error"} />
				</ErrorPage>
			);
		}

		return (
			<Container fluid>
				{apps.length > 0 && (
					<Row style={{ marginTop: 15, padding: 15 }}>
						<Col lg={8} className="animated">
							{this.getAppView(app)}
						</Col>
						{
							<AppsList
								notFound={!notFound}
								apps={apps}
								onAppSelected={this.handleAppSelected}
								onAppDeSelected={this.handleAppDeSelected}
								onCreateApp={this.handleAppCreated}
							/>
						}
					</Row>)}

				<Row style={{ marginTop: 15, padding: 15 }}>
					<Col lg={8} className="animated">
						{this.getCompanyView(company, userToCreate, isCreateNew)}
					</Col>
					{
						<CompanyList
							notFound={!notFound}
							onCompanySelected={this.handleCompanySelected}
							onCompanyDeSelected={this.handleCompanyDeSelected}
							onCreateCompany={this.handleNewCompany}
						/>
					}
				</Row>
			</Container>
		);
	}

	private getAppView = (app?: IApp) => {
		if (this.state.isDeleting) {
			return <LoadingPage />;
		}

		if (app !== undefined) {
			return (
				<AppInfoEdit
					app={app}
					handleSaveApp={this.handleSaveApp}
					handleRemoveApp={this.handleRemoveApp}
				/>
			);
		}
		return this.noAppSelected();
	};

	private noAppSelected = (): any => {
		if (this.props.isMobile === true) {
			return null;
		}

		return (
			<div className="app-detail-container">
				<div className="company-detail-actionbar">
					<div>
						<h4 className="company-detail-items">
							<T v="Apps.NoAppSelected" />
						</h4>
					</div>
				</div>
				<Card className="app-unselected">
					<Icon name="comapny" className="app-unselected-icon" />
					<T v="Apps.SelectApp" />
				</Card>
			</div>
		);
	};

	private getCompanyView = (company?: ICompany, userToCreate?: ICompanyAdmin, isCrateNew?: boolean) => {
		if (this.state.isDeleting) {
			return <LoadingPage />;
		}

		if (company !== undefined) {
			if (isCrateNew) {
				return (
					<CompanyInfromationCreate
						isCreateNew={this.state.isCreateNew}
						isEditing={this.state.isEditing}
						company={company}
						user={userToCreate}
						isSaving={this.state.saving}
						onCreateClicked={this.handleCreateCompany}
					/>
				);
			}

			return (
				<div>
					<CompanyInfromation
						isSuperAdmin={true}
						canEdit={true}
						isEditing={this.state.isEditing}
						company={company}
						isSaving={this.state.saving}
						onSaveClicked={this.handleSaveCompany}
						onDeleteClicked={this.handleDeleteCompany}
					/>
					<Col>
						<Users forcedCompanyId={company.id} />
					</Col>
				</div>
			);
		}
		return this.noCompanySelected();
	};


	private noCompanySelected = (): any => {
		if (this.props.isMobile === true) {
			return null;
		}

		return (
			<div className="company-detail-container">
				<div className="company-detail-actionbar">
					<div>
						<h4 className="company-detail-items">
							<T v="Companies.NoCompanySelected" />
						</h4>
					</div>
				</div>
				<Card className="company-unselected">
					<Icon name="comapny" className="company-unselected-icon" />
					<T v="Companies.SelectCompany" />
				</Card>
			</div>
		);
	};

	private handleCreateCompany = (newCompany: ICompanyCreate, newUser: ICompanyAdmin) => {
		const { company } = this.state;

		this.setState({
			companyToCreate: {
				id: newCompany.id ? newCompany.id : "",
				name: newCompany.name,
				officialName: newCompany.officialName,
				logoUrl: newCompany.logoUrl ? newCompany.logoUrl : "",
				address: newCompany.address,
				postCode: newCompany.postCode,
				state: newCompany.state ? newCompany.state : "",
				city: newCompany.city,
				country: newCompany.country,
				licenseInformation: newCompany.licenseInformation,
				mainCompanyId: newCompany.mainCompanyId,
				allowTemporaryUsers: newCompany.allowTemporaryUsers,
				allowArFunctions: newCompany.allowArFunctions,
				allowTeams: newCompany.allowTeams,
				allowApiUsage: newCompany.allowApiUsage,
				allowMinuteRate: newCompany.allowMinuteRate,
				expirationDate: newCompany.expirationDate,
				notificationsEmail: newCompany.notificationsEmail,
				maximumCallTime: newCompany.maximumCallTime,
				totalExtraMinutes: newCompany.totalExtraMinutes,
				totalUsedMinutes: newCompany.totalUsedMinutes,
				creationDate: newCompany.creationDate,
				renewalMonths: newCompany.renewalMonths,
				uploadLimit: newCompany.uploadLimit,
				storageLimit: newCompany.storageLimit,
			},
			userToCreate: newUser,
		});

		this.props.createCompany({ companyViewModel: newCompany, companyAdminViewModel: newUser }, this.validation);
	};

	private handleSaveCompany = (updatedCompany: ICompany) => {
		this.props.updateCompany(updatedCompany, this.validation);
		this.setState({ company: updatedCompany });
	};

	private handleDeleteCompany = (deletedCompany: ICompany) => {
		this.setState({ isDeleting: true });

		const response = this.props.deleteCompany(deletedCompany.id, this.validation);
		if(response.payload.companyId === deletedCompany.id){
			const subordinateCompanies = this.props.companies.filter(company => company.mainCompanyId === deletedCompany.id);
			if(subordinateCompanies !== null || subordinateCompanies !== undefined){
				subordinateCompanies.map(value => value.mainCompanyId = null);
			}
		}
	};


	private handleNewCompany = () => {
		const company = {
			id: "",
			name: "",
			officialName: "",
			logoUrl: "",
			address: "",
			postCode: "",
			state: "",
			city: "",
			country: "",
			licenseInformation: "",
			mainCompanyId: null,
			allowTemporaryUsers: false,
			allowArFunctions: false,
			allowTeams: false,
			allowApiUsage: false,
			allowMinuteRate: false,
			expirationDate: moment().add(40, 'days').format("YYYY-MM-DDTHH:mm:ss"),
			notificationsEmail: "",
			maximumCallTime: undefined,
			totalExtraMinutes: 0,
			totalUsedMinutes: 0,
			creationDate: moment().format("YYYY-MM-DD"),
			renewalMonths: 0,
			uploadLimit: 0,
			storageLimit: 0,
		};
		this.setState({ company, isCreateNew: true, isEditing: false });
	};

	private handleCompanySelected = (id: string) => {
		const company = this.props.companies.find((item: ICompany) => item.id === id);
		if (company !== undefined) {
			this.props.getLicensesByCompanyId(company.id);
			this.props.getSubordinateCompaniesById(company.id);
		}
		this.setState({ company });
	};

	private handleAppDeSelected = () => {
		this.setState({ app: undefined });
	};

	private handleAppSelected = (id: string) => {
		const app = this.props.apps.find((item: IApp) => item.id === id);
		this.setState({ app });
	};

	private handleSaveApp = (updatedApp: IApp) => {
		this.props.setApp(updatedApp, this.validationApp);
		this.setState({ app: updatedApp });
	};

	private handleAppCreated = () => {
		const updatedApp: IApp = {
			id: "",
			name: "New App",
			creationDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
			lastAccessDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
			lastAccessIp: "",
			isAuthorized: false,
		}

		this.props.setApp(updatedApp, this.validationApp);
		this.setState({ app: updatedApp });
	};

	private handleRemoveApp = (id: string) => {
		this.setState({ app: undefined });
		this.props.removeApp(id, this.validationApp);
	};

	private handleCompanyDeSelected = () => {
		this.setState({ company: undefined, isCreateNew: false, isEditing: false, userToCreate: undefined });
	};

	private validation = async (msgId: string, valid: boolean) => {
		this.setState({ isDeleting: false });

		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId });
			this.setState({ isCreateNew: false, userToCreate: undefined });
			return;
		}

		if (this.state.isCreateNew) {
			this.setState({ company: this.state.companyToCreate, isCreateNew: true, isEditing: false });
		}

		this.props.addToast({ type: ToastType.Error, msgId });
	};

	private validationApp = async (msgId: string, valid: boolean) => {
		this.setState({ app: undefined });

		if (valid) {
			this.props.addToast({ type: ToastType.Success, msgId });
		}
		else {
			this.props.addToast({ type: ToastType.Error, msgId });
		}

		//this.props.getApps();
	};
}

const mapStateToProps = (state: IRootState) => ({
	loading: state.companies.loading,
	error: state.companies.error,
	notFound: state.companies.notFound,
	saving: state.companies.saving,
	companies: toArray(state.companies.data) as ICompany[],
	apps: toArray(state.companies.apps) as IApp[],

});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getSubordinateCompaniesById: actions.getSubordinateCompaniesById,
			getSingleCompany: actions.getSingleCompany,
			updateCompany: actions.updateCompany,
			getCompanies: actions.getCompanies,
			createCompany: actions.createCompany,
			deleteCompany: actions.deleteCompany,
			getLicensesByCompanyId: actions.getLicensesByCompanyId,
			getApps: actions.getApps,
			setApp: actions.setApp,
			removeApp: actions.removeApp,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

const CompanyAdminDashboardBaseComponent = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withToasts(Company));

export const CompanyAdminDashboard = () => {
	const isMobile = useMobile();
	return <CompanyAdminDashboardBaseComponent isMobile={isMobile} />;
};
