import { Omit } from "ramda";
import * as React from "react";
import { ICreateToast } from "./toast.interfaces";
import { ToastConsumer } from "./toast.provider";

export interface InjectedToastsProps {
	addToast: (data: ICreateToast) => void;
}

export function withToasts<T extends InjectedToastsProps = InjectedToastsProps>(WrappedComponent: React.ComponentType<T>) {
	const displayName = WrappedComponent.displayName || "Component";
	type ComponentProps = Omit<T, keyof InjectedToastsProps>;

	return class ComponentWithToasts extends React.Component<ComponentProps> {
		public static displayName = `withToasts(${displayName})`;
		public render() {
			return <ToastConsumer>{({ addToast }) => <WrappedComponent addToast={addToast} {...this.props as any} />}</ToastConsumer>;
		}
	};
}
