import { combineReducers } from "redux";
import { IState as CallQueue, reducer as callQueue } from "./call-queue";
import { IState as Companies, reducer as companies } from "./companies";
import { IState as Company, reducer as company } from "./company";
import { IState as Files, reducer as files } from "./files";
import { IState as Layout, reducer as layout } from "./layout";
import { IState as OVCall, reducer as ovCall } from "./ov";
import { IState as Products, reducer as products } from "./products";
import { IState as Tickets, reducer as tickets } from "./tickets";
import { IState as Tools, reducer as tools } from "./tools";
import { IState as TroubleshootingItems, reducer as troubleshootingItems } from "./troubleshooting-items";
import { IState as TroubleshootingLanguages, reducer as troubleshootingLanguages } from "./troubleshooting-languages";
import { IState as UserInfo, reducer as userInfo } from "./user-info";
import { IState as Users, reducer as users } from "./users";

export interface IRootState {
	callQueue: CallQueue;
	files: Files;
	layout: Layout;
	products: Products;
	companies: Companies;
	company: Company;
	troubleshootingItems: TroubleshootingItems;
	tickets: Tickets;
	troubleshootingLanguages: TroubleshootingLanguages;
	users: Users;
	userInfo: UserInfo;
	tools: Tools;
	ovCall: OVCall;
}

export const rootReducer = combineReducers<IRootState>({
	callQueue,
	files,
	layout,
	products,
	troubleshootingItems,
	tickets,
	tools,
	companies,
	company,
	troubleshootingLanguages,
	users,
	userInfo,
	ovCall,
});
