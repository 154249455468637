import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { Action, actions } from "./tools.actions";

interface IVesion {
	version: string;
}

export interface IState {
	error: string;
	isLoading: boolean;
	data: IVesion;
}

export const initialState: IState = {
	isLoading: true,
	error: "",
	data: {
		version: "",
	},
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.getVersion):
			return {
				...initialState,
			};
		case getType(actions.getVersionSuccess):
			return {
				...state,
				isLoading: false,
				data: {
					version: action.payload,
				},
			};
		case getType(actions.getVersionError):
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};
