import * as React from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardFooter from "reactstrap/lib/CardFooter";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IUser } from "../../types";
import { ICompanyAdmin, ICompany, ICompanyCreate } from "../../types/ICompany";
import { objectDiff } from "../../utilities";
import { Icon, IconButton, IconTextButton, LoadingPage } from "../globalComponents";
import { Spinner } from "../globalComponents/Spinner";
import { RequiredLabel } from '../globalComponents';
import "./CompanyInformation.scss";
import { connect } from "react-redux";
import Button from "reactstrap/lib/Button";
import moment from "moment";
import { ICreatedLicense } from "../../types/ILicense";
import { actions } from "../../reducers/companies";
import { IRootState } from "../../reducers/root-reducer";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import { LinkSubordinateToMain } from "./LinkSubordinateToMain";

interface ItemProps extends ConnectProps, InjectedToastsProps {
	company: ICompany | any;
	user: ICompanyAdmin | undefined;
	isSaving: boolean;
	isCreateNew: boolean;
	// saveError: boolean;
	nonSubordinateCompanies: ICompany[];
	isEditing: boolean;
	onCreateClicked?: (newCompany: ICompanyCreate, newUser: ICompanyAdmin) => void;
	onSaveClicked?: (newCompany: ICompanyCreate, newUser: ICompanyAdmin) => void;
}

interface ItemState {
	isCreateNew: boolean;
	loading: boolean;
	isEditing: boolean;
	company: ICompany;
	user: ICompanyAdmin;
	nameValid: boolean;
	nameInValid: boolean;
	companyNameValid: boolean;
	companyNameInValid: boolean;
	officialNameValid: boolean;
	officialNameInValid: boolean;
	isDateValid: boolean;
}

export class CompanyInfromationCreateBase extends React.Component<ItemProps, ItemState> {
	public state: Readonly<ItemState> = {
		company: {
			id: "",
			name: "",
			officialName: "",
			logoUrl: "",
			address: "",
			postCode: "",
			state: "",
			city: "",
			country: "",
			licenseInformation: "",
			mainCompanyId: null,
			allowTemporaryUsers: false,
			allowArFunctions: false,
			allowTeams: false,
			allowApiUsage: false,
			allowMinuteRate: false,
			notificationsEmail: "",
			expirationDate: moment().add(40, 'days').format("YYYY-MM-DD"),
			maximumCallTime: undefined,
			totalUsedMinutes: 0,
			totalExtraMinutes: 0,
			creationDate: moment().format("YYYY-MM-DD"),
			renewalMonths: 0,
			uploadLimit: 50,
			storageLimit: 20,
		},
		user: {
			id: "",
			userName: "",
			firstName: "",
			lastName: "",
			phone: "",
			isLinked: false,
			email: "",
		},
		isDateValid: true,
		isEditing: false,
		loading: false,
		isCreateNew: false,
		// Validation
		nameValid: false,
		nameInValid: false,
		companyNameValid: false,
		companyNameInValid: false,
		officialNameValid: false,
		officialNameInValid: false,
	};

	public componentDidMount() {

		this.props.getNonSubordinateCompanies();
		if (this.props.isEditing || this.props.isCreateNew) {
			this.setState({ isEditing: this.props.isEditing, isCreateNew: this.props.isCreateNew });
		}

		this.setState({ company: this.props.company });
		if (this.props.user != undefined) this.setState({ user: this.props.user });
	}

	public componentDidUpdate(prevProps: ItemProps) {
		if (
			(prevProps !== this.props && this.props.isEditing !== this.state.isEditing && !this.props.isSaving) ||
			(prevProps !== this.props && this.props.isCreateNew !== this.state.isCreateNew && !this.props.isSaving)
		) {
			this.setState({ isEditing: this.props.isEditing, isCreateNew: this.props.isCreateNew });
		}

		if (prevProps !== this.props && !this.props.isSaving) {
			this.setState({ company: this.props.company });
			if (this.props.user != undefined) this.setState({ user: this.props.user });
		}

		if (prevProps !== this.props && this.props.company.id === "" && !this.props.isSaving) {
			const user = {
				id: "",
				userName: "",
				firstName: "",
				lastName: "",
				isLinked: false,
				phone: "",
				email: "",
			};
			this.setState({ company: this.props.company, user });
			if (this.props.user != undefined) this.setState({ user: this.props.user });
		}
	}

	private createCheckboxElement(langString: string, companyFieldName: any, companyFieldValue: string | boolean, editMode: boolean): JSX.Element {
		return (
			<div style={{ width: "50%", height: 58 }}>
				<Col className="gutter-right" xs={12} md={6}>
					<Row>
						<Label>
							<T v={langString} />
						</Label>
						<Input
							style={{ width: 28, height: 28, left: 'true', marginLeft: 0, marginTop: 28 }}
							type={"checkbox"}
							checked={companyFieldValue === "true"}
							disabled={editMode}
							className={editMode ? "disabled" : ""}
							onChange={this.handleToggleBoolCompanyChange(companyFieldName)}
						/>
					</Row>
				</Col>
			</div>
		)
	}


	private setMainCompany = (id: string | null) => {
		this.setState({ company: { ...this.state.company, mainCompanyId: id } });
	}

	public render() {
		const {
			isEditing,
			isCreateNew,
			company,
			user,
			loading,
			nameInValid,
			nameValid,
			companyNameValid,
			companyNameInValid,
			officialNameValid,
			officialNameInValid,
		} = this.state;
		const { isSaving } = this.props;
		const editMode = this.returnEditMode(isEditing, isSaving, isCreateNew);
		// const imageUrl = serverManager.products.getProductImageUrl(item.id);

		if (loading) {
			return <LoadingPage />;
		}

		if (!company) {
			return null;
		}

		return (
			<div className="company-detail-container">
				<div className="company-detail-actionbar">
					<div>
						<h4 className="company-list-items">{company.officialName ? company.officialName : "Company name..."}</h4>
					</div>
					{this.renderActionButton()}
				</div>
				<Card className="company-details">
					<CardBody>
						{/* <div className="hide-desktop">
							<img className="product-details-image-mobile" src={imageUrl} />
						</div> */}
						<Form>
							<Row noGutters>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.Name"} />
										</RequiredLabel>
										<Input
											valid={companyNameValid}
											invalid={companyNameInValid}
											onBlur={this.handleCompanyNameValidation}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("name")}
											value={company.name}
										/>
									</FormGroup>
								</Col>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.OfficialName"} />
										</RequiredLabel>
										<Input
											valid={officialNameValid}
											invalid={officialNameInValid}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("officialName")}
											value={company.officialName}
										/>
									</FormGroup>
								</Col>

								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.LicenseInformation"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("licenseInformation")}
											value={company.licenseInformation}
										/>
									</FormGroup>
								</Col> */}

								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.RenewalMonths"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("renewalMonths")}
											value={company.renewalMonths}
										/>
									</FormGroup>
								</Col> */}

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.NotificationsEmail"} />
										</Label>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("notificationsEmail")}
											value={company.notificationsEmail}
										/>
									</FormGroup>
								</Col>


								{/* <Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.ExpirationDate"} />
										</Label>
										<Input
											min={moment().format("YYYY-MM-DD")}

											valid={this.state.isDateValid}
											invalid={!this.state.isDateValid}
											onBlur={this.handleCompanyDateValidation}

											type={"date"}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("expirationDate")}
											value={moment(company.expirationDate).format("YYYY-MM-DD")}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<Label>
											<T v={"Companies.CreationDate"} />
										</Label>
										<Input
											min={moment().format("YYYY-MM-DD")}
											type={"date"}
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("creationDate")}
										/>
									</FormGroup>
								</Col>
							*/}
								<Col className="gutter-right" xs={12} md={6}>{
									<LinkSubordinateToMain
										nonSubordinateCompanies={this.props.nonSubordinateCompanies.filter(x => x.id !== company.id)}
										isEditing={!editMode}
										company={company}
										selectItem={this.setMainCompany}
										hasProducts={false}
									/>}
								</Col>
							</Row>

							<Row noGutters className="company-details-permits">
								{this.createCheckboxElement("Companies.AllowTemporaryUsers", "allowTemporaryUsers", company.allowTemporaryUsers, editMode)}
								{this.createCheckboxElement("Companies.allowArFunctions", "allowArFunctions", company.allowArFunctions, editMode)}
								{this.createCheckboxElement("Companies.allowTeams", "allowTeams", company.allowTeams, editMode)}
								{this.createCheckboxElement("Companies.allowApiUsage", "allowApiUsage", company.allowApiUsage, editMode)}
								{/* {this.createCheckboxElement("Companies.allowMinuteRate", "allowMinuteRate", company.allowMinuteRate, editMode)} */}
								<FormGroup>
									<Label>
										<T v={"Companies.UploadSizeLimit"} />
									</Label>
									<Input
										disabled={editMode}
										className={editMode ? "disabled" : ""}
										onChange={this.handleCompanyChange("uploadLimit")}
									/>

								</FormGroup>
							</Row>

							<Row noGutters className="company-details-permits">
								<FormGroup>
									<Label>
										<T v={"Companies.StorageLimit"} />
									</Label>
									<Input
										disabled={editMode}
										className={editMode ? "disabled" : ""}
										onChange={this.handleCompanyChange("storageLimit")}
									/>


								</FormGroup>
							</Row>

							<Row noGutters className="company-details-adress">
								<Col className="gutter-right" xs={12} md={12}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.Address"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("address")}
											value={company.address}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.City"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("city")}
											value={company.city}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.PostCode"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("postCode")}
											value={company.postCode}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.State"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("state")}
											value={company.state}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Companies.Country"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleCompanyChange("country")}
											value={company.country}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row noGutters className="company-details-user">
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Users.UserName"} />
										</RequiredLabel>
										<Input
											required
											invalid={nameInValid}
											valid={nameValid}
											disabled={editMode}
											onBlur={this.handleUserNameValidation}
											className={editMode ? "disabled" : ""}
											onChange={this.handleUserChange("userName")}
											value={user.userName}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Users.FirstName"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleUserChange("firstName")}
											value={user.firstName}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Users.LastName"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleUserChange("lastName")}
											value={user.lastName}
										/>
									</FormGroup>
								</Col>

								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Users.Email"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleUserChange("email")}
											value={user.email}
										/>
									</FormGroup>
								</Col>
								<Col className="gutter-right" xs={12} md={6}>
									<FormGroup>
										<RequiredLabel>
											<T v={"Users.Phone"} />
										</RequiredLabel>
										<Input
											disabled={editMode}
											className={editMode ? "disabled" : ""}
											onChange={this.handleUserChange("phone")}
											value={user.phone}
										/>
									</FormGroup>
								</Col>
								{/* <div style={{width: "50%", height: 58, marginLeft: 15}}>
									<Col className="gutter-right" xs={12} md={6}>
										<Row>
										<FormGroup>
											<Label>
												<T v={"Users.IsLinked"}/>
											</Label>
											<Input
												style={{ width: 28, height: 28, marginLeft: -100, marginTop: 28 }}
												type="checkbox"
												checked={user.isLinked}
												onChange={this.handleToggleBoolUserChange("isLinked")}
											/>
										</FormGroup>
										</Row>
									</Col>
								</div> */}
							</Row>
						</Form>
						{this.renderAddButton()}
					</CardBody>
					<CardFooter />
				</Card>
			</div>
		);
	}


	private validation = (message: string, valid: boolean) => {
		if (valid) {
			(this.props as any).toastManager.add(message, { appearance: "success", autoDismiss: true });
			return;
		}

		(this.props as any).toastManager.add(message, { appearance: "error", autoDismiss: true });
	};

	private handleToggleBoolUserChange = (fieldName: keyof IUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			user: {
				...this.state.user,
				[fieldName]: e.target.checked,
			},
		});
	}

	private handleUserNameValidation = async () => {
		if (this.state.user.userName === "") {
			this.setState({ nameValid: false, nameInValid: false });
			return;
		}
		const response = await serverManager.users.IsValidUsername(this.state.user.userName);
		if (response.data) {
			this.setState({ nameValid: true, nameInValid: false });
		} else {
			this.setState({ nameValid: false, nameInValid: true });
		}
	};

	private renderActionButton = () => {
		const { isEditing, isCreateNew } = this.state;
		const { isSaving } = this.props;

		if (isSaving) {
			return (
				<div>
					<Spinner />
				</div>
			);
		}

		if (isCreateNew) {
			return (
				<div>
					<IconButton onClick={this.handleCreateButtonClick} iconName={"save"} />
				</div>
			);
		}

		if (isEditing) {
			return (
				<div>
					<IconButton onClick={this.cancelEditMode} iconName={"close"} />
					<IconButton onClick={this.handleSaveButtonClick} iconName={"save"} />
				</div>
			);
		}

		return (
			<div>
				<IconButton onClick={this.handleEditButtonClick} iconName={"edit"} />
			</div>
		);
	};

	private renderAddButton = () => {
		const { isCreateNew } = this.state;

		if (isCreateNew) {
			return (
				<div>
					<IconTextButton color="primary" iconName="add" text="Companies.Create" onClick={this.handleCreateButtonClick} />
				</div>
			);
		}
		return null;
	};

	private handleCompanyChange = (fieldName: keyof ICompany) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			company: {
				...this.state.company,
				[fieldName]: e.target.value,
			},
		});
	};

	private handleToggleBoolCompanyChange = (fieldName: keyof ICompany) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			company: {
				...this.state.company,
				[fieldName]: e.target.checked.toString(),
			},
		});
	}

	private handleUserChange = (fieldName: keyof IUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			user: {
				...this.state.user,
				[fieldName]: e.target.value,
			},
		});
	};

	private handleCompanyNameValidation = async () => {
		if (this.state.company.name === "") {
			this.setState({ companyNameInValid: false, companyNameValid: false });
			return;
		}
		const response = await serverManager.companies.isValidCompanyName(this.state.company.name);
		if (response.data) {
			this.setState({ companyNameValid: true, companyNameInValid: false });
		} else {
			this.setState({ companyNameValid: false, companyNameInValid: true });
		}
	};

	private handleOffisialNameValidation = async () => {
		if (this.state.company.officialName === "") {
			this.setState({ officialNameValid: false, officialNameInValid: false });
			return;
		}
		const response = await serverManager.companies.isValidOfficialName(this.state.company.officialName);
		if (response.data) {
			this.setState({ officialNameValid: true, officialNameInValid: false });
		} else {
			this.setState({ officialNameValid: false, officialNameInValid: true });
		}
	};

	private handleSaveButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const diff = objectDiff(this.state.company, this.props.company);

		if (diff.length) {
			return this.saveItem();
		}

		return this.cancelEditMode();
	};

	private handleCreateButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		//const diff = objectDiff(this.state.company, this.props.company);

		//console.log("trying to create new company");
		//if (diff.length) {
		return this.saveItem();
		//}

		//return this.cancelEditMode();
	};

	private cancelEditMode = () => {
		this.setState({ isEditing: false });
	};

	private saveItem = async () => {
		const { company, user } = this.state;
		if (this.props.onCreateClicked) {
			this.props.onCreateClicked(company, user);
		}
		this.setState({ isEditing: false });
	};

	private handleEditButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.setState({ isEditing: !this.state.isEditing });
	};

	private handleCompanyDateValidation = async () => {
		const date = new Date(this.state.company.expirationDate).getTime();
		const now = new Date().getTime();

		this.setState({ isDateValid: (date - now > 0) });
	};

	private returnEditMode(isEditing: boolean, isSaving: boolean, isCreateNew: boolean) {
		if (isEditing) {
			return !isEditing || isSaving;
		}
		if (isCreateNew) {
			return !isCreateNew || isSaving;
		}
		return true;
	}
}

const mapStateToProps = (state: IRootState) => ({
	nonSubordinateCompanies: state.companies.nonSubordinateCompanies,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getNonSubordinateCompanies: actions.getNonSubordinateCompanies,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

const CompanyInfromationCreate = connect(mapStateToProps, mapDispatchToProps)(withToasts(CompanyInfromationCreateBase));

export default CompanyInfromationCreate;