import { defaultTo, Dictionary, merge, omit } from "ramda";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { IUser } from "../../types";
import { IDictionary, IGenericObject, makeDictionary, toArray } from "../../utilities";
import { Action, actions } from "./users.actions";
import { ITeam } from "../../types/ITeam";

export interface IState
{
	data: IDictionary<IUser>;
	teamsData: IDictionary<ITeam>;
	isDeleting: IDictionary<boolean>;
	isDeletingTeam: IDictionary<boolean>;
	referenceLoading: IDictionary<string[]>;
	referenceTeamLoading: IDictionary<string[]>;
	error: boolean;
	notFound: boolean;
	loading: boolean;
	loadingTeams: boolean;
	saving: boolean;
}

export const initialState: IState = {
	data: {},
	teamsData: {},
	isDeleting: {},
	referenceLoading: {},
	referenceTeamLoading: {},
	isDeletingTeam: {},
	error: false,
	notFound: false,
	loading: false,
	loadingTeams: false,
	saving: false,
};

export const reducer: Reducer<IState> = (state: IState = initialState, action: Action) =>
{
	switch (action.type) {
		case getType(actions.cleanTeams):
			return {
				...state,
				data: {},
			};
		case getType(actions.deleteTeam):
			return {
				...state,
				isDeletingTeam: {
					...state.isDeletingTeam,
					[action.payload.teamId]: true,
				},
			};
		case getType(actions.deleteTeamSuccess):
			return {
				...state,
				isDeletingTeam: omit([action.payload], state.isDeletingTeam),
				teamsData: omit([action.payload], state.teamsData),
			};
		case getType(actions.createTeam):
			return {
				...state,
				saving: true,
			};
		case getType(actions.createTeamError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.createTeamSuccess):
			return {
				...state,
				saving: false,
				teamsData: {
					...state.teamsData,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.updateTeamError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.updateTeam):
			return {
				...state,
				saving: true,
			};
		case getType(actions.updateTeamSuccess):
			return {
				...state,
				saving: false,
				teamsData: {
					...state.teamsData,
					[action.payload.id]: merge(state.teamsData[action.payload.id], action.payload),
				},
			};
		case getType(actions.moveToTeamSuccess):
			return {
				...state,
				data: Object.fromEntries(Object.entries(state.data).filter(([k, v]) => v.id !== action.payload.id))
			};
		case getType(actions.getTeamsByCompany):
		case getType(actions.getTeams):
			return {
				...state,
				notFound: false,
				loadingTeams: true,
			};
		case getType(actions.getTeamsSuccess):
			return {
				...state,
				teamsData: {
					...state.teamsData,
					...makeDictionary(action.payload, "id")
				},
				loadingTeams: false,
				notFound: false
			};
		case getType(actions.getTeamsError):
			return {
				...state,
				loadingTeams: false,
			};
		case getType(actions.deleteUser):
			return {
				...state,
				isDeleting: {
					...state.isDeleting,
					[action.payload.userId]: true,
				},
			};
		case getType(actions.deleteUserSuccess):
			return {
				...state,
				isDeleting: omit([action.payload], state.isDeleting),
				data: omit([action.payload], state.data),
			};
		case getType(actions.createUser):
			return {
				...state,
				saving: true,
			};
		case getType(actions.createUserError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.createUserSuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.updateUserError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.updateUser):
			return {
				...state,
				saving: true,
			};
		case getType(actions.updateUserSuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: merge(state.data[action.payload.id], action.payload),
				},
			};
		case getType(actions.cleanUsers):
			return {
				...state,
				data: {},
			};
		case getType(actions.getUsersByCompany):
		case getType(actions.getUsersByTeam):
		case getType(actions.getUsers):
		case getType(actions.getSingleUser):
			return {
				...state,
				notFound: false,
				loading: true,
			};
		case getType(actions.getUsersSuccess):
			return {
				...state,
				data: makeDictionaryWithTeamId(action.payload, "id"),
				loading: false,
				notFound: false
			};
		case getType(actions.createTempUserSuccess):
			console.log(`[debug] tempUser: ${action.payload.id}`);

			const users = [...toArray(state.data)];
			const index = users.findIndex(user => user.isTemporary);
			users[index] = action.payload;
			return {
				...state,
				data: {
					...state.data,
					[users[index].id]: action.payload
				}
			}
		case getType(actions.getSingleUserSuccess):
			return {
				...state,
				notFound: false,
				loading: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.getUsersError):
		case getType(actions.getSingleUserError):
			return {
				...state,
				loading: false,
			};


		case getType(actions.removeTeamReference):
			return {
				...state,
				referenceTeamLoading: {
					...state.referenceTeamLoading,
					[action.payload.productId]: [...defaultTo([], state.referenceTeamLoading[action.payload.teamId]), action.payload.productId],
				},
			};
		case getType(actions.removeTeamReferenceSuccess):
			return {
				...state,
				referenceTeamLoading: {
					...state.referenceTeamLoading,
					[action.payload.teamId]: state.referenceTeamLoading[action.payload.teamId].filter(i => i !== action.payload.productId),
				},
				teamsData: {
					...state.teamsData,
					[action.payload.teamId]: {
						...state.teamsData[action.payload.teamId],
						referencedProductIds: state.teamsData[action.payload.teamId].referencedProductIds.filter(i => i !== action.payload.productId),
					},
				},
			};
		case getType(actions.removeTeamReferenceError):
			return {
				...state,
				error: true,
			};
		case getType(actions.addTeamReference):
			console.log("Trying to add to reference: " + action.payload.productId);
			return {
				...state,
				referenceTeamLoading: {
					...state.referenceTeamLoading,
					[action.payload.teamId]: [...defaultTo([], state.referenceTeamLoading[action.payload.teamId]), action.payload.productId],
				},
			};
		case getType(actions.addTeamReferenceError):
			return {
				...state,
				error: true,
			};
		case getType(actions.addTeamReferenceSuccess):
			console.log("[foo] to reference: " + state.teamsData[action.payload.teamId].referencedProductIds);
			// if (state.teamsData[action.payload.teamId].referencedProductIds === null) {
			// 	state.teamsData[action.payload.teamId].referencedProductIds = [];
			// }

			return {
				...state,
				referenceTeamLoading: {
					...state.referenceTeamLoading,
					[action.payload.teamId]: state.referenceTeamLoading[action.payload.teamId].filter(i => i !== action.payload.productId),
				},
				teamsData: {
					...state.teamsData,
					[action.payload.teamId]: {
						...state.teamsData[action.payload.teamId],
						referencedProductIds: [...state.teamsData[action.payload.teamId].referencedProductIds, action.payload.productId],
					},
				},
			};

		default:
			return state;
	}

	function makeDictionaryWithTeamId(list: IUser[], keyProp: keyof IUser)
	{
		var dictionary: Dictionary<IUser> = makeDictionary(list, keyProp);

		for (let key in dictionary) {
			if (!dictionary[key].teamId || dictionary[key].teamId === "" || dictionary[key].teamId === " ") {
				dictionary[key].teamId = null
			}
		}

		return dictionary;
	}
};
