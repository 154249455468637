import { defaultTo, merge, omit } from "ramda";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { ICompany, IApp, IAuthorizationCode } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./companies.actions";
import moment from "moment";
import { ILicense, ILicenseNumber } from "../../types/ILicense";

export interface IState {
	data: IDictionary<ICompany>;
	apps: IDictionary<IApp>;
	isDeleting: IDictionary<boolean>;
	referenceLoading: IDictionary<string[]>;
	error: boolean;
	notFound: boolean;
	authCodes: IAuthorizationCode[];
	loading: boolean;
	saving: boolean;
	companyQECode: Blob;
	remainingTime: number | undefined;
	licenses: ILicenseNumber[];
	allLicenses: ILicenseNumber[];
	nonSubordinateCompanies: ICompany[];
	subordinateCompaniesById: ICompany[];
}

export const initialState: IState = {
	data: {},
	apps: {},
	isDeleting: {},
	referenceLoading: {},
	error: false,
	notFound: false,
	authCodes: [],
	loading: false,
	saving: false,
	companyQECode: new Blob(),
	remainingTime: undefined,
	licenses: [],
	allLicenses: [],
	nonSubordinateCompanies: [],
	subordinateCompaniesById: [],
};

export const reducer: Reducer<IState> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.deleteLicensesSuccess):
			return {
				...state,
				licenses: []
			}
		case getType(actions.getAllLicensesSuccess):
			return {
				...state,
				allLicenses: action.payload
			}
		case getType(actions.getLicensesByCompanyIdSuccess):
			return {
				...state,
				licenses: action.payload
			};
		case getType(actions.createLicensesForCompanySuccess):
			return {
				...state,
				licenses: state.licenses.concat(action.payload)
			}
		case getType(actions.deleteAuthCodeSuccess):
			return {
				...state,
				authCodes: state.authCodes.filter(authCode => authCode.code !== action.payload)
			};
		case getType(actions.addCrmEntryPoint):
			const { authCode, entryPoint } = action.payload;
			const index = state.authCodes.findIndex(x => x.code === authCode);
			state.authCodes[index].crmEntryPoint = entryPoint;
			return {
				...state,
				authCodes: state.authCodes
			};
		case getType(actions.getAuthCodeSuccess):
			return {
				...state,
				authCodes: action.payload
			};
		case getType(actions.addAuthCodeSuccess):
			return {
				...state,
				authCodes: [
					...state.authCodes,
					action.payload
				],
			};
		case getType(actions.deleteCompany):
			return {
				...state,
				isDeleting: {
					...state.isDeleting,
				},
			};
		case getType(actions.deleteCompanySuccess):
			return {
				...state,
				isDeleting: omit([action.payload], state.isDeleting),
				data: omit([action.payload], state.data),
			};
		case getType(actions.createCompany):
			return {
				...state,
				saving: true,
			};
		case getType(actions.createCompanyError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.createCompanySuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.updateLicensesRenewalMonthsSuccess):
			// const licenses = [...state.licenses];
			// const updatedLicenseGroup = action.payload;
			// var idx = licenses.findIndex(x => x.startDate === updatedLicenseGroup.startDate && x.endDate === updatedLicenseGroup.endDate && x.totalLicenses === updatedLicenseGroup.totalLicenses);
			// licenses[idx] = updatedLicenseGroup;
			return {
				...state,
				licenses: action.payload
			}
		case getType(actions.setApp):
			return {
				...state,
				saving: true,
			};
		case getType(actions.setAppError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.setAppSuccess):
			return {
				...state,
				saving: false,
				apps: {
					...state.apps,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.updateCompanyError):
			return {
				...state,
				saving: false,
				error: true,
			};
		case getType(actions.updateCompany):
			return {
				...state,
				saving: true,
			};
		case getType(actions.updateCompanySuccess):
			return {
				...state,
				saving: false,
				data: {
					...state.data,
					[action.payload.id]: merge(state.data[action.payload.id], action.payload),
				},
			};
		case getType(actions.getCompanyQRCode):
		case getType(actions.getCompanies):
		case getType(actions.getNonSubordinateCompanies):
		case getType(actions.getSingleCompany):
			return {
				...state,
				notFound: false,
				loading: true,
			};
		case getType(actions.getSubordinateCompaniesByIdSuccess):
			console.log("payload length " + action.payload.length)
			return {
				...state,
				subordinateCompaniesById: action.payload
			};
		case getType(actions.getNonSubordinateCompaniesSuccess):
			return {
				...state,
				nonSubordinateCompanies: action.payload
			};

		case getType(actions.getCompaniesSuccess):
			action.payload.forEach(x => {
				x.expirationDate = moment(x.expirationDate).utc(true).local().format("YYYY-MM-DDTHH:mm:ss");
			});
			return { ...state, data: { ...state.data, ...makeDictionary(action.payload, "id") }, loading: false, notFound: false };
		case getType(actions.removeAppSuccess):
			return { ...state, apps: {}, loading: false, notFound: false };
		case getType(actions.getApps):
			return {
				...state,
				notFound: false,
				loading: true,
			};
		case getType(actions.getAppsSuccess):
			return { ...state, apps: { ...state.apps, ...makeDictionary(action.payload, "id") }, loading: false, notFound: false };
		case getType(actions.getSingleCompanySuccess):
			action.payload.expirationDate = moment(action.payload.expirationDate).utc(true).local().format("YYYY-MM-DDTHH:mm:ss");
			return {
				...state,
				notFound: false,
				loading: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.getCompanyQRCodeError):
		case getType(actions.getAppsError):
		case getType(actions.getCompaniesError):
		case getType(actions.getNonSubordinateCompaniesError):
		case getType(actions.getSingleCompanyError):
			return {
				...state,
				loading: false,
			};
		case getType(actions.updateUsedCallTimeSuccess):
			return {
				...state,
				remainingTime: action.payload
			}
		case getType(actions.getCompanyQRCodeSuccess):
			return {
				...state,
				notFound: false,
				loading: false,
				companyQECode: action.payload,
			};
		default:
			return state;
	}
};
