import { AxiosResponse } from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { ToastType } from "../../components/toast";
import { GetLangString } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { ICompany, ICompanyCreate, ICompanyCreateDto, IApp, IAuthorizationCode } from "../../types";
import { ILicense, ILicenseNumber } from "../../types/ILicense";
import { logger } from "../../utilities/logger";
import { actions } from "./companies.actions";

export function* handleGetCompanies(action: ReturnType<typeof actions.getCompanies>) {
	try {
		logger.debug("Fetching companies");
		const { data }: AxiosResponse<ICompany[]> = yield call(serverManager.companies.get);
		yield put(actions.getCompaniesSuccess(data));
		logger.debug(data.length, "companies fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getCompaniesError("Something went wrong while fetching companies."));
	}
}

export function* handleGetNonSubordinateCompanies(action: ReturnType<typeof actions.getNonSubordinateCompanies>) {
	try {
		logger.debug("Fetching non subordinate companies");
		const { data }: AxiosResponse<ICompany[]> = yield call(serverManager.companies.getNonSubordinateCompanies);
		yield put(actions.getNonSubordinateCompaniesSuccess(data));
		logger.debug(data.length, "non subordinate companies fetched successfully.");
	}
	catch (err) {
		logger.error(err);
		yield put(actions.getNonSubordinateCompaniesError("Something went wrong while fetching non subordinate companies."));
	}
}

export function* handleGetSubordinateCompaniesById(action: ReturnType<typeof actions.getSubordinateCompaniesById>) {
	try {
		logger.debug("Fetching subordinate companies by id");
		const { data }: AxiosResponse<ICompany[]> = yield call(serverManager.companies.getSubordinateCompaniesById, action.payload);
		yield put(actions.getSubordinateCompaniesByIdSuccess(data));
		logger.debug(data.length, "subordinate companies fetched successfully.");
	}
	catch (err) {
		logger.error(err);
		yield put(actions.getSubordinateCompaniesByIdError(err));
	}
}


export function* handleAddAuthCode(action: ReturnType<typeof actions.addAuthCode>) {
	try {
		const { data }: AxiosResponse<IAuthorizationCode> = yield call(serverManager.authorizationCodes.generateAuthCode);
		yield put(actions.addAuthCodeSuccess(data));
		console.log(`AuthCode added successfully`);
	} catch (error) {
		yield put(actions.addAuthCodeError(error));
		console.error(error);
	}
}

export function* handleDeleteAuthCode(action: ReturnType<typeof actions.deleteAuthCode>) {
	try {
		const code = action.payload;
		console.log(`[foo] code: ${code}`);
		yield call(serverManager.authorizationCodes.deleteAuthorizationCode, code);
		yield put(actions.deleteAuthCodeSuccess(code));
		console.log(`Auth code deleted successfully`);
	} catch (error) {
		yield put(actions.deleteAuthCodeError(error));
		console.error(`Auth code delete error`);
	}
}

export function* handleGetAuthCodes(action: ReturnType<typeof actions.addAuthCode>) {
	try {
		const { data }: AxiosResponse<IAuthorizationCode[]> = yield call(serverManager.authorizationCodes.getAuthCodes);
		yield put(actions.getAuthCodeSuccess(data));
		console.log(`AuthCode fetched successfully`);
	} catch (error) {
		yield put(actions.getAuthCodeError(error));
		console.log(error);
	}
}

export function* handleGetSingleCompany(action: ReturnType<typeof actions.getSingleCompany>) {
	try {
		logger.debug("Fetching company with id", action.payload);
		const { data }: AxiosResponse<ICompany> = yield call(serverManager.companies.getById, action.payload);
		yield put(actions.getSingleCompanySuccess(data));
		logger.debug("Company with id", action.payload, "fetched successfully.");
		return data;
	} catch (err) {
		if (err.response && err.response.status === 404) {
			yield put(actions.getSingleCompanyMissing());
		} else {
			logger.error(err);
			yield put(actions.getSingleCompanyError("Something went wrong while fetching company with id: " + action.payload));
		}
	}
}

export function* handleGetAllLicenses(action: ReturnType<typeof actions.getAllLicenses>) {
	try {
		const { data }: AxiosResponse<ILicenseNumber[]> = yield call(serverManager.licenses.getAllLicenses);

		yield put(actions.getAllLicensesSuccess(data));
	} catch (error) {
		yield put(actions.getAllLicensesError(error));
	}
}

export function* handleGetLicensesByCompany(action: ReturnType<typeof actions.getLicensesByCompanyId>) {
	try {
		const companyId = action.payload
		const { data }: AxiosResponse<ILicenseNumber[]> = yield call(serverManager.licenses.getLicensesByCompany, companyId);
		yield put(actions.getLicensesByCompanyIdSuccess(data));
	} catch (error) {
		yield put(actions.getLicensesByCompanyIdError(error));
	}
}

export function* handleDeleteLicenses(action: ReturnType<typeof actions.deleteLicenses>) {
	const { companyId, callback } = action.payload;

	try {

		yield call(serverManager.licenses.deleteLicenses, companyId);
		yield put(actions.deleteLicensesSuccess());

		if (typeof callback === 'function') {
			yield call(callback, "Companies.LicensesDeletedSuccess", ToastType.Success);
		}


	} catch (error) {
		yield put(actions.deleteLicensesError(error));

		if (typeof callback === 'function') {
			yield call(callback, "Companies.LicensesDeletedError", ToastType.Error);
		}
	}
}

export function* handleCreateLicenseForCompany(action: ReturnType<typeof actions.createLicensesForCompany>) {
	const { companyId, licenses, callback } = action.payload;
	try {

		const { data }: AxiosResponse<ILicenseNumber[]> = yield call(serverManager.licenses.createLicensesForCompany, companyId, licenses);

		yield put(actions.createLicensesForCompanySuccess(data));

		yield call(callback, "Companies.LicensesSuccess", ToastType.Success);
	} catch (error) {
		yield put(actions.createLicensesForCompanyError(error as string));

		yield call(callback, "Companies.LicensesError", ToastType.Error);
	}
}

export function* handlePutUserCheckLicense(action: ReturnType<typeof actions.putUserCheckLicense>) {
	try {
		const { companyId, userId } = action.payload;

		const { data }: AxiosResponse<boolean> = yield call(serverManager.licenses.putUser, companyId, userId)

		yield put(actions.putUserCheckLicenseSuccess(data));

	} catch (error) {
		yield put(actions.putUserCheckLicenseError(error));
	}
}

export function* handleUpdateCompany(action: ReturnType<typeof actions.updateCompany>) {
	const { dto, callback } = action.payload;
	try {
		yield call(serverManager.companies.put, dto);
		yield put(actions.updateCompanySuccess(dto));

		if (typeof callback === "function") {
			const contentString = `Toast.CompanyUpdated`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.CompanyUpdatedFailed`;
			yield call(callback, contentString, false);
		}

		yield put(actions.updateCompanyError("Something went wrong while saving compnay with id: " + action.payload.dto.id));
	}
}

export function* handleCreateCompany(action: ReturnType<typeof actions.createCompany>) {
	const { dto, callback, callbackLandingPage} = action.payload;
	try {
		const { data } : AxiosResponse<ICompany> = yield call(serverManager.companies.post, dto);
		
		logger.debug(data);
		yield put(actions.createCompanySuccess(data));

		if (typeof callback === "function") {
			const contentString = `Toast.CompanyCreeated`;
			yield call(callback, contentString, true);
		}

		if (typeof callbackLandingPage === "function") {
			const contentString = `Toast.CompanyCreeated`;
			yield call(callbackLandingPage, contentString, data);
		}
	} catch (err) {
		logger.error(err.response.data);

		if (typeof callback === "function") {
			const contentString = GetLangString(err.response.data);
			yield put(actions.createCompanyError(contentString));
			yield call(callback, contentString, false);
		}

		if (typeof callbackLandingPage === "function") {
			const contentString = GetLangString(err.response.data);
			yield call(callbackLandingPage, contentString, undefined);
		}

	}
}

export function* handleDeleteCompany(action: ReturnType<typeof actions.deleteCompany>) {
	const { companyId, callback } = action.payload;
	try {
		yield call(serverManager.companies.delete, companyId);
		yield put(actions.deleteCompanySuccess(companyId));

		if (typeof callback === "function") {
			const contentString = `Toast.CompanyDeleted`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.CompanyDeletedFailed`;
			yield call(callback, contentString, false);
		}

		yield put(actions.deleteCompanyError("Something went wrong while deleting company with id: " + companyId));
	}
}

export function* handleGetCompanyQRCode(action: ReturnType<typeof actions.getCompanyQRCode>) {
	try {
		logger.debug("Fetching QRCode");
		const { data }: AxiosResponse<Blob> = yield call(serverManager.companies.getCompanyQRCode);
		yield put(actions.getCompanyQRCodeSuccess(data));
		logger.debug(data, "companies fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getCompanyQRCodeError("Something went wrong while fetching companies."));
	}
}

export function* handleUpdateRenewalMonths(action: ReturnType<typeof actions.updateLicensesRenewalMonths>) {
	try {
		const license = action.payload;
		const { data }: AxiosResponse<ILicenseNumber[]> = yield call(serverManager.licenses.updateRenewalMonths, license);
		yield put(actions.updateLicensesRenewalMonthsSuccess(data))

	} catch (error) {
		yield put(actions.updateLicensesRenewalMonthsError(error));
	}
}


export function* handleUpdateUsedCallTime(action: ReturnType<typeof actions.updateUsedCallTime>) {
	const { companyId, time, callback } = action.payload;

	try {
		const { data }: AxiosResponse<number> = yield call(serverManager.companies.updateUsedCallTime, companyId, time);
		yield put(actions.updateUsedCallTimeSuccess(data))

		if (typeof callback === 'function') {
			const contentString = `Toast.UsedCallTimeUpdateSuccessful`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (error) {
		if (typeof callback === 'function') {
			const contentString = `Toast.UsedCallTimeUpdateFailed`;
			yield call(callback, contentString, ToastType.Success);
		}
		console.log(`Something went wrong while updating used call time: ${error}`);
	}
}

export function* handleSendHelp(action: ReturnType<typeof actions.sendHelp>) {
	const { userId, message, callback } = action.payload;
	try {
		yield call(serverManager.companies.sendHelp, userId, message);
		yield put(actions.sendHelpSuccess());

		if (typeof callback === "function") {
			const contentString = `Toast.HelpRequested`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.HelpRequestFail`;
			yield call(callback, contentString, false);
		}
		yield put(actions.sendHelpError("Something went wrong while requesting help"));
	}
}

export function* handleGetApps(action: ReturnType<typeof actions.getApps>) {
	try {
		logger.debug("Fetching apps");
		const { data }: AxiosResponse<IApp[]> = yield call(serverManager.companies.getApps);
		yield put(actions.getAppsSuccess(data));
		logger.debug(data.length, "apps fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getAppsError("Something went wrong while fetching apps."));
	}
}

export function* handleSetApp(action: ReturnType<typeof actions.setApp>) {
	const { dto, callback } = action.payload;
	try {
		const { data }: AxiosResponse<IApp> = yield call(serverManager.companies.setApp, dto);
		logger.debug(data);
		yield put(actions.setAppSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Toast.AppCreated`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.AppCreateFailed`;
			yield call(callback, contentString, false);
		}
		yield put(actions.setAppError("Something went wrong while creating a new company"));
	}
}

export function* handleRemoveApp(action: ReturnType<typeof actions.removeApp>) {
	const { dto, callback } = action.payload;
	try {
		const { data }: AxiosResponse<string> = yield call(serverManager.companies.removeApp, dto);
		logger.debug(data);
		yield put(actions.removeAppSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Toast.AppRemoved`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.AppRemoveFailed`;
			yield call(callback, contentString, false);
		}
		yield put(actions.removeAppError("Something went wrong while creating a new company"));
	}
}

export function* companiesSaga() {
	yield takeLatest(getType(actions.deleteLicenses), handleDeleteLicenses);
	yield takeLatest(getType(actions.updateLicensesRenewalMonths), handleUpdateRenewalMonths);
	yield takeLatest(getType(actions.getAllLicenses), handleGetAllLicenses);
	yield takeLatest(getType(actions.putUserCheckLicense), handlePutUserCheckLicense);
	yield takeLatest(getType(actions.createLicensesForCompany), handleCreateLicenseForCompany);
	yield takeLatest(getType(actions.getLicensesByCompanyId), handleGetLicensesByCompany);
	yield takeLatest(getType(actions.deleteAuthCode), handleDeleteAuthCode);
	yield takeLatest(getType(actions.getAuthCode), handleGetAuthCodes);
	yield takeLatest(getType(actions.addAuthCode), handleAddAuthCode);
	yield takeLatest(getType(actions.deleteCompany), handleDeleteCompany);
	yield takeLatest(getType(actions.createCompany), handleCreateCompany);
	yield takeLatest(getType(actions.getCompanies), handleGetCompanies);
	yield takeLatest(getType(actions.getSubordinateCompaniesById), handleGetSubordinateCompaniesById);
	yield takeLatest(getType(actions.getNonSubordinateCompanies), handleGetNonSubordinateCompanies);
	yield takeLatest(getType(actions.getSingleCompany), handleGetSingleCompany);
	yield takeLatest(getType(actions.updateCompany), handleUpdateCompany);
	yield takeLatest(getType(actions.getCompanyQRCode), handleGetCompanyQRCode);
	yield takeLatest(getType(actions.sendHelp), handleSendHelp);
	yield takeLatest(getType(actions.updateUsedCallTime), handleUpdateUsedCallTime);
	yield takeLatest(getType(actions.getApps), handleGetApps);
	yield takeLatest(getType(actions.setApp), handleSetApp);
	yield takeLatest(getType(actions.removeApp), handleRemoveApp);
}
