import { GetLangString } from "../../managers/I18n";
import { ITicketItem, TicketItemType, TicketStatusText } from "../../types";
import { logger } from "../../utilities";
import { Ticket } from "./Ticket";

export const getTicketTitle = (type: TicketItemType): string => {
	switch (type) {
		case TicketItemType.Login:
			return "Ticket.Login";
		case TicketItemType.SessionEnded:
			return "Ticket.SessionEnded";
		case TicketItemType.Enqueue:
			return "Ticket.Enqueue";
		case TicketItemType.TroubleshootingTreeClose:
			return "Ticket.TroubleshootingTreeClose";
		case TicketItemType.TroubleshootingTreeOpen:
			return "Ticket.TroubleshootingTreeOpen";
		case TicketItemType.TroubleshootingTreeReset:
			return "Ticket.TroubleshootingTreeReset";
		case TicketItemType.AnswerCall:
			return "Ticket.AnswerCall";
		case TicketItemType.VideoCapturedStarted:
			return "Ticket.VideoCapturedStarted";
		case TicketItemType.FileOpen:
			return "Ticket.FileOpen";
		case TicketItemType.FileSent:
			return "Ticket.FileSent";
		case TicketItemType.ImageCaptured:
			return "Ticket.ImageCaptured";
		case TicketItemType.VideoCapturedEnded:
			return "Ticket.VideoCapturedEnded";
		case TicketItemType.AnnotationSent:
			return "Ticket.AnnotationSent";
		case TicketItemType.ModelOpen:
			return "Ticket.ModelOpen";
		case TicketItemType.EndedCall:
			return "Ticket.EndedCall";
		case TicketItemType.ProductDownload:
			return "Ticket.ProductDownload";
		case TicketItemType.ProductChanged:
			return "Ticket.ProductChanged";
		case TicketItemType.AnnotationDeleted:
			return "Ticket.AnnotationDeleted";
		case TicketItemType.ModelPosition:
			return "Ticket.ModelPosition";
		case TicketItemType.CallPublished:
			return "Ticket.CallPublished";
		case TicketItemType.CallPublishedTeam:
			return "Ticket.CallPublishedTeam";
		case TicketItemType.OperatorPosition:
			return "Ticket.OperatorPosition";
		case TicketItemType.ParticipantJoin:
			return "Ticket.ParticipantJoin";
		case TicketItemType.DocumentUploaded:
			return "Ticket.DocumentUploaded";
		case TicketItemType.TicketCreatedSuccess:
			return "Ticket.TicketCreatedSuccess";
		case TicketItemType.TicketStatusChanged:
			return "Ticket.TicketStatusChanged";
		default:
			return "";
	}
};

export const getTicketDescription = (type: TicketItemType, data?: any): string => {
	switch (type) {
		case TicketItemType.Login:
			return "Ticket.LoginDescription";
		case TicketItemType.SessionEnded:
			return "Ticket.SessionEndedDescription";
		case TicketItemType.Enqueue:
			return "Ticket.EnqueueDescription";
		case TicketItemType.TroubleshootingTreeClose:
			return "Ticket.TroubleshootingTreeCloseDescription";
		case TicketItemType.TroubleshootingTreeOpen:
			return "Ticket.TroubleshootingTreeOpenDescription";
		case TicketItemType.TroubleshootingTreeReset:
			return "Ticket.TroubleshootingTreeResetDescription";
		case TicketItemType.AnswerCall:
			return "Ticket.AnswerCallDescription";
		case TicketItemType.VideoCapturedStarted:
			return "Ticket.VideoCapturedStartedDescription";
		case TicketItemType.FileOpen:
			return "Ticket.FileOpenDescription";
		case TicketItemType.FileSent:{
			const fileSentFromDescription = GetLangString("Ticket.FileSentFromDescription");
			 return `${fileSentFromDescription} ${data}`;
		}
		case TicketItemType.ImageCaptured:
			return "Ticket.ImageCapturedDescription";
		case TicketItemType.VideoCapturedEnded:
			return "Ticket.VideoCapturedEndedDescription";
		case TicketItemType.AnnotationSent:
			return "Ticket.AnnotationSentDescription";
		case TicketItemType.ModelOpen: {
			const modelOpenDescription = GetLangString("Ticket.ModelOpenDescription");
			return `${modelOpenDescription} ${data}`;
		}
		case TicketItemType.EndedCall:
			return "Ticket.EndedCallDescription";
		case TicketItemType.ProductDownload:
			return "Ticket.ProductDownloadDescription";
		case TicketItemType.ProductChanged: {
			const productChangedDescription = GetLangString('Ticket.ProductChangedDescription');
			return `${productChangedDescription} ${data}`;
		}
		case TicketItemType.ParticipantJoin: {
			const participantJoinDescription = GetLangString('Ticket.ParticipantJoinDescription');
			return `${participantJoinDescription} ${data}`;
		}
		case TicketItemType.TicketStatusChanged: {
			const ticketStatusChangedDescription = GetLangString('Ticket.TicketStatusChangedDescription');
			let result = data;

			try{

				if(!isNaN(parseInt(data))){
					result = GetLangString(TicketStatusText[parseInt(data)]);
				}
			}
			catch(err)
			{
				logger.error(err);
			}

			return `${ticketStatusChangedDescription} ${result}`;
			
		}
		case TicketItemType.AnnotationDeleted:
			return "Ticket.AnnotationDeletedDescription";
		case TicketItemType.ModelPosition:
			return "Ticket.ModelPosition";
		case TicketItemType.CallPublished:
			return "Ticket.CallPublished";
		case TicketItemType.CallPublishedTeam:
			return "Ticket.CallPublishedTeam";
		case TicketItemType.OperatorPosition: {
			const operatorPositionDescription = GetLangString("Ticket.OperatorPositionDescription");
			return `${operatorPositionDescription} ${data}`;
		}
		case TicketItemType.DocumentUploaded:
			return "Ticket.DocumentUploadedDescription";
		default:
			return "";
	}
};

export const getTicketIcon = (type: TicketItemType): string => {
	switch (type) {
		
		case TicketItemType.TicketStatusChanged:
			return "wifi_protected_setup"
		case TicketItemType.TicketCreatedSuccess:
		case TicketItemType.Login:
			return "person";
		case TicketItemType.SessionEnded:
			return "exit_to_app";
		case TicketItemType.Enqueue:
			return "call_made";
		case TicketItemType.TroubleshootingTreeClose:
		case TicketItemType.TroubleshootingTreeOpen:
		case TicketItemType.TroubleshootingTreeReset:
			return "build";
		case TicketItemType.AnswerCall:
			return "call";
		case TicketItemType.VideoCapturedEnded:
		case TicketItemType.VideoCapturedStarted:
			return "videocam";
		case TicketItemType.DocumentUploaded:
		case TicketItemType.ImageCaptured:
			return "insert_photo";
		case TicketItemType.AnnotationSent:
			return "room";
		case TicketItemType.FileOpen:
		case TicketItemType.FileSent:
			return "description";
		case TicketItemType.ModelOpen:
			return "star";
		case TicketItemType.EndedCall:
			return "call_end";
		case TicketItemType.ProductDownload:
			return "get_app";
		case TicketItemType.ProductChanged:
			return "cached";
		case TicketItemType.AnnotationDeleted:
			return "delete";
		case TicketItemType.OperatorPosition:
			return "location_on";
		case TicketItemType.ParticipantJoin:
			return "add_ic_call";
		default:
			return "";
	}
};
