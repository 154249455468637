import { AxiosInstance } from "axios";
import { IAuthorizationCode } from "../../types/ICompany";


export class AuthorizationCodesManager {
    private agent: AxiosInstance;
	private url: string = "AuthorizationCodes";
	private urlAuth: string = "auth";

    constructor(agent: AxiosInstance) {
        this.agent = agent;
    }

    public addEntryPoint = async (code: string, entryPoint: string) => this.agent.put(`${this.url}/AddEntryPoint?code=${code}&entryPoint=${entryPoint}`);
    public generateAuthCode = async () => this.agent.post<IAuthorizationCode>(`${this.url}/GenerateAuthenticationCode`);
	public getAuthCodes = async () => this.agent.get<IAuthorizationCode[]>(`${this.url}/GetAuthenticationCodes`);
    public deleteAuthorizationCode = async (code: string) => this.agent.delete(`${this.url}/DeleteAuthorizationCode?code=${code}`)
}