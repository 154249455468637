import React from "react";
import { classNames } from "../../utilities/class-names";
import "./Icon.scss";

export type IconProps = {
	name: string;
} & React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>;

export const Icon: React.FunctionComponent<IconProps> = ({ className, name, ...rest }) => (
	<i className={classNames("material-icons", "app_icon", className)} {...rest}>
		{name}
	</i>
);
