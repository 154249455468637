import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { bindActionCreators, Dispatch } from "redux";
import { T } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { IFile, IProduct, IUser, IClips } from "../../types";
import { UserRole } from "../../types/IRoles";
import { classNames, getFileType, FileTypes, delay, toArray } from "../../utilities";
import { Icon, MobileImage, Spinner } from "../globalComponents";
import { DetailInformation, TakeData } from "./DetailInformation";
import "./DocumentationDetails.scss";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { takeEvery } from "redux-saga/effects";
import { actions } from "../../reducers/files";
import { MODEL_PLACEHOLDER_URL, PDF_PLACEHOLDER_URL, PRODUCT_PLACEHOLDER_URL, VIDEO_PLACEHOLDER_URL } from "../../constants";
import { convertImageToFile } from "../active-call/ActiveCallUtils";

interface IProps {
	isCompanyNotSubordinate: boolean;
	files: IFile[];
	linkedProducts?: string;
	onSetOfficial: () => void;
	onLinkProduct: (() => void) | null;
	handleModal: (file: IFile) => void;
	userRole: UserRole;
	takeData: JSX.Element | null;
}

const DocumentationDetails: React.FunctionComponent<IProps> = ({
	userRole,
	files,
	onSetOfficial,
	onLinkProduct,
	linkedProducts,
	isCompanyNotSubordinate,
	handleModal,
}) => {
	const dispatch = useDispatch();

	if (files.length === 0) {
		return null;
	}

	const file: IFile = files[0];
	const singleFile: boolean = files.length === 1;
	const modalView = isModalView(file);
	let thumbnailUrl = "";

	if (singleFile) {
		console.log("file url: " + file.thumbnailUrl);
		console.log("real file url: " + file.realFileUri);
		if (file.thumbnailUrl == 'generic://model') {
			thumbnailUrl = MODEL_PLACEHOLDER_URL;
		}
		else if (file.thumbnailUrl == 'generic://video') {
			thumbnailUrl = VIDEO_PLACEHOLDER_URL;
		} else if (file.thumbnailUrl == 'generic://pdf') {
			thumbnailUrl = PDF_PLACEHOLDER_URL;
		} else if (file.thumbnailUrl == 'generic://image/png') {
			thumbnailUrl = PRODUCT_PLACEHOLDER_URL;
		} else {
			thumbnailUrl = `${process.env.REACT_APP_LOCALHOST}${file.thumbnailUrl}`;
		}
		return (
			<div className={userRole !== UserRole.Operator ? "div-documentation-detail" : ""}>
				{userRole === UserRole.Operator ? (
					<div>
						{modalView ? (
							<div
								className="product-card-container-mobile"
								onClick={() => handleModal(file)}
							>
								<div className="app-stretched-image">
									<MobileImage
										height={80}
										width={80}
										imgSrc={thumbnailUrl}
									/>
								</div>
								<div className="product-card-header-mobile">
									<div className="product-card-header-text-mobile">
										<p>{file.name}</p>
										<span>
											{moment
												.utc(file.createdDate)
												.local()
												.format("LL")}
										</span>
									</div>
								</div>
							</div>
						) : (
							<a href={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} target="_blank">
								<div className="product-card-container-mobile">
									<div className="app-stretched-image">
										<MobileImage
											height={80}
											width={80}
											imgSrc={thumbnailUrl}
										/>
									</div>
									<div className="product-card-header-mobile">
										<div className="product-card-header-text-mobile">
											<p>{file.name}</p>
											<span>
												{moment
													.utc(file.createdDate)
													.local()
													.format("LL")}
											</span>
										</div>
									</div>
								</div>
							</a>
						)}
					</div>
				) : (
					<Card>
						<CardHeader>
							<h5>{file.name}</h5>
						</CardHeader>
						{<a href={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`}>{getImage(thumbnailUrl)}</a>}


						{getImageContent(
							isCompanyNotSubordinate,
							file,
							onSetOfficial,
							onLinkProduct,
							userRole,
							linkedProducts
						)}
						<CardFooter />
					</Card>
				)}
			</div>
		);
	} else {
		return (
			<Card>
				<CardHeader>
					<h5>
						<T v="DocumentationDetails.SelectedFiles" />
					</h5>
				</CardHeader>
				<CardBody>
					{files.map((docFile) => (
						<div className={"selected-files"}>{docFile.name}</div>
					))}
					;
				</CardBody>
				<CardFooter />
			</Card>
		);
	}
};

const getImageContent = (
	isCompanyNotSubordinate: boolean,
	file: IFile,
	onSetOfficial: () => void,
	onLinkProduct: (() => void) | null,
	userRole: UserRole,
	linkedProducts?: string
) => {
	if (file.realFileUri == null) {
		return null;
	}

	return (
		<CardBody>

			{userRole >= UserRole.CompanyAdmin && (
				<TakeData
					isCompanyNotSubordinate={isCompanyNotSubordinate}
					file={file}
				/>
			)}

			<DetailInformation
				isCompanyNotSubordinate={isCompanyNotSubordinate}
				fileName={file.name}
				fileSize={file.bytes || 0}
				fileType={getFileType(file)}
				dateAdded={new Date(file.createdDate)}
				isOfficial={file.official}
				linkedProducts={linkedProducts}
				onSetOfficial={onSetOfficial}
				onLinkProduct={onLinkProduct}
				userRole={userRole}
			/>
		</CardBody>
	);
};

const getImage = (imageURL?: string | null) => {
	if (imageURL == null || imageURL === "") {
		return (
			<div className="icon">
				<i className="material-icons md-72">image</i>
			</div>
		);
	}


	if (imageURL.includes("model")) {
		console.log("path Model: " + imageURL)
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/model-placeholder.png" />
			</div>
		);
	} else if (imageURL.includes("video")) {
		console.log("path Video: " + imageURL)
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/video-placeholder.png" />
			</div>
		);
	} else if (imageURL.includes("pdf")) {
		console.log(`path Pdf: ${imageURL}`);
		return (
			<div className="thumbnail-image">
				<img className="image" src={PDF_PLACEHOLDER_URL} />
			</div>
		);
	}

	return (
		<div className="thumbnail-image">
			<img className="image" src={imageURL} />
		</div>
	);
};

export const isModalView = (file: IFile): boolean => {
	if (file && file.name !== undefined) {
		const fileExt = file.name.split(".").pop();
		if (
			fileExt === "glb" ||
			fileExt === "gltf" ||
			fileExt === "fbx" ||
			fileExt === "png" ||
			fileExt === "jpg" ||
			fileExt === "pdf" ||
			fileExt === "mp4"
		) {
			return true;
		}
	}

	return false;
};

const mapStateToProps = (state: IRootState) => ({
	userRole: state.userInfo.data.userRole,
	//files: toArray(state.files.data)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({

	}, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DocumentationDetails);
