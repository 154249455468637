import moment from "moment";
import * as React from "react";
import { serverManager } from "../../managers/ServerManager";
import { Id, IFile } from "../../types";
import { formatKilobytes } from "../../utilities/files";

interface IFileItemProps {
	item: IFile;
	

	onSelect: (id: string) => void;
	selected?: Id[];
}

const renderImage = (thumbnailLink: string | null): JSX.Element => {

	if (thumbnailLink === "" || !thumbnailLink) {
		return (
			<div className="icon">
				<i className="fa fa-file" />
			</div>
		);
	}

	if(thumbnailLink.includes("image")){
		console.log("path Image: " + thumbnailLink);
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/product-placeholder.gif" />
			</div>
		);
	}
	if (thumbnailLink.includes("model")) {
		console.log("path Model: " + thumbnailLink);
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/model-placeholder.png" />
			</div>
		);
	} else if (thumbnailLink.includes("video")) {
		console.log("path Video: " + thumbnailLink);
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/video-placeholder.png" />
			</div>
		);
	} else if (thumbnailLink.includes("pdf")) {
		console.log("path pdf: " + thumbnailLink);
		return (
			<div className="thumbnail-image">
				<img className="image" src="/images/pdf-placeholder.png"/>
			</div>
		);
	}

	return (
		<div className="thumbnail-image">
			<img className="image" src={process.env.REACT_APP_LOCALHOST + thumbnailLink} />
		</div>
	);
};

export default (props: IFileItemProps) => {
	console.log("URL : " + props.item.thumbnailUrl);
	const { id, name, createdDate, bytes, thumbnailUrl } = props.item;

	const getActive = (): string => {
		return props.selected && props.selected.includes(id) ? "file active" : "file";
	};

	return (
		<div className="file-box">
			<div
				className={getActive()}
				onClick={() => {
					props.onSelect(id);
				}}
			>
				{renderImage(thumbnailUrl)}
				<div className="file-name">
					{name}
					<br />
					<small style={{right: `10px`}}>
						{`${formatKilobytes(bytes)}`}
					</small>
					<small style={{left: `10px`}}>
						{moment
							.utc(createdDate)
							.local()
							.format("LL")}
					</small>
				</div>
			</div>
		</div>
	);
};
