//import * as msr from "msr";
import { OpenVidu, Publisher, Session, SignalEvent, Stream, StreamEvent, StreamManager, Subscriber, Device } from "openvidu-browser";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import Badge from "reactstrap/lib/Badge";
import { bindActionCreators, Dispatch } from "redux";
import { Lang, T, GetLangString } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IRootState } from "../../reducers";
import { actions as callQueueActions } from "../../reducers/call-queue/call-queue.actions";
import { actions as filesActions } from "../../reducers/files";
import { actions as ovActions } from "../../reducers/ov";
import { actions as productsActions } from "../../reducers/products";
import { Id, IFile, IProgress, IQueueSession, ITicketCreate, ITicketItem, TicketItemType, IClips, ICompany, IProduct, IRecording } from "../../types";
import { classNames, IDictionary, toArray, VideoElementWithMediaStream } from "../../utilities";
import { logger } from "../../utilities/logger";
import { IFileReceivedMessageData, MessageType, parseOnFileReceivedMessage } from "../../utilities/recivedMessage";
import { getRandomName } from "../../WebRTC/helpers";
import {
	IConnectedSession,
	IOVTokenData,
	ISessionConnectionData,
	IUserType,
	OPERATOR,
	parseConnectionData,
	REMOTE_EXPERT,
	REMOTE_PARTICIPANT,
} from "../../WebRTC/RTCHelpers";
//import {MediaRecorder} from "extendable-media-recorder";
import { OVManager } from "../../WebRTC/RTCManager";
import { SignalingHelper, SignalMessage } from "../../WebRTC/Signaling";
import { IPeerInfo } from "../../WebRTC/StreamManager";
import { Icon, Spinner } from "../globalComponents";
import { Dropzone } from "../globalComponents/Dropzone";
import { UploadButton } from "../globalComponents/UploadButton";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import "./ActiveCall.scss";
import { convertBlobToFile, convertImageToFile, log, parsingJSON } from "./ActiveCallUtils";
import { Annotations, IDrawEvent } from "./Annotations";
import { Messaging } from "./Messaging";
import { actions as companyActions } from "../../reducers/company";
import { ProductFileList } from "./ProductFileList";
import { ProductInfo } from "./ProductInfo";
import { ToolbarButton } from "./ToolbarButton";
import { actions as companiesActions } from "../../reducers/companies";
import { VideoContainer } from "./VideoContainer";
import { MobileRemoteVideo } from "../video/MobileRemoteVideo";
import { checkMediaDevices, handleMediaDeviceError, IDeviceStatus } from "../../utilities/checkDevice";
import { getCookieValue } from "../../reducers/call-queue/call-queue";
import { handle3dModel, handleVideoFile } from "../globalComponents/MobileFileHelper";
import { ModalImage } from "../globalComponents/ModalImage";
import { actions as fileActions } from "../../reducers/files";
import ReactDOM from "react-dom";
import { ProductsListActiveCall } from "../products/ProductsInActiveCall";
import { actions as userActions } from "../../reducers/users";
import { ITeam } from "../../types/ITeam";
import { FbxHandler } from "../globalComponents/FbxHandler";
import { CustomAlert } from "../../utilities/custom-alert";
import { UserRole } from "../../types/IRoles";
import { PdfHandler } from "../globalComponents/PdfHandler";
import "../globalComponents/FbxHandler.scss";
import { LayoutBase } from "../../App";
import { HD, HQ, LQ } from "../../constants";
'use strict';

interface ICallWindowState {
	operatorName: string;
	webcamConnected: boolean;
	isFlashAnimating: boolean;
	isScreenSharing: boolean;
	fullscreen: boolean;
	fullscreenProductInfo: boolean;
	myInfo: IPeerInfo | undefined;
	localVideoEnabled: boolean;
	localMicEnabled: boolean;
	sendingFile: boolean;
	recordedVideos: File[];
	recording: boolean;
	showAnnotationsMenu: boolean;
	annotationMode: boolean;
	ticketId: Id;
	progressMap: IDictionary<number>;
	session: Session;
	videoDeviceList: Device[],
	videoDeviceIndex: number,
	mainStreamManager?: StreamManager;
	isConnected: boolean;
	publisher?: Publisher;
	remoteExpert?: Subscriber;
	participants: Subscriber[];
	publisherType: IUserType;
	fileSent: IFile[];
	firstVisualization: boolean;
	secondVisualization: boolean;
	thirdVisualization: boolean;
	secondStreamManager?: StreamManager;
	switchActionParticipantIndex?: number;
	videoResolution: string;
	mainStreamParticipant?: Subscriber;
	videoUploaded: boolean;
	videoRecorded?: File;
	endCallPressed: boolean;
	imageUploaded: boolean;
	annotationUploaded: boolean;
	modalWindowEnabled: boolean;
	openRightMenu: boolean;
	company: ICompany | undefined;
	enableGenericExpertInCompany: boolean;
	enableGenericExpertInTeam: boolean;
	genericProductId: string | null;
	linkedProduct: string | null;
	linkProductModal: boolean;
	isTokenModalOpen: boolean;
	actualProduct: IProduct | null;
	isSelectedProduct: boolean;
	filesArray: IFile[];
	teams: ITeam[];
	products: IProduct[];
	modal3dWindowEnabled: boolean;
	imageBackgroundUrl: string;
	isOperatorStreamChanged: boolean;
	isDifferentProduct?: boolean;
	convertedBlob: Blob | null;
	openCustomAlert: boolean;
	currentTime: number;
	recordingVideo: IRecording[];
	selectedUser: string;
	uploadValue: number;
	isCallEnded: boolean;
	snapshotWidth: number;
	snapshotHeight: number;
	recordingTime: number;
	isStopping: boolean;
	isStarting: boolean;
	isSharing: boolean;
	isToastAllowed: boolean;
	offsetTop: number;
	offsetLeft: number;
}

type ICallWindowProps = ConnectProps & RouteProps & InjectedToastsProps;
var recordingVideos: IRecording[] = [];

var xDown: number | null = null;
var yDown: number | null = null;
var xUp = null;
var yUp = null;
var chunks: any[] = [];

declare var MediaRecorder: any;
class ActiveCallBase extends React.Component<ICallWindowProps, ICallWindowState> {
	public OV: OpenVidu = new OpenVidu();
	private connectedParticipants: Subscriber[] = [];
	private ctx: CanvasRenderingContext2D | null = null;
	private screenshotCanvas: HTMLCanvasElement | null = null;
	private remoteVideo: React.RefObject<VideoElementWithMediaStream>;
	private remoteVideoContainer: React.RefObject<HTMLDivElement>;
	private signalClient = new SignalingHelper(`${process.env.REACT_APP_LOCALHOST}api/MediaServer/`);
	private rndName = getRandomName();
	private recorder: any;
	private sessionConnectionData: ISessionConnectionData | undefined;
	private dragDivRef: React.RefObject<HTMLDivElement>;
	private anchorRef: React.RefObject<HTMLDivElement>;

	constructor(params: any) {
		super(params);

		this.dragDivRef = React.createRef();
		this.anchorRef = React.createRef();

		this.state = {
			offsetTop: 0,
			offsetLeft: 0,
			operatorName: "",
			webcamConnected: false,
			fullscreen: false,
			fullscreenProductInfo: false,
			myInfo: undefined,
			localVideoEnabled: true,
			isFlashAnimating: false,
			localMicEnabled: true,
			recordedVideos: [],
			recording: false,
			isScreenSharing: false,
			showAnnotationsMenu: false,
			sendingFile: false,
			annotationMode: false,
			ticketId: "",
			progressMap: {},
			session: this.OV.initSession(),
			fileSent: [] as IFile[],
			videoDeviceList: [],
			videoDeviceIndex: 1,
			isConnected: false,
			mainStreamManager: undefined,
			publisher: undefined,
			remoteExpert: undefined,
			participants: [],
			publisherType: REMOTE_EXPERT,
			firstVisualization: true,
			secondVisualization: false,
			thirdVisualization: false,
			secondStreamManager: undefined,
			switchActionParticipantIndex: undefined,
			videoResolution: HD,
			mainStreamParticipant: undefined,
			videoUploaded: true,
			videoRecorded: undefined,
			endCallPressed: false,
			imageUploaded: true,
			annotationUploaded: true,
			modalWindowEnabled: false,
			openRightMenu: false,
			company: undefined,
			enableGenericExpertInCompany: true,
			enableGenericExpertInTeam: true,
			linkedProduct: null,
			genericProductId: null,
			linkProductModal: true,
			isTokenModalOpen: false,
			actualProduct: null,
			isSelectedProduct: true,
			filesArray: this.props.filesArray,
			teams: [],
			products: [],
			modal3dWindowEnabled: false,
			imageBackgroundUrl: '',
			isOperatorStreamChanged: false,
			isDifferentProduct: undefined,
			convertedBlob: null,
			openCustomAlert: false,
			currentTime: 0,
			recordingVideo: [],
			selectedUser: "",
			uploadValue: 0,
			isCallEnded: false,
			snapshotWidth: 0,
			snapshotHeight: 0,
			recordingTime: 120000,
			isStopping: false,
			isStarting: false,
			isSharing: false,
			isToastAllowed: false,
		};

		this.remoteVideo = React.createRef<VideoElementWithMediaStream>();
		this.remoteVideoContainer = React.createRef<HTMLDivElement>();

		// const isSafari = isIosSafari();
	}

	public async setupSignaling() {
		if (this.props.userType !== REMOTE_EXPERT) {
			return;
		}
		const result = await this.signalClient.signIn(this.rndName);
		console.log("Signed in as", result);

		await this.setState({
			myInfo: {
				id: result.id,
				name: this.rndName,
				status: 1,
			},
		});

		this.signalClient.listen(result.id);

		if (this.props.peerId) {
			serverManager.queue.dequeue(this.props.peerId);
		}

		if (!this.state.myInfo || !this.props.activeCallId) {
			return;
		}

		this.expertReady();
		this.operatorReady();
	}

	public tokenGetter = async () => {
		console.log("SetupSession Started");
		logger.debug("UserType: ", this.props.userType);


		if (this.props.sessionId) {
			const data: IOVTokenData = { sessionId: this.props.sessionId, userType: this.props.userType };
			try {
				this.sessionConnectionData = await OVManager.tokenGetter(data);
			} catch (error) {
				log("Session created with: ", error);
			}
		} else {
			// TODO Error!!
		}
	};

	public async componentWillMount() {
		this.handleDeviseCheck();
		navigator.mediaDevices.ondevicechange = event => {
			event.preventDefault();
			this.handleDeviseCheck();
		};

	}

	async componentDidUpdate(prevProps: ICallWindowProps, prevState: ICallWindowState) {

		const { company, filesArray, teams, product, products, operatorName } = this.props;
		let newState = {};

		if (prevProps.company !== company) {
			console.log('[debug] user info: ' + this.props.userInfo.teamId);
			newState = { ...newState, company: company };
		}

		if (prevProps.product !== product) {
			newState = { ...newState, actualProduct: product };
		}

		if (prevProps.filesArray !== filesArray && !this.state.modal3dWindowEnabled) {
			if (operatorName && operatorName.indexOf('temp') !== -1) {
				console.log('[debug] filesArrayLength: ' + this.props.filesArray.length);

				if (operatorName !== null) {
					const visibleFiles = this.props.filesArray.filter(x => x.isVisibleToTemporaryUser);
					newState = { ...newState, filesArray: visibleFiles };
				}
			}
			else {
				newState = { ...newState, filesArray: filesArray };
			}
		}

		if (prevProps.teams !== teams) {
			newState = { ...newState, teams: teams };
		}

		if (prevProps.products !== products) {
			newState = { ...newState, products: products };
		}

		if (prevProps.operatorName !== operatorName) {
			console.log('[debug] operatorName = ' + operatorName);
			//this.setState({ operatorName: this.props.operatorName });
		}

		if (Object.keys(newState).length > 0) {
			this.setState(() => { return { ...newState } });
		}

	}

	private handleDeviseCheck = () => {
		navigator.mediaDevices
			.enumerateDevices()
			.then(devises => checkMediaDevices(devises, this.handleDeviseStatus))
			.catch(handleMediaDeviceError);
	};

	private handleDeviseStatus = (deviseConnected: IDeviceStatus) => {
		this.setState({ webcamConnected: deviseConnected.video });
	};

	public connectSession = async () => {
		try {

			if (this.sessionConnectionData) {
				await OVManager.connectSession({ session: this.state.session, ...this.sessionConnectionData });
				console.log("connectSession Success");
				await this.connectUser();
			}
		} catch (error) {
			log("Session created with: ", error);
		}
	};

	public connectUser = async () => {
		const { session, publisherType, } = this.state;
		let videoSource: string = "";

		await this.OV.getDevices().then((devices) => {
			this.setState({
				videoDeviceList: devices.filter(
					(device) => device.kind === "videoinput"
				),
			});

			// const index =
			// 	this.state.videoDeviceList &&
			// 	this.state.videoDeviceList.length > 1 ? 1 : 0;
			const selectedCameraIndex = getCookieValue('selectedCamera') as string;

			console.log('SELECTED CAMERA: ' + selectedCameraIndex);

			if (selectedCameraIndex !== "") {
				const index = parseInt(selectedCameraIndex, 10);
				videoSource = this.state.videoDeviceList[index].deviceId;
				this.setState({ videoDeviceIndex: index });
			} else {
				const index = 0;
				videoSource = this.state.videoDeviceList[index].deviceId;
				this.setState({ videoDeviceIndex: index });
			}
		});

		const selectResolution = getCookieValue('resolution') as string;

		if (selectResolution !== "") {
			this.setState({ videoResolution: selectResolution })
		}

		console.log(videoSource);

		logger.debug("UserType: ", this.props.userType);

		try {
			const userSession: IConnectedSession = await OVManager.connectUser({ session: session, OV: this.OV, type: this.props.userType }, videoSource);
			this.setState({
				publisher: userSession.publisher,
				publisherType: userSession.publisherType,
				isConnected: userSession.isConnected,
			});

			if (userSession.publisher) {
				this.state.session.publish(userSession.publisher).then(() => {
					if (userSession.publisher) {
						console.log("[foo] publisher not null");
						//this.setState({ localVideoEnabled: true });
						userSession.publisher.publishVideo(this.state.localVideoEnabled);
						userSession.publisher.publishAudio(this.state.localMicEnabled);
					}
				});
			}
			console.log('publisher in connectUser: ' + this.state.publisher);
			console.log("trying to publish video");
			
		} catch (error) {
			this.setState({
				isConnected: false,
			});
		}
	};

	public async componentDidMount() {

		const { company, activeCallProductId, userType} = this.props;
		// TODO: add if we get own exensjon for screen shear
		// this.OV.setAdvancedConfiguration({ screenShareChromeExtension: "https://chrome.google.com/webstore/detail/EXTENSION_NAME/EXTENSION_ID" });
		this.setupEvents();
		this.props.getMyCompany();

		if (company && company.mainCompanyId !== null) {
			this.props.getProductsByCompanyId(company.mainCompanyId);
		}
		else {
			this.props.getProducts();
		}
		this.props.getTeams();
		this.props.getUsers();
		this.props.getGenericProduct(this.handleGenericProduct);
		logger.info(activeCallProductId!);
		this.props.getSingleProduct(activeCallProductId!);
		this.setupSignaling();
		await this.tokenGetter();
		await this.connectSession();
		await this.creatSession();
		if (userType === REMOTE_EXPERT) {
			this.props.getFilesForProduct(activeCallProductId!);
			await this.createTicket();
		}

		window.addEventListener("beforeunload", (event) => {
			// navigator.sendBeacon("/api/webrtc/sign_out?peer_id=" + result.id);
			if (this.state.recording) {
				event.preventDefault();
				event.returnValue = '';
			} else {
				this.handleEndCall();
			}
		});

		// window.addEventListener("unload", () => {
		// 	this.handleEndCall();
		// })
		if (navigator.userAgent.toLowerCase().indexOf("ios") !== -1 || navigator.userAgent.toLowerCase().indexOf("mac") !== -1) {

			OVManager.toggleLocalVideo(this.OV, this.state, (videoEnabled) => this.setState((state) => {return {localVideoEnabled: videoEnabled}}));
			
			this.forceCameraOut();
		}

		window.addEventListener('resize', () => {
			if (!this.dragDivRef.current) {
				return;
			}

			this.dragDivRef.current.style.left = 0 + 'px';
			this.dragDivRef.current.style.position = 'absolute';
			this.dragDivRef.current.style.top = '25%';
			this.dragDivRef.current.style.right = 0 + 'px';
		});

		
	}
	private delayMod(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}


	private handleGenericProduct = (product: IProduct) => {
		this.setState({ genericProductId: product.id, linkedProduct: product.id });
	}

	//TO-DO unsolved bug on ios and mac devices
	private async forceCameraOut() {
		await this.delayMod(1000);
		document.getElementById("toggle_local_video")!.click();
	}

	public componentWillUnmount() {
		if (this.props.userType === REMOTE_PARTICIPANT) {
			return;
		}

		// if (this.state.myInfo) {
		// 	fetch("/api/mediaserver/sign_out?peer_id=" + this.state.myInfo.id);
		// }

		if (this.props.sessionId) {
			// serverManager.queue.dequeueSession(this.props.sessionId).then(() => {
			// 	logger.info("session is dequeueSession");
			// });
		}
		// this.signalClient.stopListen();
		logger.warn("ActiveCall UNMOUNTED");
	}

	private dragElement = (e: React.MouseEvent<HTMLDivElement>) => {
		let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
		const dragDivElement = this.dragDivRef.current;

		e = e || window.event;
		e.preventDefault();
		// get the mouse cursor position at startup:
		pos3 = e.clientX;
		pos4 = e.clientY;
		document.onmouseup = closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = elementDrag;

		function elementDrag(e: MouseEvent) {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// set the element's new position:
			if (dragDivElement) {
				const targetX = (dragDivElement.offsetLeft - pos1);
				const targetY = (dragDivElement.offsetTop - pos2);

				dragDivElement.style.left = targetX + "px";
				dragDivElement.style.top = targetY + "px";

				var pRect = document.documentElement.getBoundingClientRect();
				var tgtRect = dragDivElement.getBoundingClientRect();

				if (tgtRect.left < pRect.left) {
					dragDivElement.style.left = 0 + 'px';
				}

				if (tgtRect.top < pRect.top) {
					dragDivElement.style.top = 0 + 'px';
				}

				if (tgtRect.right > pRect.right) {
					dragDivElement.style.left = pRect.width - tgtRect.width + 'px';
				}

				if (tgtRect.bottom > pRect.bottom) {
					dragDivElement.style.top = pRect.height - tgtRect.height + 'px';
				}
			}
		}
		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}


	public render() {
		const { localMicEnabled, localVideoEnabled, fullscreen,
			recording, sendingFile, isConnected,
			isSelectedProduct, products, actualProduct,
			selectedUser, videoUploaded, isCallEnded,
			imageUploaded, annotationUploaded, annotationMode,
			uploadValue, secondVisualization, thirdVisualization,
			secondStreamManager, publisher, videoResolution,
			participants, recordedVideos, isStarting,
			filesArray, videoDeviceList
		} = this.state;

		const { isCallWindowMinimized, userType, operatorName,
			loggedUser, users, productLoading,
			productError, operatorUserAgent, product, company} = this.props;


		if (operatorName)
			console.log('[debug] Operator name indexOf: ' + operatorName.indexOf('temp'));
		return (
			<Lang>
				{getLanguageString => (
					<div id="active-call-page-container" className={this.getPageContainerClassNames()}>
						<div className="landscape-warning">
							<Icon name="screen_rotation" />
							<span>Please turn your device to landscape mode</span>
						</div>
						{this.state.openCustomAlert &&
							<CustomAlert
								toggle={(click: boolean) => this.setState({ openCustomAlert: click })}
								messageHeader={GetLangString('ActiveCall.OpenCallHeader')}
								//messageBody={GetLangString('ActiveCall.OpenCallBody')}
								messageButton1={selectedUser === "" ? GetLangString('ActiveCall.OpenCallButtonCompany') : GetLangString('ActiveCall.UnlockExpert')}
								onClickButton1={selectedUser === "" ? this.openToGenericClick : this.openToSelectedUser}
								messageButton2={selectedUser === "" && loggedUser.teamId ? GetLangString('ActiveCall.OpenCallButtonTeam') : undefined}
								onClickButton2={selectedUser === "" && loggedUser.teamId ? this.openToTeamClick : undefined}
								experts={users.filter(user => user.teamId == loggedUser.teamId && user.id !== loggedUser.id && user.userRole === UserRole.Expert)}
								getExpertToUnlock={(expertName) => this.setState({ selectedUser: expertName })}
							/>}
						{isCallEnded && !videoUploaded &&
							<CustomAlert
								messageHeader={GetLangString('ActiveCall.AlertHeader')}
								messageBody={`${GetLangString('ActiveCall.AlertBody')}\n${Math.trunc(uploadValue * 100)}%`}
							/>
						}
						{isCallEnded && (!imageUploaded || !annotationUploaded) &&
							<CustomAlert
								messageHeader={GetLangString('ActiveCall.AlertHeader')}
								messageBody={`${GetLangString('ActiveCall.AlertBody')}\n${Math.trunc(uploadValue * 100)}%`}
							/>
						}
						<div id="active-call-page-left-column" className="active-call-page-left-column">
							<div
								className={this.getModalContainerClassNames()}
								id="modal-window-2"
								style={{ flexDirection: "row", flexWrap: "nowrap", width: "400px", zIndex: 10000 }}
								ref={this.dragDivRef}
							>
								<button
									style={{ height: "32px", width: "32px" }}
									onClick={this.closeModalWindow}
								>
									<Icon name="close" />
								</button>
								{this.dragDivRef.current && <div className="modal-window-drag-anchor"
									ref={this.anchorRef}
									onMouseDown={this.dragElement}
								>
									<Icon style={{ transform: "rotate(45deg)" }} name="zoom_out_map" />
								</div>}

							</div>

							{<Annotations
								canvasRef={this.setScreenshotCanvasRef}
								isActive={annotationMode}
								onAnnotationComplete={this.completeAnnotation}
								onAnnotationClose={this.closeAnnotation}
								snapshotResolution={this.getSnapshotResolution()}
								remoteVideoRef={this.remoteVideo}
								onContinueDrawing={this.onDraw}
								onEndDrawing={this.onDrawEnd}
								onTextDrawing={this.onText}
								onUndoDrawing={this.onDrawUndo}
								onArrow={this.onArrow}
								onCrosshair={this.onCrosshair}
								onStartDrawing={this.onDrawStart}
							/>}
							{!isCallWindowMinimized && (
								<span className="active-call-users">
									<Badge color="success">{this.getNumberOfPublishers()}</Badge>
									<Icon name="person" />
								</span>

							)}
							<div className="camera-handler-div">
								{videoDeviceList.length > 1 &&
									<ToolbarButton
										tooltip={GetLangString('ActiveCall.SwitchCamera')}
										onClick={() => OVManager.switchCamera(this.OV, this.state, (index) => {this.setState((state) => {return {videoDeviceIndex: index}})})}
										id="switch_camera"
										iconName={"flip_camera_ios"}
										title={GetLangString("ActiveCall.SwitchCamera")}
									/>
								}
								{!LayoutBase.isMobileDevice() && <ToolbarButton
									tooltip={GetLangString('ActiveCall.ShareScreen')}
									onClick={() => OVManager.toggleShareScreen(this.OV, this.state)}
									id="share-screen"
									iconName={"screen_share"}
									title={GetLangString("ActiveCall.ShareScreen")}
								/>}

								{<div className="dropdown-resolution-expert">
									<button
										id="change-resolution"
										style={{ fontWeight: "bold" }}
										title={GetLangString("ActiveCall.ChangeResolution")}
									>{OVManager.changeResolutionIcon(videoResolution)}</button>
									{<div className="dropdown-resolution-expert-content">
										<a onClick={() => this.changeRes(HD)}>High Quality</a>
										<a onClick={() => this.changeRes(HQ)}>Medium Quality</a>
										<a onClick={() => this.changeRes(LQ)}>Low Quality</a>
									</div>}
								</div>}
							</div>
							<div ref={this.remoteVideoContainer} className="active-call-page-remotevideo-container">
								<div
									className={this.get3dModalContainerClassNames()}
									id="modal-3d-manager"
								>
									<button
										style={{ height: "32px", width: "32px" }}
										onClick={this.closeModal3dWindow}
									>
										<Icon name="close" />
									</button>
									<button
										style={{ height: "40px", width: "40px", top: "70px" }}
										onClick={this.sendToOperator}
									>
										<Icon name="done" />
									</button>
								</div>
								{thirdVisualization === false &&
									<VideoContainer
										className={this.getVideoContainerClassName()}
										streamManager={this.getOperatorViewStreamManager()}
										onRemoteVideoRef={this.handleRemoteVideoRef}
									/>
								}

								{secondVisualization === true &&
									<VideoContainer
										className={this.getVideoContainerClassName()}
										streamManager={secondStreamManager}
									/>
								}
								{thirdVisualization === true &&
									<div className={this.changeDivClass()}>
										<div className="item1">
											<VideoContainer
												className={"active-call-page-localvideo-element-third-vis"}
												streamManager={this.getOperatorViewStreamManager()}
											/>
										</div>
										<div className="item2">
											<VideoContainer
												className={"active-call-page-localvideo-element-third-vis"}
												streamManager={userType === REMOTE_EXPERT ? this.getRemoteExpertStreamManager() : publisher as StreamManager}
											/>
										</div>

										{userType !== REMOTE_EXPERT &&
											<div className="item3">
												<VideoContainer
													className="active-call-page-localvideo-element-third-vis"
													streamManager={this.getRemoteExpertStreamManager()}
												/>
											</div>
										}

										{participants.map((participant, index) => {
											return (
												<div className={userType !== REMOTE_EXPERT ? `item${3 + (index + 1)}` : `item${2 + (index + 1)}`}>
													<VideoContainer
														className={"active-call-page-localvideo-element-third-vis"}
														streamManager={this.getParticipantStreamManager(participant)}
													/>
												</div>
											);
										})
										}
									</div>
								}

								{participants.map((participant, index) => {
									return (
										<MobileRemoteVideo
											key={index}
											videoClass="remote_view_hidden"
											streamManager={participant as StreamManager}
										/>
									);
								})}
								{/* {company.allowMinuteRate == "true" && <Timer getTime={this.getTotalTime} />} */}
								{this.renderFullscreenProductInfo()}
								{isCallWindowMinimized && this.renderReturnToCallOverlay()}
								<div className="active-call-page-remotevideo-menu">
									<div className="active-call-page-remotevideo-menu-section">
										<div
											id="touchable-div"
											className="div-touch-element"
										>
											<ToolbarButton
												id="right-menu-button"
												tooltip=""
												iconName="menu"
												onClick={() => { this.state.openRightMenu ? this.setState({ openRightMenu: false }) : this.setState({ openRightMenu: true }) }}
											/>
										</div>
										{userType === REMOTE_EXPERT && (
											<ToolbarButton
												id="camera"
												iconName="camera"
												tooltip="Camera"
												onClick={this.imageCapture}
												title={GetLangString("ActiveCall.ScreenShot")}
											/>
										)
										}
										{userType === REMOTE_EXPERT &&
											<ToolbarButton
												onClick={() => this.setState({ openCustomAlert: true })}
												tooltip={GetLangString("ActiveCall.EnableGenericExpertUser")}
												iconName={"group_add"}
												id="open_to_generic_button"
												title={GetLangString("ActiveCall.EnableGenericExpertUser")}
											/>
										}
										{fullscreen && (
											<ToolbarButton
												id="show_product_info"
												tooltip={getLanguageString("ActiveCall.ToggleProductInfo")}
												iconName="description"
												onClick={this.toggleFullscreenProductInfo}
												title={GetLangString('ActiveCall.ToggleProductInfo')}
											/>
										)}
									</div>
									<div className="active-call-page-remotevideo-menu-section">
										{userType === REMOTE_EXPERT && (
											<ToolbarButton
												className={recording ? "recording" : ""}
												id="recording_start_button"
												tooltip={getLanguageString("ActiveCall.StartRecording")}
												iconName={recording ? "pause" : "fiber_manual_record"}
												onClick={this.handleRecordingClick}
												disabled={isStarting}
												title={GetLangString('ActiveCall.StartRecording')}
											>
												{recording && <div className={"pulse-recording"} />}
												{recordedVideos.length > 0 && (
													<div className={"recorded-videos"}>{recordedVideos.length}</div>
												)}
											</ToolbarButton>
										)}
										<ToolbarButton
											id="mute_mic_button"
											tooltip={localMicEnabled ? getLanguageString("ActiveCall.Unmute") : getLanguageString("ActiveCall.Mute")}
											iconName={!localMicEnabled ? "mic_off" : "mic"}
											onClick={this.toggleMicMute}
											// disabled={this.state.isSharing}
											title={localMicEnabled ? GetLangString('ActiveCall.Mute') : GetLangString('ActiveCall.Unmute')}
										/>
										<ToolbarButton
											tooltip={getLanguageString("ActiveCall.EndCall")}
											className={"lg icon-red"}
											onClick={this.handleEndCall}
											iconName={"call_end"}
											id="hangup_button"
											disabled={isStarting}
											title={GetLangString('ActiveCall.EndCall')}
										/>
										<ToolbarButton
											tooltip={getLanguageString("ActiveCall.ShowCamera")}
											onClick={() => OVManager.toggleLocalVideo(this.OV, this.state, (videoEnabled) => this.setState((state) => {return {localVideoEnabled: videoEnabled}}))}
											iconName={localVideoEnabled ? "videocam" : "videocam_off"}
											id="toggle_local_video"
											// disabled={this.state.isSharing}
											title={localVideoEnabled ? GetLangString('ActiveCall.HideCamera') : GetLangString('ActiveCall.ShowCamera')}
										/>

										{(userType === REMOTE_EXPERT || userType === REMOTE_PARTICIPANT) && (
											<div className="dropup" >
												<ToolbarButton
													tooltip={getLanguageString("ActiveCall.ToggleLocation")}
													onClick={this.takeScreenshot}
													iconName={"edit"}
													id="toggle_location"
													title={GetLangString('ActiveCall.ToggleLocation')}
												/>

												{userType === REMOTE_EXPERT && <div className="dropup-content">
													{company.allowArFunctions === true &&
														<ToolbarButton
															tooltip={getLanguageString("ActiveCall.PositionModel")}
															onClick={this.handleImageCaptured}
															iconName={"wb_iridescent"}
															id="wb_iridescent"
															title={GetLangString('ActiveCall.PositionModel')}
														/>}
													{/* <a onClick={this.takeScreenshot}>{GetLangString('ActiveCall.ToggleLocation')}</a> */}
												</div>}
											</div>
										)}

									</div>
									<div className="active-call-page-remotevideo-menu-section">
										{(participants.length > 0 || userType !== REMOTE_EXPERT) &&
											<ToolbarButton
												tooltip={GetLangString('ActiveCall.ChangeVisualization')}
												id="change_vis"
												iconName={this.changeButtonIcon()}
												onClick={() => this.changeVisualization()}
												title={GetLangString('ActiveCall.ChangeVisualization')}
											/>
										}
										<ToolbarButton
											tooltip={"Minimize call"}
											onClick={this.props.toggleMinimizeCallWindow}
											iconName={"picture_in_picture_alt"}
											id="toggle_minimize"
											title={GetLangString('ActiveCall.FloatingWindow')}
										/>
										<ToolbarButton
											tooltip={getLanguageString("ActiveCall.ToggleFullscreen")}
											onClick={this.toggleFullscreen}
											iconName={fullscreen ? "fullscreen_exit" : "fullscreen"}
											id="toggle_fullscreen"
											title={GetLangString('ActiveCall.ToggleFullscreen')}
										/>

									</div>
								</div>
								{this.state.isToastAllowed && <div className="toast-message" style={{ position: "absolute", zIndex: 99999999 }} onClick={this.stopToast}>
									<h3>{GetLangString('Toast.HintTitle')}</h3>
									{GetLangString('Toast.HintBodyPositionModel')}<br />
									{GetLangString('Toast.HintBodyPositionModelPart1')}<br />
									{GetLangString('Toast.HintBodyPositionModelPart2')}
								</div>}
								{thirdVisualization === false &&
									<div className={this.getLocalVideoContainerClassNames()}>
										{/* <div className="active-call-page-localvideo-placeholder">
										<Icon name="person" />
										</div> */}
										<VideoContainer
											className="active-call-page-localvideo-element"
											streamManager={userType === REMOTE_EXPERT ? this.getRemoteExpertStreamManager() : publisher as StreamManager}
										/>
										{userType !== REMOTE_EXPERT &&
											<div onClick={() => secondVisualization ? this.changeSecondStreamManager(this.getRemoteExpertStreamManager() as Subscriber) : this.changeMainStreamManager(this.getRemoteExpertStreamManager() as Subscriber)}>
												<VideoContainer
													className={this.getLocalVideoElementClassNames()}
													streamManager={this.getRemoteExpertStreamManager()}
												/>
											</div>
										}
										{!annotationMode && participants.map((participant, index) => {
											return (
												<div onClick={() => secondVisualization ? this.changeSecondStreamManager(participant) : this.changeMainStreamManager(participant, index)}>
													<VideoContainer
														className={this.getLocalVideoElementClassNames()}
														streamManager={this.getParticipantStreamManager(participant)}
													/>
												</div>
											);
										})}
										{/* <div className="active-call-page-localvideo-active-pulse">
										<div className="pulse__inner" />
									</div>
									<button onClick={this.toggleLocalVideo}>
										<Icon name="close" />
									</button> */}
									</div>}
							</div>
							{/* <div className="active-call-page-footer">footer</div> */}
						</div>
						<div className={this.getRightMenuClassName()}>
							<ProductInfo
								fullscreen={fullscreen}
								loading={productLoading}
								error={productError}
								product={actualProduct ? actualProduct : product}
								userAgent={operatorUserAgent !== null ? operatorUserAgent : ""}
							/>
							{isSelectedProduct && !this.state.modal3dWindowEnabled && <button className="change-product-button" onClick={this.handleSwitchProductButton}>
								{GetLangString('ActiveCall.SelectProduct')}
							</button>}
							{!isSelectedProduct ? <ProductsListActiveCall
								userInfo={loggedUser}
								products={products.filter(product => {
									let mainGenericProd = undefined;
									if (company && company.mainCompanyId !== null) {
										mainGenericProd = this.props.products.find(x => (x.companyId === company.mainCompanyId && x.productCode === 'Generic'));
									}

									return (
										(company && company.mainCompanyId === null) ||
										(company && company.mainCompanyId !== null && (mainGenericProd === undefined || product.id !== this.state.genericProductId))
									)
								})}
								onClick={this.handleOnClickButton}
							/> :
								(!sendingFile ? (
									<ProductFileList files={filesArray} isConnected={isConnected} onFileClicked={this.handleOnFileUploaded} />
								) : (
									<div className="loading-container">
										<Spinner />
										<h2>
											<T v="ActiveCall.SendingFile" />
										</h2>
									</div>
								))
							}
							{(userType !== OPERATOR) && (
								<Dropzone
									className="active-call-dropzone"
									validFileTypes={["image/jpeg", "image/png", "application/pdf"]}
									validFileExtensions={["jpg", "mp4"]}
									uploader={serverManager.files.upload}
									onFileUploaded={this.handleOnFileUploaded}
									onFileDropInCall={this.handleOnFileDropped}
									onFileRejected={this.handleOnFileRejected}
								/>
							)}
						</div>

					</div>
				)
				}
			</Lang>
		);
	}

	private changeRes = (res: string) => {
		OVManager.changeResolution(this.OV, this.state, res, () => this.setState((state) => { return {videoResolution: res}}));
	}

	private stopToast = () => {
		this.setState({ isToastAllowed: false });
	}

	private getTotalTime = (time: number) => {
		if (time % 60 === 0) {
			this.props.updateUsedCallTime(this.props.company.id as string, time);
		}

		if (this.props.remainingTime !== undefined && this.props.remainingTime <= 0) {
			this.handleEndCall();
		}

		this.setState({ currentTime: time });
	};

	private sendToOperator = () => {
		const canvasStream = document.getElementById('canvas-stream-video') as HTMLCanvasElement;
		const imageBackground = document.createElement("img");
		imageBackground.src = this.state.imageBackgroundUrl;

		const canvasBackground = document.createElement('canvas');
		canvasBackground.width = imageBackground.width;
		canvasBackground.height = imageBackground.height;

		const canvasBackgroundContext = canvasBackground.getContext('2d');

		if (canvasBackgroundContext) {
			canvasBackgroundContext.drawImage(imageBackground, 0, 0, imageBackground.width, imageBackground.height);
			// canvasBackgroundContext.drawImage(canvasStream, 0, 0, imageBackground.width, imageBackground.height);
			if (imageBackground.height > imageBackground.width) {
				var scale = imageBackground.height / canvasStream.height;
				canvasBackgroundContext.drawImage(canvasStream, (canvasStream.width - (imageBackground.width / scale)) / 2, (canvasStream.height - (imageBackground.height / scale)) / 2, imageBackground.width / scale, imageBackground.height / scale, 0, 0, imageBackground.width, imageBackground.height);
			} else {
				var scale = imageBackground.width / canvasStream.width;
				canvasBackgroundContext.drawImage(canvasStream, (canvasStream.width - (imageBackground.width / scale)) / 2, (canvasStream.height - (imageBackground.height / scale)) / 2, imageBackground.width / scale, imageBackground.height / scale, 0, 0, imageBackground.width, imageBackground.height);
			}

			canvasBackground.toBlob((blob: Blob | null) => {
				if (!blob) {
					return;
				}

				const file = convertImageToFile(blob, "immagineDiProva");
				// this.AnnotationSentTickeEvent(file);
				const divCurrentModel = document.createElement('div');
				divCurrentModel.id = "current-model";
				ReactDOM.render(<ModalImage uri={URL.createObjectURL(blob)} />, divCurrentModel);
				this.addElementToModal(divCurrentModel);
				serverManager.files.upload([file], this.progressCallback, true).then(response => {
					this.imageCapturedMobileEvent(response);
				});
			})
			if (this.state.actualProduct)
				this.props.getFilesForProduct(this.state.actualProduct.id);

			this.setState({ modal3dWindowEnabled: false });
		}
	};

	private closeModal3dWindow = () => {
		if (this.state.actualProduct)
			this.props.getFilesForProduct(this.state.actualProduct.id);
		const element = document.getElementById("current-model");
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
		this.setState({ modal3dWindowEnabled: false, openRightMenu: false });
	};

	private get3dModalContainerClassNames = () => {
		const base = "active-call-page-3d-modal-container";
		const { modal3dWindowEnabled } = this.state;
		const hidden = modal3dWindowEnabled ? "" : "hidden";
		return classNames(base, hidden);
	}

	private handleImageCaptured = () => {
		if (!this.screenshotCanvas || !this.remoteVideo || !this.remoteVideo.current || !this.state.publisher) {
			throw new Error("Canvas or RemoteVideo missing.");
		}
		const canvas = this.screenshotCanvas;
		const canvas2dContext = canvas.getContext('2d');

		const { videoHeight, videoWidth } = this.remoteVideo.current;

		const height = videoHeight;
		const width = videoWidth;
		canvas.width = width;
		canvas.height = height;

		if (canvas2dContext) {
			canvas2dContext.drawImage(this.remoteVideo.current, 0, 0, width, height);

			canvas.toBlob((blob: Blob | null) => {
				if (!blob) {
					return;
				}
				const screenImage: HTMLImageElement = document.createElement('img');
				screenImage.src = URL.createObjectURL(blob);
				screenImage.id = "initial-screen-image";
				screenImage.style.cssText = 'width: auto; height: 100%;';
				// screenImage.width = 500;
				// screenImage.height = 400;

				this.openModal3dManager(screenImage);
				this.setState({ imageBackgroundUrl: URL.createObjectURL(blob), isToastAllowed: true });
				setInterval(() => this.setState({ isToastAllowed: false }), 3000);
			});
			// const screenImage: HTMLImageElement = document.createElement('img');
			// screenImage.src = 
			// screenImage.width = 500;

			// this.openModal3dManager(screenImage);
			// this.setState({ imageBackgroundUrl: canvas.toDataURL()});
		}
	};

	private openModal3dManager = (screenImage: HTMLElement) => {
		const modalWindow = document.getElementById("modal-3d-manager");
		if (modalWindow) {
			const oldElem = modalWindow.querySelector("#current-model");
			const initialScreenImage = document.getElementById('initial-screen-image');
			if (oldElem) {
				modalWindow.replaceChild(screenImage, oldElem);
			} else if (initialScreenImage) {
				modalWindow.replaceChild(screenImage, initialScreenImage);
			} else {
				modalWindow.appendChild(screenImage);
			}
		}

		const filteredFiles = this.state.filesArray.filter(file => file.name.split('.').pop() === 'fbx');
		this.setState({ modal3dWindowEnabled: true, filesArray: filteredFiles });
	};

	private handleSwitchProductButton = () => {
		this.setState({ isSelectedProduct: false });
	};

	private handleOnClickButton = async (product: IProduct) => {
		const { actualProduct } = this.state;
		console.log('[debug] productId: ' + product.id);
		var isDifferentProduct = actualProduct ? product.id !== actualProduct.id : false;
		this.setState({ actualProduct: product, isSelectedProduct: true, filesArray: [], isDifferentProduct: isDifferentProduct });
		this.props.getFilesForProduct(product.id);
		const newEvent: ITicketItem = {
			type: TicketItemType.ProductChanged,
			data: product.name
		};

		await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
	};

	private getRightMenuClassName = () => {
		const base = "active-call-page-right-menu";
		const { openRightMenu } = this.state;
		const hidden = openRightMenu ? "" : "hidden";
		return classNames(base, hidden);
	}

	private handleTouchEnd = (event: React.TouchEvent<HTMLCanvasElement>) => {
		xDown = null;
		yDown = null;
	}

	private handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
		console.log(xDown);
		if (!xDown || !yDown) {
			return;
		}
		xUp = event.touches[0].clientX;
		yUp = event.touches[0].clientY;
		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;
		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 0) {
				console.log("SWIPE SINISTRA");
				this.setState({ openRightMenu: true });
			} else {
				console.log("SWIPE DESTRA");
				this.setState({ openRightMenu: true });
			}
		}
	}

	private handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
		xDown = event.touches[0].clientX;
		yDown = event.touches[0].clientY;
	}

	private getModalContainerClassNames = () => {
		const base = "active-call-page-modal-container";
		const { modalWindowEnabled } = this.state;
		const hidden = modalWindowEnabled ? "" : "hidden";
		return classNames(base, hidden);
	};

	private closeModalWindow = () => {
		this.setState({ modalWindowEnabled: false, openRightMenu: false });
		const element = document.getElementById("current-model");
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
	};

	private changeDivClass(): string {
		const { participants, publisherType: userType, publisher } = this.state;
		if ((participants.length == 1 && userType === REMOTE_EXPERT) || (participants.length == 0 && userType !== REMOTE_EXPERT)) {
			return "wrapper-one-participant";
		} else if ((participants.length == 2 && userType === REMOTE_EXPERT) || (participants.length == 1 && userType !== REMOTE_EXPERT)) {
			return "wrapper-two-participants";
		} else if ((participants.length == 3 && userType === REMOTE_EXPERT) || (participants.length == 2 && userType !== REMOTE_EXPERT)) {
			return "wrapper-three-participants";
		} else if ((participants.length == 4 && userType === REMOTE_EXPERT) || (participants.length == 3 && userType !== REMOTE_EXPERT)) {
			return "wrapper-four-participants";
		} else {
			return "wrapper";
		}
	}

	private changeButtonIcon(): string {
		const { firstVisualization, secondVisualization, thirdVisualization } = this.state;
		if (firstVisualization && !secondVisualization && !thirdVisualization) {
			return "view_stream";
		} else if (secondVisualization && !firstVisualization && !thirdVisualization) {
			return "view_module";
		} else if (thirdVisualization && !secondVisualization && !firstVisualization) {
			return "view_sidebar";
		} else {
			return "view_sidebar";
		}
	}

	private changeSecondStreamManager(participant: Subscriber): void {
		this.setState({ secondStreamManager: participant as StreamManager })
	}

	private getVideoContainerClassName(): string | undefined {
		const { secondVisualization } = this.state;
		var className: string = "active-call-page-remotevideo-element";
		if (secondVisualization)
			className = "active-call-page-remotevideo-element-second-vis";
		return className;
	}

	private changeVisualization = () => {
		const { firstVisualization, secondVisualization, thirdVisualization, participants, publisherType: userType, publisher } = this.state;
		if (firstVisualization)
			this.setState({
				firstVisualization: false,
				secondVisualization: true,
			})
		else if (secondVisualization && ((participants.length < 7 && userType === REMOTE_EXPERT) || (participants.length < 6 && userType !== REMOTE_EXPERT)))
			this.setState({
				secondVisualization: false,
				thirdVisualization: true,
			});
		else if (thirdVisualization)
			this.setState({
				thirdVisualization: false,
				firstVisualization: true,
			});
	}

	private changeMainStreamManager = (subscriber: Subscriber, index?: number) => {
		const { mainStreamManager } = this.state;

		if (mainStreamManager !== this.getOperatorViewStreamManager()) {
			this.setState({ mainStreamManager: this.getOperatorViewStreamManager() as StreamManager })
		}

		var temp = mainStreamManager;
		this.setState({ mainStreamManager: subscriber as StreamManager, mainStreamParticipant: subscriber });
		if (index !== undefined) {
			this.state.participants.splice(index, 0, temp as Subscriber);
			const participants = this.state.participants.filter(part => part !== subscriber);
			this.setState({ participants })
		} else {
			this.setState({ remoteExpert: temp as Subscriber })
		}
	};

	public getNumberOfPublishers = () => {
		const remoteExpertCount = this.getRemoteExpertStreamManager()
		let count = 0;
		if (remoteExpertCount !== null) { count++ };
		if (this.state.mainStreamManager !== undefined) { count++; }
		if (this.props.userType !== REMOTE_EXPERT) { count++; }
		count = count + this.state.participants.length;
		return count;
	};

	public addHololensStream = (subscriber: Subscriber) => {

		console.log("SETTING HOLOLENS STREAM: " + subscriber);

		this.setState({
			mainStreamManager: subscriber,
			// participants: [...this.state.participants, subscriber],
		});
	};

	public addParticipantStream = async (subscriber: Subscriber) => {
		if (this.state.switchActionParticipantIndex !== undefined) {
			const participants = this.state.participants;
			participants.splice(this.state.switchActionParticipantIndex, 0, subscriber)
			this.setState({
				participants: participants,
				switchActionParticipantIndex: undefined
			});
			// } else if (this.state.switchActionParticipantIndex !== undefined){
			// 	this.setState({
			// 		mainStreamManager: subscriber,
			// 	});
		} else {
			this.setState({
				participants: [...this.state.participants, subscriber],
				secondStreamManager: subscriber,
			});
		}

		const newEvent: ITicketItem = {
			type: TicketItemType.ParticipantJoin,
			data: this.props.participantUsername as string
		};

		await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
	};

	public addRemoteExpertStream = (subscriber: Subscriber) => {
		this.setState({
			remoteExpert: subscriber,
			secondStreamManager: subscriber as StreamManager,
			// participants: [...this.state.participants, subscriber],
		});
	};

	public removeHololensStream = (stream: Stream) => {
		// const participants = this.state.participants.filter(participant => participant.stream !== stream);
		// this.setState({ mainStreamManager: undefined, participants });
		this.setState({ mainStreamManager: undefined });

		this.handleEndCall();
	};

	public removeParticipantStream = (stream: Stream) => {
		const { publisherType: userType, publisher, mainStreamParticipant } = this.state;
		const participants = this.state.participants.filter(participant => participant.stream !== stream);
		this.setState({ participants });
		this.connectedParticipants.filter(participant => participant.stream !== stream);
		if (this.state.participants.length == 0 && userType === REMOTE_EXPERT) {
			this.setState({
				firstVisualization: true,
				secondVisualization: false,
				thirdVisualization: false,
			});
		}
		if (this.state.participants.length > 0) {
			this.setState({ secondStreamManager: this.state.participants[this.state.participants.length - 1] })
		}
	};

	public removeRemoteExpertStream = (stream: Stream) => {
		// const participants = this.state.participants.filter(participant => participant.stream !== stream);
		// this.setState({ remoteExpert: undefined, participants });
		this.setState({
			remoteExpert: undefined,
		});
	};

	private creatSession = async () => {
		if (!this.props.operatorName || !this.props.sessionId || !this.props.activeCallProductId) {
			return;
		}
		const session: IQueueSession = {
			productName: this.props.product.name,
			operatorName: this.props.operatorName,
			expertName: this.props.userInfo.userName,
			sessionId: this.props.sessionId,
			productId: this.props.activeCallProductId,
		};
		console.log(this.props.activeCallProductId);

		await serverManager.queue.enqueueSession(session);
		logger.info("Session Created");
	};

	private expertReady = () => {

		if (this.state.myInfo) {

			const callId = Number(this.props.activeCallId);
			const message = new SignalMessage(this.state.myInfo.id, callId, "ExpertReady");
			this.signalClient.relayMessage(message);
			this.signalClient.stopListen();
		}
	};

	private operatorReady = () => {

	
		if (this.state.myInfo) {

			//const callId = Number(this.props.activeCallId);
			const operatorId = Number(this.props.peerId);
			const message = new SignalMessage(operatorId, this.state.myInfo.id, "OperatorReady");
			this.signalClient.relayMessage(message);
			this.signalClient.stopListen();
		}
	};

	private getOperatorViewStreamManager = () => {
		const { mainStreamManager, publisherType: userType, publisher } = this.state;

		if (mainStreamManager) {
			return mainStreamManager;
		}

		if (publisher && userType === OPERATOR) {
			return publisher;
		}

		return null;
	};

	private getRemoteExpertStreamManager = () => {
		const { remoteExpert, publisherType: userType, publisher } = this.state;

		if (!remoteExpert && userType !== REMOTE_EXPERT) {
			return null;
		}

		if (remoteExpert) {
			return remoteExpert;
		}

		return publisher;
	};

	private getParticipantStreamManager = (participant?: Subscriber) => {
		const { publisherType: userType, publisher } = this.state;

		if (!participant && userType !== REMOTE_PARTICIPANT) {
			return null;
		}

		if (participant) {
			return participant;
		}

		return publisher;
	};

	private async createTicket() {
		const { activeCallTicketId } = this.props;
		const eventsHoloLens = parsingJSON(this.props.holoLensEvents!);

		if (activeCallTicketId) {
			for (const ev of eventsHoloLens) {
				await serverManager.tickets.postTicketEvent(activeCallTicketId, ev);
			}

			this.setState({ ticketId: activeCallTicketId });
			this.answerCallTicketEvent();
			return;
		}

		
		const data: ITicketCreate = {
			operatorId: this.props.operatorId!,
			ticketEvents: eventsHoloLens,
		};

		serverManager.tickets
			.postTicket(this.props.activeCallProductId!, data)
			.then(response => {
				logger.warn("Ticket ID:", response);
				this.setState({ ticketId: response.data.id });
				this.answerCallTicketEvent();
			})
			.catch(error => {
				logger.error("Error", error);
			});
	}

	private errorMessage = (error: any) => {
		logger.error("postTicketEvent faild with: ", error);
		console.log("postTicketEvent faild with: " + error);
		this.props.addToast({ type: ToastType.Error, msgId: "ActiveCall.FailedToAddCallLog" });
	};

	private answerCallTicketEvent = async () => {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.AnswerCall,
			};
			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			logger.info(response);
		} catch (error) {
			this.errorMessage(error);
		}
	};

	private endedCallTicketEvent = async () => {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.EndedCall,
			};
			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			logger.info(response);
		} catch (error) {
			this.errorMessage(error);
		}

		this.props.history.push(`/ticket/${this.state.ticketId}`);
	};

	//TODO: Add ticket event for new events

	private imageCapturedTicketEvent = async (file: IFile[]) => {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.ImageCaptured,
				url: file[0].id,
			};
			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ msgId: "ActiveCall.ImageCaptured", type: ToastType.Success });
			logger.info(response);
			this.setState({ imageUploaded: true });
			if (this.state.isCallEnded) {
				this.state.session.disconnect();
				await this.endedCallTicketEvent();
				this.props.endCall();
			}
		} catch (error) {
			this.errorMessage(error);
		}
	};

	// Function for notifying mobile operator of screenCapture uploaded
	private imageCapturedMobileEvent = async (file: IFile[]) => {
		if (this.state.publisher) {
			const data = Messaging.createMobileSnapshotMessage(file[0].id);
			const type = Messaging.Massage.MobileSnapshot;
			logger.debug("[ACTIVECALL]: Sent mobileImageCaptureMessage");

			this.state.publisher.session.signal({ data, type });
		}
	}

	/*private fileSentTicketEvent = async (file: IFile) => {

		await delay(1000);

		await this.setState(state => {
			logger.debug(state);
			const files = state.fileSent.filter(item => item.id !== file.id);
			logger.debug(files);
			return { fileSent: files, sendingFile: false };
		});

		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.FileSent,
				url: file.id,
			};
			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			logger.info(response);

			//this.props.addToast({ msgId: "ActiveCall.FileSent", type: ToastType.Success });
		} catch (error) {
			this.errorMessage(error);
		}
	};*/
	private fileSentTicketEvent = async (file: IFile) => {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.FileSent,
				url: file.id,
				data: this.props.userInfo.userName,
			};

			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			logger.info(response);
			// this.props.addToast({ msgId: "ActiveCall.FileSent", type: ToastType.Success });
		} catch (error) {
			// this.setState({ sendingFile: false });
			this.errorMessage(error);
		}
	};

	private fileSentTicketEventNotification = async (file: IFile) => {
		this.props.addToast({ msgId: "ActiveCall.FileSent", type: ToastType.Success });
	};

	private async videoCaptureTicketEvent(videoUploadResponse: IFile[]) {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.VideoCapturedEnded,
				url: videoUploadResponse[0].id,
			};
			const response = await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ type: ToastType.Info, msgId: "ActiveCall.VideoCaptureAdded" });
			return response;
		} catch (error) {
			this.errorMessage(error);
			return null;
		}
	}

	private async videoCapturedStartedTicketEvent() {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.VideoCapturedStarted,
			};
			await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ type: ToastType.Info, msgId: "ActiveCall.VideoCaptureStart" });
		} catch (error) {
			this.errorMessage(error);
		}

		this.setState({ isStarting: false });
	}

	private videoCapturedEnded = async (file: File, recordedVideo: string) => {
		try {
			this.setState({ videoRecorded: file });
			const videoUploadResponse = await serverManager.files.upload([file], this.progressCallback, true);
			const response = await this.videoCaptureTicketEvent(videoUploadResponse);
			logger.info(response);
			this.props.addToast({ type: ToastType.Info, msgId: "ActiveCall.VideoUploaded" });
			await this.signalClient.deleteRecording(recordedVideo);//await serverManager.users.deleteRecoding(recordedVideo);

			if (this.state.recording) {
				this.startRecording(this.state.recordingTime);
			} else {
				this.setState({ videoUploaded: true, isStopping: false });
			}

			if (this.state.isCallEnded) {
				this.state.session.disconnect();
				await this.endedCallTicketEvent();
				this.props.endCall();
				window.location.reload()
			}
		} catch (error) {
			this.errorMessage(error);
		}
	};

	private handleEndCall = async () => {
		const { videoUploaded, imageUploaded, annotationUploaded } = this.state;
		if (!this.state.session) {
			throw new Error("No Session to end.");
		}

		if (this.props.userType === REMOTE_PARTICIPANT) {
			this.props.leaveSession({ session: this.state.session, OV: this.OV });
			this.state.session.disconnect();
			this.props.history.push(`/`);
			this.props.endCall();
			return;
		}

		if (this.props.userType === REMOTE_EXPERT) {
			if (!videoUploaded) {
				this.stopRecording();
				//this.props.company.allowMinuteRate === "true" ? this.props.updateUsedCallTime(this.props.company.id as string, this.state.currentTime) : undefined;

				//this.props.leaveSession({ session: this.state.session, OV: this.OV });
				this.state.session.disconnect();
				this.setState({ isCallEnded: true, recording: false });

			} else if (!imageUploaded || !annotationUploaded) {
				//this.props.company.allowMinuteRate === "true" ? this.props.updateUsedCallTime(this.props.company.id as string, this.state.currentTime) : undefined;
				// this.props.leaveSession({ session: this.state.session, OV: this.OV });
				this.state.session.disconnect();
				this.setState({ isCallEnded: true });
			} else {
				//this.props.company.allowMinuteRate === "true" ? this.props.updateUsedCallTime(this.props.company.id as string, this.state.currentTime) : undefined;
				// this.props.leaveSession({ session: this.state.session, OV: this.OV });
				await this.endedCallTicketEvent();
				this.state.session.disconnect()
				this.props.endCall();
			}
		}
		await serverManager.queue.dequeueSession(this.state.session.sessionId);
	};

	private handleOnFileDropped = () => {
		// this.setState({ sendingFile: true });
	};

	private handleOnFileRejected = () => {
		this.setState({ sendingFile: false });
	};

	private handleOnFileUploaded = (file: IFile) => {
		this.props.activeCallFileUploadSuccess(file);
		this.openFileInModalView(file);

		console.log("[debug_log] usertype" + this.props.userType);
		if (this.props.userType === REMOTE_EXPERT) {
			this.setState({ sendingFile: true });
			this.fileSentTicketEvent(file);
		}

		log("Sending file to peer", file.name);
		if (!this.state.publisher) {
			return logger.error("No Publisher for sending file.");
		}

		const data = Messaging.createFileMessage(file.id);
		const type = Messaging.Massage.File;
		logger.debug("[ACTIVECALL]: Sent screenshotMessage");

		this.state.publisher.session.signal({ data, type });


		this.props.addToast({ type: ToastType.Success, msgId: "ActiveCall.FileUploadedAndSent" });
	};

	private closeAnnotation = () => {
		if (!this.state.publisher) {
			return;
		}

		this.setState({ annotationMode: false });
	}

	private completeAnnotation = async (item: Blob) => {
		const file = convertImageToFile(item, this.props.product.name);
		// console.log(`[debug_exp] item: ${item} product: ${this.props.product.name}`);
		const mainDiv = document.createElement('div');
		mainDiv.id = "current-model";
		ReactDOM.render(<ModalImage uri={`${URL.createObjectURL(item)}`} />, mainDiv);
		this.addElementToModal(mainDiv);

		var convertedFile = await this.AnnotationSentTickeEvent(file);

		if (convertedFile !== undefined) {
			this.imageCapturedMobileEvent(convertedFile);
			this.setState({ annotationUploaded: true });
		}
		logger.debug("[ACTIVECALL]: Exiting annotationmode.");
	};

	private AnnotationSentTickeEvent = async (file: File) => {
		try {
			this.setState({ annotationUploaded: false });
			const response = await serverManager.files.upload([file], this.progressCallback, true);
			console.log(`[foo] response: ${response[0].id}`);

			if (this.props.userType === REMOTE_EXPERT) {
				this.setState({ annotationMode: true });
				const newEvent: ITicketItem = {
					type: TicketItemType.AnnotationSent,
					url: response[0].id,
					data: this.props.userInfo.userName
				};
				await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			}

			this.props.addToast({ type: ToastType.Info, msgId: "ActiveCall.AnnotationUploaded" });

			if (this.state.isCallEnded) {
				this.state.session.disconnect();
				await this.endedCallTicketEvent();
				this.props.endCall();
			}

			return response;

		} catch (error) {
			this.errorMessage(error);
			return [];
		}
	};

	private modelPositioningTicketEvent = async (file: File) => {
		try {
			const response = await serverManager.files.upload([file], this.progressCallback, true);
			const newEvent: ITicketItem = {
				type: TicketItemType.ModelPosition,
				url: response[0].id,
				linkedObject: response[0],
			};
			await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ type: ToastType.Info, msgId: 'ActiveCall.ModelPositioningToast' });
			this.imageCapturedMobileEvent(response);
		} catch (error) {
			this.errorMessage(error);
		}
	};

	private modelOpenTicketEvent = async (file: IFile) => {
		try {
			const newEvent: ITicketItem = {
				type: TicketItemType.ModelOpen,
				data: file.name
			};
			await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ type: ToastType.Info, msgId: 'ActiveCall.ModelOpenToast' });
		} catch (error) {
			this.errorMessage(error);
		}
	};

	private openCallTicketEvent = async (ticketItemType: TicketItemType) => {
		try {
			const newEvent: ITicketItem = {
				type: ticketItemType
			};
			await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
			this.props.addToast({ type: ToastType.Info, msgId: ticketItemType === TicketItemType.CallPublished ? "ActiveCall.OpenToGeneric" : "ActiveCall.OpenToTeam" });
		} catch (error) {
			this.errorMessage(error);
		}
	}

	private onText = (e: IDrawEvent) => {
		if (!this.state.publisher)
			return;
		const data = Messaging.createTextDrawMessage(e.x, e.y, e.color, e.size, e.text as string);
		const type = Messaging.Massage.Text;
		this.state.publisher.session.signal({ data, type });
	};

	private renderAnnotations = () => {
		return (
			<Annotations
				canvasRef={this.setScreenshotCanvasRef}
				isActive={this.state.annotationMode}
				onAnnotationComplete={this.completeAnnotation}
				onAnnotationClose={this.closeAnnotation}
				snapshotResolution={this.getSnapshotResolution()}
				remoteVideoRef={this.remoteVideo}
				onContinueDrawing={this.onDraw}
				onEndDrawing={this.onDrawEnd}
				onTextDrawing={this.onText}
				onUndoDrawing={this.onDrawUndo}
				onArrow={this.onArrow}
				onCrosshair={this.onCrosshair}
				onStartDrawing={this.onDrawStart}
			/>
		);
	};

	private onArrow = (e: IDrawEvent) => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onStamp");
		const data = Messaging.createArrowMessage(e.x, e.y, e.color, e.size);
		const type = Messaging.Massage.Crosshair;
		this.state.publisher.session.signal({ data, type });
	};

	private onCrosshair = (e: IDrawEvent) => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onStamp");
		const data = Messaging.createCrosshairMessage(e.x, e.y, e.color, e.size);
		const type = Messaging.Massage.Crosshair;
		this.state.publisher.session.signal({ data, type });
	};

	private onDrawStart = (e: IDrawEvent) => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onDrawStart");
		const data = Messaging.createStartDrawMessage(e.x, e.y, e.color, e.size); // Message with position, color and size of the drawn message
		const type = Messaging.Massage.StartDraw;
		this.state.publisher.session.signal({ data, type });
	};

	private onDraw = (e: IDrawEvent) => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onDraw");
		const data = Messaging.createContinueDrawMessage(e.x, e.y);
		const type = Messaging.Massage.ContinueDraw;
		this.state.publisher.session.signal({ data, type });
	};

	private onDrawEnd = (e: IDrawEvent) => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onDrawEnd");
		const data = Messaging.createEndDrawMessage(e.x, e.y);
		const type = Messaging.Massage.ContinueDraw;
		this.state.publisher.session.signal({ data, type });
	};

	private onDrawUndo = () => {
		if (!this.state.publisher) {
			return;
		}

		logger.debug("onDrawUndo");
		const data = Messaging.createUndoMessage();
		const type = Messaging.Massage.Undo;
		this.state.publisher.session.signal({ data, type });
	}

	private setScreenshotCanvasRef = (el: HTMLCanvasElement) => {
		this.screenshotCanvas = el;
	};

	private imageCapture = (): void => {
		if (!this.screenshotCanvas || !this.remoteVideo || !this.remoteVideo.current || !this.state.publisher) {
			throw new Error("Canvas or RemoteVideo missing.");
		}
		const canvas = this.screenshotCanvas;
		const ctx = canvas.getContext("2d");

		const { videoHeight, videoWidth } = this.remoteVideo.current;

		const height = videoHeight;
		const width = videoWidth;
		this.screenshotCanvas.height = height;
		this.screenshotCanvas.width = width;

		logger.warn("VideoHeight", videoHeight);
		logger.warn("VideoWidth", videoWidth);

		if (!ctx) {
			throw new Error("Something went wrong while getting the context.");
		}

		if (!this.screenshotCanvas) {
			return;
		}

		ctx.fillRect(0, 0, width, height);
		ctx.drawImage(this.remoteVideo.current, 0, 0, width, height);

		this.setState({ imageUploaded: false });
		this.screenshotCanvas.toBlob((blob: Blob | null) => {
			if (!blob) {
				return;
			}
			const file = convertImageToFile(blob, this.props.product.name);
			serverManager.files.upload([file], this.progressCallback, true).then(response => {
				this.imageCapturedTicketEvent(response);
			});
			logger.debug("[ACTIVECALL]: Image Captured.");
		});
	};

	private takeScreenshot = async () => {
		this.exitFullscreen();

		//workaround for making annotations as soon as clicked ?
		this.setState({ offsetTop: 0 });

		if (!this.screenshotCanvas || !this.remoteVideo.current || !this.remoteVideoContainer.current || !this.state.publisher) {
			console.log(`[debug] screenshotCanvas: ${this.screenshotCanvas} remoteVideo.current: ${this.remoteVideo.current} remoteVideoContainer.current: ${this.remoteVideoContainer.current} state.publisher: ${this.state.publisher}`)
			return;
		}


		console.log('im here');
		logger.debug("[ACTIVECALL]: Taking screenshot");
		const data = JSON.stringify(Messaging.createSnapshotMessage());
		const type = "onTakeScreenshot";
		logger.debug("[ACTIVECALL]: Sent screenshotMessage");
		this.state.publisher.session.signal({ data, type });

		const ctx = this.screenshotCanvas.getContext("2d");
		this.ctx = ctx;

		const { videoHeight, videoWidth } = this.remoteVideo.current;
		const { width, height } = this.remoteVideoContainer.current.getBoundingClientRect();
		this.screenshotCanvas.width = width;
		this.screenshotCanvas.height = height;

		const canvasAr = width / height;
		const videoAr = videoWidth / videoHeight;

		const offsetTop = videoAr < canvasAr ? 0 : (height - width / videoAr) / 2;
		const offsetLeft = videoAr > canvasAr ? 0 : (width - height * videoAr) / 2;

		const drawHeight = height - offsetTop * 2;
		const drawWidth = width - offsetLeft * 2;

		if (!ctx || !this.ctx) {
			throw new Error("Something went wrong while getting the context.");
		}

		if (!this.screenshotCanvas) {
			return;
		}

		this.setState({ offsetTop: offsetTop, offsetLeft: offsetLeft });

		this.ctx.fillRect(0, 0, width, height);
		this.ctx.drawImage(this.remoteVideo.current, offsetLeft, offsetTop, drawWidth, drawHeight);

		await this.setState({ annotationMode: true });
	};

	private renderReturnToCallOverlay = () => (
		<div onClick={this.props.toggleMinimizeCallWindow} className="active-call-returntocall">
			<Icon name="settings_overscan" />
		</div>
	);

	private getPageContainerClassNames = () => {
		const { isCallWindowMinimized } = this.props;
		const { annotationMode } = this.state;
		const annotation = annotationMode ? "annotation-mode" : "";
		const base = "active-call-page-container" + " " + annotation;
		if (isCallWindowMinimized) {
			return classNames(base, "minimized");
		}

		return base;
	};

	private handleRecordingClick = () => {
		if (!this.state.isStopping) {
			if (this.state.recording) {
				this.setState({ recording: false }, this.stopRecording);
				//serverManager.users.stopRecording();
			} else {
				this.startRecording(this.state.recordingTime);
				//serverManager.users.startRecording(this.state.session.sessionId);
			}
		} else {
			this.props.addToast({ msgId: "ActiveCall.WaitForLoading", type: ToastType.Info })
		}

	};

	private startRecording = async (timeout: number) => {
		console.log(`[Recording] try start recording remoteVideo.current: ${this.remoteVideo.current} MediaRecorder:${MediaRecorder} sessionID:${this.props.sessionId}`);

		if (/*this.remoteVideo.current &&*/ MediaRecorder !== undefined && this.props.sessionId) {
			// const stream = this.getCaptureStream();
			// var options = {mimeType: 'video/webm;codecs=h264'};
			// this.recorder = new MediaRecorder(stream, options);
			// this.recorder.ondataavailable = (event: any) => {
			// 	chunks.push(event.data);
			// };

			// this.recorder.addEventListener('stop', () => {
			// 	const blob = new Blob(chunks, {type: 'video/mp4;codecs=h264'});
			// 	chunks = [];
			// 	const file = convertBlobToFile(blob, this.props.product.name);

			// 	const previousVideos = this.state.recordedVideos;
			// 	const recordedVideos = [...previousVideos, file];

			// 	this.setState({ recordedVideos });
			// 	this.videoCapturedEnded(file);
			// });

			// this.recorder.start(10);

			this.setState(() => { return { isStarting: true, recording: true, videoUploaded: false } });

			const recording: IRecording = await this.signalClient.startRecording(this.props.sessionId);//await serverManager.users.startRecording(this.props.sessionId);
			console.log("[Recording] IRecording costruito");

			console.log("[Recording] " + JSON.stringify(recording));
			setTimeout(() => {
				if (this.state.recording) {
					this.stopRecording();
				}
			}, timeout);

			recordingVideos.push(JSON.parse(JSON.stringify(recording)));
			// setInterval(() => { console.log("[DEBUG] " + recordingVideos.length) }, 20);
			//this.setState({ recordingVideo: [recordingId.data] });
			this.videoCapturedStartedTicketEvent();
		}
	};

	private stopRecording = async () => {
		// if (!this.recorder) {
		// 	log("No recorder to stop.");
		// }

		log("Stop Recording.");
		this.setState({ isStopping: true });
		var recordedVideo = recordingVideos.pop()!;
		var recording = await this.signalClient.stopRecording(recordedVideo.id);//await serverManager.users.stopRecording(recordedVideo.id);
		let base64 = require('base-64');
		let username = 'OPENVIDUAPP';
		let password = await this.signalClient.getMediaServerSecret();
		let headers = new Headers();
		//headers.append('Content-Type', 'text/json');
		headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));
		fetch(recording.url, { method: 'GET', headers: headers }).then(res => res.blob().then(blob => {
			const file = convertBlobToFile(blob, this.props.product.name);
			this.videoCapturedEnded(file, recordedVideo.id);
		})
		);

		// this.recorder.stop();
	};

	private getCaptureStream = () => {
		if (!this.state.mainStreamManager) {
			return;
		}
		return this.state.mainStreamManager.stream.getMediaStream();
	};

	// private addToMediaStore = (blob: Blob) => {
	// 	const file = convertBlobToFile(blob, this.props.product.name);

	// 	const previousVideos = this.state.recordedVideos;
	// 	const recordedVideos = [...previousVideos, file];

	// 	this.setState({ recordedVideos });
	// 	this.videoCapturedEnded(file);
	// };

	private progressCallback = (progress: IProgress) => {
		console.log(`PROGRESSSSS: ${progress.uploaded}`);
		this.setState({ uploadValue: progress.uploaded });
		this.forceUpdate();
		//this.setState({ progressMap: { ...this.state.progressMap, [progress.file.name]: progress.uploaded }, uploadValue: progress.uploaded });
	};

	private renderFullscreenProductInfo = () => {
		const { fullscreen, fullscreenProductInfo, sendingFile, filesArray } = this.state;
		const { userType } = this.props;
		if (fullscreen && fullscreenProductInfo) {
			return (
				<div className="fullscreen-info">
					<ProductInfo
						fullscreen={fullscreen}
						onCloseClick={this.toggleFullscreenProductInfo}
						loading={this.props.productLoading}
						error={this.props.productError}
						product={this.props.product}
						userAgent={this.props.operatorUserAgent ? this.props.operatorUserAgent : ""}
					/>
					{userType === REMOTE_EXPERT &&
						(!sendingFile ? (
							<ProductFileList fullscreen={fullscreen} files={filesArray} onFileClicked={this.handleOnFileUploaded} />
						) : (
							<div className="loading-container">
								<Spinner />
								<h2>
									<T v="ActiveCall.SendingFile" />
								</h2>
							</div>
						))}
					{this.props.userType === REMOTE_EXPERT && (
						<UploadButton
							className="active-call-upload-file"
							validFileTypes={["image/jpeg", "image/png", "application/pdf"]}
							validFileExtensions={["jpg", "mp4"]}
							uploader={serverManager.files.upload}
							onFileUploaded={this.handleOnFileUploaded}
						/>
					)}
				</div>
			);
		}

		return null;
	};

	private openToSelectedUser = () => {
		if (this.props.sessionId !== null) {
			serverManager.queue.openToUser(this.props.sessionId.toString(), this.state.selectedUser);
			this.openCallTicketEvent(TicketItemType.CallPublished);
			this.setState({ openCustomAlert: false, selectedUser: "" });
		}
	};

	private openToGenericClick = () => {
		console.log("[debug] sessionId: " + this.props.sessionId);
		if (this.props.sessionId !== null) {
			serverManager.queue.openToGeneric(this.props.sessionId.toString(), true); //Set boolean value to false to close it

			this.openCallTicketEvent(TicketItemType.CallPublished);
			this.setState({ enableGenericExpertInCompany: false, openCustomAlert: false });
		}
	};

	private openToTeamClick = () => {
		console.log("[debug] sessionId: " + this.props.sessionId);
		if (this.props.sessionId !== null) {
			serverManager.queue.openToTeam(this.props.sessionId.toString(), true); //Set boolean value to false to close it
			this.openCallTicketEvent(TicketItemType.CallPublishedTeam);
			this.setState({ enableGenericExpertInTeam: false, openCustomAlert: false });
		}
	};

	private toggleFullscreenProductInfo = () => {
		this.setState({ fullscreenProductInfo: !this.state.fullscreenProductInfo });
	};

	private openFileInModalView = async (file: IFile) => {
		const { modal3dWindowEnabled, imageBackgroundUrl } = this.state;
		if (modal3dWindowEnabled && imageBackgroundUrl !== '') {
			const fileTy = file.name.split(".").pop();
			if (fileTy && fileTy.toLowerCase() === "fbx") {
				this.props.getMyCompany();
				this.props.getClipsData(file.id, (clipsData: IClips | null) => {
					if (clipsData == null) {
						clipsData = {
							id: file.id,
							clipsData: [],
						}
					}
					const divCurrentModel = document.createElement('div');
					divCurrentModel.id = "current-model";

					ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`}
						clips={clipsData}
						allowArFunctions={false}
						activeCall={true}
						positioningModel={true} />, divCurrentModel);
					//model = handleFbxModel(file.realFileUri, clipsData, "false", true, true);
					var desiredHeight: number = 400;
					let desiredWidth: number = 500;//(model.clientHeight / model.clientWidth) / desiredHeight;
					divCurrentModel.style.cssText = `width: auto; height: 100%; background-image: url(${this.state.imageBackgroundUrl}); background-repeat: no-repeat; background-size: auto 100%; background-position: center;`
					//var clonedModel = model.cloneNode(true);
					this.openModal3dManager(divCurrentModel);
					const filteredFiles = this.state.filesArray.filter(file => file.name.split('.').pop() === 'fbx');
					this.setState({ filesArray: filteredFiles });
				});
			}
		}
		else {
			const fileType = file.name.split(".").pop();
			var model: any;

			if (fileType && (fileType.toLowerCase() === "glb" || fileType.toLowerCase() === "gltf")) {
				model = handle3dModel(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`);
				//var clonedModel = model.cloneNode(true);
				this.addElementToModal(model);
			} else if (fileType && fileType.toLowerCase() === "fbx") {
				this.props.getMyCompany();
				this.props.getClipsData(file.id, (clipsData: IClips | null) => {
					if (clipsData == null) {
						clipsData = {
							id: file.id,
							clipsData: [],
						}
					}
					const divCurrentModel = document.createElement('div');
					divCurrentModel.id = "current-model";

					ReactDOM.render(<FbxHandler
						uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`}
						clips={clipsData}
						allowArFunctions={false}
						activeCall={true}
						positioningModel={false} />, divCurrentModel);
					//model = handleFbxModel(file.realFileUri, clipsData, "true", true);
					var desiredHeight: number = 400;
					// let desiredWidth: number = (model.clientHeight / model.clientWidth) / desiredHeight;
					divCurrentModel.style.cssText = `width: ${400}px; min-height: ${desiredHeight}px;`
					this.addElementToModal(divCurrentModel);
				});
			} else if (fileType && fileType.toLowerCase() === "pdf") {
				const divCurrentModel = document.createElement('div');
				divCurrentModel.id = "current-model";
				divCurrentModel.style.cssText = " margin: 0px; overflow-y: auto; width: auto; height: 550px; background: #232323; z-index: 9999;";
				ReactDOM.render(<PdfHandler file={file} />, divCurrentModel);
				this.addElementToModal(divCurrentModel);
			}
			else if (fileType && (fileType.toLowerCase() === "png" || fileType.toLowerCase() === "jpg")) {
				const mainDiv = document.createElement('div');
				mainDiv.id = "current-model";
				ReactDOM.render(<ModalImage uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} />, mainDiv);
				model = mainDiv; //handleImageFile(file.realFileUri, undefined, true);
				this.addElementToModal(model);
			}
			else if (fileType && fileType.toLowerCase() === "mp4") {
				handleVideoFile(file, true).then(divModel => {
					//var clonedModel = divModel.cloneNode(true);	

					if (this.anchorRef.current) {
						divModel.appendChild(this.anchorRef.current);
						this.addElementToModal(divModel);
					}
				});
			}
			else {
				window.open(file.realFileUri, "_blank");
			}
		}
	};



	// private handleFileClick = async (file: IFile, isSending: boolean = true) => {
	// 	const { modal3dWindowEnabled, imageBackgroundUrl } = this.state;

	// 	if (modal3dWindowEnabled && imageBackgroundUrl !== '') {
	// 		const fileTy = file.name.split(".").pop();
	// 		if (fileTy && fileTy.toLowerCase() === "fbx") {
	// 			this.props.getMyCompany();
	// 			this.props.getClipsData(file.id, (clipsData: IClips | null) => {
	// 				if (clipsData == null) {
	// 					clipsData = {
	// 						id: file.id,
	// 						clipsData: [],
	// 					}
	// 				}
	// 				const divCurrentModel = document.createElement('div');
	// 				divCurrentModel.id = "current-model";

	// 				ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} clips={clipsData} allowArFunctions={false} activeCall={true} positioningModel={true} />, divCurrentModel);
	// 				//model = handleFbxModel(file.realFileUri, clipsData, "false", true, true);
	// 				var desiredHeight: number = 400;
	// 				let desiredWidth: number = 500;//(model.clientHeight / model.clientWidth) / desiredHeight;
	// 				divCurrentModel.style.cssText = `width: auto; height: 100%; background-image: url(${this.state.imageBackgroundUrl}); background-repeat: no-repeat; background-size: auto 100%; background-position: center;`
	// 				//var clonedModel = model.cloneNode(true);
	// 				this.openModal3dManager(divCurrentModel);
	// 				this.modelOpenTicketEvent(file);
	// 				const filteredFiles = this.state.filesArray.filter(file => file.name.split('.').pop() === 'fbx');
	// 				this.setState({ filesArray: filteredFiles });
	// 			});
	// 		}
	// 	} else {
	// 		this.setState({ sendingFile: isSending });
	// 		if (!this.state.publisher) {
	// 			return logger.error("No Publisher for sending file.");
	// 		}

	// 		log("Sending file to peer", file.name);

	// 		const data = Messaging.createFileMessage(file.id);
	// 		const type = Messaging.Massage.File;
	// 		logger.debug("[ACTIVECALL]: Sent screenshotMessage");

	// 		await this.setState(state => ({
	// 			fileSent: [...state.fileSent, file],
	// 		}));

	// 		if (isSending) {
	// 			this.state.publisher.session.signal({ data, type });
	// 		}

	// 		const fileType = file.name.split(".").pop();
	// 		var model: any;
	// 		if (fileType && (fileType.toLowerCase() === "glb" || fileType.toLowerCase() === "gltf")) {
	// 			model = handle3dModel(`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`);
	// 			//var clonedModel = model.cloneNode(true);
	// 			this.addElementToModal(model);
	// 		} else if (fileType && fileType.toLowerCase() === "fbx") {
	// 			this.props.getMyCompany();
	// 			this.props.getClipsData(file.id, (clipsData: IClips | null) => {
	// 				if (clipsData == null) {
	// 					clipsData = {
	// 						id: file.id,
	// 						clipsData: [],
	// 					}
	// 				}
	// 				const divCurrentModel = document.createElement('div');
	// 				divCurrentModel.id = "current-model";

	// 				ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} clips={clipsData} allowArFunctions={false} activeCall={true} positioningModel={false} />, divCurrentModel);
	// 				//model = handleFbxModel(file.realFileUri, clipsData, "true", true);
	// 				var desiredHeight: number = 400;
	// 				// let desiredWidth: number = (model.clientHeight / model.clientWidth) / desiredHeight;
	// 				divCurrentModel.style.cssText = `width: ${400}px; height: ${desiredHeight}px;`

	// 				if (isSending) {
	// 					if (this.props.userType !== REMOTE_PARTICIPANT) {
	// 						this.modelOpenTicketEvent(file);
	// 					}
	// 					else {
	// 						this.fileSentByPartecipantTicketEvent(file);
	// 					}
	// 				}
	// 				//var clonedModel = model.cloneNode(true);
	// 				this.addElementToModal(divCurrentModel);
	// 			});
	// 		} else if (fileType && fileType.toLowerCase() === "pdf") {
	// 			const divCurrentModel = document.createElement('div');
	// 			divCurrentModel.id = "current-model";
	// 			divCurrentModel.style.cssText = " margin: 0px; overflow-y: auto; width: auto; height: 550px; background: #232323; z-index: 9999;";
	// 			ReactDOM.render(<PdfHandler file={file} />, divCurrentModel);
	// 			this.addElementToModal(divCurrentModel);
	// 			if (isSending) {
	// 				if (this.props.userType !== REMOTE_PARTICIPANT) {
	// 					this.fileSentTicketEvent(file);
	// 				}
	// 				else {
	// 					this.fileSentByPartecipantTicketEvent(file);
	// 				}
	// 			}
	// 			// handlePdfImage(file, true).then((imageDiv) => {
	// 			// 	this.addElementToModal(imageDiv);
	// 			// });
	// 		} else if (fileType && (fileType.toLowerCase() === "png" || fileType.toLowerCase() === "jpg")) {
	// 			const mainDiv = document.createElement('div');
	// 			mainDiv.id = "current-model";
	// 			ReactDOM.render(<ModalImage uri={`${process.env.REACT_APP_LOCALHOST}${file.realFileUri}`} />, mainDiv);
	// 			model = mainDiv; //handleImageFile(file.realFileUri, undefined, true);

	// 			this.addElementToModal(model);
	// 			if (isSending) {
	// 				if (this.props.userType !== REMOTE_PARTICIPANT) {
	// 					this.fileSentTicketEvent(file);
	// 				}
	// 				else {
	// 					this.fileSentByPartecipantTicketEvent(file);
	// 				}
	// 			}

	// 		} else if (fileType && fileType.toLowerCase() === "mp4") {
	// 			handleVideoFile(file, true).then(divModel => {
	// 				//var clonedModel = divModel.cloneNode(true);
	// 				this.addElementToModal(divModel);
	// 				if (isSending) {
	// 					if (this.props.userType !== REMOTE_PARTICIPANT) {
	// 						this.fileSentTicketEvent(file);
	// 					}
	// 					else {
	// 						this.fileSentByPartecipantTicketEvent(file);
	// 					}
	// 				}
	// 			});
	// 		} else {
	// 			window.open(file.realFileUri, "_blank");
	// 		}
	// 	}
	// };

	private addElementToModal = (elem: any) => {
		const modalWindow = document.getElementById("modal-window-2");


		// const dragAnchorDiv = document.createElement("div");
		// dragAnchorDiv.className = "modal-window-drag-anchor";

		// dragAnchorDiv.onmousemove = this.handleOnMouseMove;
		// dragAnchorDiv.onmousedown = this.handleOnMouseDown;

		// const imageAnchorDiv = dragAnchorDiv.appendChild(document.createElement("i"));
		// imageAnchorDiv.style.cssText = "font-size: 24px;";
		// imageAnchorDiv.textContent = "zoom_out_map";
		// imageAnchorDiv.style.transform = "rotate(45deg)";
		// imageAnchorDiv.className = "material-icons app_icon";

		// dragAnchor.appendChild(anchorIcon);

		// const anchorIcon = React.createElement('img', {}, <Icon style={{ transform: "rotate(45deg)" }} name="zoom_out_map" />);
		// const dragAnchor = React.createElement('div', { className: "modal-window-drag-anchor", onMouseMove: this.handleOnMouseMove, onMouseDown: this.handleOnMouseDown }, [anchorIcon]);

		// <div className="modal-window-drag-anchor"
		// 	onMouseMove={this.handleOnMouseMove}
		// 	onMouseDown={this.handleOnMouseDown}
		// >
		// <Icon style={{ transform: "rotate(45deg)" }} name="zoom_out_map" />;
		// </div>;

		if (modalWindow) {
			elem.style.width = "-webkit-fill-available";
			elem.style.cssText = `min-height: ${400}px; height: fit-content; display: flex; align-items: center; justify-content: center`;


			const oldElem = modalWindow.querySelector("#current-model");
			if (oldElem) {
				modalWindow.replaceChild(elem, oldElem);

			} else {
				modalWindow.appendChild(elem);
			}

			if (this.anchorRef.current) {
				elem.appendChild(this.anchorRef.current);
			}

		}
		this.setState({ modalWindowEnabled: true });

	};

	private exitFullscreen = async () => {
		try {
			if (document.fullscreenElement !== null) {
				await document.exitFullscreen();
				this.setState({ fullscreen: false });
			}
		} catch (err) {
			logger.error("Error while exiting fullscreen");
		}
	};

	private toggleFullscreen = async () => {
		const el = this.remoteVideoContainer.current! as any;

		if (document["fullscreenElement"]) {
			await document.exitFullscreen();
		}
		else {
			const fullscreen = (el.requestFullscreen || el["mozRequestFullScreen"] || el["webkitRequestFullScreen"] || el["msRequestFullscreen"]) as () => Promise<void>;
			fullscreen.call(el);
		}
	};

	private toggleMicMute = () => {
		if (!this.state.publisher) {
			return;
		}
		const { localMicEnabled } = this.state;
		const isSendingAudio = !localMicEnabled;
		this.state.publisher.publishAudio(isSendingAudio);
		this.setState({ localMicEnabled: isSendingAudio });
	};

	private getLocalVideoContainerClassNames = () => {
		const base = "active-call-page-localvideo-container";
		const { localVideoEnabled } = this.state;
		console.log("getLocalVideoContainerClassNames: " + localVideoEnabled);
		const hidden = localVideoEnabled ? "" : "";
		return classNames(base, hidden);
	};

	private getLocalVideoElementClassNames = () => {
		const base = "active-call-page-localvideo-element";
		const { secondVisualization } = this.state;
		console.log("getLocalVideoElementClassNames: " + secondVisualization);
		const hidden = secondVisualization ? "hidden" : "";
		return classNames(base, hidden);
	};

	private setupEvents() {
		this.state.session.on("streamCreated", event => {
			const e = (event as unknown) as StreamEvent;
			const subscriber = this.state.session.subscribe(e.stream, undefined as any);
		
			const connectionData = parseConnectionData(e.stream);
			if (connectionData == null) {
				return;
			}

			switch (connectionData.type) {
				case OPERATOR:
					return this.addHololensStream(subscriber);
				case REMOTE_PARTICIPANT:
					return this.addParticipantStream(subscriber);
				case REMOTE_EXPERT:
					return this.addRemoteExpertStream(subscriber);
				default:
					return this.addHololensStream(subscriber);
			}
		});

		this.state.session.on("streamDestroyed", event => {
			const e = event as StreamEvent;
			e.preventDefault();
			const connectionData = parseConnectionData(e.stream);
			if (connectionData == null) {

				return;
			}
		
			switch (connectionData.type) {
				case OPERATOR:
					if (e.reason !== "unpublish") {
						return this.removeHololensStream(e.stream);
					}
				case REMOTE_PARTICIPANT:
					if (e.reason !== "unpublish")
						return this.removeParticipantStream(e.stream);
					else
						return this.removeUnpublishedParticipant(e.stream)
				case REMOTE_EXPERT:
					return this.removeRemoteExpertStream(e.stream);
			}
		});

		// // !!! added to end session when operator hangs up
		// this.state.session.on("sessionDisconnected", event => {
		// 	const e = event as StreamEvent;
		// 	e.preventDefault();
		// 	const connectionData = parseConnectionData(e.stream);
		// 	if (connectionData == null) {
		// 		return;
		// 	}

		// 	switch (connectionData.type) {
		// 		case OPERATOR:
		// 			return this.removeHololensStream(e.stream);
		// 	}

		// })

		this.state.session.on("signal:onFileReceived", async event => {
			const { userType } = this.props;
			const e = (event as any) as SignalEvent;

			const messageData: IFileReceivedMessageData = parseOnFileReceivedMessage(e.data);

			const response = await serverManager.files.getById(messageData.fileId);
			const file = response.data;
			// file = this.state.fileSent.find(fileItem => fileItem.id === messageData.fileId);
			console.log("[debug_log] response.data " + response.data);

			if (file !== undefined) {
				this.openFileInModalView(file);


				if (userType === REMOTE_EXPERT && !this.state.sendingFile) {
					const newEvent: ITicketItem = {
						type: TicketItemType.FileSent,
						url: file.id,
						data: this.props.participantUsername as string,
					};

					await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
				}
			}
			else {
				this.props.addToast({ type: ToastType.Error, msgId: "File ID not found in sent files." });
				return;
			}

			this.setState({ sendingFile: false });
			if (userType !== REMOTE_PARTICIPANT) {
				switch (messageData.type) {
					case MessageType.ERROR:
						return this.messageError(messageData);
					case MessageType.SUCCESS:
						return this.fileSentTicketEventNotification(file);
					case MessageType.WARNING:
					default:
						return this.messageError(messageData);
				}
			}
		});

		this.state.session.on("signal:onMobileSnapshotReceived", async event => {
			const { userType } = this.props;
			const e = (event as any) as SignalEvent;

			const messageData: IFileReceivedMessageData = parseOnFileReceivedMessage(e.data);
			const response = await serverManager.files.getById(messageData.fileId);
			const file = response.data;
			console.log("[debug_log] response.data " + response.data);

			if (file !== undefined) {
				this.openFileInModalView(file);

				if (userType === REMOTE_EXPERT && !this.state.annotationMode) {
					const newEvent: ITicketItem = {
						type: TicketItemType.AnnotationSent,
						url: file.id,
						data: this.props.participantUsername as string,
					};

					await serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
				}
			}

			this.setState({ annotationMode: false });
			if (userType !== REMOTE_PARTICIPANT) {
				switch (messageData.type) {
					case MessageType.ERROR:
						return this.messageError(messageData);
					case MessageType.SUCCESS:
						this.props.addToast({ type: ToastType.Success, msgId: "Snapshot received by mobile operator." });
						return;
					case MessageType.WARNING:
					default:
						return this.messageError(messageData);
				}
			}
		});



		this.state.session.on("onFileReceived", async event => {
			const e = (event as any) as SignalEvent;

			const messageData: IFileReceivedMessageData = parseOnFileReceivedMessage(e.data);
			const response = await serverManager.files.getById(messageData.fileId);
			const file = response.data;
			// file = this.state.fileSent.find(fileItem => fileItem.id === messageData.fileId);
			console.log("[debug_log] response.data " + response.data);

			if (file !== undefined) {
				this.openFileInModalView(file);
			}
			else {
				this.props.addToast({ type: ToastType.Error, msgId: "File ID not found in sent files." });
				return;
			}

			switch (messageData.type) {
				case MessageType.ERROR:
					return this.messageError(messageData);
				case MessageType.SUCCESS:
					return this.fileSentTicketEventNotification(file);
				case MessageType.WARNING:
				default:
					return this.messageError(messageData);
			}
		});



		this.state.session.on("signal:onSnapshotReceived", event => {
			console.log("SESSION SNAPSHOT EVENT RECEIVED");
			const e = (event as any) as SignalEvent;

			if (e.data === undefined) {
				this.props.addToast({ type: ToastType.Error, msgId: "Snapshot content invalid." });
				return;
			}


			try {
				const messageData: string = e.data;
				//var decodedString = atob(messageData);
				const canvas = this.screenshotCanvas;

				console.log("SNAPSHOT PIC RECEIVED: " + messageData);

				serverManager.files.getById(messageData).then(res => {
					if (canvas) {
						const file = res.data;
						const imageFromFile = new Image();
						imageFromFile.src = `${file.realFileUri}`;
						imageFromFile.onload = function () {
							if (canvas) {
								const ctx = canvas.getContext("2d");

								//if (ctx) {
								//	ctx.fillRect(0, 0, imageFromFile.width, imageFromFile.height);
								//	ctx.drawImage(imageFromFile, 0, 0, imageFromFile.width, imageFromFile.height);
								//}
								if (ctx) {
									const canvasAspectRatio = canvas.width / canvas.height;
									const aspectRatio = imageFromFile.width / imageFromFile.height;
									//const logoWidth = canvas.height / aspectRatio;
									//const logoHeight = aspectRatio * canvas.width;
									//ctx.drawImage(imageFromFile, 0, 0, imageFromFile.width, imageFromFile.height);
									ctx.fillRect(0, 0, canvas.width, canvas.height);

									console.log("CTX: aspectRatio / canvasAspectRatio = " + aspectRatio + " / " + canvasAspectRatio);
									console.log("CTX: image res = " + imageFromFile.width + " ; " + imageFromFile.height);
									console.log("CTX: canvas res = " + canvas.width + " ; " + canvas.height);

									if (aspectRatio >= canvasAspectRatio) {
										const height = canvas.width / aspectRatio;
										const heightDiff = canvas.height - height;
										ctx.drawImage(imageFromFile, 0, heightDiff * 0.5, canvas.width, height);
									}
									else {
										const width = canvas.height * aspectRatio;
										const widthDiff = canvas.width - width;
										ctx.drawImage(imageFromFile, widthDiff * 0.5, 0, width, canvas.height);
									}
								}
							}
						}
					}
				});

				this.props.addToast({ type: ToastType.Success, msgId: "ActiveCall.SnapshotReceived" });
			}
			catch {
				console.log("CANVAS ERROR!");
			}
		});

		logger.debug("Events Success!");
	}

	private getSnapshotResolution(): { snapshotWidth: number, snapshotHeight: number } | false {
		const { snapshotWidth, snapshotHeight } = this.state;

		if (snapshotWidth == 0 || snapshotHeight == 0)
			return false;

		return { snapshotWidth, snapshotHeight };
	}
	private removeUnpublishedParticipant(stream: Stream): void {
		const { mainStreamManager, mainStreamParticipant, participants } = this.state;

		const unpublishedParticipantIndex = this.state.participants.findIndex((participant) => participant.stream === stream);
		if (mainStreamParticipant === participants[unpublishedParticipantIndex]) {
			this.setState({ mainStreamManager: this.getOperatorViewStreamManager() as StreamManager })
		}
		const parts = this.state.participants.filter(participant => participant.stream !== stream);
		this.setState({
			participants: parts,
			switchActionParticipantIndex: unpublishedParticipantIndex,
		});
	}

	private messageError = (data: IFileReceivedMessageData) => {
		this.setState({ sendingFile: false });
		if (data.type === MessageType.ERROR) {
			this.props.addToast({ type: ToastType.Error, msgId: data.message });
		} else {
			this.props.addToast({ type: ToastType.Warning, msgId: data.message });
		}
	};

	private handleRemoteVideoRef = (el: React.RefObject<VideoElementWithMediaStream>) => {
		if (!el) {
			throw new Error("No HTMLVideoElement available");
		}

		this.remoteVideo = el;
	};
}

const mapStateToProps = (state: IRootState) => ({
	activeCallConnected: state.callQueue.activeCallConnected,
	activeCallId: state.callQueue.activeCallId,
	activeCallProductId: state.callQueue.activeCallProductId,
	peerId: state.callQueue.activeCallId,
	filesArray: toArray(state.files.dataForProduct),
	loggedUser: state.userInfo.data,
	filesLoading: state.files.loading,
	filesError: state.files.error,
	isCallWindowMinimized: state.callQueue.isCallWindowMinimized,
	productError: state.products.error,
	productLoading: state.products.loading,
	product: state.products.data[state.callQueue.activeCallProductId!],
	products: toArray(state.products.data),
	activeCallTicketId: state.callQueue.activeCallTicketId,
	holoLensEvents: state.callQueue.holoLensEvents,
	operatorId: state.callQueue.userId,
	sessionId: state.callQueue.roomId,
	userType: state.callQueue.userType,
	participantUsername: state.callQueue.participantsUsername,
	mainStreamManager: state.ovCall.callState.mainStreamManager,
	isConnected: state.ovCall.callState.isConnected,
	publisher: state.ovCall.callState.publisher,
	remoteExpert: state.ovCall.callState.remoteExpert,
	participants: state.ovCall.callState.participants,
	publisherType: state.ovCall.callState.publisherType,

	error: state.ovCall.error,
	ovError: state.ovCall.ovError,
	loading: state.ovCall.loading,
	userInfo: state.userInfo.data,
	operatorName: state.callQueue.operatorName,
	operatorUserAgent: state.callQueue.operatorUserAgent,
	company: state.company.data.company,

	users: toArray(state.users.data),
	teams: toArray(state.users.teamsData),
	remainingTime: state.companies.remainingTime,
	uploadValue: state.callQueue.uploadValue
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			activeCallFileUploadSuccess: filesActions.activeCallFileUploadSuccess,
			connectUser: ovActions.connectUser,
			leaveSession: ovActions.leaveSession,

			getProductsByCompanyId: productsActions.getProductsByCompanyId,
			getSingleProduct: productsActions.getSingleProduct,
			getProducts: productsActions.getProducts,
			getGenericProduct: productsActions.getGenericProduct,

			getFilesForProduct: filesActions.getFilesForProduct,
			signIn: callQueueActions.signIn,
			toggleMinimizeCallWindow: callQueueActions.toggleMinimizeCallWindow,
			onFileUploaded: filesActions.onFileUploaded,

			// answerCall: callQueueActions.answerCall,
			endCall: callQueueActions.endCall,
			addHololensStream: ovActions.addHololensStream,
			addRemoteExpertStream: ovActions.addRemoteExpertStream,
			addParticipantStream: ovActions.addParticipantStream,
			removeHololensStream: ovActions.removeHololensStream,
			removeParticipantStream: ovActions.removeParticipantStream,
			removeRemoteExpertStream: ovActions.removeRemoteExpertStream,
			getMyCompany: companyActions.getMyCompany,
			getClipsData: fileActions.getFileClipsData,

			getUsers: userActions.getUsers,
			updateUsedCallTime: companiesActions.updateUsedCallTime,
			getTeams: userActions.getTeams,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;
type RouteProps = RouteComponentProps<{ id: string }>;

const ActiveCallBaseComponent = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(withToasts(ActiveCallBase)));

export const ActiveCall = () => {
	return <ActiveCallBaseComponent />;
};
