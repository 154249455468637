import { OpenVidu, Publisher, Session, Stream, StreamManager, Subscriber } from "openvidu-browser/lib";
import { ToastType } from "../components/toast";
import { IPeerInfo } from "../WebRTC/StreamManager";

export const OPERATOR = "OPERATOR";
export const REMOTE_EXPERT = "REMOTE_EXPERT";
export const REMOTE_PARTICIPANT = "REMOTE_PARTICIPANT";

export type IUserType = typeof OPERATOR | typeof REMOTE_EXPERT | typeof REMOTE_PARTICIPANT;

export interface IConnectionData {
	userName: string;
	type: IUserType;
}

export interface IOVTokenData {
	sessionId: string;
	userType: IUserType;
}

export interface ISessionConnectionData {
	session?: Session;
	userName?: string;
	token: string;
	type: IUserType;
}

export interface IConnectedSession {
	mainStreamManager?: StreamManager;
	publisher?: Publisher;
	publisherType: IUserType;
	isConnected: boolean;
}

export interface IUserConnect {
	session: Session;
	OV: OpenVidu;
	type: IUserType;
}

export interface IOVError {
	userMessage: string;
	errorMessage?: string;
	toastType?: ToastType;
	type?: string;
	multiline?: boolean;
	callback?: any;
}

export interface ILeaveSession {
	session: Session;
	OV: OpenVidu;
}

export interface ICallState {
	mainStreamManager?: StreamManager;
	isConnected: boolean;
	publisher?: Publisher;
	remoteExpert?: Subscriber;
	participants?: Subscriber[];
	publisherType?: IUserType;
}

export interface IOVStreamRemove {
	participants: Subscriber[];
	stream: Stream;
}

export function parseConnectionData(stream: Stream): IConnectionData | undefined {
	try {
		const parsed: IConnectionData = JSON.parse(stream.connection.data);
		return parsed;
	} catch (err) {
		console.error("Error while parsing connectionData");
		// TODO Error Handling...
		return;
	}
}
