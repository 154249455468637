import { AxiosInstance } from "axios";
import { Id, IUser } from "../../types";
import { ITeam } from "../../types/ITeam";


export class TeamsManager {
    private agent: AxiosInstance;
    private url: string = "Teams";

    constructor(agent: AxiosInstance) {
        this.agent = agent;
    }

    public getUsers = async (teamId: Id) => this.agent.get<IUser[]>(`${this.url}/GetUsers?teamId=${teamId}`);
    public updateTeam = async (dto: ITeam) => this.agent.put<ITeam>(`${this.url}/UpdateTeam?teamId=${dto.id}&name=${dto.name}`, dto.name);
	public createTeam = async (dto: ITeam) => this.agent.post<ITeam>(`${this.url}/CreateTeam`, dto); //Gives created Team with ID
	public deleteTeam = async (TeamId: string) => this.agent.delete(`${this.url}/DeleteTeam?teamId=${TeamId}`);
    public getTeams = async () => this.agent.get<ITeam[]>(`${this.url}/Teams`);
	public getTeamsByCompany = async (companyId: string) => this.agent.get<ITeam[]>(`${this.url}/Teams?forceCompanyId=${companyId}`); //Useful when you're a super admin and cannot access company context
    public moveToTeam = async (teamId: Id | null, userId: Id) => this.agent.put(`${this.url}/MoveToTeam?teamId=${teamId}&userId=${userId}`);
}