import { HubConnection } from "@microsoft/signalr";
import { call, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { logger } from "../../utilities/logger";
import { actions } from "./call-queue.actions";
import { SendableHubEvents } from "./call-queue.events";

export function* handleSignIn(socket: HubConnection, action: ReturnType<typeof actions.signIn>) {
	try {
		yield call(logger.debug, "Dispatching signIn event to hub");
		yield call([socket, "send"], SendableHubEvents.SignIn);
	} catch (err) {
		logger.error('Error dispatching "listen" event to hub.');
	}
}

export function* eventDispatcher(socket: HubConnection) {
	yield takeEvery(getType(actions.signIn), handleSignIn, socket);
}
