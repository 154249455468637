import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { InjectedToastsProps, ToastType, withToasts } from "../toast";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import { bindActionCreators, Dispatch } from "redux";
import { T, GetLangString } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions as tiketsActions } from "../../reducers/tickets";
import { Id, IFile, ITicket, ITicketCreate, ITicketItem, IUser, TicketItemType } from "../../types";
import { toArray } from "../../utilities";
import { logger } from "../../utilities/logger";
import { LoadingPage, Icon } from "../globalComponents";
import { WorkLogsList } from "../ticket/WorkLogList";
import { Button } from "reactstrap";
import { UserRole } from "../../types/IRoles";
import "./ProductsWorklogTab.scss";
import { serverManager } from "../../managers/ServerManager";
import { CustomAlert } from "../../utilities/custom-alert";
import { DropzoneCreateTicketModal } from "./DropzoneCreateTicketModal";
import { parsingJSON } from "../active-call/ActiveCallUtils";
import { firstTicketEvent, ticketEvent } from "../../managers/DummyData";
import { userInfo } from "os";


interface IProductWorkLogTabsProps extends ConnectProps, RouteProps, InjectedToastsProps {
	productId: Id;
	userInfo: IUser;
}

interface IProductWorkLogTabsState {
	openCustomAlertMergeTickets: boolean;
	sortOrder: string;
	sortOrderDesending: boolean;
	localWorklog: ITicket[];
	description: string;
	selectedStatus: string;
	selectedTickets: string[];
	openCustomAlertEmailReport: boolean;
	openCustomAlertDeleteTickets: boolean;
	actualSection: string;
	ticketId: Id;
	uploadTicketDocument: boolean;
	isFileUploaded: boolean;
	ticketEvents: ITicketItem[];
}

class ProductWorkLogs extends React.Component<IProductWorkLogTabsProps, IProductWorkLogTabsState> {
	public state: IProductWorkLogTabsState = {
		sortOrder: "start",
		sortOrderDesending: false,
		localWorklog: [],
		description: "",
		selectedStatus: "",
		selectedTickets: [],
		openCustomAlertEmailReport: false,
		openCustomAlertMergeTickets: false,
		openCustomAlertDeleteTickets: false,
		actualSection: "",
		ticketId: "",
		uploadTicketDocument: false,
		isFileUploaded: true,
		ticketEvents: [],
	};
	private pageIndex: number = 0;
	private dateValue: string = "";

	public async componentDidMount() {
		const callerSection = location.href;
		const section = callerSection.split("/")[5];
		this.setState({ actualSection: section });
		this.dateValue = this.formatDate(new Date());

		if (location.href.includes("?") && !this.props.userInfo.isTemporary) {
			const parameters = location.href.split("?")[1];
			var description = parameters.split("&").find(x => x.includes("description"));
			if (description !== undefined) {
				description = description.split("=")[1];
				console.log("[debug] DESCRIPTION: " + description);
				this.setState({ description: description });
			}
			var status = parameters.split("&").find(x => x.includes("selectedStatus"));
			if (status !== undefined) {
				status = status.split("=")[1];
				console.log("[debug] STATUS: " + status);
				this.setState({ selectedStatus: status });
			}
			var date = parameters.split("&").find(x => x.includes("date"));
			if (date !== undefined) {
				date = date.split("=")[1];
				console.log("[debug] DATE: " + date);
				this.dateValue = this.formatDate(new Date(date));
			}
			var page = parameters.split("&").find(x => x.includes("page"));
			if (page !== undefined) {
				page = page.split("=")[1];
				console.log("[debug] INDEX: " + page);
				this.pageIndex = parseInt(page);
			}
			this.props.getGetWorklogs(this.props.productId, parseInt(page as string), this.formatDate(new Date(date as string)), (description as string).toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", status as string);

		} else if (!this.props.userInfo.isTemporary) {
			this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.formatDate(new Date()), this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
		} else {
			this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.formatDate(new Date()), this.props.userInfo.userName, this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
			this.setState({ description: this.props.userInfo.userName });
		}

		//	await this.props.getGetWorklogsSingle(this.props.productId, 0, 25, this.addTicketsToLocalWorklog);
	}

	private addTicketsToLocalWorklog = (tickets: ITicket[]) => {
		console.log("TRYING TO FETCH all TICKETS!");
		tickets.forEach(this.addTicketToLocalWorklog);
		console.log(this.state.localWorklog.length);
		this.forceUpdate();
	}

	private addTicketToLocalWorklog = (ticket: ITicket) => {
		console.log("TRYING TO FETCH single TICKET!");
		if (ticket) {
			this.state.localWorklog.push(ticket);
			console.log("fetched ticket! adding " + ticket.id);
		}
	}

	public render() {
		const { error, loading, workLogs, userInfo, productId } = this.props;

		var today = new Date();

		if (error) {
			return (
				<Col>
					<h1>No Worklogs Found </h1>
				</Col>
			);
		}

		if (loading) {
			return <LoadingPage />;
		}

		return (
			<React.Fragment>
				<DropzoneCreateTicketModal addToast={this.props.addToast} onFileUploaded={this.onFileUploaded} isOpen={this.state.uploadTicketDocument} toggle={this.toggle} />
				{this.state.openCustomAlertEmailReport && <CustomAlert
					messageHeader={GetLangString("Ticket.ReceiveCallLogViaMail")}
					messageBody={GetLangString("Ticket.ReceiveCallLogViaMailBody")}
					messageButton1={GetLangString("Done")}
					messageButton2={GetLangString("Cancel")}
					onClickButton1={this.requestTicketsReport}
					onClickButton2={() => this.setState({ openCustomAlertEmailReport: false })}
				/>}
				{this.state.openCustomAlertMergeTickets && <CustomAlert
					messageHeader={GetLangString("Ticket.MergeHeader")}
					messageBody={this.customAlertMergeBody()}
					messageButton1={GetLangString("Done")}
					messageButton2={GetLangString("Cancel")}
					onClickButton1={this.mergeTickets}
					onClickButton2={() => this.setState({ openCustomAlertMergeTickets: false })}
				/>}
				{this.state.openCustomAlertDeleteTickets && <CustomAlert
					messageHeader={GetLangString("Ticket.DeleteHeader")}
					messageBody={GetLangString("Ticket.DeleteBody")}
					messageButton1={GetLangString("Done")}
					messageButton2={GetLangString("Cancel")}
					onClickButton1={this.deleteTickets}
					onClickButton2={() => this.setState({ openCustomAlertDeleteTickets: false })}
				/>}
				<Col className="col-no-gutters" sm={12} style={{ marginTop: 15 }}>
					<Card>
						{userInfo.userRole !== UserRole.Operator ? (
							<CardBody>
								<h5>
									<T v="WorkLog.Title" />
									{/* <span className="page-number">{`${this.pageIndex + 1}/${splittedWorkLogs.length}`}</span> */}
								</h5>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
									<form onSubmit={this.handleSubmit} style={{ width: "fit-content" }}>
										<select value={this.state.selectedStatus} onChange={(event) => this.setState({ selectedStatus: event.target.value })} style={{ marginRight: "5px" }} name="cars" id="cars">
											<option value="">{GetLangString("TicketStatus.SelectStatus")}</option>
											<option value="Open">{GetLangString("TicketStatus.Open")}</option>
											<option value="Working">{GetLangString("TicketStatus.Working")}</option>
											<option value="WaitingForFeedback">{GetLangString("TicketStatus.WaitingForFeedback")}</option>
											<option value="Solved">{GetLangString("TicketStatus.Solved")}</option>
											<option value="ClosedButNotSolved">{GetLangString("TicketStatus.ClosedButNotSolved")}</option>
											<option value="NeedFurtureActivity">{GetLangString("TicketStatus.NeedFurtureActivity")}</option>
											<option value="NoNeedToSolved">{GetLangString("TicketStatus.NoNeedToSolved")}</option>
										</select>
										<input style={{ width: "186px" }} value={this.state.description} type="text" onChange={(event) => this.setState({ description: event.target.value })} />
										<input type="submit" value={`${GetLangString("Ticket.Submit")}`} />
									</form>
								</div>
								<br />
								<div style={{ display: "inline-flex", width: "100%" }}>
									<input style={{ marginLeft: "0px" }} type="date" id="date_filter" name="date_filter" value={this.dateValue} onChange={this.getTimeFilter()}></input>
									{
										<button title={GetLangString("Ticket.SendMailReport")} onClick={() => this.setState({ openCustomAlertEmailReport: true })} style={{ marginLeft: "calc(100% - 320px)", backgroundColor: "white", borderBlockEndWidth: "1px" }}>
											{`${GetLangString("Ticket.SendMailReport")} `}
											<Icon style={{ fontSize: "19px" }} name="mark_email_read" />
										</button>
									}
								</div>

								{<div>
									<br />
									<Button onClick={this.createNewTicket} title={GetLangString("Ticket.CreateNewTicket")}>
										<Icon name="playlist_add" />
									</Button>
									{this.state.selectedTickets.length === 2 &&
										<Button title={GetLangString("Ticket.MergeHeader")} style={{ marginLeft: "5px" }} onClick={() => this.setState({ openCustomAlertMergeTickets: true })} color="secondary">
											<Icon name="compare_arrows" />
										</Button>}
									{this.state.selectedTickets.length > 0 &&
										<Button title={GetLangString("Ticket.DeleteHeader")} style={{ marginLeft: this.state.selectedTickets.length == 2 ? "calc(100% - 198px)" : "calc(100% - 143px)" }} onClick={() => this.setState({ openCustomAlertDeleteTickets: true })} color="primary" >
											<Icon name="delete" />
										</Button>}
								</div>
								}
								{/* <Button href={this.getExportTicketsUrl()} style={{ marginLeft: 8 }} color="primary">
								<T v="Ticket.Export" />
							</Button> */}
							</CardBody>
						) : null}

						<WorkLogsList
							userInfo={userInfo}
							getTicketId={this.getSelectedTickets}
							getDeselectedTicketId={this.getDeselectedTickets}
							goToWorkLog={this.goToWorkLog}
							productId={productId}
							workLogs={this.splitWorkLogs(10)[0]}//this.state.localWorklog
							setSortOrder={this.setSortOrder}
							sortOrder={this.state.sortOrder}
							sortOrderDesending={this.state.sortOrderDesending}
						/>

						<div className="div-buttons">
							{this.pageIndex > 0 && <button style={{ width: "auto" }} className="button" onClick={this.stepBack()}><Icon name="keyboard_arrow_left" /></button>}
							{workLogs.length == 11 && <button style={{ width: "auto" }} className="button" onClick={this.stepForward()}><Icon name="keyboard_arrow_right" /></button>}
						</div>
					</Card>
				</Col>
			</React.Fragment>
		);
	}

	private isFileUploaded = (isUploaded: boolean) => {
		this.setState({ isFileUploaded: isUploaded });
	};

	private toggle = (isCreatingTicket: boolean) => {
		if (isCreatingTicket) {
			if (this.state.ticketEvents.length >= 0) {
				const data: ITicketCreate = {
					operatorId: this.props.userInfo.id,
					ticketEvents: firstTicketEvent,
				};

				serverManager.tickets
					.postTicket(this.props.productId, data)
					.then(response => {
						console.warn("Ticket ID:", response);
						this.setState({ ticketId: response.data.id });
						this.state.ticketEvents.map(ticketEvent => {
							return serverManager.tickets.postTicketEvent(this.state.ticketId, ticketEvent);
						});
						this.setState({ uploadTicketDocument: false });
						this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.dateValue, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
					}).catch(error => {
						console.error(error);
					})
			}
			else {
				this.setState({ uploadTicketDocument: false });
			}
		}
		else {
			this.setState({ isFileUploaded: false, ticketEvents: [], uploadTicketDocument: false });
		}
	}

	private createNewTicket = () => {
		this.setState({ uploadTicketDocument: true, isFileUploaded: false, ticketEvents: [] });
	}

	private onFileUploaded = (file: IFile) => {
		this.setState({ isFileUploaded: true });
		const newEvent: ITicketItem = {
			type: TicketItemType.FileSent,
			url: file.id,
			linkedObject: file,
			data: `${this.props.userInfo.userName}`,
		};
		this.setState({ ticketEvents: [...this.state.ticketEvents, newEvent] });
		// serverManager.tickets.postTicketEvent(this.state.ticketId, newEvent);
		this.props.addToast({ msgId: "Toast.DocumentUploadedSuccess", type: ToastType.Success })
	};

	private customAlertMergeBody = () => {
		var tickets = this.props.workLogs.filter(x => this.state.selectedTickets.includes(x.id as string));
		if (tickets.length == 2) {
			var startDateTicket1 = new Date(tickets[0].start);
			var endDateTicket1 = new Date(tickets[0].end);
			var startDateTicket2 = new Date(tickets[1].start);
			var endDateTicket2 = new Date(tickets[1].end);
			if ((startDateTicket2.getTime() < startDateTicket1.getTime() && startDateTicket1.getTime() < endDateTicket2.getTime()) ||
				(startDateTicket1.getTime() < startDateTicket2.getTime() && startDateTicket2.getTime() < endDateTicket1.getTime())) {
				return GetLangString("Ticket.MergeBody")
			}
		}
		return "";
	}

	private mergeTickets = () => {
		this.props.mergeTickets(this.state.selectedTickets, this.validator);
		this.setState({ openCustomAlertMergeTickets: false });
	};

	private deleteTickets = async () => {
		this.props.deleteTickets(this.state.selectedTickets, this.validator);
		this.setState({ openCustomAlertDeleteTickets: false });
	};

	private validator = (message: string, validate: boolean) => {
		const toastType = validate ? ToastType.Success : ToastType.Error;
		this.props.addToast({ type: toastType, msgId: message });
		if (this.state.actualSection !== "") {
			this.props.history.push(`/product/${this.props.productId}/${this.state.actualSection}?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
		} else {
			this.props.history.push(`/product/${this.props.productId}/worklogs?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
		}

		this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.dateValue, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
		this.setState({ selectedTickets: [] })
	}

	private getDeselectedTickets = (ticketId: string) => {
		var tickets = this.state.selectedTickets.filter(x => x !== ticketId);
		console.log("[debug] number of deselected tickets: " + tickets.length);
		this.setState({ selectedTickets: tickets });
	};

	private getSelectedTickets = (ticketId: string) => {
		console.log('[debug] ticket with id: ' + ticketId);
		var tickets = this.state.selectedTickets;
		tickets.push(ticketId);
		console.log('[debug] number of selected tickets: ' + tickets.length);
		this.setState({ selectedTickets: tickets });
	};

	private handleSubmit = () => {
		if (this.state.actualSection !== "") {
			this.props.history.push(`/product/${this.props.productId}/${this.state.actualSection}?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
		} else {
			this.props.history.push(`/product/${this.props.productId}/worklogs?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
		}
		this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.dateValue, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
	};

	private requestTicketsReport = async () => {
		this.props.requestTicketsReport(this.props.productId.toString(), this.validator);
		this.setState({ openCustomAlertEmailReport: false });
		//console.log( `api/Tickets/exportTickets/${this.props.productId}`);
		//return `api/Tickets/exportTickets/${this.props.productId}`;
	}

	private getExportTicketsUrl(): string {
		console.log(`api/Tickets/exportTickets/${this.props.productId}`);
		return `api/Tickets/exportTickets/${this.props.productId}`;
	}

	public formatDate = (date: Date): string => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}

	private splitWorkLogs = (chunks: number): ITicket[][] => {
		var splittedArray = [];
		var lastIndex: number = 0;
		var i, j;
		const workLogsLength = this.props.workLogs.length;
		for (i = 0; i < workLogsLength; i += chunks) {
			lastIndex += chunks;
			var subList = [];
			for (j = i; j < lastIndex; j++) {
				if (j < workLogsLength) {
					subList.push(this.props.workLogs[j]);
				}
			}
			splittedArray.push(subList);
		}
		return splittedArray;
	};

	private setSortOrder = (sortOrder: string) => {
		const sortOrderDesending = !this.state.sortOrderDesending;
		this.setState({ sortOrder, sortOrderDesending });
		logger.info("Sorted by key:", sortOrder);
		logger.info("Sorted by descending:", this.state.sortOrderDesending);
	};

	private goToWorkLog = (workLogId: Id): void => {
		this.props.history.push(`/product/${this.props.productId}/ticket/${workLogId}`);
	};

	private getTimeFilter(): ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined {
		return (event) => {
			this.pageIndex = 0;
			if (this.state.actualSection !== "") {
				this.props.history.push(`/product/${this.props.productId}/${this.state.actualSection}?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			} else {
				this.props.history.push(`/product/${this.props.productId}/worklogs?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			}
			this.props.getGetWorklogs(this.props.productId, this.pageIndex, event.target.value, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
			this.dateValue = event.target.value;
			this.forceUpdate();
		};
	}

	private stepForward(): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) & ((event: React.MouseEvent<any, MouseEvent>) => void) & (() => void) {
		return () => {
			this.pageIndex++;
			if (this.state.actualSection !== "") {
				this.props.history.push(`/product/${this.props.productId}/${this.state.actualSection}?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			} else {
				this.props.history.push(`/product/${this.props.productId}/worklogs?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			}
			this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.dateValue, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
			console.log(this.pageIndex);
			this.forceUpdate();
		};
	}

	private stepBack(): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) & ((event: React.MouseEvent<any, MouseEvent>) => void) & (() => void) {
		return () => {
			this.pageIndex--;
			if (this.state.actualSection !== "") {
				this.props.history.push(`/product/${this.props.productId}/${this.state.actualSection}?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			} else {
				this.props.history.push(`/product/${this.props.productId}/worklogs?page=${this.pageIndex}&description=${this.state.description}&selectedStatus=${this.state.selectedStatus}&date=${this.dateValue}`, { page: this.pageIndex, status: this.state.selectedStatus, date: this.dateValue, search: this.state.description });
			}
			this.props.getGetWorklogs(this.props.productId, this.pageIndex, this.dateValue, this.state.description.toLowerCase(), this.props.userInfo.teamId ? this.props.userInfo.teamId : "", this.state.selectedStatus);
			console.log(this.pageIndex);
			this.forceUpdate();
		};
	}
}

const mapStateToProps = (state: IRootState) => ({
	error: state.tickets.error,
	workLogDictionary: state.tickets.data,
	workLogs: toArray(state.tickets.data),
	loading: state.tickets.loading,
	userInfo: state.userInfo.data,
	hololensEvents: state.callQueue.holoLensEvents,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			mergeTickets: tiketsActions.mergeTickets,
			getGetWorklogs: tiketsActions.getTickets,
			getGetWorklogsSingle: tiketsActions.getTicketView,
			deleteTickets: tiketsActions.deleteTickets,
			requestTicketsReport: tiketsActions.requestTicketsReport,
		},
		dispatch,
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;
type RouteProps = RouteComponentProps<{ id: string, }>;

export const WorkLogTab = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withToasts(withRouter(ProductWorkLogs)));
