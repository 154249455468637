export function getTimeString(): string {
	const date = new Date();
	return `[${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}] `;
}

export function isSessionDescription(obj: any): obj is RTCSessionDescriptionInit {
	return obj && obj.type && obj.sdp;
}

export function isCandidateDescription(obj: any): obj is RTCIceCandidateInit {
	return obj && obj.sdpMid && obj.candidate;
}

export function timeout(time: number): Promise<void> {
	const prom: Promise<void> = new Promise<void>((exec, rejec) => {
		setTimeout(() => {
			exec();
		}, time);
	});
	return prom;
}

export function getRandomName(): string {
	const webNumber: number = Math.floor(Math.random() * 100000);
	const peerName: string = "WebClient_" + webNumber;
	return peerName;
}
