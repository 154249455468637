import * as React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { bindActionCreators, Dispatch } from "redux";
import { GetLangString, T } from "../../managers/I18n";
import { IRootState } from "../../reducers";
import { actions, productsSaga } from "../../reducers/products";
import { actions as userActions } from "../../reducers/users";
import { actions as companyActions } from "../../reducers/company";
import { actions as troubleshootingActions } from "../../reducers/troubleshooting-items";
import { ICreateProductDto, IProduct, IUser } from "../../types";
import { UserRole } from "../../types/IRoles";
import { toArray } from "../../utilities";
import { PlaceholderPage } from "../globalComponents";
import { LoadingPage } from "../globalComponents/LoadingPage";
import PageHeader from "../globalComponents/PageHeader";
import { ItemDisplay } from "./ItemDisplay";
import { NewProductModal } from "./NewProductModal";
import { QrModal } from "./QrModal";
import { MobileToolbarButton } from "../active-call/ToolbarButton";
import "./Products.scss";
import { userInfo } from "os";
import { ITeam } from "../../types/ITeam";

interface IProductsProps extends ConnectProps {
	isActiveCall?: boolean;
	[key: string]: any;
}

interface IProductsState {
	isModalOpen: boolean;
	isQrModalOpen: boolean;
	genericProductId: string | null;
	teams: ITeam[];
	products: IProduct[];
	productFilter: string;
}



class ProductsComponent extends React.Component<IProductsProps, IProductsState> {
	public state: IProductsState = {
		isModalOpen: false,
		isQrModalOpen: false,
		genericProductId: null,
		teams: [],
		products: [],
		productFilter: "",
	};

	private containerStyle: React.CSSProperties = {
		margin: 0
	};

	public componentDidMount() {
		this.props.getTeams();

		if (!this.props.company) {
			this.props.getMyCompany();
		}

		if (this.props.company && this.props.company.mainCompanyId !== null) {
			this.props.getProductsByCompanyId(this.props.company.mainCompanyId);
		}
		else {
			this.props.getProducts();
		}
		this.props.getGenericProduct((x: IProduct) => this.setState({ genericProductId: x.id }));

	}

	public componentDidUpdate(prevProps: IProductsProps) {
		if (prevProps.teams != this.props.teams) {
			this.setState({ teams: this.props.teams })
		}

		if (prevProps.products != this.props.products) {
			this.setState({ products: this.props.products })
		}
	}

	public render() {
		const { isModalOpen, isQrModalOpen, teams, products } = this.state;
		const { loading, loadingTeams, error, saving, userInfo, isActiveCall } = this.props;
		const isCompanyNotSubordinate = this.props.company && this.props.company.mainCompanyId === null;

		if (loading || loadingTeams) {
			return <LoadingPage />;
		}

		if (error && isActiveCall === undefined) {
			return (
				<React.Fragment>
					<PageHeader />
					<Container style={this.containerStyle}>
						<Row>
							<T v={"Error"} />
						</Row>
					</Container>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<NewProductModal
					isSaving={saving}
					onSave={this.handleSave}
					toggle={this.closeModal}
					isOpen={isModalOpen}
				/>
				<QrModal
					toggle={this.closeQrModal}
					isOpen={isQrModalOpen}
					products={products}
				/>
				{userInfo.userRole !== UserRole.Operator && <PageHeader />}

				<div
					className={
						userInfo.userRole === UserRole.Operator
							? "products-list-container-mobile"
							: "products-list-container"
					}
				>
					<div className="products-list-actionbar">
						{userInfo.userRole === UserRole.CompanyAdmin && isCompanyNotSubordinate && (
							<Button onClick={this.openModal} color="primary">
								<T v="ProductsProperties.New" />
							</Button>
						)}
					</div>
					<div className="search-bar">
						{/* <label style={{ padding: "5px" }} htmlFor="search_product">{`${GetLangString('Ticket.SearchProduct')}`}</label> */}
						<form onSubmit={e => e.preventDefault()}>
							<input className="input" id="search_product" name="search_product" onChange={e => this.setState({ productFilter: e.target.value.toLowerCase() })} />
							<input type="submit" value={`${GetLangString("Ticket.Submit")}`} />
						</form>
					</div>
					<div className="products-list-items">
						{products.length === 0
							? this.renderPlaceholder()
							: products.map(product => this.searchInProducts(product, this.state.productFilter) && this.renderProduct(product, isCompanyNotSubordinate))}
					</div>
				</div>
				{isActiveCall === undefined && <div className="qr-code-button">
					<MobileToolbarButton
						className={"icon-primary"}
						onClick={this.openQRModal}
						iconName={"filter_center_focus"}
						id="filter_center_focus"
					/>
					{userInfo.originalUserRole === UserRole.Expert && userInfo.userRole === UserRole.Operator && <MobileToolbarButton
						className={"icon-primary-2"}
						onClick={this.handleSwitchUserButton}
						iconName={"360"}
						id="switch-role"
					/>}
				</div>}
			</React.Fragment>
		);
	}

	private searchInProducts(product: IProduct, filter: string): boolean {
		return product.name.toLowerCase().includes(filter) || product.id.toLowerCase().includes(filter);
	}

	private handleSwitchUserButton = () => {
		this.props.switchRole(() => location.reload());		
	};

	public renderPlaceholder = () => (
		<PlaceholderPage iconName="library_books">
			<T v="ProductsProperties.NoProducts" />
		</PlaceholderPage>
	);

	private getExportProductsUrl(): string {
		return `api/Tickets/ExportProductsInfo`;
	}

	private handleSave = async (dto: ICreateProductDto) => {
		const callback = () => {
			this.setState({ isModalOpen: false });
			this.props.getTeams();
		};

		this.props.createProduct(dto, callback);
	};

	private createProductCallback() {
		this.setState({ isModalOpen: false });
		this.props.getTeams();
	}

	private closeModal = () => this.setState({ isModalOpen: false });
	private openModal = () => this.setState({ isModalOpen: true });
	private closeQrModal = () => this.setState({ isQrModalOpen: false });
	private openQRModal = () => this.setState({ isQrModalOpen: true });

		private renderProduct = (product: IProduct, isCompanyNotSubordinate: boolean): JSX.Element | undefined => {
		const referencedTeam = this.state.teams.find(x => x.id === this.props.userInfo.teamId);
		//TODO: Check when admin
		const isInTeam = (this.props.userInfo.teamId != null && this.props.userInfo.teamId != "");
		const isVisible = (product.id == this.state.genericProductId || !isInTeam || (referencedTeam && referencedTeam.referencedProductIds.includes(product.id)));
		let mainGenericProd = undefined;
		if (this.props.company && this.props.company.mainCompanyId !== null) {
			mainGenericProd = this.props.products.find(x => (x.companyId === this.props.company.mainCompanyId && x.productCode === 'Generic'));
		}
		console.log("referencedTeam: " + referencedTeam);
		console.log("isInTeam: " + isInTeam);
		console.log("isVisible: " + isVisible);

		var result: JSX.Element | undefined = undefined;

		if (isCompanyNotSubordinate && isVisible ) {
			result = <ItemDisplay
				isCompanyNotSubordinate={isCompanyNotSubordinate}
				onDelete={this.props.deleteProduct}
				isDeleting={this.props.isDeleting[product.id]}
				item={product}
				genericProductId={this.state.genericProductId}
				key={product.id}
				role={this.props.role}
				userInfo={this.props.userInfo}
				teams={!isInTeam ? this.state.teams : []}
			/>
		}
		else if (this.props.company && this.props.company.mainCompanyId !== null && isVisible && (mainGenericProd === undefined || product.id !== this.state.genericProductId) && (this.props.userInfo.userRole >= UserRole.Expert)) {
			result = <ItemDisplay
				isCompanyNotSubordinate={isCompanyNotSubordinate}
				onDelete={this.props.deleteProduct}
				isDeleting={this.props.isDeleting[product.id]}
				item={product}
				genericProductId={this.state.genericProductId}
				key={product.id}
				role={this.props.role}
				userInfo={this.props.userInfo}
				teams={!isInTeam ? this.state.teams : []}
			/>
		}

		return result;
	};
}

const mapStateToProps = (state: IRootState) => ({
	company: state.company.data.company,
	error: state.products.error,
	isDeleting: state.products.isDeleting,
	loading: state.products.loading,
	loadingTeams: state.users.loadingTeams,
	saving: state.products.saving,
	userInfo: state.userInfo.data,
	products: toArray(state.products.data),
	role: state.userInfo.data.userRole,
	teams: toArray(state.users.teamsData),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			createProduct: actions.createProduct,
			deleteProduct: actions.deleteProduct,
			getProducts: actions.getProducts,
			getMyCompany: companyActions.getMyCompany,
			getProductsByCompanyId: actions.getProductsByCompanyId,
			getGenericProduct: actions.getGenericProduct,
			getTroubleshootingItemsForProduct: troubleshootingActions.getTroubleshootingItemsForProduct,
			getTeams: userActions.getTeams,
			switchRole: userActions.switchUserRole,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export const Products = connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductsComponent);