import React from "react";
import { NavLink as RouterNavLink, NavLinkProps, RouteComponentProps, withRouter } from "react-router-dom";
import { Icon } from "../globalComponents";
import "./MobileNavLink.scss";

interface IOwnProps {
	iconName: string;
	text: string;
}

type IMobileNavLinkProps = NavLinkProps & IOwnProps;

const MobileNavLinkBase: React.FunctionComponent<IMobileNavLinkProps> = ({ iconName, to, text, ...rest }) => (
	<RouterNavLink {...rest} to={to} className="mobile-navlink">
		<Icon name={iconName} />
		<span className="mobile-navlink-text">{text}</span>
	</RouterNavLink>
);

export const MobileNavLink = MobileNavLinkBase;
