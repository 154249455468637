export const PRODUCT_PLACEHOLDER_URL = "/images/product-placeholder.gif";
export const MODEL_PLACEHOLDER_URL = "/images/model-placeholder.png";
export const VIDEO_PLACEHOLDER_URL = "/images/video-placeholder.png";
export const AR_INTRO_URL = "/images/ar.webp";
export const PDF_PLACEHOLDER_URL = "/images/pdf-placeholder.png";
export const BROWSER_WARNING = "holomaintenance_browser_warning";
export const DEV_USER_ROLE = "holomaintenance_dev_user_role";
export const SIGNALR_SERVER_TIMEOUT_MILLISECONDS = 120000;
export const SIGNALR_SERVER_KEEP_ALIVE_MILLISECONDS = 60000;
export const ANIMATION_LANGUAGES = ['it', 'en'];
export const REGEX_GUID_ID = '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$';
export const HD = "1280x720";
export const LQ = "320x240";
export const HQ = "640x480";