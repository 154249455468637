import React from "react";
import ReactDOM from "react-dom";
import { Toast } from "./toast.component";
import { IToastMessage } from "./toast.interfaces";
import "./toast.scss";

export interface IToastListProps {
	toasts: IToastMessage[];
	onDismiss: (id: number) => void;
}

export class ToastList extends React.Component<IToastListProps> {
	private containerEl: HTMLDivElement = document.createElement("div");

	public componentDidMount() {
		document.body.appendChild(this.containerEl);
	}

	public componentWillUnmount() {
		document.body.removeChild(this.containerEl);
	}

	public render() {
		const { toasts } = this.props;
		if (!toasts.length) {
			return null;
		}

		return ReactDOM.createPortal(this.renderToasts(), this.containerEl);
	}

	private renderToasts = () => (
		<div className="app-toast-container">
			{this.props.toasts.map(toast => (
				<Toast key={toast.id} onDismiss={() => this.props.onDismiss(toast.id)} toast={toast} />
			))}
		</div>
	);
}
