import React from "react";
import { T } from "../../managers/I18n";
import { classNames } from "../../utilities/class-names";
import { OriginalHtmlProps } from "../../utilities/html-props";
import { Icon } from "./Icon";
import "./IconTextButton.scss";
import { Spinner } from "./Spinner";

export type IconTextButtonProps = OriginalHtmlProps<HTMLButtonElement> & {
	iconName: string;
	showSpinner?: boolean;
	text: string;
	color: string;
};

export const IconTextButton: React.FunctionComponent<IconTextButtonProps> = ({
	iconName,
	className,
	showSpinner,
	text,
	color,
	disabled,
	...rest
}) => (
	<button className={classNames("icon-text-button", className, `btn btn-${color}`)} {...rest}>
		{showSpinner ? <Spinner /> : <Icon name={iconName} />} <T v={text} />
	</button>
);
