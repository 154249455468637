import { authProvider } from "../auth/AuthProvider";
import { IEvent, IEventBase, newEvent } from "../event/newEvent";
import { getCookie } from "../managers/I18n";
import { serverManager } from "../managers/ServerManager";
import { IRecording } from "../types";
import { logger } from "../utilities/logger";
import { getTimeString, timeout } from "./helpers";

export interface ISignInInfo {
	id: number;
	peers: string[];
}

export interface ISignalingClient {
	onmessage: IEventBase<ISignalEvent>;
	signIn(peerName: string): Promise<ISignInInfo>;
	relayMessage(msg: ISignalMessage): Promise<void>;

	// waiter(waitPromise: Promise<Response>, myId: number, waitString: string, onmessage: (msg: ISignalMessage) => void);
	// createPostMsg(msg: ISignalMessage): Request;
}

export interface ISignalMessage {
	localId: number;
	remoteId: number;
	message: string;
}

export function createSendFileMessage(localId: number, remoteId: number, fileId: string) {
	const msg: ISignalMessage = {
		localId,
		remoteId,
		message: "file:" + fileId,
	};

	return msg;
}

export interface ISignalingEndpoints {
	signIn: string;
	message: string;
	wait: string;
	startRecording: string;
	stopRecording: string;
	getRecording: string;
	deleteRecording: string;
	getMediaServerSecret: string;
}

export interface ISignalEvent extends IEvent {
	message: ISignalMessage;
	requestUrl: string;
}

export interface ISignalErrorEvent extends IEvent {
	error: Error;
}

export class SignalEvent implements ISignalEvent {
	public target: any;
	public message: ISignalMessage;
	public requestUrl: string;

	constructor(message: ISignalMessage, requestUrl: string, target: any) {
		this.message = message;
		this.target = target;
		this.requestUrl = requestUrl;
	}
}

// tslint:disable-next-line:max-classes-per-file
export class SignalMessage implements ISignalMessage {
	public localId: number;
	public remoteId: number;
	public message: string;

	constructor(localId: number, remoteId: number, message: string | any) {
		this.localId = localId;
		this.remoteId = remoteId;
		this.message = typeof message !== "string" ? JSON.stringify(message) : message;
	}
}

// tslint:disable-next-line:max-classes-per-file
export class SignalingHelper implements ISignalingClient {
	public static readonly NoAuthEndpoints: ISignalingEndpoints = {
		message: "message",
		signIn: "sign_in",
		wait: "wait",
		startRecording: "StartCallRecording",
		stopRecording: "StopCallRecording",
		deleteRecording: "DeleteCallRecording",
		getRecording: "GetRecordings",
		getMediaServerSecret: "GetMediaServerSecret",
	};

	public onerror = newEvent<ISignalErrorEvent>("error");
	public onmessage = newEvent<ISignalEvent>("message");
	public accessToken: string | undefined;
	public endpoints: ISignalingEndpoints = {
		message: SignalingHelper.NoAuthEndpoints.message,
		signIn: SignalingHelper.NoAuthEndpoints.signIn,
		wait: SignalingHelper.NoAuthEndpoints.wait,
		startRecording: SignalingHelper.NoAuthEndpoints.startRecording,
		stopRecording: SignalingHelper.NoAuthEndpoints.stopRecording,
		deleteRecording: SignalingHelper.NoAuthEndpoints.deleteRecording,
		getRecording: SignalingHelper.NoAuthEndpoints.getRecording,
		getMediaServerSecret: SignalingHelper.NoAuthEndpoints.getMediaServerSecret,
	};
	public baseAddress: string;

	private cancelLoop: boolean = false;

	constructor(baseAddress: string, accessToken?: string) {
		this.baseAddress = baseAddress;
		this.accessToken = accessToken;
		this.endpoints.message = this.baseAddress + this.endpoints.message;
		this.endpoints.signIn = this.baseAddress + this.endpoints.signIn;
		this.endpoints.wait = this.baseAddress + this.endpoints.wait;
		this.endpoints.startRecording = this.baseAddress + this.endpoints.startRecording;
		this.endpoints.stopRecording = this.baseAddress + this.endpoints.stopRecording;
		this.endpoints.getRecording = this.baseAddress + this.endpoints.getRecording;
		this.endpoints.deleteRecording = this.baseAddress + this.endpoints.deleteRecording;
		this.endpoints.getMediaServerSecret = this.baseAddress + this.endpoints.getMediaServerSecret;
	}

	public async signIn(peerName: string): Promise<ISignInInfo> {
		logger.debug(getTimeString(), this.endpoints.signIn);
		const response = await this.fetcher(this.endpoints.signIn + "?peer_name=" + peerName);
		logger.debug(getTimeString(), "Sign in response", response);
		const text = await response.text();
		logger.debug(getTimeString(), "Sign in data:\n", text);
		return {
			id: parseInt(response.headers.get("pragma") as string, 10),
			peers: text.split("\n"),
		};
	}

	public async startRecording(sessionId: string): Promise<IRecording> {
		const response = await this.fetcher(`${this.endpoints.startRecording}?sessionId=${sessionId}`);
		const textResponse = await response.text();
		var jsonRecording = JSON.parse(textResponse);
		var recording : IRecording = {
			id: jsonRecording['id'],
			name: jsonRecording['name'],
			outputMode: jsonRecording['outputMode'],
			resolution: jsonRecording['resolution'],
			recordingLayout: jsonRecording['recordingLayout'],
			sessionId: jsonRecording['sessionId'],
			createdAt: jsonRecording['createdAt'],
			size: jsonRecording['size'],
			duration: jsonRecording['duration'],
			url: jsonRecording['url'],
			hasAudio: jsonRecording['boolean'],
			hasVideo: jsonRecording['boolean'],
			status: jsonRecording['status'],
			isComplete: jsonRecording['isComplete']
		}
		return recording;
	}

	public async getMediaServerSecret(): Promise<string> {
		const response = await this.fetcher(this.endpoints.getMediaServerSecret);
		return response.text();
	}

	public async stopRecording(recordingId: string): Promise<IRecording> {
		const response = await this.fetcher(`${this.endpoints.stopRecording}?recordingId=${recordingId}`);
		const textResponse = await response.text();
		var jsonRecording = JSON.parse(textResponse);
		var recording : IRecording = {
			id: jsonRecording['id'],
			name: jsonRecording['name'],
			outputMode: jsonRecording['outputMode'],
			resolution: jsonRecording['resolution'],
			recordingLayout: jsonRecording['recordingLayout'],
			sessionId: jsonRecording['sessionId'],
			createdAt: jsonRecording['createdAt'],
			size: jsonRecording['size'],
			duration: jsonRecording['duration'],
			url: jsonRecording['url'],
			hasAudio: jsonRecording['boolean'],
			hasVideo: jsonRecording['boolean'],
			status: jsonRecording['status'],
			isComplete: jsonRecording['isComplete']
		}
		return recording;
	}

	public async deleteRecording(recordingId: string) {
		await this.fetcher(`${this.endpoints.deleteRecording}?recordingId=${recordingId}`);
	}

	public listen(myId: number): void {
		this.cancelLoop = false;
		const url = `${this.endpoints.wait}?peer_id=${myId}`;
		this.waiter(this.fetcher(url), myId, url);
	}

	public stopListen(): void {
		this.cancelLoop = true;
	}

	public relayMessage(msg: ISignalMessage): Promise<void>;
	public relayMessage(localId: number, remoteId: number, data: any): Promise<void>;
	public async relayMessage(msg: ISignalMessage | number, remoteId?: number, data?: any): Promise<void> {
		if (typeof msg === "number" && remoteId && data) {
			await this.fetcher(
				this.createPostMsg({
					localId: msg,
					remoteId,
					message: JSON.stringify(data),
				}),
			);
			return;
		} else if (typeof msg !== "number") {
			await this.fetcher(this.createPostMsg(msg));
			return;
		}
		// this.relayMessage.name;
		throw Error("Wrong or Missing arguments for postMsg");
	}

	private invokeError(e: Error) {
		if (this.onerror) {
			this.onerror({ error: e, target: this });
		}
	}

	private async waiter(waitPromise: Promise<Response>, myId: number, waitString: string): Promise<void> {
		// logger.debug("Started handling update");
		let response: Response | undefined;
		const last = new Date();
		if (this.cancelLoop) {
			return;
		}
		try {
			response = await waitPromise;
		} catch (e) {
			this.invokeError(e);
			console.error("Error accured", e);
			if (new Date().getTime() - last.getTime() < 2000) {
				await timeout(5000);
			}
			this.waiter(this.fetcher(waitString), myId, waitString);
			// if (e.message !== "NetworkError when attempting to fetch resource.")
			// return;
		}
		// logger.debug("wait response");
		if (!response) {
			this.invokeError(new Error("Could not communicate with the server"));
			return;
		}
		if (response.status === 504 || response.status === 502) {
			console.info("waiter timed out with status 504, retrying");
			this.waiter(this.fetcher(waitString), myId, waitString);
			return;
		}
		if (response.status !== 200) {
			console.error(response);
			this.invokeError(new Error("Status code from server was not 200"));
			return;
		}

		this.waiter(this.fetcher(waitString), myId, waitString);

		const remoteId: number = parseInt(response.headers.get("pragma") as string, 10);

		const textResponse = await response.text();
		this.onmessage(new SignalEvent({ localId: myId, remoteId, message: textResponse }, waitString, this));
	}

	private createPostMsg(msg: ISignalMessage): Request {
		return new Request(this.endpoints.message + "?peer_id=" + msg.localId + "&to=" + msg.remoteId, { method: "POST", body: msg.message });
	}

	private async fetcher(input: Request | string, init?: RequestInit): Promise<Response> {
		
		//user not logged in AD
		const newInit = init || {};
		newInit.headers = newInit.headers || {};

			//Temp user setup header
			getCookie("XBasicToken");
			const isTemporaryUser: boolean = getCookie("XBasicToken") != null;
			console.log("log test 1: " + isTemporaryUser );
			let token:any = "";

			if (isTemporaryUser)
			{
			token = getCookie("XBasicToken");
			console.log("log test 2:" + token);
			(newInit as any).headers["X-Base-Token"] = token;
			return fetch(input, newInit);
			}
		
		//user logged in AD
		var response = await authProvider.getAccessToken();
		var accessToken = response.accessToken;
		if (typeof input === "string") {
			const newInit = init || {};
			newInit.headers = newInit.headers || {};
			
			if (accessToken) {
				(newInit as any).headers["Authorization"] = "bearer " + accessToken;
			}
			return fetch(input, newInit);
		} else {
			if (accessToken) {
				input.headers.append("Authorization", "bearer " + accessToken);
			}
			return fetch(input, init);
		}


	}
}
