import moment from "moment";
import React from "react";
import Badge from "reactstrap/lib/Badge";
import { Msg, T } from "../../managers/I18n";
import { Id, ITicket, IUser, TicketStatus } from "../../types";
import { UserRole } from "../../types/IRoles";
import { OriginalHtmlProps } from "../../utilities/html-props";
import { logger } from "../../utilities/logger";

// import "./WorkLogItemRow.scss";

const getStatus = (status: number): string => {
	logger.info("Type is: ", status);
	switch (status) {
		case 0:
			return "TicketStatus.Open";
		case 1:
			return "TicketStatus.Working";
		case 2:
			return "TicketStatus.WaitingForFeedback";
		case 3:
			return "TicketStatus.Solved";
		case 4:
			return "TicketStatus.ClosedButNotSolved";
		case 5:
			return "TicketStatus.NeedFurtureActivity";
		case 6:
			return "TicketStatus.NoNeedToSolved";
		default:
			return "No Status";
	}
};

const getStatusBadgeColor = (status: number): string => {
	switch (status) {
		case 0:
			return "primary";
		case 1:
			return "primary";
		case 2:
			return "warning";
		case 3:
			return "secondary";
		case 4:
			return "primary";
		case 5:
			return "warning";
		case 6:
			return "primary";
		default:
			return "primary";
	}
};

export type FileItemRowProps = {
	userInfo: IUser;
	worklogItem: ITicket;
	index: number;
	goToWorkLog: (workLog: Id) => void;
	getTicketId: (worklogId: string) => void;
	getDeselectedTicketId: (worklogId: string) => void;
} 
& OriginalHtmlProps<HTMLDivElement>;

export const WorkLogItemRow: React.FunctionComponent<FileItemRowProps> = ({ userInfo, className, goToWorkLog, getTicketId, getDeselectedTicketId, worklogItem, index, ...rest }) => {
	if (!worklogItem) {
		return null;
	}

	return (
		<React.Fragment>
			{userInfo.userRole >= UserRole.Expert && <td className="hide-mobile"><input type="checkbox" onChange={(e) => e.target.checked ? getTicketId(worklogItem.id.toString()) : getDeselectedTicketId(worklogItem.id.toString())}/></td>}
			<td scope="row" onClick={() => goToWorkLog(worklogItem.id)}>{worklogItem.friendlyId}</td>
			<td onClick={() => goToWorkLog(worklogItem.id)}>
				<span>
					{moment
						.utc(worklogItem.start)
						.local()
						.format("LL")}
				</span>
				<p>
					{moment
						.utc(worklogItem.start)
						.local()
						.format("LT")}
				</p>
			</td>
			<td className="hide-mobile" onClick={() => goToWorkLog(worklogItem.id)}>{worklogItem.operatorName}</td>
			<td className="hide-mobile" onClick={() => goToWorkLog(worklogItem.id)}>{worklogItem.expertName ? worklogItem.expertName : "Tom Jones"}</td>
			<td onClick={() => goToWorkLog(worklogItem.id)}>
				<Badge color={getStatusBadgeColor(worklogItem.status)}>
					<T v={getStatus(worklogItem.status)} />
				</Badge>
			</td>
			<td className="hide-mobile" onClick={() => goToWorkLog(worklogItem.id)}>{worklogItem.description.substring(0, 10)}</td>
		</React.Fragment>
	);
};
