import { Subscriber } from "openvidu-browser";
import { call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { logger } from "../../utilities/logger";
import { IConnectedSession } from "../../WebRTC/RTCHelpers";
import { OVManager } from "../../WebRTC/RTCManager";
import { actions } from "./ov.actions";

export function* handleConnectUser(action: ReturnType<typeof actions.connectUser>) {
	try {
		const data: IConnectedSession = yield call(OVManager.connectUser, action.payload);
		yield put(actions.connectUserSuccess(data));
		logger.debug("Connected to user successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.connectUserError(err));
	}
}

export function* handleRemoveHololensStream(action: ReturnType<typeof actions.removeHololensStream>) {
	try {
		const data: Subscriber[] = yield call(OVManager.removeStream, action.payload);
		yield put(actions.removeHololensStreamSuccess(data));
		logger.debug("Stream removed successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.removeHololensStreamError(err));
	}
}
export function* handleRemoveParticipantStream(action: ReturnType<typeof actions.removeParticipantStream>) {
	try {
		const data: Subscriber[] = yield call(OVManager.removeStream, action.payload);
		yield put(actions.removeParticipantStreamSuccess(data));
		logger.debug("Stream removed successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.removeParticipantStreamError(err));
	}
}
export function* handleRemoveRemoteExpertStream(action: ReturnType<typeof actions.removeRemoteExpertStream>) {
	try {
		const data: Subscriber[] = yield call(OVManager.removeStream, action.payload);
		yield put(actions.removeRemoteExpertStreamSuccess(data));
		logger.debug("Stream removed successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.removeRemoteExpertStreamError(err));
	}
}

export function* ovSaga() {
	yield takeLatest(getType(actions.connectUser), handleConnectUser);
	yield takeLatest(getType(actions.removeHololensStream), handleRemoveHololensStream);
	yield takeLatest(getType(actions.removeParticipantStream), handleRemoveParticipantStream);
	yield takeLatest(getType(actions.removeRemoteExpertStream), handleRemoveRemoteExpertStream);
}
