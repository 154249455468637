export const getFileIdFromPathName = (path: string) => {
	const value = getIdBySegment(path, 'files');

	return value ? value : undefined;
};

export const getTicketIdFromPath = (path: string) => {
	return getIdBySegment(path, 'ticketcall');
};

export const getProductIdFromPathName = (path: string) => {
	return getIdBySegment(path, 'product');
};

export const isCallPage = (path: string) => {
	return existSegment(path, 'call', PositionSegment.End);
}

export const isProductPage = (path: string) => {
	return existSegment(path, 'product', PositionSegment.Start);
};

export const isTroubleshootingPage = (path: string) => {
	return existSegment(path, 'troubleshooting', PositionSegment.Contains);
};

export const isDocumentPage = (path: string) => {
	return existSegment(path, 'files', PositionSegment.Contains);
};

export const isCallLogPage = (path: string) => {
	return existSegment(path, 'received', PositionSegment.End);
};

export const isCallLogItemPage = (path: string) => {
	return existSegment(path, 'ticket', PositionSegment.Contains);
};


const existSegment = (path: string, segment: string, pos: PositionSegment) => {
	switch (pos) {
		case PositionSegment.Start:
			{
				return path.startsWith(`/${segment}`);
			}
		case PositionSegment.End:
			{
				return path.endsWith(`/${segment}`);
			}
		case PositionSegment.Contains:
			{
				const pathArray = path.split("/");
				return pathArray.includes(segment);
			}
		default:
			return false;
	}
}


const getIdBySegment = (path: string, segment: string) => {
	const pathArray = path.split("/");
	let ticketId = "";

	if (pathArray.includes(segment)) {
		const idx = pathArray.indexOf(segment);
		ticketId = pathArray[idx + 1];
	}

	return ticketId;
}


enum PositionSegment {
	Start,
	Contains,
	End
}