import { IFile, IProduct, IQueueUser, ITicket, ITicketItem, ITroubleshootingItem, TicketItemType } from "../types";
import { ITroubleshootingLanguage } from "../types/ITroubleshootingLanguage";
import { ITroubleshootingFlatNode, ITroubleshootingNode, NodeType } from "../types/ITroubleshootingNode";

export const dummyTroubleshootingLanguages: ITroubleshootingLanguage[] = [
	{
		id: "en",
		name: "English",
	},
	{
		id: "it",
		name: "Italian",
	},
];

export const mockTextObject = {
	en: ["test string"],
	it: ["test streng"],
};

export const answerMockTextObject = {
	en: ["test string", "another test"],
	it: ["test string italianos", "porque"],
};

export const flatTroubleshootingMockData: ITroubleshootingFlatNode[] = [
	{
		id: 1,
		text: mockTextObject,
		type: NodeType.QUESTION,
		parentId: null,
	},
	{
		id: 2,
		parentId: 1,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 3,
		parentId: 2,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 4313,
		parentId: 3,
		text: answerMockTextObject,
		type: NodeType.ANSWER,
	},
	{
		id: 356,
		parentId: 2,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 431431,
		parentId: 356,
		text: answerMockTextObject,
		type: NodeType.ANSWER,
	},
	{
		id: 43,
		parentId: 1,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 74,
		parentId: 43,
		text: answerMockTextObject,
		type: NodeType.ANSWER,
	},
	{
		id: 4,
		parentId: null,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 61231,
		parentId: 4,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 523,
		parentId: 61231,
		text: answerMockTextObject,
		type: NodeType.ANSWER,
	},
	{
		id: 6,
		parentId: 4,
		text: mockTextObject,
		type: NodeType.QUESTION,
	},
	{
		id: 5,
		parentId: 6,
		text: answerMockTextObject,
		type: NodeType.ANSWER,
	},
];

export const troubleshootingMockData: ITroubleshootingNode[] = [
	{
		id: 1,
		text: mockTextObject,
		type: NodeType.QUESTION,
		children: [
			{
				children: [
					{
						id: 3,
						text: mockTextObject,
						type: NodeType.QUESTION,
						children: [
							{
								id: 4313,
								text: answerMockTextObject,
								type: NodeType.ANSWER,
								children: [],
							},
						],
					},
					{
						id: 356,
						text: mockTextObject,
						type: NodeType.QUESTION,
						children: [
							{
								id: 431431,
								text: answerMockTextObject,
								type: NodeType.ANSWER,
								children: [],
							},
						],
					},
				],
				id: 2,
				text: mockTextObject,
				type: NodeType.QUESTION,
			},
			{
				children: [
					{
						id: 74,
						text: answerMockTextObject,
						type: NodeType.ANSWER,
						children: [],
					},
				],
				id: 43,
				text: mockTextObject,
				type: NodeType.QUESTION,
			},
		],
	},
	{
		id: 4,
		text: mockTextObject,
		type: NodeType.QUESTION,
		children: [
			{
				children: [
					{
						id: 5,
						text: answerMockTextObject,
						type: NodeType.ANSWER,
						children: [],
					},
				],
				id: 6,
				text: mockTextObject,
				type: NodeType.QUESTION,
			},
			{
				children: [
					{
						id: 523,
						text: answerMockTextObject,
						type: NodeType.ANSWER,
						children: [],
					},
				],
				id: 61231,
				text: mockTextObject,
				type: NodeType.QUESTION,
			},
		],
	},
];

export const dummyTicket: ITicket = {
	id: 1,
	friendlyId: 2,
	ticketId: 9,
	expertName: "Tom Jones",
	operatorName: "Tom Jones",
	companyName: "Jones Inc",
	productName: "Hattifnattene",
	productCode: "cup-001",
	description: "The handle is broken an it needs fiksing. what type of glu is needed? ",
	status: 3,
	start: new Date().toDateString(),
	end: new Date().toString(),
	ticketEvent: [
		{
			id: 1,
			type: TicketItemType.Login,
			date: new Date().toDateString(),
			url: "",
			data: "",
			linkedObject: {},
		},
		{
			id: 2,
			type: TicketItemType.FileSent,
			date: new Date().toDateString(),
			url: "https://bakerenogkokken.no/20.0.0.3/34682/cache/34682_07e1201bdd104ef65315d310f131e45a.jpg",
			data: "",
			linkedObject: {},
		},
		{
			id: 3,
			type: TicketItemType.AnnotationSent,
			date: new Date().toDateString(),
			url: "https://d242fdlp0qlcia.cloudfront.net/uploads/2015/09/01222545/italics.png",
			data: "",
			linkedObject: {},
		},
		{
			id: 4,
			type: TicketItemType.VideoCapturedStarted,
			date: new Date().toDateString(),
			url: "https://www.bensound.org/bensound-music/bensound-betterdays.mp3",
			data: "",
			linkedObject: {},
		},
		{
			id: 5,
			type: TicketItemType.VideoCapturedEnded,
			date: new Date().toDateString(),
			url: "https://download.blender.org/peach/bigbuckbunny_movies/big_buck_bunny_720p_h264.mov",
			data: "",
			linkedObject: {},
		},
		{
			id: 6,
			type: TicketItemType.ImageCaptured,
			date: new Date().toDateString(),
			url: "",
			data: "",
			linkedObject: {},
		},
		{
			id: 7,
			type: TicketItemType.FileSent,
			date: new Date().toDateString(),
			url: "http://www.pdf995.com/samples/pdf.pdf",
			data: "",
			linkedObject: {},
		},
		{
			id: 8,
			type: TicketItemType.SessionEnded,
			date: new Date().toDateString(),
			url: "",
			data: "",
			linkedObject: {},
		},
	],
};

export const dummyTroubleshootingItems: ITroubleshootingItem[] = [
	{
		id: "12abc",
		text: "Power issues",
		productId: "1",
	},
	{
		id: "abc12",
		text: "Display not working",
		productId: "1",
	},
	{
		id: "12df3ee",
		text: "Issues with sound",
		productId: "1",
	},
];

export const dummyProduct: IProduct = {
	id: "some-unique-id",
	shortName: "Item Short Name",
	name: "Item Name",
	companyId: "some-company-id",
	thumbnailUrl: "https://via.placeholder.com/300x200",
	companyName: "Fractal Inc",
	created: new Date().toISOString(),
	lastEdited: new Date().toISOString(),
	reference: [],
	isEmpty: false,
	// tslint:disable:max-line-length
	description: `Lorem Ispum is a choke artist. It chokes! The concept of Lorem Ipsum was created by and for the Chinese in order to make U.S. design jobs non-competitive. An ‘extremely credible source’ has called my office and told me that Barack Obama’s placeholder text is a fraud.

				The concept of Lorem Ipsum was created by and for the Chinese in order to make U.S. design jobs non-competitive. Lorem Ispum is a choke artist. It chokes!

				I’m the best thing that ever happened to placeholder text.

				We are going to make placeholder text great again. Greater than ever before. I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can't do it. I just can't do it. It's inappropriate. It's not nice." I think my strongest asset maybe by far is my temperament. I have a placeholding temperament.
			`,
	editedById: "some-editor-id",
	editedByName: "Stian Bakken",
	productCode: "Lo3458384",
};

export const dummyFiles: IFile[] = [
	{
		id: "some-id",
		name: "Blue Flower.jpg",
		realFileUri: "https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg",
		thumbnailUrl: "https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg",
		fileUri: "image://some-id",
		official: true,
		bytes: 1234,
		createdDate: new Date().toISOString(),
		isVisibleToTemporaryUser: true,
		hidden: false,
	},
	{
		id: "some-other-id",
		name: "Brown Wodden Dock.jpg",
		realFileUri: "https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg",
		thumbnailUrl: "https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg",
		fileUri: "image://some-other-id",
		official: true,
		createdDate: new Date(2019, 1, 2).toISOString(),
		bytes: 3214,
		isVisibleToTemporaryUser: true,
		hidden: false,
	},
];

export const ticket: ITicket = {
	id: 2,
	ticketId: 1,
	friendlyId: 1,
	expertName: "Tom Jones",
	companyName: "Boruvet",
	operatorName: "Chris",
	start: new Date().toString(),
	end: new Date().toString(),
	status: 5,
	productCode: "001",
	productName: "Hattifnattene",
	description: "tiket description",
	ticketEvent: [
		{
			id: 2,
			type: TicketItemType.Login,
			date: new Date().toString(),
			url: "url",
			data: "",
		},
	],
};

const ticket1: ITicket = {
	id: 3,
	ticketId: 2,
	friendlyId: 3,
	expertName: "Tom Jones",
	companyName: "Hevolus",
	operatorName: "Francesco Greco",
	start: new Date().toString(),
	end: new Date().toString(),
	status: 2,
	productCode: "001",
	productName: "Hattifnattene",
	description: "tiket description",
	ticketEvent: [
		{
			id: 2,
			type: TicketItemType.Login,
			date: new Date().toString(),
			url: "url",
			data: "",
		},
	],
};

const ticket2: ITicket = {
	id: 4,
	friendlyId: 4,
	ticketId: 3,
	expertName: "Tom Jones",
	companyName: "Bouvet",
	operatorName: "Martin Helvig",
	start: new Date().toString(),
	end: new Date().toString(),
	status: 1,
	productCode: "001",
	productName: "Hattifnattene",
	description: "tiket description",
	ticketEvent: [
		{
			id: 2,
			type: TicketItemType.Login,
			date: new Date().toString(),
			url: "url",
			data: "",
		},
	],
};

export const dummyTickets: ITicket[] = [dummyTicket, ticket, ticket2, ticket1];

export const ticketEvent: ITicketItem[] = [
	{
		id: 1,
		type: TicketItemType.Login,
		date: new Date().toDateString(),
		url: "",
		data: "",
		linkedObject: {},
	},
];

export const firstTicketEvent: ITicketItem[] = [
	{
		id: 1,
		type: TicketItemType.TicketCreatedSuccess,
		date: new Date().toDateString(),
		url: "",
		data: "",
		linkedObject: {},
	},
];

export const dummyQueueUsers: IQueueUser[] = [
	{
		peerId: 3,
		enqueuedAt: new Date().toISOString(),
		productId: "productId1",
		productName: "ProductName",
		userId: "user-id-1",
		userName: "UserName",
		events: JSON.stringify(ticketEvent),
		roomId: "DEMO2019-GUID-4396-aad6-e86c9f84d372",
		readyStatus: 0,
		teamId: null,
		userAgent: "",
	},
	{
		peerId: 4,
		enqueuedAt: new Date().toISOString(),
		productId: "productId2",
		productName: "ProductName",
		userId: "user-id-2",
		userName: "UserName",
		events: JSON.stringify(ticketEvent),
		roomId: "DEMO2019-GUID-4396-aad6-e86c9f84d372",
		readyStatus: 0,
		teamId: null,
		userAgent: "",
	},
];
