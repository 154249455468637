import React, { useEffect, useState } from "react";

// Component Import
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../reducers";
import { actions as companyActions } from "../../reducers/company";
import { GetLangString, T } from "../../managers/I18n";
import { IFile, ITicketItem, IUser, TicketItemType, IClips, ICompany } from "../../types";
import { UserRole } from "../../types/IRoles";
import { classNames } from "../../utilities";
import { Icon } from "../globalComponents";
import {
	handle3dModel,
	handleImageFile,
	handleVideoFile,
} from "../globalComponents/MobileFileHelper";
import TicketItemAction from "./TicketItemAction";
import TicketItemMedia from "./TicketItemMedia";
import TicketItemMediaMobile from "./TicketItemMediaMobile";
import "./TicketTimeline.scss";
import { actions } from "../../reducers/files";
import { FbxHandler } from "../globalComponents/FbxHandler";
import ReactDOM from "react-dom";
import { PdfHandler } from "../globalComponents/PdfHandler";

interface IProp {
	company: ICompany;
	ticketEntries: ITicketItem[];
	userInfo: IUser;
}

function getTicketItem(item: ITicketItem, index: number): JSX.Element {
	switch (item.type) {
		case TicketItemType.Login:
		case TicketItemType.AnswerCall:
		case TicketItemType.TroubleshootingTreeClose:
		case TicketItemType.TroubleshootingTreeOpen:
		case TicketItemType.TroubleshootingTreeReset:
		case TicketItemType.EndedCall:
		case TicketItemType.Enqueue:
		case TicketItemType.VideoCapturedStarted:
		case TicketItemType.SessionEnded:
		case TicketItemType.AnnotationDeleted:
		case TicketItemType.ProductDownload:
		case TicketItemType.ProductChanged:
		case TicketItemType.ModelOpen:
		case TicketItemType.ParticipantJoin:
		case TicketItemType.ProductListDownload:
		case TicketItemType.TicketStatusChanged:
			return <TicketItemAction key={index} item={item} />;
		case TicketItemType.ModelPosition:
		case TicketItemType.ImageCaptured:
		case TicketItemType.AnnotationSent:
		case TicketItemType.VideoCapturedEnded:
		case TicketItemType.FileOpen:
		case TicketItemType.FileSent:
		case TicketItemType.DocumentUploaded:
			return <TicketItemMedia key={index} item={item} />;

		default:
			return <TicketItemAction key={index} item={item} />;
	}
}

function getTicketItemOperator(
	item: ITicketItem,
	index: number,
	handleModal: (file: IFile) => Promise<void | undefined>
): JSX.Element {
	if (item.type === TicketItemType.FileSent) {
		return (
			<TicketItemMediaMobile
				key={index}
				item={item}
				handleModal={handleModal}
			/>
		);
	} else if (item.type === TicketItemType.AnnotationSent) {
		return (
			<TicketItemMediaMobile
				key={index}
				item={item}
				handleModal={handleModal}
			/>
		);
	} else {
		return <h1></h1>;
	}
}
const TicketTimeline: React.FunctionComponent<IProp> = (prop: IProp) => {
	const [modalEnabled, setModalEnabled] = useState<boolean>(false);

	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			setModalEnabled(false);
		};
	}, []);

	const handleModal = async (modalFile: IFile) => {
		const type = modalFile.name.split(".").pop();
		let model: any;
		switch (type) {
			case "glb":
			case "gltf":
				model = handle3dModel(`${process.env.REACT_APP_LOCALHOST}${modalFile.realFileUri}`);
				addElementToModal(model);
				break;
			case "fbx":
				dispatch(actions.getFileClipsData(modalFile.id, (clipsData: IClips | null) =>
				{
					if (clipsData == null) {
						clipsData = {
							id: modalFile.id,
							clipsData: [],
						}
					}
					dispatch(companyActions.getMyCompany());
					const divCurrentModel = document.createElement('div');
					divCurrentModel.id = "current-model";
					ReactDOM.render(<FbxHandler uri={`${process.env.REACT_APP_LOCALHOST}${modalFile.realFileUri}`} clips={clipsData} allowArFunctions={true} activeCall={false} positioningModel={false} />, divCurrentModel);
					//model = handleFbxModel(file.realFileUri, clipsData, company.allowArFunctions);
					addElementToModal(divCurrentModel);addElementToModal(model);
				}));
				break;
			case "png":
			case "jpg":
				model = handleImageFile(`${process.env.REACT_APP_LOCALHOST}${modalFile.realFileUri}`);
				addElementToModal(model);
				break;
			case "pdf":
				const divCurrentModel = document.createElement('div');
				divCurrentModel.id = "current-model-2";
				ReactDOM.render(<PdfHandler file={modalFile}/>, divCurrentModel);
				addElementToModal(divCurrentModel);
				break;
			case "mp4":
				const video = await handleVideoFile(modalFile);
				addElementToModal(video);
				break;
			default:
				break;
		}
	};

	const addElementToModal = (elem: any) => {
		const modalWindow = document.getElementById("modal-window");
		if (modalWindow) {
			const oldElem = modalWindow.querySelector("#current-model-2");
			if (oldElem) {
				modalWindow.replaceChild(elem, oldElem);
			} else {
				modalWindow.appendChild(elem);
			}
		}
		setModalEnabled(true);
	};
	const closeModalWindow = () => {
		setModalEnabled(false);
		const element = document.getElementById("current-model-2");
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
	};

	const getModalContainerClassNames = () => {
		const base = "modal-doc-container";
		const hidden = modalEnabled ? "" : "hidden";
		return classNames(base, hidden);
	};

	return (
		<React.Fragment>
			{prop.userInfo.userRole !== UserRole.Operator ? (
				<div className="timeline-title mt-3">
					<h5>
						<T v="Ticket.Timeline" />
					</h5>
				</div>
			) : null}

			{
				prop.userInfo.userRole !== UserRole.Operator ? (
					<div className="timeline-title mt-3">
						<div className="timeline">
							{prop.ticketEntries.map(
								(item: ITicketItem, index: number) =>
									getTicketItem(item, index)
							)}
						</div>
					</div>
				) : prop.ticketEntries.find(x => x.type === TicketItemType.FileSent || x.type === TicketItemType.AnnotationSent) !== undefined ? (
					<div className="timeline-title mt-3">
						<div className="timeline">
							{prop.ticketEntries.map(
								(item: ITicketItem, index: number) =>
									getTicketItemOperator(item, index, handleModal)
							)}
						</div>
					</div>
				) : <div className="timeline-title mt-5 text-center">
						<span>{GetLangString("NavLink.NoFileReceived")}</span>
					</div>
			}
			<div className={getModalContainerClassNames()} id="modal-window">
				<button
					style={{ height: "32px", width: "32px" }}
					onClick={closeModalWindow}
				>
					<Icon name="close" />
				</button>
			</div>
			{<div style={{ height: "60px", color: "$primary" }} />}
		</React.Fragment>
	);
};

const mapStateToProps = (state: IRootState) => ({ 
	company: state.company.data.company
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getMyCompany: companyActions.getMyCompany,
			getClipsData: actions.getFileClipsData,
		},
		dispatch
	);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(TicketTimeline);
