import { resolve } from "dns";
import { ActionType, createAction } from "typesafe-actions";
import { ToastType } from "../../components/toast";
import { IUser, IUserCreate, IProduct, Id } from "../../types";
import { ITeam } from "../../types/ITeam";

export const actions = {
	getUsersByCompany: createAction("data/users/getAllByCompany", resolve => (companyId: string) => resolve(companyId)),
	getUsers: createAction("data/users/getAll"),
	getUsersByTeam: createAction("data/users/getAllByTeam", resolve => (teamId: string) => resolve(teamId)),
	cleanUsers: createAction("data/users/cleanAll"),
	getUsersError: createAction("data/users/getAllError", resolve => (err: string) => resolve(err)),
	getUsersSuccess: createAction("data/users/getAllSuccess", resolve => (users: IUser[]) => resolve(users)),

	createUser: createAction("data/user/create", resolve => (dto: IUserCreate, callback?: (message: string, valid: boolean) => void) =>
		resolve({ dto, callback }),
	),
	createUserError: createAction("data/user/createError", resolve => (err: string) => resolve(err)),
	createUserSuccess: createAction("data/user/createSuccess", resolve => (data: IUser) => resolve(data)),

	createTempUser: createAction("data/user/createTemp", resolve => (callback?: (userCreated: IUser | null, message: string, type: ToastType) => void) =>
		resolve({ callback }),
	),
	createTempUserError: createAction("data/user/createTempError", resolve => (err: string) => resolve(err)),
	createTempUserSuccess: createAction("data/user/createTempSuccess", resolve => (data: IUser) => resolve(data)),

	getBearerToken: createAction("data/user/getBearerToken", resolve => (userId: string, productId: string, redirectedSection: string, callback?: (link: string, message: string, type: ToastType) => void) =>
		resolve({ userId, productId, redirectedSection, callback }),
	),
	getBearerTokenError: createAction("data/user/getBearerTokenError", resolve => (err: string) => resolve(err)),
	getBearerTokenSuccess: createAction("data/user/getBearerTokenSuccess", resolve => (data: string) => resolve(data)),

	getUserDuration: createAction("data/user/getUserDuration", resolve => (userId: string, callback?: (duration: string) => void) =>
		resolve({ userId, callback }),
	),
	getUserDurationError: createAction("data/user/getUserDurationError", resolve => (err: string) => resolve(err)),
	getUserDurationSuccess: createAction("data/user/getUserDurationSuccess", resolve => (data: string) => resolve(data)),

	moveToTeam: createAction("data/user/moveToTeam", resolve => (teamId: Id | null, userId: Id, isFromNone: boolean) => resolve({ teamId, userId, isFromNone })),
	moveToTeamSuccess: createAction("data/user/moveToTeamSuccess", resolve => (user: IUser) => resolve(user)),
	moveToTeamError: createAction("data/user/moveToTeamError", resolve => (error: string) => resolve(error)),

	getUserCreationDate: createAction("data/user/getUserCreationDate", resolve => (userId: string, callback?: (dates: string) => void) =>
		resolve({ userId, callback }),
	),
	getUserCreationDateError: createAction("data/user/getUserCreationDateError", resolve => (err: string) => resolve(err)),
	getUserCreationDateSuccess: createAction("data/user/getUserCreationDateSuccess", resolve => (data: string) => resolve(data)),

	blockUser: createAction("data/user/blockUser", resolve => (userId: string, callback?: (message: string, type: ToastType) => void) =>
		resolve({ userId, callback }),
	),
	blockUserSuccess: createAction("data/user/blockSuccess", resolve => (userId: string) => resolve(userId)),
	blockUserError: createAction("data/user/blockError", resolve => (err: string) => resolve(err)),

	unblockUser: createAction("data/user/unblockUser", resolve => (userId: string, callback?: (message: string, type: ToastType) => void) =>
		resolve({ userId, callback }),
	),
	unblockUserSuccess: createAction("data/user/unblockSuccess", resolve => (userId: string) => resolve(userId)),
	unblockUserError: createAction("data/user/unblockError", resolve => (err: string) => resolve(err)),

	deleteUser: createAction("data/user/delete", resolve => (userId: string, callback?: (message: string, type: ToastType) => void) =>
		resolve({ userId, callback }),
	),
	deleteUserSuccess: createAction("data/user/deleteSuccess", resolve => (userId: string) => resolve(userId)),
	deleteUserError: createAction("data/user/deleteError", resolve => (err: string) => resolve(err)),

	getSingleUser: createAction("data/users/getSingle", resolve => (userId: string) => resolve(userId)),
	getSingleUserMissing: createAction("data/user/getSingleMissing"),
	getSingleUserError: createAction("data/user/getSingleError", resolve => (error: string) => resolve(error)),
	getSingleUserSuccess: createAction("data/user/getSingleSuccess", resolve => (users: IUser) => resolve(users)),

	updateUser: createAction("data/user/update", resolve => (dto: IUser, callback?: (message: string, type: ToastType) => void) => resolve({ dto, callback })),
	updateUserSuccess: createAction("data/user/updateSuccess", resolve => (data: IUser) => resolve(data)),
	updateUserError: createAction("data/user/updaterror", resolve => (err: string) => resolve(err)),

	resetUserPsw: createAction("data/user/resetPassword", resolve => (userId: string, callback?: (message: string, type: ToastType) => void) => resolve({ userId, callback })),
	resetUserPswSuccess: createAction("data/user/resetPasswordSuccess", resolve => (userId: string) => resolve(userId)),
	resetUserPswError: createAction("data/user/resetPasswordError", resolve => (err: string) => resolve(err)),

	getTeamsByCompany: createAction("data/users/getAllTeamsByCompany", resolve => (companyId: string) => resolve(companyId)),
	getTeams: createAction("data/users/getAllTeams"),
	cleanTeams: createAction("data/users/cleanAllTeams"),
	getTeamsError: createAction("data/users/getAllTeamsError", resolve => (err: string) => resolve(err)),
	getTeamsSuccess: createAction("data/users/getAllTeamsSuccess", resolve => (teams: ITeam[]) => resolve(teams)),

	createTeam: createAction("data/user/createTeam", resolve => (dto: ITeam, callback?: (message: string, type: ToastType) => void) =>
		resolve({ dto, callback }),
	),
	createTeamError: createAction("data/user/createTeamError", resolve => (err: string) => resolve(err)),
	createTeamSuccess: createAction("data/user/createTeamSuccess", resolve => (data: ITeam) => resolve(data)),

	updateTeam: createAction("data/user/updateTeam", resolve => (dto: ITeam, callback?: (message: string, type: ToastType) => void) => resolve({ dto, callback })),
	updateTeamSuccess: createAction("data/user/updateTeamSuccess", resolve => (data: ITeam) => resolve(data)),
	updateTeamError: createAction("data/user/updateTeamError", resolve => (err: string) => resolve(err)),

	deleteTeam: createAction("data/user/deleteTeam", resolve => (teamId: string, callback?: (message: string, type: ToastType) => void) =>
		resolve({ teamId, callback }),
	),
	deleteTeamSuccess: createAction("data/user/deleteTeamSuccess", resolve => (userId: string) => resolve(userId)),
	deleteTeamError: createAction("data/user/deleteTeamError", resolve => (err: string) => resolve(err)),


	switchUserRole: createAction("data/user/switchUserRole", resolve => (callback?: () => void) => resolve({ callback })),
	switchUserRoleError: createAction("data/user/switchUserRoleError", resolve => (err: string) => resolve(err)),
	
	userNotificationExpired: createAction("data/user/userNotificationExpired", resolve => () => resolve()),



	addTeamReference: createAction("data/user/addTeamReference", resolve => (teamId: string, productId: string, callback?: () => void) =>
		resolve({ teamId, productId, callback })),
	addTeamReferenceSuccess: createAction("data/user/addTeamReferenceSuccess", resolve => (teamId: string, productId: string) =>
		resolve({ teamId, productId }),
	),
	addTeamReferenceError: createAction("data/user/addTeamReferenceError", resolve => (err: string) => resolve(err)),
	removeTeamReference: createAction("data/user/removeTeamReference", resolve => (teamId: string, productId: string, callback?: () => void) =>
		resolve({ teamId, productId, callback })),
	removeTeamReferenceSuccess: createAction("data/user/removeTeamReferenceSuccess", resolve => (teamId: string, productId: string) =>
		resolve({ teamId, productId }),
	),
	removeTeamReferenceError: createAction("data/user/removeTeamReferenceError", resolve => (err: string) => resolve(err)),

	getProductsByTeam: createAction("data/user/getProductsByTeam", resolve => (productId: string) => resolve(productId)),
	getProductsByTeamError: createAction("data/user/getProductsByTeamError", resolve => (err: string) => resolve(err)),
	getProductsByTeamSuccess: createAction("data/user/getProductsByTeamSuccess", resolve => (data: IProduct[]) => resolve(data)),
};

export type Action = ActionType<typeof actions>;
