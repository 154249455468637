import React, { useEffect } from "react";
import { Msg, T, GetLangString } from "../../managers/I18n";
import { IFile, ITicketItem, TicketItemType } from "../../types";
import { logger } from "../../utilities/logger";
import { isModalView } from "../documentation/DocumentationDetails"
import { delay } from "redux-saga/effects";
import { MODEL_PLACEHOLDER_URL, PDF_PLACEHOLDER_URL } from "../../constants";

export const getMedia = (item: ITicketItem, handleModal?: (file: IFile) => void) => {
	logger.info(item);
	switch (item.type) {
		case TicketItemType.VideoCapturedEnded: 
			return getVideoItem(item);
		case TicketItemType.ModelOpen:
			return getModel(item);
		case TicketItemType.OperatorPosition:
			return getPositionMap(item);
		case TicketItemType.AnnotationSent:
			return getDocument(item, handleModal!);
		case TicketItemType.FileOpen:
			return getDocument(item, handleModal!);
		case TicketItemType.FileSent:
			return getDocument(item, handleModal!);
		case TicketItemType.DocumentUploaded:
			return getDocument(item, handleModal!);
		case TicketItemType.ModelPosition:
			return getDocument(item, handleModal!);
		case TicketItemType.ImageCaptured:
			return getDocument(item, handleModal!);
		default:
			return null;
	}
};

export const getPositionMap = (item: ITicketItem) : JSX.Element => {
	return (
		<img src={`${item.data}`}>
			<source src={`${item.data}`} />
		</img>
	);
};

export const getDocument = (item: ITicketItem, handleModal: (file: IFile) => void): JSX.Element => {
	let uri = "";

	const file = item.linkedObject as IFile;

	uri = `${process.env.REACT_APP_LOCALHOST}${file.thumbnailUrl!}`;

	if (file !== undefined && file.thumbnailUrl !== undefined && file.thumbnailUrl !== null && file.thumbnailUrl.includes("model"))
		uri = MODEL_PLACEHOLDER_URL;

	if (file !== undefined && file.thumbnailUrl !== undefined && file.thumbnailUrl !== null && file.thumbnailUrl.includes("pdf"))
		uri = PDF_PLACEHOLDER_URL;

	const modalView = isModalView(file);

	if (modalView) {
		return (
			<div>
				<Msg id="Ticket.DocumentSentDescription">{val => <img src={uri} alt={val} className="img-thumbnail" />}</Msg>
			</div>
		);
	} else {
		return (
			<a href={`${process.env.REACT_APP_LOCALHOST}${(item.linkedObject as IFile).realFileUri}`}>
				<Msg id="Ticket.DocumentSentDescription">{val => <img src={uri} alt={val} className="img-thumbnail" />}</Msg>
			</a>
		);
	}
};

export const getModel = (item: ITicketItem): JSX.Element => {
	return (
		<a href={`${(item.linkedObject as IFile).realFileUri}`}>
			file model
			{/* <Msg id="Ticket.DocumentSentDescription">{val => <img src={"api/blob/" + item.data} alt={val} className="img-thumbnail" />}</Msg> */}
		</a>
	);
};

export const getModelPositionImage = (item: ITicketItem) => {
	
	var file: IFile = item.linkedObject as IFile;
	var uri: string = file.thumbnailUrl as string;

	return (
		<div>
			<img src={uri} />
		</div>
	);
}

const getVideoItem = (item: ITicketItem) => {
	const [uri, setValue] = React.useState<string>('');
	if (!item.linkedObject) {
		return (
			<h5>
				<T v="Ticket.NoVideo" />
			</h5>
		);
	}
	useEffect(() => {
		async function getUri() {
			var response = await fetch(`${process.env.REACT_APP_LOCALHOST}` + (item.linkedObject as IFile).realFileUri);
			var uri = await response.text();
			setValue(`${process.env.REACT_APP_LOCALHOST}` + (item.linkedObject as IFile).realFileUri);
		}
		getUri();
	}, []);

	return (
		<React.Fragment>
			<p>
				<small className="label">
					<T v="Ticket.VideoCaptureDescription" />
				</small>
			</p>
			<video src={uri} width="100%" controls preload="auto" style={{ maxWidth: "600px" }}>
				<source src={(item.linkedObject as IFile).realFileUri} type="video/mp4" />
			</video>
		</React.Fragment>
	);
};

export const getTicketAddon = (item: ITicketItem): JSX.Element | null => {
	switch (item.type) {
		case TicketItemType.FileOpen:
		case TicketItemType.FileSent:
			return <a href={`${process.env.REACT_APP_LOCALHOST}${(item.linkedObject as IFile).realFileUri}`}>{": " + (item.linkedObject as IFile).name}</a>;
		default:
			return null;
	}
};
