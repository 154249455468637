import React from "react";
import { T } from "../../managers/I18n";
import { IFile } from "../../types";
import { FileTypes, getFileType } from "../../utilities/files";
import { Icon, Spinner } from "../globalComponents";
import "./ProductFileList.scss";

export interface IProductFileListProps {
	files: IFile[];
	onFileClicked: (file: IFile) => void;
	fullscreen?: boolean;
	isConnected?: boolean;
}

export class ProductFileList extends React.Component<IProductFileListProps> {
	public render() {
		const { files, fullscreen, isConnected } = this.props;

		if (isConnected === false) {
			return (
				<div className="loading-container" style={fullscreen ? { maxHeight: "250" } : {}}>
					<Spinner />
					<h2>
						<T v="Loading" />
					</h2>
				</div>
			);
		}
		return (
			files.length !== 0 ? 
			(<div className="product-file-list-container">
				<div style={fullscreen ? { maxHeight: "250", } : {}}>
					{files.map(this.renderFile)}
				</div>
			</div>) : 
			<div className="product-file-list-container-empty">
			</div>
		);
	}

	private renderFile = (file: IFile) => (
		<div onClick={() => this.props.onFileClicked(file)} className="product-file-list-item" key={file.id}>
			<div className="product-file-icon-container">
				<Icon name={this.getIcon(getFileType(file))} />
			</div>
			<span style={{textOverflow:"ellipsis"}} title={file.name}>{file.name}</span>
			<button>
				<Icon name="send" />
			</button>
		</div>
	);

	private getIcon = (fileType: FileTypes) => {
		switch (fileType) {
			case FileTypes.IMAGE:
				return "image";
			case FileTypes.VIDEO:
				return "movie";
			case FileTypes.PDF:
				return "description";
			case FileTypes.GLB:
				return "3d_rotation";
			case FileTypes.FBX:
				return "3d_rotation";
			default:
				return "description";
		}
	};
}
