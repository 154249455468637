import React from "react";
import Button from "reactstrap/lib/Button";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Id } from "../../types";
import { UserRole } from "../../types/IRoles";
import { ITroubleshootingFlatNode } from "../../types/ITroubleshootingNode";
import { IDictionary, toArray } from "../../utilities";
import { logger } from "../../utilities/logger";
import { findAllChildrenForNode } from "./TroubleshootingData";
import { TroubleshootingNode } from "./TroubleshootingNode";
import "./TroubleshootingNodeList.scss";
import { userInfo } from "os";


export interface ITroubleshootingNodeListProps {
	languageKey: string;
	onNodeClick: (nodeId: number) => void;
	getDepth: (nodeId: number) => number;
	nodeDictionary: IDictionary<ITroubleshootingFlatNode>;
	onDelete: (nodeId: number) => void;
	onDeselect: () => void;
	sortOrder: Id[];
	selectedNodeId: Id | null;
	role: UserRole;
}

export interface ITroubleshootingNodeListState {
	childNodes: number[];
	deleteModal: boolean;
	toDeleteNodeId: number;
}

export class TroubleshootingNodeList extends React.Component<ITroubleshootingNodeListProps, ITroubleshootingNodeListState> {
	public state: ITroubleshootingNodeListState = {
		deleteModal: false,
		childNodes: [],
		toDeleteNodeId: 0,
	};

	public render() {
		const { sortOrder } = this.props;
		const { deleteModal } = this.state;
		return (
			<div>
				{sortOrder.map(this.renderNode)}
				<Modal isOpen={deleteModal}>
					<ModalHeader>Confirm</ModalHeader>
					<ModalBody>{this.getModalBodyText()}</ModalBody>
					<ModalFooter>
						<Button onClick={this.handleOnDeleteConfirm} color="primary">
							Confirm
						</Button>
						<Button onClick={this.handleOnDeleteCancel} color="secondary">
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}

	private getModalBodyText = () => {
		const { childNodes } = this.state;

		switch (childNodes.length) {
			case 0:
				return "Are you sure you want to delete the selected node?";
			case 1:
				return "Deleting this node will also delete 1 other node.";
			default:
				return `Deleting this node will also delete ${childNodes.length} other nodes.`;
		}
	};

	private renderNode = (nodeId: Id) => {
		const { getDepth, selectedNodeId, languageKey, onDeselect, role } = this.props;
		const node = this.props.nodeDictionary[nodeId];
		return (
			<TroubleshootingNode
				onDeselect={onDeselect}
				languageKey={languageKey}
				key={nodeId}
				onClick={e => this.handleNodeClick(e, +nodeId)}
				node={node}
				onDeleteClicked={this.handleOnDeleteClick}
				depth={getDepth(+nodeId)}
				isSelected={selectedNodeId === node.id}
				userRole={role}
				nodeList={this.props.nodeDictionary}				
			/>
		);
	};

	private handleOnDeleteClick = (nodeId: number) => {
		logger.debug("Clicked on delete", nodeId);
		const { nodeDictionary } = this.props;
		const nodes = toArray(nodeDictionary);
		const childNodes = findAllChildrenForNode(nodes, nodeId).map(i => i.id);
		this.setState({ deleteModal: true, childNodes, toDeleteNodeId: nodeId });
	};

	private handleOnDeleteCancel = () => {
		this.setState({ deleteModal: false });
	};

	private handleOnDeleteConfirm = () => {
		const { toDeleteNodeId } = this.state;
		this.props.onDelete(toDeleteNodeId);
		this.setState({ deleteModal: false });
	};

	private handleNodeClick = (e: React.MouseEvent<HTMLDivElement>, nodeId: number) => {
		console.log("ever clicked?")
		e.preventDefault();
		this.props.onNodeClick(nodeId);
	};
}
