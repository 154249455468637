import { AxiosInstance } from "axios";

export class ToolsManager {
	private agent: AxiosInstance;
	private url: string = "Environment";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public getVersion = () => this.agent.get<string>(`${this.url}/Version`);
}
