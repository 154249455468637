import Bowser from "bowser";
import { logger } from "./logger";

export function isIosSafari() {
	const agent = navigator.userAgent.toLowerCase();
	const includesSafari = agent.indexOf("safari") !== -1;
	const includesChrome = agent.indexOf("chrome") !== -1;

	if (includesSafari && !includesChrome) {
		return true;
	}

	return false;
}

export function isFirefox() {
	const agent = navigator.userAgent.toLowerCase();
	const includesFirefox = agent.indexOf("firefox") !== -1;
	return includesFirefox;
}

export function isEdgeOrIe() {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const browserName = browser.getBrowserName().toLowerCase();

	logger.debug("[BROWSER]", browser);

	if (browserName.includes("internet explorer")) {
		return true;
	}

	if (browserName.includes("edge")) {
		return true;
	}

	return false;
}
