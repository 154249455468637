import omit from "ramda/es/omit";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { ITicket } from "../../types";
import { IDictionary, makeDictionary } from "../../utilities";
import { Action, actions } from "./tickets.actions";

export interface IState {
	error: boolean;
	loading: boolean;
	saving: boolean;
	data: IDictionary<ITicket>;
}

export const initialState: IState = {
	error: false,
	loading: false,
	data: {},
	saving: false,
};

export const reducer: Reducer<IState, Action> = (state: IState = initialState, action: Action) => {
	switch (action.type) {
		case getType(actions.getTickets):
		case getType(actions.getTicket):
			return {
				...state,
				data: {},
				loading: true,
			};
		case getType(actions.getTicketsError):
		case getType(actions.getTicketError):
			return {
				...state,
				loading: false,
				error: true,
			};
		case getType(actions.getTicketsSuccess):
			return {
				...state,
				loading: false,
				error: false,
				data: { ...state.data, ...makeDictionary(action.payload, "id") },
			};
		case getType(actions.saveTicketSuccess):
		case getType(actions.getTicketSuccess):
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
			};
		case getType(actions.mergeTicketsSuccess):
		case getType(actions.deleteTicketsSuccess):
			return {
				...state,
				data: omit(action.payload, state.data),
			}
		case getType(actions.saveTicket):
			return {
				...state,
				saving: true,
			};

		case getType(actions.saveTicketError):
			return {
				...state,
				error: true,
			};
		case getType(actions.getTicketViewSuccess):
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: action.payload,
				},
				loading: true,
			};

		default:
			return state;
	}
};
