import { AxiosInstance } from "axios";
import { ICompany, ICompanyCreateDto, IApp, IUser, stringToBoolean } from "../../types";
import { getCurrentLanguage } from "../I18n";

export class CompaniesManager {
	private agent: AxiosInstance;
	private url: string = "Companies";
	private urlAuth: string = "auth";

	constructor(agent: AxiosInstance) {
		this.agent = agent;
	}

	public get = async () => this.agent.get<ICompany[]>(this.url);
	public getNonSubordinateCompanies = async () => this.agent.get<ICompany[]>(`${this.url}/GetNonSubordinateCompanies`);
	public getSubordinateCompaniesById = async (mainCompanyId: string) => this.agent.get<ICompany[]>(`${this.url}/GetSubordinateCompanies?mainCompanyId=${mainCompanyId}`);
	public getMyCompany = async () => this.agent.get<ICompany>(`${this.url}/Company`);
	public getById = async (companyId: string) => this.agent.get<ICompany>(`${this.url}/${companyId}`);
	public post = async (dto: ICompanyCreateDto) => this.agent.post<ICompany>(`${this.url}/Create?lang=${getCurrentLanguage()}`, dto);
	public put = async (dto: ICompany) => this.agent.put<ICompany>(`${this.url}/${dto.id}`, dto);
	public delete = async (companyId: string) => this.agent.delete(`${this.url}/RemoveCompany?companyId=${companyId}`);
	public isValidCompanyName = async (companyName: string) => this.agent.get(`${this.url}/IsValidCompanyName?companyName=${companyName}`);
	public isValidOfficialName = async (companyName: string) => this.agent.get(`${this.url}/IsValidOfficialName?officialName=${companyName}`);
	public getCompanyQRCode = async () => this.agent.get<string>(`${this.url}/QRCode`);
	public sendHelp = async (userId: string, message: string) => this.agent.post(`${this.url}/ProcessHelpRequest?requesterId=${userId}&message=${message}`);
	public updateUsedCallTime = async (companyId: string, time: number) => this.agent.put<number>(`${this.url}/UpdateUsedCallTime?companyId=${companyId}&time=${time}`);
	public getApps = async () => this.agent.get<IApp>(`${this.urlAuth}/GetApps`);
	public setApp = async (dto: IApp) => this.agent.put(`${this.urlAuth}/SetAppAuthorized`, dto);
	public removeApp = async (dto: string) => this.agent.delete(`${this.urlAuth}/RemoveAppAuthorized?appId=${dto}`);
}
