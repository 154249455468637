import { AxiosResponse, AxiosPromise } from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { callbackify } from "util";
import { ToastType } from "../../components/toast";
import { GetLangString, Message } from "../../managers/I18n";
import { serverManager } from "../../managers/ServerManager";
import { IUser, IUserCreate, IProduct } from "../../types";
import { logger } from "../../utilities/logger";
import { actions } from "./users.actions";
import { ITeam } from "../../types/ITeam";

export function* handleGetUsers(action: ReturnType<typeof actions.getUsers>)
{
	try {
		logger.debug("Fetching users");
		const { data }: AxiosResponse<IUser[]> = yield call(serverManager.users.get);
		yield put(actions.getUsersSuccess(data));
		logger.debug(data.length, "users fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getUsersError("Something went wrong while fetching users."));
	}
}

export function* handleGetUsersByCompany(action: ReturnType<typeof actions.getUsersByCompany>)
{
	try {
		logger.debug("Fetching users by company");
		const { data }: AxiosResponse<IUser[]> = yield call(serverManager.users.getByCompany, action.payload);
		yield put(actions.getUsersSuccess(data));
		logger.debug(data.length, "users fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getUsersError("Something went wrong while fetching users."));
	}
}

export function* handleGetUsersByTeam(action: ReturnType<typeof actions.getUsersByTeam>)
{
	try {
		const teamId = action.payload;

		logger.debug("Fetching users by Team");

		const { data }: AxiosResponse<IUser[]> = yield call(serverManager.teams.getUsers, teamId);

		yield put(actions.getUsersSuccess(data));

		logger.debug(data.length, "users fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getUsersError("Something went wrong while fetching users."));
	}
}

export function* handleGetSingleUser(action: ReturnType<typeof actions.getSingleUser>)
{
	try {
		logger.debug("Fetching User with id", action.payload);
		const { data }: AxiosResponse<IUser> = yield call(serverManager.users.getById, action.payload);
		yield put(actions.getSingleUserSuccess(data));
		logger.debug("User with id", action.payload, "fetched successfully.");
	} catch (err) {
		if (err.response && err.response.status === 404) {
			yield put(actions.getSingleUserMissing());
		} else {
			logger.error(err);
			yield put(actions.getSingleUserError("Something went wrong while fetching user with id: " + action.payload));
		}
	}
}

/*export function* handleUpdateUser(action: ReturnType<typeof actions.updateUser>) {
	try {
		yield call(serverManager.users.put, action.payload);
		yield put(actions.updateUserSuccess(action.payload));
	} catch (err) {
		logger.error(err);
		yield put(actions.updateUserError("Something went wrong while saving compnay with id: " + action.payload.id));
	}
}*/

export function* handleUpdateUser(action: ReturnType<typeof actions.updateUser>)
{
	const { callback, dto } = action.payload;

	try {
		yield call(serverManager.users.put, dto);
		yield put(actions.updateUserSuccess(dto));

		if (typeof callback === "function") {
			const contentString = `Toast.UserEditSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.UserEditError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.updateUserError("Something went wrong while updating user with id: " + dto.id));
	}
}

export function* handleResetUser(action: ReturnType<typeof actions.resetUserPsw>)
{
	const { callback, userId } = action.payload;

	try {
		yield call(serverManager.users.reset, userId);
		yield put(actions.resetUserPswSuccess(userId));

		if (typeof callback === "function") {
			const contentString = `Toast.UserResetSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.UserResetError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.resetUserPswError("Something went wrong while resetting user with id: " + userId));
	}
}

export function* handleCreateUser(action: ReturnType<typeof actions.createUser>)
{
	const { dto, callback } = action.payload;
	try {
		const { data }: AxiosResponse<IUser> = yield call(serverManager.users.post, dto);
		
		yield put(actions.createUserSuccess(data));

		if (typeof callback === "function") {
			const contentString = `User created with username:  ${dto.userName}`;
			yield call(callback, contentString, true);
		}
	} catch (err) {
		logger.error(err.response.data);
		if (typeof callback === "function") {
			const contentString = GetLangString(err.response.data);
			yield call(callback, contentString, false);
		}
		yield put(actions.createUserError("Something went wrong while creating a new User"));
	}
}

export function* handleCreateTempUser(action: ReturnType<typeof actions.createTempUser>)
{
	const { callback } = action.payload;

	try {
		const { data }: AxiosResponse<IUser> = yield call(serverManager.users.createTemp);
		yield put(actions.createTempUserSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Toast.UserTempSuccess`;
			yield call(callback, data, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.UserTempError`;
			yield call(callback, null, contentString, ToastType.Error);
		}
		yield put(actions.createTempUserError(err));
	}
}

export function* handleGetBearerToken(action: ReturnType<typeof actions.getBearerToken>)
{
	const { userId, productId, redirectedSection, callback } = action.payload;

	try {
		const { data }: AxiosResponse<string> = yield call(serverManager.users.getBearerToken, userId, productId, redirectedSection);
		yield put(actions.getBearerTokenSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Toast.UserTokenSuccess`;
			yield call(callback, data, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Toast.UserTokenError`;
			yield call(callback, "error", contentString, ToastType.Error);
		}
		yield put(actions.getBearerTokenError(err));
	}
}

export function* handleGetUserDuration(action: ReturnType<typeof actions.getUserDuration>)
{
	const { userId, callback } = action.payload;

	try {
		const { data }: AxiosResponse<string> = yield call(serverManager.users.getUserDuration, userId);
		yield put(actions.getUserDurationSuccess(data));

		if (typeof callback === "function") {
			yield call(callback, data);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			yield call(callback, "N/A");
		}
		yield put(actions.getUserDurationError(err));
	}
}

export function* handleGetUserCreationDate(action: ReturnType<typeof actions.getUserCreationDate>)
{
	const { userId, callback } = action.payload;

	try {
		const { data }: AxiosResponse<string> = yield call(serverManager.users.getUserCreationDate, userId);
		yield put(actions.getUserCreationDateSuccess(data));

		if (typeof callback === "function") {
			yield call(callback, data);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			yield call(callback, "");
		}
		yield put(actions.getUserCreationDateError(err));
	}
}

export function* handleBlockUser(action: ReturnType<typeof actions.blockUser>)
{
	const { callback, userId } = action.payload;

	try {
		yield call(serverManager.users.block, userId);
		yield put(actions.blockUserSuccess(userId));

		if (typeof callback === "function") {
			const contentString = `Toast.UserBlockSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.UserBlockError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.blockUserError(err));
	}
}

export function* handleUnblockUser(action: ReturnType<typeof actions.unblockUser>)
{
	const { callback, userId } = action.payload;

	try {
		yield call(serverManager.users.unblock, userId);
		yield put(actions.unblockUserSuccess(userId));

		if (typeof callback === "function") {
			const contentString = `Toast.UserUnblockSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.UserUnblockError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.unblockUserError(err));
	}
}

export function* handleDeleteUser(action: ReturnType<typeof actions.deleteUser>)
{
	const { callback, userId } = action.payload;

	try {
		yield call(serverManager.users.delete, userId);
		yield put(actions.deleteUserSuccess(userId));

		if (typeof callback === "function") {
			const contentString = `Toast.UserDeletedSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.UserDeletedError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.deleteUserError(err));
	}
}

export function* handleGetTeamsByCompany(action: ReturnType<typeof actions.getTeamsByCompany>)
{
	try {
		logger.debug("Fetching teams by company");
		const { data }: AxiosResponse<ITeam[]> = yield call(serverManager.teams.getTeamsByCompany, action.payload);
		yield put(actions.getTeamsSuccess(data));
		logger.debug(data.length, "teams fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getUsersError("Something went wrong while fetching teams."));
	}
}

export function* handleGetTeams(action: ReturnType<typeof actions.getTeams>)
{
	try {
		logger.debug("Fetching teams");
		const { data }: AxiosResponse<ITeam[]> = yield call(serverManager.teams.getTeams);
		yield put(actions.getTeamsSuccess(data));
		logger.debug(data.length, "teams fetched successfully.");
	} catch (err) {
		logger.error(err);
		yield put(actions.getTeamsError("Something went wrong while fetching teams."));
	}
}

export function* handleCreateTeam(action: ReturnType<typeof actions.createTeam>)
{
	const { dto, callback } = action.payload;

	try {
		const { data }: AxiosResponse<ITeam> = yield call(serverManager.teams.createTeam, dto);
		yield put(actions.createTeamSuccess(data));

		if (typeof callback === "function") {
			const contentString = `Team created with name:  ${dto.name}`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);

		if (typeof callback === "function") {
			const contentString = `Failed to created team`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.createTeamError("Something went wrong while creating a new Team"));
	}
}

export function* handleMoveToTeam(action: ReturnType<typeof actions.moveToTeam>) {
	try {
		const { teamId, userId, isFromNone } = action.payload;

		const { data }: AxiosResponse<IUser> = yield call(serverManager.teams.moveToTeam, teamId, userId);
		
		if (!isFromNone)
			yield put(actions.moveToTeamSuccess(data));

		console.log("User successfully moved");

	} catch (error) {
		
		yield put(actions.moveToTeamError(error.response.data));
		
		console.log("Something wen wrong while moving user");
	}
}

export function* handleUpdateTeam(action: ReturnType<typeof actions.updateTeam>)
{
	const { callback, dto } = action.payload;

	try {
		yield call(serverManager.teams.updateTeam, dto);
		yield put(actions.updateTeamSuccess(dto));

		if (typeof callback === "function") {
			const contentString = `Toast.TeamEditSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.TeamEditError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.updateUserError("Something went wrong while updating team with id: " + dto.id));
	}
}

export function* handleDeleteTeam(action: ReturnType<typeof actions.deleteTeam>)
{
	const { callback, teamId } = action.payload;
	console.log('[foo] teamId: ' + teamId);
	try {
		yield call(serverManager.teams.deleteTeam, teamId);
		yield put(actions.deleteTeamSuccess(teamId));

		if (typeof callback === "function") {
			const contentString = `Toast.TeamDeletedSuccess`;
			yield call(callback, contentString, ToastType.Success);
		}
	} catch (err) {
		logger.error(err);
		if (typeof callback === "function") {
			const contentString = `Toast.TeamDeletedError`;
			yield call(callback, contentString, ToastType.Error);
		}
		yield put(actions.deleteTeamError(err));
	}
}

export function* handleAddTeamReference(action: ReturnType<typeof actions.addTeamReference>) {
	const { teamId, productId, callback } = action.payload;
	
	try {
		console.log('[foo] sono prima di add team');
		yield call(serverManager.products.addTeamReference, teamId, productId);
		console.log('[foo] sono dopo di add team');
		if(typeof callback === "function") {
			yield call(callback);
		}
		yield put(actions.addTeamReferenceSuccess(teamId, productId));

	} catch (err) {
		logger.error(err);
		yield put(actions.addTeamReferenceError(err));
	}
}


export function* handleRemoveTeamReference(action: ReturnType<typeof actions.removeTeamReference>) {
	try {
		const { teamId, productId, callback} = action.payload;
		yield call(serverManager.products.removeTeamReference, teamId, productId);
		if(typeof callback === "function") {
			yield call(callback);
		}
		yield put(actions.removeTeamReferenceSuccess(teamId, productId));


	} catch (err) {
		logger.error(err);
		yield put(actions.removeTeamReferenceError(err));
	}
}

export function* handleGetProductsByTeam(action: ReturnType<typeof actions.getProductsByTeam>) {
	try {
		const { data }: AxiosResponse<IProduct[]> = yield call(serverManager.products.getTeamReferences, action.payload);
		yield put(actions.getProductsByTeamSuccess(data));
	} catch (err) {
		logger.error(err);
		yield put(actions.getProductsByTeamError(err));
	}
}

export function* handleUserSwitchRole(action: ReturnType<typeof actions.switchUserRole>)
{
	try {
		const { callback } = action.payload;

		yield call(serverManager.users.switchUserRole);
		if(typeof callback === "function") {
			yield call(callback);
		}
	} catch (err) {
		logger.error(err);
		yield put(actions.switchUserRoleError(err));
	}
}

export function* handleUserNotification(action: ReturnType<typeof actions.userNotificationExpired>)
{
	try {
		yield call(serverManager.users.SetUserNotificationExpired);
	} catch (err) {
		logger.error(err);
	}
}

export function* usersSaga()
{
	yield takeLatest(getType(actions.moveToTeam), handleMoveToTeam);
	yield takeLatest(getType(actions.deleteUser), handleDeleteUser);
	yield takeLatest(getType(actions.createUser), handleCreateUser);
	yield takeLatest(getType(actions.createTempUser), handleCreateTempUser);
	yield takeLatest(getType(actions.getBearerToken), handleGetBearerToken);
	yield takeLatest(getType(actions.getUserDuration), handleGetUserDuration);
	yield takeLatest(getType(actions.getUserCreationDate), handleGetUserCreationDate);
	yield takeLatest(getType(actions.blockUser), handleBlockUser);
	yield takeLatest(getType(actions.unblockUser), handleUnblockUser);
	yield takeLatest(getType(actions.getUsers), handleGetUsers);
	yield takeLatest(getType(actions.getUsersByCompany), handleGetUsersByCompany);
	yield takeLatest(getType(actions.getUsersByTeam), handleGetUsersByTeam);
	yield takeLatest(getType(actions.getSingleUser), handleGetSingleUser);
	yield takeLatest(getType(actions.updateUser), handleUpdateUser);
	yield takeLatest(getType(actions.resetUserPsw), handleResetUser);
	yield takeLatest(getType(actions.getTeamsByCompany), handleGetTeamsByCompany);
	yield takeLatest(getType(actions.getTeams), handleGetTeams);
	yield takeLatest(getType(actions.createTeam), handleCreateTeam);
	yield takeLatest(getType(actions.updateTeam), handleUpdateTeam);
	yield takeLatest(getType(actions.deleteTeam), handleDeleteTeam);

	yield takeEvery(getType(actions.addTeamReference), handleAddTeamReference);
	yield takeEvery(getType(actions.removeTeamReference), handleRemoveTeamReference);
	yield takeEvery(getType(actions.getProductsByTeam), handleGetProductsByTeam);

	yield takeLatest(getType(actions.switchUserRole), handleUserSwitchRole);

	yield takeLatest(getType(actions.userNotificationExpired), handleUserNotification);
}