import { IRootState } from "../";
import { NodeType } from "../../types/ITroubleshootingNode";
import { toArray } from "../../utilities";

export function canCreateAnswer(state: IRootState) {
	const { selectedNodeId } = state.troubleshootingItems;

	if (!selectedNodeId) {
		return false;
	}

	const selectedNode = state.troubleshootingItems.data[selectedNodeId];

	if (!selectedNode) {
		return false;
	}

	if (selectedNode.type === NodeType.ANSWER) {
		return false;
	}

	const hasChildren = toArray(state.troubleshootingItems.data).some(i => i.parentId === selectedNodeId);

	if (hasChildren) {
		return false;
	}

	return true;
}

export function canCreateQuestion(state: IRootState) {
	const { selectedNodeId } = state.troubleshootingItems;

	if (!selectedNodeId) {
		return true;
	}

	const selectedNode = state.troubleshootingItems.data[selectedNodeId];

	if (!selectedNode) {
		return true;
	}

	if (selectedNode.type === NodeType.ANSWER) {
		return false;
	}

	const hasAnswerChild =
		toArray(state.troubleshootingItems.data)
			.filter(i => i.parentId === selectedNodeId)
			.filter(i => i.type === NodeType.ANSWER).length > 0;

	if (hasAnswerChild) {
		return false;
	}

	return true;
}
