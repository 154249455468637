import { Publisher, PublisherProperties, Session, StreamManager, OpenVidu } from "openvidu-browser";
import { ToastType } from "../components/toast";
import { serverManager } from "../managers/ServerManager";
import { logger } from "../utilities/logger";
import {
	IConnectedSession,
	IOVError,
	IOVStreamRemove,
	IOVTokenData,
	ISessionConnectionData,
	IUserConnect,
	IUserType,
	OPERATOR,
	REMOTE_EXPERT,
	REMOTE_PARTICIPANT,
} from "./RTCHelpers";
import { HD, HQ, LQ } from "../constants";
import { OpenViduErrorName } from "openvidu-browser/lib/OpenViduInternal/Enums/OpenViduError";

export class OVManagerBase {
	public toggleDesktop = () => {
		// TODO Toggle desktop on publisher stream.
	};

	public publishNewCamera = async (userConnect: IUserConnect, oldPublisher: Publisher, videoSource: string) => {
		const { session, OV } = userConnect;
		const resolution = "320x240";
		const frameRate = 15;

		if (oldPublisher) {
			const publisherSettings: PublisherProperties = {
				videoSource,
				audioSource: true,
				publishAudio: true,
				publishVideo: true,
				frameRate,
				resolution,
				mirror: false,
			};
			// Creating a new publisher with specific videoSource
			// In mobile devices the default and first camera is the front one
			const newPublisher = OV.initPublisher(
				undefined as any,
				publisherSettings
			);

			session.unpublish(oldPublisher);

			await session.publish(newPublisher);
			console.log(`[foo] publisher published`);
		}
	};

	public connectSession = async (sessionConnect: ISessionConnectionData) => {
		try {
			const { type, token, userName, session } = sessionConnect;
			const connectionOptions = { userName, type };

			if (session) {
				await session.connect(token, connectionOptions);
			} else {
				throw new Error("No session object provided");
			}
		} catch (err) {
			logger.error(err);
			const ovError: IOVError = {
				userMessage: "Error.SessionConnection",
				toastType: ToastType.Error,
			};
			throw ovError;
		}
	};

	public connectUser = async (userConnect: IUserConnect, videoSource?: string): Promise<IConnectedSession> => {
		const { OV, type } = userConnect;
		try {

			let publisherSettings: PublisherProperties;
			if (videoSource) {
				publisherSettings = this.getPublisherSettings(type, videoSource);
			}
			else {
				publisherSettings = this.getPublisherSettings(type);
			}

			const publisher: Publisher = OV.initPublisher(undefined as any, publisherSettings);
			//await session.publish(publisher);
			//this.awaitWrapper(userConnect, publisher);
			const data: IConnectedSession = {
				mainStreamManager: publisher as StreamManager,
				publisher: publisher,
				publisherType: type,
				isConnected: true,
			};
			return data;
		} catch (err) {
			logger.error(err);
			const ovError: IOVError = {
				userMessage: "Error.PublishConnection",
				toastType: ToastType.Error,
			};
			throw ovError;
		}
	};

	private awaitWrapper = async (userConnect: IUserConnect, publisher: Publisher) => {
		await userConnect.session.publish(publisher);
	}

	public tokenGetter = async (data: IOVTokenData): Promise<ISessionConnectionData> => {
		try {
			if (data.sessionId === "" || data.sessionId == null || data.sessionId.length < 36) {
				throw new Error("SessionID is not valid");
			}

			const res = await serverManager.ovConnections.getToken(data);
			if (res.status !== 200) {
				throw new Error("Could not get token");
			}
			return res.data;
		} catch (err) {
			logger.error(err);
			const ovError: IOVError = {
				userMessage: "Error.TokenGetter",
				toastType: ToastType.Error,
			};
			throw ovError;
		}
	};

	public leaveSession = async (session: Session) => {

		if (session) {
			await session.disconnect();
		} else {
			throw new Error("No session is provided");
		}
	};

	public removeStream = (dto: IOVStreamRemove) => {
		try {
			return dto.participants.filter(participant => participant.stream !== dto.stream);
		} catch (error) {
			const ovError: IOVError = {
				userMessage: "Error.RemoveStream",
				toastType: ToastType.Error,
				errorMessage: error as any,
			};
			throw ovError;
		}
	};

	private getPublisherSettings = (userType: IUserType, videoSource?: string): PublisherProperties => {
		var resolution = "1280x720";
		const frameRate = 15;
		switch (userType) {
			case OPERATOR:
				return {
					publishAudio: true,
					publishVideo: true,
					frameRate,
					resolution,
				};
			case REMOTE_PARTICIPANT:
				return {
					videoSource,
					publishAudio: true,
					publishVideo: true,
					frameRate,
					resolution,
				};
			case REMOTE_EXPERT:
				return {
					videoSource,
					publishAudio: true,
					publishVideo: true,
					resolution,
					frameRate,
				};
			default:
				return {
					videoSource,
					publishAudio: true,
					publishVideo: true,
					resolution,
					frameRate,
				};
		}
	};


	switchCamera = (openViduInstance: OpenVidu, state: any, callback: (index: number) => void, publisherCallback?: (errPublisher: Publisher) => void) => {
		const { publisher, videoDeviceList, videoDeviceIndex } = state;
		const frameRate = 15;
		let videoSource: string = "";
		if (publisher && videoDeviceList.length > 1) {
			const index = (videoDeviceIndex + 1) % videoDeviceList.length;
			videoSource = videoDeviceList[index].deviceId;
			const publisherSettings = {
				audioSource: state.localMicEnabled,
				publishAudio: state.localMicEnabled,
				publishVideo: state.localVideoEnabled,
				videoSource: videoSource,
				resolution: '1280x720',
				frameRate: frameRate
			};

			openViduInstance.getUserMedia(publisherSettings).then(mediaStream => {
				const videoTrack = mediaStream.getVideoTracks()[0];
				videoTrack.enabled = state.localVideoEnabled;

				if (callback) {
					callback.call(this, index);
				}

				try {
					publisher.replaceTrack(videoTrack);
				}
				catch (error) {
					if (error.name === OpenViduErrorName.DEVICE_ACCESS_DENIED) {
						// Creating a new publisher with specific videoSource
						// In mobile devices the default and first camera is the front one
						openViduInstance.session.unpublish(publisher);

						const newPublisher = openViduInstance.initPublisher(
							undefined as any,
							publisherSettings
						);

						openViduInstance.session.publish(newPublisher).then(() => {
							if (publisherCallback) {
								publisherCallback.call(this, newPublisher);
							}
						}
						)
					}
				}
			})
		}
	}

	changeResolution = (openViduInstance: OpenVidu, state: any, resolution: string, callback: () => void) => {
		const { publisher, videoDeviceList, videoDeviceIndex } = state;
		var frameRate = 15;
		if (publisher) {
			let videoSource: string = videoDeviceList[videoDeviceIndex].deviceId;
			openViduInstance.getUserMedia({
				audioSource: state.localMicEnabled,
				videoSource: videoSource,
				resolution: resolution,
				publishAudio: state.localMicEnabled,
				publishVideo: state.localVideoEnabled,
				frameRate: frameRate
			}).then(mediaStream => {
				const videoTrack = mediaStream.getVideoTracks()[0];
				publisher.replaceTrack(videoTrack);

				callback.call(this);
			})
		}
	};

	changeResolutionIcon = (videoResolution: string): string => {

		if (videoResolution === HD) {
			return "HQ";
		}
		if (videoResolution === HQ) {
			return "MQ";
		}
		if (videoResolution === LQ) {
			return "LQ";
		}

		return "";
	}

	toggleLocalVideo = (openViduInstance: OpenVidu, state: any, callback: (videoEnabled: boolean) => void) => {
		const { publisher, videoDeviceList, videoDeviceIndex, localMicEnabled, localVideoEnabled } = state;
		if (publisher) {
			let videoSource: string = videoDeviceList[videoDeviceIndex].deviceId;
			openViduInstance.getUserMedia({
				audioSource: localMicEnabled,
				videoSource: videoSource,
				resolution: "1280x720",
				publishAudio: localMicEnabled,
				publishVideo: localVideoEnabled,
				frameRate: 15
			}).then(mediaStream => {
				const videoTrack = mediaStream.getVideoTracks()[0];
				publisher.replaceTrack(videoTrack);
				const videoEnabled = !localVideoEnabled
				videoTrack.enabled = videoEnabled;

				callback.call(this, videoEnabled);
			})
		}
	}

	toggleShareScreen = (openViduInstance: OpenVidu, state: any) => {
		const { publisher, videoDeviceList, videoDeviceIndex, localMicEnabled, localVideoEnabled } = state;
		let frameRate = 15;

		if (publisher) {
			let videoSource: string = videoDeviceList[videoDeviceIndex].deviceId;
			let oldVideoTrack: MediaStreamTrack;
			openViduInstance.getUserMedia({
				audioSource: localMicEnabled,
				videoSource: videoSource,
				resolution: HQ,
				publishAudio: true,
				publishVideo: true,
				frameRate: frameRate
			}).then(mediaStream => {
				oldVideoTrack = mediaStream.getVideoTracks()[0];

				oldVideoTrack.enabled = localVideoEnabled;
			})

			navigator.mediaDevices.getDisplayMedia({
				video: localVideoEnabled,

			}).then(mediaStream => {
				let videoTrack = mediaStream.getVideoTracks()[0];

				// videoTrack.enabled = this.state.localVideoEnabled;
				publisher.replaceTrack(videoTrack);
				videoTrack.onended = () => {
					publisher.replaceTrack(oldVideoTrack);
				};
			});
		}

	}
}

export const OVManager = new OVManagerBase();
