export enum UserRole {
	Operator = 0,
	Expert = 1,
	CompanyAdmin = 2,
	SuperAdmin = 3,
}

export enum UserRoleText {
	Operator = "UserType.Operator",
	Expert = "UserType.Expert",
	CompanyAdmin = "UserType.CompanyAdmin",
	SuperAdmin = "UserType.SuperAdmin",
}

export const UserRolesTextList = [
	{
		name: "UserType.Operator",
		vaue: 0,
	},
	{
		name: "UserType.Expert",
		vaue: 1,
	},
	{
		name: "UserType.CompanyAdmin",
		vaue: 2,
	},
	{
		name: "UserType.SuperAdmin",
		vaue: 3,
	},
];
